import { Autocomplete, Box, Button, Grid, RadioGroup, Radio, FormControlLabel, InputAdornment, Link, TextField, Tooltip, Typography, Modal, LinearProgress, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { GET_USER_COMPANIES_POSTULATE } from '../../Queries/Companies'
import { GET_FUNDS_BY_ID, CREATE_FUND_PROPOSAL } from '../../Queries/Funds'
import { useLazyQuery, useMutation } from '@apollo/client'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { imgUploadHandler } from '../../utils/fileUpload'
import InfoIcon from '@mui/icons-material/Info'
import { useParams } from "react-router"
import { push } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'
import { NumericFormat } from 'react-number-format';

const CompanyPostulation = () => {
    const { t } = useTranslation()
    const { user } = useAuth0()
    const { fundId } = useParams()
    const dispatch = useDispatch()

    const [getUserCompanies, { data: userCompanies }] = useLazyQuery(GET_USER_COMPANIES_POSTULATE, {
        variables: { userId: user.email || user?.sub }
    })

    const [getFundById, { data: fundData }] = useLazyQuery(GET_FUNDS_BY_ID, { variables: { FundId: fundId } })
    const [createProposal] = useMutation(CREATE_FUND_PROPOSAL)

    useEffect(() => {
        getUserCompanies()
        getFundById()
    }, [getUserCompanies, getFundById])

    function convertToNumberWithCommas(numWithCommas) {
        if (typeof numWithCommas === 'string') {
            return parseFloat(numWithCommas.replace(/,/g, ''));
        } else {
            return NaN;
        }
    }

    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [budget, setBudget] = useState(undefined)
    const pureBudget = convertToNumberWithCommas(budget)

    useEffect(() => {
        if (budget === undefined) {
            setBudget(String(fundData?.funds[0]?.minCompanyAsk))
        }
    }, [budget, fundData])

    const [milestoneState, setMilestoneState] = useState('')
    const [actionPlanState, setActionPlanState] = useState('')
    const [objectives, setObjectives] = useState('')
    const [externalUrl, setExternalUrl] = useState('')
    const [radioSelected, setRadioSelected] = useState('en')
    const [values, setValues] = useState({
        titleEn: '',
        titleEs: '',
        titlePt: '',
        descriptionEN: '',
        descriptionES: '',
        descriptionPT: '',
    })

    const handleInputChange = async (fieldName, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }))
    }

    const handleRadioChange = (event) => {
        setRadioSelected(event.target.value)
    }

    const milestoneHandler = async (file) => {
        const milestoneFileUrl = await imgUploadHandler(file)
        setMilestoneState(milestoneFileUrl)
    }

    const actionPlanHandler = async (file) => {
        const actionPlanFileUrl = await imgUploadHandler(file)
        setActionPlanState(actionPlanFileUrl)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pureBudget < fundData?.funds[0]?.minCompanyAsk) {
                setBudget(String(fundData?.funds[0]?.minCompanyAsk))
            }
            if (pureBudget > fundData?.funds[0]?.maxCompanyAsk) {
                setBudget(String(fundData?.funds[0]?.maxCompanyAsk))
            }
            if (pureBudget === 0) {
                setBudget(String(fundData?.funds[0]?.minCompanyAsk))
            }
        }, 400);
        return () => clearTimeout(timer);
    }, [budget, pureBudget, fundData])

    const newProposalId = uuidv4()
    const newUrlId = uuidv4()
    const dateTime = new Date()
    const [openSuccessPostulation, setOpenSuccessPostulation] = useState(false);
    const handleOpenSuccessPostulation = () => setOpenSuccessPostulation(true);
    const handleCloseSuccessPostulation = () => setOpenSuccessPostulation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '5px',
        p: 2,
    };

    const [sendingMutation, setSendingMutation] = useState(false)
    const [autoOpen, setAutoOpen] = useState(false)
    const handleCreateCoClick = () => {
        dispatch(push('/companies-create'))
    }

    const handlePublishProposal = async () => {
        setSendingMutation(true)
        await createProposal({
            variables: {
                propId: newProposalId,
                propstatus: "proposed",
                proptitleEn: values.titleEn,
                proptitleEs: values.titleEs,
                proptitlePt: values.titlePt,
                propdescriptionEn: values.descriptionEN,
                propdescriptionEs: values.descriptionES,
                propdescriptionPt: values.descriptionPT,
                reqInvestAmount: Number(pureBudget),
                on: dateTime,
                actionPlanFile: actionPlanState,
                milestonesFile: milestoneState,
                objectives: objectives,
                assessmentStatus: "pending",
                urlId: newUrlId,
                urllink: "https://" + externalUrl,
                urlname: "ProposalUrl",
                fundId: fundId,
                companyId: selectedCompanyId
            }
        })
        setSendingMutation(false)
        handleOpenSuccessPostulation()
    }

    // eslint-disable-next-line
    const handleProposalSaveAsDraft = async () => {
        await createProposal({
            variables: {
                propId: newProposalId,
                propstatus: "draft",
                proptitleEn: values.titleEn,
                proptitleEs: values.titleEs,
                proptitlePt: values.titlePt,
                propdescriptionEn: values.descriptionEN,
                propdescriptionEs: values.descriptionES,
                propdescriptionPt: values.descriptionPT,
                reqInvestAmount: Number(pureBudget),
                on: dateTime,
                actionPlanFile: actionPlanState,
                milestonesFile: milestoneState,
                objectives: objectives,
                assessmentStatus: "pending",
                fundId: fundId,
                companyId: selectedCompanyId
            }
        })
        await dispatch(push('/funds'))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    return (
        <>
            {/* HEADER */}
            <Grid container xs={12} md={11}>
                <Grid item sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, justifyContent: 'space-between', borderBottom: '1px solid #4F4F4F', width: { md: '100%', xs: '90%' }, ml: { md: 6, xs: 2 }, mb: 2, mt: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}
                    >
                        <ArrowBackIcon sx={{
                            fontSize: '16px',
                            cursor: 'pointer'
                        }} />
                        <Typography
                            onClick={handleOpenConfirmation}
                            sx={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontSize: '14px',
                            }}
                        >
                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                        </Typography>
                        <Typography sx={{ fontSize: '20px', ml: 4 }}>
                            {t('ADMIN.POSTULATION')}
                        </Typography>
                    </Box>
                    <Box>
                        <RadioGroup
                            disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                            sx={{ mt: 4, mb: 2 }}
                            onChange={handleRadioChange}
                            defaultValue={radioSelected}
                            row
                        >
                            <Tooltip placement="top" title={t('DASHBOARD_VIEW.MANDATORY')}>
                                <FormControlLabel
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                    value="en"
                                    control={<Radio />}
                                    label="English *"
                                />
                            </Tooltip>
                            <Tooltip placement="top" title={t('DASHBOARD_VIEW.OPTIONAL')}>
                                <FormControlLabel
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === '' || selectedCompanyId === '' ||
                                        values.titleEn === '' ||
                                        values.descriptionEN === '' ||
                                        budget === 0 ||
                                        actionPlanState === '' ||
                                        externalUrl === '' ||
                                        milestoneState === '' ||
                                        objectives === ''}
                                    value="es"
                                    control={<Radio />}
                                    label="Español"
                                />
                            </Tooltip>
                            <Tooltip placement="top" title={t('DASHBOARD_VIEW.OPTIONAL')}>
                                <FormControlLabel
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === '' || selectedCompanyId === '' ||
                                        values.titleEn === '' ||
                                        values.descriptionEN === '' ||
                                        budget === 0 ||
                                        actionPlanState === '' ||
                                        externalUrl === '' ||
                                        milestoneState === '' ||
                                        objectives === ''}
                                    value="pt"
                                    control={<Radio />}
                                    label="Português"
                                />
                            </Tooltip>
                        </RadioGroup>
                    </Box>
                </Grid>
            </Grid>
            {/* LEFT COLUMN */}
            <Grid container xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item xs={12} md={6} sx={{ pl: { md: 16, xs: 2 }, pr: { md: 8, xs: 2 } }}>
                    <Box sx={{ pl: 2, pr: 2 }}>
                        <Typography>
                            {t('INVESTMENT_TABLE.COMPANY')}*
                        </Typography>
                        {userCompanies?.companies?.length < 1 && userCompanies == undefined ? (
                            <Autocomplete
                                fullWidth
                                size='small'
                                disabled
                            />
                        ) : (
                            <>
                                <Autocomplete
                                    fullWidth
                                    sx={{ zIndex: -10 }}
                                    size='small'
                                    limitTags={6}
                                    onOpen={() => { setAutoOpen(true) }}
                                    onClose={() => { setAutoOpen(false) }}
                                    noOptionsText={t('COMPANY_VIEW.NO_COMPANY')}
                                    onChange={(event, newValue) => {
                                        setSelectedCompanyId(newValue.id)
                                    }}
                                    id="tags-outlined"
                                    options={userCompanies?.companies || []}
                                    getOptionLabel={(p) => p?.coName}
                                    renderInput={(params) => (
                                        <>
                                            {autoOpen === true && userCompanies?.companies?.length < 1 ? (
                                                <Button
                                                    variant='contained'
                                                    onClick={handleCreateCoClick}
                                                    sx={{ position: 'absolute', mt: 0.2, ml: 0.4, zIndex: 100 }}
                                                >
                                                    {t('OFFERINGS_TABLE.CREATE_COMPANY')}
                                                </Button>
                                            ) : null}
                                            <TextField
                                                sx={{ width: '100%', zIndex: 1 }}
                                                {...params}
                                                name="Country"
                                                color="pink"
                                            />
                                        </>
                                    )}
                                />
                            </>
                        )}
                    </Box>

                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        {radioSelected === 'en' ? (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography >
                                        {t('ADMIN.NEW_PUBLICATION_TITLE_FULL')}*
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                        fullWidth
                                        value={values.titleEn}
                                        onChange={(e) =>
                                            handleInputChange('titleEn', e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        ) : radioSelected === 'es' ? (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography>
                                        {t('ADMIN.NEW_PUBLICATION_TITLE_FULL')}
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={values.titleEs}
                                        onChange={(e) =>
                                            handleInputChange('titleEs', e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography>
                                        {t('ADMIN.NEW_PUBLICATION_TITLE_FULL')}
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={values.titlePt}
                                        onChange={(e) =>
                                            handleInputChange('titlePt', e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography>
                                {t('CO_ASSESSMENT.MILESTONES')}*
                            </Typography>
                            <Tooltip title={t('FUND.MILESTONES_TOOLTIP')}>
                                <InfoIcon
                                    fontSize="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                            {fundData?.funds[0]?.vestingPlan === "vesting 1" &&
                                <Link
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting1.pdf?alt=media&token=05c4ff7e-70b8-4219-83cc-066f408f2f12'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 1
                                </Link>
                            }
                            {fundData?.funds[0]?.vestingPlan === "vesting 2" &&
                                <Link
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting2.pdf?alt=media&token=30774161-8223-4bf4-9c88-80524ca05e19'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 2
                                </Link>
                            }
                            {fundData?.funds[0]?.vestingPlan === "vesting 3" &&
                                <Link
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting3.pdf?alt=media&token=7c9a8eac-b743-4383-bfd8-50c162f1b1f2'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 3
                                </Link>
                            }
                            {fundData?.funds[0]?.vestingPlan === "vesting 4" &&
                                <Link
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting4.pdf?alt=media&token=d6eaf554-2ae5-4696-81b1-241a3dd26f0c'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 4
                                </Link>
                            }
                        </Box>
                        {milestoneState ? (
                            <Button
                                disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '100%', md: '100%' },
                                    height: '40px',
                                }}
                            >
                                ✅
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '100%', md: '100%' },
                                    height: '40px',
                                }}
                            >
                                <UploadFileIcon sx={{ mr: 2 }} />
                                <input
                                    hidden
                                    type="file"
                                    accept="file/*"
                                    onChange={(e) => milestoneHandler(e.target.files?.[0])}
                                />
                                {t('CREATE_COMPANY.UPLOAD_FILE')}
                            </Button>
                        )}
                    </Box>
                </Grid>
                {/* LEFT COLUMN- END */}
                {/* RIGHT COLUMN */}
                <Grid item xs={12} md={6} sx={{ pl: { md: 16, xs: 2 }, pr: { md: 8, xs: 2 }, mt: { md: 0, xs: 1 } }}>
                    <Box sx={{ pl: 2, pr: 2 }}>
                        <Typography>
                            {t('WALLET_VIEW.SYMBOL')}
                        </Typography>
                        <TextField
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={fundData?.funds[0]?.name}
                            disabled
                        />
                    </Box>
                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography>
                                {t('FUND.REQUESTED_INVESTMENT')}*
                            </Typography>
                            <Tooltip title={`Min: ${fundData?.funds[0]?.minCompanyAsk} - Max: ${fundData?.funds[0]?.maxCompanyAsk}`}>
                                <InfoIcon
                                    fontSize="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                        </Box>
                        <NumericFormat
                            value={budget}
                            disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                            thousandSeparator
                            size='small'
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                            }}
                            onChange={(e) => { setBudget(e.target.value) }}
                            customInput={TextField}
                            error={isNaN(pureBudget) && pureBudget !== ''}
                        />
                    </Box>
                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography>
                                {t('CO_ASSESSMENT.ACT_PLAN')}*
                            </Typography>
                            <Tooltip title={t('CREATE_COMPANY.UPLOAD_PDF_FILE')}>
                                <InfoIcon
                                    fontSize="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                        </Box>
                        {actionPlanState ? (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '100%', md: '100%' },
                                    height: '40px',
                                }}
                            >
                                ✅
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '100%', md: '100%' },
                                    height: '40px',
                                }}
                            >
                                <UploadFileIcon sx={{ mr: 2 }} />
                                <input
                                    hidden
                                    type="file"
                                    accept="file/*"
                                    onChange={(e) => actionPlanHandler(e.target.files?.[0])}
                                />
                                {t('CREATE_COMPANY.UPLOAD_FILE')}
                            </Button>
                        )}
                    </Box>


                </Grid>
                {/* RIGHT COLUMN - END */}
            </Grid >

            <Box sx={{ display: 'flex', flexDirection: 'row', ml: { md: 16, xs: 2 }, mr: { md: 16, xs: 2 }, mt: 1 }}>
                <Box sx={{ pl: 2, pr: 2, width: { md: '45%', xs: '100%' } }}>
                    <Typography>
                        URL*
                    </Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                        }}
                        value={externalUrl}
                        onChange={(e) => setExternalUrl(e.target.value)}
                    />
                </Box>
            </Box >

            <Box sx={{ display: 'flex', flexDirection: 'row', ml: { md: 16, xs: 2 }, mr: { md: 16, xs: 2 }, mt: 1 }}>
                <Box sx={{ pl: 2, pr: 2, width: '100%' }}>
                    <Typography>
                        {t('FUND.OBJECTIVES')}*
                    </Typography>
                    <TextField
                        variant="outlined"
                        disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                        fullWidth
                        multiline
                        minRows={2}
                        value={objectives}
                        onChange={(e) => setObjectives(e.target.value)}
                    />
                </Box>
            </Box >
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: { md: 16, xs: 2 }, mr: { md: 16, xs: 2 }, mt: 1 }}>
                <Box sx={{ pl: 2, pr: 2, width: '100%' }}>
                    {radioSelected === 'en' ? (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {t('ADMIN.DESCRIPTION')}*
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    disabled={userCompanies?.companies?.length < 1 || selectedCompanyId === ''}
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={values.descriptionEN}
                                    onChange={(e) =>
                                        handleInputChange('descriptionEN', e.target.value)
                                    }
                                    type="text"
                                    aria-describedby="status-input-text"
                                />
                            </Box>
                        </>
                    ) : radioSelected === 'es' ? (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {t('ADMIN.DESCRIPTION')}
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={values.descriptionES}
                                    onChange={(e) =>
                                        handleInputChange('descriptionES', e.target.value)
                                    }
                                    type="text"
                                    aria-describedby="status-input-text"
                                />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {t('ADMIN.DESCRIPTION')}
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={values.descriptionPT}
                                    onChange={(e) =>
                                        handleInputChange('descriptionPT', e.target.value)
                                    }
                                    type="text"
                                    aria-describedby="status-input-text"
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Box >
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: { md: 16, xs: 2 }, mr: { md: 16, xs: 2 } }}>
                {sendingMutation ?
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                        <Box sx={{ width: '50%' }}>
                            <LinearProgress />
                        </Box>
                    </Box>
                    :
                    <Box sx={{ pl: 2, pr: 2, mt: 2, mb: 2, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            selectedCompanyId === '' ||
                                values.titleEn === '' ||
                                values.descriptionEN === '' ||
                                budget === 0 ||
                                actionPlanState === '' ||
                                externalUrl === '' ||
                                milestoneState === '' ||
                                objectives === '' ?
                                < Button
                                    color="primary"
                                    component="label"
                                    disabled
                                    variant="contained"
                                    sx={{
                                        borderRadius: '5px',
                                        backgroundColor: '#9A1D4C',
                                        ml: { md: 8, xs: 0 }
                                    }}
                                >
                                    {t('CREATE_COMPANY.APPLY')}
                                </Button> :
                                < Button
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    onClick={handlePublishProposal}
                                    sx={{
                                        borderRadius: '5px',
                                        backgroundColor: '#9A1D4C',
                                        ml: { md: 8, xs: 0 }
                                    }}
                                >
                                    {t('CREATE_COMPANY.APPLY')}
                                </Button>
                        }
                    </Box>}
            </Box >
            <Modal
                open={openSuccessPostulation}
                onClose={handleCloseSuccessPostulation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ fontSize: '18px', color: '#9A1D4C', fontWeight: 'bold', mb: 2 }}>
                        {t('FUND.SUCCESS_POSTULATION')}
                    </Typography>

                    <Typography sx={{ pb: 1, mb: 1, borderBottom: '1px solid gray' }}>
                        {fundData?.funds[0]?.name}
                    </Typography>
                    <Typography>
                        {t('FUND.SUCCESS_POSTULATION_OK')}
                    </Typography>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                        <Button variant='contained' onClick={() => dispatch(goBack())}>
                            {t('FUND.BACK_FUND')}
                        </Button>
                    </Box>
                </Box>
            </Modal >
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleConfirm}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                        {t('MY_OFFERINGS.CHANGES_LOST')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            mt: 2,
                        }}
                    >
                        <Button variant="outlined" onClick={handleCloseConfirmation}>
                            {t('CO_ASSESSMENT.CANCEL')}
                        </Button>
                        <Button variant="contained" onClick={() => dispatch(goBack())}>
                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CompanyPostulation