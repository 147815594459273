import { Avatar, Box, Button, Link, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

const InvestmentTableRow = ({ fundId, data, handleButtonClick }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleButtonClickSelect = () => {
        handleButtonClick(fundId);
    };

    const handleClickViewFund = () => {
        dispatch(push(`/fund-detail/${fundId}`))
    }

    const [headerStartDateToShow, setHeaderStartDateToShow] = useState(undefined)
    const [headerEndDateToShow, setHeaderEndDateToShow] = useState(undefined)

    useEffect(() => {
        data[0]?.fund?.event?.map((e) => {
            if (e?.titleEn === data[0]?.fund?.status) {
                setHeaderStartDateToShow(e?.startDate?.split('T')[0])
                setHeaderEndDateToShow(e?.endDate?.split('T')[0])
            }
        })
    }, [data])

    const startDay = headerStartDateToShow?.split('-')[2].split('T')[0]
    const startMonth = headerStartDateToShow?.split('-')[1]
    const startYear = headerStartDateToShow?.split('-')[0]
    const endDay = headerEndDateToShow?.split('-')[2].split('T')[0]
    const endMonth = headerEndDateToShow?.split('-')[1]
    const endYear = headerEndDateToShow?.split('-')[0]

    const totalAmount = data?.reduce((acc, cur) => acc + cur.amount, 0);

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    const totalAmountInvestorDeposited = data?.reduce((acc, transaction) => {
        if (!excludedStatuses.includes(transaction.status)) {
            acc += transaction.amount;
        }
        return acc;
    }, 0);

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <>
            <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: actualThemeLight ? 'white' : '#262626', cursor: 'pointer' }}>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {data[0]?.fund?.image !== '' ?
                            <Avatar src={data[0]?.fund?.image} alt={'LOGO'} sx={{ mr: 1 }} />
                            : <Box sx={{ ml: 6 }}> </Box>
                        }
                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', textWrap: 'nowrap', overflow: 'hidden' }}>
                            {data[0]?.fund?.name}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Typography sx={{ fontSize: '12px' }}>
                        {data[0]?.fund?.status}
                    </Typography>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {headerStartDateToShow !== undefined &&
                            <Typography sx={{ fontSize: '12px' }}>
                                {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}, {startYear}
                            </Typography>
                        }
                        {headerEndDateToShow !== undefined &&
                            <Typography sx={{ fontSize: '12px' }}>
                                {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}, {endYear}
                            </Typography>
                        }
                    </Box>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Typography sx={{ fontSize: '12px' }}>
                        {totalAmountInvestorDeposited} / {totalAmount} {data[0]?.fund?.token[0]?.symbol}
                    </Typography>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Link sx={{
                        color: actualThemeLight ? null : '#e6a3bd',
                        textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                    }} onClick={handleClickViewFund}  >
                        <Typography sx={{ fontSize: '12px' }}>
                            {t('WALLET_VIEW.INVESTMENT_CARD.VIEW_FUND')}
                        </Typography>
                    </Link>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Button onClick={handleButtonClickSelect} variant='contained' size='small'>
                        <Typography sx={{ fontSize: '12px' }}>
                            {t('WALLET_VIEW.BUTTON_INV_OFF')}
                        </Typography>
                    </Button>
                </TableCell>
            </TableRow >
        </>
    )
}

export default InvestmentTableRow