import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
    const [expanded, setExpanded] = React.useState(false);
    const { t } = useTranslation()
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ width: { md: '100%', xs: '90%' }, pb: 8, ml: { xs: '5%' } }}>
            <Box>
                <Typography sx={{ mb: 2, fontSize: '20px', fontWeight: 'bold' }}>{t('FAQ.SECTION_A_TITLE')}</Typography>
                <Accordion
                    expanded={expanded === 'panela1'}
                    onChange={handleChange('panela1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panela1bh-content"
                        id="panela1bh-header"
                    >
                        <Typography>
                            {t('NAVBAR.DASHBOARD')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_A1_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panela2'}
                    onChange={handleChange('panela2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panela2bh-content"
                        id="panela2bh-header"
                    >
                        <Typography>
                            {t('NAVBAR.FUNDS')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_A2_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panela3'}
                    onChange={handleChange('panela3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panela3bh-content"
                        id="panela3bh-header"
                    >
                        <Typography>
                            {t('NAVBAR.COMPANIES')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_A3_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panela4'}
                    onChange={handleChange('panela4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panela4bh-content"
                        id="panela4bh-header"
                    >
                        <Typography>
                            {t('NAVBAR.MY_WALLET')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_A4_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panela5'}
                    onChange={handleChange('panela5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panela5bh-content"
                        id="panela5bh-header"
                    >
                        <Typography>
                            {t('NAVBAR.DAO')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_A5_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box>
                <Typography sx={{ mb: 2, mt: 2, fontSize: '20px', fontWeight: 'bold' }}>{t('FAQ.SECTION_B_TITLE')}</Typography>
                <Accordion
                    expanded={expanded === 'panelb1'}
                    onChange={handleChange('panelb1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelb1bh-content"
                        id="panelb1bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_B1_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_B1_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelb2'}
                    onChange={handleChange('panelb2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelb2bh-content"
                        id="panelb2bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_B2_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_B2_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box>
                <Typography sx={{ mb: 2, mt: 2, fontSize: '20px', fontWeight: 'bold' }}>{t('FAQ.SECTION_C_TITLE')}</Typography>
                <Accordion
                    expanded={expanded === 'panelc1'}
                    onChange={handleChange('panelc1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc1bh-content"
                        id="panelc1bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C1_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C1_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc2'}
                    onChange={handleChange('panelc2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc2bh-content"
                        id="panelc2bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C2_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C2_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc3'}
                    onChange={handleChange('panelc3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc3bh-content"
                        id="panelc3bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C3_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C3_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc4'}
                    onChange={handleChange('panelc4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc4bh-content"
                        id="panelc4bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C4_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C4_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc5'}
                    onChange={handleChange('panelc5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc5bh-content"
                        id="panelc5bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C5_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C5_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc6'}
                    onChange={handleChange('panelc6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc6bh-content"
                        id="panelc6bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C6_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C6_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc7'}
                    onChange={handleChange('panelc7')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc7bh-content"
                        id="panelc7bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C7_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C7_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panelc8'}
                    onChange={handleChange('panelc8')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelc8bh-content"
                        id="panelc8bh-header"
                    >
                        <Typography>
                            {t('FAQ.PANEL_C8_TITLE')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('FAQ.PANEL_C8_DESCRIPTION')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}

export default FAQ