import { gql } from '@apollo/client'

export const CREATE_SMART_CONTRACT = gql`
  mutation (
    $id: ID!
    $createdDate: DateTime!
    $status: String!
    $contractId: String!
    $contractLink: String!
    $offerPurchaseId: ID!
  ) {
    createSmartContracts(
      input: {
        id: $id
        createdDate: $createdDate
        status: $status
        contractId: $contractId
        contractLink: $contractLink
        offerPurchase: {
          connect: { where: { node: { id: $offerPurchaseId } } }
        }
      }
    ) {
      smartContracts {
        id
      }
    }
  }
`

export const UPDATE_SMART_CONTRACT = gql`
  mutation ($smartId: ID!, $contractData: SmartContractUpdateInput!) {
    updateSmartContracts(where: { id: $smartId }, update: $contractData) {
      smartContracts {
        id
      }
    }
  }
`

export const UPDATE_OFFER_PURCHASE = gql`
  mutation (
    $offerId: ID!
    $status: String
    $txId: String
    $lastUpdate: DateTime
    $coreteamaddress: String
    $choicemakerAddress: String
  ) {
    updateOfferPurchases(
      where: { id: $offerId }
      update: {
        status: $status
        lastTxId: $txId
        lastUpdate: $lastUpdate
        coreteamAddress: $coreteamaddress
        choicemakerAddress: $choicemakerAddress
      }
    ) {
      offerPurchases {
        id
      }
    }
  }
`

export const UPDATE_OFFER_PURCHASE_BULK = gql`
  mutation (
    $offerId: [ID!]
    $status: String
    $txId: String
    $lastUpdate: DateTime
  ) {
    updateOfferPurchases(
      where: { id_IN: $offerId }
      update: { status: $status, lastTxId: $txId, lastUpdate: $lastUpdate }
    ) {
      offerPurchases {
        id
      }
    }
  }
`

export const ADD_CBOR_OFFER_PURCHASE_BULK = gql`
  mutation ($offerId: [ID!], $txCbor: String, $withdrawLink: String) {
    updateOfferPurchases(
      where: { id_IN: $offerId }
      update: { txCbor: $txCbor, withdrawLink: $withdrawLink }
    ) {
      offerPurchases {
        id
      }
    }
  }
`
