import React from "react"
import { Fragment } from "react"
import Card from "../../../../components/Card/Card"
import { Box, Avatar, Typography, Button, Grid, Tooltip, Modal, useTheme } from "@mui/material"
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useTranslation } from 'react-i18next'
import VerifiedIcon from '@mui/icons-material/Verified';
import { push } from 'connected-react-router'
import { showMessage } from '../../../../store/actions/snackBarActions'
import { useDispatch } from 'react-redux'
import ReactCountryFlag from "react-country-flag"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMutation } from "@apollo/client";
import { DELETE_COMPANY } from "../../../../Queries/Companies";
import CardanoLogo from '../../../../assets/icons/cardano-ada-logo.png'


const ActiveCompanyCard = ({ id, name, logo, isValidated, desc, address, country, link, employees, fundationYear, isDraft, annualBilling, isIncJurisdiction, isIncorporated, seeMore, showMore }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const locStorageRole = localStorage.getItem('role')

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const [deleteCompany] = useMutation(DELETE_COMPANY)

    const handleDelete = async () => {
        await deleteCompany({
            variables: {
                coId: id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Deleted!', variant: 'success' }))
    }

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return <Fragment>
        <Card color='#FFF' key={id} height='100%' border='1px solid #DADADA' display='flex' flexDirection='column' justifyContent='space-between' >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%' }}>
                <Grid container xs={12} sx={{ display: 'flex', flexDirection: 'row', maxHeight: '40px' }}>
                    <Grid item xs={1.5} sx={{ height: '40px', width: '40px' }} >
                        <Avatar alt="logo" src={logo} />
                    </Grid>
                    <Grid item xs={10.5} sx={{ height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Grid xs={12} container sx={{ pl: 1 }}>
                            <Grid xs={8} sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'row', maxHeight: '22px' }} item>
                                <Typography sx={{ color: actualThemeLight ? 'black' : 'white', fontWeight: 'bold', overflow: 'hidden', ml: { sx: 0, md: 1, lg: 0 } }}>
                                    {name}
                                </Typography>
                                {isValidated && <VerifiedIcon sx={{ color: actualThemeLight ? '#9A1D4C' : '#e6a3bd', ml: 1 }} />}
                                {isDraft ?
                                    <Tooltip title={t('OFFERINGS_TABLE.DELETE_DRAFT')}>
                                        <Box onClick={handleOpenConfirmation} sx={{ color: '#9A1D4C', cursor: 'pointer', ml: 1 }}>
                                            <DeleteForeverIcon />
                                        </Box>
                                    </Tooltip>
                                    :
                                    null
                                }
                            </Grid>
                            {
                                country?.id !== '1' ?
                                    <>
                                        <Tooltip title={address + " - " + country?.name}>
                                            <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end', overflow: 'hidden', maxHeight: '22px', pr: 1 }}>
                                                <ReactCountryFlag
                                                    countryCode={country?.id}
                                                    style={{
                                                        fontSize: '18px',
                                                        lineHeight: '18px',
                                                    }}
                                                    svg
                                                />
                                                <Typography sx={{ fontSize: '12px', color: actualThemeLight ? '#4F4F4F' : 'white', mt: '1px', ml: '2px' }}>
                                                    {country?.id}
                                                </Typography>
                                            </Grid>
                                        </Tooltip>
                                    </>
                                    :
                                    country?.id === undefined ?
                                        <>
                                            <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end', overflow: 'hidden', maxHeight: '22px', pr: 1 }}>
                                                <img src={CardanoLogo} width={'20px'} height={'20px'} />
                                                <Typography sx={{ fontSize: '12px', color: '#4F4F4F', mt: '1px', ml: '2px' }}>
                                                    NA
                                                </Typography>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end', overflow: 'hidden', maxHeight: '22px', pr: 1 }}>
                                                <img src={CardanoLogo} width={'20px'} height={'20px'} />
                                                <Typography sx={{ fontSize: '12px', color: '#4F4F4F', mt: '1px', ml: '2px' }}>
                                                    NA
                                                </Typography>
                                            </Grid>
                                        </>

                            }
                        </Grid>
                    </Grid>
                </Grid>
                {/* INFERIOR */}
                {locStorageRole === 'role-investor' || showMore ? <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', mt: 1 }}>
                    {/* EMPLOYEES */}
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '80%', maxHeight: '30px' }}>
                        <Typography sx={{ fontSize: '14px', width: '60%' }}>
                            {t('FUND.EMPLOYEES_NUMBER')}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', width: '40%', fontWeight: 'bold' }}>
                            {employees}
                        </Typography>
                    </Box>
                    {/* ANNUAL BILLING */}
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '80%', maxHeight: '30px' }}>
                        <Typography sx={{ fontSize: '14px', width: '60%' }}>
                            {t('CREATE_COMPANY.ANNUAL_BILLING')}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', width: '40%', fontWeight: 'bold' }}>
                            $ {annualBilling?.toLocaleString()}
                        </Typography>
                    </Box>
                    {/* INC JURISDICTION */}
                    {isIncJurisdiction && isIncorporated &&
                        (<Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '80%', maxHeight: '24px' }}>
                            <Typography sx={{ fontSize: '14px', width: '60%' }}>
                                {t('COMPANY.JURISDICTION')}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', width: '40%', fontWeight: 'bold', overflow: 'hidden' }}>
                                {isIncJurisdiction}
                            </Typography>
                        </Box>)
                    }
                    {/* IS INCORPORATED */}
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '80%', maxHeight: '30px' }}>
                        <Typography sx={{ fontSize: '14px', width: '60%' }}>
                            {t('COMPANY_CARD.INCORPORATED')}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', width: '40%', fontWeight: 'bold' }}>
                            {isIncorporated ? t('COMPANY.YES') : t('COMPANY.NO')}
                        </Typography>
                    </Box>
                    {/* FUNDATION YEAR */}
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '80%', maxHeight: '30px' }}>
                        <Typography sx={{ fontSize: '14px', width: '60%' }}>
                            {t('CREATE_COMPANY.FOUNDATION_YEAR')}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', width: '40%', fontWeight: 'bold' }}>
                            {fundationYear?.split('T')[0]}
                        </Typography>
                    </Box>
                    {/* DESCRIPCIÓN */}
                    <Typography sx={{ fontSize: '14px', maxHeight: '105px', minHeight: '105px', mt: isIncorporated ? 2 : 5, width: '85%', overflow: 'auto', overflowX: 'hidden' }}>
                        {desc}
                    </Typography>

                </Box > : null}
            </Box >
            {/* LINK TREE */}
            <Button
                variant="text"
                onClick={() => window.open(link, '_blank')}
                sx={{ mt: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
            >
                <InsertLinkIcon sx={{ color: actualThemeLight ? null : '#e6a3bd' }} />
                <Typography sx={{ fontSize: '14px', color: actualThemeLight ? null : '#e6a3bd' }}>
                    LinkTree
                </Typography>
            </Button>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                        <Button variant='contained' onClick={handleDelete}>{t('MY_OFFERINGS.DELETE')}</Button>
                    </Box>
                </Box>
            </Modal>
            {
                seeMore &&
                <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    onClick={() => { dispatch(push(`/company-detail/${id}`)) }}
                >
                    {t('INVERSOR_VIEW.VIEW')} +
                </Button>
            }
        </Card >
    </Fragment >
}

export default ActiveCompanyCard