import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Box, Tab, Tabs, Typography, CircularProgress, Tooltip, Pagination, MenuItem, Divider, Menu } from '@mui/material'
import { useDispatch } from "react-redux"
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import { GET_EVENTS } from '../../Queries/Event'
import { useLazyQuery } from '@apollo/client';
import i18n from "i18next";
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/pt';
import EventModal from '../EventModal/EventModal'
import IndividualEvent from './IndividualEvent'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info'
import { useTheme } from '@mui/styles'
import "./index.css"

const CalendarComponent = () => {
    const { t } = useTranslation()
    const selectedLanguage = i18n.language
    moment.locale(i18n.language);
    moment.updateLocale("en-gb", {
        week: {
            dow: 1,
            doy: 4,
        },
    });
    const localizer = momentLocalizer(moment)

    const [getEvents, { data: eventsData, loading: eventsLoading }] = useLazyQuery(GET_EVENTS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    const messages = {
        allDay: t('CALENDAR.ALL_DAY'),
        previous: t('CALENDAR.PREVIOUS'),
        next: t('CALENDAR.NEXT'),
        today: t('CALENDAR.TODAY'),
        month: t('CALENDAR.MONTH'),
        week: t('CALENDAR.WEEK'),
        day: t('CALENDAR.DAY'),
        agenda: t('CALENDAR.AGENDA'),
        date: t('CALENDAR.DATE'),
        time: t('CALENDAR.TIME'),
        event: t('CALENDAR.EVENT'),
        showMore: (total) => `+ (${total}) ${t('CALENDAR.EVENTS')}`
    }

    useEffect(() => {
        getEvents()
    }, [getEvents])

    function createEventsArrayFromDB(eventsData) {
        const events = [];
        const eventData = eventsData?.events;
        for (let i = 0; i < eventData?.length; i++) {
            const startDate = new Date(eventData[i]?.startDate);
            const endDate = eventData[i]?.endDate ? new Date(eventData[i]?.endDate) : new Date(startDate);

            // Set time components to zero to ignore the time
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            // Increment endDate and startDate by one day as we are considering all-day events
            startDate.setDate(startDate.getDate() + 1);
            endDate.setDate(endDate.getDate() + 2);

            const event = {
                title: selectedLanguage === 'en' ? eventData[i]?.titleEn :
                    selectedLanguage === 'es' ? eventData[i]?.titleEs :
                        selectedLanguage === 'pt' && eventData[i]?.titlePt,
                allDay: true,
                start: startDate.toISOString().split('T')[0],
                end: endDate.toISOString().split('T')[0],
                originalData: eventData[i]
            };

            events.push(event);
        }
        return events;
    }


    const events = createEventsArrayFromDB(eventsData)

    const dispatch = useDispatch()

    const [value, setValue] = useState(0); // el valor inicial de la pestaña seleccionada es 0

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNewsClick = () => {
        dispatch(push('/dao/news'))
    }

    const handleCalendarClick = () => {
        dispatch(push('/dao/calendar'))
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }

    const [modalEvent, setModalEvent] = useState(false)
    const handleCloseEvent = () => {
        setModalEvent(false)
        setSelectedEvent(undefined)
    }

    const [selectedEvent, setSelectedEvent] = useState(undefined)

    useEffect(() => {
        if (selectedEvent !== undefined) {
            setModalEvent(true)
        }
    }, [selectedEvent])


    const components = {
        event: (props) => {
            if (props.event.originalData.fund !== null) {
                return (
                    <>
                        <Tooltip title={
                            selectedLanguage === 'en' ? props.event.originalData.detailEn :
                                selectedLanguage === 'es' ? props.event.originalData.detailEs :
                                    selectedLanguage === 'pt' && props.event.originalData.detailPt}>
                            <Typography onClick={() => { setSelectedEvent(props.event.originalData) }} sx={{ fontSize: '12px', color: 'white', fontWeight: 'bold' }}>
                                {props.event.originalData.fund.name} - {props.event.title}
                            </Typography>
                        </Tooltip>

                    </>
                )
            }
            if (props.event.originalData.fund === null) {
                return (
                    <Tooltip title={
                        selectedLanguage === 'en' ? props.event.originalData.detailEn :
                            selectedLanguage === 'es' ? props.event.originalData.detailEs :
                                selectedLanguage === 'pt' && props.event.originalData.detailPt}>
                        <Typography onClick={() => { setSelectedEvent(props.event.originalData) }} sx={{ fontSize: '12px', color: 'white', fontWeight: 'bold' }}>
                            {selectedLanguage === 'en' ? "DAO Event" :
                                selectedLanguage === 'es' ? "Evento DAO" :
                                    selectedLanguage === 'pt' && "Evento DAO"
                            } - {props.event.title}
                        </Typography>
                    </Tooltip>
                )
            }

            return null
        },
    }


    const [screenSize, setScreenSize] = useState('');

    const updateScreenSize = () => {
        if (window.innerWidth < 768) {
            setScreenSize('xs');
        } else {
            setScreenSize('md');
        }
    };

    useEffect(() => {
        updateScreenSize();
        window.addEventListener('resize', updateScreenSize);
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);


    const [page, setPage] = React.useState(1);
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const eventsPerPage = 4
    const startIndex = (page - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    const displayedEvents = eventsData?.events.slice(startIndex, endIndex);
    const amountOfPages = eventsData?.events?.length / eventsPerPage
    const [filter, setFilter] = React.useState('all');

    const filterEvents = (filter) => {
        if (filter === 'all') {
            return events;
        } else if (filter === 'dao') {
            return events?.filter(event => !event.originalData?.fund);
        } else if (filter === 'fund') {
            return events?.filter(event => event.originalData?.fund);
        } else if (filter === 'Collecting') {
            return events?.filter(event => event.originalData?.titleEn === 'Collecting');
        } else if (filter === 'Published') {
            return events?.filter(event => event.originalData?.titleEn === 'Published');
        } else if (filter === 'Open') {
            return events?.filter(event => event.originalData?.titleEn === 'Open');
        } else if (filter === 'Assessment') {
            return events?.filter(event => event.originalData?.titleEn === 'Assessment');
        } else if (filter === 'Governance') {
            return events?.filter(event => event.originalData?.titleEn === 'Governance');
        } else if (filter === 'Vesting') {
            return events?.filter(event => event.originalData?.titleEn === 'Vesting');
        } else if (filter === 'Execution') {
            return events?.filter(event => event.originalData?.titleEn === 'Execution');
        } else if (filter === 'ROI') {
            return events?.filter(event => event.originalData?.titleEn === 'ROI');
        } else {
            return events;
        }
    };

    const filteredEvents = filterEvents(filter);
    const [anchorEl, setAnchorEl] = useState(null);
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
    const timerRef = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubmenuAnchorEl(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    const handleSubmenuOpen = (event) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setSubmenuAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = (event) => {
        const relatedTarget = event.relatedTarget;
        if (
            !relatedTarget ||
            (!relatedTarget.getAttribute('aria-haspopup') &&
                relatedTarget.tagName.toLowerCase() !== 'li')
        ) {
            timerRef.current = setTimeout(() => {
                setSubmenuAnchorEl(null);
            }, 200);
        }
    };

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Fragment>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="pestañas">
                    <Tab label={t('ADMIN.CALENDAR')} onClick={handleCalendarClick} />
                    <Tab label={t('ADMIN.DAO_NEWS')} onClick={handleNewsClick} />
                </Tabs>
            </Box>
            <Box>
                {eventsLoading ?
                    <Box width={'100%'} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50vh'
                    }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        {screenSize === 'md' ?
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
                                    <>
                                        <Box sx={{
                                            width: '20%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                            cursor: 'pointer',
                                            mb: 0.4
                                        }} onClick={handleClick}>
                                            <FilterListIcon />
                                            <Typography
                                                sx={{
                                                    width: '150px',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    ml: 1,
                                                }}
                                            >
                                                {t('OFFERINGS_TABLE.FILTER')}
                                            </Typography>
                                            {filter !== 'all' ? (
                                                <Tooltip title={t('FUND_SEARCH_VIEW.FILTER_APPLIED')}>
                                                    <InfoIcon
                                                        fontSize="medium"
                                                        sx={{
                                                            position: 'absolute',
                                                            mr: 10
                                                        }}
                                                        color="primary"
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </Box>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            onMouseLeave={handleClose}
                                        >
                                            <MenuItem onClick={() => setFilter('all')}>{t('CALENDAR.FILTER_ALL')}</MenuItem>
                                            <Divider />
                                            <MenuItem onClick={() => setFilter('dao')}>{t('CALENDAR.FILTER_DAO')}</MenuItem>
                                            <MenuItem onClick={() => setFilter('fund')}>{t('CALENDAR.FILTER_FUND')}
                                                <MenuItem
                                                    onMouseEnter={handleSubmenuOpen}
                                                    onMouseLeave={handleSubmenuClose}
                                                >
                                                    <KeyboardArrowDownIcon />
                                                    <Menu
                                                        anchorEl={submenuAnchorEl}
                                                        open={Boolean(submenuAnchorEl)}
                                                        onClose={handleSubmenuClose}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        getContentAnchorEl={null}
                                                        MenuListProps={{
                                                            onMouseEnter: handleSubmenuOpen,
                                                            onMouseLeave: handleSubmenuClose,
                                                        }}
                                                        style={{ marginTop: '5px' }} // Adjust this value based on your layout
                                                    >
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Collecting'); }}>
                                                            {t('CALENDAR.FUND_STATUS_1')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Published') }}>
                                                            {t('CALENDAR.FUND_STATUS_2')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Open') }}>
                                                            {t('CALENDAR.FUND_STATUS_3')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Assessment') }}>
                                                            {t('CALENDAR.FUND_STATUS_4')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Governance') }}>
                                                            {t('CALENDAR.FUND_STATUS_5')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Vesting') }}>
                                                            {t('CALENDAR.FUND_STATUS_6')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('Execution') }}>
                                                            {t('CALENDAR.FUND_STATUS_7')}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => { e.stopPropagation(); setFilter('ROI') }}>
                                                            {t('CALENDAR.FUND_STATUS_8')}
                                                        </MenuItem>
                                                    </Menu>
                                                </MenuItem>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                </Box>
                                <Calendar
                                    localizer={localizer}
                                    events={filteredEvents}
                                    culture={i18n.language}
                                    messages={messages}
                                    views={['month', 'week', 'day']}
                                    tooltipAccessor={'false'}
                                    startAccessor="start"
                                    endAccessor="end"
                                    allDayAccessor="allDay"
                                    components={components}
                                    eventPropGetter={event => {
                                        let backgroundColor = actualThemeLight ? "white" : '#1D1D1D';
                                        if (event?.originalData?.fund === null) {
                                            backgroundColor = "#0f80d1";
                                        } else {
                                            backgroundColor = "#9A1D4C";
                                        }
                                        return {
                                            style: { backgroundColor }
                                        }
                                    }}
                                    style={{
                                        height: 570,
                                        marginTop: '5px',
                                        marginLeft: "40px",
                                        marginRight: "40px",
                                        backgroundColor: actualThemeLight ? "white" : '#1D1D1D',
                                        border: '3px solid #7c7c7c',
                                        borderRadius: '15px',
                                        padding: '10px',
                                        textTransform: 'capitalize',
                                    }} />
                            </>
                            :
                            <>
                                <Box>
                                    {displayedEvents?.map((event) => (
                                        <IndividualEvent key={event.id} data={event} />
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', jc: 'center', mt: 1 }}>
                                    <Pagination count={amountOfPages} variant="outlined" page={page} onChange={handleChangePage} />
                                </Box>
                            </>
                        }

                    </>
                }
            </Box>
            {
                modalEvent && (
                    <EventModal
                        open={modalEvent}
                        handleClose={handleCloseEvent}
                        data={selectedEvent}
                    />
                )
            }
        </Fragment>
    )
}

export default CalendarComponent