import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function TransactionsCard({ transaction }) {
  const finalDate = transaction?.date?.split('T')[0]
  const styles = {
    padding: '10px',
    borderBottom: '1px solid var(--borderGray)',
    borderRadius: '5px',
    height: '100%',
    marginBottom: '5px'
  }
  const { t } = useTranslation()
  const startDate = moment(finalDate);
  const startDay = startDate.format('DD');
  const startMonth = startDate.format('MM');
  const isCoreTeam = transaction?.type?.includes('Core Team')
  const [embededToken, setEmbededToken] = useState(undefined)

  useEffect(() => {
    setEmbededToken(transaction?.type?.split('-')[1])
  }, [transaction])

  return (
    <Box className="transactions-card" sx={styles}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontSize: '14px',
            width: '25%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            width: '25%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center'
          }}>
          {transaction?.type?.split('-')[0]}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            width: '25%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center'
          }}>
          {`${transaction?.offerPurchase?.fund?.name || 'FIRST MVP FUND'}`}
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '14px',
            width: '25%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {`${transaction?.amount} ${isCoreTeam ? 'tstAlli' : embededToken || transaction?.offerPurchase?.fund?.token[0]?.symbol || 'FakeUSD'}`}
        </Typography>
      </Box>
    </Box>
  )
}


export default TransactionsCard