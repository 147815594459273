import { gql } from '@apollo/client'

export const LINK_WALLET = gql`
  mutation linkWallet($userID: ID!, $walletID: ID!) {
    updateUsers(
      where: { id: $userID }
      connect: { wallet: { where: { node: { id: $walletID } } } }
    ) {
      users {
        userName
      }
    }
  }
`
export const CREATE_WALLET = gql`
  mutation createWallet(
    $walletID: ID!
    $address: String!
    $stakeAddress: String!
  ) {
    createWallets(
      input: { id: $walletID, address: $address, stakeAddress: $stakeAddress }
    ) {
      wallets {
        id
        address
      }
    }
  }
`

export const GET_WALLET = gql`
  query getWallet($userID: ID!) {
    users(where: { id: $userID }) {
      wallet {
        id
        address
        stakeAddress
      }
    }
  }
`

export const GET_TOKENS = gql`
  query {
    tokens(
      options: { sort: { symbol: ASC } }
      where: { symbol_IN: ["DVC", "tstALLI"] }
    ) {
      id
      logo
      symbol
      maxCap
      policyId
      description
      marketprice
      encodedName
      fingerprint
    }
  }
`

export const UPDATE_WALLET = gql`
  mutation ($walletId: ID!, $newAddress: String!) {
    updateWallets(where: { id: $walletId }, update: { address: $newAddress }) {
      wallets {
        id
        address
      }
    }
  }
`
