import React from 'react'
import useStyles from './style'
import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  Avatar,
  Typography,
} from '@mui/material'
import { push } from 'connected-react-router'
import WelcomeLogo from '../../assets/icons/welcomeModal/welcomeModal.svg'
import { useState } from 'react'
import Logo from '../../assets/icons/TokenAlliesIcon.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { DISABLE_MODAL_SHOW } from '../../Queries/Users'
import { useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const WelcomeDialog = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { user } = useAuth0()

  const [disableModal] = useMutation(DISABLE_MODAL_SHOW, { variables: { userId: user?.email || user?.sub } })

  const closeModal = async () => {
    await disableModal()
    await handleClose()
  }
  const classes = useStyles()
  const dispatch = useDispatch()
  const [page, setpage] = useState(1)

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent>
        <>
          {page === 1 &&
            <Box sx={{ maxWidth: '400px', width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                <Avatar src={Logo} />
                <Typography
                  onClick={async () => {
                    await closeModal()
                    await dispatch(push('/dashboard'))
                  }}
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer'
                  }}>
                  {t('PERFIL_CONFIG_VIEW.WELCOME_SKIP')}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '35vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <img src={WelcomeLogo} style={{
                  height: '200px',
                  width: '400px'
                }} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                <Typography
                  sx={{
                    color: '#DB417B',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    flexGrow: 1,
                    textAlign: 'center',
                  }}>
                  {t('PERFIL_CONFIG_VIEW.WELCOME_1_A')}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  flexGrow: 1,
                  textAlign: 'center',
                  mt: 2
                }}>
                {t('PERFIL_CONFIG_VIEW.WELCOME_1_B')}
              </Typography>

              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 4
                }}>
                <FiberManualRecordIcon sx={{ color: '#9A1D4C' }} />
                <FiberManualRecordIcon sx={{ color: '#E796B4', ml: 2, mr: 2 }} />
                <FiberManualRecordIcon sx={{ color: '#E796B4' }} />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}>
                <Box
                  onClick={() => { setpage(2) }}
                  sx={{
                    backgroundColor: '#DB417B',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}>
                  <ArrowForwardIosIcon fontSize='large' sx={{ color: 'white' }} />
                </Box>
              </Box>
            </Box>
          }
          {page === 2 &&
            <Box sx={{ maxWidth: '400px', width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                <ArrowBackIosIcon sx={{ cursor: 'pointer' }} onClick={() => { setpage(1) }} />
                <Typography
                  onClick={async () => {
                    await closeModal()
                    await dispatch(push('/dashboard'))
                  }}
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer'
                  }}>
                  {t('PERFIL_CONFIG_VIEW.WELCOME_SKIP')}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '35vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <img src={WelcomeLogo} style={{
                  height: '200px',
                  width: '400px'
                }} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                <Typography
                  sx={{
                    color: '#DB417B',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    flexGrow: 1,
                    textAlign: 'center',
                  }}>
                  {t('PERFIL_CONFIG_VIEW.WELCOME_2_A')}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  flexGrow: 1,
                  textAlign: 'center',
                  mt: 2
                }}>
                {t('PERFIL_CONFIG_VIEW.WELCOME_2_B')}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 4
                }}>
                <FiberManualRecordIcon sx={{ color: '#E796B4' }} />
                <FiberManualRecordIcon sx={{ color: '#9A1D4C', ml: 2, mr: 2 }} />
                <FiberManualRecordIcon sx={{ color: '#E796B4' }} />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}>
                <Box
                  onClick={() => { setpage(3) }}
                  sx={{
                    backgroundColor: '#DB417B',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}>
                  <ArrowForwardIosIcon fontSize='large' sx={{ color: 'white' }} />
                </Box>
              </Box>
            </Box>
          }
          {page === 3 &&
            <Box sx={{ maxWidth: '400px', width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                <ArrowBackIosIcon sx={{ cursor: 'pointer' }} onClick={() => { setpage(2) }} />
                <Typography
                  onClick={async () => {
                    await closeModal()
                    await dispatch(push('/dashboard'))
                  }}
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer'
                  }}>
                  {t('PERFIL_CONFIG_VIEW.WELCOME_SKIP')}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '35vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <img src={WelcomeLogo} style={{
                  height: '200px',
                  width: '400px'
                }} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                <Typography
                  sx={{
                    color: '#DB417B',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    flexGrow: 1,
                    textAlign: 'center',
                  }}>
                  {t('PERFIL_CONFIG_VIEW.WELCOME_3_A')}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  flexGrow: 1,
                  textAlign: 'center',
                  mt: 2
                }}>
                {t('PERFIL_CONFIG_VIEW.WELCOME_3_B')}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 4
                }}>
                <FiberManualRecordIcon sx={{ color: '#E796B4' }} />
                <FiberManualRecordIcon sx={{ color: '#E796B4', ml: 2, mr: 2 }} />
                <FiberManualRecordIcon sx={{ color: '#9A1D4C' }} />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '5vh',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}>
                <Box
                  onClick={async () => {
                    await closeModal()
                    await dispatch(push('/profile-settings'))
                  }}
                  sx={{
                    backgroundColor: '#DB417B',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}>
                  <ArrowForwardIosIcon fontSize='large' sx={{ color: 'white' }} />
                </Box>
              </Box>
            </Box>
          }
        </>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeDialog
