import React from 'react'
import { Grid, Button, Typography, Box, LinearProgress, Tooltip, Avatar, IconButton, useTheme } from '@mui/material'
import TokenCard1 from '../../components/Wallet/TokenCard1'
import ADACard from '../Wallet/ADACard';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardanoLogo from '../../assets/icons/cardano-ada-logo.png'
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_WALLET, CREATE_WALLET, LINK_WALLET, GET_TOKENS } from '../../Queries/Wallet'
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { showMessage } from '../../store/actions/snackBarActions'
import { Address, Value, TransactionUnspentOutput } from '@emurgo/cardano-serialization-lib-asmjs'

const CardanoWallet = ({ onChangeAddress }) => {
    const { t } = useTranslation()
    const { user } = useAuth0()
    const [Balance, setBalance] = useState(undefined)
    const [getWallet, { data: walletFromDb }] = useLazyQuery(GET_WALLET)
    const [createWallet] = useMutation(CREATE_WALLET)
    const [linkWallet] = useMutation(LINK_WALLET)
    const [loading, setLoading] = useState(true)
    const [API, setAPI] = useState(null)
    const [changeAddress, setChangeAddress] = useState(undefined)
    const [walletSaved, setWalletSaved] = useState(false)
    const [walletEnabled, setWalletEnabled] = useState(undefined)
    const [Utxos, setUtxos] = useState(undefined)
    const [showWallets, setShowWallets] = useState(false)
    const [wallets, setWallets] = useState([])
    const [selectedWallet, setSelectedWallet] = useState(undefined)
    const [countRetry, setCountRetry] = useState(0)
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const incrementRetry = () => {
        setCountRetry(countRetry + 1)
    }

    const handleWalletSelect = async (obj) => {
        await createLocalStorageField(obj)
        await refreshData()
        await setShowWallets(!showWallets)
        await incrementRetry()
    }

    function createLocalStorageField(value) {
        localStorage.setItem('wallet', JSON.stringify(value))
    }

    function getLocalStorageField(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    const [localStorageWallet, setLocalStorageWallet] = useState(getLocalStorageField('wallet'));

    useEffect(() => {
        const interval = setInterval(() => {
            setLocalStorageWallet(getLocalStorageField('wallet'));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function deleteLocalStorageField() {
        localStorage.removeItem('wallet')
    }

    useEffect(() => {
        if (localStorageWallet !== null) {
            setSelectedWallet(localStorageWallet)
        } else if (localStorageWallet === null) {
            setSelectedWallet(null)
        }
    }, [localStorageWallet])

    useEffect(() => {
        onChangeAddress(changeAddress)
    }, [changeAddress])

    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])

    const handleConnectButtonClick = (selectedWallet) => {
        handleWalletSelect(selectedWallet);
    }

    const getUtxos = async () => {
        let utxos = []
        if (API !== null) {
            try {
                const rawUtxos = await API?.getUtxos()

                for (const rawUtxo of rawUtxos) {
                    const utxo = TransactionUnspentOutput.from_bytes(
                        Buffer.from(rawUtxo, 'hex')
                    )
                    const input = utxo.input()
                    const txid = Buffer.from(
                        input.transaction_id().to_bytes(),
                        'utf8'
                    ).toString('hex')
                    const txindx = input.index()
                    const output = utxo.output()
                    const amount = output.amount().coin().to_str()
                    const multiasset = output.amount().multiasset()
                    let multiAssetStr = ''

                    if (multiasset) {
                        const keys = multiasset.keys()
                        const N = keys.len()

                        for (let i = 0; i < N; i++) {
                            const policyId = keys.get(i)
                            const policyIdHex = Buffer.from(
                                policyId.to_bytes(),
                                'utf8'
                            ).toString('hex')

                            const assets = multiasset.get(policyId)
                            const assetNames = assets.keys()
                            const K = assetNames.len()

                            for (let j = 0; j < K; j++) {
                                const assetName = assetNames.get(j)
                                const assetNameString = Buffer.from(
                                    assetName.name(),
                                    'utf8'
                                ).toString()
                                const assetNameHex = Buffer.from(
                                    assetName.name(),
                                    'utf8'
                                ).toString('hex')
                                const multiassetAmt = multiasset.get_asset(policyId, assetName)
                                multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                            }
                        }
                    }

                    const obj = {
                        txid: txid,
                        txindx: txindx,
                        amount: amount,
                        str: `${txid} #${txindx} = ${amount}`,
                        multiAssetStr: multiAssetStr,
                        TransactionUnspentOutput: utxo,
                    }
                    utxos.push(obj)
                }
                setUtxos(utxos)
            } catch (err) {
                setSelectedWallet(undefined)
                //Err
            }
        }
    }

    const [netId, setNetId] = useState(undefined)
    const appropiateNetworkIdIdentified = 0
    const [isCorrectNetworkSelected, setIsCorrectNetworkSelected] = useState(null)

    useEffect(() => {
        if (netId !== undefined) {
            if (appropiateNetworkIdIdentified === netId) {
                setIsCorrectNetworkSelected(true)
            } else {
                setIsCorrectNetworkSelected(false)
            }
        }
    }, [appropiateNetworkIdIdentified, netId])

    const getNetId = async () => {
        if (API !== null) {
            try {
                const id = await API.getNetworkId()
                setNetId(id)
            } catch (err) {
                setNetId(undefined)
            }
        }
    }

    const getChangeAddress = async () => {
        if (API !== null) {
            try {
                const raw = await API.getChangeAddress()
                const changeAddressObj = Address.from_bytes(
                    Buffer.from(raw, 'hex')
                ).to_bech32()
                setChangeAddress(changeAddressObj)
            } catch (err) {
                setSelectedWallet(undefined)
                //Err
            }
        }
    }

    const getBalance = async () => {
        if (API !== null) {
            try {
                const balanceCBORHex = await API.getBalance()
                const balance = Value.from_bytes(Buffer.from(balanceCBORHex, 'hex'))
                    .coin()
                    .to_str()
                setBalance(balance)
            } catch (err) {
                setSelectedWallet(undefined)
                //Err
            }
        }
    }

    const enableWallet = async () => {
        const walletKey = selectedWallet
        try {
            setAPI(await window.cardano[walletKey].enable())
        } catch (err) {
            setSelectedWallet(undefined)
            //Err
        }
        return checkIfWalletEnabled()
    }

    const checkIfWalletEnabled = async () => {
        let walletIsEnabled = false
        try {
            const walletName = localStorageWallet
            walletIsEnabled = await window.cardano[walletName].isEnabled()
            setWalletEnabled(walletIsEnabled)
        } catch (err) {
            //Err
            setSelectedWallet(undefined)
        }
        return walletIsEnabled
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshData();
        }, 2000);
        return () => {
            clearInterval(intervalId);
        };
    });

    async function checkEnabledStatus(walletNames) {
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}:`, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );

        setWalletEnabled(anyEnabled);
    }

    useEffect(() => {
        if (localStorageWallet !== null) {
            checkEnabledStatus(wallets)
            const intervalId = setInterval(() => {
                checkEnabledStatus(wallets)
            }, 5000)

            return () => clearInterval(intervalId)
        }
    }, [wallets, localStorageWallet])

    const refreshData = async () => {
        try {
            if (walletEnabled && API !== null) {
                await getBalance()
                await getNetId()
                await getChangeAddress()
                await getUtxos()
                setLoading(false)
            }
        } catch (err) {
            setSelectedWallet(undefined)
            deleteLocalStorageField()
            //Err
        }
    }

    useEffect(() => {
        refreshData()
        const intervalId = setInterval(() => {
            if ((changeAddress === undefined || API !== null) && walletEnabled) {
                refreshData()
            }
        }, 5000)

        return () => clearInterval(intervalId)
    }, [changeAddress, API, walletEnabled, localStorageWallet])

    useEffect(() => {
        getWallet({
            variables: {
                userID: user?.email || user?.sub,
            },
        })
    }, [user?.email])

    // EFFECT FOR WALLET CONNECTION
    useEffect(() => {
        if (walletFromDb?.users[0]?.wallet != null) return

        if (
            changeAddress !== '' &&
            changeAddress !== undefined &&
            walletSaved === false
        ) {
            const walletID = uuidv4()
            setWalletSaved(true)
            createWallet({
                variables: {
                    walletID: walletID,
                    address: changeAddress,
                    stakeAddress: changeAddress,
                },
            })
                // eslint-disable-next-line
                .then((res) =>
                    linkWallet({
                        variables: {
                            userID: user?.email || user?.sub,
                            walletID: walletID,
                        },
                    })
                )
                // eslint-disable-next-line
                .catch((err) => {
                    showMessage({
                        message: t('GENERAL.ERROR_OCCURRED'),
                        variant: 'error',
                    })
                })
        }
    }, [changeAddress, Balance])

    useEffect(() => {
        if (selectedWallet !== undefined) {
            enableWallet()
        }
    }, [selectedWallet, localStorageWallet])

    setTimeout(clearLoading, 3000);

    function clearLoading() {
        setLoading(false)
    }

    const [getAllTokens, { data: tokensData }] = useLazyQuery(GET_TOKENS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllTokens()
    }, [getAllTokens])

    function findMatchingTokens(databaseTokens, walletTokens) {
        if (!databaseTokens || !walletTokens) {
            return [];
        }

        const result = new Map();

        for (const dbToken of databaseTokens) {
            const policyIdToMatch = dbToken.policyId;

            for (const walletToken of walletTokens) {
                const occurrences = findAllOccurrences(walletToken.multiAssetStr, policyIdToMatch);

                for (const occurrence of occurrences) {
                    const amount = extractAmountFromOccurrence(occurrence);

                    if (!result.has(policyIdToMatch)) {
                        result.set(policyIdToMatch, amount);
                    } else {
                        result.set(policyIdToMatch, result.get(policyIdToMatch) + amount);
                    }
                }
            }
        }

        return Array.from(result.entries()).map(([policyId, amount]) => ({ policyId, amount }));
    }

    function findAllOccurrences(input, policyId) {
        const regex = /\+ (\d+) \+.*?(\w{56}).*?\((.*?)\)/g;
        let matches = [];
        let match;

        while ((match = regex.exec(input)) !== null) {
            // eslint-disable-next-line
            const [, amount, tokenPolicyId] = match;

            if (tokenPolicyId === policyId) {
                matches.push(match);
            }
        }

        return matches;
    }

    function extractAmountFromOccurrence(occurrence) {
        const amount = parseInt(occurrence[1]);
        return amount;
    }

    const matchingTokens = findMatchingTokens(tokensData?.tokens, Utxos);

    useEffect(() => {
        if (changeAddress !== undefined) {
            setCountRetry(0)
        }
    }, [changeAddress])

    if (loading) return (
        <>
            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 10, mb: 10 }}>
                <Box sx={{ width: '50%' }}>
                    <LinearProgress />
                </Box>
            </Grid>
        </>
    )

    return (
        <>
            <Grid item xs={12} md={12} className="leftSide">
                <Box>
                    {walletEnabled && localStorageWallet !== null &&
                        <>
                            <Typography sx={{ color: actualThemeLight ? 'black' : 'white' }} variant="h6" fontWeight={'bold'}>
                                Tokens
                            </Typography>
                            <Grid container md={12} xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                <Grid item sx={{ width: '100%', mt: 1 }} md={4} xs={12}>
                                    <ADACard
                                        name="Cardano"
                                        symbol="ADA"
                                        amount={Balance !== undefined ? (Balance / 1000000).toFixed(2) : null}
                                        img={CardanoLogo}
                                    />
                                </Grid>
                                {tokensData?.tokens?.map((token) => {
                                    return (
                                        <Grid key={token?.id} item sx={{ width: '100%', mt: 1 }} md={4} xs={12}>
                                            <TokenCard1
                                                matchingTokens={matchingTokens}
                                                name={token?.description}
                                                symbol={token?.symbol}
                                                policyId={token?.policyId}
                                                img={token?.logo}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            {isCorrectNetworkSelected === false &&
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography sx={{ mt: 2, fontWeight: 'bold', color: '#f7712e' }}>{t('WALLET_VIEW.WRONG_NET')}</Typography>
                                </Box>
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                                <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden', fontSize: '10px', color: actualThemeLight ? 'black' : 'white' }}>
                                    {t('WALLET.CONNECTED')} {localStorageWallet} ({changeAddress})
                                </Typography>

                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 1 }}>

                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        pt: 0.4,
                                        pb: 0.4,
                                        pl: 1,
                                        pr: 1,
                                        ml: 2
                                    }}
                                    onClick={() => {
                                        deleteLocalStorageField()
                                    }}
                                >
                                    {t(
                                        'INVERSOR_VIEW.OFFERING_CARD.ERROR_DISC_WALLET_ACT'
                                    )}
                                </Button>
                            </Box>
                        </>}
                    {
                        (walletEnabled === undefined || localStorageWallet === null) &&
                        <Box sx={{ width: '100%', mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ color: actualThemeLight ? 'black' : 'white' }}>{t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET')}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: countRetry >= 2 ? 0 : 2 }}>
                                <Tooltip title={t('WALLET.SUPPORTED_WALLET')}>
                                    <Button variant='contained' size='small' onClick={() => {
                                        setShowWallets(!showWallets)
                                    }}>
                                        {t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT')}
                                    </Button>
                                </Tooltip>
                                {countRetry >= 2 && (
                                    <Box>
                                        <Tooltip
                                            title={t(
                                                'INVERSOR_VIEW.OFFERING_CARD.WALLET_ERROR_DAPP'
                                            )}
                                        >
                                            <IconButton>
                                                <PriorityHighIcon sx={{ color: 'orange' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                            {showWallets &&
                                <>
                                    {wallets.length === 0 ?
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 3 }}>
                                                <Typography sx={{ pl: 1, pr: 1, pt: 1, color: '#9A1D4C' }}>{t('WALLET_VIEW.NO_WALLET_HELPER1')}</Typography>
                                                <Typography sx={{ pl: 1, pr: 1, pb: 1, color: '#9A1D4C' }}>{t('WALLET_VIEW.NO_WALLET_HELPER2')}</Typography>
                                            </Box>
                                        </Box>
                                        :
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {wallets?.map(key =>
                                                <Box key={key} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 2 }}>
                                                    <Button size='small' sx={{ mt: 1, color: actualThemeLight ? null : '#e6a3bd', borderColor: actualThemeLight ? null : '#e6a3bd' }} variant='outlined' onClick={() => handleConnectButtonClick(key)}>
                                                        <Typography sx={{ ml: 1 }}>{t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT').split(' ')[0]}</Typography>
                                                        <Avatar sx={{ ml: 1 }} src={window.cardano[key].icon} width={24} height={24} alt={key} />
                                                        <Typography sx={{ ml: 1 }}>{key}</Typography>
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                    }
                </Box>
            </Grid>
        </>
    )
}

export default CardanoWallet