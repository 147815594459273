import React, { useEffect } from "react"
import { Fragment } from "react"
import Card from "../../../../components/Card/Card"
import { Box, Typography, Grid, Button, LinearProgress, Link, CircularProgress, Avatar, Zoom, Tooltip as Tool, useTheme } from "@mui/material"
import { useTranslation } from 'react-i18next'
import PersonIcon from '@mui/icons-material/Person'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
const axios = require('axios')
import { useState } from "react"
import { GET_TOKENS } from '../../../../Queries/Wallet'
import InvestModal from "../../../../components/InvestModal/InvestModal"
import TokenAllies from '../../../../../src/assets/icons/TokenAlliesIcon.svg'
import WaitingListModal from "../../../../components/InvestModal/WaitingListModal"
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import i18n from "i18next";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { CartesianGrid, XAxis, YAxis, Bar, BarChart, Tooltip, PieChart, Pie } from 'recharts';
import { ReactComponent as Logo } from '../../../../assets/icons/TokenAlliesIcon.svg'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useLazyQuery } from "@apollo/client"
import CustomTooltipCollectingThresshold from "../../../../components/FundCard/CustomTooltipCollectingThresshold"

const ActiveFundsDashboard = ({ data, dataAll }) => {

    const totalVestingEvents = data?.vesting?.length
    const amountToReach = data?.amountToReach
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const selectedLanguage = i18n.language

    const handleClick = () => {
        dispatch(push(`/fund-detail/${data?.id}`))
    }

    const locStorageRole = localStorage.getItem('role')
    // MODAL INVEST
    const [modalInvest, setModalInvest] = useState(false)
    const [modalWaitingList, setModalWaitingList] = useState(false)
    const handleCloseInvest = () => {
        setModalInvest(false)
    }

    const handleCloseWaitingList = () => {
        setModalWaitingList(false)
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
        },
    }));

    function countAssessedProposals(obj) {
        let count = 0;

        if (obj.proposal && Array.isArray(obj.proposal)) {
            for (const proposal of obj.proposal) {
                if (proposal.status === "assessed") {
                    count++;
                }
            }
        }

        return count;
    }

    const assessedProposalsCount = countAssessedProposals(data);

    function countProposalStatuses(proposals) {
        let totalCount = 0;

        for (const proposal of proposals) {
            const status = proposal.status;

            if (status !== "draft") {
                totalCount++;
            }
        }

        return totalCount;
    }

    const totalProposalCount = countProposalStatuses(data?.proposal);

    function addReqInvestAmount(proposals) {
        let totalReqInvestAmount = 0;
        for (const proposal of proposals) {
            totalReqInvestAmount += proposal.reqInvestAmount;
        }
        return totalReqInvestAmount;
    }

    const totalReqInvestAmount = addReqInvestAmount(data?.proposal);

    function countEmployeesOccurrences(proposals) {
        const employeeCountMap = new Map();
        proposals?.forEach(proposal => {
            const employeesString = proposal.company.employees;
            if (employeesString) {
                if (employeeCountMap.has(employeesString)) {
                    employeeCountMap.set(employeesString, employeeCountMap.get(employeesString) + 1);
                } else {
                    employeeCountMap.set(employeesString, 1);
                }
            }
        });
        const dataEx = Array.from(employeeCountMap).map(([name, qty]) => ({
            name,
            qty,
        }));

        return dataEx;
    }

    const employeesCount = countEmployeesOccurrences(data?.proposal);

    const today = new Date();

    function calculateDaysLeft(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000;
        const start = new Date(startDate);
        const end = new Date(endDate);
        const timeDiff = end.getTime() - start.getTime();
        const daysDiff = Math.ceil(timeDiff / oneDay);
        return daysDiff;
    }

    const collectingEvent = data?.event?.find(event => event?.titleEn === "Collecting");
    const openEvent = data?.event?.find(event => event?.titleEn === "Open");
    let daysLeft = null;

    if (collectingEvent && collectingEvent.endDate && collectingEvent.endDate) {
        const eventDate = new Date(collectingEvent.endDate);
        daysLeft = calculateDaysLeft(today, eventDate);
    }
    if (openEvent && openEvent.endDate && openEvent.endDate) {
        const eventDate = new Date(openEvent.endDate);
        daysLeft = calculateDaysLeft(today, eventDate);
    }


    const [statusValue, setStatusValue] = useState(0)

    useEffect(() => {
        if (data?.status === "Collecting") {
            setStatusValue(1)
        }
        if (data?.status === "Published") {
            setStatusValue(2)
        }
        if (data?.status === "Open") {
            setStatusValue(3)
        }
        if (data?.status === "Assessment") {
            setStatusValue(4)
        }
        if (data?.status === "Governance") {
            setStatusValue(5)
        }
        if (data?.status === "Vesting") {
            setStatusValue(6)
        }
        if (data?.status === "Execution") {
            setStatusValue(7)
        }
        if (data?.status === "ROI") {
            setStatusValue(8)
        }
    }, [data?.status])

    function getEventByStatus(fundObject) {
        const { status, event } = fundObject;

        if (status && event && event.length > 0) {
            const matchingEvent = event.find(e => e.titleEn === status);
            return matchingEvent || null;
        } else {
            return null;
        }
    }

    const matchingEvent = getEventByStatus(data);

    const startDay = matchingEvent?.startDate?.split('-')[2].split('T')[0]
    const startMonth = matchingEvent?.startDate?.split('-')[1]
    const endDay = matchingEvent?.endDate?.split('-')[2].split('T')[0]
    const endMonth = matchingEvent?.endDate?.split('-')[1]


    function getEventByCollecting(fundObject) {
        const { event } = fundObject;

        if (event && event.length > 0) {
            const matchingEvent = event.find(e => e.titleEn === "Collecting");
            return matchingEvent || null;
        } else {
            return null;
        }
    }

    const startingFundDate = getEventByCollecting(data);

    function getEventByVesting(fundObject) {
        const { event } = fundObject;

        if (event && event.length > 0) {
            const roiEvent = event.find(e => e.titleEn === "ROI");
            if (roiEvent) {
                return roiEvent;
            }
            const executionEvent = event.find(e => e.titleEn === "Execution");
            if (executionEvent) {
                return executionEvent;
            }
            const vestingEvent = event.find(e => e.titleEn === "Vesting");
            return vestingEvent || null;
        } else {
            return null;
        }
    }

    const endingFundDate = getEventByVesting(data);
    const overAllstartDay = startingFundDate?.startDate?.split('-')[2].split('T')[0]
    const overAllstartMonth = startingFundDate?.startDate?.split('-')[1]
    const overAllstartYear = startingFundDate?.startDate?.split('-')[0]
    const overAllendDay = endingFundDate?.endDate?.split('-')[2].split('T')[0]
    const overAllendMonth = endingFundDate?.endDate?.split('-')[1]
    const overAllendYear = endingFundDate?.endDate?.split('-')[0]


    const [getAllTokens, { data: tokensData }] = useLazyQuery(GET_TOKENS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        if (data.status === 'Published') { getAllTokens() }
    }, [getAllTokens, data])

    const tstALLIObject = tokensData?.tokens?.find(item => item.symbol === "tstALLI");

    const [totalQuantityHoldersTstAllies, setTotalQuantityHoldersTstAllies] = useState(0);
    const excludedAddress = "addr_test1qq4hg23ralkz9wzv9tn9kd2c2rxrzxckz8fecrdmrc8qltcd7r8mfgmnp3eaaasrds0fd42feweqvwxk8qqk2sy60wus7aaykm";
    const [addressesHoldingtstAlli, setAddressesHoldingtstAlli] = useState(undefined)
    const policyIdToQuerytstAlli = tstALLIObject?.policyId + tstALLIObject?.encodedName
    const [mintedtstALLI, setMintedtestALLI] = useState(undefined)

    const blockFrostAPITest = `https://cardano-preprod.blockfrost.io/api/v0/assets/`
    const testProjectID = 'preprod0YDs6YpK8cM5KFJA7bTZbOAmWVW0D94s'
    const axiosBFClient = axios.create({
        baseURL: blockFrostAPITest,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            project_id: testProjectID,
        },
    })

    function getMintedtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/history`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (data.status === 'Published' && policyIdToQuerytstAlli.length > 7) {
            getMintedtstAlli().then((res) => {
                setMintedtestALLI(res)
            })
        }
    }, [policyIdToQuerytstAlli, data])

    function getMCtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/addresses`)
            .then((res) => res.data)
        return finalRes
    }
    useEffect(() => {
        if (data.status === 'Published' && policyIdToQuerytstAlli.length > 7) {
            getMCtstAlli().then((res) => {
                setAddressesHoldingtstAlli(res)
            })
        }
    }, [policyIdToQuerytstAlli, data])

    useEffect(() => {
        const filteredArray = addressesHoldingtstAlli?.filter(item => item.address !== excludedAddress);
        const sum = filteredArray?.reduce((acc, curr) => acc + parseInt(curr.quantity, 10), 0);
        setTotalQuantityHoldersTstAllies(sum);
    }, [addressesHoldingtstAlli, excludedAddress]);

    function sumMintedAmountstAlli(data) {
        if (!data || !Array.isArray(data)) {
            return 0;
        }

        let totalAmount = 0;
        for (const item of data) {
            if (item && item.action === "minted") {
                totalAmount += parseInt(item.amount);
            }
        }
        return totalAmount;
    }

    const totalMintedAmountTstAlli = sumMintedAmountstAlli(mintedtstALLI);
    // eslint-disable-next-line
    const [percentHoldingAlli, setPercentHoldingAlli] = useState(undefined)

    useEffect(() => {
        if (data.status === 'Published') {
            setPercentHoldingAlli((totalQuantityHoldersTstAllies / totalMintedAmountTstAlli) * 100)
        }
    }, [totalQuantityHoldersTstAllies, totalMintedAmountTstAlli, data])

    function calculateTotalReportLength(proposals) {
        let totalReportLength = 0;

        proposals?.forEach((proposal) => {
            // Check if the 'report' property exists and is an array
            if (proposal?.report && Array.isArray(proposal?.report)) {
                totalReportLength += proposal?.report.length;
            }
        });

        return totalReportLength;
    }

    const totalReportLength = calculateTotalReportLength(data?.proposal);

    function getCollectingStartDateAndMonthsPassed(events) {
        const collectingEvent = events.find(event => event.titleEn === 'Collecting');
        if (!collectingEvent) {
            return null;
        }

        const collectingStartDate = new Date(collectingEvent.startDate);
        const today = new Date();

        const monthsPassed = Math.floor((today.getFullYear() - collectingStartDate.getFullYear()) * 12 + (today.getMonth() - collectingStartDate.getMonth()));

        return {
            startDate: collectingStartDate,
            monthsPassed: monthsPassed
        };
    }

    const collectingInfo = getCollectingStartDateAndMonthsPassed(data?.event);

    function calculateTotalVoteCount(proposals) {
        let totalVoteCount = 0;

        proposals?.forEach(proposal => {
            if (proposal?.voteCount !== null) {
                totalVoteCount += proposal.voteCount;
            }
        });

        return totalVoteCount;
    }

    const totalVoteCount = calculateTotalVoteCount(data?.proposal);

    function calculateAssessedTotal(reqInvestments) {
        const assessedProposals = reqInvestments.filter(proposal => proposal.status === "assessed");
        const totalAmount = assessedProposals.reduce((sum, proposal) => sum + proposal.reqInvestAmount, 0);
        return totalAmount;
    }

    const assessedTotal = calculateAssessedTotal(data?.proposal);

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    function countValidOfferPurchases(offerPurchases) {

        const validOfferPurchases = offerPurchases?.filter(
            offer => !excludedStatuses?.includes(offer?.status)
        );

        return validOfferPurchases?.length;
    }
    const countOfValidOffPur = countValidOfferPurchases(dataAll?.offerPurchase);

    function calculateAverageAmount(validOfferPurchases) {
        if (validOfferPurchases.length === 0) {
            return 0;
        }

        const totalAmount = validOfferPurchases.reduce(
            (sum, offer) => sum + offer.amount,
            0
        );

        const averageAmount = totalAmount / validOfferPurchases?.length;
        return averageAmount;
    }

    const validOfferPurchases = dataAll?.offerPurchase?.filter(
        offer => !excludedStatuses?.includes(offer?.status)
    );

    const averageAmount = calculateAverageAmount(validOfferPurchases);


    function calculateTotalInvestment(validOfferPurchases) {
        if (validOfferPurchases.length === 0) {
            return 0;
        }

        const totalAmount = validOfferPurchases.reduce(
            (sum, offer) => sum + offer.amount,
            0
        );

        return totalAmount;
    }

    const totalInvestment = calculateTotalInvestment(validOfferPurchases);

    // const [thresholdMet, setThresholdMet] = useState(false)

    // useEffect(() => {
    //     if (totalInvestment >= (data?.amountToReach * data?.successThreshold / 100)) {
    //         setThresholdMet(true)
    //     } else {
    //         setThresholdMet(false)
    //     }
    // }, [totalInvestment, data])

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    const dataChartCollecting = [
        { name: 'Raised', value: totalInvestment, fill: '#db417b' },
        { name: '', value: data?.amountToReach - totalInvestment, fill: 'gray' },
    ];
    const dataChartCollectingThresshold = [
        { name: 'Thresshold', value: data?.successThreshold, fill: 'green' },
        { name: 'Thresshold', value: 100 - data?.successThreshold, fill: 'gray' },
    ];

    return <Fragment>
        <Card key={data?.id} color='#FFF'>
            <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
                {/* HEADER */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: { md: 'row', xs: 'column' },
                    justifyContent: 'left',
                    fontSize: '16px',
                    backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f",
                    p: 1,
                    borderRadius: '5px',
                }}>
                    <Box sx={{
                        width: { md: '50%', xs: '100%' },
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'left', justifyContent: 'center'
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                            {data?.image !== '' ? <Avatar
                                sx={{ width: 38, height: 38, mr: 1 }}
                                src={data?.image} /> :
                                <Avatar
                                    sx={{ width: 38, height: 38, mr: 1 }}
                                    src={TokenAllies} />}
                            <Box sx={{
                                display: 'flex', flexDirection: 'column',
                                alignItems: 'start', justifyContent: 'center'
                            }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                                    {data?.name}
                                </Typography>
                                {data?.status === 'Collecting' ?
                                    <Typography sx={{ fontWeight: 'bold', color: 'white', textAlign: 'start' }}>
                                        {t('FUND.TARGET')}: $ {data?.amountToReach?.toLocaleString()}
                                    </Typography>
                                    :
                                    data?.status === 'ROI' || data?.status === 'Execution' ?
                                        <Typography sx={{ fontWeight: 'bold', color: 'white', textAlign: 'start' }}>
                                            {t('WALLET_VIEW.REMAINING')}: $ {totalInvestment?.toLocaleString()}
                                        </Typography>
                                        :

                                        <Typography sx={{ fontWeight: 'bold', color: 'white', textAlign: 'start' }}>
                                            {t('FUND.COLLECTED')}: $ {totalInvestment?.toLocaleString()}
                                        </Typography>

                                }

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: { md: '50%', xs: '100%' },
                        display: 'flex', flexDirection: 'row',
                        alignItems: 'center', justifyContent: { md: 'right', xs: 'center' }
                    }}>
                        {/* FUND DATE */}
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{
                                    display: 'flex', flexDirection: 'row',
                                    color: 'white'
                                }}>
                                    {t(`MONTHS.${overAllstartMonth}`).substring(0, 3)}. {overAllstartDay}
                                </Typography>
                                <Typography sx={{
                                    display: 'flex', flexDirection: 'row',
                                    color: 'white'
                                }}>
                                    {overAllstartYear}
                                </Typography>
                            </Box>
                            {data.status === 'Closed' || data.status === 'Closed - Not Fully Funded' ?
                                <Box sx={{ width: '30px' }}></Box>
                                :
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ width: '80%', m: 1 }}>
                                        <BorderLinearProgress variant="determinate" value={statusValue * 100 / 8} />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '180px', display: 'flex', flexDirection: 'row',
                                            alignItems: 'center', justifyContent: 'center', height: '31px', overflow: 'hidden'
                                        }}>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Collecting">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column',
                                                    alignItems: 'end', justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 1 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 1 ? "gray" : statusValue === 1 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1.1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 0 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>1</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Published">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 2 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 2 ? "gray" : statusValue === 2 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 1 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>2</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Open">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 3 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 3 ? "gray" : statusValue === 3 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1.05, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 2 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>3</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Assessment">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 4 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 4 ? "gray" : statusValue === 4 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 3 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>4</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Governance">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 5 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 5 ? "gray" : statusValue === 5 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 4 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>5</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Vesting">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 6 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 6 ? "gray" : statusValue === 6 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 5 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>6</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Execution">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 7 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 7 ? "gray" : statusValue === 7 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 6 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>7</Typography>
                                            </Box>
                                        </Tool>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="ROI">
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                    justifyContent: 'end', ml: -0.3, mr: -0.3
                                                }}>
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        color: statusValue === 8 ? '#B2E0DF' : actualThemeLight ? '#9A1D4C' : "#57313f", mb: -0.8, pl: 0.4
                                                    }} />
                                                <FiberManualRecordIcon fontSize="small"
                                                    sx={{
                                                        color: statusValue < 8 ? "gray" : statusValue === 8 ? '#B2E0DF' : "#E1BBC9"
                                                    }} />
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                <Typography sx={{ color: statusValue > 7 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>8</Typography>
                                            </Box>
                                        </Tool>
                                    </Box>
                                </Box>
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                <Typography sx={{
                                    display: 'flex', flexDirection: 'row',
                                    color: 'white'
                                }}>
                                    {t(`MONTHS.${overAllendMonth}`).substring(0, 3)}. {overAllendDay}
                                </Typography>
                                <Typography sx={{
                                    display: 'flex', flexDirection: 'row',
                                    color: 'white'
                                }}>
                                    {overAllendYear}
                                </Typography>
                            </Box>
                        </>

                    </Box>
                </Box>
                <Box sx={{ color: '#DB417B', mb: '12px' }} />
                {/* CARD CONTENT */}
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
                    <Box sx={{
                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                        borderRadius: '5px',
                        display: 'flex', flexDirection: { md: 'column', xs: 'column' },
                        width: { md: '53%', xs: '100%' }
                    }}>
                        <Box sx={{
                            width: { md: '100%', xs: '100%' },
                            display: 'flex', flexDirection: 'column',
                            alignItems: { md: 'start', xs: 'center' }, pl: 2, mt: 1
                        }}>
                            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
                                <Typography sx={{
                                    fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white'
                                }}>{t('DASHBOARD_VIEW.ACTUAL_ROUND')}:
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <FiberManualRecordIcon fontSize="medium" sx={{ ml: 1 }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>
                                        {data?.status === 'Open' ? t('ROUND_STATUS.OPEN') :
                                            data?.status === 'Draft' ? t('ROUND_STATUS.DRAFT') :
                                                data?.status === 'Collecting' ? t('ROUND_STATUS.COLLECTING') :
                                                    data?.status === 'Published' ? t('ROUND_STATUS.PUBLISHED') :
                                                        data?.status === 'Closed' ? t('ROUND_STATUS.CLOSED') :
                                                            data?.status === 'Closed - Not Fully Funded' ? t('ROUND_STATUS.CLOSED') :
                                                                data?.status === 'Governance' ? t('ROUND_STATUS.GOVERNANCE') :
                                                                    data?.status === 'Vesting' ? 'Vesting' :
                                                                        data?.status === 'Assessment' ? 'Assessment' :
                                                                            data?.status === 'ROI' ? 'ROI' :
                                                                                data?.status === 'Execution' ? t('ROUND_STATUS.EXECUTION') :
                                                                                    data?.status === 'Finished' ? t('ROUND_STATUS.FINISHED') : ''}
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px', ml: 0.5, color: actualThemeLight ? 'black' : 'white' }}>
                                        ({t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay})
                                    </Typography>
                                    <Box sx={{ position: 'absolute', ml: 2.1, mt: 0.2 }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: actualThemeLight ? 'white' : 'black' }}>{statusValue}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {data?.status === 'Collecting' ?
                                null
                                :
                                data?.status === 'Published' ||
                                    data?.status === 'Open' ||
                                    data?.status === 'Assessment' ||
                                    data?.status === 'Governance' ||
                                    data?.status === 'Vesting'
                                    ? null
                                    :
                                    data?.status === 'Execution'
                                        ?
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {t('FUND_SEARCH_VIEW.REPORTS')}:
                                            </Typography>
                                            <Typography sx={{ ml: 1, fontSize: '12px' }}>{totalReportLength}</Typography>
                                        </Box>
                                        : data?.status === 'ROI'
                                            ?
                                            // <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            //     <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            //         ROI:
                                            //     </Typography>
                                            //     <Typography sx={{ ml: 1, fontSize: '12px' }}>
                                            //         $ 0
                                            //     </Typography>
                                            // </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.INVESTED')}/{t('WALLET_VIEW.INVESTMENT_CARD.AVALIABLE')}</Typography>
                                                <Typography sx={{ ml: 1, fontSize: '12px' }}>$ 0 / $ {totalInvestment.toLocaleString()}</Typography>
                                            </Box>
                                            :
                                            data?.status === 'Closed' || data.status === 'Closed - Not Fully Funded'
                                                ?
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                        ROI:
                                                    </Typography>
                                                    <Typography sx={{ ml: 1, fontSize: '12px' }}>
                                                        $ 0
                                                    </Typography>
                                                </Box> : null
                            }
                        </Box>
                        <Box sx={{
                            width: { md: '100%', xs: '100%' },
                            display: 'flex', flexDirection: 'column',
                            alignItems: { md: 'start', xs: 'center' },
                            mb: { md: 1, xs: 1 }, pl: 2
                        }}>
                            {data.status === 'Collecting' &&
                                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: { md: 'start', xs: 'center' }, width: '100%' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MIN_THRESHOLD')}:</Typography>
                                    <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>${(data?.amountToReach * data?.successThreshold / 100).toLocaleString()} ({data?.successThreshold}%)</Typography>
                                </Box>
                            }
                            {data.status === 'Governance' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('ADMIN.RECEIVED_VOTES')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}> {totalVoteCount}</Typography>
                                    </Box>
                                </> : null
                            }
                            {data.status === 'Assessment' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.TOTAL_REQUESTED')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>${totalReqInvestAmount?.toLocaleString()}</Typography>
                                    </Box>
                                </> : null
                            }
                            {data.status === 'Open' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: { md: 'start', xs: 'center' }, width: '100%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MIN/MAX_EXPECTED_INVESTMENT')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>$ {data?.minCompanyAsk.toLocaleString()}/{data?.maxCompanyAsk.toLocaleString()}</Typography>
                                    </Box>
                                </> : null
                            }
                            {data.status === 'Published' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: { md: 'start', xs: 'center' }, width: '100%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MIN/MAX_EXPECTED_INVESTMENT')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>$ {data?.minCompanyAsk.toLocaleString()}/ $ {data?.maxCompanyAsk.toLocaleString()}</Typography>
                                    </Box>
                                </> : null
                            }
                            {data.status === 'Execution' ?
                                <>

                                </> : null
                            }
                            {data.status === 'Vesting' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: { md: 'start', xs: 'center' }, width: '100%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                                            Exits:
                                        </Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{totalVestingEvents}</Typography>
                                    </Box>
                                </> : null
                            }
                            {data.status === 'Closed' || data.status === 'Closed - Not Fully Funded' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: { md: 'start', xs: 'center' }, width: '100%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.EXIT_COMPANIES')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>0 / {data?.proposal?.length}</Typography>
                                    </Box>
                                    {locStorageRole === "role-investor" ? <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: { md: 'start', xs: 'center' }, width: '100%' }}>
                                        <Link
                                            href={''}
                                            target={{ _blank: true }}
                                            color="inherit"
                                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '12px' }}>
                                            <OpenInNewIcon sx={{ mr: 1 }} fontSize="small" />
                                            {t('ADMIN.FINAL_REPORT')}
                                        </Link>
                                    </Box> : null}
                                </> : null
                            }
                        </Box>
                    </Box>
                    <Box sx={{ width: { md: '47%', xs: '100%' }, pl: 1 }}>
                        <Grid container sx={{ spacing: { md: 2, xs: 0 } }}>
                            <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row', mt: 1 }} >
                                <Box sx={{ border: '3px solid #9A1D4C', height: '45px', borderRadius: '5px' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'absolute', ml: 1 }}>
                                    <DoubleArrowIcon fontSize="small" sx={{ color: actualThemeLight ? '#828282' : 'white' }} />
                                    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                        <Typography sx={{ fontSize: '13px', color: actualThemeLight ? '#828282' : 'white' }}>
                                            {t('CREATE_COMPANY.DESCRIPTION')}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ maxHeight: '24px', minHeight: '24px', overflow: 'hidden', color: actualThemeLight ? '#828282' : 'white', fontSize: '13px', width: '100%', mt: '20px', ml: { xs: 1 } }}>
                                    {selectedLanguage === 'en' ? data?.descriptionEn :
                                        selectedLanguage === 'es' && data?.descriptionEs !== '' ?
                                            data?.descriptionEs :
                                            selectedLanguage === 'pt' && data?.descriptionPt !== '' ?
                                                data?.descriptionPt : data?.descriptionEn}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* FUND DESCRIPTION */}

                {/* LOWER THIRD */}
                <Box sx={{ width: '100%', display: 'flex', flexDirection: { md: 'row', xs: 'column' }, mt: { md: 1, xs: 0 }, mb: 1 }}>
                    <Box sx={{ width: { md: '50%', xs: '100%' }, height: { md: '13vh', xs: '10vh' } }}>
                        {
                            data?.status === 'Collecting'
                                ?
                                <Box sx={{ ml: -1 }}>
                                    <PieChart
                                        margin={{ top: 30, right: 30, left: 20, bottom: -30 }}
                                        width={380}
                                        height={120}
                                    >
                                        <Pie
                                            dataKey="value"
                                            startAngle={180}
                                            endAngle={0}
                                            data={dataChartCollecting}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={60}
                                            fill="#db417b"
                                        />
                                        <Pie
                                            dataKey="value"
                                            startAngle={180}
                                            endAngle={0}
                                            data={dataChartCollectingThresshold}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={72}
                                            fill="#db417b"
                                        />
                                        <Tooltip
                                            position={{ x: 40, y: -60 }}
                                            allowEscapeViewBox={{ x: true, y: true }}
                                            content={
                                                <CustomTooltipCollectingThresshold
                                                    thresshold={data?.successThreshold}
                                                    raised={totalInvestment}
                                                    amountToReach={data?.amountToReach}
                                                />
                                            } />
                                    </PieChart>
                                    <Box sx={{ width: 320, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: -3.5, ml: 3 }}>
                                        <Typography sx={{ color: "#db417b", fontSize: '10px', fontWeight: 'bold' }}>
                                            {t('FUND.COLLECTED')} / Total
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: 320, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: -13.4, ml: 3 }}>
                                        <Typography sx={{ color: "#db417b", fontSize: '10px', fontWeight: 'bold' }}>
                                            {t('FUND.THRESHOLD')}
                                        </Typography>
                                    </Box>
                                </Box>
                                // <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                //     <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.COLLECTED')}</Typography>
                                //     <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                //         <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>$ {totalInvestment.toLocaleString()}</Typography>
                                //         <Typography sx={{ fontWeight: 'bold', ml: 1, mr: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.OF')}</Typography>
                                //         <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}> $ {(data?.amountToReach).toLocaleString()}  </Typography>
                                //     </Box>
                                //     <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                //         <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}</Typography>
                                //         <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow placement="top-start" title={`${t('FUND.COLLECTED')}: $ ${totalInvestment.toLocaleString()} / ${t('FUND.TARGET')}: $ ${data?.amountToReach?.toLocaleString()}`}>
                                //             <Box sx={{ width: '50%', ml: 1, mr: 1, position: 'relative' }}>
                                //                 <BorderLinearProgress variant="determinate" value={(totalInvestment / data?.amountToReach) * 100} />
                                //                 <Box
                                //                     sx={{
                                //                         position: 'absolute',
                                //                         top: '50%',
                                //                         left: `${data?.successThreshold}%`,
                                //                         transform: 'translate(-50%, -50%)',
                                //                         borderLeft: '2px solid #9A1D4C',
                                //                         height: '100%',
                                //                         zIndex: 1
                                //                     }}
                                //                 />
                                //                 <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow title={`${t('FUND.MIN_THRESHOLD')}: $ ${(data?.amountToReach * data?.successThreshold / 100).toLocaleString()} (${data?.successThreshold}%)`}>
                                //                     <Typography
                                //                         sx={{
                                //                             color: thresholdMet ? 'green' : 'white',
                                //                             fontSize: '20px',
                                //                             fontWeight: 'bold',
                                //                             position: 'absolute',
                                //                             left: `${data?.successThreshold}%`,
                                //                             top: '50%',
                                //                             transform: 'translate(-50%, -50%)'
                                //                         }}>
                                //                         |
                                //                     </Typography>
                                //                 </Tool>
                                //             </Box>
                                //         </Tool>
                                //         <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}</Typography>
                                //     </Box>
                                //     <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{((totalInvestment / data?.amountToReach) * 100).toFixed(2)}%</Typography>
                                //     <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                //         {thresholdMet === false && <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('DASHBOARD_VIEW.TO_REACH_THRESHOLD')}</Typography>}
                                //         {thresholdMet === false ?
                                //             <Typography sx={{ fontSize: '12px', ml: 1, color: actualThemeLight ? 'black' : 'white' }}>
                                //                 $ {(data?.amountToReach * data?.successThreshold / 100).toLocaleString() - totalInvestment.toLocaleString() || (data?.amountToReach * data?.successThreshold / 100).toLocaleString()}
                                //             </Typography>
                                //             :
                                //             <>
                                //                 <Typography sx={{ fontSize: '12px', mt: 0.8, color: actualThemeLight ? 'black' : 'white' }}>
                                //                     {t('DASHBOARD_VIEW.THRESHOLD_REACHED')}
                                //                 </Typography>
                                //                 <CheckIcon sx={{ color: 'green', ml: 0.5, mt: 0.8 }} />
                                //             </>
                                //         }
                                //     </Box>
                                // </Box> 
                                :
                                null
                        }
                        {
                            data?.status === 'ROI'
                                ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.RETURNED')}</Typography>
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>$ 0</Typography>
                                </Box> : null
                        }
                        {
                            data?.status === 'Closed' || data.status === 'Closed - Not Fully Funded'
                                ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('DASHBOARD_VIEW.TOTAL_INVESTED')}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>0%</Typography>
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <Box sx={{ width: '50%', ml: 1, mr: 1 }}>
                                            <BorderLinearProgress variant="determinate" value={0} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('DASHBOARD_VIEW.TOTAL_INVESTED')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}> $ 0</Typography>
                                    </Box>
                                </Box> : null
                        }
                        {data.status === 'Execution' ||
                            data.status === 'Open'
                            ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.EXPECTED_ROI')}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <AccessTimeIcon sx={{ mr: 1, color: actualThemeLight ? 'black' : 'white' }} fontSize="small" />
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{data?.roiTime}</Typography>
                                </Box>
                                <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MULTIPLIED')}: {data?.roiMultiplier}</Typography>
                            </Box>
                            :
                            null}
                        {
                            data.status === 'Vesting'
                                ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.UPDATED_ROI')}</Typography>
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MULTIPLIED')}: {data?.roiMultiplier}</Typography>
                                </Box>
                                :
                                null}
                        {data.status === 'Governance'
                            ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                <Typography sx={{ fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white', fontSize: { md: '12px', xs: '12px' } }}>{t('FUND.APPROVED/APPLICATIONS')}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <PersonIcon sx={{ color: actualThemeLight ? 'black' : 'white' }} />
                                    <Typography sx={{ fontSize: { md: '12px', xs: '12px' }, color: actualThemeLight ? 'black' : 'white' }}>{assessedProposalsCount}/{totalProposalCount} ({(assessedProposalsCount * 100 / totalProposalCount).toFixed(2)}%)</Typography>
                                </Box>
                            </Box>
                            :
                            null}
                        {data.status == 'Assessment' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', ml: 6, mt: 2, color: actualThemeLight ? 'black' : 'white' }}>
                                    {t('FUND.COMPANY_TYPE')}
                                </Typography>
                                <Box zIndex={2}>
                                    <BarChart width={180} height={80} data={employeesCount}>
                                        <CartesianGrid strokeDasharray="2 0" />
                                        <XAxis dataKey="name" fontSize={8} />
                                        <YAxis fontSize={8} />
                                        <Tooltip />
                                        <Bar dataKey="qty" fill="#8884d8" />
                                    </BarChart>
                                </Box>
                                <Typography sx={{ fontSize: '10px', position: 'absolute', mt: 11, ml: 6, zIndex: 1 }}>{t('CREATE_COMPANY.EMPLOYEES_AMOUNT')}</Typography>
                                <Typography sx={{ fontSize: '10px', position: 'absolute', mt: 0.1, ml: -13, rotate: '-90deg', zIndex: 1 }}>N° {t('ADMIN.COMPANY_ADMINISTRATION')}</Typography>
                            </Box>
                            :
                            null}
                        {
                            data.status === 'Published' ?
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { md: 'center', xs: 'center' }, width: '100%', height: '100%' }} >
                                    <Box>
                                        <Logo />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', ml: 2, alignItems: 'start'
                                    }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>ALLIs</Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MINTED_TOKENS')}:</Typography>
                                            <Typography sx={{ fontSize: '12px', fontWeight: 'bold', ml: 0.5, color: actualThemeLight ? 'black' : 'white' }}>{tstALLIObject?.symbol === "tstALLI" ? totalQuantityHoldersTstAllies?.toLocaleString() : 0}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '12px', ml: 0.5, color: actualThemeLight ? 'black' : 'white' }}>$ 1</Typography>
                                        </Box>
                                    </Box>
                                </Box> : null
                        }
                        {data.status === 'Closed' || data.status === 'Closed - Not Fully Funded'
                            ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.EXPECTED_ROI')}</Typography>
                                <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MULTIPLIED')}: X 2.1</Typography>
                            </Box>
                            :
                            null}
                    </Box>
                    <Box sx={{ width: { md: '50%', xs: '100%' }, height: { md: '12vh', xs: '10vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {data?.status === 'Governance' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", borderRadius: '20px', width: '80%', alignItems: 'center', justifyContent: 'center', p: { md: 1, xs: 0 }, mt: { md: 0, xs: 2 } }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: { md: '12px', xs: '12px' }, ml: 1, mr: 1 }}>{t('FUND.APROVED_INVESTMENTS')} </Typography>
                                <Typography sx={{ fontSize: { md: '12px', xs: '12px' }, ml: 1, mr: 1 }}>${assessedTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: { md: '12px', xs: '12px' }, ml: 1, mr: 1 }}>{t('FUND.AVERAGE_INVESTMENT')} </Typography>
                                <Typography sx={{ fontSize: { md: '12px', xs: '12px' }, ml: 1, mr: 1 }}>${(assessedTotal / assessedProposalsCount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                            </Box>
                            :
                            null}
                        {data?.status === 'Assessment' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", borderRadius: '20px', width: '80%', alignItems: 'center', justifyContent: 'center', p: 1 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.RECEIVED_APPLICATIONS')}:</Typography>
                                    <PersonIcon sx={{ color: actualThemeLight ? 'black' : 'white' }} />
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{totalProposalCount}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.ASSESSED_APPLICATIONS')}:</Typography>
                                    <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}> {assessedProposalsCount} ({(assessedProposalsCount * 100 / totalProposalCount).toFixed(2)}%)</Typography>
                                </Box>
                            </Box>
                            :
                            null}
                        {data?.status === 'Published' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", borderRadius: '20px', width: '80%', alignItems: 'center', justifyContent: 'center', p: { md: 1, xs: 0 } }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.INVESTORS')}</Typography>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: { lg: 'row', xs: 'column' },
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <PersonIcon sx={{ color: actualThemeLight ? 'black' : 'white' }} />
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{countOfValidOffPur}</Typography>
                                        <Typography sx={{ ml: 0.5, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('WALLET_VIEW.INVESTMENT_CARD.INVESTED')}</Typography>
                                        <Typography sx={{ ml: 0.5, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>|</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography sx={{ ml: 0.5, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('OFFERER_DASHBOARD.AVERAGE')}:</Typography>
                                        <Typography sx={{ ml: 0.5, fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{data?.token[0]?.symbol} {averageAmount.toFixed(2)}</Typography>
                                    </Box>
                                </Box>

                            </Box>
                            :
                            null}
                        {data?.status === 'Execution' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', width: '80%', alignItems: 'center', justifyContent: 'center', p: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.TIME_SPENT')}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <HourglassBottomIcon fontSize="small" sx={{ mr: 1 }} />
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{collectingInfo?.monthsPassed} {t('ADMIN.MONTHS')}</Typography>
                                </Box>
                                <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.RECEIVED_INVESTMENTS')}</Typography>
                            </Box>
                            :
                            null}
                        {
                            data?.status === 'ROI' ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('ADMIN.ROI_MULTIPLIER_FINAL')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>0X</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.EXIT_COMPANIES')}:</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>0 / {data?.proposal?.length}</Typography>
                                    </Box>
                                </>
                                :
                                null}
                        {data?.status === 'Vesting' ?
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '20px', width: '100%', alignItems: 'center', justifyContent: 'center', p: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                                                {t('ADMIN.VESTING_EVENTS')}
                                            </Typography>
                                            <Typography sx={{ ml: 1, fontSize: '12px' }}>{totalVestingEvents} / {totalProposalCount}</Typography>
                                        </Box>
                                        <Box sx={{ width: '50px', height: '50px' }}>
                                            <CircularProgress
                                                sx={{
                                                    position: "absolute",
                                                    ml: -2,
                                                    zIndex: 10,
                                                    color: '#D9D9D9',
                                                }}
                                                size={50}
                                                thickness={22}
                                                variant="determinate"
                                                value={100} />
                                            <CircularProgress
                                                sx={{
                                                    position: "absolute",
                                                    ml: -2,
                                                    zIndex: 30,
                                                    color: '#B2E0DF',
                                                }}
                                                size={50}
                                                thickness={22}
                                                variant="determinate"
                                                value={totalVestingEvents !== 0 ? totalProposalCount * 100 / totalVestingEvents : 1} />
                                            <Typography sx={{ fontSize: '10px', fontWeight: 'bold', zIndex: 50, position: 'absolute', mt: '17px', ml: '26px' }}>
                                                {totalVestingEvents !== 0 ? totalProposalCount * 100 / totalVestingEvents : 0}%
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                            :
                            null}
                        {data?.status === 'Open' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", borderRadius: '20px', width: '80%', alignItems: 'center', justifyContent: 'center', p: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.RECEIVED_APPLICATIONS')}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <PersonIcon sx={{ color: actualThemeLight ? 'black' : 'white' }} />
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{totalProposalCount}</Typography>
                                </Box>
                            </Box>
                            :
                            null}
                        {data?.status === 'Collecting' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", borderRadius: '20px', width: { md: '80%', xs: '90%' }, alignItems: 'center', justifyContent: 'center', p: 1, mt: { md: 0, xs: 2 } }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.INVESTORS')}</Typography>
                                <>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <PersonIcon sx={{ color: actualThemeLight ? 'black' : 'white' }} />
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{countOfValidOffPur}</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('WALLET_VIEW.INVESTMENT_CARD.INVESTED')}</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>|</Typography>
                                        <Typography sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('OFFERER_DASHBOARD.AVERAGE')}:</Typography>
                                        <Typography sx={{ ml: 1, fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{data?.token[0]?.symbol} {averageAmount.toFixed(2)}</Typography>
                                    </Box>
                                </>
                            </Box>
                            :
                            null}
                        {data?.status === 'Closed' || data.status === 'Closed - Not Fully Funded' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", borderRadius: '20px', width: '80%', alignItems: 'center', justifyContent: 'center', p: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.TIME_SPENT')}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <HourglassBottomIcon sx={{ mr: 2 }} />
                                    <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>3 Years</Typography>
                                </Box>
                                <Typography sx={{ fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.FUND_OPEN')}</Typography>
                            </Box>
                            :
                            null}

                    </Box>

                </Box>
                {/* END - LOWER THIRD */}
            </Box>
            {(locStorageRole === 'role-applicant' && data?.status === 'Collecting') ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.INVEST_BUTTON_HELPER')}>
                        <Box>
                            <Button disabled variant="contained" >
                                <Typography>
                                    {t('FUND.INVEST')}
                                </Typography>
                            </Button>
                        </Box>
                    </Tool>
                </Box>
                :
                data?.status === 'Collecting' ?
                    <>
                        {
                            amountToReach > totalInvestment && daysLeft >= 1
                                ?
                                <Box onClick={() => {
                                    setModalInvest(true)
                                }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                    <Button variant="contained" >
                                        <Typography>
                                            {t('FUND.INVEST')}
                                        </Typography>
                                    </Button>
                                </Box>
                                :
                                daysLeft <= 0 ? null :
                                    <Box onClick={() => {
                                        setModalWaitingList(true)
                                    }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                        <Button variant="contained" >
                                            <Typography>
                                                {t('DASHBOARD.WAITING_LIST')}
                                            </Typography>
                                        </Button>
                                    </Box>
                        }
                    </>
                    : null
            }
            {locStorageRole === 'role-applicant' && data?.status === 'Open' && daysLeft > 0 ?
                <Button
                    onClick={() => {
                        dispatch(push(`/postulate/${data?.id}`))
                    }}
                    variant="contained"
                >
                    <Typography sx={{ color: 'white' }}>
                        {t('CREATE_COMPANY.APPLY')}
                    </Typography>
                </Button>
                : null
            }
            {locStorageRole === 'role-investor' && data?.status === 'Open' && daysLeft > 0 ?
                <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.APPLY_BUTTON_HELPER')}>
                    <Box>
                        <Button
                            disabled
                            variant="contained"
                        >
                            <Typography sx={{ color: 'white' }}>
                                {t('CREATE_COMPANY.APPLY')}
                            </Typography>
                        </Button>
                    </Box>
                </Tool>
                : null
            }
        </Card>
        {
            modalInvest && (
                <InvestModal
                    data={dataAll}
                    open={modalInvest}
                    handleClose={handleCloseInvest}
                    raised={totalInvestment}
                    target={data?.amountToReach}
                    minInvest={data?.minInvestment}
                    maxInvest={data?.maxInvestment}
                    token={data?.token[0]}
                    txFundId={data?.id}
                    scData={data?.smartContract}
                />
            )
        }
        {
            modalWaitingList && (
                <WaitingListModal
                    data={dataAll}
                    open={modalWaitingList}
                    handleClose={handleCloseWaitingList}
                    raised={totalInvestment}
                    target={data?.amountToReach}
                    token={data?.token[0]}
                    minInvest={data?.minInvestment}
                    maxInvest={data?.maxInvestment}
                    txFundId={data?.id}
                    scData={data?.smartContract}
                />
            )
        }
    </Fragment >
}

export default ActiveFundsDashboard