import { gql } from '@apollo/client'

export const GET_COMPANIES = gql`
  query getCompanies {
    companies(where: { NOT: { id: null } }) {
      id
      address
      isValidated
      coName
      descriptionEs
      logo
      has_url {
        _id
        name
      }
    }
  }
`

export const GET_COMPANY_BY_ID = gql`
  query ($CompanyId: ID!) {
    companies(where: { id: $CompanyId }) {
      id
      address
      coName
      descriptionEn
      descriptionEs
      descriptionPt
      isValidated
      isIncorporated
      isIncJurisdiction
      email
      phone
      employees
      preferences {
        id
        preferenceName
      }
      fundationYear
      annualBilling
      logo
      users {
        id
        userName
        email
      }
      proposal {
        assessmentStatus
        fund {
          name
        }
      }
      country {
        id
        name
      }
      has_url {
        id
        name
        link
      }
    }
  }
`

export const GET_COMPANIES_SEARCH = gql`
  query getCompanies($filter: Boolean, $sort: [CompanySort!]) {
    companies(
      where: {
        AND: [
          { NOT: { id: null } }
          { isValidated: $filter }
          { isActive: true }
        ]
      }
      options: { sort: $sort }
    ) {
      id
      address
      coName
      descriptionEn
      descriptionEs
      descriptionPt
      logo
      isValidated
      country {
        id
        name
      }
      users {
        id
      }
      proposal {
        reqInvestAmount
        assessmentStatus
        descriptionEn
        descriptionEs
        descriptionPt
        titleEn
        titleEs
        titlePt
        fund {
          id
          name
        }
      }
      employees
      fundationYear
      annualBilling
      isIncJurisdiction
      isIncorporated
      has_url {
        _id
        name
        link
      }
    }
  }
`

export const GET_COMPANIES_SEARCH_NO_PREF = gql`
  query getCompanies($filter: Boolean, $sort: [CompanySort!]) {
    companies(
      where: {
        AND: [
          { NOT: { id: null } }
          { isValidated: $filter }
          { isActive: true }
        ]
      }
      options: { sort: $sort }
    ) {
      id
      address
      coName
      descriptionEn
      descriptionEs
      descriptionPt
      logo
      country {
        id
        name
      }
      isValidated
      users {
        id
      }
      proposal {
        reqInvestAmount
        assessmentStatus
        descriptionEn
        descriptionEs
        descriptionPt
        titleEn
        titleEs
        titlePt
        fund {
          id
          name
        }
      }
      employees
      fundationYear
      annualBilling
      isIncJurisdiction
      isIncorporated
      has_url {
        _id
        name
        link
      }
    }
  }
`

export const GET_PROPOSALS_BY_USER = gql`
  query ($search: String!, $sort: [ProposalSort!], $userId: ID) {
    proposals(
      where: {
        company: { users_SOME: { id: $userId } }
        OR: [
          { descriptionEn_CONTAINS: $search }
          { descriptionEs_CONTAINS: $search }
          { descriptionPt_CONTAINS: $search }
          { titleEn_CONTAINS: $search }
          { titleEs_CONTAINS: $search }
          { titlePt_CONTAINS: $search }
        ]
      }
      options: { sort: $sort }
    ) {
      id
      reqInvestAmount
      descriptionEn
      descriptionEs
      descriptionPt
      titleEn
      titleEs
      titlePt
      assessmentStatus
      assessmentRating
      url {
        id
        link
        name
      }
      company {
        coName
        logo
        address
        country {
          id
          name
        }
      }
      fund {
        name
        status
      }
    }
  }
`

export const GET_USER_COMPANIES = gql`
  query getUserCompanies($userId: ID!) {
    companies(
      where: {
        NOT: { id: null }
        AND: [{ users_SOME: { id: $userId } }, { isActive: true }]
      }
    ) {
      id
      address
      coName
      users {
        id
      }
      employees
      fundationYear
      annualBilling
      isIncJurisdiction
      isDraft
      email
      country {
        id
        name
      }
      descriptionEn
      descriptionEs
      descriptionPt
      isValidated
      isActive
      isIncorporated
      logo
      proposal(where: { assessmentStatus: "complete" }) {
        descriptionEn
        descriptionEs
        descriptionPt
        titleEn
        titleEs
        titlePt
        reqInvestAmount
        assessmentStatus
        status
        fund {
          name
        }
      }
      createdOn
      has_url {
        _id
        id
        link
        name
      }
    }
  }
`

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $id: ID!
    $address: String!
    $name: String!
    $descriptionEn: String
    $descriptionEs: String
    $descriptionPt: String
    $userId: ID!
    $logo: String
    $email: String
    $date: DateTime!
    $countryID: ID!
    $linkId: ID!
    $linkUrl: String!
    $phone: String!
    $employees: String!
    $annualBilling: Float!
    $jurisdiction: String!
    $isIncorporated: Boolean!
    $preferences: [ID!]
    $fundationYear: DateTime!
  ) {
    createCompanies(
      input: {
        id: $id
        address: $address
        coName: $name
        descriptionEn: $descriptionEn
        descriptionEs: $descriptionEs
        descriptionPt: $descriptionPt
        logo: $logo
        email: $email
        createdOn: $date
        isValidated: false
        isActive: true
        isDraft: false
        phone: $phone
        employees: $employees
        annualBilling: $annualBilling
        isIncJurisdiction: $jurisdiction
        isIncorporated: $isIncorporated
        fundationYear: $fundationYear
        users: { connect: { where: { node: { id: $userId } } } }
        preferences: { connect: { where: { node: { id_IN: $preferences } } } }
        country: { connect: { where: { node: { id: $countryID } } } }
      }
    ) {
      companies {
        id
      }
    }
    createUrls(
      input: {
        id: $linkId
        link: $linkUrl
        name: "LinkTree"
        company: { connect: { where: { node: { id: $id } } } }
      }
    ) {
      urls {
        id
      }
    }
  }
`

export const CREATE_COMPANY_DRAFT = gql`
  mutation createCompany(
    $id: ID!
    $address: String
    $name: String
    $descriptionEn: String
    $descriptionEs: String
    $descriptionPt: String
    $userId: ID!
    $logo: String
    $email: String
    $date: DateTime
    $countryID: ID
    $linkId: ID!
    $linkUrl: String!
    $phone: String
    $employees: String
    $annualBilling: Float
    $jurisdiction: String
    $isIncorporated: Boolean
    $fundationYear: DateTime
  ) {
    createCompanies(
      input: {
        id: $id
        address: $address
        coName: $name
        descriptionEn: $descriptionEn
        descriptionEs: $descriptionEs
        descriptionPt: $descriptionPt
        logo: $logo
        email: $email
        createdOn: $date
        isValidated: false
        isActive: true
        isDraft: true
        phone: $phone
        employees: $employees
        annualBilling: $annualBilling
        isIncJurisdiction: $jurisdiction
        isIncorporated: $isIncorporated
        fundationYear: $fundationYear
        users: { connect: { where: { node: { id: $userId } } } }
        country: { connect: { where: { node: { id: $countryID } } } }
      }
    ) {
      companies {
        id
        address
        coName
        has_url {
          name
        }
      }
    }
    createUrls(
      input: {
        id: $linkId
        link: $linkUrl
        name: "LinkTree"
        company: { connect: { where: { node: { id: $id } } } }
      }
    ) {
      urls {
        id
      }
    }
  }
`

export const EDIT_COMPANY = gql`
  mutation (
    $coId: ID!
    $coUpdateData: CompanyUpdateInput!
    $urlId: ID!
    $preferences: [ID!]
    $link: String
    $countryId: ID
  ) {
    updateCompanies(
      where: { id: $coId }
      update: $coUpdateData
      disconnect: {
        preferences: { where: { NOT: { node: { id: "PABLO123" } } } }
        country: { where: { NOT: { node: { id: "PAIS123" } } } }
      }
      connect: {
        preferences: { where: { node: { id_IN: $preferences } } }
        country: { where: { node: { id: $countryId } } }
      }
    ) {
      companies {
        id
      }
    }
    updateUrls(where: { id: $urlId }, update: { link: $link }) {
      urls {
        id
      }
    }
  }
`

export const GET_USER_COMPANIES_POSTULATE = gql`
  query getUserCompanies($userId: ID!) {
    companies(
      where: {
        NOT: { id: null }
        AND: [
          { users_SOME: { id: $userId } }
          { isActive: true }
          { isDraft: false }
        ]
      }
    ) {
      id
      address
      coName
      users {
        id
      }
      employees
      fundationYear
      annualBilling
      isIncJurisdiction
      email
      country {
        id
        name
      }
      descriptionEn
      descriptionEs
      descriptionPt
      isValidated
      isActive
      isIncorporated
      logo
      proposal(where: { assessmentStatus: "complete" }) {
        descriptionEn
        descriptionEs
        descriptionPt
        titleEn
        titleEs
        titlePt
        reqInvestAmount
        assessmentStatus
        status
        fund {
          name
        }
      }
      createdOn
      has_url {
        _id
        id
        link
        name
      }
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation ($coId: ID!) {
    deleteCompanies(where: { id: $coId }) {
      nodesDeleted
    }
  }
`
