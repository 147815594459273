import React, { useState } from 'react';
import { Box, TextField, Button, InputLabel, styled, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Define styled component for back button
const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.hint,
    fontFamily: 'Quicksand, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#f1f1f1',
    },
}))

const CreateFund = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()


    // DEFINE STATES
    // eslint-disable-next-line
    const [companyField, setCompanyField] = useState('')

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 6 }}>
                {/* SUPERIOR */}
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 4, justifyContent: 'space-between' }}>
                    {/* LEFT SIDE */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ArrowBackIcon sx={{ fontSize: '16px' }} />
                            <BackButton
                                onClick={() => dispatch(goBack())}
                                sx={{
                                    marginBottom: '10px',
                                    textDecoration: 'underline',
                                    fontSize: '14px',
                                }}
                            >
                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                            </BackButton>
                        </Box>
                        <Typography sx={{ fontSize: '20px', color: '#4F4F4F', ml: 2 }}>
                            {t('CREATE_FUND.APPLICATION_TO_FUND')}
                        </Typography>
                    </Box>
                    {/* LANGUAGE SETTINGS */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>

                    </Box>
                </Box>
                {/* FORM */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* COMPANY */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 2,
                            }}
                        >
                            <InputLabel
                                shrink
                                htmlFor="lenguage-select"
                                sx={{
                                    color: '#4F4F4F',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Quicksand',
                                    letterSpacing: 0.15,
                                    textAlign: 'left',
                                }}
                            >
                                {t('CREATE_COMPANY.USER_NAME')}
                            </InputLabel>
                            <TextField
                                id="company-input"
                                sx={{ width: '100%' }}
                                name={t('CREATE_COMPANY.USER_NAME')}
                                value={companyField}
                                type="text"
                                variant="outlined"
                                aria-describedby="company-input-text"
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default CreateFund