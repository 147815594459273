import createTheme from '@mui/material/styles/createTheme'

export const lightTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Quicksand, sans-serif',
    },
  },
  button: {
    allVariants: {
      fontFamily: 'Quicksand, sans-serif',
    },
  },
  textField: {
    allVariants: {
      fontFamily: 'Quicksand, sans-serif',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1500,
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#110A5B',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#5F6162',
      secondary: '#2B2D42',
      hint: '#000000',
      disabled: 'rgba(95,97,98,0.6)',
    },
    primary: {
      dark: '#9A1D4CD1',
      main: '#9A1D4C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#EE3552',
      edit: '#5C95FF',
    },
    lightGrey: {
      main: '#828282',
      dark: '#828282D1',
      contrastText: '#FFFFFF',
    },
    pink: {
      main: '#DB417B',
      dark: '#FCEEF3', // Corrected here
      contrastText: '#FFFFFF',
    },
  },
})

export const darkTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Quicksand, sans-serif',
    },
  },
  button: {
    allVariants: {
      fontFamily: 'Quicksand, sans-serif',
    },
  },
  textField: {
    allVariants: {
      fontFamily: 'Quicksand, sans-serif',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1500,
    },
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#262626', // 20% lighter than #121212
      paper: '#333333', // 20% lighter than #1E1E1E
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#CAD7DC', // 20% lighter than #B0BEC5
      hint: '#FFFFFF',
      disabled: 'rgba(255, 255, 255, 0.6)',
    },
    primary: {
      dark: '#7E3347', // 20% lighter than #6B2C3D
      main: '#B02555', // 20% lighter than #9A1D4C
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF4C6E', // 20% lighter than #EE3552
      edit: '#80B9FF', // 20% lighter than #5C95FF
    },
    lightGrey: {
      main: '#CAD7DC', // 20% lighter than #B0BEC5
      dark: '#999999', // 20% lighter than #757575
      contrastText: '#FFFFFF',
    },
    pink: {
      main: '#EE5B83', // 20% lighter than #DB417B
      dark: '#FEE6F1', // Corrected here
      contrastText: '#FFFFFF',
    },
  },
})
