import React, { useEffect } from "react"
import { Fragment } from "react"
import Card from "../../../../components/Card/Card"
import { Avatar, Box, Link, Typography, useTheme } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { GET_ALL_NEWS } from "../../../../Queries/News"
import { useLazyQuery } from '@apollo/client'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation, Pagination } from 'swiper'
import { useDispatch } from "react-redux"
import { push } from 'connected-react-router'
import i18n from "i18next";


const AlliesNewsDashboard = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const selectedLanguage = i18n.language


    // IMPORT NEWS
    const [getNews, { data: newsData }] = useLazyQuery(
        GET_ALL_NEWS,
        {
            pollInterval: 5000,
        }
    )

    useEffect(() => {
        getNews()
    }, [getNews])

    const handleNewsClick = () => {
        dispatch(push('/dao/news'))
    }

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return <Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 1, mt: 2, width: '350px' }}>
            <Typography sx={{ fontSize: '20px', color: actualThemeLight ? 'black' : 'white' }}>
                {t('TREASURY.TOKEN_ALLIES_DAO')}
            </Typography>
            <Link sx={{
                cursor: 'pointer', mr: 4, color: actualThemeLight ? null : '#e6a3bd',
                textDecorationColor: actualThemeLight ? null : '#e6a3bd'
            }} onClick={() => {
                dispatch(push('/dao/news'))
            }}>
                {t('DASHBOARD_VIEW.SEE_ALL_NEWS')}
            </Link>
        </Box>
        <Card color='#FFF' height='35vh' >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Swiper
                    loop={false}
                    slidesPerView={1}
                    modules={[Navigation, Pagination]}
                    navigation={true}
                    grabCursor={true}
                    className="recommended tokens slider"
                >
                    {newsData?.news?.map((news, i) => {
                        return (
                            <SwiperSlide key={i} onClick={handleNewsClick}>
                                <Box id="Info" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', overflow: 'hidden', p: 3, width: '100%', height: '90%', cursor: 'pointer' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <Avatar src={news?.image}></Avatar>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 550, ml: 1, color: actualThemeLight ? 'black' : 'white' }}>
                                            {selectedLanguage === 'en' ? news.titleEn.slice(0, 50) + "..."
                                                : selectedLanguage === 'es' ? news.titleEs.slice(0, 50) + "..." :
                                                    selectedLanguage === 'pt' && news.titlePt.slice(0, 50) + "..."}
                                        </Typography>
                                    </Box>
                                    <Link
                                        href={news?.url?.link}
                                        target={{ _blank: true }}
                                        sx={{
                                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                                            color: actualThemeLight ? null : '#e6a3bd',
                                            textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                                        }}>
                                        <Typography sx={{ fontSize: '12px' }}>{news?.url?.link.slice(0, 30)}...</Typography>
                                    </Link>
                                    <Typography sx={{ fontSize: '12px', mt: 2, color: actualThemeLight ? 'black' : 'white' }}>
                                        {selectedLanguage === 'en' ? news.contentEn.slice(0, 200) + "..."
                                            : selectedLanguage === 'es' ? news.contentEs.slice(0, 200) + "..." :
                                                selectedLanguage === 'pt' && news.contentPt.slice(0, 200) + "..."}
                                    </Typography>
                                </Box>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Box >
        </Card >
    </Fragment >
}

export default AlliesNewsDashboard