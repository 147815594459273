import { Grid, Typography } from '@mui/material'
import React from 'react'
import esFlag from '../../assets/icons/i18nIcons/es_flag.svg'
import usFlag from '../../assets/icons/i18nIcons/us_flag.svg'
import porFlag from '../../assets/icons/i18nIcons/por_flag.svg'

const BlockedView = () => {
    return (
        <>
            <Grid container xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '32vh' }}>
                <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid red', borderRadius: '5px', p: 2, m: 1 }}>
                    <img src={usFlag} alt="usFlag" width="30px" height="30px" style={{ margin: "10px" }} />
                    <Typography sx={{ fontSize: '30px', ml: 1 }}>You have been blocked by an Administrator</Typography>
                </Grid>
                <Typography sx={{ fontSize: '20px' }}>If you were blocked by mistake, please contact admin@tokenallies.com</Typography>
            </Grid>
            <Grid container xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '32vh' }}>
                <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid red', borderRadius: '5px', p: 2, m: 1 }}>
                    <img src={esFlag} alt="esFlag" width="30px" height="30px" />
                    <Typography sx={{ fontSize: '30px', ml: 1 }}>Ha sido bloqueado por un administrador</Typography>
                </Grid>
                <Typography sx={{ fontSize: '20px' }}>Si fue bloqueado por error, comuníquese con admin@tokenallies.com</Typography>
            </Grid>
            <Grid container xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '32vh' }}>
                <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid red', borderRadius: '5px', p: 2, m: 1 }}>
                    <img src={porFlag} alt="esFlaporFlagg" width="30px" height="30px" />
                    <Typography sx={{ fontSize: '30px', ml: 1 }}>Você foi bloqueado por um administrador</Typography>
                </Grid>
                <Typography sx={{ fontSize: '20px' }}>Se você foi bloqueado por engano, entre em contato com admin@tokenallies.com</Typography>
            </Grid>
        </>
    )
}

export default BlockedView