import React, { useEffect, useState } from 'react'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import RoutingComponent from './components/Routing/RoutingComponent'
import { lightTheme, darkTheme } from './theme'
import { Provider } from 'react-redux'
import store from './store'
import SnackbarMessage from './components/snackbar/SnackbarMessage'
import { Suspense } from 'react'
import Preloader from './components/preloader/Preloader'
import GlobalStyles from './components/GlobalStyles/GlobalStyles'
import GlobalStylesDark from './components/GlobalStyles/GlobalStylesDark'

export default function App() {
  const [mode, setMode] = useState(localStorage.getItem('mode') || 'light')

  useEffect(() => {
    const checkMode = () => {
      setMode(localStorage.getItem('mode') || 'light')
    }
    checkMode()
    const intervalId = setInterval(checkMode, 500)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const [theme, setTheme] = useState(lightTheme)

  useEffect(() => {
    if (mode === 'dark') {
      setTheme(darkTheme)
    } else {
      setTheme(lightTheme)
    }
  }, [mode, darkTheme, lightTheme])

  return (
    <Suspense fallback={<Preloader />}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {mode === 'dark' ? <GlobalStylesDark /> : <GlobalStyles />}
          <RoutingComponent />
          <SnackbarMessage />
        </ThemeProvider>
      </Provider>
    </Suspense>
  )
}
