import { useLazyQuery } from '@apollo/client';
import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GET_ALL_VESTING_EVENTS } from '../../../Queries/Admin';
import { Box, CircularProgress, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { push } from 'connected-react-router'
import SearchIcon from '@mui/icons-material/Search';
import IndividualVestingEvent from './IndividualVestingEvent';


const VestingEventsAdministration = () => {

    const [search, setSearch] = React.useState('');
    const { t } = useTranslation()
    const dispatch = useDispatch()


    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };

    const [getAllVestingEvents, { data: vestingEvents, loading: dataLoading }] = useLazyQuery(GET_ALL_VESTING_EVENTS, {
        variables: { search: search },
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllVestingEvents()
    }, [getAllVestingEvents]);

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
            <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        {t('ADMIN.VESTING_EVENTS')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "space-around"
                    }}>
                    <Box
                        onClick={() => {
                            dispatch(push('/admin-vesting-create'))
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <AddCircleIcon
                            sx={{
                                width: '50px',
                                height: '50px',
                                color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',

                            }}

                        />
                        <Typography sx={{
                            ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}>{t('ADMIN.NEW_VESTING')}</Typography>
                    </Box>
                    <TextField
                        id="filled-search"
                        label={t('ADMIN.ONLY_SEARCH')}
                        type="search"
                        variant="outlined"
                        onChange={handleTextChange}
                        size='small'
                        sx={{ width: '55%', ml: 4 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Grid >
            {dataLoading ?
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 8
                    }}>
                    <CircularProgress />
                </Box>
                :
                <TableContainer sx={{ width: '90%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                    <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", width: '20%' }} align="center">{t('WALLET_VIEW.DATE')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('WALLET_VIEW.SYMBOL')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('WALLET_VIEW.AMOUNT')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">Proposal</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('CREATE_OFFERING.CONAME')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('CREATE_COMPANY.USER_NAME')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vestingEvents?.vestingEvents?.map((event) => (
                                <IndividualVestingEvent key={event.id} data={event} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </Box >
    )
}

export default VestingEventsAdministration