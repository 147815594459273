import React from "react"
import { Fragment, useEffect, useState } from "react"
import Card from "../../../../components/Card/Card"
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, CircularProgress, useTheme } from "@mui/material"
import { Typography, Grid } from "@mui/material"
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { GET_ASSESSED_PROPOSALS, GET_ALL_PROPOSALS_NOT_DRAFT } from "../../../../Queries/Dashboard"
import { GET_TOKENS } from '../../../../Queries/Wallet'
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery } from "@apollo/client"
import { PieChart, Pie, CartesianGrid, XAxis, YAxis, Bar, Legend, ResponsiveContainer, Tooltip, BarChart } from 'recharts';
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from 'swiper'
const axios = require('axios')




const ApplicantMetricsDashboard = () => {
    // Import the `t` function from the `useTranslation` hook
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { user } = useAuth0()

    // Set up state variables using the `useState` hook
    const [walletEnabled, setWalletEnabled] = useState(false)

    const [wallets, setWallets] = useState([])

    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])

    async function checkEnabledStatus(walletNames) {
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}:`, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );

        setWalletEnabled(anyEnabled);
    }

    useEffect(() => {
        checkEnabledStatus(wallets)
        const intervalId = setInterval(() => {
            checkEnabledStatus(wallets)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [wallets])

    const [getAssessedProposasl, { data: assessedProposals }] = useLazyQuery(GET_ASSESSED_PROPOSALS, {
        variables: { userId: user?.email || user?.sub },
        pollInterval: 10000
    })

    useEffect(() => {
        getAssessedProposasl()
    }, [getAssessedProposasl])

    const [getAllProposalsNotDraft, { data: allProposals, loading: dataloading }] = useLazyQuery(GET_ALL_PROPOSALS_NOT_DRAFT, {
        variables: { userId: user?.email || user?.sub },
        pollInterval: 10000
    })

    useEffect(() => {
        getAllProposalsNotDraft()
    }, [getAllProposalsNotDraft])

    function calculateTotalInvestment(obj) {
        let totalInvestment = 0;
        for (let i = 0; i < obj?.length; i++) {
            if (obj[i]?.__typename === "Proposal" && obj[i]?.reqInvestAmount) {
                totalInvestment += obj[i]?.reqInvestAmount;
            }
        }
        return totalInvestment;
    }

    const totalAwarded = calculateTotalInvestment(assessedProposals?.proposals);

    function reorganizeData(inputData) {
        const reorganizedData = [];

        if (inputData) {
            const uniqueFunds = new Set();
            const possibleColors = ["#babbff", "#f4c211", "#7779ff", "#6b48ff"];

            for (let i = 0; i < inputData.length; i++) {
                const item = inputData[i];
                const fundName = item?.fund?.name;

                if (!uniqueFunds.has(fundName)) {
                    const color = possibleColors[i % possibleColors.length];

                    const newItem = {
                        name: fundName,
                        Multiplier: item?.fund?.roiMultiplier?.split(' ')[0].split('x')[0],
                        MultiText: item?.fund?.roiMultiplier?.split(' ')[0].split('x')[0] + 'X',
                        ROI: item?.fund?.roiTime?.split(' ')[0] * 12,
                        ROIText: item?.fund?.roiTime?.split(' ')[0] + ' months',
                        fill: color
                    };

                    reorganizedData.push(newItem);
                    uniqueFunds.add(fundName);
                }
            }
        }

        return reorganizedData;
    }

    const data = reorganizeData(assessedProposals?.proposals);

    const colors = ["#444054", "#ff0164", "#66ff66", "#14cfff", "#f4c211"];
    let colorIndex = 0;

    function addRandomColorToObjectArray(objArray) {
        const modifiedArray = objArray?.map(obj => {
            const randomColor = colors[colorIndex % colors.length];
            colorIndex++;
            return { ...obj, fill: randomColor };
        });

        return modifiedArray;
    }

    const resultArray = addRandomColorToObjectArray(assessedProposals?.proposals);

    const [oneAssessed, setOneIsAssessed] = useState(false);

    useEffect(() => {
        allProposals?.proposals?.map((prop) => {
            if (prop.status === 'assessed') {
                setOneIsAssessed(true)
            }
        })
    }, [allProposals])

    const [getAllTokens, { data: tokensData, loading: tokensLoading }] = useLazyQuery(GET_TOKENS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllTokens()
    }, [getAllTokens])

    const blockFrostAPITest = `https://cardano-preprod.blockfrost.io/api/v0/assets/`
    const testProjectID = 'preprod0YDs6YpK8cM5KFJA7bTZbOAmWVW0D94s'
    const axiosBFClient = axios.create({
        baseURL: blockFrostAPITest,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            project_id: testProjectID,
        },
    })

    const dvcObject = tokensData?.tokens?.find(item => item.symbol === "DVC");
    const [mintedDVC, setMintedDVC] = useState(undefined)
    const policyIdToQueryDVC = dvcObject?.policyId + dvcObject?.encodedName

    function getMintedDVC() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQueryDVC}/history`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (policyIdToQueryDVC.length > 7) {
            getMintedDVC().then((res) => {
                setMintedDVC(res)
            })
        }
    }, [policyIdToQueryDVC])

    function sumMintedAmountsDVC(data) {
        if (!data || !Array.isArray(data)) {
            return 0;
        }

        let totalAmount = 0;
        for (const item of data) {
            if (item && item.action === "minted") {
                totalAmount += parseInt(item.amount);
            }
        }
        return totalAmount;
    }

    const totalMintedAmountDVC = sumMintedAmountsDVC(mintedDVC);

    const tstALLIObject = tokensData?.tokens?.find(item => item.symbol === "tstALLI");
    const policyIdToQuerytstAlli = tstALLIObject?.policyId + tstALLIObject?.encodedName
    const [mintedtstALLI, setMintedtestALLI] = useState(undefined)

    function getMintedtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/history`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (policyIdToQuerytstAlli.length > 7) {
            getMintedtstAlli().then((res) => {
                setMintedtestALLI(res)
            })
        }
    }, [policyIdToQuerytstAlli])

    function getMCtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/addresses`)
            .then((res) => res.data)
        return finalRes
    }

    const [addressesHoldingtstAlli, setAddressesHoldingtstAlli] = useState(undefined)

    useEffect(() => {
        if (policyIdToQuerytstAlli.length > 7) {
            getMCtstAlli().then((res) => {
                setAddressesHoldingtstAlli(res)
            })
        }
    }, [policyIdToQuerytstAlli])

    const excludedAddress = "addr_test1qq4hg23ralkz9wzv9tn9kd2c2rxrzxckz8fecrdmrc8qltcd7r8mfgmnp3eaaasrds0fd42feweqvwxk8qqk2sy60wus7aaykm";

    const [totalQuantityHoldersTstAllies, setTotalQuantityHoldersTstAllies] = useState(0);

    useEffect(() => {
        const filteredArray = addressesHoldingtstAlli?.filter(item => item.address !== excludedAddress);
        const sum = filteredArray?.reduce((acc, curr) => acc + parseInt(curr.quantity, 10), 0);
        setTotalQuantityHoldersTstAllies(sum);
    }, [addressesHoldingtstAlli, excludedAddress]);


    function sumMintedAmountstAlli(data) {
        if (!data || !Array.isArray(data)) {
            return 0;
        }

        let totalAmount = 0;
        for (const item of data) {
            if (item && item.action === "minted") {
                totalAmount += parseInt(item.amount);
            }
        }
        return totalAmount;
    }

    const totalMintedAmountTstAlli = sumMintedAmountstAlli(mintedtstALLI);

    const [percentHoldingAlli, setPercentHoldingAlli] = useState(undefined)

    useEffect(() => {
        setPercentHoldingAlli((totalQuantityHoldersTstAllies / totalMintedAmountTstAlli) * 100)
    }, [totalQuantityHoldersTstAllies, totalMintedAmountTstAlli])

    const [screenSize, setScreenSize] = useState('');

    const updateScreenSize = () => {
        if (window.innerWidth < 768) {
            setScreenSize('xs');
        } else {
            setScreenSize('md');
        }
    };

    useEffect(() => {
        updateScreenSize();
        window.addEventListener('resize', updateScreenSize);
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    const tooltipFormatter = (value) => `$${value.toLocaleString()}`;

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return <Fragment>
        <Card color={actualThemeLight ? '#FFF' : "#1D1D1D"} sx={{ backgroundColor: actualThemeLight ? '#fff' : "#1D1D1D" }}>
            {allProposals?.proposals?.length === 0 ?
                <Box sx={{ width: '100%', height: '31vh', display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <Box sx={{ display: 'flex', width: { lg: '35%', xs: '90%' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(154, 29, 76, 0.3)', p: { lg: 1, xs: 0.2 }, borderRadius: '20px' }} >
                        <Avatar sx={{ width: { lg: 50, xs: 25 }, height: { lg: 50, xs: 25 } }} src={dvcObject?.logo}></Avatar>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>{dvcObject?.symbol}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MINTED_TOKENS')}:</Typography>
                                <Typography sx={{ fontSize: '14px', ml: 1, color: actualThemeLight ? 'black' : 'white' }} >{dvcObject?.symbol === "DVC" ? totalMintedAmountDVC.toLocaleString() : 0}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                <Typography sx={{ fontSize: '14px', ml: 1, color: actualThemeLight ? 'black' : 'white' }} >$ {dvcObject?.marketprice}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: { lg: '35%', xs: '90%' }, backgroundColor: 'rgba(154, 29, 76, 0.3)', p: { lg: 1, xs: 0.2 }, borderRadius: '20px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '60%' }} >
                            <Avatar sx={{ width: { lg: 50, xs: 25 }, height: { lg: 50, xs: 25 } }} src={tstALLIObject?.logo}></Avatar>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>{tstALLIObject?.symbol}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>{t('FUND.MINTED_TOKENS')}:</Typography>
                                    <Typography sx={{ fontSize: '14px', ml: 1, color: actualThemeLight ? 'black' : 'white' }} >{tstALLIObject?.symbol === "tstALLI" ? totalQuantityHoldersTstAllies?.toLocaleString() : 0}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                    <Typography sx={{ fontSize: '14px', ml: 1, color: actualThemeLight ? 'black' : 'white' }} >$ {tstALLIObject?.marketprice}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {percentHoldingAlli !== undefined && !isNaN(percentHoldingAlli) &&
                            <Box sx={{ width: '80px', height: '80px' }}>
                                <CircularProgress
                                    sx={{
                                        position: 'absolute',
                                        zIndex: 10,
                                        color: '#808080',
                                    }}
                                    size={80}
                                    thickness={22}
                                    variant="determinate"
                                    value={100}
                                />
                                <CircularProgress
                                    sx={{
                                        position: 'absolute',
                                        zIndex: 30,
                                        color: '#9A1D4C',
                                    }}
                                    size={80}
                                    thickness={22}
                                    variant="determinate"
                                    value={percentHoldingAlli < 1 ? percentHoldingAlli * 10 : percentHoldingAlli}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 50, position: 'absolute', mt: '24px', ml: '14px', color: 'white' }}>
                                    <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                                        {percentHoldingAlli.toFixed(2)}%
                                    </Typography>
                                    <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                                        Marketcap
                                    </Typography>
                                </Box>
                            </Box>}
                    </Box>
                </Box>
                :
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {/* METRICS */}
                    {dataloading && <Grid container spacing={1}>
                        <Box sx={{ width: '100%', height: '31vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    </Grid>}
                    {!dataloading &&
                        <Grid container>
                            {allProposals?.proposals?.length === 0 ?
                                <Grid item xs={12} md={8} sx={{ height: { md: '31vh', xs: '10vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center' }}>
                                        {/* NO POSTULATIONS */}
                                        <Typography sx={{ fontSize: '16px', color: '#9A1D4C', fontWeight: 'bold' }}>
                                            {t('DASHBOARD_VIEW.NO_APPLICATIONS')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                !oneAssessed ?
                                    <Grid item xs={12} md={8} sx={{ height: { md: '31vh', xs: '10vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center' }}>
                                            {/* NO POSTULATIONS */}
                                            <Typography sx={{ fontSize: '16px', color: '#9A1D4C', fontWeight: 'bold', textAlign: 'center' }}>
                                                {t('DASHBOARD_VIEW.NOT_ASSESSED_YET')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={8} >
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            {/* TOTAL RECEIVED */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '16px', color: '#828282', pr: '4px'
                                                    }}
                                                >
                                                    {t('DASHBOARD_VIEW.TOTAL_RECEIVED')}/{t('DASHBOARD_VIEW.TOTAL_AWARDED')}: $0 / ${totalAwarded?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* PIE CHART */}

                                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, justifyContent: 'center', height: '200px', width: '100%' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart width={200} height={200}>
                                                    <Pie
                                                        dataKey="reqInvestAmount"
                                                        isAnimationActive={true}
                                                        nameKey="fund.name"
                                                        data={resultArray}
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={68}
                                                        fill="#14cfff"
                                                        label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
                                                            const RADIAN = Math.PI / 180;
                                                            const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                            const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                                            return (
                                                                <text
                                                                    x={x}
                                                                    y={y}
                                                                    fill="#8884d8"
                                                                    textAnchor={x > cx ? 'start' : 'end'}
                                                                    dominantBaseline="central"
                                                                >
                                                                    ${value.toLocaleString()}
                                                                </text>
                                                            );
                                                        }}
                                                    />
                                                    <Tooltip formatter={tooltipFormatter} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                            {screenSize === 'md' &&
                                                <>
                                                    <BarChart width={200} height={200} data={data}>
                                                        <CartesianGrid strokeDasharray="1 1" />
                                                        <XAxis dataKey="name" fontSize={8} />
                                                        <YAxis fontSize={8} />
                                                        <Tooltip />
                                                        <Legend align="right" iconSize={8} height={12} />
                                                        <Bar name={t('ADMIN.DURATION_MONTHS')} maxBarSize={15} dataKey="ROI" fill="#f4c211" />
                                                    </BarChart>
                                                    <BarChart width={200} height={200} data={data}>
                                                        <CartesianGrid strokeDasharray="1 1" />
                                                        <XAxis dataKey="name" fontSize={8} />
                                                        <YAxis fontSize={8} />
                                                        <Tooltip />
                                                        <Legend align="right" iconSize={8} height={12} />
                                                        <Bar name={t('ADMIN.EXPECTED_ROI_MULTI')} maxBarSize={15} dataKey="Multiplier" fill="#7779ff" />
                                                    </BarChart>
                                                </>
                                            }




                                        </Box>
                                    </Grid>
                            }
                            {walletEnabled ?
                                <>
                                    <Grid item md={1} />
                                    <Grid item xs={12} md={3}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: 'rgba(154, 29, 76, 0.3)',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '20px',
                                                justifyContent: 'center'
                                            }}>
                                            {tokensLoading ?
                                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                                                    <CircularProgress />
                                                </Box>
                                                :
                                                <Swiper
                                                    loop={true}
                                                    spaceBetween={0}
                                                    slidesPerView={1}
                                                    modules={[Navigation, Pagination]}
                                                    navigation={true}
                                                    grabCursor={true}
                                                    className="recommended tokens slider"
                                                >
                                                    <SwiperSlide>
                                                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: { md: 0, xs: 1 }, mb: { md: 0, xs: 1 } }} >
                                                            <Avatar sx={{ width: 42, height: 42 }} src={dvcObject?.logo}></Avatar>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{dvcObject?.symbol}</Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Typography>{t('FUND.MINTED_TOKENS')}:</Typography>
                                                                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>{dvcObject?.symbol === "DVC" ? totalMintedAmountDVC?.toLocaleString() : 0}</Typography>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Typography>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                                                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>$ {dvcObject?.marketprice}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: { md: 0, xs: 1 }, mb: { md: 0, xs: 1 } }} >
                                                            <Avatar sx={{ width: 42, height: 42 }} src={tstALLIObject?.logo}></Avatar>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{tstALLIObject?.symbol}</Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Typography>{t('FUND.MINTED_TOKENS')}:</Typography>
                                                                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>{tstALLIObject?.symbol === "tstALLI" ? totalQuantityHoldersTstAllies?.toLocaleString() : 0}</Typography>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Typography>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                                                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>$ {tstALLIObject?.marketprice}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </SwiperSlide>

                                                </Swiper>
                                            }
                                        </Box>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item md={1} />
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}>
                                        <Box sx={{
                                            backgroundColor: 'rgba(154, 29, 76, 0.3)',
                                            borderRadius: '10px',
                                            width: '100%',
                                            height: '60%',
                                            mt: '15%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            p: 2,
                                            textAlign: 'center'
                                        }}>
                                            <Typography>{t('REGISTER_VIEW.CLICK_HERE_CONNECT_WALLET')}</Typography>
                                            <Button onClick={() => {
                                                dispatch(push('/profile-settings'))
                                            }} sx={{ mt: 1 }} variant="contained">{t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT').split(' ')[0]}</Button>
                                        </Box>
                                    </Grid>
                                </>
                            }
                        </Grid>}
                </Box>}
        </Card >
    </Fragment >
}

export default ApplicantMetricsDashboard