import React from 'react'
import {
    Box, Grid, Typography, TableBody, TableCell, Table, Button,
    TableContainer, TableHead, TableRow, FormControl,
    TextField, InputAdornment, CircularProgress, Modal, IconButton,
    Radio, FormControlLabel, RadioGroup, LinearProgress, Tooltip,
    useTheme
} from '@mui/material';
import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect } from 'react';
import { GET_ALL_FUNDS_ADMIN, GET_SPECIFIC_FUND_ADMIN } from '../../../Queries/Admin'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { showMessage } from '../../../store/actions/snackBarActions';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FundAdministrationRow from './FundAdministrationRow';
import CloseIcon from '@mui/icons-material/Close'
import { UPDATE_FUND } from '../../../Queries/Admin';
import { UPDATE_EVENT, CREATE_EVENT_RELATE_FUND } from '../../../Queries/Admin'
import SCAdministration from '../SCAdministration/SCAdministration';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Address } from '@emurgo/cardano-serialization-lib-asmjs'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const FundManagement = () => {

    const [search, setSearch] = useState('');
    const [ordering, setOrdering] = useState({ status: "ASC" });
    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };
    const [updateFund] = useMutation(UPDATE_FUND)
    const [updateEvent] = useMutation(UPDATE_EVENT)
    const [createEvent] = useMutation(CREATE_EVENT_RELATE_FUND)
    const [isCoreTeamWallet, setIsCoreTeamWallet] = useState(null)
    const [isValidatorWallet, setIsValidatorWallet] = useState(null)

    const ValidatorWalletList = process.env.REACT_APP_TOKEN_WALLET
    const CoreTeamWalletList = process.env.REACT_APP_CORE_TEAM_WALLET

    const [validatorWalletArray, setValidatorWalletArray] = useState(undefined)
    const [CoreTeamWalletArray, setCoreTeamWalletArray] = useState(undefined)

    const [fetchFunds, { data: fundsData, loading: loadingFundsData }] = useLazyQuery(GET_ALL_FUNDS_ADMIN, {
        variables: {
            order: ordering
        },
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    });

    const [filteredFunds, setFilteredFunds] = useState([]);

    useEffect(() => {
        if (fundsData?.funds) {
            const filtered = fundsData.funds.filter(fund => {
                const nameMatches = fund.name.toLowerCase().includes(search.toLowerCase());
                const offerPurchaseMatches = fund.offerPurchase.some(offer => {
                    const user = offer.users;
                    return user.userName.toLowerCase().includes(search.toLowerCase()) ||
                        user.id.toLowerCase().includes(search.toLowerCase());
                });
                return nameMatches || offerPurchaseMatches;
            });
            setFilteredFunds(filtered);
        }
    }, [search, fundsData]);

    const [selectedFundId, setSelectedFundId] = useState(undefined)

    const [fetchSpecificFund, { data: specificFundData, loading: loadingSpecificFund }] = useLazyQuery(GET_SPECIFIC_FUND_ADMIN, {
        variables: {
            fundId: selectedFundId,
        },
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (selectedFundId !== undefined) {
            fetchSpecificFund();
        }
    }, [selectedFundId]);


    useEffect(() => {
        fetchFunds();
    }, [fetchFunds]);

    const { t } = useTranslation()
    const todayDate = new Date().toISOString().split('T')[0]
    const [matchingEvent, setMatchingEvent] = useState(null);
    const [startDay, setStartDay] = useState(null);
    const [startMonth, setStartMonth] = useState(null);
    const [startYear, setStartYear] = useState(null);
    const [endDay, setEndDay] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [endYear, setEndYear] = useState(null);

    useEffect(() => {
        function getCollectingEvent(fundObject) {
            if (fundObject !== undefined) {
                const { event } = fundObject;

                if (event && event.length > 0) {
                    const matchingEvent = event.find(e => e.titleEn === 'Collecting');
                    return matchingEvent || null;
                } else {
                    return null;
                }
            }
        }

        const matchingEvent = getCollectingEvent(specificFundData?.funds[0]);
        setMatchingEvent(matchingEvent);

    }, [specificFundData]);

    useEffect(() => {
        if (matchingEvent) {
            const startDay = matchingEvent?.startDate?.split('-')[2].split('T')[0];
            const startMonth = matchingEvent?.startDate?.split('-')[1];
            const startYear = matchingEvent?.startDate?.split('-')[0];
            const endDay = matchingEvent?.endDate?.split('-')[2].split('T')[0];
            const endMonth = matchingEvent?.endDate?.split('-')[1];
            const endYear = matchingEvent?.endDate?.split('-')[0];

            setStartDay(startDay);
            setStartMonth(startMonth);
            setStartYear(startYear)
            setEndDay(endDay);
            setEndMonth(endMonth);
            setEndYear(endYear);
        }
    }, [matchingEvent]);

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    const totalAmountInvestorDeposited = specificFundData?.funds[0]?.offerPurchase?.reduce((acc, transaction) => {
        if (!excludedStatuses.includes(transaction.status)) {
            acc += transaction.amount;
        }
        return acc;
    }, 0);

    const [totalAmountInvIntentions, setTotalAmountInvIntentions] = useState(0);

    useEffect(() => {
        if (specificFundData?.funds[0]?.offerPurchase) {
            const total = specificFundData.funds[0].offerPurchase.reduce((acc, curr) => acc + curr.amount, 0);
            setTotalAmountInvIntentions(total);
        }
    }, [specificFundData]);

    function countUniqueUsers(array) {
        const uniqueUsers = new Set();
        array?.forEach(element => {
            if (element?.users && element?.users?.id) {
                uniqueUsers?.add(element?.users?.id);
            }
        });
        return uniqueUsers?.size > 0 ? uniqueUsers?.size : 0;
    }

    const uniqueUserCount = countUniqueUsers(specificFundData?.funds[0]?.offerPurchase);
    const [API, setAPI] = useState(undefined)
    const [selectedWallet, setSelectedWallet] = useState(null)
    const dispatch = useDispatch()
    const [wallets, setWallets] = useState([])

    async function checkEnabledStatus(walletNames) {
        // eslint-disable-next-line
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                            setSelectedWallet(walletName)
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}: `, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );
    }

    const [XChangeAddress, setXChangeAddress] = useState(undefined)
    const getChangeAddress = async () => {
        if (API !== undefined) {
            try {
                const raw = await API.getChangeAddress()
                const changeAddressObj = Address.from_bytes(
                    Buffer.from(raw, 'hex')
                ).to_bech32()
                setXChangeAddress(changeAddressObj)
            } catch (err) {
                setSelectedWallet(null)
            }
        }
    }

    useEffect(() => {
        checkEnabledStatus(wallets)
        const intervalId = setInterval(() => {
            checkEnabledStatus(wallets)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [wallets])

    const [netId, setNetId] = useState(undefined)
    const appropiateNetworkIdIdentified = 0
    const [isCorrectNetworkSelected, setIsCorrectNetworkSelected] = useState(null)

    useEffect(() => {
        if (netId !== undefined) {
            if (appropiateNetworkIdIdentified === netId) {
                setIsCorrectNetworkSelected(true)
            } else {
                setIsCorrectNetworkSelected(false)
            }
        }
    }, [appropiateNetworkIdIdentified, netId])

    const getNetId = async () => {
        if (API !== null) {
            try {
                const id = await API.getNetworkId()
                setNetId(id)
            } catch (err) {
                setNetId(undefined)
            }
        }
    }

    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])

    const [walletEnabled, setWalletEnabled] = useState(false)
    const checkIfWalletEnabled = async () => {
        let walletIsEnabled = false
        try {
            const walletName = selectedWallet
            walletIsEnabled = await window.cardano[walletName].isEnabled()
            setWalletEnabled(walletIsEnabled)
        } catch (err) {
            setSelectedWallet(null)
        }
        return walletIsEnabled
    }

    const enableWallet = async () => {
        const walletKey = selectedWallet
        try {
            setAPI(await window.cardano[walletKey].enable())
            setWalletEnabled(true)
        } catch (err) {
            setSelectedWallet(null)
        }
        return checkIfWalletEnabled()
    }

    useEffect(() => {
        if (selectedWallet !== null) {
            enableWallet()
        }
    }, [selectedWallet])

    const refreshData = async () => {
        try {
            if (walletEnabled && API !== null) {
                await getChangeAddress()
                await getNetId()
            }
        } catch (err) {
            setSelectedWallet(null)
        }
    }

    useEffect(async () => {
        pollWallets()
        await refreshData()
        await getChangeAddress()
        await getNetId()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            getNetId()
            getChangeAddress()
        }, 2000);

        return () => clearInterval(intervalId);
    }, [API]);

    const [sort, setSort] = useState('date_desc')

    const receivedOrderingFromChild = (data) => {
        setSort(data);
    };

    const [totalSCCreated, setTotalSCCreated] = useState(0)

    const receivedTotalCreatedSCFromChild = (data) => {
        setTotalSCCreated(data);
    };

    const [totalSCActive, setTotalSCActive] = useState(0)

    const receivedTotalActiveSCFromChild = (data) => {
        setTotalSCActive(data);
    };

    // eslint-disable-next-line
    const [totalTokenSCActive, setTotalTokenSCActive] = useState(0)

    const receivedTotalTokensActiveSCFromChild = (data) => {
        setTotalTokenSCActive(data);
    };

    const [totalALLISCActive, setTotalALLISCActive] = useState(0)

    const receivedTotalALLIActiveSCFromChild = (data) => {
        setTotalALLISCActive((prevTotal) => prevTotal + data);
    };

    const sortArray = (array, sortOption) => {
        try {
            const sortedArray = [...array];

            switch (sortOption) {
                case 'username_asc':
                    sortedArray.sort((a, b) => a.users.userName.localeCompare(b.users.userName));
                    break;
                case 'username_desc':
                    sortedArray.sort((a, b) => b.users.userName.localeCompare(a.users.userName));
                    break;
                case 'amount_asc':
                    sortedArray.sort((a, b) => a.amount - b.amount);
                    break;
                case 'amount_desc':
                    sortedArray.sort((a, b) => b.amount - a.amount);
                    break;
                case 'date_asc':
                    sortedArray.sort(
                        (a, b) => new Date(a.on) - new Date(b.on)
                    );
                    break;
                case 'date_desc':
                    sortedArray.sort(
                        (a, b) => new Date(b.on) - new Date(a.on)
                    );
                    break;
                case 'status_asc':
                    sortedArray.sort((a, b) => a.status.localeCompare(b.status));
                    break;
                case 'status_desc':
                    sortedArray.sort((a, b) => b.status.localeCompare(a.status));
                    break;
                default:
                    break;
            }

            return sortedArray;
        } catch (error) {
            return array;
        }
    };

    const [sortedArray, setSortedArray] = useState([]);

    const updateSortedArray = () => {
        if (specificFundData !== undefined) {
            const newArray = sortArray(specificFundData?.funds[0]?.offerPurchase, sort);
            setSortedArray([...newArray]);
        }
    };

    const [updatedFundsData, setUpdatedFundsData] = useState([])

    const updateFundData = () => {
        setUpdatedFundsData(filteredFunds)
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateSortedArray();
            updateFundData()
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [specificFundData, sort, fundsData, updateSortedArray, updateFundData]);

    const [isThresholdMet, setIsThresholdmet] = useState(false)

    useEffect(() => {
        if (specificFundData !== undefined &&
            totalAmountInvestorDeposited >= (((specificFundData?.funds[0]?.amountToReach * specificFundData?.funds[0]?.successThreshold) / 100).toLocaleString())
        ) {
            setIsThresholdmet(true)
        } else {
            setIsThresholdmet(false)
        }
    }, [totalAmountInvestorDeposited])

    const receiveDataFromChild = (data) => {
        setSelectedFundId(data);
    };

    const [openNextStatus, setOpenNextStatus] = React.useState(false);
    const handleOpenNextStatus = () => setOpenNextStatus(true);
    const handleCloseNextStatus = () => {
        setOpenNextStatus(false);
        setNextStatusText(currentFundStatus);
        setNewCollectingEndDate(undefined);
        setNewPublishedStartDate(undefined)
        setNewPublishedEndDate(undefined)
        setNewOpenStartDate(undefined)
        setNewOpenEndDate(undefined)
        setNewAssessmentStartDate(undefined)
        setNewAssessmentEndDate(undefined)
        setNewGovernanceStartDate(undefined)
        setNewGovernanceEndDate(undefined)
        setNewVestingStartDate(undefined)
        setNewVestingEndDate(undefined)
        setNewExecutionStartDate(undefined)
        setNewROIStartDate(undefined)
        setNewClosedStartDate(undefined)
        setDaysAddedToCollectingEndDate(undefined)
        setDaysAddedToPublishedStartDate(undefined)
        setDaysAddedToPublishedEndDate(undefined)
        setDaysAddedToOpenStartDate(undefined)
        setDaysAddedToOpenEndDate(undefined)
        setDaysAddedToAssessmentStartDate(undefined)
        setDaysAddedToAssessmentEndDate(undefined)
        setDaysAddedToGovernanceStartDate(undefined)
        setDaysAddedToGovernanceEndDate(undefined)
        setDaysAddedToVestingStartDate(undefined)
        setOpenConfirmationClosingNextStatus(false)
    };

    const [openConfirmationClosingNextStatus, setOpenConfirmationClosingNextStatus] = React.useState(false)
    const handleOpenConfirmationClosingNextStatus = () => setOpenConfirmationClosingNextStatus(true)
    const handleCloseConfirmationClosingNextStatus = () => setOpenConfirmationClosingNextStatus(false)

    const [currentFundStatus, setCurrentFundStatus] = useState('')
    const [nextStatusText, setNextStatusText] = useState(currentFundStatus)

    useEffect(() => {
        setNextStatusText(currentFundStatus)
    }, [currentFundStatus])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '15px',
        pt: 4,
        pl: 2,
        pr: 2,
        pb: 1
    };

    const [thisFundId, setThisFundId] = useState('')
    const [fundName, setFundName] = useState('')

    useEffect(() => {
        setFundName(specificFundData?.funds[0]?.name)
        setThisFundId(specificFundData?.funds[0]?.id)
    }, [specificFundData])

    //1
    const [dbCollectingEvent, setDbCollectingEvent] = useState(undefined)
    const [newCollectingEndDate, setNewCollectingEndDate] = useState(undefined)
    const dbCollectingStartDateDay = dbCollectingEvent?.startDate?.split('-')[2].split('T')[0]
    const dbCollectingStartDateMonth = dbCollectingEvent?.startDate?.split('-')[1]
    const dbCollectingEndDateDay = dbCollectingEvent?.endDate?.split('-')[2].split('T')[0]
    const dbCollectingEndDateMonth = dbCollectingEvent?.endDate?.split('-')[1]
    const newCollectingEndDateDay = newCollectingEndDate?.split('-')[2].split('T')[0]
    const newCollectingEndDateMonth = newCollectingEndDate?.split('-')[1]
    //2
    const [dbPublishedEvent, setDbPublishedEvent] = useState(undefined)
    const [newPublishedStartDate, setNewPublishedStartDate] = useState(undefined)
    const [newPublishedEndDate, setNewPublishedEndDate] = useState(undefined)
    const dbPublishedStartDateDay = dbPublishedEvent?.startDate?.split('-')[2].split('T')[0]
    const dbPublishedStartDateMonth = dbPublishedEvent?.startDate?.split('-')[1]
    const dbPublishedEndDateDay = dbPublishedEvent?.endDate?.split('-')[2].split('T')[0]
    const dbPublishedEndDateMonth = dbPublishedEvent?.endDate?.split('-')[1]
    const newPublishedStartDateDay = newPublishedStartDate?.split('-')[2].split('T')[0]
    const newPublishedStartDateMonth = newPublishedStartDate?.split('-')[1]
    const newPublishedEndDateDay = newPublishedEndDate?.split('-')[2].split('T')[0]
    const newPublishedEndDateMonth = newPublishedEndDate?.split('-')[1]
    //3
    const [dbOpenEvent, setDbOpenEvent] = useState(undefined)
    const [newOpenStartDate, setNewOpenStartDate] = useState(undefined)
    const [newOpenEndDate, setNewOpenEndDate] = useState(undefined)
    const dbOpenStartDateDay = dbOpenEvent?.startDate?.split('-')[2].split('T')[0]
    const dbOpenStartDateMonth = dbOpenEvent?.startDate?.split('-')[1]
    const dbOpenEndDateDay = dbOpenEvent?.endDate?.split('-')[2].split('T')[0]
    const dbOpenEndDateMonth = dbOpenEvent?.endDate?.split('-')[1]
    const newOpenStartDateDay = newOpenStartDate?.split('-')[2].split('T')[0]
    const newOpenStartDateMonth = newOpenStartDate?.split('-')[1]
    const newOpenEndDateDay = newOpenEndDate?.split('-')[2].split('T')[0]
    const newOpenEndDateMonth = newOpenEndDate?.split('-')[1]
    //4
    const [dbAssessmentEvent, setDbAssessmentEvent] = useState(undefined)
    const [newAssessmentStartDate, setNewAssessmentStartDate] = useState(undefined)
    const [newAssessmentEndDate, setNewAssessmentEndDate] = useState(undefined)
    const dbAssessmentStartDateDay = dbAssessmentEvent?.startDate?.split('-')[2].split('T')[0]
    const dbAssessmentStartDateMonth = dbAssessmentEvent?.startDate?.split('-')[1]
    const dbAssessmentEndDateDay = dbAssessmentEvent?.endDate?.split('-')[2].split('T')[0]
    const dbAssessmentEndDateMonth = dbAssessmentEvent?.endDate?.split('-')[1]
    const newAssessmentStartDateDay = newAssessmentStartDate?.split('-')[2].split('T')[0]
    const newAssessmentStartDateMonth = newAssessmentStartDate?.split('-')[1]
    const newAssessmentEndDateDay = newAssessmentEndDate?.split('-')[2].split('T')[0]
    const newAssessmentEndDateMonth = newAssessmentEndDate?.split('-')[1]
    //5
    const [dbGovernanceEvent, setDbGovernanceEvent] = useState(undefined)
    const [newGovernanceStartDate, setNewGovernanceStartDate] = useState(undefined)
    const [newGovernanceEndDate, setNewGovernanceEndDate] = useState(undefined)
    const dbGovernanceStartDateDay = dbGovernanceEvent?.startDate?.split('-')[2].split('T')[0]
    const dbGovernanceStartDateMonth = dbGovernanceEvent?.startDate?.split('-')[1]
    const dbGovernanceEndDateDay = dbGovernanceEvent?.endDate?.split('-')[2].split('T')[0]
    const dbGovernanceEndDateMonth = dbGovernanceEvent?.endDate?.split('-')[1]
    const newGovernanceStartDateDay = newGovernanceStartDate?.split('-')[2].split('T')[0]
    const newGovernanceStartDateMonth = newGovernanceStartDate?.split('-')[1]
    const newGovernanceEndDateDay = newGovernanceEndDate?.split('-')[2].split('T')[0]
    const newGovernanceEndDateMonth = newGovernanceEndDate?.split('-')[1]
    //6
    const [dbVestingEvent, setDbVestingEvent] = useState(undefined)
    const [newVestingStartDate, setNewVestingStartDate] = useState(undefined)
    const [newVestingEndDate, setNewVestingEndDate] = useState(undefined)
    const dbVestingStartDateDay = dbVestingEvent?.startDate?.split('-')[2].split('T')[0]
    const dbVestingStartDateMonth = dbVestingEvent?.startDate?.split('-')[1]
    const dbVestingEndDateDay = dbVestingEvent?.endDate?.split('-')[2].split('T')[0]
    const dbVestingEndDateMonth = dbVestingEvent?.endDate?.split('-')[1]
    const newVestingStartDateDay = newVestingStartDate?.split('-')[2].split('T')[0]
    const newVestingStartDateMonth = newVestingStartDate?.split('-')[1]
    const newVestingEndDateDay = newVestingEndDate?.split('-')[2].split('T')[0]
    const newVestingEndDateMonth = newVestingEndDate?.split('-')[1]
    //7
    const [dbExecutionEvent, setDbExecutionEvent] = useState(undefined)
    const [newExecutionStartDate, setNewExecutionStartDate] = useState(undefined)
    const dbExecutionStartDateDay = dbExecutionEvent?.startDate?.split('-')[2].split('T')[0]
    const dbExecutionStartDateMonth = dbExecutionEvent?.startDate?.split('-')[1]
    const newExecutionStartDateDay = newExecutionStartDate?.split('-')[2].split('T')[0]
    const newExecutionStartDateMonth = newExecutionStartDate?.split('-')[1]
    //8
    const [dbROIEvent, setDbROIEvent] = useState(undefined)
    const [newROIStartDate, setNewROIStartDate] = useState(undefined)
    const dbROIStartDateDay = dbROIEvent?.startDate?.split('-')[2].split('T')[0]
    const dbROIStartDateMonth = dbROIEvent?.startDate?.split('-')[1]
    const newROIStartDateDay = newROIStartDate?.split('-')[2].split('T')[0]
    const newROIStartDateMonth = newROIStartDate?.split('-')[1]
    //9
    const [dbCloseEvent, setDbCloseEvent] = useState(undefined)
    const [newClosedStartDate, setNewClosedStartDate] = useState(undefined)
    const dbClosedStartDateDay = dbCloseEvent?.startDate?.split('-')[2].split('T')[0]
    const dbClosedStartDateMonth = dbCloseEvent?.startDate?.split('-')[1]
    const newClosedStartDateDay = newClosedStartDate?.split('-')[2].split('T')[0]
    const newClosedStartDateMonth = newClosedStartDate?.split('-')[1]

    //Saving new function for loading
    const [savingNewFunction, setSavingNewFunction] = useState(false)

    const uniqueExecutionID = uuidv4()
    const uniqueROIID = uuidv4()
    const uniqueClosedID = uuidv4()

    const [daysAddedToCollectingEndDate, setDaysAddedToCollectingEndDate] = useState(undefined);
    const [daysAddedToPublishedStartDate, setDaysAddedToPublishedStartDate] = useState(undefined);
    const [daysAddedToPublishedEndDate, setDaysAddedToPublishedEndDate] = useState(undefined);
    const [daysAddedToOpenStartDate, setDaysAddedToOpenStartDate] = useState(undefined);
    const [daysAddedToOpenEndDate, setDaysAddedToOpenEndDate] = useState(undefined);
    const [daysAddedToAssessmentStartDate, setDaysAddedToAssessmentStartDate] = useState(undefined);
    const [daysAddedToAssessmentEndDate, setDaysAddedToAssessmentEndDate] = useState(undefined);
    const [daysAddedToGovernanceStartDate, setDaysAddedToGovernanceStartDate] = useState(undefined);
    const [daysAddedToGovernanceEndDate, setDaysAddedToGovernanceEndDate] = useState(undefined);
    const [daysAddedToVestingStartDate, setDaysAddedToVestingStartDate] = useState(undefined);

    useEffect(() => {
        if (newCollectingEndDate && dbCollectingEvent) {
            const dateToCompare = new Date(newCollectingEndDate);
            const dbEndDate = new Date(dbCollectingEvent.endDate);
            let daysAddedToPublishedEndDate = 0;
            if (dateToCompare > dbEndDate) {
                daysAddedToPublishedEndDate = Math.ceil((dateToCompare - dbEndDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToPublishedEndDate = -Math.ceil((dbEndDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToCollectingEndDate(daysAddedToPublishedEndDate);
        }
        if (newPublishedStartDate && dbPublishedEvent) {
            const dateToCompare = new Date(newPublishedStartDate);
            const dbStartDate = new Date(dbPublishedEvent.startDate);
            let daysAddedToPublishStartDate = 0;
            if (dateToCompare > dbStartDate) {
                daysAddedToPublishStartDate = Math.ceil((dateToCompare - dbStartDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToPublishStartDate = -Math.ceil((dbStartDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToPublishedStartDate(daysAddedToPublishStartDate);
        }
        if (newPublishedEndDate && dbPublishedEvent) {
            const dateToCompare = new Date(newPublishedEndDate);
            const dbEndDate = new Date(dbPublishedEvent.endDate);
            let daysAddedToPublishEndDate = 0;
            if (dateToCompare > dbEndDate) {
                daysAddedToPublishEndDate = Math.ceil((dateToCompare - dbEndDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToPublishEndDate = -Math.ceil((dbEndDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToPublishedEndDate(daysAddedToPublishEndDate);
        }
        if (newOpenStartDate && dbOpenEvent) {
            const dateToCompare = new Date(newOpenStartDate);
            const dbStartDate = new Date(dbOpenEvent.startDate);
            let daysAddedToOpenStartDate = 0;
            if (dateToCompare > dbStartDate) {
                daysAddedToOpenStartDate = Math.ceil((dateToCompare - dbStartDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToOpenStartDate = -Math.ceil((dbStartDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToOpenStartDate(daysAddedToOpenStartDate);
        }
        if (newOpenEndDate && dbOpenEvent) {
            const dateToCompare = new Date(newOpenEndDate);
            const dbEndDate = new Date(dbOpenEvent.endDate);
            let daysAddedToOpenEndDate = 0;
            if (dateToCompare > dbEndDate) {
                daysAddedToOpenEndDate = Math.ceil((dateToCompare - dbEndDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToOpenEndDate = -Math.ceil((dbEndDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToOpenEndDate(daysAddedToOpenEndDate);
        }
        if (newAssessmentStartDate && dbAssessmentEvent) {
            const dateToCompare = new Date(newAssessmentStartDate);
            const dbStartDate = new Date(dbAssessmentEvent.startDate);
            let daysAddedToAssessmentStartDate = 0;
            if (dateToCompare > dbStartDate) {
                daysAddedToAssessmentStartDate = Math.ceil((dateToCompare - dbStartDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToAssessmentStartDate = -Math.ceil((dbStartDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToAssessmentStartDate(daysAddedToAssessmentStartDate);
        }
        if (newAssessmentEndDate && dbAssessmentEvent) {
            const dateToCompare = new Date(newAssessmentEndDate);
            const dbEndDate = new Date(dbAssessmentEvent.endDate);
            let daysAddedToAssessmentEndDate = 0;
            if (dateToCompare > dbEndDate) {
                daysAddedToAssessmentEndDate = Math.ceil((dateToCompare - dbEndDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToAssessmentEndDate = -Math.ceil((dbEndDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToAssessmentEndDate(daysAddedToAssessmentEndDate);
        }
        if (newGovernanceStartDate && dbGovernanceEvent) {
            const dateToCompare = new Date(newGovernanceStartDate);
            const dbStartDate = new Date(dbGovernanceEvent.startDate);
            let daysAddedToGovernanceStartDate = 0;
            if (dateToCompare > dbStartDate) {
                daysAddedToGovernanceStartDate = Math.ceil((dateToCompare - dbStartDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToGovernanceStartDate = -Math.ceil((dbStartDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToGovernanceStartDate(daysAddedToGovernanceStartDate);
        }
        if (newGovernanceEndDate && dbGovernanceEvent) {
            const dateToCompare = new Date(newGovernanceEndDate);
            const dbEndDate = new Date(dbGovernanceEvent.endDate);
            let daysAddedToGovernanceEndDate = 0;
            if (dateToCompare > dbEndDate) {
                daysAddedToGovernanceEndDate = Math.ceil((dateToCompare - dbEndDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToGovernanceEndDate = -Math.ceil((dbEndDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToGovernanceEndDate(daysAddedToGovernanceEndDate);
        }
        if (newVestingStartDate && dbVestingEvent) {
            const dateToCompare = new Date(newVestingStartDate);
            const dbStartDate = new Date(dbVestingEvent.startDate);
            let daysAddedToVestingStartDate = 0;
            if (dateToCompare > dbStartDate) {
                daysAddedToVestingStartDate = Math.ceil((dateToCompare - dbStartDate) / (1000 * 60 * 60 * 24));
            } else {
                daysAddedToVestingStartDate = -Math.ceil((dbStartDate - dateToCompare) / (1000 * 60 * 60 * 24));
            }
            setDaysAddedToVestingStartDate(daysAddedToVestingStartDate);
        }
    }, [
        newCollectingEndDate,
        newPublishedStartDate,
        newPublishedEndDate,
        dbPublishedEvent,
        newOpenStartDate,
        dbOpenEvent,
        newOpenEndDate,
        newAssessmentStartDate,
        dbAssessmentEvent,
        newAssessmentEndDate,
        newGovernanceStartDate,
        dbGovernanceEvent,
        newGovernanceEndDate,
        newVestingStartDate,
        dbVestingEvent
    ]);

    function addDaysToDate(daysToAdd, dateToAddDays) {
        if (!dateToAddDays) {
            return null;
        }
        const date = new Date(dateToAddDays);
        if (isNaN(date.getTime())) {
            return null;
        }
        date.setDate(date.getDate() + daysToAdd);
        return date.toISOString().split('T')[0];
    }

    useEffect(() => {
        if (daysAddedToCollectingEndDate && currentFundStatus === nextStatusText) {
            const newDatePublishedStart = addDaysToDate(daysAddedToCollectingEndDate, dbPublishedEvent?.startDate);
            setNewPublishedStartDate(newDatePublishedStart);
            const newDatePublishedEnd = addDaysToDate(daysAddedToCollectingEndDate, dbPublishedEvent?.endDate);
            setNewPublishedEndDate(newDatePublishedEnd);
            const newDateOpenStart = addDaysToDate(daysAddedToCollectingEndDate, dbOpenEvent?.startDate);
            setNewOpenStartDate(newDateOpenStart);
            const newDateOpenEnd = addDaysToDate(daysAddedToCollectingEndDate, dbOpenEvent?.endDate);
            setNewOpenEndDate(newDateOpenEnd);
            const newDateAssessmentStart = addDaysToDate(daysAddedToCollectingEndDate, dbAssessmentEvent?.startDate);
            setNewAssessmentStartDate(newDateAssessmentStart);
            const newDateAssessmentEnd = addDaysToDate(daysAddedToCollectingEndDate, dbAssessmentEvent?.endDate);
            setNewAssessmentEndDate(newDateAssessmentEnd);
            const newDateGovernanceStart = addDaysToDate(daysAddedToCollectingEndDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToCollectingEndDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToCollectingEndDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToCollectingEndDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToPublishedStartDate && currentFundStatus === nextStatusText) {
            const newDatePublishedEnd = addDaysToDate(daysAddedToPublishedStartDate, dbPublishedEvent?.endDate);
            setNewPublishedEndDate(newDatePublishedEnd);
            const newDateOpenStart = addDaysToDate(daysAddedToPublishedStartDate, dbOpenEvent?.startDate);
            setNewOpenStartDate(newDateOpenStart);
            const newDateOpenEnd = addDaysToDate(daysAddedToPublishedStartDate, dbOpenEvent?.endDate);
            setNewOpenEndDate(newDateOpenEnd);
            const newDateAssessmentStart = addDaysToDate(daysAddedToPublishedStartDate, dbAssessmentEvent?.startDate);
            setNewAssessmentStartDate(newDateAssessmentStart);
            const newDateAssessmentEnd = addDaysToDate(daysAddedToPublishedStartDate, dbAssessmentEvent?.endDate);
            setNewAssessmentEndDate(newDateAssessmentEnd);
            const newDateGovernanceStart = addDaysToDate(daysAddedToPublishedStartDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToPublishedStartDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToPublishedStartDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToPublishedStartDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToPublishedEndDate && currentFundStatus === nextStatusText) {
            const newDateOpenStart = addDaysToDate(daysAddedToPublishedEndDate, dbOpenEvent?.startDate);
            setNewOpenStartDate(newDateOpenStart);
            const newDateOpenEnd = addDaysToDate(daysAddedToPublishedEndDate, dbOpenEvent?.endDate);
            setNewOpenEndDate(newDateOpenEnd);
            const newDateAssessmentStart = addDaysToDate(daysAddedToPublishedEndDate, dbAssessmentEvent?.startDate);
            setNewAssessmentStartDate(newDateAssessmentStart);
            const newDateAssessmentEnd = addDaysToDate(daysAddedToPublishedEndDate, dbAssessmentEvent?.endDate);
            setNewAssessmentEndDate(newDateAssessmentEnd);
            const newDateGovernanceStart = addDaysToDate(daysAddedToPublishedEndDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToPublishedEndDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToPublishedEndDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToPublishedEndDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToOpenStartDate && currentFundStatus === nextStatusText) {
            const newDateOpenEnd = addDaysToDate(daysAddedToOpenStartDate, dbOpenEvent?.endDate);
            setNewOpenEndDate(newDateOpenEnd);
            const newDateAssessmentStart = addDaysToDate(daysAddedToOpenStartDate, dbAssessmentEvent?.startDate);
            setNewAssessmentStartDate(newDateAssessmentStart);
            const newDateAssessmentEnd = addDaysToDate(daysAddedToOpenStartDate, dbAssessmentEvent?.endDate);
            setNewAssessmentEndDate(newDateAssessmentEnd);
            const newDateGovernanceStart = addDaysToDate(daysAddedToOpenStartDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToOpenStartDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToOpenStartDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToOpenStartDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToOpenEndDate && currentFundStatus === nextStatusText) {
            const newDateAssessmentStart = addDaysToDate(daysAddedToOpenEndDate, dbAssessmentEvent?.startDate);
            setNewAssessmentStartDate(newDateAssessmentStart);
            const newDateAssessmentEnd = addDaysToDate(daysAddedToOpenEndDate, dbAssessmentEvent?.endDate);
            setNewAssessmentEndDate(newDateAssessmentEnd);
            const newDateGovernanceStart = addDaysToDate(daysAddedToOpenEndDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToOpenEndDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToOpenEndDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToOpenEndDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToAssessmentStartDate && currentFundStatus === nextStatusText) {
            const newDateAssessmentEnd = addDaysToDate(daysAddedToAssessmentStartDate, dbAssessmentEvent?.endDate);
            setNewAssessmentEndDate(newDateAssessmentEnd);
            const newDateGovernanceStart = addDaysToDate(daysAddedToAssessmentStartDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToAssessmentStartDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToAssessmentStartDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToAssessmentStartDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToAssessmentEndDate && currentFundStatus === nextStatusText) {
            const newDateGovernanceStart = addDaysToDate(daysAddedToAssessmentEndDate, dbGovernanceEvent?.startDate);
            setNewGovernanceStartDate(newDateGovernanceStart);
            const newDateGovernanceEnd = addDaysToDate(daysAddedToAssessmentEndDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToAssessmentEndDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToAssessmentEndDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToGovernanceStartDate && currentFundStatus === nextStatusText) {
            const newDateGovernanceEnd = addDaysToDate(daysAddedToGovernanceStartDate, dbGovernanceEvent?.endDate);
            setNewGovernanceEndDate(newDateGovernanceEnd);
            const newDateVestingStart = addDaysToDate(daysAddedToGovernanceStartDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToGovernanceStartDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToGovernanceEndDate && currentFundStatus === nextStatusText) {
            const newDateVestingStart = addDaysToDate(daysAddedToGovernanceEndDate, dbVestingEvent?.startDate);
            setNewVestingStartDate(newDateVestingStart);
            const newDateVestingEnd = addDaysToDate(daysAddedToGovernanceEndDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
        if (daysAddedToVestingStartDate && currentFundStatus === nextStatusText) {
            const newDateVestingEnd = addDaysToDate(daysAddedToVestingStartDate, dbVestingEvent?.endDate);
            setNewVestingEndDate(newDateVestingEnd);
        }
    }, [
        daysAddedToCollectingEndDate,
        daysAddedToPublishedStartDate,
        daysAddedToPublishedEndDate,
        daysAddedToOpenStartDate,
        daysAddedToOpenEndDate,
        daysAddedToAssessmentStartDate,
        daysAddedToAssessmentEndDate,
        daysAddedToGovernanceStartDate,
        daysAddedToGovernanceEndDate,
        daysAddedToVestingStartDate
    ])

    const handleSaveStatusAndUpdateEvents = async () => {
        await setSavingNewFunction(true)
        if (specificFundData?.funds[0]?.status !== nextStatusText) {
            await updateFund({
                variables: {
                    fundId: thisFundId,
                    updateData: {
                        status: nextStatusText,
                    }
                }
            })
        }
        //Updating 1
        if (newCollectingEndDate !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbCollectingEvent?.id,
                    newData: {
                        startDate: dbCollectingEvent?.startDate,
                        endDate: newCollectingEndDate || dbCollectingEvent?.endDate,
                    }
                }
            })
        }
        //Updating 2
        if (newPublishedStartDate !== undefined || newPublishedEndDate !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbPublishedEvent?.id,
                    newData: {
                        startDate: newPublishedStartDate || dbPublishedEvent?.startDate,
                        endDate: newPublishedEndDate || dbPublishedEvent?.endDate,
                    }
                }
            })
        }
        //Updating 3
        if (newOpenStartDate !== undefined || newOpenEndDate !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbOpenEvent?.id,
                    newData: {
                        startDate: newOpenStartDate || dbOpenEvent?.startDate,
                        endDate: newOpenEndDate || dbOpenEvent?.endDate,
                    }
                }
            })
        }
        //Updating 4
        if (newAssessmentStartDate !== undefined || newAssessmentEndDate !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbAssessmentEvent?.id,
                    newData: {
                        startDate: newAssessmentStartDate || dbAssessmentEvent?.startDate,
                        endDate: newAssessmentEndDate || dbAssessmentEvent?.endDate,
                    }
                }
            })
        }
        //Updating 5
        if (newGovernanceStartDate !== undefined || newGovernanceEndDate !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbGovernanceEvent?.id,
                    newData: {
                        startDate: newGovernanceStartDate || dbGovernanceEvent?.startDate,
                        endDate: newGovernanceEndDate || dbGovernanceEvent?.endDate,
                    }
                }
            })
        }
        //Updating 6
        if (newVestingStartDate !== undefined || newVestingEndDate !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbVestingEvent?.id,
                    newData: {
                        startDate: newVestingStartDate || dbVestingEvent?.startDate,
                        endDate: newVestingEndDate || dbVestingEvent?.endDate,
                    }
                }
            })
        }
        //Updating 7
        if (newExecutionStartDate !== undefined && dbExecutionEvent !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbExecutionEvent?.id,
                    newData: {
                        startDate: newExecutionStartDate || dbExecutionEvent?.startDate,
                        endDate: newExecutionStartDate || dbExecutionEvent?.startDate,
                    }
                }
            })
        }
        //Creating 7
        if (newExecutionStartDate !== undefined && dbExecutionEvent === undefined) {
            await createEvent({
                variables: {
                    id: uniqueExecutionID,
                    startDate: newExecutionStartDate,
                    endDate: newExecutionStartDate,
                    titleEn: 'Execution',
                    detailEn: 'Execution event for ' + fundName,
                    titleEs: 'Execution',
                    detailEs: 'Evento Execution de ' + fundName,
                    titlePt: 'Execution',
                    detailPt: 'Evento Execution de ' + fundName,
                    fundId: thisFundId,
                }
            })
        }
        //Updating 8
        if (newROIStartDate !== undefined && dbROIEvent !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbROIEvent?.id,
                    newData: {
                        startDate: newROIStartDate || dbROIEvent?.startDate,
                        endDate: newROIStartDate || dbROIEvent?.startDate,
                    }
                }
            })
        }
        //Creating 8
        if (newROIStartDate !== undefined && dbROIEvent === undefined) {
            await createEvent({
                variables: {
                    id: uniqueROIID,
                    startDate: newROIStartDate,
                    endDate: newROIStartDate,
                    titleEn: 'ROI',
                    detailEn: 'ROI event for ' + fundName,
                    titleEs: 'ROI',
                    detailEs: 'Evento ROI de ' + fundName,
                    titlePt: 'ROI',
                    detailPt: 'Evento ROI de ' + fundName,
                    fundId: thisFundId,
                }
            })
        }
        //Updating 9
        if (newClosedStartDate !== undefined && dbCloseEvent !== undefined) {
            await updateEvent({
                variables: {
                    eventId: dbCloseEvent?.id,
                    newData: {
                        startDate: newClosedStartDate || dbCloseEvent?.startDate,
                        endDate: newClosedStartDate || dbCloseEvent?.startDate,
                    }
                }
            })
        }
        //Creating 9
        if (newClosedStartDate !== undefined && dbCloseEvent === undefined) {
            await createEvent({
                variables: {
                    id: uniqueClosedID,
                    startDate: newClosedStartDate,
                    endDate: newClosedStartDate,
                    titleEn: 'Closed',
                    detailEn: 'Closed event for ' + fundName,
                    titleEs: 'Closed',
                    detailEs: 'Evento Closed de ' + fundName,
                    titlePt: 'Closed',
                    detailPt: 'Evento Closed de ' + fundName,
                    fundId: thisFundId,
                }
            })
        }
        await setSavingNewFunction(false)
        await dispatch(showMessage({ message: 'Saved', variant: 'success' }))
        await handleCloseNextStatus()
        await handleCloseConfirmation()
    }

    useEffect(() => {
        if (specificFundData?.funds[0]?.event) {
            const collectingEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Collecting");
            const publishedEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Published");
            const openEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Open");
            const assessmentEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Assessment");
            const governanceEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Governance");
            const vestingEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Vesting");
            const executionEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Execution");
            const roiEvent = specificFundData.funds[0].event.find(event => event.titleEn === "ROI");
            const closeEvent = specificFundData.funds[0].event.find(event => event.titleEn === "Closed");
            if (collectingEvent) {
                setDbCollectingEvent(collectingEvent);
            }
            if (publishedEvent) {
                setDbPublishedEvent(publishedEvent);
            }
            if (openEvent) {
                setDbOpenEvent(openEvent);
            }
            if (assessmentEvent) {
                setDbAssessmentEvent(assessmentEvent);
            }
            if (governanceEvent) {
                setDbGovernanceEvent(governanceEvent);
            }
            if (vestingEvent) {
                setDbVestingEvent(vestingEvent);
            }
            if (executionEvent) {
                setDbExecutionEvent(executionEvent);
            }
            if (roiEvent) {
                setDbROIEvent(roiEvent);
            }
            if (closeEvent) {
                setDbCloseEvent(closeEvent);
            }
        }
    }, [specificFundData]);

    useEffect(() => {
        setCurrentFundStatus(specificFundData?.funds[0]?.status);
    }, [specificFundData, setCurrentFundStatus]);

    const handleChange = (event) => {
        setNextStatusText(event.target.value);
    };

    const [openConfirmation, setOpenConfirmation] = useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 750,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const styleConfirmDatesModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    useEffect(() => {
        setValidatorWalletArray(ValidatorWalletList?.split(','))
        setCoreTeamWalletArray(CoreTeamWalletList?.split(','))
    }, [ValidatorWalletList])

    useEffect(() => {
        if (XChangeAddress !== undefined) {
            setIsCoreTeamWallet(CoreTeamWalletArray.includes(XChangeAddress));
            setIsValidatorWallet(validatorWalletArray.includes(XChangeAddress));
        }
    }, [XChangeAddress, CoreTeamWalletArray, validatorWalletArray]);

    useEffect(() => {
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Closed - Not Fully Funded')) {
            setNewCollectingEndDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Published')) {
            setNewCollectingEndDate(todayDate)
            setNewPublishedStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Open')) {
            setNewPublishedEndDate(todayDate)
            setNewOpenStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Assessment')) {
            setNewOpenEndDate(todayDate)
            setNewAssessmentStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Governance')) {
            setNewAssessmentEndDate(todayDate)
            setNewGovernanceStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Vesting')) {
            setNewGovernanceEndDate(todayDate)
            setNewVestingStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Execution')) {
            setNewVestingEndDate(todayDate)
            setNewExecutionStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'ROI')) {
            setNewROIStartDate(todayDate)
        }
        if ((currentFundStatus !== nextStatusText) && (nextStatusText === 'Closed')) {
            setNewClosedStartDate(todayDate)
        }
    }, [currentFundStatus, nextStatusText])

    const [funds, setFunds] = useState([])
    const [fundsReverse, setFundsReverse] = useState([])

    const sortedStatuses = [
        'Collecting',
        'Published',
        'Open',
        'Assessment',
        'Governance',
        'Vesting',
        'Execution',
        'ROI',
        'Closed',
        'Closed - Not Fully Funded',
    ]

    useEffect(() => {
        if (filteredFunds) {
            const sortedFunds = [...filteredFunds].sort((a, b) => {
                const statusA = sortedStatuses.indexOf(a.status)
                const statusB = sortedStatuses.indexOf(b.status)
                return statusA - statusB
            })
            setFunds(sortedFunds)
            setFundsReverse([...sortedFunds].reverse())
        }
    }, [filteredFunds])

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 4 }}>
            <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        Fund management
                    </Typography>
                </Box>
            </Grid >
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    m: 2,
                                    alignItems: "center", justifyContent: "space-around"
                                }}>
                                <Box
                                    onClick={() => {
                                        dispatch(push('/admin-funds-create'))
                                    }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        p: 0.5,
                                    }}>
                                    <AddCircleIcon
                                        sx={{
                                            width: '50px',
                                            height: '50px',
                                            color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',

                                        }}

                                    />
                                    <Typography sx={{
                                        ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                                    }}>{t('ADMIN.CREATE_FUND')}</Typography>
                                </Box>
                                <TextField
                                    id="filled-search"
                                    label={t('ADMIN.ONLY_SEARCH')}
                                    type="search"
                                    size='small'
                                    variant="outlined"
                                    placeholder={"Fund Name - Offer Purchase username/email"}
                                    onChange={handleTextChange}
                                    sx={{ width: '55%', ml: 4 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Grid >
                    </Box >
                    {loadingFundsData || loadingSpecificFund ?
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: '26vh',
                                mb: '26vh'
                            }}>
                            <CircularProgress />
                        </Box>
                        :
                        <Box sx={{ width: '100%' }}>
                            <TableContainer sx={{ width: '100%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                                <Table stickyHeader={true} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                onClick={() => {
                                                    if (ordering.name != "ASC") {
                                                        setOrdering({ name: "ASC" })
                                                    }
                                                    if (ordering.name != "DESC") {
                                                        setOrdering({ name: "DESC" })
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }}
                                                align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.FUND')}
                                                    {ordering.name !== "ASC" && ordering.name !== "DESC" &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering.name === "DESC" &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering.name === "ASC" &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    if (ordering.status != "ASC") {
                                                        setOrdering({ status: "ASC" })
                                                    }
                                                    if (ordering.status != "DESC") {
                                                        setOrdering({ status: "DESC" })
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }}
                                                align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.CURR_STAGE')}
                                                    {ordering.status !== "ASC" && ordering.status !== "DESC" &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering.status === "DESC" &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering.status === "ASC" &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('ADMIN.STAGE_DURATION')}</TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    if (ordering.amountToReach != "ASC") {
                                                        setOrdering({ amountToReach: "ASC" })
                                                    }
                                                    if (ordering.amountToReach != "DESC") {
                                                        setOrdering({ amountToReach: "DESC" })
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }}
                                                align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.OBJECTIVE_DJED')}
                                                    {ordering.amountToReach !== "ASC" && ordering.amountToReach !== "DESC" &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering.amountToReach === "DESC" &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering.amountToReach === "ASC" &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    if (ordering.successThreshold != "ASC") {
                                                        setOrdering({ successThreshold: "ASC" })
                                                    }
                                                    if (ordering.successThreshold != "DESC") {
                                                        setOrdering({ successThreshold: "DESC" })
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }}
                                                align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.SUCC_THRESHOLD')}
                                                    {ordering.successThreshold !== "ASC" && ordering.successThreshold !== "DESC" &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering.successThreshold === "DESC" &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering.successThreshold === "ASC" &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!ordering?.status && updatedFundsData?.funds?.map((row) => {
                                            return (
                                                <FundAdministrationRow
                                                    sendData={receiveDataFromChild}
                                                    key={row.id}
                                                    data={row}
                                                />
                                            );
                                        })}
                                        {ordering?.status === 'ASC' && funds?.map((row) => {

                                            return (
                                                <FundAdministrationRow
                                                    sendData={receiveDataFromChild}
                                                    key={row.id}
                                                    data={row}
                                                />)
                                        })}
                                        {ordering?.status === 'DESC' && fundsReverse?.map((row) => {
                                            return (<FundAdministrationRow
                                                sendData={receiveDataFromChild}
                                                key={row.id}
                                                data={row}

                                            />)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    }
                </Box>
                {isValidatorWallet === false &&
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px',
                        mt: 1
                    }}>
                        <WarningAmberIcon sx={{ mr: 1, color: 'red' }} />
                        <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                            {t('ADMIN.WARNING_CHOICE_WALLET')}
                        </Typography>
                    </Box>
                }
                {isCoreTeamWallet === false &&
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px',
                        mt: 1
                    }}>
                        <WarningAmberIcon sx={{ mr: 1, color: 'red' }} />
                        <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                            {t('ADMIN.WARNING_CORE_WALLET')}
                        </Typography>
                    </Box>
                }
                {isCorrectNetworkSelected === false &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                        <Typography sx={{ color: '#f7712e', fontSize: '14px', fontWeight: 600 }}>{t('WALLET_VIEW.WRONG_NET')}</Typography>
                    </Box>
                }
            </>
            {
                loadingSpecificFund &&
                <Modal style={{
                    position: 'absolute',
                    margin: '1%',
                    width: '98%',
                    height: '95%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        width: '98%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        borderRadius: '15px',
                        p: 2, m: 2
                    }}>
                        <CircularProgress />
                    </Box>
                </Modal>
            }
            {
                specificFundData === undefined ? null :
                    <Modal
                        open={specificFundData !== undefined && !loadingSpecificFund}
                        onClose={() => setSelectedFundId(null)}
                        style={{
                            position: 'absolute',
                            margin: '1%',
                            width: '98%',
                            height: '98%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{
                            width: '98%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'white',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            borderRadius: '15px',
                            p: 2, m: 2
                        }}>
                            <Grid
                                container
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    height: '25px',
                                    maxHeight: '25px',
                                    borderRadius: '15px'
                                }}
                            >
                                <IconButton onClick={() => {
                                    setSelectedFundId(null)
                                    setTotalSCCreated(0)
                                }}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            {loadingSpecificFund ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 1, mt: 2 }}>
                                    <CircularProgress />
                                </Box> :
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 2 }}>
                                    {/* LEFT COLUMN - START */}
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.NAME')}: </Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{specificFundData?.funds[0]?.name}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>Id: </Typography>
                                            <Typography>{specificFundData?.funds[0]?.id}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1, alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.STATUS')}: </Typography>
                                            <Typography>{currentFundStatus}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 1 }}>
                                                <Button variant='contained' size='small' onClick={handleOpenNextStatus}>
                                                    {t('ADMIN.SET_NEXT_STATUS')}
                                                </Button>
                                                <Modal
                                                    open={openNextStatus}
                                                    onClose={handleOpenConfirmationClosingNextStatus}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'end',
                                                                height: '25px',
                                                                marginTop: '-23px',
                                                            }}
                                                        >
                                                            <IconButton onClick={handleOpenConfirmationClosingNextStatus}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        value={nextStatusText}
                                                                        onChange={handleChange}
                                                                    >
                                                                        {isValidatorWallet === true && specificFundData?.funds[0]?.offerPurchase?.length === 0 && currentFundStatus === 'Collecting' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel value="Draft" control={<Radio />} label="Draft" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel disabled value="Draft" control={<Radio />} label="Draft" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Draft' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -1.8, fontWeight: 'bold' }}>1</Typography>
                                                                                <FormControlLabel value="Collecting" control={<Radio />} label="Collecting" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -1.8, fontWeight: 'bold' }}>1</Typography>
                                                                                <FormControlLabel disabled value="Collecting" control={<Radio />} label="Collecting" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {isValidatorWallet === true && currentFundStatus === 'Collecting' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel value="Closed - Not Fully Funded" control={<Radio />} label="Closed - Not Fully Funded" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel disabled value="Closed - Not Fully Funded" control={<Radio />} label="Closed - Not Fully Funded" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {isValidatorWallet === true && currentFundStatus === 'Collecting' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>2</Typography>
                                                                                <FormControlLabel value="Published" control={<Radio />} label="Published" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>2</Typography>
                                                                                <FormControlLabel disabled value="Published" control={<Radio />} label="Published" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Published' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>3</Typography>
                                                                                <FormControlLabel value="Open" control={<Radio />} label="Open" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>3</Typography>
                                                                                <FormControlLabel disabled value="Open" control={<Radio />} label="Open" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Open' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>4</Typography>
                                                                                <FormControlLabel value="Assessment" control={<Radio />} label="Assessment" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>4</Typography>
                                                                                <FormControlLabel disabled value="Assessment" control={<Radio />} label="Assessment" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Assessment' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>5</Typography>
                                                                                <FormControlLabel value="Governance" control={<Radio />} label="Governance" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>5</Typography>
                                                                                <FormControlLabel disabled value="Governance" control={<Radio />} label="Governance" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Governance' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>6</Typography>
                                                                                <FormControlLabel value="Vesting" control={<Radio />} label="Vesting" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>6</Typography>
                                                                                <FormControlLabel disabled value="Vesting" control={<Radio />} label="Vesting" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Vesting' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>7</Typography>
                                                                                <FormControlLabel value="Execution" control={<Radio />} label="Execution" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>7</Typography>
                                                                                <FormControlLabel disabled value="Execution" control={<Radio />} label="Execution" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'Execution' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel value="ROI" control={<Radio />} label="ROI" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel disabled value="ROI" control={<Radio />} label="ROI" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                        {(isCoreTeamWallet === true || isValidatorWallet === true) && currentFundStatus === 'ROI' ?
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel value="Closed" control={<Radio />} label="Closed" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                            :
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                                <FiberManualRecordIcon />
                                                                                <Typography sx={{ color: 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                                                                <FormControlLabel disabled value="Closed" control={<Radio />} label="Closed" sx={{ ml: 1 }} />
                                                                            </Box>
                                                                        }
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }}>

                                                                {/* STATUS DRAFT DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    <Typography>
                                                                        ------------------------
                                                                    </Typography>
                                                                </Box>
                                                                {/* STATUS DRAFT DATE - END */}
                                                                {/* STATUS COLLECTING DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        fullWidth
                                                                        value={dbCollectingEvent?.startDate?.split('T')[0]}
                                                                        disabled
                                                                    />
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        inputProps={{
                                                                            min: todayDate,
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        value={newCollectingEndDate || dbCollectingEvent?.endDate?.split('T')[0]}
                                                                        onChange={(e) => setNewCollectingEndDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS COLLECTING DATE - END */}
                                                                {/* STATUS CLOSED NOT FULLY FUNDED DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    <Typography>
                                                                        ------------------------
                                                                    </Typography>
                                                                </Box>
                                                                {/* STATUS CLOSED NOT FULLY FUNDED DATE - END */}
                                                                {/* STATUS PUBLISED DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    {
                                                                        ((newCollectingEndDate > (newPublishedStartDate || dbPublishedEvent?.startDate?.split('T')[0])) ||
                                                                            (newPublishedStartDate || dbPublishedEvent?.startDate?.split('T')[0]) > (newPublishedEndDate || dbPublishedEvent?.endDate?.split('T')[0]))
                                                                        &&
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -4 }}>
                                                                            <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        inputProps={{
                                                                            min: newCollectingEndDate || dbCollectingEvent?.endDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={newCollectingEndDate > (newPublishedStartDate || dbPublishedEvent?.startDate?.split('T')[0])}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        value={newPublishedStartDate || dbPublishedEvent?.startDate?.split('T')[0]}
                                                                        onChange={(e) => setNewPublishedStartDate(e.target.value)}
                                                                    />
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        inputProps={{
                                                                            min: newPublishedStartDate || dbPublishedEvent?.startDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newPublishedStartDate || dbPublishedEvent?.startDate?.split('T')[0]) > (newPublishedEndDate || dbPublishedEvent?.endDate?.split('T')[0])}
                                                                        fullWidth
                                                                        value={newPublishedEndDate || dbPublishedEvent?.endDate?.split('T')[0]}
                                                                        onChange={(e) => setNewPublishedEndDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS PUBLISHED DATE - END */}
                                                                {/* STATUS OPEN DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    {
                                                                        ((newPublishedEndDate || dbPublishedEvent?.endDate?.split('T')[0]) > (newOpenStartDate || dbOpenEvent?.startDate?.split('T')[0]) ||
                                                                            (newOpenStartDate || dbOpenEvent?.startDate?.split('T')[0]) > (newOpenEndDate || dbOpenEvent?.endDate?.split('T')[0]))
                                                                        &&
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -4 }}>
                                                                            <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        inputProps={{
                                                                            min: newPublishedEndDate || dbPublishedEvent?.endDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newPublishedEndDate || dbPublishedEvent?.endDate?.split('T')[0]) > (newOpenStartDate || dbOpenEvent?.startDate?.split('T')[0])}
                                                                        value={newOpenStartDate || dbOpenEvent?.startDate?.split('T')[0]}
                                                                        onChange={(e) => setNewOpenStartDate(e.target.value)}
                                                                    />
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        fullWidth
                                                                        inputProps={{
                                                                            min: newOpenStartDate || dbOpenEvent?.startDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newOpenStartDate || dbOpenEvent?.startDate?.split('T')[0]) > (newOpenEndDate || dbOpenEvent?.endDate?.split('T')[0])}
                                                                        value={newOpenEndDate || dbOpenEvent?.endDate?.split('T')[0]}
                                                                        onChange={(e) => setNewOpenEndDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS OPEN DATE - END */}
                                                                {/* STATUS ASSESSMENT DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    {
                                                                        ((newOpenEndDate || dbOpenEvent?.endDate?.split('T')[0]) > (newAssessmentStartDate || dbAssessmentEvent?.startDate?.split('T')[0]) ||
                                                                            (newAssessmentStartDate || dbAssessmentEvent?.startDate?.split('T')[0]) > (newAssessmentEndDate || dbAssessmentEvent?.endDate?.split('T')[0]))
                                                                        &&
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -4 }}>
                                                                            <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        fullWidth
                                                                        inputProps={{
                                                                            min: newOpenEndDate || dbOpenEvent?.endDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        error={(newOpenEndDate || dbOpenEvent?.endDate?.split('T')[0]) > (newAssessmentStartDate || dbAssessmentEvent?.startDate?.split('T')[0])}
                                                                        value={newAssessmentStartDate || dbAssessmentEvent?.startDate?.split('T')[0]}
                                                                        onChange={(e) => setNewAssessmentStartDate(e.target.value)}
                                                                    />
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        inputProps={{
                                                                            min: newAssessmentStartDate || dbAssessmentEvent?.startDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newAssessmentStartDate || dbAssessmentEvent?.startDate?.split('T')[0]) > (newAssessmentEndDate || dbAssessmentEvent?.endDate?.split('T')[0])}
                                                                        value={newAssessmentEndDate || dbAssessmentEvent?.endDate?.split('T')[0]}
                                                                        onChange={(e) => setNewAssessmentEndDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS ASSESSMENT DATE - END */}
                                                                {/* STATUS GOVERNANCE DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    {
                                                                        ((newAssessmentEndDate || dbAssessmentEvent?.endDate?.split('T')[0]) > (newGovernanceStartDate || dbGovernanceEvent?.startDate?.split('T')[0]) ||
                                                                            (newGovernanceStartDate || dbGovernanceEvent?.startDate?.split('T')[0]) > (newGovernanceEndDate || dbGovernanceEvent?.endDate?.split('T')[0]))
                                                                        &&
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -4 }}>
                                                                            <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        inputProps={{
                                                                            min: newAssessmentEndDate || dbAssessmentEvent?.endDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newAssessmentEndDate || dbAssessmentEvent?.endDate?.split('T')[0]) > (newGovernanceStartDate || dbGovernanceEvent?.startDate?.split('T')[0])}
                                                                        value={newGovernanceStartDate || dbGovernanceEvent?.startDate?.split('T')[0]}
                                                                        onChange={(e) => setNewGovernanceStartDate(e.target.value)}
                                                                    />
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        inputProps={{
                                                                            min: newGovernanceStartDate || dbGovernanceEvent?.startDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newGovernanceStartDate || dbGovernanceEvent?.startDate?.split('T')[0]) > (newGovernanceEndDate || dbGovernanceEvent?.endDate?.split('T')[0])}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        value={newGovernanceEndDate || dbGovernanceEvent?.endDate?.split('T')[0]}
                                                                        onChange={(e) => setNewGovernanceEndDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS GOVERNANCE DATE - END */}
                                                                {/* STATUS VESTING DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    {
                                                                        ((newGovernanceEndDate || dbGovernanceEvent?.endDate?.split('T')[0]) > (newVestingStartDate || dbVestingEvent?.startDate?.split('T')[0]) ||
                                                                            (newVestingStartDate || dbVestingEvent?.startDate?.split('T')[0]) > (newVestingEndDate || dbVestingEvent?.endDate?.split('T')[0]))
                                                                        &&
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -4 }}>
                                                                            <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        inputProps={{
                                                                            min: newGovernanceEndDate || dbGovernanceEvent?.endDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newGovernanceEndDate || dbGovernanceEvent?.endDate?.split('T')[0]) > (newVestingStartDate || dbVestingEvent?.startDate?.split('T')[0])}
                                                                        fullWidth
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        value={newVestingStartDate || dbVestingEvent?.startDate?.split('T')[0]}
                                                                        onChange={(e) => setNewVestingStartDate(e.target.value)}
                                                                    />
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        disabled={!(isCoreTeamWallet || isValidatorWallet)}
                                                                        inputProps={{
                                                                            min: newVestingStartDate || dbVestingEvent?.startDate?.split('T')[0],
                                                                            max: newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0]
                                                                        }}
                                                                        error={(newVestingStartDate || dbVestingEvent?.startDate?.split('T')[0]) > (newVestingEndDate || dbVestingEvent?.endDate?.split('T')[0])}
                                                                        fullWidth
                                                                        value={newVestingEndDate || dbVestingEvent?.endDate?.split('T')[0]}
                                                                        onChange={(e) => setNewVestingEndDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS VESTING DATE - END */}
                                                                {/* STATUS EXECUTION DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        sx={{ width: '50%' }}
                                                                        disabled={!isValidatorWallet}
                                                                        value={newExecutionStartDate || dbExecutionEvent?.startDate?.split('T')[0] || newExecutionStartDate}
                                                                        onChange={(e) => setNewExecutionStartDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS EXECUTION DATE - END */}
                                                                {/* STATUS ROI DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        sx={{ width: '50%' }}
                                                                        disabled={!isValidatorWallet}
                                                                        value={newROIStartDate || dbROIEvent?.startDate?.split('T')[0] || newROIStartDate}
                                                                        onChange={(e) => setNewROIStartDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS ROI DATE - END */}
                                                                {/* STATUS CLOSED DATE - START */}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="date"
                                                                        size='small'
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        sx={{ width: '50%' }}
                                                                        disabled={!isValidatorWallet}
                                                                        value={newClosedStartDate || dbCloseEvent?.startDate?.split('T')[0] || newClosedStartDate}
                                                                        onChange={(e) => setNewClosedStartDate(e.target.value)}
                                                                    />
                                                                </Box>
                                                                {/* STATUS CLOSED DATE - END */}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                            {nextStatusText === '' ?
                                                                <Button disabled variant='contained'>{t('CO_ASSESSMENT.VALIDATE')}</Button>
                                                                :
                                                                <Button onClick={handleOpenConfirmation} variant='contained'>{t('CO_ASSESSMENT.VALIDATE')}</Button>
                                                            }
                                                        </Box>
                                                        {isValidatorWallet === false &&
                                                            <Box sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '5px',
                                                                mt: 1
                                                            }}>
                                                                <WarningAmberIcon sx={{ mr: 1, color: 'red' }} />
                                                                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                                                                    {t('ADMIN.WARNING_CHOICE_WALLET')}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        {isCoreTeamWallet === false &&
                                                            <Box sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '5px',
                                                                mt: 1
                                                            }}>
                                                                <WarningAmberIcon sx={{ mr: 1, color: 'red' }} />
                                                                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                                                                    {t('ADMIN.WARNING_CORE_WALLET')}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        <Modal
                                                            open={openConfirmation}
                                                            onClose={handleCloseConfirmation}
                                                            aria-labelledby="modal-modal-title"
                                                            aria-describedby="modal-modal-description"
                                                        >
                                                            <Box sx={styleConfirm}>
                                                                <Grid
                                                                    container
                                                                    xs={12}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        height: '25px',
                                                                        marginTop: '-12px',
                                                                    }}
                                                                >
                                                                    <IconButton onClick={handleCloseConfirmation}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                                                    {t('MY_OFFERINGS.ARE_YOU_SURE')}
                                                                </Typography>
                                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                                    <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.NAME')}: </Typography>
                                                                    <Typography sx={{ fontWeight: 'bold' }}>{specificFundData?.funds[0]?.name}</Typography>
                                                                </Box>
                                                                {currentFundStatus !== nextStatusText &&
                                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                                        <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.STATUS')}: </Typography>
                                                                        <Typography>{currentFundStatus}</Typography>
                                                                        <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                        <Typography sx={{ fontWeight: 'bold', color: 'green' }}>{nextStatusText}</Typography>
                                                                    </Box>
                                                                }
                                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center', mt: 2 }}>
                                                                    {/* COLUMN 1 */}
                                                                    <Box sx={{ width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                                                        {newCollectingEndDate !== undefined && <Typography>Collecting</Typography>}
                                                                        {(newPublishedStartDate !== undefined || newPublishedEndDate !== undefined) && <Typography>Published</Typography>}
                                                                        {(newOpenStartDate !== undefined || newOpenEndDate !== undefined) && <Typography>Open</Typography>}
                                                                        {(newAssessmentStartDate !== undefined || newAssessmentEndDate !== undefined) && <Typography>Assessment</Typography>}
                                                                        {(newGovernanceStartDate !== undefined || newGovernanceEndDate !== undefined) && <Typography>Governance</Typography>}
                                                                        {(newVestingStartDate !== undefined || newVestingEndDate !== undefined) && <Typography>Vesting</Typography>}
                                                                        {(newExecutionStartDate !== undefined || newExecutionStartDate !== undefined) && <Typography>Execution</Typography>}
                                                                        {(newROIStartDate !== undefined || newROIStartDate !== undefined) && <Typography>ROI</Typography>}
                                                                        {(newClosedStartDate !== undefined || newClosedStartDate !== undefined) && <Typography>Closed</Typography>}
                                                                    </Box>
                                                                    {/* COLUMN 2 */}
                                                                    <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                                                        {newCollectingEndDate !== undefined &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbCollectingStartDateMonth}`).substring(0, 3)}. {dbCollectingStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${dbCollectingStartDateMonth}`).substring(0, 3)}. {dbCollectingStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newPublishedStartDate !== undefined || newPublishedEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbPublishedStartDateMonth}`).substring(0, 3)}. {dbPublishedStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newPublishedStartDateMonth || dbPublishedStartDateMonth}`).substring(0, 3)}. {newPublishedStartDateDay || dbPublishedStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newOpenStartDate !== undefined || newOpenEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbOpenStartDateMonth}`).substring(0, 3)}. {dbOpenStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newOpenStartDateMonth || dbOpenStartDateMonth}`).substring(0, 3)}. {newOpenStartDateDay || dbOpenStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newAssessmentStartDate !== undefined || newAssessmentEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbAssessmentStartDateMonth}`).substring(0, 3)}. {dbAssessmentStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newAssessmentStartDateMonth || dbAssessmentStartDateMonth}`).substring(0, 3)}. {newAssessmentStartDateDay || dbAssessmentStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newGovernanceStartDate !== undefined || newGovernanceEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbGovernanceStartDateMonth}`).substring(0, 3)}. {dbGovernanceStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newGovernanceStartDateMonth || dbGovernanceStartDateMonth}`).substring(0, 3)}. {newGovernanceStartDateDay || dbGovernanceStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newVestingStartDate !== undefined || newVestingEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbVestingStartDateMonth}`).substring(0, 3)}. {dbVestingStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newVestingStartDateMonth || dbVestingStartDateMonth}`).substring(0, 3)}. {newVestingStartDateDay || dbVestingStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {newExecutionStartDate !== undefined &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbExecutionStartDateMonth}`).substring(0, 3)}. {dbExecutionStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newExecutionStartDateMonth || dbExecutionStartDateMonth}`).substring(0, 3)}. {newExecutionStartDateDay || dbExecutionStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {newROIStartDate !== undefined &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbROIStartDateMonth}`).substring(0, 3)}. {dbROIStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newROIStartDateMonth || dbROIStartDateMonth}`).substring(0, 3)}. {newROIStartDateDay || dbROIStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {newClosedStartDate !== undefined &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_START_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbClosedStartDateMonth}`).substring(0, 3)}. {dbClosedStartDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newClosedStartDateMonth || dbClosedStartDateMonth}`).substring(0, 3)}. {newClosedStartDateDay || dbClosedStartDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                    </Box>
                                                                    {/* COLUMN 3 */}
                                                                    <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                                                        {newCollectingEndDate !== undefined &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_END_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbCollectingEndDateMonth}`).substring(0, 3)}. {dbCollectingEndDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newCollectingEndDateMonth || dbCollectingEndDateMonth}`).substring(0, 3)}. {newCollectingEndDateDay || dbCollectingEndDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newPublishedStartDate !== undefined || newPublishedEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_END_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbPublishedEndDateMonth}`).substring(0, 3)}. {dbPublishedEndDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newPublishedEndDateMonth || dbPublishedEndDateMonth}`).substring(0, 3)}. {newPublishedEndDateDay || dbPublishedEndDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newOpenStartDate !== undefined || newOpenEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_END_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbOpenEndDateMonth}`).substring(0, 3)}. {dbOpenEndDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newOpenEndDateMonth || dbOpenEndDateMonth}`).substring(0, 3)}. {newOpenEndDateDay || dbOpenEndDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newAssessmentStartDate !== undefined || newAssessmentEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_END_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbAssessmentEndDateMonth}`).substring(0, 3)}. {dbAssessmentEndDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newAssessmentEndDateMonth || dbAssessmentEndDateMonth}`).substring(0, 3)}. {newAssessmentEndDateDay || dbAssessmentEndDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newGovernanceStartDate !== undefined || newGovernanceEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_END_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbGovernanceEndDateMonth}`).substring(0, 3)}. {dbGovernanceEndDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newGovernanceEndDateMonth || dbGovernanceEndDateMonth}`).substring(0, 3)}. {newGovernanceEndDateDay || dbGovernanceEndDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                        {(newVestingStartDate !== undefined || newVestingEndDate !== undefined) &&
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEW_EVENT_END_DATE')}:</Typography>
                                                                                <Typography sx={{ ml: 1 }}>{t(`MONTHS.${dbVestingEndDateMonth}`).substring(0, 3)}. {dbVestingEndDateDay}</Typography>
                                                                                <ArrowRightAltIcon sx={{ ml: 1, mr: 1 }} />
                                                                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{t(`MONTHS.${newVestingEndDateMonth || dbVestingEndDateMonth}`).substring(0, 3)}. {newVestingEndDateDay || dbVestingEndDateDay}</Typography>
                                                                            </Box>
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                        width: '100%',
                                                                        justifyContent: 'space-evenly',
                                                                        mt: 4,
                                                                    }}
                                                                >
                                                                    {savingNewFunction &&
                                                                        <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
                                                                            <LinearProgress />
                                                                        </Box>

                                                                    }
                                                                    <Button onClick={handleSaveStatusAndUpdateEvents} variant='contained'>
                                                                        {t('PERFIL_CONFIG_VIEW.SAVE')} & {t('ADMIN.UPDATE_EVENT')}
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Modal>
                                                        <Modal
                                                            open={openConfirmationClosingNextStatus}
                                                            onClose={handleCloseConfirmationClosingNextStatus}
                                                            aria-labelledby="modal-modal-title"
                                                            aria-describedby="modal-modal-description"
                                                        >
                                                            <Box sx={styleConfirmDatesModal}>
                                                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                                                    {t('MY_OFFERINGS.ARE_YOU_SURE')}
                                                                </Typography>
                                                                <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                                                                    {t('MY_OFFERINGS.CHANGES_LOST')}
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        width: '100%',
                                                                        justifyContent: 'space-evenly',
                                                                        mt: 2,
                                                                    }}
                                                                >
                                                                    <Button variant="outlined" onClick={handleCloseConfirmationClosingNextStatus}>
                                                                        {t('CO_ASSESSMENT.CANCEL')}
                                                                    </Button>
                                                                    <Button variant="contained" onClick={handleCloseNextStatus}>
                                                                        {t('MY_OFFERINGS.CLOSE')}
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Modal>
                                                    </Box>
                                                </Modal>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.COLLECTING_PERIOD')}: </Typography>
                                            <Typography>{t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {startYear} / {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay} - {endYear}</Typography>
                                        </Box>
                                    </Box>
                                    {/* LEFT COLUMN - END */}
                                    {/* RIGHT COLUMN - START */}
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('FUND.TARGET')}: </Typography>
                                            <Typography>$ {specificFundData?.funds[0]?.amountToReach.toLocaleString()}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('FUND_CARD.SUCC_THRESHOLD')}: </Typography>
                                            <Typography>{specificFundData?.funds[0]?.successThreshold} % ( $ {((specificFundData?.funds[0]?.amountToReach * specificFundData?.funds[0]?.successThreshold) / 100).toLocaleString()} )</Typography>
                                            {isThresholdMet && <CheckIcon sx={{ color: 'green', ml: 1 }} />}
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('FUND.MIN/MAX_INVESTMENT')}: </Typography>
                                            <Typography>{specificFundData?.funds[0]?.minInvestment} {specificFundData?.funds[0]?.token[0]?.symbol} / {specificFundData?.funds[0]?.maxInvestment} {specificFundData?.funds[0]?.token[0]?.symbol}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.TOTAL_INTENTIONS')}: </Typography>
                                            <Typography>{totalAmountInvIntentions} {specificFundData?.funds[0]?.token[0]?.symbol}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.UNIQUE_USERS')}: </Typography>
                                            <Typography>{uniqueUserCount}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.CREATED_SC_AMOUNT')}: </Typography>
                                            <Typography>{totalSCCreated}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.ACTIVE_SC_AMOUNT')}: </Typography>
                                            <Typography>{totalSCActive}</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.ADA_IN_SC_AMOUNT')}: </Typography>
                                            <Typography>{totalSCActive * 2} ADA</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.ALLI_IN_SC_AMOUNT')}: </Typography>
                                            <Typography>{totalALLISCActive} ALLI</Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mt: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{t('ADMIN.TOKENS_IN_SC_AMOUNT')}: </Typography>
                                            <Typography>{totalAmountInvestorDeposited} {specificFundData?.funds[0]?.token[0]?.symbol}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                            {specificFundData !== '' &&
                                !loadingSpecificFund &&
                                <SCAdministration
                                    dataOfferPurchases={sortedArray}
                                    loading={loadingSpecificFund}
                                    sendOrderingToParent={receivedOrderingFromChild}
                                    sendTotalCreatedSCToParent={receivedTotalCreatedSCFromChild}
                                    sendTotalActiveSCToParent={receivedTotalActiveSCFromChild}
                                    sendTotalTokensActiveSCToParent={receivedTotalTokensActiveSCFromChild}
                                    sendTotalALLIActiveSCToParent={receivedTotalALLIActiveSCFromChild}
                                    totalALLISCActive={totalALLISCActive}
                                />}
                            {/* RIGHT COLUMN - END */}
                        </Box>
                    </Modal>
            }
        </Box >
    )
}

export default FundManagement