import React from 'react'
import { Box, Button, Grid, InputAdornment, TextField, Typography, Chip, Autocomplete } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { GET_ALL_FUNDS, UPDATE_TOKEN, GET_TOKEN_BY_ID, REMOVE_FUND_TOKEN, ADD_FUND_TOKEN } from '../../../Queries/Admin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { showMessage } from '../../../store/actions/snackBarActions'
import { imgUploadHandler } from '../../../utils/fileUpload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import Preloader from '../../preloader/Preloader';


const TokenEdit = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [updateToken] = useMutation(UPDATE_TOKEN)
    const [getFunds, { data: allFunds }] = useLazyQuery(GET_ALL_FUNDS)
    const [getToken, { data: tokenById, loading: loadingToken }] = useLazyQuery(GET_TOKEN_BY_ID, { variables: { tokenId: id }, pollInterval: 1000, })

    useEffect(() => {
        getFunds()
        getToken()
    }, [getFunds, getToken])

    const [tokenImgURLState, setTokenImgURLState] = useState('')
    const uploadTokenImgHandler = async (file) => {
        const tokenImgURL = await imgUploadHandler(file)
        setTokenImgURLState(tokenImgURL)
    }
    const [symbol, setSymbol] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [policyId, setPolicyId] = useState('')
    const [encodedName, setEncodedName] = useState('')
    const [fingerprint, setFingerprint] = useState('')
    const [maxCap, setMaxCap] = useState('')
    const [inputError, setInputError] = useState(false)

    const handleMaxCapChange = (e) => {
        const inputValue = parseInt(e.target.value, 10);
        if (!isNaN(inputValue) && inputValue <= Number.MAX_SAFE_INTEGER) {
            setMaxCap(inputValue)
            setInputError(false)
        } else {
            setInputError(true)
            dispatch(showMessage({ message: 'MaxCap Error!' }))
        }
    };

    useEffect(() => {
        if (tokenById !== undefined) {
            setSymbol(tokenById?.tokens[0]?.symbol)
            setDescription(tokenById?.tokens[0]?.description)
            setPrice(tokenById?.tokens[0]?.marketprice)
            setPolicyId(tokenById?.tokens[0]?.policyId)
            setEncodedName(tokenById?.tokens[0]?.encodedName)
            setFingerprint(tokenById?.tokens[0]?.fingerprint)
            setTokenImgURLState(tokenById?.tokens[0]?.logo)
            setMaxCap(tokenById?.tokens[0]?.maxCap)
        }
    }, [tokenById])

    const handleSave = async () => {
        await updateToken({
            variables: {
                tokenData: {
                    symbol: symbol,
                    logo: tokenImgURLState,
                    description: description,
                    marketprice: Number(price),
                    policyId: policyId,
                    encodedName: encodedName,
                    fingerprint: fingerprint,
                    maxCap: Number(maxCap)
                },
                tokenId: id
            }
        })
        await dispatch(push('/admin-tokens'))
        await dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
    }

    const [loadingFundTokenRelate, setLoadingFundTokenRelate] = useState(false)
    const [fundToDelete, setFundToDelete] = useState('')
    const [selectedFundOption, setSelectedFundOption] = useState(null)
    const [removeTokenFund] = useMutation(REMOVE_FUND_TOKEN, {
        variables: {
            tokenId: id,
            fundId: fundToDelete,
        },
    })

    const [addTokenFund] = useMutation(ADD_FUND_TOKEN, {
        variables: {
            tokenId: id,
            fundId: selectedFundOption,
        },
    })

    useEffect(() => {
        const addTokenFundAsync = async () => {
            if (selectedFundOption !== null) {
                try {
                    await setLoadingFundTokenRelate(true);
                    await addTokenFund();
                    await dispatch(showMessage({ message: 'Saved!', variant: 'success' }));
                    await setSelectedFundOption(null);
                    setTimeout(async () => {
                        await setLoadingFundTokenRelate(false);
                    }, 1500);
                } catch (error) {
                    // error
                }
            }
        };
        addTokenFundAsync();
    }, [selectedFundOption]);


    const handleDelete = (fund) => async () => {
        try {
            await setLoadingFundTokenRelate(true);
            await setFundToDelete(fund.id);
            await removeTokenFund();
            await dispatch(showMessage({ message: 'Removed!', variant: 'success' }));
            setTimeout(async () => {
                await setLoadingFundTokenRelate(false);
            }, 1500);
        } catch (error) {
            // error
        }
    };


    function extractIdsFromArray(dataArray) {
        return dataArray?.map(item => item?.id);
    }

    const fundIdsArray = extractIdsFromArray(tokenById?.tokens[0]?.fund);

    if (loadingToken) return <Preloader />

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 6
            }}>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "left"
                    }}>
                    <Box
                        onClick={() => {
                            dispatch(push('/admin-tokens'))
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                        <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                    </Box>
                    <Box sx={{ ml: 4 }}>
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}>
                            {t('MY_OFFERINGS.MODIFY')} Token/NFT
                        </Typography>
                    </Box>
                </Box>
            </Grid >
            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "100%"
                    }}>
                    <Grid item xs={12} md={7}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: { xs: '100%', md: '100%' },
                                alignItems: 'center',
                                flexDirection: { xs: 'column', md: 'column' },
                            }}
                        >
                            <img
                                src={tokenImgURLState}
                                style={{
                                    width: '150px',
                                    height: '50px',
                                    objectFit: 'contain',
                                }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '400px', md: '400px' },
                                    height: '50px',
                                    mt: 1
                                }}
                            >
                                <UploadFileIcon sx={{ mr: 2 }} />
                                <input
                                    hidden
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => uploadTokenImgHandler(e.target.files?.[0])}
                                />
                                Logo
                            </Button>

                        </Box>
                    </Grid>
                </Grid>
                {/* SYMBOL */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('CREATE_OFFERING.NAME_TOKEN')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={symbol}
                        onChange={(e) => setSymbol(e.target.value)}
                    />
                </Grid>

                {/* DESCRIPTION */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('CREATE_COMPANY.DESCRIPTION')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>

                {/* PRICE */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('GENERAL.PRICE')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                        }}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </Grid>

                {/* POLICY ID */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        Policy ID
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={policyId}
                        onChange={(e) => setPolicyId(e.target.value)}
                    />
                </Grid>

                {/* ENCODED NAME */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('ADMIN.ENCODED_NAME')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={encodedName}
                        onChange={(e) => setEncodedName(e.target.value)}
                    />
                </Grid>

                {/* FINGERPRINT */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        Fingerprint
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={fingerprint}
                        onChange={(e) => setFingerprint(e.target.value)}
                    />
                </Grid>

                {/* MAXCAP */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        MaxCap
                    </Typography>
                    <TextField
                        sx={{ width: '400px' }}
                        size='small'
                        type='number'
                        error={inputError}
                        onWheel={(e) => e.target.blur()}
                        value={maxCap}
                        onChange={handleMaxCapChange}
                    />
                </Grid>


                {/* FUND */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2,
                    }}>
                    <>
                        <Typography>
                            {t('GENERAL.ADD')} {t('WALLET_VIEW.SYMBOL')}
                        </Typography>
                        {allFunds?.funds?.length < 1 ? <Autocomplete fullWidth disabled /> :
                            <Autocomplete
                                sx={{ mt: 1, width: '400px' }}
                                size="small"
                                limitTags={6}
                                clearOnBlur
                                clearOnEscape
                                value={null}
                                onChange={(event, newValue) => {
                                    setSelectedFundOption(newValue?.id);
                                }}
                                id="tags-outlined"
                                options={allFunds?.funds?.filter(
                                    (option) => option.offerPurchase.length === 0 && !fundIdsArray?.includes(option.id)
                                )}
                                getOptionLabel={(p) => p?.name}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ width: { xs: '100%' } }}
                                        {...params}
                                        name="languages"
                                        placeholder={t('GENERAL.ADD') + ' ' + t('WALLET_VIEW.SYMBOL')}
                                        color="pink"
                                    />
                                )}
                            />
                        }
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                overflow: 'unset',
                                mt: 0.5
                            }}
                        >
                            {tokenById?.tokens[0]?.fund?.map((fund) => (
                                <Chip
                                    variant="outlined"
                                    value={fund?.id}
                                    label={fund?.name}
                                    onDelete={handleDelete(fund)}
                                    color="pink"
                                    key={fund.id}
                                    sx={{ m: '0.5%' }}
                                />
                            ))}
                        </Grid>
                        {loadingFundTokenRelate &&
                            <Box sx={{ width: '50%', mt: 0.5 }}>
                                <LinearProgress />
                            </Box>}
                    </>

                </Grid>
                <>
                    <Button sx={{ mt: 4 }} onClick={handleSave} variant='contained'>
                        {t('CO_ASSESSMENT.SAVE')}
                    </Button>
                </>
            </Grid>
        </Box >
    )
}

export default TokenEdit