import React from 'react'
import { Box } from '@mui/material'
import WelcomeDialog from '../../components/welcomeDialog/WelcomeDialog'
import { useAuth0 } from '@auth0/auth0-react'
import Preloader from '../../components/preloader/Preloader'
import { useEffect } from 'react'
import { useState } from 'react'
import { WELCOME_MODAL_SHOW } from '../../Queries/Users'
import { useLazyQuery } from '@apollo/client'

const LandingPage = () => {
  const { isLoading, isAuthenticated } = useAuth0()
  const { user } = useAuth0()
  const [openDialog, setOpenDialog] = useState(false)

  const [getUserData, { data: userData, loading: userDataLoading }] =
    useLazyQuery(WELCOME_MODAL_SHOW, {
      variables: { userId: user?.email || user?.sub },
      pollInterval: 1000,
    })

  useEffect(() => {
    if (user !== undefined) {
      getUserData()
    }
  }, [getUserData])

  const handleClose = () => {
    setOpenDialog(false)
  }

  const runCodeEverySecond = () => {
    if (
      userData?.users[0]?.welcomeModal === null &&
      userData?.users[0]?.welcomeModal !== undefined &&
      isAuthenticated &&
      !userDataLoading
    ) {
      setOpenDialog(true)
    } else if (
      userData?.users[0]?.welcomeModal !== null &&
      userData?.users[0]?.welcomeModal === undefined &&
      isAuthenticated &&
      !userDataLoading
    ) {
      setOpenDialog(false)
    }
  }

  // Set the initial state of the dialog
  useEffect(() => {
    runCodeEverySecond()
    if (userData?.users[0] === undefined) {
      getUserData()
    }
  }, [isAuthenticated, userData, getUserData])

  useEffect(() => {
    const intervalId = setInterval(runCodeEverySecond, 1000)
    return () => clearInterval(intervalId)
  }, [isAuthenticated, userData])

  const [iframeSrc, setIframeSrc] = useState('https://tokenallies.com/')

  const forceReloadIframe = () => {
    setIframeSrc(
      (prevSrc) =>
        prevSrc +
        (prevSrc.includes('?') ? '&' : '?') +
        'random=' +
        Math.random()
    )
  }

  useEffect(() => {
    forceReloadIframe()
  }, [])

  if (isLoading) return <Preloader />

  if (!isLoading)
    return (
      <>
        <Box
          sx={{
            width: '100%',
            height: { md: '87vh', xs: '82vh' },
            zIndex: 2,
            overflow: 'hidden',
          }}
        >
          <iframe
            id="website"
            title="Token Allies"
            src={iframeSrc}
            width="101%"
            height="112%"
            frameBorder="0"
            sandbox="allow-scripts allow-same-origin"
            style={{
              position: 'relative',
              top: '-65px',
              transform: 'translateY(-65px - 500px)',
              zIndex: '1',
            }}
          />
        </Box>
        <WelcomeDialog
          sx={{ zIndex: 5 }}
          open={openDialog}
          handleClose={handleClose}
        />
      </>
    )
}

export default LandingPage
