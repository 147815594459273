import { push } from 'connected-react-router'
import { asyncActionCreator } from '../../utils/loadingUtils'
import loginWithCredentials from '../services/authService'
import { getPerfilInfo } from './perfilActions'
import { showMessage } from './snackBarActions'

export const AUTH_LOGIN = asyncActionCreator('AUTH_LOGIN')
export const LOGOUT = 'LOGOUT'
export const login = (tokenSession, user) => async (dispatch) => {
  try {
    await dispatch({ type: AUTH_LOGIN.start })
    await localStorage.setItem('sessionToken', tokenSession)
    const data = await loginWithCredentials(user)
    await dispatch(getPerfilInfo(data))
    await dispatch({
      type: AUTH_LOGIN.success,
      payload: tokenSession,
    })
  } catch {
    //ERR
  }
}

export function loginWithToken(token) {
  return async (dispatch) => {
    try {
      localStorage.setItem('sessionToken', token)
      dispatch({
        type: AUTH_LOGIN.success,
        payload: token,
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export function logoutAction() {
  return (dispatch) => {
    localStorage.removeItem('sessionToken')
    dispatch({ type: LOGOUT })
    dispatch(push('/'))
  }
}

export function messageSent() {
  return async (dispatch) => {
    try {
      await dispatch(
        showMessage({
          message: 'Message Sent Successfully',
          variant: 'success',
        })
      )
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: 'error' }))
    }
  }
}
