import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Avatar,
  LinearProgress
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import TransactionsRenderer from './TransactionsRenderer'
import CardanoWallet from './../../components/TestWallet/CardanoWallet';
import { GET_ALL_USER_TRANSACTIONS, GET_PURE_USER_TRANSACTIONS } from '../../Queries/Transactions'
import InvestmentTable from './InvestmentTable'

function Wallet() {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const [screenSize, setScreenSize] = useState('');
  const actualThemeLight = theme.palette.mode === 'light'

  useEffect(() => {
    if (isMd) {
      setScreenSize('md');
    } else {
      setScreenSize('xs');
    }
  }, [isMd]);

  const [fetchTransactions, { data: transactionData, loading }] = useLazyQuery(GET_ALL_USER_TRANSACTIONS,
    {
      variables:
        { userId: user?.email || user?.sub },
      pollInterval: 1000
    },)

  const [fetchPureTransactions, { data: pureTransactionData }] = useLazyQuery(GET_PURE_USER_TRANSACTIONS,
    {
      variables:
        { userId: user?.email || user?.sub },
      pollInterval: 2000
    },)

  useEffect(() => {
    fetchTransactions()
    fetchPureTransactions()
  }, [fetchTransactions, fetchPureTransactions])

  const [sort, setSort] = useState('lastUpdate_desc')

  const handleChangeSort = (event) => {
    setSort(event.target.value)
  }

  const sortArray = (array, sortOption) => {
    try {
      const sortedArray = [...array];

      switch (sortOption) {
        case 'fundName_asc':
          sortedArray.sort((a, b) => a.fund.name.localeCompare(b.fund.name));
          break;
        case 'fundName_desc':
          sortedArray.sort((a, b) => b.fund.name.localeCompare(a.fund.name));
          break;
        case 'investment_asc':
          sortedArray.sort((a, b) => a.amount - b.amount);
          break;
        case 'investment_desc':
          sortedArray.sort((a, b) => b.amount - a.amount);
          break;
        case 'lastUpdate_asc':
          sortedArray.sort(
            (a, b) => new Date(a.lastUpdate) - new Date(b.lastUpdate)
          );
          break;
        case 'lastUpdate_desc':
          sortedArray.sort(
            (a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate)
          );
          break;
        case 'status_asc':
          sortedArray.sort((a, b) => a.status.localeCompare(b.status));
          break;
        case 'status_desc':
          sortedArray.sort((a, b) => b.status.localeCompare(a.status));
          break;
        default:
          break;
      }
      return sortedArray;
    } catch (error) {
      return array;
    }
  };

  const sortedArray = sortArray(transactionData?.offerPurchases, sort);
  const [connectedWallet, setConnectedWallet] = useState(null)

  const handleAddressChange = (newAddress) => {
    setConnectedWallet(newAddress)
  };

  if (loading) return (
    <>
      <Grid item xs={12} md={12} className="rightSide">
        <Box sx={{
          width: '100%', display: "flex", alignItems: "center",
          justifyContent: "center", pt: 5
        }}>
          < CircularProgress color="secondary" disableShrink />
        </Box>
      </Grid>
    </>
  )

  return (
    <>
      <Grid
        container
        gap={'15px'}
        sx={{
          width: '95%',
          margin: '0 auto',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
      >
        <Grid item xs={12} md={12} sx={{ marginTop: '20px' }} className="leftSide">
          <Box>
            <CardanoWallet onChangeAddress={handleAddressChange} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ marginBottom: '20px' }}
          className="rightSide"
        >
          <Box sx={{
            display: 'flex', flexDirection: { md: 'row', xs: 'column' },
            alignItems: 'center', justifyContent: 'space-between'
          }}>
            <Box>
              <Typography sx={{ color: actualThemeLight ? 'black' : 'white' }} variant="h6" fontWeight={'bold'}>
                {t('WALLET_VIEW.INVESTMENTS')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  mr: 1,
                  ml: { md: 10, xs: 0 },
                  color: actualThemeLight ? 'black' : 'white'
                }}
              >
                {t('ADMIN.SORT_BY')}
              </Typography>
              <FormControl sx={{ minWidth: '250px', maxWidth: '250px' }}>
                <Select
                  sx={{ maxHeight: '20px', fontSize: '14px' }}
                  size='small'
                  value={sort}
                  onChange={handleChangeSort}
                >
                  <MenuItem fontSize={'10px'} value={'fundName_asc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('ADMIN.FUND_NAME')} - A-Z
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'fundName_desc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('ADMIN.FUND_NAME')} - Z-A
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'investment_asc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('FUND_SEARCH_VIEW.INVESTMENT_LOW')}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'investment_desc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('FUND_SEARCH_VIEW.INVESTMENT_HIGH')}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'lastUpdate_desc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('ADMIN.LAST_UPDATE_NEW')}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'lastUpdate_asc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('ADMIN.LAST_UPDATE_OLD')}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'status_desc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('ADMIN.STATUS')} - A-Z
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'status_asc'}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {t('ADMIN.STATUS')} - Z-A
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{
            height: '48vh',
            maxHeight: '48vh',
            borderRadius: '20px'
          }}>
            {
              screenSize !== 'xs' &&
              <Paper
                sx={{
                  borderRadius: '10px', border: '2px solid var(--borderGray)',
                  maxHeight: '50vh', display: 'flex', justifyContent: 'center ',
                  flexDirection: 'column', alignItems: 'center', p: 0.2, mt: 1
                }}
              >
                <InvestmentTable data={sortedArray} connectedWallet={connectedWallet} />
              </Paper>
            }
            {
              screenSize === 'xs' &&
              <>
                {sortedArray.map((investment, index) => (
                  <>
                    <Paper
                      key={index}
                      sx={{
                        borderRadius: '10px',
                        border: '2px solid var(--borderGray)',
                        maxHeight: '50vh',
                        display: 'flex',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 0.5,
                        mt: 1,
                      }}
                    >
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: '100%'
                      }}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '40%'
                        }}>
                          {investment?.fund?.image !== '' ?
                            <Avatar
                              src={investment?.fund?.image}
                              alt={'LOGO'}
                              sx={{ mr: 1 }}
                            />
                            : <Box sx={{ ml: 6 }}> </Box>
                          }
                          <Typography sx={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>
                            {investment?.fund?.name}
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '30%'
                        }}>
                          <Typography sx={{ fontSize: '10px' }}>
                            {t(`MONTHS.${investment?.on?.split('-')[1]}`).substring(0, 3)}
                            . {investment?.on?.split('-')[2].split('T')[0]}
                            , {investment?.on?.split('-')[0]}
                          </Typography>
                          <Typography sx={{ fontSize: '10px' }}>
                            {investment?.on?.split('T')[1]?.split('.')[0]} UTC
                          </Typography>
                        </Box>

                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '30%'
                        }}>
                          <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {investment?.amount} {investment?.fund?.token[0]?.symbol}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        {investment?.status === "SC Created - ALLI Deposited" ? (
                          <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#EEF18B",
                            pl: 0.5, pr: 0.5,
                            width: '80%'
                          }}>
                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                              {t('INVESTMENT_STATUS.APPROVED')}
                            </Typography>
                          </Box>)
                          : investment?.status === "Investor Deposited" ? (
                            <Box sx={{
                              border: '1px solid gray',
                              borderRadius: '10px',
                              backgroundColor: "#6BF27D",
                              pl: 0.5, pr: 0.5,
                              width: '80%'
                            }}>
                              <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                {t('TX_TICKER.TX_CONFIRMED')}
                              </Typography>
                            </Box>)
                            : (investment?.status === "Close Executed" || investment?.status === "Close Executed - Pending") ? (
                              <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#EEF18B",
                                pl: 0.5, pr: 0.5,
                                width: '80%'
                              }}>
                                <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                  {t('WALLET_VIEW.INVESTMENT_CARD.FUND_CLOSED_WAITING')}
                                </Typography>
                              </Box>)
                              : (investment?.status === "Core Withdrawn" || investment?.status === "Core Withdrawn - Pending") ? (
                                <>
                                  <Box sx={{
                                    border: '1px solid gray',
                                    borderRadius: '10px',
                                    backgroundColor: "#EEF18B",
                                    pl: 0.5, pr: 0.5,
                                    width: '80%'
                                  }}>
                                    <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                      {t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW_READY')}
                                    </Typography>
                                  </Box>
                                </>)
                                : (investment?.status === "Payout Executed" || investment?.status === "Payout Executed - Pending") ? (
                                  <Box sx={{
                                    border: '1px solid gray',
                                    borderRadius: '10px',
                                    backgroundColor: "#EEF18B",
                                    pl: 0.5, pr: 0.5,
                                    width: '80%'
                                  }}>
                                    <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                      {t('WALLET_VIEW.INVESTMENT_CARD.PAYOUT_WAITING')}
                                    </Typography>
                                  </Box>)
                                  : investment?.status === "SC Timed Out - Core Withdrawn" ? (
                                    <Box sx={{
                                      border: '1px solid gray',
                                      borderRadius: '10px',
                                      backgroundColor: "gray",
                                      pl: 0.5, pr: 0.5,
                                      width: '80%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                      <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                        {t('WALLET_VIEW.INVESTMENT_CARD.CONTRACT_CLOSED')}
                                      </Typography>
                                      <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                        {t('INVERSOR_VIEW.OFFERING_CARD.TIMEOUT')}
                                      </Typography>
                                    </Box>)
                                    : (investment?.status === "Investor Withdrawn" || investment?.status === "Both Withdrawn") ? (
                                      <Box sx={{
                                        border: '1px solid gray',
                                        borderRadius: '10px',
                                        backgroundColor: "gray",
                                        pl: 0.5, pr: 0.5,
                                        width: '80%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                          {t('WALLET_VIEW.INVESTMENT_CARD.CONTRACT_CLOSED')}
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                          {t('WALLET_VIEW.INVESTMENT_CARD.NFA')}
                                        </Typography>
                                      </Box>)
                                      : investment?.status === "Investor Deposited - Pending" ? (
                                        <Box sx={{
                                          border: '1px solid gray',
                                          borderRadius: '10px',
                                          backgroundColor: "#F4BC44",
                                          pl: 0.5, pr: 0.5,
                                          width: '80%'
                                        }}>
                                          <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                            {t('TX_TICKER.TX_CONFIRMED').split(' ')[0] + ' ' + t('TX_TICKER.TX_PENDING')}
                                          </Typography>
                                        </Box>)
                                        : investment?.status === "Both Withdrawn - Pending" ? (
                                          <Box sx={{
                                            border: '1px solid gray',
                                            borderRadius: '10px',
                                            backgroundColor: "#F4BC44",
                                            pl: 0.5, pr: 0.5,
                                            width: '80%'
                                          }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                              {t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAWN') + ' ' + t('TX_TICKER.TX_PENDING')}
                                            </Typography>
                                          </Box>)
                                          : investment?.status === "Investment Cancelled" ? (
                                            <Box sx={{
                                              border: '1px solid gray',
                                              borderRadius: '10px',
                                              backgroundColor: "gray",
                                              pl: 0.5, pr: 0.5,
                                              width: '80%'
                                            }}>
                                              <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                                {t('TX_TICKER.INV_CANCELLED')}
                                              </Typography>
                                            </Box>)
                                            : investment?.status === "Investment Cancelled - Target Met" ? (
                                              <Box sx={{
                                                border: '1px solid gray',
                                                borderRadius: '10px',
                                                backgroundColor: "gray",
                                                pl: 0.5, pr: 0.5,
                                                width: '80%'
                                              }}>
                                                <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                                  {t('TX_TICKER.INV_CANCELLED')}
                                                </Typography>
                                                <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                                  {t('NOTIFICATIONS.TARGETMET')?.split(',')[0]}
                                                </Typography>
                                              </Box>)
                                              : investment?.status === "Investment Cancelled - Collecting Ended" ? (
                                                <Box sx={{
                                                  border: '1px solid gray',
                                                  borderRadius: '10px',
                                                  backgroundColor: "gray",
                                                  pl: 0.5, pr: 0.5,
                                                  width: '80%'
                                                }}>
                                                  <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                                    {t('TX_TICKER.INV_CANCELLED')}
                                                  </Typography>
                                                  <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>
                                                    {t('NOTIFICATIONS.COLLOVER')?.split(',')[0]}
                                                  </Typography>
                                                </Box>)
                                                : investment?.status === "SC Created - ALLI Deposited - Pending" ? (
                                                  <Box sx={{
                                                    border: '1px solid gray',
                                                    borderRadius: '10px',
                                                    backgroundColor: "#EEF18B",
                                                    pl: 0.5, pr: 0.5,
                                                    width: '80%'
                                                  }}>
                                                    <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                                      {t('INVESTMENT_STATUS.APPROVED')}
                                                    </Typography>
                                                    <LinearProgress />
                                                  </Box>)
                                                  : investment?.status === "Investment Intention - Waiting List" ? (
                                                    <Box sx={{
                                                      border: '1px solid gray',
                                                      borderRadius: '10px',
                                                      backgroundColor: "#EEF18B",
                                                      pl: 0.5, pr: 0.5,
                                                      width: '80%'
                                                    }}>
                                                      <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                                        {t('INVESTMENT_STATUS.WAITING_APPROVAL')}
                                                      </Typography>
                                                      <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                                        {t('DASHBOARD.WAITING_LIST')}
                                                      </Typography>
                                                    </Box>)
                                                    : <Box sx={{
                                                      border: '1px solid gray',
                                                      borderRadius: '10px',
                                                      backgroundColor: "#EEF18B",
                                                      pl: 0.5, pr: 0.5,
                                                      width: '80%'
                                                    }}>
                                                      <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                                                        {t('INVESTMENT_STATUS.WAITING_APPROVAL')}
                                                      </Typography>
                                                    </Box>}
                      </Box>
                    </Paper>
                  </>
                ))}
              </>
            }
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3.6}
          sx={{ marginBottom: '20px' }}
          className="rightSide"
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ color: actualThemeLight ? 'black' : 'white' }} variant="h6" fontWeight={'bold'}>
              {t('WALLET_VIEW.HISTORY_TITLE')}
            </Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }}>
            <Paper
              sx={{
                borderRadius: '10px', border: '2px solid var(--borderGray)', maxHeight: '50vh',
                paddingBottom: '10px', paddingTop: '10px', display: 'flex', justifyContent: 'center ',
                backgroundColor: actualThemeLight ? 'white' : '#1d1d1d',
                flexDirection: 'column', alignItems: transactionData?.transactions?.length == 0 ? 'center' : 'normal'
              }}
            >
              <Box sx={{ color: 'black', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={{ fontWeight: 'bold', width: '25%', display: 'flex', justifyContent: 'center', color: actualThemeLight ? 'black' : 'white' }}>
                  {t('WALLET_VIEW.DATE')}
                </Typography>
                <Typography sx={{ fontWeight: 'bold', width: '25%', display: 'flex', justifyContent: 'center', color: actualThemeLight ? 'black' : 'white' }}>
                  {t('WALLET_VIEW.TYPE')}
                </Typography>
                <Typography sx={{ fontWeight: 'bold', width: '25%', display: 'flex', justifyContent: 'center', color: actualThemeLight ? 'black' : 'white' }}>
                  {t('WALLET_VIEW.SYMBOL')}
                </Typography>
                <Typography sx={{ fontWeight: 'bold', width: '25%', display: 'flex', justifyContent: 'center', mr: 1, color: actualThemeLight ? 'black' : 'white' }}>
                  {t('WALLET_VIEW.AMOUNT')}
                </Typography>
              </Box>
              <TransactionsRenderer transactions={pureTransactionData?.transactions} />
              <Link to="/wallet/history">
                <Button variant="text" color="primary" sx={{
                  marginTop: transactionData?.offerPurchases?.length == 0 ? '8px' : '5px', color: actualThemeLight ? null : '#e6a3bd',
                  textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                }}>
                  {t('OFFERINGS_TABLE.ACT_SEE_MORE')}
                </Button>
              </Link>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Wallet
