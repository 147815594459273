import decode from 'jwt-decode'
import moment from 'moment'
import { loginWithToken, logoutAction } from '../actions/authActions'

import { selectAuthToken, selectIsLoggedIn } from '../selectors/authSelector'

let preventRun = false

export const rehydrateToken = (store) => (next) => (action) => {
  const state = store.getState()
  const token = selectAuthToken(state) || localStorage.getItem('sessionToken')
  const isLoggedIn = selectIsLoggedIn(state)

  if (token && !preventRun) {
    preventRun = true
    const payload = decode(token)
    const expiration = moment.unix(payload.exp)

    if (moment().isAfter(expiration)) {
      next(logoutAction())
    } else if (!isLoggedIn) {
      next(loginWithToken(token))
    }
    preventRun = false
  }

  next(action)
}

export default rehydrateToken
