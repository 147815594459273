import React from 'react'
import { TableCell, TableRow } from '@mui/material'

const IndividualCountry = ({ data }) => {

    return (
        <TableRow key={data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" component="th" scope="row">
                {data?.id}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.name}
            </TableCell>
        </TableRow>
    )
}

export default IndividualCountry