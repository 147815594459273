import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import EnhancedTable from '../../components/WalletMovements/EnhancedTable'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '@mui/icons-material/Download'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import jsPDF from 'jspdf'
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'
import { useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { GET_PURE_USER_TRANSACTIONS } from '../../Queries/Transactions'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useEffect } from 'react'
import TokenAllies from '../../../src/assets/icons/TokenAlliesIcon.png'

function createData(date, transactionType, orderNumber, symbol, amount, fund) {
  return {
    date, transactionType, orderNumber, symbol, amount, fund
  }
}

function Wallet() {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const dispatch = useDispatch()

  const columns = [
    { title: t('WALLET_VIEW.DATE'), field: 'date' },
    { title: t('WALLET_VIEW.TRANSACTION'), field: 'transactionType' },
    { title: t('WALLET_VIEW.ORDER_NUMBER'), field: 'orderNumber' },
    { title: t('WALLET_VIEW.SYMBOL'), field: 'symbol' },
    { title: t('WALLET_VIEW.AMOUNT'), field: 'amount', type: 'numeric' },
  ]

  const [fetchTransactions, { data: transactionData, loading }] = useLazyQuery(GET_PURE_USER_TRANSACTIONS,
    {
      variables: { userId: user?.email || user?.sub },
      pollInterval: 2000
    },)

  useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions])

  const dataForFiles = transactionData?.transactions?.map((t) => {
    const fDate = t?.date?.split('T')[0];
    return createData(fDate, t?.type, t?.hash, t?.offerPurchase?.fund?.token[0]?.symbol, t?.amount, t?.offerPurchase?.fund?.name);
  });

  const downloadPdf = () => {
    const doc = new jsPDF();
    const logoImage = new Image();
    logoImage.src = TokenAllies;
    logoImage.onload = function () {
      const logoHeight = 20;
      const logoYPosition = 10;
      doc.addImage(logoImage, 'PNG', 10, logoYPosition, 20, logoHeight);
      const titleYPosition = logoYPosition + logoHeight + 10;
      doc.text(t('WALLET_VIEW.HISTORY_TITLE'), 15, titleYPosition);
      const tableYPosition = titleYPosition + 10;
      doc.autoTable({
        theme: 'grid',
        columns: columns.map((col) => ({ ...col, dataKey: col.field })),
        body: dataForFiles,
        headStyles: {
          fillColor: [219, 65, 123],
          color: 'white',
        },
        startY: tableYPosition,
      });
      doc.save('Transactions History.pdf');
    };
  };


  const downloadExcel = () => {
    const newData = dataForFiles.map((row) => {
      delete row.tableData;
      return row;
    });
    let excelContent = `
    <html xmlns:o="urn:schemas-microsoft-com:office:office"
          xmlns:x="urn:schemas-microsoft-com:office:excel"
          xmlns="http://www.w3.org/TR/REC-html40">
    <head>
    <style>
      <!--
      table {
        mso-displayed-decimal-separator: ".";
        mso-displayed-thousand-separator: ",";
      }
      -->
    </style>
    <!--[if gte mso 9]><xml>
    <x:ExcelWorkbook>
      <x:ExcelWorksheets>
        <x:ExcelWorksheet>
          <x:Name>Transactions</x:Name>
          <x:WorksheetOptions>
            <x:DisplayGridlines/>
          </x:WorksheetOptions>
        </x:ExcelWorksheet>
      </x:ExcelWorksheets>
    </x:ExcelWorkbook>
    </xml><![endif]-->
    </head>
    <body>
      <table>
        <tr><td colspan="10"><img src=${TokenAllies} alt="Logo" width="50" height="50"></td></tr>
        <tr><td colspan="10">${t('WALLET_VIEW.HISTORY_TITLE')}</td></tr>
        <tr>${columns.map((col) => `<th>${col.title}</th>`).join('')}</tr>
        ${newData.map((row) => `<tr>${columns.map((col) => `<td>${row[col.field]}</td>`).join('')}</tr>`).join('')}
      </table>
    </body>
    </html>
  `;
    const blob = new Blob([excelContent], { type: 'application/vnd.ms-excel' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Transactions History.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        width: '80%',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'left',
          mt: 2
        }}
      >
        <ArrowBackIcon sx={{
          fontSize: '16px',
          cursor: 'pointer'
        }} />
        <Typography
          onClick={() => dispatch(goBack())}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            fontSize: '14px',
          }}
        >
          {t('INVERSOR_VIEW.USER_CARD.RETURN')}
        </Typography>
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <Box
          className={'titleContainer'}
          sx={{
            display:
              loading == false && transactionData?.transactions?.length > 0
                ? 'flex'
                : 'none',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Typography variant="h6" fontWeight={'bold'}>
            {t('WALLET_VIEW.HISTORY_TITLE')}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              endIcon={<DownloadIcon />}
              onClick={() => downloadExcel()}
              sx={{
                width: { xs: '100%', md: 'auto' }
              }}
            >
              {t('WALLET_VIEW.HISTORY_DOWNLOAD_EXCEL')}
            </Button>
            <Button
              variant="outlined"
              endIcon={<PictureAsPdfIcon />}
              onClick={() => downloadPdf()}
              sx={{
                marginLeft: { xs: '0px', md: '10px' },
                marginTop: { xs: '10px', md: '0px' },
                width: { xs: '100%', md: 'auto' }
              }}
            >
              {t('WALLET_VIEW.HISTORY_DOWNLOAD')}
            </Button>
          </Box>
        </Box>
        <EnhancedTable
          transactions={transactionData?.transactions}
          isLoading={loading}
        />
      </Box>
    </Box>
  )
}

export default Wallet