import React from "react"
import { Fragment } from "react"
import { Box, useTheme } from '@mui/material'

const Card = ({ children, color, opacity, border, height, margin, mt, mb, display, flexDirection, justifyContent, alignItems, minHeight }) => {
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const backgroundColor = actualThemeLight ? color : '#1d1d1d'
    const cardOpacity = opacity
    const borderStyle = border
    const cardHeight = height
    const cardMargin = margin
    const cardMarginTop = mt
    const cardMarginBottom = mb
    const cardDisplay = display
    const cardFD = flexDirection
    const cardJC = justifyContent
    const cardAI = alignItems
    const cardMinHeight = minHeight

    return <Fragment>
        <Box sx={{ backgroundColor: actualThemeLight ? backgroundColor : '#1d1d1d', borderRadius: '15px', p: 1, width: '100%', opacity: cardOpacity, border: borderStyle, height: cardHeight, m: cardMargin, mt: cardMarginTop, mb: cardMarginBottom, display: cardDisplay, alignItems: cardAI, justifyContent: cardJC, flexDirection: cardFD, minHeight: cardMinHeight }}>
            {children}
        </Box>
    </Fragment >
}

export default Card