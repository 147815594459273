import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GET_USER_INFO } from '../../../Queries/Users'
import { Box, Button, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { imgUploadHandler } from '../../../utils/fileUpload'
import { GET_NEWS_BY_ID, UPDATE_NEWS_POST } from '../../../Queries/Admin';
import { showMessage } from '../../../store/actions/snackBarActions'
import { useParams } from "react-router"
import Preloader from '../../preloader/Preloader';


const DaoNewsEdit = () => {
    const { id } = useParams()
    const { user } = useAuth0()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [titleEn, setTitleEn] = React.useState('')
    const [contentEn, setContentEn] = React.useState('')
    const [titleEs, setTitleEs] = React.useState('')
    const [contentEs, setContentEs] = React.useState('')
    const [titlePt, setTitlePt] = React.useState('')
    const [contentPt, setContentPt] = React.useState('')
    const [externalURL, setExternalURL] = React.useState('')
    const [newsImgURLStateEn, setNewsImgURLStateEn] = React.useState(undefined)
    const [newsImgFileEn] = React.useState('')
    const uploadNewsImgHandlerEn = async (file) => {
        const newsImgURLEn = await imgUploadHandler(file)
        setNewsImgURLStateEn(newsImgURLEn)
    }



    const [updateNewsPost] = useMutation(UPDATE_NEWS_POST)

    const [fetchNews, { data: newsById, loading }] = useLazyQuery(
        GET_NEWS_BY_ID,
        {
            variables: { newsId: id },
            pollInterval: 1500
        }
    )

    useEffect(() => {
        fetchNews()
    }, [fetchNews])

    useEffect(() => {
        if (newsById !== undefined) {
            setTitleEn(newsById?.news[0]?.titleEn)
            setContentEn(newsById?.news[0]?.contentEn)
            setTitleEs(newsById?.news[0]?.titleEs)
            setContentEs(newsById?.news[0]?.contentEs)
            setTitlePt(newsById?.news[0]?.titlePt)
            setContentPt(newsById?.news[0]?.contentPt)
            setExternalURL(newsById?.news[0]?.url?.link)
            setNewsImgURLStateEn(newsById?.news[0]?.image)
        }
    }, [newsById])


    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    useEffect(() => {
        getUserData()
    }, [getUserData]);

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })


    const handleSaveNews = async () => {
        await updateNewsPost({
            variables: {
                newsId: newsById?.news[0]?.id,
                newsData: {
                    titleEn: titleEn,
                    contentEn: contentEn,
                    titleEs: titleEs,
                    contentEs: contentEs,
                    titlePt: titlePt,
                    contentPt: contentPt,
                    image: newsImgURLStateEn
                },
                urlId: newsById?.news[0]?.url?.id,
                urlLink: externalURL,
            }
        })

        await dispatch(push('/admin-dao-news'))
        await dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
    }

    const [radioSelected, setRadioSelected] = useState('en')
    const handleRadioChange = (event) => {
        setRadioSelected(event.target.value);
    };

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    if (loading) return <Preloader />

    if (isCoreTeam) {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        mb: 6
                    }}>
                    <Grid
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column'
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                ml: 4,
                                mb: 2,
                                alignItems: "center",
                                justifyContent: "left"
                            }}>
                            <Box
                                onClick={() => {
                                    dispatch(push('/admin-dao-news'))
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    p: 0.5,
                                }}>
                                <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                                <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                            </Box>
                            <Box sx={{ ml: 4 }}>
                                <Typography
                                    fontSize={20}
                                    fontWeight={'bold'}>
                                    {t('ADMIN.EDIT_PUBLICATION')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid >
                    <Box width={'45%'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <>
                            <img
                                src={newsImgURLStateEn}
                                style={{
                                    width: '250px',
                                    height: '50px',
                                    objectFit: 'scale-down',
                                    marginBottom: '10px'
                                }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '100%', md: '100%' },
                                    height: '70%',
                                }}
                            >
                                <input
                                    hidden
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => uploadNewsImgHandlerEn(e.target.files?.[0])}
                                />
                                {newsImgFileEn
                                    ? 'IMAGE UPLOADED'
                                    : t('ADMIN.NEW_PUBLICATION_IMG_MSG')}
                            </Button>
                        </>
                    </Box>
                    <RadioGroup sx={{ mt: 4, mb: 2 }} onChange={handleRadioChange} defaultValue={radioSelected} row >
                        <FormControlLabel value="en" control={<Radio />} label="English" />
                        <FormControlLabel value="es" control={<Radio />} label="Español" />
                        <FormControlLabel value="pt" control={<Radio />} label="Português" />
                    </RadioGroup>

                    {radioSelected === 'en' ?
                        <>
                            {/* ENGLISH NEWS */}
                            <Grid
                                sx={{
                                    display: 'flex',
                                    width: '95%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around'
                                }}>
                                <Box sx={{
                                    display: 'flex',
                                    width: '95%',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}>
                                    <Typography fontSize={14}>{t('ADMIN.NEW_PUBLICATION_TITLE')} - English</Typography>
                                    <TextField
                                        value={titleEn}
                                        onChange={(e) => setTitleEn(e.target.value)}
                                        sx={{ width: '100%' }}
                                        variant="outlined"
                                    />
                                </Box>

                            </Grid >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    width: '95%',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 4
                                }}>
                                <Typography fontSize={14}>{t('ADMIN.NEW_PUBLICATION_CONTENT')} - English</Typography>
                                <TextField
                                    value={contentEn}
                                    onChange={(e) => setContentEn(e.target.value)}
                                    multiline
                                    minRows={6}
                                    sx={{ width: '95%' }}
                                    variant="outlined"
                                />
                            </Grid >
                            {/* ENGLISH NEWS END */}
                        </>
                        :
                        radioSelected === 'es' ?
                            <>
                                {/* ESPAÑOL NEWS */}
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        width: '95%',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around'
                                    }}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '95%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}>
                                        <Typography fontSize={14}>{t('ADMIN.NEW_PUBLICATION_TITLE')} - Español</Typography>
                                        <TextField
                                            value={titleEs}
                                            onChange={(e) => setTitleEs(e.target.value)}
                                            sx={{ width: '100%' }}
                                            variant="outlined"
                                        />
                                    </Box>

                                </Grid >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        width: '95%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mt: 4
                                    }}>
                                    <Typography fontSize={14}>{t('ADMIN.NEW_PUBLICATION_CONTENT')} - Español</Typography>
                                    <TextField
                                        value={contentEs}
                                        onChange={(e) => setContentEs(e.target.value)}
                                        multiline
                                        minRows={6}
                                        sx={{ width: '95%' }}
                                        variant="outlined"
                                    />
                                </Grid >
                                {/* ESPAÑOL NEWS END */}
                            </>
                            :
                            <>
                                {/* PORTUGUES NEWS */}
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        width: '95%',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around'
                                    }}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '95%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}>
                                        <Typography fontSize={14}>{t('ADMIN.NEW_PUBLICATION_TITLE')} - Português</Typography>
                                        <TextField
                                            value={titlePt}
                                            onChange={(e) => setTitlePt(e.target.value)}
                                            sx={{ width: '100%' }}
                                            variant="outlined"
                                        />
                                    </Box>

                                </Grid >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        width: '95%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mt: 4
                                    }}>
                                    <Typography fontSize={14}>{t('ADMIN.NEW_PUBLICATION_CONTENT')} - Português</Typography>
                                    <TextField
                                        value={contentPt}
                                        onChange={(e) => setContentPt(e.target.value)}
                                        multiline
                                        minRows={6}
                                        sx={{ width: '95%' }}
                                        variant="outlined"
                                    />
                                </Grid >
                                {/* PORTUGUES NEWS END */}

                            </>
                    }
                    <Grid
                        sx={{
                            display: 'flex',
                            width: '95%',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            mt: 2
                        }}>
                        <Box sx={{
                            display: 'flex',
                            width: '95%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <Typography fontSize={14}>{t('ADMIN.EXT_URL')}</Typography>
                            <TextField
                                value={externalURL}
                                onChange={(e) => setExternalURL(e.target.value)}
                                sx={{ width: '100%' }}
                                variant="outlined"
                            />
                        </Box>

                    </Grid >
                    {titleEn === '' ||
                        contentEn === '' ||
                        titleEs === '' ||
                        contentEs === '' ||
                        titlePt === '' ||
                        contentPt === '' ?
                        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{t('ADMIN.NEWS_MUST_3')}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                {titleEn === '' || contentEn === '' ?
                                    <Typography sx={{ ml: 1, mr: 1, color: 'red' }}>{t('ADMIN.MISSING_ENGLISH')}</Typography>
                                    : null}
                                {titleEs === '' || contentEs === '' ?
                                    <Typography sx={{ ml: 1, mr: 1, color: 'red' }}>{t('ADMIN.MISSING_SPANISH')}</Typography>
                                    : null}
                                {titlePt === '' || contentPt === '' ?
                                    <Typography sx={{ ml: 1, mr: 1, color: 'red' }}>{t('ADMIN.MISSING_PORTUGUESE')}</Typography>
                                    : null}
                            </Box>
                        </Box> : null}
                    {
                        externalURL === '' ?
                            <Typography>{t('ADMIN.NEWS_MUST_URL')}</Typography> : null
                    }

                    {titleEn !== '' &&
                        contentEn !== '' &&
                        titleEs !== '' &&
                        contentEs !== '' &&
                        titlePt !== '' &&
                        externalURL !== '' &&
                        contentPt !== '' ?
                        <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', mt: 2 }}>
                            <Button sx={{ mr: 2 }} onClick={() => {
                                dispatch(push('/admin-dao-news'))
                            }} variant='outlined'>{t('MESSAGES.CANCEL')}</Button>
                            {
                                titleEn === '' ||
                                    contentEn === '' ||
                                    titleEs === '' ||
                                    contentEs === '' ||
                                    titlePt === '' ||
                                    contentPt === '' ?
                                    <Button sx={{ ml: 2 }} disabled variant='contained'>{t('CO_ASSESSMENT.SAVE')}</Button>
                                    :
                                    <Button sx={{ ml: 2 }} onClick={handleSaveNews} variant='contained'>{t('CO_ASSESSMENT.SAVE').toUpperCase()}</Button>}
                        </Grid > : null}
                </Box >
            </>
        )
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default DaoNewsEdit