import { TableCell, TableRow } from '@mui/material'
import React from 'react'


const IndividualWalletInfo = ({ text, data }) => {

    return (
        <>
            <TableRow key={data} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center" component="th" scope="row">
                    {text}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    ...{data?.slice(-10)}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    -
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    -
                </TableCell>
            </TableRow>
        </>
    )
}

export default IndividualWalletInfo