import { CircularProgress, Grid, Paper, Typography, useTheme } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

function TokenCard1({ name, symbol, img, matchingTokens, policyId }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const dataContainer = {
    display: 'flex',
    flexDirection: { md: 'column', xs: 'row' },
    alignItems: 'center',
    justifyContent: { xs: 'center' }
  }

  function filterTokensByPolicyId(matchingTokens, policyId) {
    return matchingTokens.filter(token => token.policyId === policyId);
  }

  const filteredTokens = filterTokensByPolicyId(matchingTokens, policyId);

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <Grid
      container
      component={Paper}
      sx={{
        p: 1,
        border: '2px solid var(--borderGray)',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: { xs: '100%', md: '100%' },
        maxWidth: { xs: 'none', md: '450px' },
        height: { xs: 'none', md: '80px' },
        borderRadius: '15px',
        backgroundColor: actualThemeLight ? 'white' : '#1d1d1d'
      }}
    >
      <Grid item xs={12} md={4} sx={dataContainer}>
        <img
          src={img}
          alt={name}
          style={{
            width: '50px',
            height: '50px',
            objectFit: 'scale-down',
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={dataContainer}>
        <Typography fontWeight="bold">{name}</Typography>
        <Typography sx={{ ml: { xs: 1 } }}>{symbol}</Typography>
      </Grid>
      {loading ?
        <Grid item xs={12} md={4} sx={dataContainer}>
          <CircularProgress size={24} />
        </Grid>
        :
        <Grid item xs={12} md={4} sx={dataContainer}>
          {filteredTokens?.length === 0 ?
            <>
              <Typography fontWeight="bold">{t('WALLET_VIEW.AMOUNT')}</Typography>
              <Typography sx={{ ml: { xs: 1 } }}>0</Typography>
            </> :
            <>
              <Typography fontWeight="bold">{t('WALLET_VIEW.AMOUNT')}</Typography>
              <Typography sx={{ ml: { xs: 1 } }}>{filteredTokens[0]?.amount}</Typography>
            </>
          }
        </Grid>
      }
    </Grid>
  )
}

export default TokenCard1
