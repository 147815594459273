import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router"
import {
    GET_PROPOSAL_BY_ID,
    SEND_COMMENT_TO_PROPOSAL,
    SEND_REPLY_TO_COMMENT_TO_PROPOSAL,
    CREATE_REPORT_PROPOSAL
} from '../../Queries/Proposals'
import { GET_USER_INFO } from '../../Queries/Users'
import {
    Box, Typography, Avatar, styled, Button, Grid,
    CircularProgress,
    Link, Tabs, Tab, LinearProgress, TextField, Modal, IconButton, InputLabel, Tooltip,
    useTheme
} from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LanguageIcon from '@mui/icons-material/Language';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PropTypes from 'prop-types';
import Arrows from '../../../src/assets/icons/Arrows.svg'
import Target from '../../../src/assets/icons/target.svg'
import Files from '../../../src/assets/icons/files.svg'
import LinkIcon from '@mui/icons-material/Link';
import i18n from "i18next";
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { push } from 'connected-react-router'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ProposalView = () => {
    const { applicationId } = useParams()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const selectedLanguage = i18n.language
    const { user } = useAuth0()
    const [value, setValue] = React.useState(0);
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [fetchProposal,
        { data: proposalByID, loading: proposalByIDLoading }] = useLazyQuery(
            GET_PROPOSAL_BY_ID,
            {
                variables: { proposalId: applicationId },
                pollInterval: 1500
            }
        )

    const myuuid = uuidv4()
    const dateTime = moment.utc()


    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(async () => {
        await fetchProposal()

    }, [fetchProposal, proposalByID])

    const [score, setScore] = useState(0)

    useEffect(() => {
        if (score > 10) {
            setScore(10)
        }
        if (score < 0) {
            setScore(0)
        }
    }, [score])

    useEffect(() => {
        scrollToBottom()
    })


    const [getUserData, { data: userData }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    useEffect(() => {
        getUserData()
    }, [getUserData])


    const [modalReport, setModalReport] = useState(false)
    const handleOpenReport = () => setModalReport(true);
    const handleCloseReport = () => setModalReport(false);

    const reportStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: '15px',
        boxShadow: 24,
        p: 2,
    };

    const [commentToSend, setCommentToSend] = useState('')
    const [toggleReply, setToggleReply] = useState(false)
    const [replyToPerson, setReplyToPerson] = useState('')
    const [replyToOriginalMsgId, setReplyToOriginalMsgId] = useState('')
    const [sendComment, { loading: loadingSendComment }] = useMutation(SEND_COMMENT_TO_PROPOSAL)
    const [sendReplyToComment, { loading: loadingSendReply }] = useMutation(SEND_REPLY_TO_COMMENT_TO_PROPOSAL)

    const handleSendComment = async (e) => {
        e.preventDefault()
        await sendComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: commentToSend,
                UserId: user.email || user?.sub,
                ProposalId: applicationId
            }
        })
        setCommentToSend("")
    }

    const handleSendReplyComment = async (e) => {
        e.preventDefault()
        await sendReplyToComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: commentToSend,
                UserId: user.email || user?.sub,
                ProposalId: applicationId,
                replyToId: replyToOriginalMsgId
            }
        })
        setCommentToSend("")
        setToggleReply(false)
    }

    const filteredData = proposalByID?.proposals[0]?.messages?.filter(item => {
        const isNullReply = item.replyTo === null
        return isNullReply === true;
    });
    const filteredReplies = proposalByID?.proposals[0]?.messages?.filter(item => {
        const isNullReply = item.replyTo === null
        return isNullReply !== true;
    });

    const [sendReport] = useMutation(CREATE_REPORT_PROPOSAL)

    const newRepID = uuidv4()
    const newUrlID = uuidv4()

    const [reportTitleEn, setReportTitleEn] = useState('')
    const [reportTitleEs, setReportTitleEs] = useState('')
    const [reportTitlePt, setReportTitlePt] = useState('')
    const [achievedObjective, setAchievedObjective] = useState(undefined)
    const [amountReceived, setAmountReceived] = useState(undefined)
    const [amountRequested, setAmountRequested] = useState(undefined)
    const [votePercent, setVotePercent] = useState(undefined)
    const [reportLinkUrl, setReportLinkUrl] = useState(undefined)

    useEffect(() => {
        if (votePercent > 100) {
            setVotePercent(100)
        }
        if (votePercent < 0) {
            setVotePercent(0)
        }
    }, [votePercent])

    const submitReport = async () => {
        try {
            sendReport({
                variables: {
                    reportData: {
                        id: newRepID,
                        titleEn: reportTitleEn,
                        titleEs: reportTitleEs,
                        titlePt: reportTitlePt,
                        achievedObjective: achievedObjective,
                        amountReceived: Number(amountReceived),
                        amountRequested: Number(amountRequested),
                        votePercent: Number(votePercent)
                    },
                    urlData: {
                        id: newUrlID,
                        link: reportLinkUrl,
                        name: "ReportLink"
                    },
                    reportId: newRepID,
                    urlId: newUrlID,
                    proposalId: applicationId
                }
            })
            handleCloseReport()
            setReportTitleEn('')
            setReportTitleEs('')
            setReportTitlePt('')
            setAchievedObjective(undefined)
            setAmountReceived(undefined)
            setAmountRequested(undefined)
            setVotePercent(undefined)
            setReportLinkUrl(undefined)
        }
        catch (error) {
            //Err
        }
    }

    if (proposalByIDLoading) {
        return (
            <>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '30vh'
                }}>
                    <CircularProgress />
                </Box>
            </>
        )
    }

    // Button styling for back button
    const BackButton = styled(Button)(({ theme }) => ({
        color: theme.palette.text.hint,
        fontFamily: 'Quicksand, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    }))



    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} className="right">
                    <Box sx={{ backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", }}>
                        <Grid container>
                            {/* BACK BUTTON & AVATAR */}
                            <Grid item md={1} xs={12} sx={{ alignSelf: 'center', alignItems: 'center', justifyContent: { md: 'center', xs: 'center' }, mr: { md: 0, xs: '90%' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: { md: 'center', xs: 'left' }, alignItems: 'center' }}>
                                    <ArrowBackIcon sx={{ color: '#4F4F4F', fontSize: '16px' }} />
                                    <BackButton
                                        onClick={() => dispatch(goBack())}
                                        sx={{ color: '#4F4F4F', textDecoration: 'underline', fontSize: '14px' }}
                                    >
                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                    </BackButton>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={4} sx={{
                                maxHeight: '16vh',
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                        alt={proposalByID?.proposals[0]?.company?.coName}
                                        src={proposalByID?.proposals[0]?.company?.logo}
                                    />
                                </Box>
                            </Grid>
                            {/* FUND INFO */}
                            <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                <Grid container>
                                    {/* COLUMN 1 */}
                                    <Grid item md={4.5} xs={12}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ fontSize: '20px', mr: 1 }}>
                                                    {t('INVESTMENT_TABLE.COMPANY')}:
                                                </Typography>
                                                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mr: 1 }}>
                                                    {proposalByID?.proposals[0]?.company.coName}
                                                </Typography>
                                                {((proposalByID?.proposals[0]?.fund?.status === 'awarded' || proposalByID?.proposals[0]?.fund?.status === 'participant' || proposalByID?.proposals[0]?.fund?.status === 'assessed') && proposalByID?.proposals[0]?.assessmentRating != null) &&
                                                    <Box sx={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#9A1D4C', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: '#FFF' }}>
                                                            {proposalByID?.proposals[0]?.assessmentRating}
                                                        </Typography>
                                                    </Box>}
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {proposalByID?.proposals[0]?.assessmentStatus === 'pending' &&
                                                        t('COMPANY.PENDING')}
                                                    {proposalByID?.proposals[0]?.assessmentStatus === 'assessed' &&
                                                        t('COMPANY.ASSESSED')}
                                                    {proposalByID?.proposals[0]?.assessmentStatus === 'rejected' &&
                                                        t('COMPANY.REJECTED')}
                                                    {proposalByID?.proposals[0]?.assessmentStatus === 'awarded' &&
                                                        t('COMPANY.AWARDED')}
                                                </Typography>
                                                <FiberManualRecordIcon fontSize='small' sx={{ ml: 1, mr: 1, fontSize: 'small' }} />
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {proposalByID?.proposals[0]?.fund.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* COLUMN 2 */}
                                    <Grid item md={4} xs={12} sx={{ alignSelf: 'center', height: '100%', ml: { md: 3, xs: 0 } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                <Typography sx={{ fontSize: '18px', mr: 1 }}>
                                                    {t('COMPANY_VIEW.OWNER')}:
                                                </Typography>
                                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline' }}>
                                                    {proposalByID?.proposals[0]?.company.users[0].userName}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <LanguageIcon />
                                                <Typography sx={{ fontSize: '18px', ml: 1 }}>
                                                    {proposalByID?.proposals[0]?.company.address}, {proposalByID?.proposals[0]?.company.country.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {(proposalByID?.proposals[0]?.assessmentStatus === 'draft' || proposalByID?.proposals[0]?.assessmentStatus === 'pending' || proposalByID?.proposals[0]?.assessmentStatus === 'rejected') && proposalByID?.proposals[0]?.company?.users[0]?.email === user.email &&
                                        <Tooltip title={t('MY_COMPANIES.EDIT_PROPOSAL')}>
                                            <EditIcon sx={{ cursor: 'pointer', mt: 2 }} onClick={() => { dispatch(push(`/edit-proposal/${applicationId}`)) }} />
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', pl: { md: 20, xs: 1 }, pr: { md: 8, xs: 0 } }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={t('FUND.INFORMATION')} {...a11yProps(0)} />
                                    <Tab label={'Q&A'} {...a11yProps(1)} />
                                    <Tab label={t('COMPANY_VIEW.REPORTS')} {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, justifyContent: { md: 'space-between', xs: 'center' } }}>
                                    <Box sx={{ width: { md: '48%', xs: '100%' }, display: 'flex', flexDirection: 'column', mt: 2, alignItems: { md: 'start', xs: 'center' } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: { md: 'start', xs: 'center' } }}>
                                            <img src={Arrows} alt="icon" width="70px" />
                                            <Typography sx={{ fontSize: '18px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? proposalByID?.proposals[0]?.titleEn :
                                                    selectedLanguage === 'es' && proposalByID?.proposals[0]?.titleEs !== null ?
                                                        proposalByID?.proposals[0]?.titleEs :
                                                        selectedLanguage === 'pt' && proposalByID?.proposals[0]?.titlePt !== null ?
                                                            proposalByID?.proposals[0]?.titlePt : proposalByID?.proposals[0]?.titleEn}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {
                                                    selectedLanguage === 'en'
                                                        ? proposalByID?.proposals[0]?.descriptionEn
                                                        : selectedLanguage === 'es' &&
                                                            proposalByID?.proposals[0]?.descriptionEs !== ''
                                                            ? proposalByID?.proposals[0]?.descriptionEs
                                                            : selectedLanguage === 'pt' &&
                                                                proposalByID?.proposals[0]?.descriptionPt !== ''
                                                                ? proposalByID?.proposals[0]?.descriptionPt
                                                                : proposalByID?.proposals[0]?.descriptionEn
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: { md: '48%', xs: '100%' }, display: 'flex', flexDirection: 'column', mt: { md: 2, xs: 4 }, alignItems: { md: 'start', xs: 'center' } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                            <img src={Target} alt="icon" width="70px" />
                                            <Typography sx={{ fontSize: '18px', ml: 2 }}>{t('INVERSOR_VIEW.OFFERING_CARD.DESCRIPTION')}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('FUND.REQUESTED_INVESTMENT')}:</Typography>
                                            <Typography sx={{ ml: 1, fontWeight: 'bold' }}>$ {proposalByID?.proposals[0]?.reqInvestAmount.toLocaleString()}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('COMPANY_VIEW.RECEIVED')}: </Typography>
                                            <Typography sx={{ ml: 1, fontWeight: 'bold' }}>$ {0}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('ADMIN.RECEIVED_VOTES')}:</Typography>
                                            <Typography sx={{ ml: 1, fontWeight: 'bold' }}>{proposalByID?.proposals[0]?.voteCount || 0}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                                            <Typography sx={{ fontSize: '14px' }} >
                                                {proposalByID?.proposals[0]?.objectives}
                                            </Typography>
                                        </Box>
                                        {proposalByID?.proposals[0]?.url[0]?.link &&
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                                <Link
                                                    href={proposalByID?.proposals[0]?.url[0]?.link}
                                                    target={{ _blank: true }}
                                                    color='inherit'
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <LinkIcon />
                                                    <Typography sx={{ fontSize: '12px' }}>{t('MY_COMPANIES.CUSTOM_URL')}</Typography>
                                                </Link>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 4, justifyContent: 'space-between' }}>
                                    <Box sx={{ width: { md: '48%', xs: '0%' }, display: 'flex', flexDirection: 'column' }}>
                                    </Box>
                                    <Box sx={{ width: { md: '48%', xs: '100%' }, display: 'flex', flexDirection: 'column', alignItems: { md: 'start', xs: 'center' } }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                            <img src={Files} alt="icon" width="55px" />
                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', ml: 2 }}>{t('CREATE_COMPANY.PROJECT_FILES')}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                            <Link
                                                href={proposalByID?.proposals[0]?.milestonesFile}
                                                target={{ _blank: true }}
                                                color='inherit'
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <LinkIcon />
                                                <Typography sx={{ fontSize: '12px' }}>{t('CO_ASSESSMENT.DOWNLOAD_MILESTONES')}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                            <Link
                                                href={proposalByID?.proposals[0]?.actionPlanFile}
                                                target={{ _blank: true }}
                                                color='inherit'
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <LinkIcon />
                                                <Typography sx={{ fontSize: '12px' }}>{t('CO_ASSESSMENT.DOWNLOAD_ACT_PLAN')}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                            <Link
                                                href={proposalByID?.proposals[0]?.assessmentStatus === 'assessed' && proposalByID?.proposals[0]?.assessmentDetail}
                                                target={{ _blank: true }}
                                                color='inherit'
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <LinkIcon />
                                                <Typography sx={{ fontSize: '12px' }}>{t('CO_ASSESSMENT.DOWNLOAD_ASSESSMENT')}</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <>
                                    <Box>
                                        <Box sx={{
                                            width: '100%',
                                            height: '48vh',
                                            borderBottom: '1px solid black',
                                            overflowY: 'scroll'
                                        }}>
                                            {filteredData?.length === 0 &&
                                                <>
                                                    <Typography>{t('NEWS_SECTION.NO_COMMENTS')}</Typography>
                                                </>
                                            }
                                            {filteredData?.map((message) => {
                                                return (
                                                    <Box
                                                        key={message.id}
                                                        sx={{
                                                            mt: 1, mb: 1, ml: 1, mr: 2,
                                                            border: '1px solid pink', borderRadius: '10px'
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row', alignItems: 'center',
                                                                ml: 1
                                                            }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    color: '#9A1D4C',
                                                                    fontSize: '16px'
                                                                }}>
                                                                {message.sentBy.userName}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ ml: 1, fontSize: '12px' }}>
                                                                {message.on.split('T')[0]}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ ml: 1, fontSize: '12px' }}>
                                                                {message.on.split('T')[1].split('.')[0]}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', ml: 1, mt: -1 }}>
                                                            <Typography>{message.text}</Typography>
                                                            <Box
                                                                onClick={() => {
                                                                    setToggleReply(!toggleReply)
                                                                    setReplyToPerson(message.sentBy.userName)
                                                                    setReplyToOriginalMsgId(message.id)
                                                                }}
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center', ml: 1,
                                                                    cursor: 'pointer',
                                                                    p: 0.2
                                                                }}>
                                                                <ReplyIcon fontSize='small' />
                                                                <Typography sx={{ fontSize: '10px' }}>{t('ADMIN.REPLY')}</Typography>
                                                            </Box>
                                                        </Box>
                                                        {filteredReplies.reverse().map((reply) => {
                                                            return (
                                                                <Box key={reply.id}>
                                                                    {reply.replyTo == message.id ?
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row', alignItems: 'center',
                                                                                    ml: 3
                                                                                }}>
                                                                                <SubdirectoryArrowRightIcon />
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontWeight: 'bold',
                                                                                        color: '#DB417B',
                                                                                        fontSize: '14px'
                                                                                    }}>
                                                                                    {reply.sentBy.userName}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{ ml: 1, mt: 0.4, fontSize: '12px' }}>
                                                                                    {reply.on.split('T')[0]}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{ ml: 1, mt: 0.4, fontSize: '12px' }}>
                                                                                    {reply.on.split('T')[1].split('.')[0]}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row', alignItems: 'center',
                                                                                    ml: 6
                                                                                }}>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '12px',
                                                                                        mt: -0.8
                                                                                    }}>
                                                                                    {reply.text}
                                                                                </Typography>
                                                                            </Box>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                )

                                            })}
                                        </Box>

                                    </Box>
                                    {/* LEAVING A COMMENT - NOT REPLY */}
                                    {toggleReply === false &&
                                        <> {loadingSendComment ?
                                            <Box sx={{ width: '100%', pl: 2, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                                <LinearProgress />
                                            </Box>
                                            :
                                            <Box sx={{ pl: 2, mt: 1 }}>
                                                <Typography sx={{ fontSize: 12 }}>
                                                    {t('FUND_VIEW.LEAVE_A_COMMENT')}
                                                </Typography>

                                                <form style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mb: 1
                                                }} onSubmit={handleSendComment}>
                                                    <TextField
                                                        size='small'
                                                        value={commentToSend}
                                                        onChange={(event) => { setCommentToSend(event.target.value) }}
                                                        sx={{ width: '90%' }}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        type='submit'
                                                        sx={{ ml: 2 }}
                                                        variant='contained'>
                                                        {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                                    </Button>
                                                </form>
                                            </Box>
                                        }
                                        </>
                                    }

                                    {/* LEAVING A  REPLY */}
                                    {toggleReply === true &&
                                        <> {loadingSendReply ?
                                            <Box sx={{ width: '100%', pl: 2, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                                <LinearProgress />
                                            </Box>
                                            :
                                            <Box sx={{ pl: 2, mt: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                        {t('ADMIN.REPLY_TO')} {replyToPerson}
                                                    </Typography>
                                                    <CloseIcon onClick={() => { setToggleReply(false) }} sx={{ ml: 1, cursor: 'pointer' }} fontSize='small' />
                                                </Box>
                                                <form style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mb: 1
                                                }} onSubmit={handleSendReplyComment}>
                                                    <TextField
                                                        size='small'
                                                        value={commentToSend}
                                                        onChange={(event) => { setCommentToSend(event.target.value) }}
                                                        sx={{ width: '90%' }}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        type='submit'
                                                        sx={{ ml: 2 }}
                                                        variant='contained'>
                                                        {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                                    </Button>
                                                </form>
                                            </Box>
                                        }
                                        </>
                                    }
                                </>

                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {isCoreTeam && <Button onClick={handleOpenReport} variant='outlined'>
                                    {t('ADMIN.UPLOAD_REPORT')}
                                </Button>}
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center', mt: 2 }}>
                                    {proposalByID?.proposals[0]?.report?.map((rep) => {
                                        return (
                                            <Box
                                                key={rep.id}
                                                sx={{
                                                    width: '100% ',
                                                    border: '1px solid #DB417B',
                                                    borderRadius: '15px',
                                                    p: 2,
                                                    mt: 2
                                                }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <Box>
                                                        <Typography>
                                                            {selectedLanguage === 'en' ? rep.titleEn :
                                                                selectedLanguage === 'es' ? rep.titleEs :
                                                                    rep.titlePt
                                                            }</Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.REPORT')}
                                                        </Typography>
                                                        <Link
                                                            href={rep.url.link}
                                                            target={{ _blank: true }}
                                                            color='inherit'>
                                                            {rep.url.link}
                                                        </Link>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.GOAL_ACHIEVED')}
                                                        </Typography>
                                                        <Typography>
                                                            {rep.achievedObjective}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.RECEIVED')}
                                                        </Typography>
                                                        <Typography>
                                                            {rep.amountReceived}/{rep.amountRequested}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.VOTES')}
                                                        </Typography>
                                                        <Typography>
                                                            {rep.votePercent} %
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Modal
                                    open={modalReport}
                                    onClose={handleCloseReport}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={reportStyle}>
                                        <Box sx={{
                                            position: 'relative',
                                            width: '100%', height: '50px'
                                        }}>
                                            <IconButton onClick={handleCloseReport} sx={{ position: 'absolute', right: '10px' }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <Grid container sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                            <Grid item
                                                md={3}
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                <Typography>
                                                    English Title
                                                </Typography>
                                                <TextField size='small'
                                                    value={reportTitleEn}
                                                    onChange={(e) => setReportTitleEn(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item
                                                md={3}
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    ml: 1,
                                                    mr: 1
                                                }}>
                                                <Typography>
                                                    Titulo en Español
                                                </Typography>
                                                <TextField size='small'
                                                    value={reportTitleEs}
                                                    onChange={(e) => setReportTitleEs(e.target.value)} />
                                            </Grid>
                                            <Grid item
                                                md={3}
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                <Typography>
                                                    Titulo em portugues
                                                </Typography>
                                                <TextField size='small'
                                                    value={reportTitlePt}
                                                    onChange={(e) => setReportTitlePt(e.target.value)} />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: '100%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <Grid item md={4} xs={12} sx={{ pl: { md: 8, xs: 0 } }}>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        width: '100%'
                                                    }}>
                                                    {t('ADMIN.GOAL_ACHIEVED')}
                                                </InputLabel>
                                            </Grid>
                                            <Grid item md={8} xs={12} sx={{ pr: { md: 12, xs: 0 } }}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    value={achievedObjective}
                                                    onChange={(e) => setAchievedObjective(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ display: 'flex', flexDirection: 'row', width: '90%', alignItems: 'center', justifyContent: 'center', pl: { md: 2, xs: 0 }, pr: { md: 2, xs: 0 } }}>
                                            <Grid item sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: { md: '49%', xs: '100%' }, alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                <Grid item md={4} xs={12} sx={{ pl: { md: 4, xs: 0 } }}>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                        }}>
                                                        {t('ADMIN.RECEIVED')}
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                        width={'100%'}
                                                        value={amountReceived}
                                                        onChange={(e) => setAmountReceived(e.target.value)}
                                                        error={isNaN(amountReceived) && amountReceived !== ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: { md: '49%', xs: '100%' }, alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                <Grid item md={5} xs={12} sx={{ pl: { md: 0, xs: 0 } }}>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                        }}>
                                                        /{t('ADMIN.AMOUNT_GATHER')}
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item md={7} xs={12}>

                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                        width={'50%'}
                                                        value={amountRequested}
                                                        onChange={(e) => setAmountRequested(e.target.value)}
                                                        error={isNaN(amountReceived) && amountReceived !== ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: { md: '49%', xs: '100%' }, alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    width: '32%'
                                                }}>
                                                % {t('ADMIN.VOTES')}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                width={'100px'}
                                                type="number"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                value={votePercent}
                                                onChange={(e) => setVotePercent(e.target.value)}
                                                error={isNaN(votePercent) && votePercent !== ''}
                                            />
                                        </Grid>
                                        <Grid container sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: { md: '100%', xs: '100%' }, alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <Grid item md={4} xs={12} sx={{ pl: { md: 8, xs: 0 } }}>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                    }}>
                                                    {t('CREATE_OFFERING.USEFUL_LINK')}
                                                </InputLabel>
                                            </Grid>
                                            <Grid item md={8} xs={12} sx={{ pr: { md: 12, xs: 0 } }}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    value={reportLinkUrl}
                                                    onChange={(e) => setReportLinkUrl(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 2, mb: { md: 0, xs: 8 } }}>
                                            <Button onClick={handleCloseReport} sx={{ mr: 2 }} variant='contained'>Cancel</Button>
                                            {reportTitleEn === '' ||
                                                reportTitleEs === '' ||
                                                reportTitlePt === '' ||
                                                achievedObjective === undefined ||
                                                amountReceived === undefined ||
                                                amountRequested === undefined ||
                                                votePercent === undefined ||
                                                reportLinkUrl === undefined
                                                ?
                                                <Button disabled variant='contained'>Save</Button>
                                                :
                                                <Button onClick={submitReport} variant='contained'>Save</Button>
                                            }
                                        </Box>
                                    </Box>
                                </Modal>
                            </TabPanel>
                        </Box>
                    </Grid >
                </Grid >
            </Grid >
        </>
    )
}

export default ProposalView