import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, InputAdornment, LinearProgress, Link, Slide, TextField, Typography } from '@mui/material'
import React from 'react'
import { push } from 'connected-react-router'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Address, TransactionUnspentOutput } from '@emurgo/cardano-serialization-lib-asmjs'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_DVC_POLICY } from '../../Queries/Tokens'
import { CREATE_OFFER_PURCHASE } from '../../Queries/OfferPurchases'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../store/actions/snackBarActions'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

// eslint-disable-next-line
const WaitingListModal = ({ open, handleClose, minInvest, maxInvest, txFundId, scData, token, data }) => {
    const { t } = useTranslation()
    const [amountToInvest, setAmountToInvest] = useState('')
    const dispatch = useDispatch()

    const closeModal = () => {
        handleClose()
    }

    const [walletEnabled, setWalletEnabled] = useState(false)
    void window.cardano?.eternl?.isEnabled().then(res => {
        setWalletEnabled(res)
    })

    const [noWallet, setNoWallet] = useState(false)

    function noEnabledWallet() {
        setTimeout(function () {
            setNoWallet(true);
        }, 5000);
    }

    noEnabledWallet()

    const [createOfferPurchase] = useMutation(CREATE_OFFER_PURCHASE)
    const [XChangeAddress, setXChangeAddress] = useState(undefined)
    const [API, setAPI] = useState(null)
    const [selectedWallet, setSelectedWallet] = useState(null)
    const [wallets, setWallets] = useState([])
    const [Utxos, setUtxos] = useState(undefined)
    const [hasInvestorToken, setHasInvestorToken] = useState(undefined)
    const [getDVCData, { data: DVCdata }] = useLazyQuery(GET_DVC_POLICY)

    useEffect(() => {
        getDVCData()
    }, [])
    const investorTokenPolicy = DVCdata?.tokens[0]?.policyId

    function findAmountByInvestorTokenPolicy(data, investorTokenPolicy) {
        if (
            !Array.isArray(data) ||
            typeof investorTokenPolicy !== 'string' ||
            !investorTokenPolicy.trim()
        ) {
            return null
        }

        for (const item of data) {
            if (
                item.multiAssetStr &&
                item.multiAssetStr.includes(investorTokenPolicy)
            ) {
                const amountMatch = item.multiAssetStr.match(/\+ (\d+) \+.*\s\((.*?)\)/)
                if (amountMatch) {
                    const amount = parseInt(amountMatch[1])
                    return amount
                }
            }
        }
        return null
    }

    const DVCamount = findAmountByInvestorTokenPolicy(Utxos, investorTokenPolicy)

    useEffect(() => {
        if (DVCamount >= 1) {
            setHasInvestorToken(true)
        } else {
            setHasInvestorToken(false)
        }
    }, [DVCamount])

    const handleBuyClick = () => {
        const url = 'https://tokenallies.com/#DVC'
        window.open(url, '_blank')
    }

    const checkIfWalletEnabled = async () => {
        let walletIsEnabled = false
        try {
            const walletName = selectedWallet
            walletIsEnabled = await window.cardano[walletName]?.isEnabled()

            setWalletEnabled(walletIsEnabled)

        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return walletIsEnabled
    }


    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])

    const enableWallet = async () => {
        const walletKey = selectedWallet
        try {
            setAPI(await window.cardano[walletKey]?.enable())
            setWalletEnabled(true)
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return checkIfWalletEnabled()
    }

    async function checkEnabledStatus(walletNames) {
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                            setSelectedWallet(walletName)
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}:`, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );

        setWalletEnabled(anyEnabled);
    }


    useEffect(() => {
        checkEnabledStatus(wallets)
        const intervalId = setInterval(() => {
            checkEnabledStatus(wallets)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [wallets])

    const getUtxos = async () => {
        let utxos = []
        if (API !== null) {
            try {
                const rawUtxos = await API?.getUtxos()

                for (const rawUtxo of rawUtxos) {
                    const utxo = TransactionUnspentOutput.from_bytes(
                        Buffer.from(rawUtxo, 'hex')
                    )
                    const input = utxo.input()
                    const txid = Buffer.from(
                        input.transaction_id().to_bytes(),
                        'utf8'
                    ).toString('hex')
                    const txindx = input.index()
                    const output = utxo.output()
                    const amount = output.amount().coin().to_str()
                    const multiasset = output.amount().multiasset()
                    let multiAssetStr = ''

                    if (multiasset) {
                        const keys = multiasset.keys()
                        const N = keys.len()

                        for (let i = 0; i < N; i++) {
                            const policyId = keys.get(i)
                            const policyIdHex = Buffer.from(
                                policyId.to_bytes(),
                                'utf8'
                            ).toString('hex')

                            const assets = multiasset.get(policyId)
                            const assetNames = assets.keys()
                            const K = assetNames.len()

                            for (let j = 0; j < K; j++) {
                                const assetName = assetNames.get(j)
                                const assetNameString = Buffer.from(
                                    assetName.name(),
                                    'utf8'
                                ).toString()
                                const assetNameHex = Buffer.from(
                                    assetName.name(),
                                    'utf8'
                                ).toString('hex')
                                const multiassetAmt = multiasset.get_asset(policyId, assetName)
                                multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                            }
                        }
                    }

                    const obj = {
                        txid: txid,
                        txindx: txindx,
                        amount: amount,
                        str: `${txid} #${txindx} = ${amount}`,
                        multiAssetStr: multiAssetStr,
                        TransactionUnspentOutput: utxo,
                    }
                    utxos.push(obj)
                }
                setUtxos(utxos)
            } catch (err) {
                setSelectedWallet(null)
                //Err
            }
        }
    }

    useEffect(() => {
        if (selectedWallet !== null) {
            enableWallet()
        }
    }, [selectedWallet])

    const getChangeAddress = async () => {
        if (API !== null) {
            try {
                const raw = await API.getChangeAddress()
                const changeAddressObj = Address.from_bytes(
                    Buffer.from(raw, 'hex')
                ).to_bech32()
                setXChangeAddress(changeAddressObj)
            } catch (err) {
                setSelectedWallet(null)
                //Err
            }
        }
    }

    const [netId, setNetId] = useState(undefined)
    const appropiateNetworkIdIdentified = 0
    const [isCorrectNetworkSelected, setIsCorrectNetworkSelected] = useState(null)

    useEffect(() => {
        if (netId !== undefined) {
            if (appropiateNetworkIdIdentified === netId) {
                setIsCorrectNetworkSelected(true)
            } else {
                setIsCorrectNetworkSelected(false)
            }
        }
    }, [appropiateNetworkIdIdentified, netId])

    const getNetId = async () => {
        if (API !== null) {
            try {
                const id = await API.getNetworkId()
                setNetId(id)
            } catch (err) {
                setNetId(undefined)
            }
        }
    }

    const refreshData = async () => {
        try {
            if (walletEnabled && API !== null) {
                await getChangeAddress()
                await getUtxos()
                await getNetId()
            }
        } catch (err) {
            setSelectedWallet(undefined)
            //Err
        }
    }

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if ((XChangeAddress === undefined || API !== null) && walletEnabled) {
                await refreshData();
                await getNetId();
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [XChangeAddress, API, walletEnabled]);

    const { user } = useAuth0()
    const [creatingTx, setCreatingTx] = useState(false)

    async function createTransaction() {
        try {
            setCreatingTx(true);
            const newPurchaseID = uuidv4()
            const dateFormatted = new Date()

            await createOfferPurchase({
                variables: {
                    userID: user?.email,
                    fundID: txFundId,
                    purchaseID: newPurchaseID,
                    amount: parseFloat(amountToInvest),
                    date: dateFormatted,
                    price: parseFloat(amountToInvest),
                    status: "Investment Intention - Waiting List",
                    investorAddress: XChangeAddress
                },
            })

            dispatch(showMessage({ message: 'Investment Intention Sent, you can check the status in My Assets Tab', variant: 'success' }));
            await setCreatingTx(false);
            await handleClose()
            return;
        } catch (error) {
            setCreatingTx(false);
            dispatch(showMessage({ message: 'Something went wrong, please try again' }));
            if (error.response) {
                console.error('Server Error:', error.response.status, error.response.data);
            } else if (error.request) {
                console.error('No response received from server');
            } else {
                console.error('Error:', error.message);
            }
            throw error;
        }
    }



    const currFundTokenPolicy = token?.policyId
    const currFundTokenAmount = findAmountByInvestorTokenPolicy(Utxos, currFundTokenPolicy)

    const totalAmountForUser = data?.offerPurchase
        .filter(offer => offer?.users?.id === user?.email || offer?.users?.id === user?.sub)
        .reduce((total, offer) => total + offer?.amount, 0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (amountToInvest === '') {
                setAmountToInvest(minInvest)
            }
            if (amountToInvest < minInvest && currFundTokenAmount > minInvest) {
                setAmountToInvest(minInvest)
            }
            if (amountToInvest > maxInvest) {
                setAmountToInvest(maxInvest)
            }
            if (amountToInvest > currFundTokenAmount) {
                setAmountToInvest(currFundTokenAmount)
            }
            if (amountToInvest > (maxInvest - totalAmountForUser)) {
                setAmountToInvest(maxInvest - totalAmountForUser)
            }
        }, 600);
        return () => clearTimeout(timer);
    }, [amountToInvest, minInvest, currFundTokenAmount, totalAmountForUser])


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogContent style={{ marginBottom: '16px' }}>
                <>
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'right',
                            position: 'absolute',
                            mt: -1
                        }}>
                        <IconButton>
                            <CloseIcon onClick={closeModal} />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { sm: '14px', md: '18px', lg: '20px' },
                                fontFamily: 'Quicksand, sans-serif',
                                lineHeight: '20px',
                                fontWeight: 'bold',
                                color: '#9A1D4C',
                            }}
                        >
                            {t('DASHBOARD.WAITING_LIST')}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: 3, p: 1,
                        border: '2px solid #e3e1e3',
                        borderRadius: 1
                    }}>
                        <Typography sx={{ mr: 1, fontWeight: 'bold' }}>{t('ADMIN.MIN_INVEST')}</Typography>
                        <Typography sx={{ mr: 1 }}>{minInvest} {token?.symbol}</Typography>
                        <Typography>-</Typography>
                        <Typography sx={{ ml: 1, mr: 1, fontWeight: 'bold' }}>{t('ADMIN.MAX_INVEST')}</Typography>
                        <Typography>{maxInvest} {token?.symbol}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                            <Typography sx={{ mr: 2 }}>{t('INVEST_MODAL.INVEST_AMOUNT')}</Typography>
                            <TextField
                                type='number'
                                value={amountToInvest}
                                sx={{ m: 1, width: '25ch' }}
                                onChange={(e) => {
                                    const intNumber = parseInt(e.target.value)
                                    setAmountToInvest(intNumber)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{token?.symbol}</InputAdornment>,
                                }}
                            />
                        </Box>
                        {isCorrectNetworkSelected === false &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ color: '#f7712e' }}>{t('WALLET_VIEW.WRONG_NET')}</Typography>
                            </Box>
                        }
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 1, p: 1,
                        }}>
                            <Typography>{t('INVEST_MODAL.HELPER_3')}</Typography>
                            <Typography>{t('INVEST_MODAL.HELPER_4')}</Typography>
                            <Typography>{t('INVEST_MODAL.HELPER_1')}</Typography>
                        </Box>
                        {walletEnabled ?
                            <>
                                {creatingTx ?
                                    <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                                        <LinearProgress />
                                    </Box>
                                    :
                                    <Box>
                                        {hasInvestorToken ?
                                            <>
                                                <Button
                                                    sx={{ width: '200px', mt: 2, mr: 2 }}
                                                    onClick={closeModal}
                                                    variant='outlined'>
                                                    {t('CO_ASSESSMENT.CANCEL')}
                                                </Button>
                                                <Button
                                                    sx={{ width: '200px', mt: 2, ml: 2 }}
                                                    variant='contained'
                                                    onClick={() => { createTransaction() }}
                                                >
                                                    {t('INVEST_MODAL.GET_ON_WAITING')}
                                                </Button>
                                            </>
                                            :
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                                                <Typography
                                                    id="modal-modal-description"
                                                >
                                                    {t('PERFIL_CONFIG_VIEW.REQ_TOKEN1')}
                                                </Typography>
                                                <Button
                                                    onClick={handleBuyClick}
                                                    sx={{ mt: 1 }}
                                                    variant="contained"
                                                >
                                                    {t('PERFIL_CONFIG_VIEW.BUY_HERE')}
                                                </Button>
                                                <CircularProgress size={20} sx={{ mt: 2 }} />
                                            </Box>
                                        }
                                    </Box>}
                            </>
                            : noWallet ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                                    <Typography>{t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET')}</Typography>
                                    <Link sx={{ cursor: 'pointer' }} onClick={() => { dispatch(push('/wallet')) }}>
                                        {t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT')}
                                    </Link>
                                </Box>
                                :
                                <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                                    <LinearProgress />
                                </Box>
                        }
                    </Box>
                </>
            </DialogContent>
        </Dialog>
    )
}

export default WaitingListModal