import React from 'react'
import {
    Box, Button, Dialog, DialogContent, Slide,
    Typography
} from '@mui/material'
import i18n from "i18next";
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const EventModal = ({ open, handleClose, data }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const selectedLanguage = i18n.language
    const closeModal = () => {
        handleClose()
    }
    const handleClickLink = () => {
        dispatch(push(`/fund-detail/${data?.fund?.id}`))
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogContent>
                <Box sx={{ width: { md: '600px', xs: '95%' } }}>
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            pr: 2
                        }}>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={closeModal}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { sm: '14px', md: '18px', lg: '20px' },
                                fontFamily: 'Quicksand, sans-serif',
                                lineHeight: '20px',
                                fontWeight: 'bold',
                                color: '#9A1D4C',
                            }}
                        >
                            {t('CALENDAR.EVENT')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mt: 2
                            }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{selectedLanguage === 'en' ? data?.titleEn :
                                selectedLanguage === 'es' ? data?.titleEs :
                                    selectedLanguage === 'pt' && data?.titlePt}
                            </Typography>
                            {data?.fund !== undefined && data?.fund !== null ?
                                <Typography sx={{ ml: 1, fontWeight: 'bold' }}> - {data?.fund?.name}</Typography>
                                : null}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2, justifyContent: 'center' }}>
                        <Typography sx={{ textAlign: 'center' }}>{selectedLanguage === 'en' ? data?.detailEn :
                            selectedLanguage === 'es' ? data?.detailEs :
                                selectedLanguage === 'pt' && data?.detailPt}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {data?.fund !== undefined && data?.fund !== null ? <Button sx={{ mt: 2 }} variant='contained' onClick={handleClickLink} >{t('DASHBOARD_VIEW.GO_TO_FUND')}  <OpenInNewIcon fontSize='small' /></Button> : null}
                    </Box>
                </Box>
            </DialogContent >
        </Dialog >
    )
}

export default EventModal