import {
  CLEAR_PERFIL_INFO,
  GET_PERFIL_INFO,
  SHOW_INVESTOR_OR_OFFERER_VIEWS,
  GET_WALLET_INFO,
} from '../actions/perfilActions'

const initialState = {
  perfilInfo: null,
  walletInfo: null,
  viewTypeToShow: 'Offerer',
}

const PerfilReducer = (state = initialState, action) => {
  let { type, payload } = action
  switch (type) {
    case GET_PERFIL_INFO.success: {
      return {
        ...state,
        perfilInfo: payload,
      }
    }
    case SHOW_INVESTOR_OR_OFFERER_VIEWS.success: {
      return {
        ...state,
        viewTypeToShow: payload,
      }
    }
    case CLEAR_PERFIL_INFO: {
      return initialState
    }
    case GET_WALLET_INFO.success: {
      return {
        ...state,
        walletInfo: payload,
      }
    }
    default: {
      return state
    }
  }
}

export default PerfilReducer
