import React, { useState } from 'react'
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { showMessage } from '../../../store/actions/snackBarActions'
import { CREATE_FUND_PURPOSE } from '../../../Queries/Admin';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid'
import { goBack } from 'connected-react-router'

const FundPurposeCreate = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [titleEn, setTitleEn] = useState('')
    const [titleEs, setTitleEs] = useState('')
    const [titlePt, setTitlePt] = useState('')
    const [targetEn, setTargetEn] = useState('')
    const [targetEs, setTargetEs] = useState('')
    const [targetPt, setTargetPt] = useState('')
    const [createFundPurpose] = useMutation(CREATE_FUND_PURPOSE)
    const uniqueID = uuidv4()
    const handlePublish = async () => {
        await createFundPurpose({
            variables: {
                data: {
                    id: uniqueID,
                    titleEn: titleEn,
                    titleEs: titleEs,
                    titlePt: titlePt,
                    targetEn: targetEn,
                    targetEs: targetEs,
                    targetPt: targetPt
                }
            }
        })
        await dispatch(push('/admin-fund-purpose'))
        await dispatch(showMessage({ message: 'Purpose created!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 6
            }}>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "left"
                    }}>
                    <Box
                        onClick={handleOpenConfirmation}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                        <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                    </Box>
                    <Box sx={{ ml: 4 }}>
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}>
                            {t('ADMIN.NEW_FUND_PURPOSE')}
                        </Typography>
                    </Box>
                </Box>
            </Grid >
            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        English Title
                    </Typography>
                    <TextField size='small'
                        value={titleEn}
                        onChange={(e) => setTitleEn(e.target.value)}
                    />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Titulo en Español
                    </Typography>
                    <TextField size='small'
                        value={titleEs}
                        onChange={(e) => setTitleEs(e.target.value)} />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Titulo em portugues
                    </Typography>
                    <TextField size='small'
                        value={titlePt}
                        onChange={(e) => setTitlePt(e.target.value)} />
                </Grid>
            </Grid>

            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    mt: 3
                }}>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        English Target
                    </Typography>
                    <TextField size='small'
                        multiline
                        rows={4}
                        value={targetEn}
                        onChange={(e) => setTargetEn(e.target.value)}
                    />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Target en Español
                    </Typography>
                    <TextField size='small'
                        multiline
                        rows={4}
                        value={targetEs}
                        onChange={(e) => setTargetEs(e.target.value)} />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Target em portugues
                    </Typography>
                    <TextField size='small'
                        multiline
                        rows={4}
                        value={targetPt}
                        onChange={(e) => setTargetPt(e.target.value)} />
                </Grid>
            </Grid>

            {titleEn === '' ||
                titleEs === '' ||
                titlePt === '' ||
                targetEn === '' ||
                targetEs === '' ||
                targetPt === ''
                ?
                <Button
                    sx={{ m: 3 }}
                    disabled variant='contained'>
                    {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                </Button> :
                <Button
                    sx={{ m: 3 }}
                    onClick={handlePublish}
                    variant='contained'>
                    {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                </Button>}

            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleConfirm}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                        {t('MY_OFFERINGS.CHANGES_LOST')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            mt: 2,
                        }}
                    >
                        <Button variant="outlined" onClick={handleCloseConfirmation}>
                            {t('CO_ASSESSMENT.CANCEL')}
                        </Button>
                        <Button variant="contained" onClick={() => dispatch(goBack())}>
                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}

export default FundPurposeCreate