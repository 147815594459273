import React, { useCallback, useEffect } from 'react'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { GET_USER_INFO, UPDATE_PHOTO } from '../../Queries/Users'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import BusinessIcon from '@mui/icons-material/Business'
import InterestsIcon from '@mui/icons-material/Interests'
import SettingsIcon from '@mui/icons-material/Settings'
import Container from '@mui/material/Container'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import MenuItem from '@mui/material/MenuItem'
import AdjustIcon from '@mui/icons-material/Adjust'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { useLocation } from 'react-router-dom'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import TokenIcon from '@mui/icons-material/Token'
import TerminalIcon from '@mui/icons-material/Terminal'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import {
  Address,
  Value,
  TransactionUnspentOutput,
} from '@emurgo/cardano-serialization-lib-asmjs'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Badge,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Switch,
  Tooltip,
  useTheme,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import LogoWithLetters from '../../assets/icons/LogoWithLetters.png'
import LogoWithLettersWhite from '../../assets/icons/LogoWithLettersWhite.png'
import I18nMenu from '../../Layouts/DashboardLayout/I18nMenu'
import { login, logoutAction } from '../../store/actions/authActions'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Style from './Navbar.module.css'
import { useTranslation } from 'react-i18next'
import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from '../../Queries/Notifications'
import { GET_DVC_POLICY } from '../../Queries/Tokens'
import { useState } from 'react'
import moment from 'moment'
import { useRef } from 'react'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import DarkModeSwitch from './DarkModeSwitch'
import { ADD_ROLE_USER, REMOVE_ROLES_USER } from '../../Queries/Preferences'

const NavBar = () => {
  const location = useLocation()
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0()
  const picture = user?.picture
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState(
    document.visibilityState === 'visible'
  )

  const envApolloSandboxUrl = process.env.REACT_APP_GRAPHQL_URL_DEV
  const [apolloSandboxUrl, setApolloSandboxUrl] = useState('')

  useEffect(() => {
    if (envApolloSandboxUrl) {
      const encodedUrl = encodeURIComponent(envApolloSandboxUrl)
      const formattedUrl = `https://studio.apollographql.com/sandbox/explorer?endpoint=${encodedUrl}`
      setApolloSandboxUrl(formattedUrl)
    } else {
      console.log('envApolloSandboxUrl: not set')
    }
  }, [envApolloSandboxUrl])

  const marloweRunnerUrl = 'https://preprod.runner.marlowe-lang.org/'

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === 'visible')
    }

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () =>
      window.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  const [showWallets, setShowWallets] = useState(false)

  const [countRetry, setCountRetry] = useState(0)

  const incrementRetry = () => {
    setCountRetry(countRetry + 1)
  }

  const handleWalletSelect = async (obj) => {
    await createLocalStorageField(obj)
    await refreshData()
    await setShowWallets(!showWallets)
    await incrementRetry()
  }

  const handleConnectButtonClick = (selectedWallet) => {
    handleWalletSelect(selectedWallet)
  }

  const useClickAway = (handler) => {
    const ref = useRef(null)

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handler()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [handler])

    return ref
  }

  const paperRef = useClickAway(() => setShowWallets(false))

  const [getUserData, { data: userData, loading }] = useLazyQuery(
    GET_USER_INFO,
    {
      variables: { email: user?.email || user?.sub },
    }
  )

  const [updatePhoto] = useMutation(UPDATE_PHOTO)

  useEffect(() => {
    if (
      !loading &&
      !isLoading &&
      user?.picture !== userData?.users[0]?.profilePicture
    ) {
      updatePhoto({
        variables: {
          id: user.email || user?.sub,
          profilePicture: user.picture,
        },
      })
    }
  }, [user, userData])

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS)
  //! CHANGE ID!
  const { data: notificationData } = useQuery(GET_NOTIFICATIONS, {
    pollInterval: 10000,
    variables: {
      userID: user?.email || user?.sub,
    },
  })

  const showInvestorOrOffererView = localStorage.getItem(
    'showInvestorOrOffererView'
  )

  const [notificationsState, setNotificationsState] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)

  useEffect(() => {
    if (notificationData !== undefined && !loading) {
      setNotificationsState(notificationData?.userNotifications)
      const unreadExists = notificationData?.userNotifications.some(
        (notification) => notification.read == false
      )
      setUnreadNotifications(unreadExists)
    }
  }, [notificationData, loading, unreadNotifications])

  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const dispatch = useDispatch()

  const getAccessToken = useCallback(async () => {
    if (!isLoading && user !== undefined) {
      try {
        const token = await getAccessTokenSilently()
        dispatch(login(token, user))
      } catch (err) {
        dispatch(push('/'))
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect, dispatch, isLoading])

  useEffect(() => {
    if (!isLoading && user !== undefined) {
      getAccessToken()
      getUserData()
    }
  }, [getAccessToken, isLoading, user, loading, getUserData])

  const [isCoreTeam, setIsCoreTeam] = useState(false)
  const [show, setShow] = useState(false)
  const handleAdminDropdown = () => {
    setShow((prev) => !prev)
  }
  const handleClickAway = () => {
    setShow(false)
  }

  useEffect(() => {
    userData?.users[0]?.has.map((role) => {
      if (role.id === 'role-core-team') {
        setIsCoreTeam(true)
      }
    })
  })

  useEffect(() => {
    if (showInvestorOrOffererView == null) {
      if (
        userData?.users[0]?.has[0]?.roleName !== undefined &&
        userData?.users[0]?.has[0]?.roleName === null
      ) {
        localStorage.setItem(
          'showInvestorOrOffererView',
          userData?.users[0]?.has[0]?.roleName
        )
      }
    }
  }, [userData?.users[0]?.has[0]?.roleName])

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
    dispatch(logoutAction())
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  //FUNCTIONS FOR NOTIFICATION HANDLING
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openNotificationsDesk, setOpenNotificationsDesk] = useState(false)
  // eslint-disable-next-line
  const handleOpenNotificationsDesk = async () => {
    const notificationsIds = []
    setOpenNotificationsDesk(openNotificationsDesk ? false : true)
    notificationsState.forEach((notification) => {
      if (notification.read == false) {
        notificationsIds.push(notification.id)
      }
    })
    // eslint-disable-next-line
    const response = await updateNotifications({
      variables: {
        ids: notificationsIds,
      },
    })
    setUnreadNotifications(false)
  }
  const handleOpenNotifications = async () => {
    const notificationsIds = []
    setOpenNotifications(openNotifications ? false : true)
    notificationsState.forEach((notification) => {
      if (notification.read == false) {
        notificationsIds.push(notification.id)
      }
    })
    // eslint-disable-next-line
    const response = await updateNotifications({
      variables: {
        ids: notificationsIds,
      },
    })
    setUnreadNotifications(false)
  }

  const [walletEnabled, setWalletEnabled] = useState(null)
  // eslint-disable-next-line
  const [hasInvestorToken, setHasInvestorToken] = useState(false)

  const [wallets, setWallets] = useState([])

  const pollWallets = (count = 0) => {
    const IntWallets = []
    for (const key in window.cardano) {
      if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
        IntWallets.push(key)
      }
    }
    if (IntWallets.length === 0 && count < 3) {
      setTimeout(() => {
        pollWallets(count + 1)
      }, 1000)
      return
    }
    setWallets(IntWallets)
  }

  // eslint-disable-next-line
  const [Balance, setBalance] = useState(undefined)
  const [API, setAPI] = useState(null)
  const [changeAddress, setChangeAddress] = useState(undefined)
  // eslint-disable-next-line
  const [Utxos, setUtxos] = useState(undefined)
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [errorWallet, setErrorWallet] = useState(null)

  function createLocalStorageField(value) {
    localStorage.setItem('wallet', JSON.stringify(value))
  }

  function getLocalStorageField(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  const [localStorageWallet, setLocalStorageWallet] = useState(
    getLocalStorageField('wallet')
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalStorageWallet(getLocalStorageField('wallet'))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  function deleteLocalStorageField() {
    localStorage.removeItem('wallet')
  }

  useEffect(() => {
    if (localStorageWallet !== null) {
      setSelectedWallet(localStorageWallet)
    } else if (localStorageWallet === null) {
      setSelectedWallet(null)
    }
  }, [localStorageWallet])

  useEffect(() => {
    pollWallets()
  }, [])

  const getUtxos = async () => {
    let utxos = []
    if (API !== null) {
      try {
        const rawUtxos = await API?.getUtxos()

        for (const rawUtxo of rawUtxos) {
          const utxo = TransactionUnspentOutput.from_bytes(
            Buffer.from(rawUtxo, 'hex')
          )
          const input = utxo.input()
          const txid = Buffer.from(
            input.transaction_id().to_bytes(),
            'utf8'
          ).toString('hex')
          const txindx = input.index()
          const output = utxo.output()
          const amount = output.amount().coin().to_str()
          const multiasset = output.amount().multiasset()
          let multiAssetStr = ''

          if (multiasset) {
            const keys = multiasset.keys()
            const N = keys.len()

            for (let i = 0; i < N; i++) {
              const policyId = keys.get(i)
              const policyIdHex = Buffer.from(
                policyId.to_bytes(),
                'utf8'
              ).toString('hex')

              const assets = multiasset.get(policyId)
              const assetNames = assets.keys()
              const K = assetNames.len()

              for (let j = 0; j < K; j++) {
                const assetName = assetNames.get(j)
                const assetNameString = Buffer.from(
                  assetName.name(),
                  'utf8'
                ).toString()
                const assetNameHex = Buffer.from(
                  assetName.name(),
                  'utf8'
                ).toString('hex')
                const multiassetAmt = multiasset.get_asset(policyId, assetName)
                multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
              }
            }
          }

          const obj = {
            txid: txid,
            txindx: txindx,
            amount: amount,
            str: `${txid} #${txindx} = ${amount}`,
            multiAssetStr: multiAssetStr,
            TransactionUnspentOutput: utxo,
          }
          utxos.push(obj)
        }
        setUtxos(utxos)
      } catch (err) {
        setSelectedWallet(null)
        //Err
      }
    }
  }

  const getChangeAddress = async () => {
    if (API !== null) {
      try {
        const raw = await API.getChangeAddress()
        const changeAddressObj = Address.from_bytes(
          Buffer.from(raw, 'hex')
        ).to_bech32()
        setChangeAddress(changeAddressObj)
      } catch (err) {
        setSelectedWallet(null)
      }
    }
  }

  const [netId, setNetId] = useState(undefined)
  const appropiateNetworkIdIdentified = 0
  const [isCorrectNetworkSelected, setIsCorrectNetworkSelected] = useState(null)

  useEffect(() => {
    if (netId !== undefined) {
      if (appropiateNetworkIdIdentified === netId) {
        setIsCorrectNetworkSelected(true)
      } else {
        setIsCorrectNetworkSelected(false)
      }
    }
  }, [appropiateNetworkIdIdentified, netId])

  const getNetId = async () => {
    if (API !== null) {
      try {
        const id = await API.getNetworkId()
        setNetId(id)
      } catch (err) {
        setNetId(undefined)
      }
    }
  }

  const getBalance = async () => {
    if (API !== null) {
      try {
        const balanceCBORHex = await API.getBalance()
        const balance = Value.from_bytes(Buffer.from(balanceCBORHex, 'hex'))
          .coin()
          .to_str()
        setBalance(balance)
      } catch (err) {
        setSelectedWallet(null)
        //Err
      }
    }
  }

  const enableWallet = async () => {
    const walletKey = selectedWallet
    try {
      setAPI(await window.cardano[walletKey].enable())
      setErrorWallet(null)
    } catch (err) {
      setSelectedWallet(null)
      setErrorWallet(true)
      //Err
    }
    return checkIfWalletEnabled()
  }

  const checkIfWalletEnabled = async () => {
    let walletIsEnabled = false
    try {
      const walletName = localStorageWallet
      walletIsEnabled = await window.cardano[walletName].isEnabled()
      setWalletEnabled(walletIsEnabled)
    } catch (err) {
      //Err
      setSelectedWallet(undefined)
    }
    return walletIsEnabled
  }

  async function checkEnabledStatus(walletNames) {
    let anyEnabled = false
    // eslint-disable-next-line
    const enabledStatuses = await Promise.all(
      walletNames
        .filter((walletName) => walletName && typeof walletName === 'string')
        .map(async (walletName) => {
          try {
            const isEnabled = await window.cardano?.[walletName]?.isEnabled()
            if (isEnabled) {
              anyEnabled = true
              setSelectedWallet(walletName)
            }
          } catch (error) {
            console.error(`Error checking status for ${walletName}:`, error)
            return { walletName, isEnabled: false }
          }
        })
    )

    setWalletEnabled(anyEnabled)
  }

  const refreshData = async () => {
    try {
      if (walletEnabled && API !== null) {
        await getBalance()
        await getNetId()
        await getChangeAddress()
        await getUtxos()
      }
    } catch (err) {
      setSelectedWallet(null)
      deleteLocalStorageField()
      //Err
    }
  }

  useEffect(() => {
    refreshData()
    const intervalId = setInterval(() => {
      if ((changeAddress === undefined || API !== null) && walletEnabled) {
        refreshData()
      }
    }, 5000)

    return () => clearInterval(intervalId)
  }, [changeAddress, API, walletEnabled])

  useEffect(() => {
    if (selectedWallet !== null) {
      enableWallet()
    }
  }, [selectedWallet, localStorageWallet])

  useEffect(() => {
    if (localStorageWallet !== null && isVisible === true) {
      checkEnabledStatus(wallets)
      const intervalId = setInterval(() => {
        checkEnabledStatus(wallets)
      }, 5000)

      return () => clearInterval(intervalId)
    }
  }, [wallets, localStorageWallet, isVisible])

  const [getDVCData, { data: DVCdata }] = useLazyQuery(GET_DVC_POLICY)

  useEffect(() => {
    getDVCData()
  }, [])

  const investorTokenPolicy = DVCdata?.tokens[0]?.policyId

  function findAmountByInvestorTokenPolicy(data, investorTokenPolicy) {
    if (
      !Array.isArray(data) ||
      typeof investorTokenPolicy !== 'string' ||
      !investorTokenPolicy.trim()
    ) {
      return null
    }

    let totalAmount = 0

    for (const item of data) {
      if (
        item.multiAssetStr &&
        item.multiAssetStr.includes(investorTokenPolicy)
      ) {
        const amountMatch = item.multiAssetStr.match(/\+ (\d+) \+.*\s\((.*?)\)/)
        if (amountMatch) {
          const amount = parseInt(amountMatch[1])
          totalAmount += amount
        }
      }
    }

    return totalAmount !== 0 ? totalAmount : 0
  }

  const DVCamount = findAmountByInvestorTokenPolicy(Utxos, investorTokenPolicy)

  useEffect(() => {
    if (DVCamount >= 1 && DVCamount !== null) {
      setHasInvestorToken(true)
    } else if (DVCamount < 1 && DVCamount !== null) {
      setHasInvestorToken(false)
    }
  }, [DVCamount])

  const [roleOnDB, setRoleOnDB] = useState(undefined)

  useEffect(() => {
    userData?.users[0]?.has?.map((role) => {
      if (role.id === 'role-applicant') setRoleOnDB('role-applicant')
      if (role.id === 'role-investor') setRoleOnDB('role-investor')
    })
  }, [userData])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isVisible && errorWallet) {
        deleteLocalStorageField()
      }
    }, 5000)
    return () => clearTimeout(timer)
  }, [isVisible, errorWallet])

  useEffect(() => {
    if (changeAddress !== undefined) {
      setCountRetry(0)
    }
  }, [changeAddress])

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  const [changeRoleModal, setChangeRoleModal] = useState(false)

  const handleChangeRoleOpen = () => {
    setChangeRoleModal(true)
  }

  const handleChangeRoleClose = () => {
    setChangeRoleModal(false)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
  const [updateUserRole] = useMutation(ADD_ROLE_USER)
  const [removeRolesUser] = useMutation(REMOVE_ROLES_USER)
  const [savingProfile, setSavingProfile] = useState(false)

  const handleSaveProfileAsApplicant = async () => {
    try {
      await setSavingProfile(true)
      await removeRolesUser({
        variables: {
          userId: userData?.users[0]?.id,
        },
      })
      await updateUserRole({
        variables: {
          userId: userData?.users[0]?.id,
          roleId: 'role-applicant',
        },
      })
      await localStorage.setItem('role', 'role-applicant')
      await localStorage.setItem('showInvestorOrOffererView', 'Offerer')
      await setSavingProfile(false)
      await window.location.reload()
    } catch (err) {
      //Err
    }
  }

  const handleSaveProfileAsInvestor = async () => {
    try {
      await setSavingProfile(true)
      await removeRolesUser({
        variables: {
          userId: userData?.users[0]?.id,
        },
      })
      await updateUserRole({
        variables: {
          userId: userData?.users[0]?.id,
          roleId: 'role-investor',
        },
      })
      await localStorage.setItem('role', 'role-investor')
      await localStorage.setItem('showInvestorOrOffererView', 'Investor')
      await setSavingProfile(false)
      await window.location.reload()
    } catch (err) {
      //Err
    }
  }

  const [page, setPage] = useState(1)

  const handleBuyClick = () => {
    const url = 'https://tokenallies.com/#DVC'
    window.open(url, '_blank')
  }

  const MaterialUISwitchLR = styled(Switch)(({ theme }) => ({
    width: 62 * 0.75,
    height: 34 * 0.75,
    padding: 7 * 0.75,
    '& .MuiSwitch-switchBase': {
      margin: 1 * 0.75,
      padding: 0,
      transform: 'translateX(6px * 0.75)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px * 0.75)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            '#fff'
          )}" d="M12 20l-1.41-1.41L16.17 13H4v-2h12.17l-5.58-5.59L12 4l8 8z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#e6a3bd' : '#e6a3bd',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#9A1D4C',
      width: 32 * 0.75,
      height: 32 * 0.75,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M12 4l1.41 1.41L7.83 11H20v2H7.83l5.58 5.59L12 20l-8-8z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#e6a3bd' : '#e6a3bd',
      borderRadius: (20 / 2) * 0.75,
    },
  }))

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: actualThemeLight ? '#EFEFEF' : '#1D1D1D',
        padding: '12px 0px',
      }}
      elevation={0}
    >
      <Container
        sx={{
          width: '95%',
          margin: 'auto',
          maxWidth: 'none !important',
          padding: '0px !important',
        }}
      >
        <Toolbar
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          disableGutters
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              mr: 2,
            }}
            onClick={() => dispatch(push('/'))}
          >
            <img
              src={actualThemeLight ? LogoWithLetters : LogoWithLettersWhite}
              width={'150px'}
              height={'25px'}
            />
            <Typography
              fontSize={14}
              color={actualThemeLight ? 'black' : 'white'}
            >
              Investment Platform
            </Typography>
          </Box>

          {isAuthenticated ? (
            <Container
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-start',
              }}
            >
              <Box sx={{ mr: { lg: '37px', md: '15px', sm: '10px' } }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/dashboard'))
                  }}
                  className={
                    location.pathname !== '/dashboard'
                      ? Style.titlesNavLight
                      : Style.titlesNavBold
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.DASHBOARD')}
                </Typography>
              </Box>
              <Box sx={{ mr: { lg: '37px', md: '15px', sm: '10px' } }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/funds'))
                  }}
                  className={
                    location.pathname !== '/funds'
                      ? Style.titlesNavLight
                      : Style.titlesNavBold
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.FUNDS')}
                </Typography>
              </Box>
              <Box sx={{ mr: { lg: '37px', md: '15px', sm: '10px' } }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/companies'))
                  }}
                  className={
                    location.pathname === '/companies' ||
                    location.pathname.includes('/company-detail')
                      ? Style.titlesNavBold
                      : Style.titlesNavLight
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.COMPANIES')}
                </Typography>
              </Box>
              <Box sx={{ mr: { lg: '37px', md: '15px', sm: '10px' } }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/wallet'))
                  }}
                  className={
                    location.pathname !== '/wallet'
                      ? Style.titlesNavLight
                      : Style.titlesNavBold
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.MY_WALLET')}
                </Typography>
              </Box>
              <Box sx={{ mr: { lg: '37px', md: '15px', sm: '10px' } }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/dao/calendar'))
                  }}
                  className={
                    location.pathname === '/dao/calendar' ||
                    location.pathname === '/dao/news'
                      ? Style.titlesNavBold
                      : Style.titlesNavLight
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.DAO')}
                </Typography>
              </Box>
              <Box sx={{ mr: { lg: '37px', md: '15px', sm: '10px' } }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/faq'))
                  }}
                  className={
                    location.pathname === '/faq'
                      ? Style.titlesNavBold
                      : Style.titlesNavLight
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                    cursor: 'pointer !important',
                  }}
                >
                  FAQ
                </Typography>
              </Box>
              {isCoreTeam && (
                <Box sx={{ mt: -0.5 }}>
                  <Button
                    variant="contained"
                    sx={{
                      whiteSpace: 'nowrap',
                      pt: 0.4,
                      pb: 0.4,
                      pl: 1,
                      pr: 1,
                    }}
                    onClick={handleAdminDropdown}
                  >
                    {t('ADMIN.ADMIN_TOOLS')}
                  </Button>
                  {show && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Paper
                        elevation={5}
                        sx={{ position: 'absolute', zIndex: 100 }}
                      >
                        <List component="div" disablePadding>
                          {[
                            {
                              path: '/admin-fund-management',
                              icon: <AccountTreeIcon />,
                              text: 'Fund Management',
                            },
                            {
                              path: '/admin-fund-purpose',
                              icon: <AdjustIcon />,
                              text: 'Fund Purpose',
                            },
                            {
                              path: '/admin-companies',
                              icon: <BusinessIcon />,
                              text: t('ADMIN.COMPANY_ADMINISTRATION'),
                            },
                            {
                              path: '/admin-users',
                              icon: <PersonIcon />,
                              text: t('ADMIN.USER_ADMINISTRATION'),
                            },
                          ].map((item) => (
                            <ListItemButton
                              key={item.path}
                              onClick={() => {
                                dispatch(push(item.path)), handleAdminDropdown()
                              }}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItemButton>
                          ))}
                        </List>
                        <Divider />
                        <List component="div" disablePadding>
                          {[
                            {
                              path: '/admin-vesting-event',
                              icon: <CurrencyExchangeIcon />,
                              text: t('ADMIN.VESTING_EVENTS'),
                            },
                            {
                              path: '/admin-interests',
                              icon: <InterestsIcon />,
                              text: t('ADMIN.INTERESTS_ADMINISTRATION'),
                            },
                            {
                              path: '/admin-settings',
                              icon: <SettingsIcon />,
                              text: t('ADMIN.SETTINGS_ADMINISTRATION'),
                            },
                          ].map((item) => (
                            <ListItemButton
                              key={item.path}
                              onClick={() => {
                                dispatch(push(item.path)), handleAdminDropdown()
                              }}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItemButton>
                          ))}
                        </List>
                        <Divider />
                        <List component="div" disablePadding>
                          {[
                            {
                              path: '/admin-dao-news',
                              icon: <NewspaperIcon />,
                              text: t('ADMIN.DAO_NEWS'),
                            },
                            {
                              path: '/admin-calendar',
                              icon: <CalendarMonthIcon />,
                              text: t('ADMIN.CALENDAR'),
                            },
                            {
                              path: '/admin-tokens',
                              icon: <TokenIcon />,
                              text: 'DAO Tokens/NFTs',
                            },
                          ].map((item) => (
                            <ListItemButton
                              key={item.path}
                              onClick={() => {
                                dispatch(push(item.path)), handleAdminDropdown()
                              }}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItemButton>
                          ))}
                        </List>
                        <Divider />
                        <List component="div" disablePadding>
                          {[
                            {
                              path: '/admin-transactionlog',
                              icon: <AccountBalanceIcon />,
                              text: t('ADMIN.TRANSACTION_LOG'),
                            },
                            {
                              path: '/admin-errorlog',
                              icon: <ReportGmailerrorredIcon />,
                              text: t('ADMIN.ERROR_LOG'),
                            },
                            {
                              icon: <TerminalIcon />,
                              text: 'Apollo Sandbox',
                              isExternal: true,
                              externalUrl: apolloSandboxUrl,
                            },
                            {
                              icon: <AccountBalanceWalletIcon />,
                              text: 'Marlowe Runner',
                              isExternal: true,
                              externalUrl: marloweRunnerUrl,
                            },
                          ].map((item) => (
                            <ListItemButton
                              key={item.path}
                              onClick={() => {
                                if (item.isExternal) {
                                  window.open(
                                    item.externalUrl,
                                    '_blank',
                                    'noopener,noreferrer'
                                  )
                                } else {
                                  dispatch(push(item.path))
                                  handleAdminDropdown()
                                }
                              }}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItemButton>
                          ))}
                        </List>
                      </Paper>
                    </ClickAwayListener>
                  )}
                </Box>
              )}
            </Container>
          ) : null}

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ flexGrow: 0 }}>
              {!isAuthenticated ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                  }}
                >
                  <Button
                    sx={{
                      width: '7rem',
                      border: '1px solid pink',
                      justifySelf: 'flex-end',
                    }}
                    color="lightGrey"
                    onClick={() => loginWithRedirect()}
                  >
                    {t('NAVBAR.LOG-IN')}
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 1,
                    }}
                  >
                    {countRetry >= 2 && (
                      <Box>
                        <Tooltip
                          title={t(
                            'INVERSOR_VIEW.OFFERING_CARD.WALLET_ERROR_DAPP'
                          )}
                        >
                          <IconButton>
                            <PriorityHighIcon sx={{ color: 'orange' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    {walletEnabled && localStorageWallet !== null ? (
                      <>
                        <Chip
                          fullWidth
                          size="small"
                          variant="outlined"
                          label={t(
                            'INVERSOR_VIEW.OFFERING_CARD.ERROR_DISC_WALLET_ACT'
                          )}
                          sx={{
                            color: 'red',
                            borderColor: 'red',
                            whiteSpace: 'nowrap',
                            pt: 0.4,
                            pb: 0.4,
                            pl: 1,
                            pr: 1,
                          }}
                          onClick={() => {
                            deleteLocalStorageField()
                          }}
                        >
                          {t(
                            'INVERSOR_VIEW.OFFERING_CARD.ERROR_DISC_WALLET_ACT'
                          )}
                        </Chip>
                        <Tooltip
                          title={
                            t('WALLET.CONNECTED') + ' ' + localStorageWallet
                          }
                        >
                          <IconButton
                            onClick={() => {
                              dispatch(push('/wallet'))
                            }}
                            id="navbar-notifications-btn"
                          >
                            <CheckCircleIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Box>
                          <Tooltip
                            title={
                              <div>
                                {t('FUND.INVEST_BUTTON_HELPER')}
                                <br />
                                <br />
                                {t('WALLET.SUPPORTED_WALLET')}
                              </div>
                            }
                          >
                            <Chip
                              fullWidth
                              size="small"
                              variant="contained"
                              color="success"
                              label={t(
                                'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                              )}
                              sx={{
                                whiteSpace: 'nowrap',
                                pt: 0.4,
                                pb: 0.4,
                                pl: 1,
                                pr: 1,
                              }}
                              onClick={() => {
                                setShowWallets(!showWallets)
                              }}
                            >
                              {t(
                                'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                              )}
                            </Chip>
                          </Tooltip>
                          {showWallets && (
                            <>
                              {wallets.length === 0 ? (
                                <Paper
                                  className={Style.notificationModal}
                                  ref={paperRef}
                                  sx={{
                                    padding: '1rem',

                                    mr: '5%',
                                    mt: 1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography
                                      textAlign="center"
                                      sx={{
                                        color: '#9A1D4C',
                                      }}
                                    >
                                      {t('WALLET_VIEW.NO_WALLET_HELPER1')}
                                    </Typography>
                                    <Typography
                                      textAlign="center"
                                      sx={{
                                        color: '#9A1D4C',
                                      }}
                                    >
                                      {t('WALLET_VIEW.NO_WALLET_HELPER2')}
                                    </Typography>
                                  </Box>
                                </Paper>
                              ) : (
                                <Paper
                                  className={Style.notificationModal}
                                  ref={paperRef}
                                  sx={{
                                    padding: '5px',
                                    width: '18%',
                                    mr: '11%',
                                    mt: 1,
                                  }}
                                >
                                  {wallets?.map((key) => (
                                    <Box
                                      key={key}
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        m: 1,
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          mt: 1,
                                          color: actualThemeLight
                                            ? null
                                            : '#e6a3bd',
                                          borderColor: actualThemeLight
                                            ? null
                                            : '#e6a3bd',
                                        }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() =>
                                          handleConnectButtonClick(key)
                                        }
                                      >
                                        <Typography sx={{ ml: 1 }}>
                                          {
                                            t(
                                              'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                                            ).split(' ')[0]
                                          }
                                        </Typography>
                                        <Avatar
                                          sx={{ ml: 1 }}
                                          src={window.cardano[key].icon}
                                          width={24}
                                          height={24}
                                          alt={key}
                                        />
                                        <Typography sx={{ ml: 1 }}>
                                          {key}
                                        </Typography>
                                      </Button>
                                    </Box>
                                  ))}
                                </Paper>
                              )}
                            </>
                          )}
                        </Box>
                        <Tooltip
                          title={t(
                            'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET'
                          )}
                        >
                          <IconButton
                            onClick={() => {
                              dispatch(push('/wallet'))
                            }}
                            id="navbar-notifications-btn"
                          >
                            <HighlightOffIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {isCorrectNetworkSelected === true ? (
                      <Box
                        sx={{
                          backgroundColor: 'green',
                          pl: 0.5,
                          pr: 0.5,
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        <Tooltip title={t('WALLET_VIEW.RIGHT_NET')}>
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 'bold' }}
                          >
                            Preprod
                          </Typography>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: 'red',
                          pl: 0.5,
                          pr: 0.5,
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        <Tooltip title={t('WALLET_VIEW.WRONG_NET')}>
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 'bold' }}
                          >
                            Mainnet
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: '80px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ClickAwayListener
                      onClickAway={() => setOpenNotifications(false)}
                    >
                      <Box>
                        <Tooltip title={t('NAVBAR.NOTIFICATIONS')}>
                          <IconButton
                            id="navbar-notifications-btn"
                            onClick={() => handleOpenNotifications()}
                          >
                            {unreadNotifications ? (
                              <Badge
                                color="error"
                                aria-label={`new notifications`}
                                badgeContent={1}
                                overlap="circular"
                                max={99}
                                variant="dot"
                              >
                                <NotificationsNoneIcon
                                  sx={{ fontSize: '24px' }}
                                  style={{
                                    color: actualThemeLight
                                      ? '#9A1D4C'
                                      : '#e6a3bd',
                                  }}
                                />
                              </Badge>
                            ) : (
                              <NotificationsNoneIcon
                                style={{
                                  color: actualThemeLight
                                    ? '#9A1D4C'
                                    : '#e6a3bd',
                                }}
                                sx={{ fontSize: '24px', color: 'pink' }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        {openNotifications && (
                          <Paper
                            className={Style.notificationModal}
                            sx={{
                              padding: '1rem',
                              width: '20%',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                              }}
                            >
                              {t('NAVBAR.NOTIFICATIONS')}
                            </Typography>
                            {notificationsState?.length > 0 ? (
                              notificationsState.map((notification, index) => {
                                const dateToShow = moment(
                                  notification.createdOn.toLocaleString()
                                )
                                  .locale('en')
                                  .fromNow()
                                const notif = notification.message.split('-')
                                const notifReason =
                                  notification.message.split('*')[1]
                                let notifMSG = notif[0].trim()
                                let notifFinalMSG
                                switch (notifMSG) {
                                  case 'MSG':
                                    notifFinalMSG = t('NOTIFICATIONS.MESSAGE')
                                    break
                                  case 'RDYDEPOSIT':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.RDYDEPOSIT'
                                    )
                                    break
                                  case 'TARGETMET':
                                    notifFinalMSG = t('NOTIFICATIONS.TARGETMET')
                                    break
                                  case 'COLLOVER':
                                    notifFinalMSG = t('NOTIFICATIONS.COLLOVER')
                                    break
                                  case 'RDYWITHDRAW':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.RDYWITHDRAW'
                                    )
                                    break
                                  case 'PROP':
                                    notifFinalMSG = t('NOTIFICATIONS.PROPOSAL')
                                    break
                                  case 'ACCPROP':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.PROP_ACCEPTED'
                                    )
                                    break
                                  case 'OFFER':
                                    notifFinalMSG = t('NOTIFICATIONS.OFFER')
                                    break
                                  case 'COUNTEROFFER':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.COUNTEROFFER'
                                    )
                                    break
                                  case 'COUNTER_ACC':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.COUNTER_ACC'
                                    )
                                    break
                                  case 'COUNTER_REJ':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.COUNTER_REJ'
                                    )
                                    break
                                  case 'REJECTION':
                                    notifFinalMSG = t('NOTIFICATIONS.REJECTION')
                                    break
                                  case 'ACCPUROFF':
                                    notifFinalMSG = t('NOTIFICATIONS.ACCPUROFF')
                                    break
                                  case 'PAYED':
                                    notifFinalMSG = t('NOTIFICATIONS.PAYED')
                                    break
                                  case 'INVCANCEL':
                                    notifFinalMSG = t('NOTIFICATIONS.INVCANCEL')
                                    break
                                  case 'INVTIMEOUT':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.INVTIMEOUT'
                                    )
                                    break
                                  case 'DEPNOTCONFIRMED':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.DEPNOTCONFIRMED'
                                    )
                                    break
                                  case 'CONGRATS':
                                    notifFinalMSG = t('NOTIFICATIONS.CONGRATS')
                                    break
                                  case 'COCREATED':
                                    notifFinalMSG = t('NOTIFICATIONS.NEW_CO')
                                    break
                                  case 'OPCREATED':
                                    notifFinalMSG = t('NOTIFICATIONS.NEW_OP')
                                    break
                                  case 'INFOMISSING':
                                    notifFinalMSG = t(
                                      'NOTIFICATIONS.INFOMISSING'
                                    )
                                    break
                                  case 'CLOSEEXE':
                                    notifFinalMSG = t('NOTIFICATIONS.CLOSEEXE')
                                    break
                                  case 'PAYOUTEXE':
                                    notifFinalMSG = t('NOTIFICATIONS.PAYOUTEXE')
                                    break
                                }

                                return (
                                  <Box
                                    sx={
                                      notification?.read == false
                                        ? {
                                            color: 'white',
                                            backgroundColor:
                                              'var(--primaryRed)  !important',
                                          }
                                        : {}
                                    }
                                    className={
                                      actualThemeLight
                                        ? Style.eachNotif
                                        : Style.eachNotifDark
                                    }
                                    key={index}
                                    onClick={() => {
                                      dispatch(
                                        push(
                                          `${notification.link}/${
                                            notification.message.split('-')[1]
                                          }`
                                        )
                                      )
                                      handleOpenNotifications()
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 'bold',
                                        width: '100%',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {notif[1]?.split('*')[0]}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 500,
                                        width: '100%',
                                        fontSize: '14px',
                                        mb: 2,
                                      }}
                                    >
                                      {notifFinalMSG}{' '}
                                      {notifReason ? `- ${notifReason}` : null}
                                    </Typography>
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        right: '5px',
                                        bottom: '0',
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          fontWeight: 400,
                                          fontSize: '12px',
                                          color: 'var(--primaryGray)',
                                        }}
                                      >
                                        {dateToShow}
                                      </Typography>
                                    </Box>
                                  </Box>
                                )
                              })
                            ) : (
                              <Typography
                                variant="body1"
                                sx={{ color: 'var(--primaryBlue)' }}
                              >
                                No new notifications
                              </Typography>
                            )}
                          </Paper>
                        )}
                      </Box>
                    </ClickAwayListener>
                    <Tooltip title={t('NAVBAR.MESSAGES')}>
                      <IconButton
                        onClick={() => {
                          dispatch(push('/messages/messages'))
                        }}
                      >
                        <EmailOutlinedIcon
                          style={{
                            color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                          }}
                          sx={{
                            fontSize: '24px',
                            cursor: 'pointer !important',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu}>
                      <AccountCircleOutlinedIcon
                        style={{
                          fontSize: '24px',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Box sx={{ width: '300px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '5%',
                          justifyContent: 'start',
                          maxWidth: '300px',
                          wordBreak: 'break-word',
                        }}
                      >
                        <Avatar alt={user?.email} src={picture} />
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                              fontSize: '15px',
                              fontFamily: 'Quicksand, sans-serif',
                              fontStyle: 'normal',
                              textAlign: 'start',
                              width: '100%',
                            }}
                          >
                            {user?.name}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  mr: 0.5,
                                }}
                              >
                                {t('PERFIL_CONFIG_VIEW.ROLE_OFFERER')}
                              </Typography>
                              <MaterialUISwitchLR
                                onChange={handleChangeRoleOpen}
                                checked={roleOnDB === 'role-investor'}
                              />
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  ml: 0.5,
                                }}
                              >
                                {t('PERFIL_CONFIG_VIEW.ROLE_INVESTOR')}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <DarkModeSwitch />
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box sx={{ margin: '5%' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                            fontSize: '15px',
                            fontFamily: 'Quicksand, sans-serif',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                          }}
                        >
                          {t('NAVBAR.ACCOUNT')}
                        </Typography>
                        <MenuItem
                          sx={{ marginTop: 0.5 }}
                          onClick={() => {
                            handleCloseUserMenu()
                            dispatch(push('/profile-settings'))
                          }}
                        >
                          <ListItemIcon>
                            <SettingsIcon
                              fontSize="small"
                              sx={{
                                color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                              }}
                            />
                          </ListItemIcon>
                          <Typography
                            textAlign="center"
                            sx={{
                              fontWeight: 'normal',
                              color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                              fontSize: '14px',
                              fontFamily: 'Quicksand, sans-serif',
                              fontStyle: 'normal',
                              lineHeight: '20px',
                            }}
                          >
                            {t('NAVBAR.EDIT_PROFILE')}
                          </Typography>
                        </MenuItem>
                      </Box>
                      <Divider />
                      <Box sx={{ margin: '5%' }}>
                        <MenuItem key={1223123} onClick={() => handleLogout()}>
                          <ListItemIcon>
                            <LogoutIcon
                              fontSize="small"
                              sx={{ color: '#F81414' }}
                            />{' '}
                          </ListItemIcon>
                          <Typography
                            textAlign="center"
                            sx={{
                              fontWeight: 'normal',
                              color: '#F81414',
                              fontSize: '14px',
                              fontFamily: 'Quicksand, sans-serif',
                              fontStyle: 'normal',
                              lineHeight: '20px',
                            }}
                          >
                            {t('NAVBAR.LOG_OUT')}
                          </Typography>
                        </MenuItem>
                      </Box>
                    </Box>
                  </Menu>
                </Box>
              )}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <I18nMenu />
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Modal
        open={changeRoleModal}
        onClose={handleChangeRoleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'right',
              position: 'absolute',
              mt: -1,
            }}
          >
            <IconButton>
              <CloseIcon onClick={handleChangeRoleClose} />
            </IconButton>
          </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('MY_OFFERINGS.ARE_YOU_SURE')}
          </Typography>
          {roleOnDB === 'role-applicant' &&
          (!hasInvestorToken ||
            !walletEnabled ||
            localStorageWallet === null) ? (
            <Box sx={style}>
              <Box
                sx={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'right',
                  position: 'absolute',
                  mt: -1,
                }}
              >
                <IconButton>
                  <CloseIcon onClick={handleChangeRoleClose} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{ mt: 2, fontSize: '16px', fontWeight: 'bold' }}
                >
                  {t('PERFIL_CONFIG_VIEW.REQUIREMENTS')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
                {page === 1 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      m: 0.5,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '50%',
                      }}
                    >
                      <Typography sx={{ mr: 2 }} id="modal-modal-description">
                        {t('PERFIL_CONFIG_VIEW.REQ_WALLET')}
                      </Typography>
                    </Box>
                    {walletEnabled && localStorageWallet !== null ? (
                      <CheckCircleIcon
                        fontSize="large"
                        sx={{ mt: 1 }}
                        color="success"
                      />
                    ) : (
                      <HighlightOffIcon
                        fontSize="large"
                        sx={{ mt: 1 }}
                        color="error"
                      />
                    )}
                    {localStorageWallet === null && (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            mt: 1,
                          }}
                        >
                          <Tooltip title={t('WALLET.SUPPORTED_WALLET')}>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setShowWallets(!showWallets)
                              }}
                            >
                              {t(
                                'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                              )}
                            </Button>
                          </Tooltip>
                          {countRetry >= 2 && (
                            <Box>
                              <Tooltip
                                title={t(
                                  'INVERSOR_VIEW.OFFERING_CARD.WALLET_ERROR_DAPP'
                                )}
                              >
                                <IconButton>
                                  <PriorityHighIcon sx={{ color: 'orange' }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                )}
                {page === 2 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      m: 0.5,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '50%',
                      }}
                    >
                      <Typography id="modal-modal-description" sx={{ mr: 2 }}>
                        {t('PERFIL_CONFIG_VIEW.REQ_TOKEN')}
                      </Typography>
                    </Box>
                    {hasInvestorToken && localStorageWallet !== null && (
                      <CheckCircleIcon
                        fontSize="large"
                        sx={{ mt: 1 }}
                        color="success"
                      />
                    )}
                    {!hasInvestorToken || localStorageWallet === null ? (
                      <Button
                        onClick={handleBuyClick}
                        sx={{ mt: 1 }}
                        variant="contained"
                      >
                        {t('PERFIL_CONFIG_VIEW.BUY_HERE')}
                      </Button>
                    ) : null}
                  </Box>
                )}
              </Box>
              {page === 1 && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 3,
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                      color: actualThemeLight ? 'white' : 'black',
                      width: 24,
                      height: 24,
                      mr: 1,
                      ml: 1,
                      fontSize: '14px',
                    }}
                  >
                    1
                  </Avatar>
                  <Avatar
                    sx={{
                      bgcolor: 'gray',
                      color: actualThemeLight ? 'white' : 'black',
                      width: 24,
                      height: 24,
                      mr: 1,
                      fontSize: '14px',
                    }}
                  >
                    2
                  </Avatar>
                  <NavigateNextIcon
                    fontSize="large"
                    onClick={() => {
                      setPage(2)
                    }}
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      mr: -30,
                      mt: 0.2,
                    }}
                  />
                </Box>
              )}
              {page === 2 && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 3,
                  }}
                >
                  <NavigateBeforeIcon
                    fontSize="large"
                    onClick={() => {
                      setPage(1)
                    }}
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      mr: 30,
                      mt: 0.2,
                    }}
                  />
                  <Avatar
                    sx={{
                      bgcolor: 'gray',
                      color: actualThemeLight ? 'white' : 'black',
                      width: 24,
                      height: 24,
                      mr: 1,
                      ml: 1,
                      fontSize: '14px',
                    }}
                  >
                    1
                  </Avatar>
                  <Avatar
                    sx={{
                      bgcolor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                      color: actualThemeLight ? 'white' : 'black',
                      width: 24,
                      height: 24,
                      mr: 1,
                      fontSize: '14px',
                    }}
                  >
                    2
                  </Avatar>
                </Box>
              )}
            </Box>
          ) : null}
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {roleOnDB === 'role-investor' && (
              <Typography>
                {t('PERFIL_CONFIG_VIEW.SET_PROF_APPLICANT')}
              </Typography>
            )}
            {roleOnDB === 'role-applicant' && (
              <Typography>
                {t('PERFIL_CONFIG_VIEW.SET_PROF_INVESTOR')}
              </Typography>
            )}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {t('PERFIL_CONFIG_VIEW.AFTER_SAVE_RELOAD')}
          </Typography>
          {roleOnDB === 'role-investor' && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                mt: 3,
              }}
            >
              <Button
                onClick={handleSaveProfileAsApplicant}
                variant="contained"
              >
                {t('PERFIL_CONFIG_VIEW.CHANGE_APPLICANT')}
              </Button>
              <Button onClick={handleChangeRoleClose} variant="outlined">
                {t('CO_ASSESSMENT.CANCEL')}
              </Button>
            </Box>
          )}
          {roleOnDB === 'role-applicant' &&
            hasInvestorToken &&
            walletEnabled &&
            localStorageWallet !== null && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  mt: 3,
                }}
              >
                <Button
                  onClick={handleSaveProfileAsInvestor}
                  variant="contained"
                  disabled={
                    roleOnDB !== 'role-applicant' ||
                    !hasInvestorToken ||
                    !walletEnabled ||
                    localStorageWallet === null
                  }
                >
                  {t('PERFIL_CONFIG_VIEW.CHANGE_INVESTOR')}
                </Button>
                <Button onClick={handleChangeRoleClose} variant="outlined">
                  {t('CO_ASSESSMENT.CANCEL')}
                </Button>
              </Box>
            )}
          {savingProfile && (
            <Box sx={{ width: '80%', mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Modal>
    </AppBar>
  )
}

export default NavBar
