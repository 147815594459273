import { Box } from '@mui/material'
import React from 'react'
import CalendarCreateEvent from '../../components/AdminComponents/Calendar/CalendarCreateEvent'

const AdminViewCalendarCreate = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
                justifySelf: 'center',
                width: { xs: '100%', md: '90%' },
                pt: '2%',
                pl: { xs: '0', md: '10%' },
            }}
        >
            <CalendarCreateEvent />
        </Box>
    )
}

export default AdminViewCalendarCreate