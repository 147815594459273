import { Box, Button, CircularProgress, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CircleIcon from '../../../src/assets/icons/circle.svg'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import LinearProgress from '@mui/material/LinearProgress';
import { GET_USER_INFO } from '../../Queries/Users'
import {
  GET_MESSAGES_FROM_USERS,
  SEND_MESSAGE,
  GET_USER_ID,
  SET_MESSAGES_AS_READ,
} from '../../Queries/Messages'
import { useAuth0 } from '@auth0/auth0-react'
import SendIcon from '@mui/icons-material/Send'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { CREATE_NOTIFICATION } from '../../Queries/Notifications'
import { fetchUTCTime } from '../../utils/utcTime'

const HistoricMessages = (selectedUser) => {

  const { t } = useTranslation()
  const { user } = useAuth0()
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const [msgsToSetAsRead, setMsgsToSetAsRead] = useState([])

  useEffect(() => {
    scrollToBottom()
  })

  const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(GET_USER_INFO, {
    variables: { email: user?.email || user?.sub },
  })
  const [otherUser, { data: otherUserData, loading: otherUserDataLoading }] = useLazyQuery(GET_USER_ID, {
    variables: { username: selectedUser?.selectedUser },
  })
  useEffect(() => {
    getUserData()
    otherUser()
  }, [user, getUserData, otherUser])
  const [sendMessageMutation] = useMutation(SEND_MESSAGE)
  const [createNotification] = useMutation(CREATE_NOTIFICATION)
  const [updateMsgsReadField] = useMutation(SET_MESSAGES_AS_READ)
  const [textToSend, setTextToSend] = useState('')
  const [messageSending, setMessageSending] = useState(false)

  const handleTextChange = (event) => {
    setTextToSend(event.target.value)
  }
  let { data: messagesData, loading: messagesDataLoading, refetch: messagesDataRefetch } = useQuery(
    GET_MESSAGES_FROM_USERS,
    {
      pollInterval: 2000,
      variables: {
        userId: userData?.users[0]?.id || '',
        otherUser: selectedUser?.selectedUser || '',
      },
    }
  )
  useEffect(() => {
    scrollToBottom()
  }, [messagesData])

  useEffect(() => {
    if (messagesData != undefined) {
      const filteredMessages = messagesData?.messages?.filter(
        (msg) => msg?.sentBy?.id !== userData?.users[0]?.id
      )
      const mappedIds = filteredMessages.map((msg) => msg.id)
      setMsgsToSetAsRead(mappedIds)
    }
    return () => {
      messagesData = undefined
    }
  }, [messagesData])

  useEffect(() => {
    if (msgsToSetAsRead.length > 0) {
      updateMsgsReadField({
        variables: {
          ids: msgsToSetAsRead,
        },
      })
    }
  }, [msgsToSetAsRead])

  //const utcTime = useUtcTime()
  const myuuid = uuidv4()
  const dateTime = moment.utc()
  const sendMessage = async (e) => {
    e.preventDefault()
    setMessageSending(true)
    try {
      if (textToSend !== '') {
        const utcTime = await fetchUTCTime()
        await sendMessageMutation({
          variables: {
            id: myuuid,
            on: dateTime,
            onUTC: utcTime,
            text: textToSend,
            read: false,
            deleted: false,
            userId: userData?.users[0]?.id,
            messageId: myuuid,
            otherUserId: otherUserData?.users[0]?.id,
          },
        })
        const notifID = uuidv4()
        messagesDataRefetch()
        setTextToSend('')
        await createNotification({
          variables: {
            notificationID: notifID,
            msg: `MSG - ${userData?.users[0]?.userName}`,
            date: dateTime,
            link: '/messages',
            userID: otherUserData?.users[0]?.id,
          },
        })
      }
    } catch (err) {
      //Err
    }
    setMessageSending(false)
  }

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  if (messagesDataLoading || userDataLoading || otherUserDataLoading)
    return (
      <Box sx={{ width: '50%', height: '50%', ml: '50%', mt: 10 }}>
        <CircularProgress />
      </Box>
    )

  return selectedUser.selectedUser === undefined ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <img src={CircleIcon} alt="icon" width="90px" />
      <Typography sx={{ fontSize: '24px', mt: '2%' }}>
        {t('MESSAGES.YOUR_MESSAGES')}
      </Typography>
      <Typography sx={{ fontSize: '16px' }}>
        {t('MESSAGES.YOUR_MESSAGES_DETAIL')}
      </Typography>
    </Box>
  ) : (
    <>
      <Box
        className="message-container"
        sx={{ mb: '10px', position: 'relative', height: '79.9vh' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid var(--borderGray)',
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: '14px', md: '18px', lg: '20px' },
              fontFamily: 'Quicksand, sans-serif',
              lineHeight: '20px',
              fontWeight: 'bold',
              color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
            }}
          >
            {selectedUser.selectedUser}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            height: '66vh',
          }}
        >
          {messagesData?.messages.map((message) => {
            if (message.sentBy.userName === selectedUser?.selectedUser) {
              const date = moment(message.on)
              if (message.isOfferPurchase) {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #9A1D4C',
                        maxWidth: '70%',
                        borderRadius: '15px',
                        color: '#9A1D4C',
                        backgroundColor: '#DB417B1F',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        {message.text.split('Description:')[0]}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          width: '100%',
                          alignItems: 'center',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: '600', mr: 1 }}>
                          {t('MESSAGES.PROP_DESCRIPTION')}
                        </Typography>
                        {message.text.split('Description:')[1].split('•')[0]}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_ROUND')}
                        </Typography>
                        {message.text.split('Round:')[1].split('•')[0]}
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_PRICE')}
                        </Typography>
                        {
                          message.text
                            .split('Round:')[1]
                            .split('•')[1]
                            .split('Price:')[1]
                        }
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_MARKETCAP')}
                        </Typography>
                        {message.text.split('Market Cap:')[1].split('•')[0]}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_STARTINGDATE')}
                        </Typography>
                        {message.text.split('Starting Date:')[1].split('•')[0]}
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_ENDINGDATE')}
                        </Typography>
                        {
                          message.text
                            .split('Ending Date:')[1]
                            .split('•')[0]
                            .split('OFID:')[0]
                        }
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'right',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {date.format('DD/MM/YYYY-H:mm:ss')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )
              } else if (message.text.split(':')[0] === 'REJ_NOTICE') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('INVESTMENT_STATUS.REJ_NOTICE')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else if (message.text.split(':')[0] === 'COUNTER_OFFER') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('MESSAGES.REC_COUNT_OFFER')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              else if (message.text === 'ACC_COUNTEROFFER') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('MESSAGES.ACC_COUNTEROFFER')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              else if (message.text === 'REJ_COUNTEROFFER') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('MESSAGES.REJ_COUNTEROFFER')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else if (message.text.split('-')[0] === 'MESSAGE_STOP') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('OFFERINGS_TABLE.MESSAGE_STOP1') + message?.text.split('-')[1] + t('OFFERINGS_TABLE.MESSAGE_STOP2')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else {
                return (
                  <Box
                    key={message.id}
                    className='normalMsgContainer'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'red',
                        maxWidth: '70%',
                        borderRadius: '15px',
                        background: '#9A1D4C',
                        color: 'white',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedUser.selectedUser}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px', lineBreak: 'auto' }}>
                        {message.text}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
            } else if (message.sentBy.userName !== selectedUser?.selectedUser) {
              const date = moment(message.on)
              if (message.isOfferPurchase) {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'end',
                      alignItems: 'flex-end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #9A1D4C',
                        maxWidth: '70%',
                        borderRadius: '15px',
                        color: '#9A1D4C',
                        backgroundColor: '#DB417B1F',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        {message.text.split('Description:')[0]}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          width: '100%',
                          alignItems: 'center',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: '600', mr: 1 }}>
                          {t('MESSAGES.PROP_DESCRIPTION')}
                        </Typography>
                        {message.text.split('Description:')[1].split('•')[0]}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_ROUND')}
                        </Typography>
                        {message.text.split('Round:')[1].split('•')[0]}
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_PRICE')}
                        </Typography>
                        {
                          message.text
                            .split('Round:')[1]
                            .split('•')[1]
                            .split('Price:')[1]
                        }
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_MARKETCAP')}
                        </Typography>
                        {message.text.split('Market Cap:')[1].split('•')[0]}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_STARTINGDATE')}
                        </Typography>
                        {message.text.split('Starting Date:')[1].split('•')[0]}
                        <Typography sx={{ fontWeight: '600' }}>
                          {t('MESSAGES.PROP_ENDINGDATE')}
                        </Typography>
                        {
                          message.text
                            .split('Ending Date:')[1]
                            .split('•')[0]
                            .split('OFID:')[0]
                        }
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          alignItems: 'right',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {date.format('DD/MM/YYYY-H:mm:ss')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )
              } else if (message.text.split(':')[0] === 'REJ_NOTICE') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('INVESTMENT_STATUS.REJ_NOTICE')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else if (message.text.split(':')[0] === 'COUNTER_OFFER') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('MESSAGES.SENT_COUNTER_OFFER')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else if (message.text === 'ACC_COUNTEROFFER') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('MESSAGES.ACC_COUNTEROFFER')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              else if (message.text === 'REJ_COUNTEROFFER') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('MESSAGES.REJ_COUNTEROFFER')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else if (message.text.split('-')[0] === 'MESSAGE_STOP') {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '70%',
                        borderRadius: '15px',
                        border: '1px solid #9A1D4C',
                        color: '#9A1D4C',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('OFFERINGS_TABLE.MESSAGE_STOP1') + message?.text.split('-')[1] + t('OFFERINGS_TABLE.MESSAGE_STOP2')}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              } else {
                return (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'green',
                        maxWidth: '70%',
                        borderRadius: '15px',
                        background: actualThemeLight ? '#D8D8D8' : '#1D1D1D',
                        pt: 1,
                        pb: 1,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '10px' }}>
                          {t('MESSAGES.YOU')}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: '18px', lineBreak: 'auto' }}>
                        {message.text}
                      </Typography>
                      <Typography sx={{ fontSize: '10px', textAlign: 'end' }}>
                        {date.format('DD/MM/YYYY-H:mm:ss')}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
            }
          })}
          <div ref={messagesEndRef} />
        </Box>
        {/* END MESSAGE DISPLAY BOX */}
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: '1px',
            zIndex: '5',
            justifySelf: 'flex-end',
            flexDirection: 'row',
            width: '100%',
            height: '6.5vh',
            alignItems: 'center',
            backgroundColor: actualThemeLight ? '#EBECF2' : '#262626',
          }}
        >
          {messageSending ? <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box> : <form
            onSubmit={sendMessage}
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: '3px',
              zIndex: '5',
              justifySelf: 'flex-end',
              flexDirection: 'row',
              width: '100%',
              height: '6.5vh',
              alignItems: 'center',
              backgroundColor: actualThemeLight ? '#EBECF2' : '#262626',
              borderTop: '1px solid var(--borderGray)',
            }}
          >
            <TextField
              sx={{ width: '100%', ml: 2 }}
              variant="standard"
              placeholder={t('MESSAGES.PLACEHOLDER')}
              value={textToSend}
              onChange={handleTextChange}
            />
            <Button
              type="submit"
              sx={{ width: '50px', cursor: 'pointer !important' }}
            >
              <SendIcon />
            </Button>
          </form>}

        </Box>
      </Box>
    </>
  )
}


export default HistoricMessages
