import { Typography } from '@mui/material';
import React from 'react';

const CustomTooltipVoting = ({ active, payload, total }) => {
    if (active && payload && payload.length) {
        const { name, value } = payload[0].payload;
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '2px solid #db417b' }}>
                <p>
                    <Typography sx={{ fontSize: '12px', color: 'black' }}>
                        {`${name}: ${value} / ${total}`}
                    </Typography>
                </p>
            </div>
        );
    }
    return null;
};

export default CustomTooltipVoting;
