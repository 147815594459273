import { Box, Button, Link, Modal, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import i18n from "i18next";
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { showMessage } from '../../../store/actions/snackBarActions'
import { useMutation } from '@apollo/client';
import { DELETE_EVENT } from '../../../Queries/Admin';
import { useState } from 'react';
import { useEffect } from 'react';

const IndividualEvent = ({ data }) => {
    const { t } = useTranslation()
    const selectedLanguage = i18n.language
    const dispatch = useDispatch()

    const handleOpenEdit = () => {
        dispatch(push(`/admin-calendar-edit/${data.id}`))
    };

    const [deleteEvent] = useMutation(DELETE_EVENT)


    const handleDelete = async () => {
        await deleteEvent({
            variables: {
                eventId: data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Deleted!', variant: 'success' }))
    }

    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const [color, setColor] = useState(undefined)

    useEffect(() => {
        if (formattedCurrentDate >= data?.startDate?.split('T')[0] && formattedCurrentDate <= data?.endDate?.split('T')[0]) {
            setColor('#26c418')
        } else if (formattedCurrentDate > data?.endDate?.split('T')[0]) {
            setColor('gray')
        } else {
            setColor('#f08a05')
        }
    }, [data])

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const startDay = data?.startDate?.split('-')[2].split('T')[0]
    const startMonth = data?.startDate?.split('-')[1]
    const startYear = data?.startDate?.split('-')[0]
    const endDay = data?.endDate?.split('-')[2].split('T')[0]
    const endMonth = data?.endDate?.split('-')[1]
    const endYear = data?.endDate?.split('-')[0]

    return (
        <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" component="th" scope="row">
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                    {selectedLanguage === 'en' ? data?.titleEn :
                        selectedLanguage === 'es' ? data?.titleEs :
                            selectedLanguage === 'pt' && data?.titlePt}
                </Typography>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                    {selectedLanguage === 'en' ? data?.detailEn :
                        selectedLanguage === 'es' ? data?.detailEs :
                            selectedLanguage === 'pt' && data?.detailPt}
                </Typography>
            </TableCell>
            {data.endDate === null ?
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ backgroundColor: color, p: 0.3, borderRadius: '25px' }}>
                        <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '14px' }}>

                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}, {startYear}
                        </Typography>
                    </Box>
                </TableCell>
                :
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ backgroundColor: color, borderRadius: '25px' }}>
                        <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '14px' }}>
                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}, {startYear} / {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}, {endYear}
                        </Typography>
                    </Box>
                </TableCell>}
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenEdit} >
                    {t('MY_OFFERINGS.MODIFY')}
                </Link>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                    {t('MY_OFFERINGS.DELETE')}
                </Link>
            </TableCell>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                        <Button variant='contained' onClick={handleDelete}>{t('MY_OFFERINGS.DELETE')}</Button>
                    </Box>
                </Box>
            </Modal>
        </TableRow >
    )
}

export default IndividualEvent