import React, { useState } from 'react'
import { Box, Button, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { showMessage } from '../../../store/actions/snackBarActions'
import { UPDATE_VESTING_EVENT, GET_FUNDS_VESTING, GET_VESTING_EVENT_BY_ID } from '../../../Queries/Admin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from "react-router"
import Preloader from '../../preloader/Preloader';



const EditVestingEvent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [updateVestingEvent] = useMutation(UPDATE_VESTING_EVENT)
    const [getFundsVesting, { data: vestingFunds, loading: loadingFunds }] = useLazyQuery(GET_FUNDS_VESTING)
    const [selectedFund, setSelectedFund] = useState('')
    const [indexSelected, setIndexSelected] = useState(undefined)
    const [selectedProposal, setSelectedProposal] = useState('')
    const { id } = useParams()
    const [vestingAmount, setVestingAmount] = useState('')

    const [fetchVestingEvent, { data: vestingById, loading }] = useLazyQuery(
        GET_VESTING_EVENT_BY_ID,
        {
            variables: { vestingEventId: id },
            pollInterval: 1500
        }
    )

    useEffect(() => {
        fetchVestingEvent()
    }, [fetchVestingEvent])

    useEffect(() => {
        if (vestingById !== undefined) {
            setVestingAmount(vestingById?.vestingEvents[0]?.amount)
            setSelectedFund(vestingById?.vestingEvents[0]?.fund?.id)
            setSelectedProposal(vestingById?.vestingEvents[0]?.proposal?.id)
        }
    }, [vestingById])

    useEffect(() => {
        getFundsVesting()
    }, [getFundsVesting])


    function findProposalIndexById(proposals, proposalId) {
        if (!proposals || proposals.length === 0) {
            return -1;
        }

        const proposal = proposals[0].proposal;
        if (!proposal || proposal.length === 0) {
            return -1;
        }

        for (let i = 0; i < proposal.length; i++) {
            if (proposal[i].id === proposalId) {
                return i;
            }
        }

        return -1;
    }

    const proposalId = vestingById?.vestingEvents[0]?.proposal.id;
    const proposalIndex = findProposalIndexById(vestingFunds?.funds, proposalId);
    useEffect(() => {
        if (proposalIndex > -1) {
            setIndexSelected(proposalIndex);
        }
    }, [proposalIndex, setIndexSelected]);

    const handleSave = async () => {
        await updateVestingEvent({
            variables: {
                vestingEventId: id,
                vestingAmount: Number(vestingAmount),
                fundId: selectedFund,
                proposalId: selectedProposal
            }
        })
        await dispatch(push('/admin-vesting-event'))
        await dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
    }

    if (loading) return <Preloader />

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 6
            }}>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "left"
                    }}>
                    <Box
                        onClick={() => {
                            dispatch(push('/admin-vesting-event'))
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                        <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                    </Box>
                    <Box sx={{ ml: 4 }}>
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}>
                            {t('ADMIN.EDIT_VESTING')}
                        </Typography>
                    </Box>
                </Box>
            </Grid >
            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '60%'
                }}>

                <Typography>
                    {t('ADMIN.VESTING_EVENT_AMOUNT')}
                </Typography>
                <TextField size='small'
                    type='number'
                    value={vestingAmount}
                    sx={{ width: '300px' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                    }}
                    onChange={(e) => setVestingAmount(e.target.value)}
                />
            </Grid>

            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 2,
                    width: '60%'
                }}>
                <Typography>
                    {t('ADMIN.FUND')}
                </Typography>
                {
                    !loadingFunds && (
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedFund}
                            size='small'
                            sx={{ width: '300px' }}
                            onChange={(event) => {
                                const selectedIndex = event.target.value;
                                const index = vestingFunds?.funds?.findIndex((fund) => fund.id === selectedIndex);
                                setSelectedFund(selectedIndex);
                                setIndexSelected(index);
                            }}
                        >
                            {vestingFunds?.funds?.map((fund) => {
                                const name = fund.name;
                                const id = fund.id;
                                return (<MenuItem key={fund.id} value={id}>{name}</MenuItem>);
                            })
                            }
                        </Select>
                    )
                }
            </Grid>

            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 2,
                    width: '60%'
                }}>
                <Typography>
                    {t('ADMIN.PROPOSAL_ADMINISTRATION')}
                </Typography>
                {!loadingFunds && (
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProposal}
                        size='small'
                        sx={{ width: '300px' }}
                        onChange={(event) => {
                            setSelectedProposal(event.target.value);
                        }}
                    >
                        {vestingFunds?.funds[indexSelected]?.proposal.map((prop) => {
                            const name = prop.titleEn;
                            const id = prop.id;
                            return (<MenuItem key={prop.id} value={id}>{name}-{prop.company.coName}</MenuItem>);
                        })
                        }
                    </Select>
                )}
            </Grid>


            {
                selectedFund === '' ||
                    indexSelected === undefined ||
                    selectedProposal === '' ||
                    vestingAmount === ''
                    ?
                    <Button
                        sx={{ m: 3 }}
                        disabled variant='contained'>
                        {t('CO_ASSESSMENT.SAVE')}
                    </Button> :
                    <Button
                        sx={{ m: 3 }}
                        onClick={handleSave}
                        variant='contained'>
                        {t('CO_ASSESSMENT.SAVE')}
                    </Button>
            }
        </Box >
    )
}

export default EditVestingEvent