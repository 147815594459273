import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, FormControl, MenuItem, Select, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Typography, InputAdornment, CircularProgress,
    useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ALL_VALIDATED_COMPANIES, GET_ALL_NOT_VALIDATED_COMPANIES } from '../../../Queries/Admin';
import { GET_USER_INFO } from '../../../Queries/Users';
import SearchIcon from '@mui/icons-material/Search';
import CompaniesAdministrationRow from './CompaniesAdministrationRow';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const CompaniesAdministration = () => {

    const { t } = useTranslation();
    const { user } = useAuth0();

    // States for search input and ordering dropdown
    const [search, setSearch] = React.useState('');
    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };
    const [ordering, setOrdering] = useState({ coName: 'ASC' });
    const [statusFilter, setStatusFilter] = useState('all')

    // Query to get user data
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } }
    );

    // Query to get all companies and pending companies
    const [getAllValidatedCompanies,
        { data: allValidatedCompaniesData,
            loading: allValidatedCompaniesDataLoading }] = useLazyQuery(GET_ALL_VALIDATED_COMPANIES, {
                variables: { order: ordering },
                pollInterval: 2000,
                fetchPolicy: 'network-only',
            });

    const [filteredValidatedCompanies, setFilteredValidatedCompanies] = useState([]);

    useEffect(() => {
        if (allValidatedCompaniesData?.companies) {
            const filtered = allValidatedCompaniesData?.companies.filter(company =>
                company.coName.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredValidatedCompanies(filtered);
        }
    }, [search, allValidatedCompaniesData]);

    const [getAllNotValidatedCompanies,
        { data: allNotValidatedCompaniesData,
            loading: allNotValidatedCompaniesDataLoading }] = useLazyQuery(GET_ALL_NOT_VALIDATED_COMPANIES, {
                variables: { order: ordering },
                pollInterval: 2000,
                fetchPolicy: 'network-only',
            });

    const [filteredNotValidatedCompanies, setFilteredNotValidatedCompanies] = useState([]);

    useEffect(() => {
        if (allNotValidatedCompaniesData?.companies) {
            const filtered = allNotValidatedCompaniesData?.companies.filter(company =>
                company.coName.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredNotValidatedCompanies(filtered);
        }
    }, [search, allNotValidatedCompaniesData]);

    // Run the getUserData, getAllValidatedCompanies and getAllPendingCompanies queries when the component mounts
    useEffect(() => {
        getUserData();
        getAllValidatedCompanies();
        getAllNotValidatedCompanies()
    }, [getUserData, getAllValidatedCompanies, getAllNotValidatedCompanies]);

    // State to check if user is a core team member
    const [isCoreTeam, setIsCoreTeam] = useState(false);

    // Set isCoreTeam state to true if user has the role-core-team role
    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true);
            }
        });
    });

    const handleChangeStatusFilter = (event) => {
        setStatusFilter(event.target.value);
    };

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    // Render companies table if user is a core team member
    if (isCoreTeam) {
        return (
            <Box sx={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'center', width: '100%', mb: 6
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', m: 4, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Search input */}
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{t('ADMIN.COMPANY_ADMINISTRATION')}</Typography>
                    <TextField
                        id="filled-search"
                        label={t('ADMIN.ONLY_SEARCH')}
                        type="search"
                        size="small"
                        variant="outlined"
                        onChange={handleTextChange}
                        sx={{ width: '40%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '200px',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{ mr: 1 }}>{t('ADMIN.STATUS')}</Typography>
                        <FormControl fullWidth>
                            <Select sx={{ maxHeight: '40px' }} value={statusFilter} onChange={handleChangeStatusFilter}>
                                <MenuItem value={'all'}>{t('FUND_SEARCH_VIEW.STATUS_ALL')}</MenuItem>
                                <MenuItem value={'verified'}>{t('ADMIN.VERIFIED')}</MenuItem>
                                <MenuItem value={'notVerified'}>{t('ADMIN.NOT_VERIFIED')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {
                    allValidatedCompaniesDataLoading ||
                        allNotValidatedCompaniesDataLoading ?
                        // Display a loading spinner if either of the data sets is still loading
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 8
                            }}>
                            <CircularProgress />
                        </Box>
                        :
                        // Otherwise, display the table with the company data
                        <TableContainer sx={{ width: '100%', height: '60vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                            {ordering !== 'pending-only' &&
                                // Display the table with all companies if ordering is not 'pending-only'
                                <Table stickyHeader={true} sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                onClick={() => {
                                                    if (ordering.coName != 'ASC') {
                                                        setOrdering({ coName: 'ASC' })
                                                    }
                                                    if (ordering.coName != 'DESC') {
                                                        setOrdering({ coName: 'DESC' })
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }}
                                                align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.COMPANY_NAME')}
                                                    {ordering.coName !== "ASC" && ordering.coName !== "DESC" &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering.coName === "DESC" &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering.coName === "ASC" &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.DESCRIPTION')}</TableCell>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    // cursor: 'pointer',
                                                }}
                                                align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.STATUS')}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {statusFilter === 'all' &&
                                            <>
                                                {filteredNotValidatedCompanies?.map((row) => (
                                                    // Map over the allValidatedCompaniesData to display the company information in rows
                                                    <CompaniesAdministrationRow key={row.id} data={row} />
                                                ))}
                                                {filteredValidatedCompanies?.map((row) => (
                                                    // Map over the allValidatedCompaniesData to display the company information in rows
                                                    <CompaniesAdministrationRow key={row.id} data={row} />
                                                ))}
                                            </>
                                        }
                                        {statusFilter === 'verified' &&
                                            <>
                                                {filteredValidatedCompanies?.map((row) => (
                                                    // Map over the allValidatedCompaniesData to display the company information in rows
                                                    <CompaniesAdministrationRow key={row.id} data={row} />
                                                ))}
                                            </>
                                        }
                                        {statusFilter === 'notVerified' &&
                                            <>
                                                {filteredNotValidatedCompanies?.map((row) => (
                                                    // Map over the allValidatedCompaniesData to display the company information in rows
                                                    <CompaniesAdministrationRow key={row.id} data={row} />
                                                ))}
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                }
            </Box >
        );
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default CompaniesAdministration