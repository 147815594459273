import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  Grid,
  Avatar,
  styled,
  Box,
  Link,
} from '@mui/material'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/free-mode/free-mode.min.css'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { GET_USERS_BY_EMAIL } from '../../Queries/Users'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Preloader from '../preloader/Preloader'
import DirectMessageModal from './DirectMessageModal'
import TelegramIcon from '@mui/icons-material/Telegram'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import DiscordIcon from './../../assets/icons/icons8-discordWhite.svg'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation, Pagination } from 'swiper'
import ActiveCompanieCard from '../../Views/DashboardView/newDashboardView/ActiveCompanieCard/ActiveCompanieCard'
import Chip from '@mui/material/Chip'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import i18n from 'i18next'

// Button styling for back button
const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.hint,
  fontFamily: 'Quicksand, sans-serif',
  fontSize: '18px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#f1f1f1',
  },
}))

const UserProfileView = () => {
  const selectedLanguage = i18n.language
  // eslint-disable-next-line
  const [searchUser, setSearchUser] = useState('')
  const { t } = useTranslation()
  const { email } = useParams()
  const locStorageRole = localStorage.getItem('role')

  const userRole =
    locStorageRole === 'role-applicant'
      ? t('INVERSOR_TYPE.APPLICANT')
      : t('INVERSOR_TYPE.INVESTOR')

  //Messages
  const [modalSendMessage, setModalSendMessage] = useState(false)
  const handleOpenNewMessage = () => {
    setModalSendMessage(true)
  }
  const handleClose = () => {
    setModalSendMessage(false)
  }

  const [fetchUsers, { data, loading, error }] = useLazyQuery(
    GET_USERS_BY_EMAIL,
    {
      variables: { email: email },
    }
  )

  useEffect(async () => {
    fetchUsers()
  }, [fetchUsers])

  useEffect(async () => {
    if (email) {
      await fetchUsers()
    }
    return () => {
      setSearchUser(data)
    }
  }, [email])
  if (error) return <p>Error User profile </p>

  const dispatch = useDispatch()
  const targetUser = data?.users[0]?.id
  const targetUsername = data?.users[0]?.userName
  if (loading) return <Preloader />

  // Trim links values

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      {!loading && (
        <Grid container>
          {/* HEADER */}
          <Grid item xs={12} md={12} className="right">
            <Box sx={{ backgroundColor: '#9A1D4C' }}>
              <Grid container>
                {/* BACK BUTTON */}
                <Grid item md={1} xs={1} sx={{ alignSelf: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                    <BackButton
                      onClick={() => dispatch(goBack())}
                      sx={{
                        marginBottom: '10px',
                        color: 'white',
                        textDecoration: 'underline',
                        fontSize: '14px',
                      }}
                    >
                      {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                    </BackButton>
                  </Box>
                </Grid>
                {/* AVATAR */}
                <Grid
                  item
                  md={2}
                  xs={2}
                  sx={{ maxHeight: '16vh', alignSelf: 'center' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      sx={{
                        width: '150px',
                        height: '150px',
                        marginTop: '4vh',
                      }}
                      alt={data?.users[0]?.userName}
                      src={data?.users[0]?.profilePicture}
                    />
                  </Box>
                </Grid>
                {/* NAME AND CONTACT */}
                <Grid item md={5} xs={5} sx={{ alignSelf: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {/* NAME */}
                      <Typography
                        sx={{
                          fontSize: { sm: '18px', md: '20px', lg: '20px' },
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand, sans-serif',
                          color: '#FFFFFF',
                          textAlign: 'center',
                          marginLeft: '16px',
                        }}
                      >
                        {data?.users[0]?.userName}
                      </Typography>
                      {/* ROLE */}
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontFamily: 'Quicksand, sans-serif',
                          color: '#FFFFFF',
                          textAlign: 'center',
                          ml: 1,
                        }}
                      >
                        | {userRole}
                      </Typography>
                    </Box>
                    {/* CONTACT */}
                    <Button
                      variant="outlined"
                      onClick={handleOpenNewMessage}
                      sx={{ color: '#FFF', textDecoration: 'underline' }}
                    >
                      {t('INVERSOR_VIEW.USER_CARD.CONTACT')}
                    </Button>
                  </Box>
                </Grid>
                {/* ICONS & LIKS */}
                <Grid item md={4} xs={4} sx={{ alignSelf: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      color: 'white',
                      alignSelf: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {data?.users[0]?.has_url?.map((url) => {
                      if (url.name !== 'profilePicture') {
                        let icon = null
                        if (url.name == 'Custom') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <InsertLinkIcon
                                sx={{ fontSize: '35px', alignSelf: 'center' }}
                                href={url.link}
                                target={{ _blank: true }}
                                color="inherit"
                              />
                            </Link>
                          )
                        } else if (url.name == 'Telegram') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <TelegramIcon
                                sx={{
                                  fontSize: '35px',
                                  alignSelf: 'center',
                                }}
                              />
                            </Link>
                          )
                        } else if (url.name == 'Twitter') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <TwitterIcon
                                sx={{
                                  fontSize: '35px',
                                  alignSelf: 'center',
                                }}
                              />
                            </Link>
                          )
                        } else if (url.name == 'Linkedin') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <LinkedInIcon
                                sx={{
                                  fontSize: '35px',
                                  alignSelf: 'center',
                                }}
                              />
                            </Link>
                          )
                        } else if (url.name == 'Youtube') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <YouTubeIcon
                                sx={{
                                  fontSize: '35px',
                                  alignSelf: 'center',
                                }}
                              />
                            </Link>
                          )
                        } else if (url.name == 'Discord') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <img
                                src={DiscordIcon}
                                alt="icon"
                                width="30px"
                                style={{
                                  fontSize: '35px',
                                  alignSelf: 'center',
                                }}
                              />
                            </Link>
                          )
                        } else if (url.name == 'Instagram') {
                          icon = (
                            <Link
                              href={url.link}
                              target={{ _blank: true }}
                              color="inherit"
                            >
                              <InstagramIcon
                                sx={{
                                  fontSize: '35px',
                                  alignSelf: 'center',
                                }}
                              />
                            </Link>
                          )
                        }
                        return <Typography key={url.id}>{icon}</Typography>
                      }
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              sx={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}
            >
              <Grid item xs={4} md={4} />
              <Grid container xs={8} md={8} sx={{ gridRowGap: '5px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {data?.users[0]?.prefers?.map((preference, i) => (
                    <Chip
                      label={preference?.preferenceName}
                      key={i}
                      sx={{
                        backgroundColor: '#ebecf2',
                        border: '1px solid #DB417B',
                        m: 3,
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Grid container xs={12} md={12}>
              <Grid item xs={3} md={3} />
              <Grid item xs={9} md={9}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '1.87rem',
                    color: '#4F4F4F',
                    minHeight: { md: '150px' },
                    marginTop: '16px',
                  }}
                >
                  {data?.users[0]?.bio || 'No bio.'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} md={12}>
            <Grid item xs={1} md={1} />
            <Grid item xs={10} md={10}>
              <Grid container xs={12} md={12}>
                {t('INVERSOR_VIEW.OFFERING_CARD.ACTIVE_OFFERINGS')}
              </Grid>
              <Grid
                container
                sx={{
                  display: 'flex',
                  marginTop: '16px',
                  marginBottom: '32px',
                }}
                xs={12}
                md={12}
              >
                <Grid item xs={1} md={1} />
                <Grid item xs={10} md={10}>
                  <Swiper
                    loop={false}
                    spaceBetween={10}
                    slidesPerView={3}
                    modules={[Navigation, Pagination]}
                    navigation={true}
                    grabCursor={true}
                    className="recommended tokens slider"
                  >
                    {data?.users[0]?.manages.map((company, i) => {
                      return (
                        <SwiperSlide key={i} sx={{ marginLeft: '4px' }}>
                          <ActiveCompanieCard
                            id={company?.id}
                            name={company?.coName}
                            logo={company?.logo}
                            isValidated={company?.isValidated}
                            address={company?.address}
                            employees={company?.employees}
                            fundationYear={company?.fundationYear}
                            annualBilling={company?.annualBilling}
                            isIncJurisdiction={company?.isIncJurisdiction}
                            isIncorporated={company?.isIncorporated}
                            desc={
                              selectedLanguage === 'en'
                                ? company?.descriptionEn
                                : selectedLanguage === 'es' &&
                                  company?.descriptionEs !== ''
                                ? company?.descriptionEs
                                : selectedLanguage === 'pt' &&
                                  company?.descriptionPt !== ''
                                ? company?.descriptionPt
                                : company?.descriptionEn
                            }
                            link={company?.has_url[0]?.link}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </Grid>
                <Grid item xs={1} md={1} />
              </Grid>
            </Grid>
            <Grid item xs={1} md={1} />
            <Grid item xs={4} md={4} />
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '32px',
                marginBottom: '32px',
              }}
              xs={4}
              md={4}
            >
              <Button
                sx={{
                  width: '32%',
                }}
                variant="contained"
                onClick={handleOpenNewMessage}
              >
                {t('INVERSOR_VIEW.USER_CARD.CONTACT')}
              </Button>
            </Grid>
            <Grid item xs={4} md={4} />
          </Grid>
          {modalSendMessage && (
            <DirectMessageModal
              targetUser={targetUser}
              targetUsername={targetUsername}
              open={modalSendMessage}
              handleClose={handleClose}
            />
          )}
        </Grid>
      )}
    </Box>
  )
}

export default UserProfileView
