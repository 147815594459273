import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { fbStorage } from '../utils/firebase'

export const imgUploadHandler = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject({
        err: 'An error occurred while uploading',
      })
    }

    // Create a unique filename by appending the current timestamp to the original filename
    const uniqueFileName = `${Date.now()}_${file.name}`
    const storageRef = ref(fbStorage, `/images/${uniqueFileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        console.log(progress)
      },
      (error) => {
        console.error(error)
        reject(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve(url)
        })
      }
    )
  })
}
