import { gql } from '@apollo/client'

export const CREATE_OFFER_PURCHASE = gql`
  mutation CreateOfferPurchase(
    $userID: ID!
    $fundID: ID!
    $purchaseID: ID!
    $amount: Float!
    $date: DateTime!
    $price: Float!
    $status: String!
    $investorAddress: String!
    $legalFullname: String
    $legalId: String
    $legalCountry: String
    $legalFundsOrigin: String
  ) {
    createOfferPurchases(
      input: {
        id: $purchaseID
        amount: $amount
        on: $date
        lastUpdate: $date
        price: $price
        status: $status
        investorAddress: $investorAddress
        legalFullname: $legalFullname
        legalId: $legalId
        legalCountry: $legalCountry
        legalFundsOrigin: $legalFundsOrigin
        users: { connect: { where: { node: { id: $userID } } } }
        fund: { connect: { where: { node: { id: $fundID } } } }
      }
    ) {
      offerPurchases {
        id
        amount
      }
    }
  }
`

export const GET_OFFER_PURCHASE_BY_ID = gql`
  query ($offerPurchaseId: ID!) {
    offerPurchases(where: { id: $offerPurchaseId }) {
      id
      on
      price
      amount
      status
    }
  }
`
