import { Avatar, Box, Button, Card, LinearProgress, Typography, Zoom, Tooltip as Tool, Tooltip, useTheme, Grid } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { useState } from 'react'
import { useEffect } from 'react'
import i18n from "i18next";
import TokenAllies from '../../../src/assets/icons/TokenAlliesIcon.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PlaylistAddCheckCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip as ToolChart, PieChart, Pie, LineChart, Line, Label } from 'recharts';
import CustomTooltipVoting from './CustomTooltipVoting'
import CustomTooltipWeeks from './CustomTooltipWeeks';
import CustomTooltipMonths from './CustomTooltipMonths'
import CustomTooltipMonthsROI from './CustomTooltipMonthsROI'
import CustomTooltipMonthsExecution from './CustomTooltipMonthsExecution'
import InvestModal from '../InvestModal/InvestModal'
import WaitingListModal from '../InvestModal/WaitingListModal'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CustomTooltipCollectingThresshold from './CustomTooltipCollectingThresshold'

const FundCard = ({ data }) => {
    const selectedLanguage = i18n.language;
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const locStorageRole = localStorage.getItem('role')
    const [modalInvest, setModalInvest] = useState(false)
    const [modalWaitingList, setModalWaitingList] = useState(false)
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const handleCloseInvest = () => {
        setModalInvest(false)
    }

    const handleCloseWaitingList = () => {
        setModalWaitingList(false)
    }

    const dataChartOpen = [
        {
            date: '1',
            amount: 11,
        },
        {
            date: '2',
            amount: 14,
        },
        {
            date: '3',
            amount: 2,
        },
        {
            date: '4',
            amount: 14,
        },
        {
            date: '5',
            amount: 2,
        },
        {
            date: '6',
            amount: 7,
        },
        {
            date: '7',
            amount: 3,
        },
        {
            date: '8',
            amount: 1,
        },
    ];

    const dataChartPublished = [
        {
            date: '1',
            amount: 20,
        },
        {
            date: '2',
            amount: 11,
        },
        {
            date: '3',
            amount: 23,
        },
        {
            date: '4',
            amount: 21,
        },
        {
            date: '5',
            amount: 6,
        },
        {
            date: '6',
            amount: 13,
        },
        {
            date: '7',
            amount: 22,
        },
        {
            date: '8',
            amount: 4,
        },

    ];

    const dataChartAssessment = [
        {
            date: '1',
            amount: 5,
        },
        {
            date: '2',
            amount: 3,
        },
        {
            date: '3',
            amount: 4,
        },
        {
            date: '4',
            amount: 3,
        },
        {
            date: '5',
            amount: 6,
        },
        {
            date: '6',
            amount: 2,
        },
        {
            date: '7',
            amount: 2,
        },
        {
            date: '8',
            amount: 1,
        },
    ];

    const dataChartGovernance = [
        { name: 'Voted', value: 34, fill: '#db417b' },
        { name: 'Not Voted', value: 66, fill: 'gray' },
    ];

    const totalGovernance = dataChartGovernance?.reduce((sum, entry) => sum + entry.value, 0);


    const dataChartVesting = [
        {
            date: '1',
            amount: 1233,
        },
        {
            date: '2',
            amount: 2122,
        },
        {
            date: '3',
            amount: 2331,
        },
        {
            date: '4',
            amount: 423,
        },
        {
            date: '5',
            amount: 623,
        },
    ];

    const dataChartExecution = [
        {
            date: '1',
            amount: 250000,
            companies: 2,
        },
        {
            date: '2',
            amount: 198000,
            companies: 3,
        },
        {
            date: '3',
            amount: 210000,
            companies: 1,
        },
        {
            date: '4',
            amount: 250000,
            companies: 5,
        },
        {
            date: '5',
            amount: 130000,
            companies: 3,
        },
        {
            date: '6',
            amount: 110000,
            companies: 2,
        },
        {
            date: '7',
            amount: 230000,
            companies: 4,
        },
        {
            date: '8',
            amount: 90000,
            companies: 1,
        },
    ];

    const dataChartROI = [
        {
            date: '1',
            inv: 10000,
            roiOverInv: 2000,
        },
        {
            date: '2',
            inv: 23000,
            roiOverInv: 12000,
        },
        {
            date: '3',
            inv: 20000,
            roiOverInv: 5000,
        },
        {
            date: '4',
            inv: 17000,
            roiOverInv: 1800,
        },
        {
            date: '5',
            inv: 18000,
            roiOverInv: 2500,
        },
        {
            date: '6',
            inv: 13000,
            roiOverInv: 5000,
        },
    ];

    // Handle click event and navigate to fund detail page
    const handleClick = () => {
        dispatch(push(`/fund-detail/${data.id}`));
    }

    // State for header start date and end date
    const [headerStartDateToShow, setHeaderStartDateToShow] = useState(undefined);
    const [headerEndDateToShow, setHeaderEndDateToShow] = useState(undefined);
    const today = moment.utc()
    const startDate = moment(headerStartDateToShow);
    const endDate = moment(headerEndDateToShow);
    const startDay = startDate.format('DD');
    const startMonth = startDate.format('MM');
    const startYear = startDate.format('YYYY');
    const endDay = endDate.format('DD');
    const endMonth = endDate.format('MM');
    const daysLeft = endDate.diff(today, 'days');

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    function countValidOfferPurchases(offerPurchases) {

        const validOfferPurchases = offerPurchases?.filter(
            offer => !excludedStatuses?.includes(offer?.status)
        );

        return validOfferPurchases?.length;
    }
    const countOfValidOffPur = countValidOfferPurchases(data?.offerPurchase);

    const [statusValue, setStatusValue] = useState(0)

    useEffect(() => {
        if (data?.status === "Collecting") {
            setStatusValue(1)
        }
        if (data?.status === "Published") {
            setStatusValue(2)
        }
        if (data?.status === "Open") {
            setStatusValue(3)
        }
        if (data?.status === "Assessment") {
            setStatusValue(4)
        }
        if (data?.status === "Governance") {
            setStatusValue(5)
        }
        if (data?.status === "Vesting") {
            setStatusValue(6)
        }
        if (data?.status === "Execution") {
            setStatusValue(7)
        }
        if (data?.status === "ROI") {
            setStatusValue(8)
        }
    }, [data?.status])

    // Calculate the total investment from the 'data' object
    function calculateTotalInvestment(data) {
        if (!Array.isArray(data)) { return 0; }
        let totalAmount = 0;
        if (data) {
            for (const item of data) {
                if (typeof item.amount === 'number') {
                    totalAmount += item.amount;
                }
            }
        }
        return totalAmount;
    }

    const validOfferPurchases = data?.offerPurchase?.filter(
        offer => !excludedStatuses?.includes(offer?.status)
    );

    const totalInvestment = calculateTotalInvestment(validOfferPurchases);

    // Find unique companies from the 'proposal' array in the 'data' object
    const uniqueCompanies = new Set();
    data.proposal.forEach((proposal) => {
        if (proposal.company && Array.isArray(proposal.company)) {
            proposal.company.forEach((company) => {
                uniqueCompanies.add(company);
            });
        }
    });

    const companiesAmount = uniqueCompanies.size;

    // Count the number of assessed proposals in the 'data' object
    function countAssessedProposals(obj) {
        let count = 0;

        if (obj?.proposal && Array.isArray(obj?.proposal)) {
            for (const proposal of obj?.proposal) {
                if (proposal.status === "assessed") {
                    count++;
                }
            }
        }

        return count;
    }

    const assessedProposalsCount = countAssessedProposals(data);

    // Dummy data object
    const dummyData = [{ availableFunds: 0, deliveredFunds: 0, winningCompanies: 0, roiAmount: 0 }];

    // useEffect hook to update header start date and end date based on data changes
    useEffect(() => {
        data?.event?.map((e) => {
            if (e?.titleEn === data?.status) {
                setHeaderStartDateToShow(e?.startDate?.split('T')[0]);
                setHeaderEndDateToShow(e?.endDate?.split('T')[0]);
            }
        });
    }, [data]);

    const amountToReach = data?.amountToReach

    function calculateTotalAmount(data) {
        if (data && data.offerPurchase && Array.isArray(data.offerPurchase)) {
            const totalAmount = data.offerPurchase.reduce((total, offer) => {
                const amount = parseFloat(offer.amount);
                return isNaN(amount) ? total : total + amount;
            }, 0);

            return totalAmount;
        } else {
            return 0;
        }
    }

    const totalAmountGathered = calculateTotalAmount(data);

    function calculateDaysLeft(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000;
        const start = new Date(startDate);
        const end = new Date(endDate);
        const timeDiff = end.getTime() - start.getTime();
        const daysDiff = Math.ceil(timeDiff / oneDay);
        return daysDiff;
    }

    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };

    const [currEvent, setCurrEvent] = useState(null)

    useEffect(() => {
        data?.event?.map((e) => {
            if (e?.titleEn === data?.status) {
                setCurrEvent(e)
            }
        })
    }, [data])

    const eventDate = new Date(currEvent?.endDate?.split('T')[0] || data?.event[0]?.endDate?.split('T')[0])
    const eventFormattedDate = eventDate.toLocaleDateString("en-US", options);
    const todayFormattedDate = today
    const daysLeftOpen = calculateDaysLeft(todayFormattedDate, eventFormattedDate);

    const dataChartCollecting = [
        { name: 'Raised', value: totalInvestment, fill: '#db417b' },
        { name: '', value: data?.amountToReach - totalInvestment, fill: 'gray' },
    ];
    const dataChartCollectingThresshold = [
        { name: 'Thresshold', value: data?.successThreshold, fill: 'green' },
        { name: 'Thresshold', value: 100 - data?.successThreshold, fill: 'gray' },
    ];

    return (
        <Card
            sx={{
                minWidth: { md: 100, xs: 120 },
                position: 'relative',
                width: { md: '98%', xs: '110%' },
                maxWidth: { xs: '340px', lg: '350px' },
                height: '440px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                borderRadius: 2,
                borderColor: actualThemeLight ? '#000' : 'white',
                cursor: 'pointer',
                justifyContent: 'space-between',
                backgroundColor: actualThemeLight ? '#ffffff' : '#1d1d1d'
            }}
            className="card"
        >
            <Box
                onClick={handleClick}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                {data?.status === 'Collecting' &&
                    <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'end',
                        pr: 2,
                        pt: 2
                    }}>
                        {
                            amountToReach > totalAmountGathered && daysLeft >= 1
                                ?
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FUND_VIEW.NO_WAIT_LIST')}
                                        </Typography>
                                    </div>
                                }>
                                    <CheckCircleOutlinedIcon sx={{ color: 'green' }} />
                                </Tooltip>
                                :
                                daysLeft <= 0 ? null :
                                    <Tooltip arrow placement='top' title={
                                        <div>
                                            <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                                {t('FUND_VIEW.WAIT_LIST')}
                                            </Typography>
                                        </div>
                                    }>
                                        <PlaylistAddCheckCircleOutlinedIcon sx={{ color: 'orange' }} />
                                    </Tooltip>
                        }
                    </Box>
                }
                {data?.status === 'Open' && daysLeftOpen >= 1 &&
                    <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'end',
                        pr: 2,
                        pt: 2
                    }}>
                        {
                            <Tooltip arrow placement='top' title={
                                <div>
                                    <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                        {t('FUND_VIEW.APPLY_NOW')}
                                    </Typography>
                                </div>
                            }>
                                <RadioButtonCheckedIcon sx={{ color: 'green' }} />
                            </Tooltip>
                        }
                    </Box>
                }
                {/* CARD HEADER */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        mt: 3,
                        ml: 3,
                        mr: 3,
                        mb: 1,
                        pb: 2,
                        borderBottom: '1px solid #e3e1e3'
                    }}>
                    {data.image !== '' ?
                        <Avatar sx={{ width: '60px', height: '60px', border: '1px solid #e3e1e3' }} src={data.image}></Avatar>
                        :
                        <Avatar sx={{ width: '60px', height: '60px', border: '1px solid #e3e1e3' }} src={TokenAllies}></Avatar>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', ml: 2 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>{data.name}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: -0.3 }}>
                            {statusValue !== 0 ?
                                <FiberManualRecordIcon fontSize="medium" sx={{ color: actualThemeLight ? '#B2E0DF' : "#2A4C4B" }} />
                                :
                                null
                            }
                            {data.status === 'Collecting' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C1_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_COLLECTING')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Published' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C2_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_PUBLISHED')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Open' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C3_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_OPEN')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Closed' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_CLOSED_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_CLOSED')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Closed - Not Fully Funded' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_CLOSED_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_CLOSED_NOT_FULLY_FUNDED')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Assessment' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C4_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_ASSESSMENT')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Governance' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C5_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_GOVERNANCE')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Execution' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C7_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_EXECUTION')}
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Vesting' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C6_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        Vesting
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'ROI' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_C8_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        ROI
                                    </Typography>
                                </Tooltip>
                            }
                            {data.status === 'Finished' &&
                                <Tooltip arrow placement='top' title={
                                    <div>
                                        <Typography textAlign={'center'} sx={{ fontSize: '14px' }}>
                                            {t('FAQ.PANEL_CLOSED_HELPER')}
                                        </Typography>
                                    </div>
                                }>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_FINISHED')}
                                    </Typography>
                                </Tooltip>
                            }
                            <Box sx={{ position: 'absolute', ml: 1.1, mt: 0.1 }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                                    {statusValue !== 0 ? statusValue : null}
                                </Typography>
                            </Box>
                        </Box>
                        {
                            ['Collecting', 'Published', 'Assessment', 'Governance', 'Open', 'Vesting'].includes(data.status) &&
                                (headerStartDateToShow !== undefined && headerEndDateToShow !== undefined)
                                ?
                                <>
                                    {
                                        startMonth !== undefined && endMonth !== undefined ?
                                            <Typography sx={{ fontSize: '12px' }}>
                                                {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                            </Typography> : null
                                    }
                                </>
                                :
                                null
                        }
                    </Box>
                </Box>
                {/* CARD HEADER - END */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                        {/* COLLECTING STATUS */}
                        {data.status === 'Collecting' ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                {/* OBJECTIVE */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                    <Box sx={{ width: '60%' }}>
                                        <Typography sx={{ fontSize: '14px' }}>{t('FUND.OBJECTIVE')}</Typography>
                                    </Box>
                                    <Box sx={{ width: '40%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            $ {data.amountToReach.toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* AMOUNT GATHERED */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                    <Box sx={{ width: '60%' }}>
                                        <Typography sx={{ fontSize: '14px' }}>{t('INVERSOR_VIEW.OFFERING_CARD.AMOUNT_REACHED')}</Typography>
                                    </Box>
                                    <Box sx={{ width: '40%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            {((totalInvestment / data.amountToReach) * 100).toFixed(2)}%
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* INVESTORS */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                    <Box sx={{ width: '60%' }}>
                                        <Typography sx={{ fontSize: '14px' }}>{t('FUND.INVESTORS')}</Typography>
                                    </Box>
                                    <Box sx={{ width: '40%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            {countOfValidOffPur || 0}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* MIN/MAX INVESTMENT */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                    <Box sx={{ width: '60%' }}>
                                        <Typography sx={{ fontSize: '14px' }}> {t('FUND.MIN/MAX_INVESTMENT')}</Typography>
                                    </Box>
                                    <Box sx={{ width: '40%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            $ {data?.minInvestment?.toLocaleString()} - {data?.maxInvestment?.toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* PURPOSE */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                    <Box sx={{ width: '60%' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {t('FUND_VIEW.PURPOSE')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '40%' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden' }}>{
                                            selectedLanguage === 'en' ? data.purpose.titleEn :
                                                selectedLanguage === 'es' ? data.purpose.titleEs :
                                                    selectedLanguage === 'pt' && data.purpose.titlePt
                                        }</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            : data.status === 'Published' || data.status === 'Open' ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                    {/* AVAILABLE FUNDS */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                        <Box sx={{ width: '60%' }}>
                                            <Typography sx={{ fontSize: '14px' }}>{t('FUND.AVAILABLE_FUNDS')}</Typography>
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                $ {totalInvestment.toLocaleString()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* REQUIRED INVESTMENT */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                        <Box sx={{ width: '60%' }}>
                                            <Typography sx={{ fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden' }}>{t('FUND.MIN/MAX_EXPECTED_INVESTMENT')}</Typography>
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                $ {data?.minCompanyAsk?.toLocaleString()}-{data.maxCompanyAsk.toLocaleString()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* APLICANTS */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                        <Box sx={{ width: '60%' }}>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {t('FUND.APLICANTS')}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                {data?.proposal?.length}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* EXPECTED ROI */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                        <Box sx={{ width: '60%' }}>
                                            <Typography sx={{ fontSize: '14px' }}> {t('FUND.EXPECTED_ROI')}</Typography>
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                {data.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* PURPOSE */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                        <Box sx={{ width: '60%' }}>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {t('FUND_VIEW.PURPOSE')}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden' }}>{
                                                selectedLanguage === 'en' ? data.purpose.titleEn :
                                                    selectedLanguage === 'es' ? data.purpose.titleEs :
                                                        selectedLanguage === 'pt' && data.purpose.titlePt
                                            }</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                : data.status === 'Assessment' ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                        {/* AVAILABLE FUNDS */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                            <Box sx={{ width: '60%' }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.AVAILABLE_FUNDS')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '40%' }}>
                                                {/* DUMMY DATA */}
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    $ {totalInvestment.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* TOTAL AMOUNT REQUESTED */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                            <Box sx={{ width: '60%' }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.TOTAL_REQUESTED')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '40%' }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    $ {data.amountToReach}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* APLICATIONS */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                            <Box sx={{ width: '60%' }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.APLICANTS')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '40%' }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {data?.proposal?.length}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* VESTING TYPE */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                            <Box sx={{ width: '60%' }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.VESTING.VESTING_TYPE')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '40%' }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {data.vestingPlan}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* PURPOSE */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                            <Box sx={{ width: '60%' }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND_VIEW.PURPOSE')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '40%' }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden' }}>{
                                                    selectedLanguage === 'en' ? data.purpose.titleEn :
                                                        selectedLanguage === 'es' ? data.purpose.titleEs :
                                                            selectedLanguage === 'pt' && data.purpose.titlePt
                                                }</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    : data.status === 'Governance' ?
                                        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                            {/* AVAILABLE FUNDS */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                <Box sx={{ width: '60%' }}>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {t('FUND.AVAILABLE_FUNDS')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '40%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        $ {totalInvestment.toLocaleString()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {/* TOTAL AMOUNT REQUESTED */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                <Box sx={{ width: '60%' }}>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {t('FUND.TOTAL_REQUESTED')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '40%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        $ {data?.amountToReach?.toLocaleString()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {/* APPROVED APLICATIONS */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                <Box sx={{ width: '60%' }}>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {t('FUND.APPROVED_APPLICATIONS')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '40%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        {data?.proposal?.assessmentStatus === 'assessed' ? data?.proposal?.length : 0}/{data?.proposal?.length}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {/* EXPECTED ROI */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                <Box sx={{ width: '60%' }}>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '40%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        {data.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {/* PURPOSE */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                <Box sx={{ width: '60%' }}>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {t('FUND_VIEW.PURPOSE')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '40%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden' }}>{
                                                        selectedLanguage === 'en' ? data.purpose.titleEn :
                                                            selectedLanguage === 'es' ? data.purpose.titleEs :
                                                                selectedLanguage === 'pt' && data.purpose.titlePt
                                                    }</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        : data.status === 'Vesting' ?
                                            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                                {/* DELIVERED FUNDS */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                    <Box sx={{ width: '60%' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>{t('FUND.DELIVERED_FUNDS')}</Typography>
                                                    </Box>
                                                    <Box sx={{ width: '40%' }}>
                                                        {/* DUMMY DATA */}
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            $ {dummyData[0].deliveredFunds}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {/* PENDING VESTING */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                    <Box sx={{ width: '60%' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>
                                                            {t('FUND.VESTING_DELIVER')}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ width: '40%' }}>
                                                        {/* DUMMY DATA */}
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            2º {t('FUND.SEMESTER')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {/* WINNING COMPANIES */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                    <Box sx={{ width: '60%' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>
                                                            {t('FUND.WINNING_COMPANIES')}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ width: '40%' }}>
                                                        {/* DUMMY DATA */}
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            {dummyData[0].winningCompanies}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {/* EXPECTED ROI */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                    <Box sx={{ width: '60%' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>
                                                            {t('FUND.EXPECTED_ROI')}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ width: '40%' }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            {data.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {/* PURPOSE */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                    <Box sx={{ width: '60%' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>{t('FUND_VIEW.PURPOSE')}</Typography>
                                                    </Box>
                                                    <Box sx={{ width: '40%' }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden' }}>{
                                                            selectedLanguage === 'en' ? data.purpose.titleEn :
                                                                selectedLanguage === 'es' ? data.purpose.titleEs :
                                                                    selectedLanguage === 'pt' && data.purpose.titlePt
                                                        }</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            : data.status === 'Execution' ?
                                                <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                                    {/* DELIVERED FUNDS */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                        <Box sx={{ width: '60%' }}>
                                                            <Typography sx={{ fontSize: '14px' }}>{t('FUND.DELIVERED_FUNDS')}</Typography>
                                                        </Box>
                                                        <Box sx={{ width: '40%' }}>
                                                            {/* DUMMY DATA */}
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                $ {dummyData[0].deliveredFunds}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* INVESTED COMPANIES */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                        <Box sx={{ width: '60%' }}>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {t('FUND_SEARCH_VIEW.INVESTED_COMPANIES')}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ width: '40%' }}>
                                                            {/* DUMMY DATA */}
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                {dummyData[0].winningCompanies}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* TIME SPENT */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                        <Box sx={{ width: '60%' }}>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {t('FUND.TIME_SPENT')}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ width: '40%' }}>
                                                            {/* DUMMY DATA */}
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                2 {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* EXPECTED ROI */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                        <Box sx={{ width: '60%' }}>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {t('FUND.EXPECTED_ROI')}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ width: '40%' }}>
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                {data.roiTime}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* EXPECTED ROI MULTIPLIER */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                        <Box sx={{ width: '60%' }}>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {t('FUND.EXPECTED_ROI')}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ width: '40%' }}>
                                                            {/* DUMMY DATA */}
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Multip 2x - 4x
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                : data.status === 'ROI' ?
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                                        {/* DELIVERED FUNDS */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            <Box sx={{ width: '60%' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {t('FUND.DELIVERED_FUNDS')}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: '40%' }}>
                                                                {/* DUMMY DATA */}
                                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    $ {dummyData[0].deliveredFunds}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {/* ROI */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            <Box sx={{ width: '60%' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    ROI
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: '40%' }}>
                                                                {/* DUMMY DATA */}
                                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    $ {dummyData[0]?.roiAmount?.toLocaleString()}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {/* TIME SPENT */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            <Box sx={{ width: '60%' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {t('FUND.TIME_SPENT')}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: '40%' }}>
                                                                {/* DUMMY DATA */}
                                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    2 {t('ADMIN.YEARS')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {/* EXPECTED ROI */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            <Box sx={{ width: '60%' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {t('FUND.EXPECTED_ROI')}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: '40%' }}>
                                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    {data.roiTime}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {/* EXIT COMPANIES */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            <Box sx={{ width: '60%' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {t('FUND.EXIT_COMPANIES')}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: '40%' }}>
                                                                {/* DUMMY DATA */}
                                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                    {assessedProposalsCount}/{companiesAmount}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    : data.status === 'Closed' || data.status === 'Closed - Not Fully Funded' ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3, pt: 1 }}>
                                                            {/* DELIVERED FUNDS */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                                <Box sx={{ width: '60%' }}>
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        {t('FUND.DELIVERED_FUNDS')}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ width: '40%' }}>
                                                                    {/* DUMMY DATA */}
                                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                        $ {dummyData[0].deliveredFunds}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* ROI */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                                <Box sx={{ width: '60%' }}>
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        ROI
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ width: '40%' }}>
                                                                    {/* DUMMY DATA */}
                                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                        $ {dummyData[0].roiAmount}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* TIME SPENT */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                                <Box sx={{ width: '60%' }}>
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        {t('FUND.TIME_SPENT')}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ width: '40%' }}>
                                                                    {/* DUMMY DATA */}
                                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                        2 {t('ADMIN.YEARS')}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* ROI DELIVERED */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                                <Box sx={{ width: '60%' }}>
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        ROI
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ width: '40%' }}>
                                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                        {data.roiMultiplier}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* EXIT COMPANIES */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                                <Box sx={{ width: '60%' }}>
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        {t('FUND.EXIT_COMPANIES')}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ width: '40%' }}>
                                                                    {/* DUMMY DATA */}
                                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                        {assessedProposalsCount}/{companiesAmount}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* DATES */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: 1, pt: 3 }}>
                                                                <>
                                                                    {
                                                                        startMonth !== undefined && endMonth !== undefined ?
                                                                            <Typography sx={{ fontSize: '16px', color: actualThemeLight ? '#4F4F4F' : 'white' }}>
                                                                                {t(`MONTHS.${startMonth}`)} {startDay}  - {startYear}
                                                                            </Typography> : null
                                                                    }
                                                                </>
                                                            </Box>
                                                        </Box>
                                                        :
                                                        <Box>
                                                            {/* INVESTMENT TOKEN */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: 4, pr: 4, pt: 1 }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    {data.status === 'Collecting' ?
                                                                        <>
                                                                            <Typography>
                                                                                {t('FUND_CARD.INVESTMENT_TOKEN')}
                                                                            </Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                                                $
                                                                            </Typography>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Typography>
                                                                                {t('NAVBAR.COMPANIES')}
                                                                            </Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                                                {data?.company?.length}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            {/* INVESTMENT TOKEN - END */}

                                                            {/* AMOUNT TO REACH */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: 4, pr: 4, pt: 1 }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    {data.status === 'Collecting' ?
                                                                        <>
                                                                            <Typography>{t('FUND_CARD.TO_COLLECT')}</Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                                                {data.amountToReach}
                                                                            </Typography>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Typography>{t('FUND.COLLECTED')}</Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>0</Typography>
                                                                        </>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            {/* AMOUNT TO REACH - END */}

                                                            {/* AMOUNT GATHERED */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: 4, pr: 4, pt: 1 }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}>

                                                                    {data.status === 'Collecting' ?
                                                                        <>
                                                                            <Typography>{t('FUND_CARD.AMOUNT_ACHIEVED')}</Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>0 %</Typography>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Typography>{t('OFFERER_DASHBOARD.INVESTOR')}</Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>0</Typography>
                                                                        </>
                                                                    }


                                                                </Box>
                                                            </Box>
                                                            {/* AMOUNT GATHERED - END */}

                                                            {/* VESTING */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: 4, pr: 4, pt: 1 }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <Typography>Vesting</Typography>
                                                                    <Typography sx={{ fontWeight: 'bold' }}>{data.vestingPlan}</Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* VESTING - END */}

                                                            {/* SUCCESS THRESSHOLD */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: 4, pr: 4, pt: 1 }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}>

                                                                    {data.status === 'Collecting' ?
                                                                        <>
                                                                            <Typography>{t('FUND_CARD.SUCC_THRESHOLD')}</Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>{data.successThreshold}%</Typography>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Typography>{t('FUND_CARD.VOTED')}</Typography>
                                                                            <Typography sx={{ fontWeight: 'bold' }}>0</Typography>
                                                                        </>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            {/* SUCCESS THRESSHOLD - END */}

                                                            {/* ENDS IN - PROGRESS BAR */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pl: 4, pr: 4, pt: 3 }}>
                                                                <Typography>{t('FUND_CARD.ENDS_IN')} X</Typography>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    sx={{
                                                                        mt: 1,
                                                                        height: 10,
                                                                        borderRadius: 5
                                                                    }}
                                                                    value={50}
                                                                />
                                                            </Box>
                                                            {/* ENDS IN - PROGRESS BAR - END */}
                                                        </Box>
                        }
                        {/* ENDS IN - PROGRESS BAR */}
                        {['Collecting'].includes(data.status) &&
                            <Box sx={{ ml: -1 }}>
                                <PieChart
                                    margin={{ top: 30, right: 30, left: 20, bottom: -30 }}
                                    width={380}
                                    height={120}
                                >
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={dataChartCollecting}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={60}
                                        fill="#db417b"
                                    />
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={dataChartCollectingThresshold}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={72}
                                        fill="#db417b"
                                    />
                                    <ToolChart
                                        position={{ x: 40, y: -60 }}
                                        allowEscapeViewBox={{ x: true, y: true }}
                                        content={
                                            <CustomTooltipCollectingThresshold
                                                thresshold={data?.successThreshold}
                                                raised={totalInvestment}
                                                amountToReach={data?.amountToReach}
                                            />
                                        } />
                                </PieChart>
                                <Box sx={{ width: 320, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: -3.5, ml: 3 }}>
                                    <Typography sx={{ color: "#db417b", fontSize: '10px', fontWeight: 'bold' }}>
                                        {t('FUND.COLLECTED')} / Total
                                    </Typography>
                                </Box>
                                <Box sx={{ width: 320, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: -13.4, ml: 3 }}>
                                    <Typography sx={{ color: "#db417b", fontSize: '10px', fontWeight: 'bold' }}>
                                        {t('FUND.THRESHOLD')}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        {/* {['Collecting'].includes(data.status) &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', mt: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{t('FUND.COLLECTED')}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>$ {totalInvestment.toLocaleString()}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', ml: 1, mr: 1, fontSize: '12px' }}>{t('FUND.OF')}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}> $ {(data?.amountToReach).toLocaleString()}  </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    <Typography sx={{ fontSize: '12px' }}>{t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}</Typography>
                                    <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow placement="top-start" title={`${t('FUND.COLLECTED')}: $ ${totalInvestment.toLocaleString()} / ${t('FUND.TARGET')}: $ ${data?.amountToReach?.toLocaleString()}`}>
                                        <Box sx={{ width: '50%', ml: 1, mr: 1, position: 'relative' }}>
                                            <BorderLinearProgress variant="determinate" value={(totalInvestment / data?.amountToReach) * 100} />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: `${data?.successThreshold}%`,
                                                    transform: 'translate(-50%, -50%)',
                                                    borderLeft: '2px solid #9A1D4C',
                                                    height: '100%',
                                                    zIndex: 1
                                                }}
                                            />
                                            <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow title={`${t('FUND.MIN_THRESHOLD')}: $ ${(data?.amountToReach * data?.successThreshold / 100).toLocaleString()} (${data?.successThreshold}%)`}>
                                                <Typography
                                                    sx={{
                                                        color: thresholdMet ? 'green' : '#9A1D4C',
                                                        fontSize: '20px',
                                                        fontWeight: 'bold',
                                                        position: 'absolute',
                                                        left: `${data?.successThreshold}%`,
                                                        top: '50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }}>
                                                    |
                                                </Typography>
                                            </Tool>
                                        </Box>
                                    </Tool>
                                    <Typography sx={{ fontSize: '12px' }}>{t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}</Typography>
                                </Box>
                                <Typography sx={{ fontSize: '12px' }}>{((totalInvestment / data?.amountToReach) * 100).toFixed(2)}%</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    {thresholdMet === false && <Typography sx={{ fontSize: '12px' }}>{t('DASHBOARD_VIEW.TO_REACH_THRESHOLD')}</Typography>}
                                    {thresholdMet === false ?
                                        <Typography sx={{ fontSize: '12px', ml: 1 }}>
                                            $ {(data?.amountToReach * data?.successThreshold / 100).toLocaleString() - totalInvestment.toLocaleString() || (data?.amountToReach * data?.successThreshold / 100).toLocaleString()}
                                        </Typography>
                                        :
                                        <>
                                            <Typography sx={{ fontSize: '12px', mt: 0.2 }}>
                                                {t('DASHBOARD_VIEW.THRESHOLD_REACHED')}
                                            </Typography>
                                            <CheckIcon sx={{ color: 'green', ml: 0.5 }} />
                                        </>
                                    }
                                </Box>
                            </Box>
                        } */}
                        {['Published'].includes(data.status) &&
                            <Box sx={{ ml: -4.5 }}>
                                <BarChart
                                    width={380}
                                    height={120}
                                    data={dataChartPublished}
                                    margin={{
                                        top: 1,
                                        right: 30,
                                        left: 30,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize={'8px'} dataKey="date">
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Weeks" offset={5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis fontSize={'8px'}>
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Registered Co." angle={-90} offset={34} position="insideLeft" dy={35} />
                                    </YAxis>
                                    <ToolChart position={{ x: 100, y: -70 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipWeeks />} />
                                    <Bar name='Registered Companies' dataKey="amount" fill="#db417b" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                </BarChart>
                            </Box>
                        }
                        {['Open'].includes(data.status) &&
                            <Box sx={{ ml: -4.5 }}>
                                <BarChart
                                    width={380}
                                    height={120}
                                    data={dataChartOpen}
                                    margin={{
                                        top: 1,
                                        right: 30,
                                        left: 30,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize={'8px'} dataKey="date">
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Weeks" offset={5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis fontSize={'8px'}>
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Registered Co." angle={-90} offset={34} position="insideLeft" dy={35} />
                                    </YAxis>
                                    <ToolChart position={{ x: 120, y: -70 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipWeeks />} />
                                    <Bar name='Registered Companies' dataKey="amount" fill="#db417b" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                </BarChart>
                            </Box>
                        }
                        {['Assessment'].includes(data.status) &&
                            <Box sx={{ ml: -4.5 }}>
                                <BarChart
                                    width={380}
                                    height={120}
                                    data={dataChartAssessment}
                                    margin={{
                                        top: 1,
                                        right: 30,
                                        left: 30,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize={'8px'} dataKey="date">
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Weeks" offset={5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis fontSize={'8px'}>
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Assessed Co." angle={-90} offset={34} position="insideLeft" dy={35} />
                                    </YAxis>
                                    <ToolChart position={{ x: 120, y: -70 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipWeeks />} />
                                    <Bar name='Assessed Companies' dataKey="amount" fill="#db417b" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                </BarChart>
                            </Box>
                        }
                        {['Governance'].includes(data.status) &&
                            <Box sx={{ ml: -1 }}>
                                <PieChart
                                    margin={{ top: 10, right: 30, left: 20, bottom: -30 }}
                                    width={380}
                                    height={120}
                                >
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={dataChartGovernance}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={60}
                                        fill="#db417b"
                                        label={"Votes"}
                                    />
                                    <ToolChart position={{ x: 110, y: -30 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipVoting total={totalGovernance} />} />
                                </PieChart>
                                <Typography sx={{ color: "#db417b", fontSize: '10px', fontWeight: 'bold', position: 'absolute', ml: 16.5, mt: -4.5 }}>
                                    Casted Votes / Total
                                </Typography>
                            </Box>
                        }
                        {['Vesting'].includes(data.status) &&
                            <Box sx={{ ml: -1 }}>
                                <LineChart
                                    width={380}
                                    height={120}
                                    data={dataChartVesting}
                                    margin={{
                                        top: 1,
                                        right: 50,
                                        left: 10,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize={'8px'} dataKey="date">
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Months" offset={5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis fontSize={'8px'}>
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Vested" angle={-90} offset={25} position="insideLeft" dy={25} />
                                    </YAxis>
                                    <ToolChart position={{ x: 100, y: -70 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipMonths />} />
                                    <Line type="monotone" name={'Vested ' + "(" + data?.token[0]?.symbol + ")"} dataKey="amount" stroke="#db417b" activeDot={{ r: 8 }} />
                                </LineChart>
                            </Box>
                        }
                        {['ROI'].includes(data.status) &&
                            <Box sx={{ ml: -1 }}>
                                <BarChart
                                    width={380}
                                    height={120}
                                    data={dataChartROI}
                                    margin={{
                                        top: 1,
                                        right: 60,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize={'8px'} dataKey="date">
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Months" offset={5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis fontSize={'8px'}>
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="ROI" angle={-90} offset={20} position="insideLeft" dy={5} />
                                    </YAxis>
                                    <ToolChart position={{ x: 110, y: -110 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipMonthsROI />} />
                                    <Bar dataKey="inv" stackId="a" fill="#8884d8" />
                                    <Bar dataKey="roiOverInv" stackId="a" fill="#82ca9d" />
                                </BarChart>
                            </Box>
                        }
                        {['Execution'].includes(data.status) &&
                            <Box sx={{ ml: -1 }}>
                                <LineChart
                                    width={380}
                                    height={120}
                                    data={dataChartExecution}
                                    margin={{
                                        top: 1,
                                        right: 60,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize={'8px'} dataKey="date">
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Months" offset={5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis fontSize={'8px'}>
                                        <Label fill='#db417b' fontWeight={'bold'} fontSize={'10px'} value="Valuation" angle={-90} offset={15} position="insideLeft" dy={25} />
                                    </YAxis>
                                    <ToolChart position={{ x: 100, y: -70 }} allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltipMonthsExecution />} />
                                    <Line type="monotone" name={'Executed ' + "(" + data?.token[0]?.symbol + ")"} dataKey="amount" stroke="#db417b" activeDot={{ r: 8 }} />
                                </LineChart>
                            </Box>
                        }
                    </Box>
                    {/* BUTTON KNOW MORE */}
                </Box>
            </Box>
            <Box sx={{ width: '100%', position: 'relative', justifySelf: 'flex-end', mb: 1.8 }}>
                {
                    data?.status !== "Collecting" && data?.status !== "Open" &&
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <Button
                            onClick={handleClick}
                            variant='contained'
                            sx={{
                                width: '50%'
                            }}>{t('FUND_CARD.LEARN_MORE')}</Button>
                    </Box>
                }
                {(locStorageRole === 'role-applicant' && data?.status === 'Collecting') ?
                    <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.INVEST_BUTTON_HELPER')}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, width: '100%' }}>
                            <Button sx={{ width: '50%' }} disabled variant="contained" >
                                <Typography>
                                    {t('FUND.INVEST')}
                                </Typography>
                            </Button>
                        </Box>
                    </Tool>
                    :
                    data?.status === 'Collecting' ?
                        <>
                            {
                                amountToReach > totalInvestment && daysLeft >= 1
                                    ?
                                    <Box onClick={() => {
                                        setModalInvest(true)
                                    }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, width: '100%' }}>
                                        <Button sx={{ width: '50%' }} variant="contained" >
                                            <Typography>
                                                {t('FUND.INVEST')}
                                            </Typography>
                                        </Button>
                                    </Box>
                                    :
                                    daysLeft <= 0 ? null :
                                        <Box onClick={() => {
                                            setModalWaitingList(true)
                                        }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <Button sx={{ width: '50%' }} variant="contained" >
                                                <Typography>
                                                    {t('DASHBOARD.WAITING_LIST')}
                                                </Typography>
                                            </Button>
                                        </Box>
                            }
                        </>
                        : null
                }
                {(locStorageRole === 'role-investor' && data?.status === 'Open') ?
                    <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.APPLY_BUTTON_HELPER')}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, width: '100%' }}>
                            <Button
                                sx={{ width: '50%' }} disabled variant="contained"
                            >
                                <Typography sx={{ color: 'white' }}>
                                    {t('CREATE_COMPANY.APPLY')}
                                </Typography>
                            </Button>

                        </Box>
                    </Tool>
                    :
                    data?.status === 'Open' && locStorageRole === 'role-applicant' && daysLeft >= 1 ?
                        <Grid item lg={12} xs={12}
                            sx={{
                                display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: 4, mt: { md: 0, xs: 0 }
                            }}>
                            <Button
                                onClick={() => {
                                    dispatch(push(`/postulate/${data?.id}`))
                                }}
                                sx={{ width: '50%' }}
                                variant="contained"
                            >
                                <Typography sx={{ color: 'white' }}>
                                    {t('CREATE_COMPANY.APPLY')}
                                </Typography>
                            </Button>
                        </Grid> : null
                }
                {
                    modalInvest && (
                        <InvestModal
                            data={data}
                            open={modalInvest}
                            handleClose={handleCloseInvest}
                            raised={totalInvestment}
                            target={data?.amountToReach}
                            minInvest={data?.minInvestment}
                            maxInvest={data?.maxInvestment}
                            token={data?.token[0]}
                            txFundId={data?.id}
                            scData={data?.smartContract}
                        />
                    )
                }
                {
                    modalWaitingList && (
                        <WaitingListModal
                            data={data}
                            open={modalWaitingList}
                            handleClose={handleCloseWaitingList}
                            raised={totalInvestment}
                            target={data?.amountToReach}
                            token={data?.token[0]}
                            minInvest={data?.minInvestment}
                            maxInvest={data?.maxInvestment}
                            txFundId={data?.id}
                            scData={data?.smartContract}
                        />
                    )
                }
            </Box>
        </Card >
    )
}

export default FundCard