import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Badge,
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useCallback, useEffect, useState } from 'react'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { login } from '../../store/actions/authActions'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from '../../Queries/Notifications'
import { GET_USER_INFO, UPDATE_PHOTO } from '../../Queries/Users'
import { setViewPreference } from '../../store/actions/perfilActions'
import Style from './Navbar.module.css'
import moment from 'moment'

function BottomNavbar() {
  const dispatch = useDispatch()

  const {
    loginWithRedirect,
    user,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0()
  const { t } = useTranslation()
  // eslint-disable-next-line
  const [viewOptions, setViewOptions] = React.useState(null)

  const gridStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const [getUserData, { data: userData, loading }] = useLazyQuery(
    GET_USER_INFO,
    {
      variables: { email: user?.email || user?.sub },
    }
  )

  const [updatePhoto] = useMutation(UPDATE_PHOTO)

  useEffect(() => {
    if (
      !loading &&
      !isLoading &&
      user?.picture !== userData?.users[0]?.profilePicture
    ) {
      updatePhoto({
        variables: {
          id: user.email,
          profilePicture: user.picture,
        },
      })
    }
  }, [user, userData])

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS)
  //! CHANGE ID!
  const { data: notificationData } = useQuery(GET_NOTIFICATIONS, {
    pollInterval: 10000,
    variables: {
      userID: user?.email || user?.sub,
    },
  })

  const showInvestorOrOffererView = localStorage.getItem(
    'showInvestorOrOffererView'
  )
  const [viewTypeToShow, setViewTypeToShow] = React.useState(
    showInvestorOrOffererView
  )

  const [notificationsState, setNotificationsState] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)

  const getAccessToken = useCallback(async () => {
    if (!isLoading) {
      try {
        const token = await getAccessTokenSilently()
        dispatch(login(token, user))
        //dispatch(push('/profile-settings'))
      } catch (err) {
        dispatch(push('/'))
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect, dispatch, isLoading])

  const handleOpenNotifications = async () => {
    const notificationsIds = []
    setOpenNotifications(openNotifications ? false : true)
    notificationsState.forEach((notification) => {
      if (notification.read == false) {
        notificationsIds.push(notification.id)
      }
    })
    // eslint-disable-next-line
    const response = await updateNotifications({
      variables: {
        ids: notificationsIds,
      },
    })
    setUnreadNotifications(false)
  }

  useEffect(() => {
    if (notificationData !== undefined && !loading) {
      setNotificationsState(notificationData?.userNotifications)
      const unreadExists = notificationData?.userNotifications.some(
        (notification) => notification.read == false
      )
      setUnreadNotifications(unreadExists)
    }
  }, [notificationData, loading, unreadNotifications])

  useEffect(() => {
    setViewTypeToShow(showInvestorOrOffererView)
  }, [userData])

  useEffect(() => {
    if (!isLoading) {
      getAccessToken()
      getUserData()
    }
  }, [getAccessToken, isLoading, user, loading, getUserData])

  useEffect(() => {
    if (showInvestorOrOffererView == null) {
      if (
        userData?.users[0]?.has[0]?.roleName !== undefined &&
        userData?.users[0]?.has[0]?.roleName === null
      ) {
        localStorage.setItem(
          'showInvestorOrOffererView',
          userData?.users[0]?.has[0]?.roleName
        )
        setViewTypeToShow(userData?.users[0]?.has[0]?.roleName)
      }
    }
    if (userData?.users[0]?.has[0]?.roleName === 'Investor & Offerer') {
      setViewOptions(['Investor', 'Offerer'])
    } else if (userData?.users[0]?.has[0]?.roleName === 'Investor') {
      setViewOptions(['Investor'])
    } else if (userData?.users[0]?.has[0]?.roleName === 'Offerer') {
      setViewOptions(['Offerer'])
    }
  }, [userData?.users[0]?.has[0]?.roleName])

  useEffect(() => {
    if (viewTypeToShow !== undefined && viewTypeToShow !== null) {
      localStorage.setItem('showInvestorOrOffererView', viewTypeToShow)
      dispatch(setViewPreference(viewTypeToShow))
    }
  }, [viewTypeToShow])

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <Grid
      container
      sx={{
        height: '75px',
        backgroundColor: actualThemeLight ? '#EFEFEF' : '#1D1D1D',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000000,
      }}
    >
      <Grid item xs={6} sx={gridStyle}>
        <EmailOutlinedIcon
          color="pink"
          onClick={() => {
            dispatch(push('/messages/messages'))
          }}
          sx={{
            width: '30px',
            height: '30px',
            cursor: 'pointer !important',
          }}
        />
      </Grid>
      <Grid item xs={6} sx={gridStyle}>
        <ClickAwayListener onClickAway={() => setOpenNotifications(false)}>
          <Box>
            <Tooltip title={t('NAVBAR.NOTIFICATIONS')}>
              <IconButton
                id="navbar-notifications-btn"
                onClick={() => handleOpenNotifications()}
              >
                {unreadNotifications ? (
                  <Badge
                    color="error"
                    aria-label={`new notifications`}
                    badgeContent={1}
                    overlap="circular"
                    max={99}
                    variant="dot"
                  >
                    <NotificationsNoneIcon
                      color="pink"
                      sx={{ fontSize: '32px', color: 'pink' }}
                    />
                  </Badge>
                ) : (
                  <NotificationsNoneIcon
                    color="pink"
                    sx={{ fontSize: '32px', color: 'pink' }}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Modal
              open={openNotifications}
              onClose={() => setOpenNotifications(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowY: 'scroll' }}
            >
              <Paper
                className={Style.notificationModal}
                sx={{
                  padding: '1rem',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: 'var(--primaryRed)',
                    }}
                  >
                    {t('NAVBAR.NOTIFICATIONS')}
                  </Typography>
                  <IconButton onClick={() => setOpenNotifications(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {notificationsState?.length > 0 ? (
                  notificationsState.map((notification, index) => {
                    const dateToShow = moment(
                      notification.createdOn.toLocaleString()
                    ).fromNow()
                    const notif = notification.message.split('-')
                    const notifReason =
                      notification.message.split('*')[1]
                    let notifMSG = notif[0].trim()

                    let notifFinalMSG
                    switch (notifMSG) {
                      case 'MSG':
                        notifFinalMSG = t('NOTIFICATIONS.MESSAGE')
                        break
                      case 'RDYDEPOSIT':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.RDYDEPOSIT'
                        )
                        break
                      case 'TARGETMET':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.TARGETMET'
                        )
                        break
                      case 'COLLOVER':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.COLLOVER'
                        )
                        break
                      case 'RDYWITHDRAW':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.RDYWITHDRAW'
                        )
                        break
                      case 'PROP':
                        notifFinalMSG = t('NOTIFICATIONS.PROPOSAL')
                        break
                      case 'ACCPROP':
                        notifFinalMSG = t('NOTIFICATIONS.PROP_ACCEPTED')
                        break
                      case 'OFFER':
                        notifFinalMSG = t('NOTIFICATIONS.OFFER')
                        break
                      case 'COUNTEROFFER':
                        notifFinalMSG = t('NOTIFICATIONS.COUNTEROFFER')
                        break
                      case 'ACCPUROFF':
                        notifFinalMSG = t('NOTIFICATIONS.ACCPUROFF')
                        break
                      case 'COUNTER_ACC':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.COUNTER_ACC'
                        )
                        break
                      case 'PAYED':
                        notifFinalMSG = t('NOTIFICATIONS.PAYED')
                        break
                      case 'COUNTER_REJ':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.COUNTER_REJ'
                        )
                        break
                      case 'REJECTION':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.REJECTION'
                        )
                        break
                      case 'DEPNOTCONFIRMED':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.DEPNOTCONFIRMED'
                        )
                        break
                      case 'INVCANCEL':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.INVCANCEL'
                        )
                        break
                      case 'CONGRATS':
                        notifFinalMSG = t('NOTIFICATIONS.CONGRATS')
                        break
                      case 'CLOSEEXE':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.CLOSEEXE'
                        )
                        break
                      case 'PAYOUTEXE':
                        notifFinalMSG = t(
                          'NOTIFICATIONS.PAYOUTEXE'
                        )
                        break
                    }
                    return (
                      <Box
                        sx={
                          notification?.read == false
                            ? {
                              color: 'white',
                              backgroundColor:
                                'var(--primaryRed)  !important',
                            }
                            : {}
                        }
                        className={Style.eachNotif}
                        key={index}
                        onClick={() => dispatch(push(notification.link))}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          {notif[1]?.split('*')[0]}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {notifFinalMSG}{' '}
                          {notifReason
                            ? `- ${notifReason}`
                            : null}
                        </Typography>
                        <Box
                          sx={{
                            position: 'absolute',
                            right: '5px',
                            bottom: '0',
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 400,
                              fontSize: '12px',
                              color: 'var(--primaryGray)',
                            }}
                          >
                            {dateToShow}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  })
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ color: 'var(--primaryBlue)' }}
                  >
                    No new notifications
                  </Typography>
                )}
              </Paper>
            </Modal>
          </Box>
        </ClickAwayListener>
      </Grid>
    </Grid>
  )
}

export default BottomNavbar
