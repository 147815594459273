import React, { useEffect, useRef, useState } from 'react'
import useStyles from '../MessagesComponent/style'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_ALL_USERS, SEND_MESSAGE } from '../../Queries/Messages'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { fetchUTCTime } from '../../utils/utcTime'
//import { useUtcTime } from '../../utils/utcTime'
import { GET_USER_INFO } from '../../Queries/Users'
import { useAuth0 } from '@auth0/auth0-react'
import { CREATE_NOTIFICATION } from '../../Queries/Notifications'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DirectMessageModal = ({
  open,
  handleClose,
  targetUser,
  targetUsername,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth0()

  const {
    // eslint-disable-next-line
    data: userList,
    // eslint-disable-next-line
    loading: userListLoading,
    // eslint-disable-next-line
    error: errorLoading,
  } = useQuery(GET_ALL_USERS)
  // eslint-disable-next-line
  const [targetUserId, setTargetUserId] = useState(targetUser)
  const [textToSend, setTextToSend] = useState('')
  const [messageSending, setMessageSending] = useState(false)
  const [sendMessageMutation] = useMutation(SEND_MESSAGE)
  const [createNotification] = useMutation(CREATE_NOTIFICATION)
  const [getUserData, { data: userData }] = useLazyQuery(GET_USER_INFO, {
    variables: { email: user?.email || user?.sub },
  })

  useEffect(() => {
    getUserData()
  }, [user, getUserData])

  const handleTextChange = (event) => {
    setTextToSend(event.target.value)
  }

  const closeModal = () => {
    handleClose()
  }
  const classes = useStyles()
  const resetRef = useRef()
  //const utcTime = useUtcTime()
  const myuuid = uuidv4()
  const dateTime = moment.utc()


  const sendMessage = async () => {
    try {
      const utcTime = await fetchUTCTime()
      if (textToSend !== '') {
        setMessageSending(true)
        await sendMessageMutation({
          variables: {
            id: myuuid,
            on: dateTime,
            onUTC: utcTime,
            text: textToSend,
            read: false,
            deleted: false,
            userId: userData?.users[0]?.id,
            messageId: myuuid,
            otherUserId: targetUserId,
          },
        })
        const notifID = uuidv4()
        await createNotification({
          variables: {
            notificationID: notifID,
            msg: `MSG - ${userData?.users[0]?.userName}`,
            date: dateTime,
            link: '/messages',
            userID: targetUserId,
          },
        })
        setMessageSending(false)
        handleClose()
      }
    } catch (err) {
      //Err
    }
  }

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current()
      }
    }
  }, [resetRef, open])

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent style={{ marginBottom: '16px' }}>
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: '14px', md: '18px', lg: '20px' },
                fontFamily: 'Quicksand, sans-serif',
                lineHeight: '20px',
                fontWeight: 'bold',
                color: '#9A1D4C',
              }}
            >
              {t('MESSAGES.SEND_MESSAGE')}
            </Typography>
            <Box sx={{ position: 'absolute', mr: -60 }}>
              <IconButton>
                <CloseIcon onClick={closeModal} />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderTop: '1px solid var(--borderGray)',
              borderBottom: '1px solid var(--borderGray)',
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: '12px', md: '16px', lg: '18px' },
                fontFamily: 'Quicksand, sans-serif',
                lineHeight: '20px',
                fontWeight: 'bold',
                color: '#9A1D4C',
              }}
            >
              {t('MESSAGES.FOR')}
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: '12px', md: '16px', lg: '18px' },
                fontFamily: 'Quicksand, sans-serif',
                lineHeight: '20px',
                width: '400px',
                ml: 2,
                fontWeight: 'bold',
                color: '#9A1D4C',
              }}
            >
              {targetUserId}
              <Typography
                sx={{
                  fontSize: { sm: '8px', md: '10px', lg: '12px' },
                  fontFamily: 'Quicksand, sans-serif',
                  lineHeight: '20px',
                  color: '#9A1D4C',
                }}
              >
                {`(${targetUsername})`}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              sx={{ width: '100%' }}
              multiline
              rows={6}
              id="outlined-basic"
              variant="outlined"
              placeholder={t('MESSAGES.PLACEHOLDER')}
              value={textToSend}
              onChange={handleTextChange}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '5%',
            }}
          >
            {!targetUserId || textToSend === '' ? (
              <Button
                disabled
                sx={{
                  width: '165px',
                  border: '3px solid  #DB417B ',
                  borderRadius: '7px',
                  color: '#828282',
                }}
                onClick={sendMessage}
              >
                {t('MESSAGES.SEND')}
              </Button>
            ) : (
              <Button
                sx={{
                  width: '165px',
                  border: '3px solid  #DB417B ',
                  borderRadius: '7px',
                  color: '#828282',
                }}
                onClick={sendMessage}
              >
                {t('MESSAGES.SEND')}
              </Button>
            )}
          </Box>
          {messageSending && <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress />
          </Box>}
        </>
      </DialogContent>
    </Dialog>
  )
}

export default DirectMessageModal
