import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as KeyboardArrowDown } from '../../assets/icons/keyboard_arrow_down.svg'
import { Button, Menu, MenuItem, useTheme } from '@mui/material'

const I18nMenu = () => {
  const { t, i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const changeLanguage = useCallback(
    (lng) => () => {
      i18n.changeLanguage(lng)
      localStorage.setItem('lang', lng)
      handleClose()
    },
    [i18n, handleClose]
  )

  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
      <Button
        color="inherit"
        aria-label="lang"
        aria-controls="lang-menu"
        aria-haspopup="true"
        size="small"
        endIcon={
          <KeyboardArrowDown fill={theme.palette.text.primary} sx={{ pt: 1 }} />
        }
        onClick={handleClick}
        sx={{
          fontVariantCaps: 'all-small-caps',
          textTransform: 'uppercase',
          fontSize: '20px',
          display: 'flex',
          color: actualThemeLight ? 'black' : 'white',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          pt: -1,
          mt: -1,
        }}
      >
        {t('DASHBOARD.LANG_ACRONYM')}
      </Button>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          color="black"
          selected={i18n.language === 'en'}
          onClick={changeLanguage('en')}
        >
          English
        </MenuItem>
        <MenuItem
          color="black"
          selected={i18n.language === 'es'}
          onClick={changeLanguage('es')}
        >
          Español
        </MenuItem>
        <MenuItem
          color="black"
          selected={i18n.language === 'pt'}
          onClick={changeLanguage('pt')}
        >
          Portugués
        </MenuItem>
      </Menu>
    </div>
  )
}

export default I18nMenu
