import React, { useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ADMIN_GET_ALL_TRANSACTIONS } from '../../../Queries/Admin';
import { useLazyQuery } from '@apollo/client';
import IndividualTransactionLog from './IndividualTransactionLog';
import Preloader from '../../preloader/Preloader';
import { Box, Typography, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';


const TransactionLog = () => {

    const { t } = useTranslation()

    const [getAllTransactions, { data: transactionData, loading: loadingTransactionData }] = useLazyQuery(ADMIN_GET_ALL_TRANSACTIONS,
        {
            pollInterval: 1500
        })

    useEffect(() => {
        getAllTransactions()
    }, [getAllTransactions])

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (loadingTransactionData) return <Preloader />

    return (
        <>
            <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', ml: 4 }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        {t('ADMIN.TRANSACTION_LOG')}
                    </Typography>
                </Box>
                <TableContainer sx={{ width: '100%', height: '75vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center', mt: 2 }}>
                    <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align='center'>{t('ADMIN.NEW_EVENT_DATE')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('GENERAL.USER')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Type</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Hash</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('WALLET_VIEW.SYMBOL')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Wallet</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('WALLET_VIEW.AMOUNT')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactionData?.transactions?.map((row) => (
                                <IndividualTransactionLog key={row?.id} data={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default TransactionLog