import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import i18n from "i18next";
import { useState } from 'react';
import { useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'

const IndividualEvent = ({ data }) => {
    const selectedLanguage = i18n.language
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const [color, setColor] = useState(undefined)

    useEffect(() => {
        if (formattedCurrentDate >= data?.startDate?.split('T')[0] && formattedCurrentDate <= data?.endDate?.split('T')[0]) {
            setColor('#26c418')
        } else if (formattedCurrentDate > data?.endDate?.split('T')[0]) {
            setColor('gray')
        } else {
            setColor('#f08a05')
        }
    }, [data])

    const handleClickLink = () => {
        dispatch(push(`/fund-detail/${data?.fund?.id}`))
    }

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            m: 2,
            backgroundColor: actualThemeLight ? "white" : '#1D1D1D',
            border: '1px solid black',
            borderRadius: '15px',
            p: 1
        }}>
            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>
                {selectedLanguage === 'en' ? data?.titleEn :
                    selectedLanguage === 'es' ? data?.titleEs :
                        selectedLanguage === 'pt' && data?.titlePt}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                {selectedLanguage === 'en' ? data?.detailEn :
                    selectedLanguage === 'es' ? data?.detailEs :
                        selectedLanguage === 'pt' && data?.detailPt}
            </Typography>
            {data.endDate === null ?
                <Box sx={{ backgroundColor: color, p: 0.3, borderRadius: '25px' }}>
                    <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '14px' }}>
                        {data.startDate.split('T')[0]}
                    </Typography>
                </Box>

                :
                <Box sx={{ backgroundColor: color, pl: 2, pr: 2, borderRadius: '25px' }}>
                    <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '14px' }}>
                        {data.startDate.split('T')[0]} / {data.endDate.split('T')[0]}
                    </Typography>
                </Box>
            }
            {data?.fund !== undefined && data?.fund !== null ? <Button sx={{ mt: 1 }} variant='contained' onClick={handleClickLink} >{t('DASHBOARD_VIEW.GO_TO_FUND')}  <OpenInNewIcon fontSize='small' /></Button> : null}
        </Box>
    )
}

export default IndividualEvent