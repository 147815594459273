import React from 'react'
import { Box, Link, TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

const IndividualTransactionLog = ({ data }) => {

    const { t } = useTranslation()
    const Day = data?.date?.split('-')[2].split('T')[0]
    const Month = data?.date?.split('-')[1]
    const isCoreTeam = data?.type?.includes('Core Team')
    const isDeposit = data?.type?.includes('Deposit')
    const isWithdraw = data?.type?.includes('Withdraw')

    function replaceHash(inputString) {
        var resultString = encodeURIComponent(inputString);
        return resultString;
    }

    function extractAfterWithdrawals(inputString) {
        const keyword = 'withdrawals/';

        if (inputString?.includes(keyword)) {
            const index = inputString?.indexOf(keyword);
            return inputString?.substring(index + keyword?.length);
        }
        return inputString;
    }

    const pureHash = extractAfterWithdrawals(data?.hash)


    var smartContractIdURI = replaceHash(data?.offerPurchase?.smartContract?.contractId);
    const depositTransactionUrl = `https://preprod.marlowescan.com/contractView?tab=tx&contractId=${smartContractIdURI}&transactionId=${pureHash}`
    const withdrawTransactionUrl = `https://preprod.cardanoscan.io/transaction/${pureHash}`

    return (
        <TableRow
            key={data.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell sx={{ fontSize: '12px' }} align="center">
                <Box>
                    {t(`MONTHS.${Month}`).substring(0, 3)}. {Day}
                </Box>
                <Box>
                    {data?.date?.split('T')[1]?.split('.')[0]}
                </Box>
            </TableCell>
            <TableCell sx={{ fontSize: '12px' }} align="center">{data?.user?.id}</TableCell>
            <TableCell sx={{ fontSize: '12px' }} align="center">{data?.type}</TableCell>
            {isDeposit && <TableCell align="center" sx={{ wordBreak: 'break-all', fontSize: '12px' }}>
                <Link
                    href={depositTransactionUrl}
                    sx={{ cursor: 'pointer' }}
                    target={{ _blank: true }}
                >
                    {pureHash}
                </Link>
            </TableCell>}
            {isWithdraw && <TableCell align="center" sx={{ wordBreak: 'break-all', fontSize: '12px' }}>
                <Link
                    href={withdrawTransactionUrl}
                    sx={{ cursor: 'pointer' }}
                    target={{ _blank: true }}
                >
                    {pureHash}
                </Link>
            </TableCell>}
            <TableCell align="center" sx={{ fontSize: '12px' }}>{data?.offerPurchase?.fund?.name}</TableCell>

            <TableCell align="center" sx={{ wordBreak: 'break-all', fontSize: '12px' }}>{data?.offerPurchase?.investorAddress}</TableCell>


            {isDeposit && <TableCell sx={{ fontSize: '12px' }} align="center">{data?.amount} {isCoreTeam ? 'tstAlli' : data?.offerPurchase?.fund?.token[0]?.symbol}</TableCell>}
            {isWithdraw && <TableCell sx={{ fontSize: '12px' }} align="center">{data?.amount} {isCoreTeam ? data?.offerPurchase?.fund?.token[0]?.symbol : 'tstAlli'}</TableCell>}
            <TableCell sx={{ fontSize: '12px' }} align="center">{data?.status}</TableCell>
        </TableRow>
    )
}

export default IndividualTransactionLog