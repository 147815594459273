import { gql } from '@apollo/client'

export const GET_ALL_USER_TRANSACTIONS = gql`
  query ($userId: ID!) {
    offerPurchases(
      where: { users: { id: $userId } }
      options: { sort: { on: DESC } }
    ) {
      id
      on
      investorAddress
      lastUpdate
      status
      amount
      transaction {
        id
        amount
        type
        user {
          id
        }
      }
      fund {
        id
        name
        image
        status
        roiTime
        amountToReach
        event {
          id
          titleEn
          startDate
          endDate
        }
        token {
          id
          logo
          symbol
          maxCap
          policyId
          description
          marketprice
          encodedName
          fingerprint
        }
      }
      smartContract {
        status
        cborHex
        creator
        contractId
        id
        createdDate
        contractLink
      }
    }
  }
`

export const GET_PURE_USER_TRANSACTIONS = gql`
  query ($userId: ID!) {
    transactions(
      where: {
        AND: [
          { user: { id: $userId } }
          { NOT: { type: "Deposit (Core Team)" } }
          { NOT: { type: "Withdraw (Core Team)" } }
        ]
      }
      options: { sort: { date: DESC } }
    ) {
      id
      date
      type
      hash
      status
      amount
      offerPurchase {
        id
        status
        smartContract {
          id
          contractId
          contractLink
        }
        fund {
          id
          name
          token {
            id
            symbol
          }
        }
      }
      user {
        id
      }
    }
  }
`

export const GET_ALL_MY_TXS = gql`
  query ($userId: ID) {
    offerPurchases(
      where: {
        AND: [
          { users: { id: $userId } }
          { NOT: { status: "Investment Intention" } }
          { NOT: { status: "Investment Cancelled" } }
          { NOT: { status: "SC Created" } }
          { NOT: { status: "SC Created - ALLI Deposited" } }
          { NOT: { status: "SC Created - ALLI Deposited - Pending" } }
          { NOT: { status: "SC Timed Out - Core Withdrawn" } }
          { NOT: { status: "Investment Cancelled - Target Met" } }
          { NOT: { status: "Investment Cancelled - Collecting Ended" } }
          { NOT: { status: "Investment Rejected - SC Not Created" } }
          { NOT: { status: "Investor Deposited - Pending" } }
        ]
      }
    ) {
      id
      fund {
        name
        roiMultiplier
        roiTime
      }
      amount
      status
    }
  }
`

export const CREATE_TRANSACTION_USER = gql`
  mutation (
    $id: ID!
    $date: DateTime!
    $type: String!
    $status: String!
    $hash: String!
    $amount: Float!
    $userId: ID!
    $offPurId: ID!
  ) {
    createTransactions(
      input: {
        id: $id
        date: $date
        type: $type
        status: $status
        hash: $hash
        amount: $amount
        user: { connect: { where: { node: { id: $userId } } } }
        offerPurchase: { connect: { where: { node: { id: $offPurId } } } }
      }
    ) {
      transactions {
        id
      }
    }
  }
`

export const UPDATE_TRANSACTION_STATUS = gql`
  mutation ($txId: ID!, $status: String!) {
    updateTransactions(where: { id: $txId }, update: { status: $status }) {
      transactions {
        id
      }
    }
  }
`

export const CREATE_TRANSACTION_CORE_TEAM = gql`
  mutation (
    $id: ID!
    $date: DateTime!
    $type: String!
    $status: String!
    $amount: Float!
    $hash: String!
    $userId: ID!
    $offPurId: ID!
  ) {
    createTransactions(
      input: {
        id: $id
        date: $date
        type: $type
        status: $status
        amount: $amount
        hash: $hash
        user: { connect: { where: { node: { id: $userId } } } }
        offerPurchase: { connect: { where: { node: { id: $offPurId } } } }
      }
    ) {
      transactions {
        id
      }
    }
  }
`
