import { gql } from '@apollo/client'

export const GET_ALL_PREFERENCES = gql`
  query {
    preferences(
      where: { isActive: true }
      options: { sort: { preferenceName: ASC } }
    ) {
      id
      preferenceName
    }
  }
`

export const ADD_ROLE_USER = gql`
  mutation ($userId: ID!, $roleId: ID!) {
    updateUsers(
      where: { id: $userId }
      connect: { has: { where: { node: { id: $roleId } } } }
    ) {
      users {
        id
      }
    }
  }
`

export const REMOVE_ROLES_USER = gql`
  mutation ($userId: ID!) {
    updateUsers(
      where: { id: $userId }
      disconnect: {
        has: { where: { node: { id_IN: ["role-investor", "role-applicant"] } } }
      }
    ) {
      users {
        id
      }
    }
  }
`

export const ADD_PREFERENCES_USER = gql`
  mutation ($userId: ID, $preferenceId: [ID!]!) {
    updateUsers(
      where: { id: $userId }
      connect: { prefers: { where: { node: { id_IN: $preferenceId } } } }
    ) {
      users {
        id
      }
    }
  }
`

export const REMOVE_USER_PREFERENCE = gql`
  mutation ($email: ID, $preferenceId: ID!) {
    updateUsers(
      where: { id: $email }
      disconnect: { prefers: { where: { node: { id: $preferenceId } } } }
    ) {
      users {
        id
      }
    }
  }
`

export const ADD_SOCIAL_NETWORK_USER = gql`
  mutation ($id: ID!, $link: String!, $name: String!, $userId: ID!) {
    createUrls(
      input: {
        id: $id
        link: $link
        name: $name
        user: { connect: { where: { node: { id: $userId } } } }
      }
    ) {
      urls {
        id
      }
    }
  }
`

export const UPDATE_SOCIAL_NETWORK_USER = gql`
  mutation ($urlID: ID!, $newLink: String!) {
    updateUrls(where: { id: $urlID }, update: { link: $newLink }) {
      urls {
        id
      }
    }
  }
`

export const ADD_USER_BIO = gql`
  mutation ($userId: ID!, $bio: String!) {
    updateUsers(where: { id: $userId }, update: { bio: $bio }) {
      users {
        id
      }
    }
  }
`

export const GET_ALL_LANGUAGES = gql`
  query {
    languages {
      id
      code
      description
    }
  }
`

export const ADD_LANGUAGES_USER = gql`
  mutation ($userId: ID, $languageId: [ID!]!) {
    updateUsers(
      where: { id: $userId }
      connect: { speaks: { where: { node: { id_IN: $languageId } } } }
    ) {
      users {
        id
      }
    }
  }
`

export const REMOVE_USER_LANGUAGE = gql`
  mutation ($userId: ID, $languageId: ID!) {
    updateUsers(
      where: { id: $userId }
      disconnect: { speaks: { where: { node: { id: $languageId } } } }
    ) {
      users {
        id
      }
    }
  }
`
