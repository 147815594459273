import React, { useState } from 'react'
import { Box, Button, Grid, InputAdornment, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { showMessage } from '../../../store/actions/snackBarActions'
import { CREATE_VESTING_EVENT, GET_FUNDS_VESTING } from '../../../Queries/Admin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { goBack } from 'connected-react-router'



const NewVestingEvent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [createVestingEvent] = useMutation(CREATE_VESTING_EVENT)
    const [getFundsVesting, { data: vestingFunds, loading: loadingFunds }] = useLazyQuery(GET_FUNDS_VESTING)
    const [selectedFund, setSelectedFund] = useState('')
    const [indexSelected, setIndexSelected] = useState(undefined)
    const [selectedProposal, setSelectedProposal] = useState('')
    const uniqueID = uuidv4()
    const [vestingAmount, setVestingAmount] = useState('')
    const todayDate = new Date().toISOString().split('T')[0]

    useEffect(() => {
        getFundsVesting()
    }, [getFundsVesting])

    const handlePublish = async () => {
        await createVestingEvent({
            variables: {
                vestingId: uniqueID,
                vestingAmount: Number(vestingAmount),
                vestingDate: todayDate,
                fundId: selectedFund,
                proposalId: selectedProposal
            }
        })
        await dispatch(push('/admin-vesting-event'))
        await dispatch(showMessage({ message: 'Vesting Event created!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 6
            }}>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "left"
                    }}>
                    <Box
                        onClick={handleOpenConfirmation}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                        <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                    </Box>
                    <Box sx={{ ml: 4 }}>
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}>
                            {t('ADMIN.NEW_VESTING')}
                        </Typography>
                    </Box>
                </Box>
            </Grid >
            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '60%'
                }}>

                <Typography>
                    {t('ADMIN.VESTING_EVENT_AMOUNT')}
                </Typography>
                <TextField size='small'
                    type='number'
                    value={vestingAmount}
                    sx={{ width: '300px' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                    }}
                    onChange={(e) => setVestingAmount(e.target.value)}
                />
            </Grid>

            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 2,
                    width: '60%'
                }}>
                <Typography>
                    {t('ADMIN.FUND')}
                </Typography>
                {
                    !loadingFunds && (
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedFund}
                            size='small'
                            sx={{ width: '300px' }}
                            onChange={(event) => {
                                const selectedIndex = event.target.value;
                                const index = vestingFunds?.funds?.findIndex((fund) => fund.id === selectedIndex);
                                setSelectedFund(selectedIndex);
                                setIndexSelected(index);
                            }}
                        >
                            {vestingFunds?.funds?.map((fund) => {
                                const name = fund.name;
                                const id = fund.id;
                                return (<MenuItem key={fund.id} value={id}>{name}</MenuItem>);
                            })
                            }
                        </Select>
                    )
                }
            </Grid>

            {indexSelected !== undefined &&
                <Grid container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: 2,
                        width: '60%'
                    }}>
                    <Typography>
                        {t('ADMIN.PROPOSAL_ADMINISTRATION')}
                    </Typography>
                    {!loadingFunds && (
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedProposal}
                            size='small'
                            sx={{ width: '300px' }}
                            onChange={(event) => {
                                setSelectedProposal(event.target.value);
                            }}
                        >
                            {vestingFunds?.funds[indexSelected]?.proposal.map((prop) => {
                                const name = prop.titleEn;
                                const id = prop.id;
                                return (<MenuItem key={prop.id} value={id}>{name}-{prop.company.coName}</MenuItem>);
                            })
                            }
                        </Select>
                    )}
                </Grid>
            }

            {
                selectedFund === '' ||
                    indexSelected === undefined ||
                    selectedProposal === '' ||
                    vestingAmount === ''
                    ?
                    <Button
                        sx={{ m: 3 }}
                        disabled variant='contained'>
                        {t('CO_ASSESSMENT.SAVE')}
                    </Button> :
                    <Button
                        sx={{ m: 3 }}
                        onClick={handlePublish}
                        variant='contained'>
                        {t('CO_ASSESSMENT.SAVE')}
                    </Button>
            }
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleConfirm}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                        {t('MY_OFFERINGS.CHANGES_LOST')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            mt: 2,
                        }}
                    >
                        <Button variant="outlined" onClick={handleCloseConfirmation}>
                            {t('CO_ASSESSMENT.CANCEL')}
                        </Button>
                        <Button variant="contained" onClick={() => dispatch(goBack())}>
                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}

export default NewVestingEvent