import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { showMessage } from '../../../store/actions/snackBarActions'
import { useDispatch } from 'react-redux'
import { Tooltip } from '@mui/material'



const CopyToClipboardButton = ({ text, title }) => {
    const dispatch = useDispatch()

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(text);
            dispatch(showMessage({ message: 'Copied', variant: 'success' }))
        } catch (err) {
            //Err
        }
    };

    return (
        <button
            onClick={copyToClipboard}
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '13px',
            }}
        >
            <Tooltip title={title}>
                <ContentCopyIcon fontSize='small' sx={{ color: '#9A1D4C' }} />
            </Tooltip>
        </button>
    );
};

export default CopyToClipboardButton;
