import { gql } from '@apollo/client'

export const GET_ASSESSED_PROPOSALS = gql`
  query ($userId: ID!) {
    proposals(
      where: {
        AND: [
          { company: { users_SOME: { id: $userId } } }
          { status: "assessed" }
        ]
      }
    ) {
      id
      reqInvestAmount
      status
      fund {
        name
        roiTime
        roiMultiplier
      }
    }
  }
`

export const GET_ALL_PROPOSALS_NOT_DRAFT = gql`
  query ($userId: ID!) {
    proposals(
      where: {
        AND: [
          { company: { users_SOME: { id: $userId } } }
          { NOT: { status: "draft" } }
        ]
      }
    ) {
      id
      reqInvestAmount
      status
    }
  }
`
