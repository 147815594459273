import React from 'react'
import Messages from '../../components/MessagesComponent/Messages'
import { Box } from '@mui/material'

const MessagesView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifySelf: 'center',
        width: { xs: '100%', md: '90%' },
        pt: '2%',
        pl: { xs: '0', md: '10%' },
      }}
    >
      <Messages />
    </Box>
  )
}

export default MessagesView
