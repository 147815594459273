import { gql } from '@apollo/client'

export const GET_ACTIVE_FUNDS = gql`
  query {
    funds(where: { NOT: { status: "Draft" } }) {
      id
      name
      image
      status
      vestingPlan
      event {
        id
        titleEn
        titleEs
        titlePt
        detailEn
        detailEs
        detailPt
        startDate
        endDate
      }
      purpose {
        titleEn
        titleEs
        titlePt
        targetEn
        targetEs
        targetPt
      }
      roiTime
      roiMultiplier
      descriptionEn
      descriptionEs
      descriptionPt
      amountToReach
      vesting {
        id
        amount
      }
      token {
        id
        logo
        symbol
        maxCap
        policyId
        description
        marketprice
        encodedName
        fingerprint
      }
      minInvestment
      maxInvestment
      minCompanyAsk
      maxCompanyAsk
      successThreshold
      offerPurchase(where: { status: "Both Withdrawn" }) {
        id
        on
        amount
        users {
          id
        }
        status
      }
      proposal(options: { sort: { assessmentRating: DESC } }) {
        id
        reqInvestAmount
        status
        assessmentStatus
        assessmentRating
        titleEn
        titleEs
        titlePt
        descriptionEn
        descriptionEs
        descriptionPt
        voteCount
        report {
          id
        }
        url {
          link
        }
        fund {
          name
          status
        }
        company {
          id
          address
          coName
          employees
          descriptionEn
          descriptionEs
          descriptionPt
          country {
            id
            name
          }
          has_url {
            _id
            name
          }
          logo
        }
      }
    }
  }
`

export const GET_ALL_ACTIVE_FUNDS = gql`
  query {
    funds(
      where: {
        AND: [
          { NOT: { status: "Closed" } }
          { NOT: { status: "Draft" } }
          { NOT: { status: "Closed - Not Fully Funded" } }
        ]
      }
    ) {
      id
      name
      image
      status
      vestingPlan
      event {
        id
        titleEn
        titleEs
        titlePt
        detailEn
        detailEs
        detailPt
        startDate
        endDate
      }
      purpose {
        titleEn
        titleEs
        titlePt
        targetEn
        targetEs
        targetPt
      }
      roiTime
      roiMultiplier
      descriptionEn
      descriptionEs
      descriptionPt
      amountToReach
      vesting {
        id
        amount
      }
      token {
        id
        logo
        symbol
        maxCap
        policyId
        description
        marketprice
        encodedName
        fingerprint
      }
      minInvestment
      maxInvestment
      minCompanyAsk
      maxCompanyAsk
      successThreshold
      maxOptionalLegalInfo
      offerPurchase {
        id
        on
        amount
        users {
          id
        }
        status
      }
      proposal(options: { sort: { assessmentRating: DESC } }) {
        id
        reqInvestAmount
        status
        assessmentStatus
        assessmentRating
        titleEn
        titleEs
        titlePt
        descriptionEn
        descriptionEs
        descriptionPt
        voteCount
        report {
          id
        }
        url {
          link
        }
        fund {
          name
          status
        }
        company {
          id
          address
          coName
          employees
          descriptionEn
          descriptionEs
          descriptionPt
          country {
            id
            name
          }
          has_url {
            _id
            name
          }
          logo
        }
      }
    }
  }
`

export const GET_FUNDS_BY_ID = gql`
  query ($FundId: ID!) {
    funds(where: { id: $FundId }) {
      id
      name
      image
      status
      ndaFile
      tycFile
      vestingPlan
      offerPurchase {
        id
        on
        amount
        users {
          id
        }
        status
      }
      purpose {
        id
        titleEn
        titleEs
        titlePt
        targetEn
        targetEs
        targetPt
      }
      roiTime
      roiMultiplier
      descriptionEn
      descriptionEs
      descriptionPt
      amountToReach
      token {
        id
        logo
        symbol
        maxCap
        policyId
        description
        marketprice
        encodedName
        fingerprint
      }
      minInvestment
      maxInvestment
      minCompanyAsk
      maxCompanyAsk
      maxOptionalLegalInfo
      event {
        id
        startDate
        endDate
        titleEn
        titleEs
        titlePt
        detailEn
        detailEs
        detailPt
      }
      proposal(where: { NOT: { status: "draft" } }) {
        titleEn
        titleEs
        titlePt
        descriptionEn
        descriptionEs
        descriptionPt
        id
        status
        assessmentStatus
        assessmentRating
        reqInvestAmount
        url {
          link
        }
        fund {
          id
          name
        }
        on
        company {
          id
          address
          coName
          email
          country {
            id
            name
          }
          descriptionEn
          descriptionEs
          descriptionPt
          createdOn
          isValidated
          users {
            id
          }
          employees
          fundationYear
          annualBilling
          isIncJurisdiction
          isIncorporated
          has_url {
            _id
            id
            link
            name
          }
          logo
        }
      }
      successThreshold
      messages {
        id
        replyTo
        on
        text
        read
        sentBy {
          id
          userName
          profilePicture
        }
      }
    }
  }
`

export const CREATE_MESSAGE_TO_FUND = gql`
  mutation createMessage(
    $MessageId: ID!
    $MessageDate: DateTime!
    $MessageText: String!
    $UserId: ID!
    $FundId: ID!
  ) {
    createMessages(
      input: {
        id: $MessageId
        on: $MessageDate
        text: $MessageText
        read: false
        sentBy: { connect: { where: { node: { id: $UserId } } } }
        fund: { connect: { where: { node: { id: $FundId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`

export const GET_FUNDS_SEARCH = gql`
  query ($search: String, $sort: [FundSort!], $statusFilter: [String!]) {
    funds(
      where: {
        OR: [
          { descriptionEn_CONTAINS: $search }
          { descriptionEs_CONTAINS: $search }
          { descriptionPt_CONTAINS: $search }
          { name_CONTAINS: $search }
          { status_CONTAINS: $search }
        ]
        AND: [{ NOT: { status: "Draft" } }, { status_IN: $statusFilter }]
      }
      options: { sort: $sort }
    ) {
      id
      name
      status
      image
      purpose {
        titleEn
        titleEs
        titlePt
        targetEn
        targetEs
        targetPt
      }
      roiTime
      token {
        symbol
      }
      roiMultiplier
      descriptionEn
      descriptionEs
      descriptionPt
      amountToReach
      minInvestment
      maxInvestment
      minCompanyAsk
      offerPurchase {
        id
        on
        amount
        users {
          id
        }
        status
      }
      maxCompanyAsk
      proposal {
        id
        on
        company {
          id
          coName
        }
      }
      event {
        titleEn
        startDate
        endDate
      }
      vestingPlan
      successThreshold
    }
  }
`

export const SEND_REPLY_TO_COMMENT_TO_FUND = gql`
  mutation createMessage(
    $MessageId: ID!
    $MessageDate: DateTime!
    $MessageText: String!
    $UserId: ID!
    $FundId: ID!
    $replyToId: String
  ) {
    createMessages(
      input: {
        id: $MessageId
        on: $MessageDate
        text: $MessageText
        read: false
        replyTo: $replyToId
        sentBy: { connect: { where: { node: { id: $UserId } } } }
        fund: { connect: { where: { node: { id: $FundId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`

export const GET_COLLECTING_FUNDS = gql`
  query {
    funds(where: { status: "Collecting" }) {
      id
      name
      offerPurchase {
        id
        on
        amount
        status
      }
    }
  }
`

export const GET_FUND_PURPOSE = gql`
  query {
    fundPurposes(
      options: { sort: { titleEn: ASC } }
      where: { isActive: true }
    ) {
      id
      titleEn
      titleEs
      titlePt
      targetEn
      targetEs
      targetPt
      isActive
    }
  }
`

export const CREATE_FUND_PROPOSAL = gql`
  mutation (
    $propId: ID!
    $propstatus: String!
    $proptitleEn: String!
    $proptitleEs: String!
    $proptitlePt: String!
    $propdescriptionEn: String!
    $propdescriptionEs: String!
    $propdescriptionPt: String!
    $reqInvestAmount: Float!
    $on: DateTime!
    $actionPlanFile: String!
    $milestonesFile: String!
    $objectives: String!
    $assessmentStatus: String!
    $urlId: ID!
    $urllink: String!
    $urlname: String!
    $fundId: ID!
    $companyId: ID!
  ) {
    createUrls(input: { id: $urlId, link: $urllink, name: $urlname }) {
      urls {
        id
      }
    }
    createProposals(
      input: {
        id: $propId
        status: $propstatus
        titleEn: $proptitleEn
        titleEs: $proptitleEs
        titlePt: $proptitlePt
        descriptionEn: $propdescriptionEn
        descriptionEs: $propdescriptionEs
        descriptionPt: $propdescriptionPt
        reqInvestAmount: $reqInvestAmount
        on: $on
        actionPlanFile: $actionPlanFile
        milestonesFile: $milestonesFile
        objectives: $objectives
        assessmentStatus: $assessmentStatus
        fund: { connect: { where: { node: { id: $fundId } } } }
        company: { connect: { where: { node: { id: $companyId } } } }
        url: { connect: { where: { node: { id: $urlId } } } }
      }
    ) {
      proposals {
        id
      }
    }
  }
`

export const EDIT_FUND_PROPOSAL = gql`
  mutation ($proposalId: ID!, $updateData: ProposalUpdateInput!) {
    updateProposals(where: { id: $proposalId }, update: $updateData) {
      proposals {
        id
      }
    }
  }
`

export const GET_ALL_FUND_OPTIONS = gql`
  query {
    settingProperties(where: { settingName_CONTAINS: "FUND_ORIGIN_OPT" }) {
      id
      settingName
      settingValue
    }
  }
`
