import React from "react"
import { Fragment } from "react"
import Card from "../Card/Card"
import { Box, Avatar, Typography, Button, Grid, Tooltip, useTheme } from "@mui/material"
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import ReactCountryFlag from "react-country-flag"

const ActiveProposalCard = ({ id, name, logo, state, fundName, desc, ADArequest, address, country, proposalTitle, proposalScore, link, applicationId, creator, showMore }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const locStorageRole = localStorage.getItem('role')
    const handleClick = () => {
        dispatch(push(`/application-detail/${applicationId}`))
    }

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return <Fragment>
        <Card color={actualThemeLight ? '#FFF' : "#1D1D1D"} key={id} height='100%' border='1px solid #DADADA' >
            {/* HEADER */}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
                {/* SUPERIOR */}
                <Grid container sx={{ display: 'flex', flexDirection: 'row', maxHeight: '40px' }}>
                    <Grid item xs={1.5} sx={{ height: '40px', width: '40px' }} >
                        <Avatar alt="logo" src={logo} />
                    </Grid>
                    <Grid item xs={10.5} sx={{ height: '40px' }}>
                        <Grid container sx={{ pl: 1 }}>
                            <Grid item xs={8} sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'row', maxHeight: '22px' }}>
                                <Typography sx={{ fontWeight: 'bold', overflow: 'hidden' }}>
                                    {name}
                                </Typography>
                            </Grid>
                            <Tooltip title={address + " - " + country?.name}>
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end', overflow: 'hidden', maxHeight: '22px', pr: 1 }}>
                                    <ReactCountryFlag
                                        countryCode={country?.id}
                                        style={{
                                            fontSize: '18px',
                                            lineHeight: '18px',
                                        }}
                                        svg
                                    />
                                    <Typography sx={{ fontSize: '12px', color: '#4F4F4F', mt: '1px', ml: '5px' }}>
                                        {country?.id}
                                    </Typography>
                                </Grid>
                            </Tooltip>

                        </Grid>
                        <Grid container sx={{ pl: 1 }}>
                            <Grid item xs={12} sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'row', maxHeight: '22px', alignItems: 'center' }} >
                                {state === 'applied' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#DB417B' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_APPLIED')}
                                    </Typography>
                                ) : state === 'rejected' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: 'bold' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_REJECTED')}
                                    </Typography>
                                ) : state === 'draft' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#828282', fontWeight: 'bold' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_DRAFT')}
                                    </Typography>
                                ) : state === 'participant' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#0090E7', fontWeight: 'bold' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_PARTICIPANT')}
                                    </Typography>
                                ) : state === 'awarded' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#00D25B', fontWeight: 'bold' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_AWARDED')}
                                    </Typography>
                                ) : state === 'assessed' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#9A1D4C', fontWeight: 'bold' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_ASSESSED')}
                                    </Typography>
                                ) : state === 'pending' ? (
                                    <Typography sx={{ fontSize: '12px', color: '#9A1D4C', fontWeight: 'bold' }}>
                                        {t('FUND_SEARCH_VIEW.STATUS_PROPOSED')}
                                    </Typography>
                                ) : (
                                    <Typography sx={{ fontSize: '12px', mr: 1 }}>
                                        {state}
                                    </Typography>
                                )}
                                {state && <Box ml={1} mr={1}>•</Box>}
                                <Typography sx={{ fontSize: '12px' }}>
                                    {fundName != null ? fundName : ''}
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* INFERIOR */}
                {locStorageRole === 'role-investor' || showMore ? <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {/* TITLE */}
                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', alignItems: 'center' }}>
                            {proposalTitle}
                        </Typography>
                        {/* SCORE */}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '300px',
                            height: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'end',
                            justifyContent: 'end',
                        }}>
                        {proposalScore != null && state === 'assessed' ?
                            <Tooltip placement="top" arrow title={'Rating'}>
                                <Box
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        backgroundColor: '#9A1D4C',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ml: 2
                                    }}>
                                    <Typography sx={{ fontSize: '10px', fontWeight: 'bold', color: '#FFF' }}>
                                        {proposalScore}/10
                                    </Typography>
                                </Box>
                            </Tooltip>
                            : null
                        }
                    </Box>
                    {/* SOLICITUD DE ADA */}
                    {
                        ADArequest !== undefined ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '80%', mt: 1 }}>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {t('ACTIVE_COMPANY.REQUESTS')}
                                </Typography>
                                <Typography sx={{ fontSize: '14px', ml: 1, fontWeight: 'bold' }}>
                                    $ {ADArequest.toLocaleString()}
                                </Typography>
                            </Box> :
                            <Typography sx={{ mt: 2, fontSize: '14px' }}>
                                {t('ACTIVE_COMPANY.NO_APLICATION')}
                            </Typography>
                    }
                    {/* DESCRIPCIÓN */}
                    <Typography sx={{ fontSize: '14px', maxHeight: '85px', minHeight: '85px', overflowY: 'hidden', width: '80%', mt: 1 }}>
                        {desc}
                    </Typography>
                </Box > : null}
                <Button
                    variant="text"
                    onClick={() => window.open(link, '_blank')}
                    sx={{ mt: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                >
                    <InsertLinkIcon />
                    <Typography sx={{ fontSize: '14px' }}>
                        URL
                    </Typography>
                </Button>
                {/* CTA */}
                {locStorageRole === "role-investor" || creator ? <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    <Button variant="contained" onClick={handleClick} sx={{ mt: '8px' }}>
                        {t('LANDING_PAGE.BUTTON')}
                    </Button>
                </Box> : null}
            </Box >
        </Card >
    </Fragment >
}

export default ActiveProposalCard