import React, { useState, useEffect } from 'react'
import {
  Box,
  styled,
  Button,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  RadioGroup,
  CircularProgress,
  FormControlLabel,
  Radio,
  InputAdornment,
  LinearProgress,
  Modal,
  createFilterOptions,
  useTheme,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_USER_INFO, GET_CORE_TEAM_USERS } from '../../../Queries/Users'
import {
  CREATE_NOTIFICATION,
  LINK_NOTIFICATION_TO_MANY_USER,
} from '../../../Queries/Notifications'
import { useAuth0 } from '@auth0/auth0-react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { GET_COUNTRIES } from '../../../Queries/Countries'
import { imgUploadHandler } from '../../../utils/fileUpload'
import {
  CREATE_COMPANY,
  CREATE_COMPANY_DRAFT,
} from '../../../Queries/Companies'
import { push } from 'connected-react-router'
import { GET_ALL_PREFERENCES } from '../../../Queries/Preferences'
import { GET_COMPANIES } from '../../../Queries/Companies'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
import BuildingIcon from '../../../../src/assets/icons/building.svg'
import cities from './cities.json'
import { showMessage } from '../../../store/actions/snackBarActions'

const CreateCompany = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const companyDate = moment.utc()
  const currentDate = new Date()

  let seenNames = {}
  const nonDuplicatedCities = cities?.filter(function (currentObject) {
    if (currentObject?.name in seenNames) {
      return false
    } else {
      seenNames[currentObject?.name] = true
      return true
    }
  })

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 20,
    stringify: (option) => option.name,
  })

  // Define styled component for back button
  const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.hint,
    fontFamily: 'Quicksand, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }))

  // DEFINE STATES
  const companyId = uuidv4()
  const [userNameField, setUserNameField] = useState('')
  const linkId = uuidv4()
  const [preferences, setPreferences] = useState([])
  const [radioSelected, setRadioSelected] = useState('en')
  const [values, setValues] = useState({
    mail: '',
    coName: '',
    country: { id: '1' },
    phone: '',
    foundationYear: '2000-01-01',
    billing: '',
    adress: '',
    link: '',
    employees: '',
    jurisdiction: '',
    descriptionEN: '',
    descriptionES: '',
    descriptionPT: '',
    // Agrega más campos según tus necesidades
  })

  const billing = parseFloat(values?.billing.replace(/,/g, ''))

  // SET MULTIPLE VARIABLES
  let selectedDate
  const dummyEmployees = [
    { id: '1', value: '1-10' },
    { id: '2', value: '11-50' },
    { id: '3', value: '51-100' },
    { id: '4', value: '101-500' },
    { id: '5', value: '+501' },
  ]

  const preferencesID = preferences.map((item) => item.id)

  const [errors, setErrors] = useState({
    mail: { active: false, error: null },
    coName: { active: false, error: null },
    foundationYear: { active: false, error: null },
    // Agrega más campos según tus necesidades
  })
  const [legallyRegistered, setLegallyRestired] = useState(false)

  const handleChangeRegistered = (event) => {
    setLegallyRestired(event.target.value)
  }

  const handleInputChange = async (fieldName, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }))

    const validateField = async (fieldName, value) => {
      switch (fieldName) {
        case 'mail':
          if (!value.includes('@')) {
            return {
              field: fieldName,
              error: 'Ingrese una dirección de correo válida',
            }
          }
          break

        case 'coName':
          if (value.trim() !== '') {
            const { data } = await getCompanies({
              variables: {
                coName: value.trim(),
              },
            })

            if (
              data &&
              data.companies.some((company) => company.coName === value.trim())
            ) {
              return {
                field: fieldName,
                error: 'El nombre de la compañía ya existe',
              }
            }
          }
          break
        case 'foundationYear':
          selectedDate = new Date(value)
          if (selectedDate > currentDate) {
            return {
              field: fieldName,
              error: 'La fecha no puede ser posterior al día de hoy',
            }
          }
          break
        default:
          break
      }

      return null // No hay error
    }

    const error = await validateField(fieldName, value)

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }))
  }

  const handleRadioChange = (event) => {
    setRadioSelected(event.target.value)
  }

  // GET USER DATA
  const [getUserData, { data: userData }] = useLazyQuery(GET_USER_INFO, {
    pollInterval: 1000,
    variables: { email: user?.email || user?.sub },
  })

  // GET CORE TEAM ARRAY
  const [getCoreTeam, { data: coreTeamData }] =
    useLazyQuery(GET_CORE_TEAM_USERS)

  useEffect(() => {
    getCoreTeam()
  }, [getCoreTeam])

  function extractIds(usersArray) {
    return usersArray?.map((user) => user?.id)
  }

  const pureCoreTeamIds = extractIds(coreTeamData?.users)

  const [createNotification] = useMutation(CREATE_NOTIFICATION)
  const [linkNotificationToUser] = useMutation(LINK_NOTIFICATION_TO_MANY_USER)

  // GET COUNTRIES
  const [getCountries, { data: countriesList }] = useLazyQuery(GET_COUNTRIES, {
    pollInterval: 10000,
  })

  // GET PREFERENCES
  const [getPreferences, { data: preferenceList }] = useLazyQuery(
    GET_ALL_PREFERENCES,
    {
      pollInterval: 5000,
    }
  )

  // GET COMPANIES
  const [getCompanies] = useLazyQuery(GET_COMPANIES, {
    pollInterval: 5000,
  })

  useEffect(() => {
    getCountries()
    getUserData()
    getPreferences()
    getPreferences()
    getCompanies()
  }, [getCountries, getUserData, getPreferences, getCompanies])

  const [createCompany] = useMutation(CREATE_COMPANY)
  const [createCompanyDraft] = useMutation(CREATE_COMPANY_DRAFT)

  // MANAGE LOGO UPDATE
  const [logoState, setLogoState] = React.useState('')
  const uploadCompanyLogoHandler = async (file) => {
    const fileURL = await imgUploadHandler(file)
    setLogoState(fileURL)
  }

  useEffect(() => {
    setUserNameField(userData?.users[0]?.userName)
  }, [userData])

  const [sendingMutation, setSendingMutation] = useState(false)

  const [openConfirmation, setOpenConfirmation] = React.useState(false)
  const handleOpenConfirmation = () => setOpenConfirmation(true)
  const handleCloseConfirmation = () => setOpenConfirmation(false)

  const [openBeforeSave, setOpenBeforeSave] = React.useState(false)
  const handleOpenBeforeSave = () => setOpenBeforeSave(true)
  const handleCloseOpenBeforeSave = () => setOpenBeforeSave(false)

  const handleSubmitForm = async (e) => {
    // Prevent the default event behavior
    e.preventDefault()
    setSendingMutation(true)

    try {
      handleOpenBeforeSave()
      // Call the createCompany function with variable values
      await createCompany({
        variables: {
          id: companyId,
          address: values.adress,
          name: values.coName,
          descriptionEs: values.descriptionES,
          descriptionEn: values.descriptionEN,
          descriptionPt: values.descriptionPT,
          userId: user?.email || user?.sub,
          logo: logoState,
          email: values.mail,
          date: companyDate,
          countryID: values.country.id,
          fundationYear: values.foundationYear,
          linkId: linkId,
          linkUrl: 'https://linktr.ee/' + values.link,
          phone: values.phone,
          employees: values.employees,
          annualBilling: billing,
          jurisdiction: values.jurisdiction,
          isIncorporated: Boolean(legallyRegistered),
          preferences: preferencesID,
        },
      })
      const notifID = await uuidv4()
      const dateTime = await moment()
      await createNotification({
        variables: {
          userID: pureCoreTeamIds[0],
          notificationID: notifID,
          msg: `COCREATED - ${values.coName}`,
          date: dateTime,
          link: '/admin-companies',
        },
      })
      await linkNotificationToUser({
        variables: {
          notificationID: notifID,
          otherUserId: pureCoreTeamIds,
        },
      })
      await new Promise((resolve) => setTimeout(resolve, 6000))
      setSendingMutation(false)
      handleCloseOpenBeforeSave()
      // Redirect to the companies page on success
      dispatch(push('/companies'))
    } catch (err) {
      // Log errors to console
      console.error('Error sending query:', err)
      console.error('Stack trace:', err.stack)
    }
  }

  const handleSaveDraft = async (e) => {
    // Prevent the default event behavior
    e.preventDefault()
    setSendingMutation(true)

    try {
      // Call the createCompany function with variable values
      await createCompanyDraft({
        variables: {
          id: companyId,
          address: values.adress,
          name: values.coName,
          descriptionEs: values.descriptionES,
          descriptionEn: values.descriptionEN,
          descriptionPt: values.descriptionPT,
          userId: user.email || user?.sub,
          logo: logoState,
          email: values.mail,
          date: companyDate,
          countryID: values.country.id,
          fundationYear: values.foundationYear,
          linkId: linkId,
          linkUrl: 'https://linktr.ee/' + values.link,
          phone: values.phone,
          employees: values.employees,
          annualBilling: billing,
          jurisdiction: values.jurisdiction,
          isIncorporated: Boolean(legallyRegistered),
          preferences: preferencesID,
        },
      })
      setSendingMutation(false)
      dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
      // Redirect to the companies page on success
      dispatch(push('/companies'))
    } catch (err) {
      // Log errors to console
      console.error('Error sending query:', err)
      console.error('Stack trace:', err.stack)
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const styleContact = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* SUPERIOR */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: 4,
            backgroundColor: actualThemeLight ? '#B2E0DF' : '#2A4C4B',
            height: '10vh',
            pl: 6,
            pr: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowBackIcon sx={{ fontSize: '16px' }} />
            <BackButton
              onClick={handleOpenConfirmation}
              sx={{
                textDecoration: 'underline',
                fontSize: '14px',
              }}
            >
              {t('INVERSOR_VIEW.USER_CARD.RETURN')}
            </BackButton>
          </Box>
          <Typography
            sx={{
              fontSize: '20px',
              color: actualThemeLight ? '#4F4F4F' : 'white',
              ml: 2,
            }}
          >
            {t('CREATE_COMPANY.NEW_COMPANY')}
          </Typography>
        </Box>
        <Grid container>
          <Grid item md={1} />
          {/* LEFT SIDE */}
          <Grid item md={10} xs={12}>
            <Grid container>
              {/* LEFT - LEFT */}
              <Grid sx={{ margin: { md: 0, xs: '5%' } }} item md={6} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: { md: '80%', xs: '100%' },
                  }}
                >
                  {/* USERNAME */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="lenguage-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.USER_NAME')}
                    </InputLabel>
                    <TextField
                      id="userName-input"
                      sx={{ width: '100%' }}
                      name={t('CREATE_COMPANY.USER_NAME')}
                      value={userNameField}
                      disabled
                      type="text"
                      variant="outlined"
                      aria-describedby="telegram-input-text"
                    />
                  </Box>
                  {/* COUNTRY */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="country-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.COUNTRY')}*
                    </InputLabel>
                    {countriesList == undefined ? null : (
                      <Autocomplete
                        fullWidth
                        limitTags={6}
                        onChange={(e, value) => {
                          handleInputChange('country', value)
                        }}
                        error={errors.country && errors.country.error !== null}
                        helperText={errors.country && errors.country.error}
                        id="countries-outlined"
                        options={countriesList?.countries}
                        getOptionLabel={(p) => p?.name}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: '100%' }}
                            {...params}
                            name="country"
                            color="pink"
                          />
                        )}
                      />
                    )}
                  </Box>
                  {/* MAIL */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="mail-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.MAIL')}*
                    </InputLabel>
                    <TextField
                      id="mail-input"
                      sx={{ width: '100%' }}
                      onChange={(e) =>
                        handleInputChange('mail', e.target.value)
                      }
                      error={errors.mail && errors.mail.error !== null}
                      helperText={errors.mail && errors.mail.error}
                      type="text"
                      variant="outlined"
                      aria-describedby="mail-input-text"
                    />
                  </Box>
                  {/* PHONE */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="phone-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.PHONE')}*
                    </InputLabel>
                    <TextField
                      id="phone-input"
                      sx={{ width: '100%' }}
                      name={t('CREATE_COMPANY.PHONE')}
                      onChange={(e) =>
                        handleInputChange('phone', e.target.value)
                      }
                      error={errors.phone && errors.phone.error !== null}
                      helperText={errors.phone && errors.phone.error}
                      type="text"
                      variant="outlined"
                      aria-describedby="telegram-input-text"
                    />
                  </Box>
                  {/* FOUNDATION YEAR */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="foundationYear-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.FOUNDATION_YEAR')}
                    </InputLabel>
                    <TextField
                      id="year-input"
                      sx={{ width: '100%' }}
                      name={t('CREATE_COMPANY.FOUNDATION_YEAR')}
                      aria-describedby="year-input-text"
                      type="date"
                      width="100%"
                      variant="outlined"
                      value={values.foundationYear}
                      onChange={(e) =>
                        handleInputChange('foundationYear', e.target.value)
                      }
                      error={
                        errors.foundationYear &&
                        errors.foundationYear.error !== null
                      }
                      helperText={
                        errors.foundationYear && errors.foundationYear.error
                      }
                    />
                  </Box>
                  {/* BILLING */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="billing-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.ANNUAL_BILLING')}*
                    </InputLabel>
                    <NumericFormat
                      value={values?.billing}
                      thousandSeparator
                      id="billing-input"
                      name={t('CREATE_COMPANY.ANNUAL_BILLING')}
                      helperText={errors.billing && errors.billing.error}
                      InputProps={{
                        startAdornment: <InputAdornment>$</InputAdornment>,
                      }}
                      fullWidth
                      onChange={(e) =>
                        handleInputChange('billing', e.target.value)
                      }
                      customInput={TextField}
                      error={errors.billing && errors.billing.error !== null}
                    />
                  </Box>
                  {/* LOGO */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2,
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="companyName-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('CREATE_COMPANY.UPLOAD_LOGO')}*
                    </InputLabel>
                    {logoState ? (
                      <img
                        src={logoState}
                        style={{
                          borderRadius: '5px',
                          border: actualThemeLight
                            ? '1px dashed #000000'
                            : '1px dashed #white',
                          width: '100%',
                          height: '55px',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        component="label"
                        starticon={<UploadFileIcon />}
                        sx={{
                          borderRadius: '5px',
                          border: actualThemeLight
                            ? '1px dashed #000000'
                            : '1px dashed #white',
                          width: '100%',
                          height: '55px',
                        }}
                      >
                        <UploadFileIcon sx={{ mr: 2 }} />
                        <input
                          hidden
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            uploadCompanyLogoHandler(e.target.files?.[0])
                          }
                        />
                        {t('CREATE_COMPANY.UPLOAD_FILE')}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* LEFT - RIGHT */}
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {/* COMPANY NAME */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        width: { md: '80%', xs: '100%' },
                      }}
                    >
                      <InputLabel
                        shrink
                        htmlFor="companyName-select"
                        sx={{
                          color: actualThemeLight ? '#4F4F4F' : 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          letterSpacing: 0.15,
                          textAlign: 'left',
                        }}
                      >
                        {t('CREATE_COMPANY.COMPANY_NAME')}*
                      </InputLabel>
                      <TextField
                        id="companyName-input"
                        name="coName"
                        sx={{ width: '100%' }}
                        onChange={(e) => {
                          handleInputChange('coName', e.target.value)
                        }}
                        type="text"
                        variant="outlined"
                        aria-describedby="companyName-input-text"
                        error={errors.coName && errors.coName.error !== null}
                        helperText={errors.coName && errors.coName.error}
                      />
                    </Box>
                  </Box>
                  {/* ADDRESS */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        width: { md: '80%', xs: '100%' },
                      }}
                    >
                      <InputLabel
                        shrink
                        htmlFor="address-select"
                        sx={{
                          color: actualThemeLight ? '#4F4F4F' : 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          letterSpacing: 0.15,
                          textAlign: 'left',
                        }}
                      >
                        {t('CREATE_COMPANY.COMPANY_ADDRESS')}*
                      </InputLabel>
                      <TextField
                        id="address-input"
                        sx={{ width: '100%' }}
                        name={t('CREATE_COMPANY.COMPANY_ADDRESS')}
                        onChange={(e) =>
                          handleInputChange('adress', e.target.value)
                        }
                        error={errors.adress && errors.adress.error !== null}
                        helperText={errors.adress && errors.adress.error}
                        type="text"
                        variant="outlined"
                        aria-describedby="address-input-text"
                      />
                    </Box>
                  </Box>
                  {/* LINK */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        width: { md: '80%', xs: '100%' },
                      }}
                    >
                      <InputLabel
                        shrink
                        htmlFor="link-select"
                        sx={{
                          color: actualThemeLight ? '#4F4F4F' : 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          letterSpacing: 0.15,
                          textAlign: 'left',
                        }}
                      >
                        {t('CREATE_COMPANY.LINKTREE')}*
                      </InputLabel>
                      <TextField
                        id="link-input"
                        sx={{ width: '100%' }}
                        name={t('CREATE_COMPANY.LINKTREE')}
                        onChange={(e) =>
                          handleInputChange('link', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>https://linktr.ee/</InputAdornment>
                          ),
                        }}
                        error={errors.link && errors.link.error !== null}
                        helperText={errors.link && errors.link.error}
                        type="text"
                        variant="outlined"
                        aria-describedby="link-input-text"
                      />
                    </Box>
                  </Box>
                  {/* EMPLOYEES NUMBER */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        width: { md: '80%', xs: '100%' },
                      }}
                    >
                      <InputLabel
                        shrink
                        htmlFor="employees-select"
                        sx={{
                          color: actualThemeLight ? '#4F4F4F' : 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          letterSpacing: 0.15,
                          textAlign: 'left',
                        }}
                      >
                        {t('CREATE_COMPANY.EMPLOYEES_AMOUNT')}
                      </InputLabel>
                      <Autocomplete
                        fullWidth
                        limitTags={6}
                        onChange={(e, option) =>
                          handleInputChange('employees', option.value || '')
                        }
                        error={
                          errors.employees && errors.employees.error !== null
                        }
                        helperText={errors.employees && errors.employees.error}
                        id="tags-outlined"
                        options={dummyEmployees}
                        getOptionLabel={(e) => e.value}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            id="employees-input"
                            sx={{ width: '100%' }}
                            {...params}
                            name={t('CREATE_COMPANY.EMPLOYEES_AMOUNT')}
                            variant="outlined"
                            aria-describedby="employees-input-text"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  {/* LEGAL COMPANY */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        width: { md: '80%', xs: '100%' },
                      }}
                    >
                      <InputLabel
                        sx={{
                          color: actualThemeLight ? '#4F4F4F' : 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          letterSpacing: 0.15,
                          textAlign: 'left',
                        }}
                        shrink
                        htmlFor="legalCompany-select"
                      >
                        {t('CREATE_COMPANY.LEGAL_COMPANY')}
                      </InputLabel>
                      <RadioGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: { xs: '100%' },
                          marginTop: '15px',
                          justifyContent: 'left',
                        }}
                        name={'perfil'}
                        id="perfile-select"
                        onChange={handleChangeRegistered}
                        value={legallyRegistered}
                      >
                        <FormControlLabel
                          value={true}
                          id="role-applicant"
                          control={<Radio />}
                          label={t('MY_OFFERINGS.YES')}
                        />
                        <FormControlLabel
                          value={false}
                          id="role-investor"
                          control={<Radio />}
                          label={t('MY_OFFERINGS.NO')}
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                  {/* JURISDICTION */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        width: { md: '80%', xs: '100%' },
                      }}
                    >
                      <InputLabel
                        shrink
                        htmlFor="jurisdiction-select"
                        sx={{
                          color: actualThemeLight ? '#4F4F4F' : 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          letterSpacing: 0.15,
                          textAlign: 'left',
                        }}
                      >
                        {t('CREATE_COMPANY.JURISDICTION')}
                      </InputLabel>
                      {legallyRegistered == 'true' &&
                      nonDuplicatedCities !== undefined ? (
                        <Autocomplete
                          fullWidth
                          limitTags={6}
                          onChange={(e, value) => {
                            handleInputChange(
                              'jurisdiction',
                              value?.name + ', ' + value?.country
                            )
                          }}
                          id="link-input"
                          options={nonDuplicatedCities}
                          filterOptions={filterOptions}
                          getOptionLabel={(p) => p?.name + ', ' + p?.country}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: '100%' }}
                              {...params}
                              name="jurisdiction"
                              color="pink"
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          fullWidth
                          disabled
                          limitTags={6}
                          onChange={(e, value) => {
                            handleInputChange(
                              'jurisdiction',
                              value?.name + ', ' + value?.country
                            )
                          }}
                          id="link-input"
                          options={nonDuplicatedCities}
                          filterOptions={filterOptions}
                          getOptionLabel={(p) => p?.name + ', ' + p?.country}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: '100%' }}
                              {...params}
                              name="jurisdiction"
                              color="pink"
                            />
                          )}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item md={10}>
            {/* TAGS */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                width: { md: '40%', xs: '100%' },
              }}
            >
              <InputLabel
                shrink
                htmlFor="tags-select"
                sx={{
                  color: actualThemeLight ? '#4F4F4F' : 'white',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  fontFamily: 'Quicksand',
                  letterSpacing: 0.15,
                  textAlign: 'left',
                }}
              >
                {t('CREATE_COMPANY.TAGS')}
              </InputLabel>
              {!preferenceList ? (
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              ) : (
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={6}
                  onChange={(event, newValue) => setPreferences(newValue)}
                  error={
                    errors.preferences && errors.preferences.error !== null
                  }
                  helperText={errors.preferences && errors.preferences.error}
                  id="tags-outlined"
                  options={preferenceList?.preferences}
                  getOptionLabel={(p) => p?.preferenceName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: { xs: '100%' } }}
                      {...params}
                      id="tags-input"
                      name={t('CREATE_COMPANY.TAGS')}
                      label={<SearchIcon />}
                      variant="outlined"
                      aria-describedby="tags-input-text"
                      color="pink"
                    />
                  )}
                />
              )}
            </Box>
            {/* DESCRIPTION */}
            <RadioGroup
              sx={{ mt: 4, mb: 2 }}
              onChange={handleRadioChange}
              defaultValue={radioSelected}
              row
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label="English"
              />
              <FormControlLabel
                value="es"
                control={<Radio />}
                label="Español"
              />
              <FormControlLabel
                value="pt"
                control={<Radio />}
                label="Português"
              />
            </RadioGroup>
            {radioSelected === 'en' ? (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                    }}
                  >
                    <img src={BuildingIcon} alt="icon" width="40px" />
                    <InputLabel
                      shrink
                      htmlFor="partners-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                        ml: 1,
                      }}
                    >
                      {t('CREATE_COMPANY.DESCRIPTION') + ' EN'}*
                    </InputLabel>
                  </Box>
                  <TextField
                    id="status-input"
                    sx={{ width: '100%' }}
                    name={t('CREATE_COMPANY.DESCRIPTION')}
                    multiline
                    rows={6}
                    value={values.descriptionEN}
                    onChange={(e) =>
                      handleInputChange('descriptionEN', e.target.value)
                    }
                    error={
                      errors.descriptionEN &&
                      errors.descriptionEN.error !== null
                    }
                    helperText={
                      errors.descriptionEN && errors.descriptionEN.error
                    }
                    type="text"
                    variant="outlined"
                    aria-describedby="status-input-text"
                  />
                </Box>
              </>
            ) : radioSelected === 'es' ? (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                    }}
                  >
                    <img src={BuildingIcon} alt="icon" width="40px" />
                    <InputLabel
                      shrink
                      htmlFor="partners-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                        ml: 1,
                      }}
                    >
                      {t('CREATE_COMPANY.DESCRIPTION') + ' ES'}
                    </InputLabel>
                  </Box>
                  <TextField
                    id="status-input"
                    sx={{ width: '100%' }}
                    name={t('CREATE_COMPANY.DESCRIPTION')}
                    multiline
                    rows={6}
                    value={values.descriptionES}
                    onChange={(e) =>
                      handleInputChange('descriptionES', e.target.value)
                    }
                    error={
                      errors.descriptionES &&
                      errors.descriptionES.error !== null
                    }
                    helperText={
                      errors.descriptionES && errors.descriptionES.error
                    }
                    type="text"
                    variant="outlined"
                    aria-describedby="status-input-text"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                    }}
                  >
                    <img src={BuildingIcon} alt="icon" width="40px" />
                    <InputLabel
                      shrink
                      htmlFor="partners-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                        ml: 1,
                      }}
                    >
                      {t('CREATE_COMPANY.DESCRIPTION') + ' PT'}
                    </InputLabel>
                  </Box>
                  <TextField
                    id="status-input"
                    sx={{ width: '100%' }}
                    name={t('CREATE_COMPANY.DESCRIPTION')}
                    multiline
                    rows={6}
                    value={values.descriptionPT}
                    onChange={(e) =>
                      handleInputChange('descriptionPT', e.target.value)
                    }
                    error={
                      errors.descriptionPT &&
                      errors.descriptionPT.error !== null
                    }
                    helperText={
                      errors.descriptionPT && errors.descriptionPT.error
                    }
                    type="text"
                    variant="outlined"
                    aria-describedby="status-input-text"
                  />
                </Box>
              </>
            )}
          </Grid>
          <Grid item md={1} />
          {/* CTA´S BUTTONS */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              mb: 6,
              mt: 4,
            }}
          >
            {sendingMutation ? (
              <Box sx={{ width: '50%' }}>
                <LinearProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: { md: '40%', xs: '100%' },
                  justifyContent: 'space-between',
                }}
              >
                {/* DRAFT */}
                <Button variant="contained" onClick={handleSaveDraft}>
                  {t('CREATE_COMPANY.SAVE_DRAFT')}
                </Button>
                {/* SUBMIT */}
                {!values.adress ||
                !values.coName ||
                !logoState ||
                !values.mail ||
                !values.descriptionEN ||
                !values.country ||
                !values.foundationYear ||
                !values.link ||
                !values.phone ||
                !values.employees ||
                !billing ||
                !preferencesID ? (
                  <Button variant="contained" disabled>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: actualThemeLight ? null : 'white',
                      }}
                    >
                      {t('ADMIN.CREATE')}
                    </Typography>
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleSubmitForm}>
                    {t('ADMIN.CREATE')}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Modal
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('MY_OFFERINGS.ARE_YOU_SURE')}
            </Typography>
            <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
              {t('MY_OFFERINGS.CHANGES_LOST')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-evenly',
                mt: 2,
              }}
            >
              <Button variant="outlined" onClick={handleCloseConfirmation}>
                {t('CO_ASSESSMENT.CANCEL')}
              </Button>
              <Button variant="contained" onClick={() => dispatch(goBack())}>
                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openBeforeSave}
          onClose={handleCloseOpenBeforeSave}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleContact}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('MY_COMPANIES.THANKYOU')}
            </Typography>
            <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
              {t('MY_COMPANIES.CONTACTED')}
            </Typography>
          </Box>
        </Modal>
      </Box>
    </>
  )
}

export default CreateCompany
