import { Avatar, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InvestmentTableRow from './InvestmentTableRow'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SelectedFundRowInvestment from './SelectedFundRowInvestment';

const InvestmentTable = ({ data, connectedWallet }) => {
    const { t } = useTranslation()

    const groupedByFundId = data?.reduce((acc, obj) => {
        const fundId = obj?.fund?.id;
        if (!acc[fundId]) {
            acc[fundId] = [];
        }
        acc[fundId].push(obj);
        return acc;
    }, {});

    const [selectedFund, setSelectedFund] = useState(undefined)

    const handleButtonClickSelectedFund = (fundId) => {
        setSelectedFund(fundId)
    };

    const getFundArrayById = (groupedByFundId, fundId) => {
        return groupedByFundId[fundId] || [];
    };

    const selectedFundArray = selectedFund ? getFundArrayById(groupedByFundId, selectedFund) : [];

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <TableContainer sx={{
            width: '100%',
            borderRadius: '10px',
            alignSelf: 'start',
            height: '48vh',
            backgroundColor: actualThemeLight ? 'white' : '#262626'
        }}>
            {selectedFund === undefined &&
                <Table stickyHeader={true} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', cursor: 'pointer', p: 1 }}
                                align="center">
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.FUND')}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell
                                sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', cursor: 'pointer', p: 1 }}
                                align="center">
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.CURR_STAGE')}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', p: 1 }} align="center">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.STAGE_DURATION')}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', p: 1 }} align="center">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('WALLET_VIEW.INVESTMENTS')}
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('WALLET_VIEW.CONF_OFFERED')}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', p: 1 }} align="center">
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', p: 1 }} align="center">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupedByFundId && Object.keys(groupedByFundId).map((fundId) => (
                            <InvestmentTableRow
                                key={fundId}
                                fundId={fundId}
                                data={groupedByFundId[fundId]}
                                handleButtonClick={handleButtonClickSelectedFund}
                            />
                        ))}
                    </TableBody>
                </Table>
            }
            {selectedFund !== undefined &&
                <Table stickyHeader={true} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" component="th" scope="row" sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626', p: 1 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <IconButton sx={{ mr: 1 }} onClick={() => setSelectedFund(undefined)}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    {selectedFundArray[0]?.fund?.image !== '' ?
                                        <Avatar src={selectedFundArray[0]?.fund?.image} alt={'LOGO'} sx={{ mr: 1 }} />
                                        : <Box sx={{ ml: 6 }}> </Box>
                                    }
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>

                                        {selectedFundArray[0]?.fund?.name}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626' }} align="center">
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626' }} align="center">
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626' }} align="center">
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626' }} align="center">
                            </TableCell>
                            <TableCell sx={{ backgroundColor: actualThemeLight ? 'white' : '#262626' }} align="center">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: actualThemeLight ? 'white' : '#262626',
                                    cursor: 'pointer',
                                    p: 1,
                                    height: '60px'
                                }}
                                align="center">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.NEW_EVENT_DATE')}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{
                                backgroundColor: actualThemeLight ? 'white' : '#262626',
                                p: 1
                            }} align="center">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.INVESTMENT_DETAIL_AMOUNT')}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    backgroundColor: actualThemeLight ? 'white' : '#262626',
                                    cursor: 'pointer',
                                    p: 1
                                }}
                                align="center">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.STATUS')}
                                </Typography>
                            </TableCell>

                            <TableCell sx={{
                                backgroundColor: actualThemeLight ? 'white' : '#262626',
                                p: 1
                            }} align="center">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.SC_LINK')}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{
                                backgroundColor: actualThemeLight ? 'white' : '#262626',
                                p: 1
                            }} align="center">
                            </TableCell>
                            <TableCell sx={{
                                backgroundColor: actualThemeLight ? 'white' : '#262626',
                                p: 1
                            }} align="center">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedFundArray?.map((investment) => {
                            return (
                                <SelectedFundRowInvestment key={investment?.id} data={investment} connectedWallet={connectedWallet} />
                            )
                        })}
                    </TableBody>
                </Table>
            }
        </TableContainer>
    )
}

export default InvestmentTable