import React from 'react'
import {
    Box, Button, CircularProgress, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography, Tooltip, IconButton,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import SCRow from './SCRow';
import { Address } from '@emurgo/cardano-serialization-lib-asmjs'
import { useState } from 'react';
import { showMessage } from '../../../store/actions/snackBarActions'
import { useDispatch } from 'react-redux';
import {
    UPDATE_OFFER_PURCHASE_BULK,
    ADD_CBOR_OFFER_PURCHASE_BULK,
} from '../../../Queries/SmartContracts';
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { CREATE_NOTIFICATION, LINK_NOTIFICATION_TO_MANY_USER, } from '../../../Queries/Notifications'
import RefreshIcon from '@mui/icons-material/Refresh';
import { GET_COLLECTING_FUNDS } from '../../../Queries/Funds'
import { CREATE_TRANSACTION_CORE_TEAM } from "../../../Queries/Transactions";
import { useAuth0 } from '@auth0/auth0-react';
import { CREATE_ERROR_LOG } from '../../../Queries/Errors';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const SCAdministration = ({
    dataOfferPurchases, loading, sendOrderingToParent,
    sendTotalCreatedSCToParent, sendTotalActiveSCToParent,
    sendTotalTokensActiveSCToParent, sendTotalALLIActiveSCToParent,
    totalALLISCActive
}) => {
    const { t } = useTranslation()
    const ValidatorWalletList = process.env.REACT_APP_TOKEN_WALLET
    const CoreTeamWalletList = process.env.REACT_APP_CORE_TEAM_WALLET
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    const [validatorWalletArray, setValidatorWalletArray] = useState(undefined)
    const [CoreTeamWalletArray, setCoreTeamWalletArray] = useState(undefined)

    useEffect(() => {
        setValidatorWalletArray(ValidatorWalletList?.split(','))
        setCoreTeamWalletArray(CoreTeamWalletList?.split(','))
    }, [ValidatorWalletList])

    const [createErrorLog] = useMutation(CREATE_ERROR_LOG)
    const [fetchCollectingFunds, { data: dataCollectingFunds }] = useLazyQuery(GET_COLLECTING_FUNDS);
    const [updatedDataOfferPurchases, setUpdatedDataOfferPurchases] = useState([])

    useEffect(() => {
        setUpdatedDataOfferPurchases(dataOfferPurchases)
    }, [dataOfferPurchases])

    function calculateTotalAmountForAllFunds(funds) {
        const results = [];
        funds?.forEach(fund => {
            let totalAmount = 0;
            fund?.offerPurchase?.forEach(offer => {
                if (offer?.status !== "Investment Intention" && offer?.status !== "Cancelled") {
                    totalAmount += offer?.amount;
                }
            });
            results.push({
                fundName: fund?.name,
                totalAmount: totalAmount
            });
        });
        return results;
    }

    const totalAmountByFund = calculateTotalAmountForAllFunds(dataCollectingFunds?.funds);

    useEffect(() => {
        fetchCollectingFunds()
    }, [fetchCollectingFunds]);

    const [dataFromChild, setDataFromChild] = useState([]);
    const [dataFromChildID, setDataFromChildID] = useState([]);
    const [dataAmountToWithdraw, setDataAmountToWithdraw] = useState([])
    const [dataFromChildUSERS, setDataFromChildUSERS] = useState([]);
    const dispatch = useDispatch()
    const [creatingTx, setCreatingTx] = useState(false)
    const [XChangeAddress, setXChangeAddress] = useState(undefined)
    const [API, setAPI] = useState(undefined)
    // eslint-disable-next-line
    const [selectedWallet, setSelectedWallet] = useState(null)
    const marloweApiUrlWithdrawals = process.env.REACT_APP_DEMETER_URL + '/withdrawals'
    const pureMarloweApiUrl = process.env.REACT_APP_DEMETER_URL
    const demeterKey = process.env.REACT_APP_DEMETER_KEY
    const delay = 5000
    const [updateOfferPurchase] = useMutation(UPDATE_OFFER_PURCHASE_BULK)
    const [addCborOfferPurchase] = useMutation(ADD_CBOR_OFFER_PURCHASE_BULK)
    const [createTransactionCoreTeam] = useMutation(CREATE_TRANSACTION_CORE_TEAM)
    const newTransactionId = uuidv4()
    const todayDate = new Date().toISOString()
    const { user } = useAuth0()

    function calculateTotal(arr) {
        const total = arr.reduce((acc, num) => acc + num, 0);
        const result = isNaN(total) ? 0 : parseFloat(total);

        return result;
    }

    const totalAmountWithdrawing = calculateTotal(dataAmountToWithdraw);

    function getLocalStorageField(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    const [localStorageWallet, setLocalStorageWallet] = useState(getLocalStorageField('wallet'));

    useEffect(() => {
        if (localStorageWallet !== null) {
            setSelectedWallet(localStorageWallet)
        } else if (localStorageWallet === null) {
            setSelectedWallet(null)
        }
    }, [localStorageWallet])

    useEffect(() => {
        const interval = setInterval(() => {
            setLocalStorageWallet(getLocalStorageField('wallet'));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const getChangeAddress = async () => {
        if (API !== undefined) {
            try {
                const raw = await API.getChangeAddress()
                const changeAddressObj = Address.from_bytes(
                    Buffer.from(raw, 'hex')
                ).to_bech32()
                setXChangeAddress(changeAddressObj)
            } catch (err) {
                setSelectedWallet(null)
                //Err
            }
        }
    }

    const [wallets, setWallets] = useState([])

    async function checkEnabledStatus(walletNames) {
        // eslint-disable-next-line
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                            setSelectedWallet(walletName)
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}:`, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );
    }

    useEffect(() => {
        checkEnabledStatus(wallets)
        const intervalId = setInterval(() => {
            checkEnabledStatus(wallets)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [wallets])

    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])
    const [walletEnabled, setWalletEnabled] = useState(false)

    const checkIfWalletEnabled = async () => {
        let walletIsEnabled = false
        try {
            const walletName = localStorageWallet
            walletIsEnabled = await window.cardano[walletName].isEnabled()
            setWalletEnabled(walletIsEnabled)
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return walletIsEnabled
    }

    const enableWallet = async () => {
        const walletKey = localStorageWallet
        try {
            setAPI(await window.cardano[walletKey].enable())
            setWalletEnabled(true)
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return checkIfWalletEnabled()
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (localStorageWallet !== null) {
                enableWallet()
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [localStorageWallet]);

    const refreshData = async () => {
        try {
            if (walletEnabled && API !== null) {
                await getChangeAddress()
            }
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshData();
        }, 2000);

        return () => clearInterval(intervalId);
    }, [API, localStorageWallet]);

    const handleChildData = (data) => {
        if (data !== undefined) {
            setDataFromChild((prevData) => [...prevData, data]);
        }
    };

    const [dataReceivedFromChildCreatingEmptyTx, setDataReceivedFromChildCreatingEmptyTx] = useState(false)

    const handleChildDataCreatingEmptyTx = (data) => {
        if (data !== undefined) {
            setDataReceivedFromChildCreatingEmptyTx(data);
        }
    };

    const handleChildDataRemove = (dataToRemove) => {
        setDataFromChild((prevData) =>
            prevData.filter((data) => data !== dataToRemove)
        );
    };

    const handleChildDataID = (data) => {
        if (data !== undefined) {
            setDataFromChildID((prevData) => [...prevData, data]);
        }
    };

    const handleChildDataRemoveID = (dataToRemove) => {
        setDataFromChildID((prevData) =>
            prevData.filter((data) => data !== dataToRemove)
        );
    };

    const handleAmountAdd = (data) => {
        if (data !== undefined) {
            setDataAmountToWithdraw((prevData) => [...prevData, data]);
        }
    };

    const handleAmountRemove = (dataToRemove) => {
        setDataAmountToWithdraw((prevData) =>
            prevData.filter((data) => data !== dataToRemove)
        );
    };

    const handleChildDataUSERS = (data) => {
        if (data !== undefined) {
            setDataFromChildUSERS((prevData) => [...prevData, data]);
        }
    };

    const handleChildDataRemoveUSERS = (dataToRemove) => {
        setDataFromChildUSERS((prevData) =>
            prevData.filter((data) => data !== dataToRemove)
        );
    };

    const [createNotification] = useMutation(CREATE_NOTIFICATION)
    const [linkNotificationToUser] = useMutation(LINK_NOTIFICATION_TO_MANY_USER)

    async function handleWithdraw() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.withdraw-tx-json',
                    'dmtr-api-key': demeterKey,
                    'X-Change-Address': XChangeAddress,
                },
                body: JSON.stringify({
                    "payouts": dataFromChild
                })
            };
            const responseTXcreated = await fetch(marloweApiUrlWithdrawals, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! ADDING CBOR TO SELECTED OFFER PURCHASE
            await addCborOfferPurchase({
                variables: {
                    offerId: dataFromChildID,
                    txCbor: createdTXData?.resource?.tx?.cborHex,
                    withdrawLink: createdTXData?.links?.withdrawal
                }
            })

            // //! SIGNING TX - START
            await API.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'error' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.withdrawal)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))

                await updateOfferPurchase({
                    variables: {
                        offerId: dataFromChildID,
                        status: "Core Withdrawn - Pending",
                        txId: createdTXData?.links?.withdrawal,
                        lastUpdate: todayDate
                    }
                })

                const notifID = await uuidv4()
                const dateTime = await moment.utc()
                await createNotification({
                    variables: {
                        notificationID: notifID,
                        msg: `RDYWITHDRAW - Core Team`,
                        date: dateTime,
                        link: '/wallet',
                    },
                })
                await linkNotificationToUser({
                    variables: {
                        notificationID: notifID,
                        otherUserId: dataFromChildUSERS,
                    },
                })
                await createTransactionCoreTeam({
                    variables: {
                        id: newTransactionId,
                        date: todayDate,
                        type: "Withdraw (Core Team) - FakeUSD",
                        status: "Confirmed",
                        amount: totalAmountWithdrawing,
                        hash: createdTXData?.links?.withdrawal,
                        userId: user?.email || user?.sub,
                        transactionId: newTransactionId,
                        offPurId: dataFromChildID[0]
                    }
                })

                await setDataFromChild([])
                await setDataFromChildID([])
                await setDataFromChildUSERS([])
            }).catch(function (error) {
                dispatch(showMessage({ message: 'Error 1015 - Something went wrong, please try again' }));
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1015",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                setDataFromChild([])
                setDataFromChildID([])
                setDataFromChildUSERS([])
            })
            // //! SIGNING TX - END
            await setCreatingTx(false);
            return;
        } catch (error) {
            setCreatingTx(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1016",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1016 - Something went wrong, please try again' }));
        }
    }

    const [isCoreTeamWallet, setIsCoreTeamWallet] = useState(null)
    const [isValidatorWallet, setIsValidatorWallet] = useState(null)

    useEffect(() => {
        if (XChangeAddress !== undefined) {
            setIsCoreTeamWallet(CoreTeamWalletArray.includes(XChangeAddress));
            setIsValidatorWallet(validatorWalletArray.includes(XChangeAddress));
        }
    }, [XChangeAddress, CoreTeamWalletArray, validatorWalletArray]);

    const [ordering, setOrdering] = useState('date_desc');

    const handleUpdateOrdering = () => {
        sendOrderingToParent(ordering);
    };

    useEffect(() => {
        handleUpdateOrdering();
    }, [ordering, sendOrderingToParent]);

    const [timedLoading, setTimedLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimedLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);


    const [isClickedRefresh, setIsClickedRefresh] = useState(false);

    const handleClickRefresh = () => {
        setIsClickedRefresh(true);
        setTimeout(() => {
            setIsClickedRefresh(false);
        }, 1000);
    };

    function calculateTotalAmountCreatedSC(arr) {
        return arr.reduce((total, item) => {
            if (item.smartContract !== null) {
                return total + 1;
            }
            return total;
        }, 0);
    }

    const totalAmountCreatedSc = calculateTotalAmountCreatedSC(updatedDataOfferPurchases);

    useEffect(() => {
        sendTotalCreatedSCToParent(totalAmountCreatedSc)
    }, [totalAmountCreatedSc])

    const [amountActiveContracts, setAmountActiveContracts] = useState(0);

    const handleContractActive = (isActive) => {
        if (isActive === true) {
            setAmountActiveContracts((prevTotal) => prevTotal + 1);
        }
    };

    useEffect(() => {
        sendTotalActiveSCToParent(amountActiveContracts)
    }, [amountActiveContracts])

    const [amountTokensActiveContracts, setAmountTokensActiveContracts] = useState(0);

    const handleAmountTokenInActive = (amount) => {
        if (amount) {
            setAmountTokensActiveContracts((prevTotal) => prevTotal + amount);
        }
    };

    useEffect(() => {
        sendTotalTokensActiveSCToParent(amountTokensActiveContracts)
    }, [amountTokensActiveContracts])

    const [amountALLIActiveContracts, setAmountALLIActiveContracts] = useState(0);

    const handleAmountALLIInActive = (amount) => {
        if (amount) {
            setAmountALLIActiveContracts((prevTotal) => prevTotal + amount);
        }
    };

    useEffect(() => {
        sendTotalALLIActiveSCToParent(amountALLIActiveContracts - totalALLISCActive);
    }, [amountALLIActiveContracts, totalALLISCActive]);

    return (
        <>
            {loading || timedLoading ?
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 1, mt: 2 }}>
                    <CircularProgress />
                </Box>
                :
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 1, mt: 1 }}>
                    {updatedDataOfferPurchases?.length > 0 ?
                        <>
                            <TableContainer sx={{ width: '100%', height: '62vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                                <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                onClick={() => {
                                                    if (ordering != 'username_asc') {
                                                        setOrdering('username_asc')
                                                    }
                                                    if (ordering != 'username_desc') {
                                                        setOrdering('username_desc')
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }} align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.USERNAME')}
                                                    {ordering != 'username_asc' && ordering != 'username_desc' &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering == 'username_desc' &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering == 'username_asc' &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell onClick={() => {
                                                if (ordering != 'date_asc') {
                                                    setOrdering('date_asc')
                                                }
                                                if (ordering != 'date_desc') {
                                                    setOrdering('date_desc')
                                                }
                                            }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }} align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.NEW_EVENT_DATE')}
                                                    {ordering != 'date_asc' && ordering != 'date_desc' &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering == 'date_desc' &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering == 'date_asc' &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell onClick={() => {
                                                if (ordering != 'amount_asc') {
                                                    setOrdering('amount_asc')
                                                }
                                                if (ordering != 'amount_desc') {
                                                    setOrdering('amount_desc')
                                                }
                                            }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }} align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.INVESTMENT_DETAIL_AMOUNT')}
                                                    {ordering != 'amount_asc' && ordering != 'amount_desc' &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering == 'amount_desc' &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering == 'amount_asc' &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell onClick={() => {
                                                if (ordering != 'status_asc') {
                                                    setOrdering('status_asc')
                                                }
                                                if (ordering != 'status_desc') {
                                                    setOrdering('status_desc')
                                                }
                                            }}
                                                sx={{
                                                    backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                    cursor: 'pointer',
                                                }} align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('ADMIN.STATUS')}
                                                    {ordering != 'status_asc' && ordering != 'status_desc' &&
                                                        <SwapVertIcon />
                                                    }
                                                    {ordering == 'status_desc' &&
                                                        <ArrowUpwardIcon />
                                                    }
                                                    {ordering == 'status_asc' &&
                                                        <ArrowDownwardIcon />
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", maxWidth: '50px' }} align="center">ID</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('ADMIN.SC_LINK')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('ADMIN.TX_LINK')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">
                                                SC Details
                                                <Tooltip
                                                    sx={{ ml: 1 }}
                                                    title={
                                                        <div>
                                                            <Typography sx={{ fontWeight: 'bold' }}>Refresh All</Typography>
                                                        </div>
                                                    }>
                                                    <IconButton edge='end' size='small' onClick={handleClickRefresh}>
                                                        <RefreshIcon fontSize='24px' sx={{ color: '#0000ff' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">
                                                {dataFromChildID?.length > 0 &&
                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
                                                        {creatingTx ?
                                                            <CircularProgress />
                                                            :
                                                            <Button
                                                                size='small'
                                                                onClick={() => handleWithdraw()}
                                                                variant='contained'>
                                                                {t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')} {dataFromChildID.length}
                                                            </Button>
                                                        }
                                                    </Box>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            {updatedDataOfferPurchases?.map((offer) => (
                                                <SCRow
                                                    sendDataToParent={handleChildData}
                                                    sendDataToParentRemove={handleChildDataRemove}
                                                    sendDataToParentID={handleChildDataID}
                                                    sendDataToParentRemoveID={handleChildDataRemoveID}
                                                    sendAmountToParentAdd={handleAmountAdd}
                                                    sendAmountToParentRemove={handleAmountRemove}
                                                    sendDataToParentUSERS={handleChildDataUSERS}
                                                    sendDataToParentRemoveUSERS={handleChildDataRemoveUSERS}
                                                    key={offer.id}
                                                    data={offer}
                                                    allData={updatedDataOfferPurchases}
                                                    totalAmountByFund={totalAmountByFund}
                                                    totalSelected={dataFromChild.length}
                                                    isCoreTeamWallet={isCoreTeamWallet}
                                                    isValidatorWallet={isValidatorWallet}
                                                    finalWalletAddress={XChangeAddress}
                                                    API={API}
                                                    refreshAllSc={isClickedRefresh}
                                                    setIsContractStillActive={handleContractActive}
                                                    setAmountTokenInActive={handleAmountTokenInActive}
                                                    setAmountALLIInActive={handleAmountALLIInActive}
                                                    sendDataToParentCreatingTxEmptyTx={handleChildDataCreatingEmptyTx}
                                                    dataReceivedFromChildCreatingEmptyTx={dataReceivedFromChildCreatingEmptyTx}
                                                />
                                            ))}
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        : timedLoading ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 1, mt: 2 }}>
                                <CircularProgress />
                            </Box>
                            :
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: '20px', mt: 8 }}>
                                    {t('ADMIN.NO_OFFERPURCHASE')}
                                </Typography>
                            </Box>
                    }
                </Box >}
        </>
    )
}

export default SCAdministration