import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GET_USER_INFO } from '../../../Queries/Users'
import { GET_FUND_PURPOSE } from '../../../Queries/Funds';
import { GET_ALL_TOKENS } from '../../../Queries/Tokens';
import { Autocomplete, Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux'
import moment from 'moment';
import { push } from 'connected-react-router'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { goBack } from 'connected-react-router'
import { imgUploadHandler } from '../../../utils/fileUpload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloseIcon from '@mui/icons-material/Close'
import { CREATE_FUND, CREATE_EVENT_RELATE_FUND, RELATE_FUND_TOKEN } from '../../../Queries/Admin';
import { CREATE_TOKEN } from '../../../Queries/Admin';
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../../store/actions/snackBarActions'
import i18n from "i18next";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { NumericFormat } from 'react-number-format';
import TokenAllies from '../../../../src/assets/icons/TokenAlliesIcon.svg'

const CreateNewFund = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const { user } = useAuth0()
    const { t } = useTranslation()
    const [createFund] = useMutation(CREATE_FUND)
    const [createEvent] = useMutation(CREATE_EVENT_RELATE_FUND)
    const [relateFundToken] = useMutation(RELATE_FUND_TOKEN)
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    const [getFundPurpose, { data: fundPurpose }] = useLazyQuery(GET_FUND_PURPOSE)
    const [getTokens, { data: dataTokens }] = useLazyQuery(GET_ALL_TOKENS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    const [filteredTokens, setFilteredTokens] = useState([]);

    useEffect(() => {
        const filtered = dataTokens?.tokens?.filter(token =>
            !['DVC', 'tstALLI', 'ALLI'].includes(token?.symbol)
        );
        setFilteredTokens(filtered);
    }, [dataTokens]);

    useEffect(() => {
        getUserData()
        getFundPurpose()
        getTokens()
    }, [getUserData, getFundPurpose, getTokens]);

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    function convertToNumberWithCommas(numWithCommas) {
        if (typeof numWithCommas === 'string') {
            return parseFloat(numWithCommas.replace(/,/g, ''));
        } else {
            return NaN;
        }
    }

    const [fundImgURLState, setFundImgURLState] = useState(undefined)
    const uploadFundImgHandler = async (file) => {
        const fundImgURL = await imgUploadHandler(file)
        setFundImgURLState(fundImgURL)
    }


    const [ndaURLState, setndaURLState] = useState('')
    const uploadNdaHandler = async (file) => {
        const ndaURL = await imgUploadHandler(file)
        setndaURLState(ndaURL)
    }

    const [tycURLState, settycURLState] = useState('')
    const uploadTycHandler = async (file) => {
        const tycURL = await imgUploadHandler(file)
        settycURLState(tycURL)
    }

    const [fundName, setFundName] = useState('')
    const [fundAmountToReach, setFundAmountToReach] = useState('')
    const pureFundAmountToReach = convertToNumberWithCommas(fundAmountToReach)

    const [minimumAmountToInvest, setMinimumAmountToInvest] = useState(undefined)
    const pureMinimumAmountToInvest = convertToNumberWithCommas(minimumAmountToInvest)

    const [maximumAmountToInvest, setMaximumAmountToInvest] = useState(undefined)
    const pureMaximumAmountToInvest = convertToNumberWithCommas(maximumAmountToInvest)

    const [maxOptionalDeclaration, setMaxOptionalDeclaration] = useState(undefined)
    const pureMaxOptionalDeclaration = convertToNumberWithCommas(maxOptionalDeclaration)

    const [errorMinMaxToInvest, setErrorMinMaxToInvest] = useState(false)

    const [minimumCompanyAsking, setMinimumCompanyAsking] = useState(undefined)
    const pureMinimumCompanyAsking = convertToNumberWithCommas(minimumCompanyAsking)

    const [maximumCompanyAsking, setMaximumCompanyAsking] = useState(undefined)
    const pureMaximumCompanyAsking = convertToNumberWithCommas(maximumCompanyAsking)

    const [errorMinMaxCompanyAsking, setErrorMinMaxCompanyAsking] = useState(false)
    const [minimumSuccessThresshold, setMinimumSuccessThresshold] = useState('')
    const [expectedTimeROI, setExpectedTimeROI] = useState('')
    const [roiMultiplier, setRoiMultiplier] = useState('')
    const [vesting, setVesting] = useState('')
    const [purposeId, setPurposeId] = useState('')
    const [purposeText, setPurposeText] = useState('')
    const [tokenIdToRelate, setTokenIdToRelate] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [descriptionEs, setDescriptionEs] = useState('')
    const [descriptionPt, setDescriptionPt] = useState('')

    function addDayToDate(date) {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        }
    }

    const todayDate = new Date().toISOString().split('T')[0]
    const [collectingStartDate, setCollectingStartDate] = useState(undefined)
    const [collectingEndDate, setCollectingEndDate] = useState(undefined)
    const nextDayToCollectingStart = addDayToDate(collectingStartDate)?.toISOString('en-EN').split('T')[0]
    const nextDayToCollectingEnd = addDayToDate(collectingEndDate)?.toISOString('en-EN').split('T')[0]


    const [openStartDate, setOpenStartDate] = useState(undefined)
    const [openEndDate, setOpenEndDate] = useState(undefined)
    const nextDayToOpenStart = addDayToDate(openStartDate)?.toISOString('en-EN').split('T')[0]
    const nextDayToOpenEnd = addDayToDate(openEndDate)?.toISOString('en-EN').split('T')[0]


    const [governanceStartDate, setGovernanceStartDate] = useState(undefined)
    const [governanceEndDate, setGovernanceEndDate] = useState(undefined)
    const nextDayToGovernanceStart = addDayToDate(governanceStartDate)?.toISOString('en-EN').split('T')[0]
    const nextDayToGovernanceEnd = addDayToDate(governanceEndDate)?.toISOString('en-EN').split('T')[0]


    const [vestingStartDate, setVestingStartDate] = useState(undefined)
    const [vestingEndDate, setVestingEndDate] = useState(undefined)
    const nextDayToVestingStart = addDayToDate(vestingStartDate)?.toISOString('en-EN').split('T')[0]

    const [datePlusROI, setDatePlusROI] = useState(null);

    const calculateNewDate = () => {
        if (collectingStartDate && expectedTimeROI) {
            const startDate = new Date(collectingStartDate);
            const yearsToAdd = parseInt(expectedTimeROI);

            const newDate = new Date(startDate);
            newDate.setFullYear(startDate.getFullYear() + yearsToAdd);

            setDatePlusROI(newDate.toISOString().split('T')[0]);
        }
    };

    useEffect(() => {
        calculateNewDate();
    }, [collectingStartDate, expectedTimeROI]);

    const [selectedDescriptionRadio, setSelectedDescriptionRadio] = useState('EN');

    const handleChangeSelectedDescription = (event) => {
        setSelectedDescriptionRadio(event.target.value);
    };

    useEffect(() => {
        if (Number(fundAmountToReach) < 0) {
            setFundAmountToReach(0)
        }
        if (Number(minimumAmountToInvest) < 0) {
            setMinimumAmountToInvest(0)
        }
        if (Number(maximumAmountToInvest) < 0) {
            setMaximumAmountToInvest(0)
        }
        if (Number(minimumCompanyAsking) < 0) {
            setMinimumCompanyAsking(0)
        }
        if (Number(maximumCompanyAsking) < 0) {
            setMaximumCompanyAsking(0)
        }
        if (Number(minimumSuccessThresshold) < 0) {
            setMinimumSuccessThresshold(0)
        }
        if (Number(minimumSuccessThresshold) > 100) {
            setMinimumSuccessThresshold(100)
        }
        if (Number(expectedTimeROI) <= 0) {
            setExpectedTimeROI(1)
        }
        if (Number(pureMinimumAmountToInvest) >= Number(pureMaximumAmountToInvest)) {
            setErrorMinMaxToInvest(true)
        }
        if (Number(pureMinimumAmountToInvest) < Number(pureMaximumAmountToInvest)) {
            setErrorMinMaxToInvest(false)
        }
        if (Number(pureMaximumAmountToInvest) <= Number(pureMinimumAmountToInvest)) {
            setErrorMinMaxToInvest(true)
        }
        if (Number(pureMaximumAmountToInvest) > Number(pureMinimumAmountToInvest)) {
            setErrorMinMaxToInvest(false)
        }
        if (Number(pureMinimumCompanyAsking) >= Number(pureMaximumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(true)
        }
        if (Number(pureMinimumCompanyAsking) < Number(pureMaximumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(false)
        }
        if (Number(pureMaximumCompanyAsking) <= Number(pureMinimumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(true)
        }
        if (Number(pureMaximumCompanyAsking) > Number(pureMinimumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(false)
        }
    }, [
        fundAmountToReach,
        minimumAmountToInvest,
        maximumAmountToInvest,
        minimumCompanyAsking,
        maximumCompanyAsking,
        minimumSuccessThresshold,
        expectedTimeROI,
        errorMinMaxToInvest,
        errorMinMaxCompanyAsking
    ])


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px',
        width: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const [sendingMutation, setSendingMutation] = useState(false)

    const uniqueID = uuidv4()
    const uniqueCollectingID = uuidv4()
    const uniquePublishedID = uuidv4()
    const uniqueOpenID = uuidv4()
    const uniqueAssessmentID = uuidv4()
    const uniqueGovernanceID = uuidv4()
    const uniqueVestingID = uuidv4()
    const uniqueExecutionID = uuidv4()
    const uniqueROIID = uuidv4()
    const uniqueClosedID = uuidv4()

    const handlePublishDraft = async () => {
        setSendingMutation(true)
        await createFund({
            variables: {
                id: uniqueID,
                name: fundName,
                amountToReach: Number(pureFundAmountToReach),
                minInvestment: Number(pureMinimumAmountToInvest),
                maxInvestment: Number(pureMaximumAmountToInvest),
                minCompanyAsk: Number(pureMinimumCompanyAsking),
                maxCompanyAsk: Number(pureMaximumCompanyAsking),
                maxOptionalLegalInfo: Number(pureMaxOptionalDeclaration),
                successThreshold: Number(minimumSuccessThresshold),
                roiTime: expectedTimeROI + ' years',
                roiMultiplier: roiMultiplier,
                vestingPlan: vesting,
                descriptionEn: descriptionEn,
                descriptionEs: descriptionEs,
                descriptionPt: descriptionPt,
                status: "Draft",
                image: fundImgURLState || TokenAllies,
                ndaFile: ndaURLState,
                tycFile: tycURLState,
                purposeId: purposeId
            }
        })
        await createEvent({
            variables: {
                id: uniqueCollectingID,
                startDate: collectingStartDate,
                endDate: collectingEndDate,
                titleEn: 'Collecting',
                detailEn: 'Collecting event for ' + fundName,
                titleEs: 'Collecting',
                detailEs: 'Evento Collecting de ' + fundName,
                titlePt: 'Collecting',
                detailPt: 'Collecting de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniquePublishedID,
                startDate: collectingEndDate,
                endDate: openStartDate,
                titleEn: 'Published',
                detailEn: 'Published event for ' + fundName,
                titleEs: 'Published',
                detailEs: 'Evento Published de ' + fundName,
                titlePt: 'Published',
                detailPt: 'Published de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueOpenID,
                startDate: openStartDate,
                endDate: openEndDate,
                titleEn: 'Open',
                detailEn: 'Open event for ' + fundName,
                titleEs: 'Open',
                detailEs: 'Evento Open de ' + fundName,
                titlePt: 'Open',
                detailPt: 'Evento Open de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueAssessmentID,
                startDate: openEndDate,
                endDate: governanceStartDate,
                titleEn: 'Assessment',
                detailEn: 'Assessment event for ' + fundName,
                titleEs: 'Assessment',
                detailEs: 'Evento Assessment de ' + fundName,
                titlePt: 'Assessment',
                detailPt: 'Evento Assessment de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueGovernanceID,
                startDate: governanceStartDate,
                endDate: governanceEndDate,
                titleEn: 'Governance',
                detailEn: 'Governance event for ' + fundName,
                titleEs: 'Governance',
                detailEs: 'Evento Governance de ' + fundName,
                titlePt: 'Governance',
                detailPt: 'Evento Governance de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueVestingID,
                startDate: vestingStartDate,
                endDate: vestingEndDate,
                titleEn: 'Vesting',
                detailEn: 'Vesting event for ' + fundName,
                titleEs: 'Vesting',
                detailEs: 'Evento Vesting de ' + fundName,
                titlePt: 'Vesting',
                detailPt: 'Evento Vesting de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueExecutionID,
                startDate: datePlusROI,
                endDate: datePlusROI,
                titleEn: 'Execution',
                detailEn: 'Execution event for ' + fundName,
                titleEs: 'Execution',
                detailEs: 'Evento Execution de ' + fundName,
                titlePt: 'Execution',
                detailPt: 'Evento Execution de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueROIID,
                startDate: datePlusROI,
                endDate: datePlusROI,
                titleEn: 'ROI',
                detailEn: 'ROI event for ' + fundName,
                titleEs: 'ROI',
                detailEs: 'Evento ROI de ' + fundName,
                titlePt: 'ROI',
                detailPt: 'Evento ROI de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueClosedID,
                startDate: datePlusROI,
                endDate: datePlusROI,
                titleEn: 'Closed',
                detailEn: 'Closed event for ' + fundName,
                titleEs: 'Closed',
                detailEs: 'Evento Closed de ' + fundName,
                titlePt: 'Closed',
                detailPt: 'Evento Closed de ' + fundName,
                fundId: uniqueID
            }
        })
        await relateFundToken({
            variables: {
                tokenId: tokenIdToRelate,
                fundId: uniqueID
            }
        })
        setSendingMutation(false)
        await dispatch(push('/admin-fund-management'))
        await dispatch(showMessage({ message: 'Fund saved as Draft!', variant: 'success' }))
    }

    const handlePublishCollecting = async () => {
        setSendingMutation(true)
        await createFund({
            variables: {
                id: uniqueID,
                name: fundName,
                amountToReach: Number(pureFundAmountToReach),
                minInvestment: Number(pureMinimumAmountToInvest),
                maxInvestment: Number(pureMaximumAmountToInvest),
                minCompanyAsk: Number(pureMinimumCompanyAsking),
                maxCompanyAsk: Number(pureMaximumCompanyAsking),
                maxOptionalLegalInfo: Number(pureMaxOptionalDeclaration),
                successThreshold: Number(minimumSuccessThresshold),
                roiTime: expectedTimeROI + ' years',
                roiMultiplier: roiMultiplier,
                vestingPlan: vesting,
                descriptionEn: descriptionEn,
                descriptionEs: descriptionEs,
                descriptionPt: descriptionPt,
                status: "Collecting",
                image: fundImgURLState || TokenAllies,
                ndaFile: ndaURLState,
                tycFile: tycURLState,
                purposeId: purposeId
            }
        })
        await createEvent({
            variables: {
                id: uniqueCollectingID,
                startDate: collectingStartDate,
                endDate: collectingEndDate,
                titleEn: 'Collecting',
                detailEn: 'Collecting event for ' + fundName,
                titleEs: 'Collecting',
                detailEs: 'Evento Collecting de ' + fundName,
                titlePt: 'Collecting',
                detailPt: 'Evento Collecting de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniquePublishedID,
                startDate: collectingEndDate,
                endDate: openStartDate,
                titleEn: 'Published',
                detailEn: 'Published event for ' + fundName,
                titleEs: 'Published',
                detailEs: 'Evento Published de ' + fundName,
                titlePt: 'Published',
                detailPt: 'Published de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueOpenID,
                startDate: openStartDate,
                endDate: openEndDate,
                titleEn: 'Open',
                detailEn: 'Open event for ' + fundName,
                titleEs: 'Open',
                detailEs: 'Evento Open de ' + fundName,
                titlePt: 'Open',
                detailPt: 'Evento Open de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueAssessmentID,
                startDate: openEndDate,
                endDate: governanceStartDate,
                titleEn: 'Assessment',
                detailEn: 'Assessment event for ' + fundName,
                titleEs: 'Assessment',
                detailEs: 'Evento Assessment de ' + fundName,
                titlePt: 'Assessment',
                detailPt: 'Evento Assessment de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueGovernanceID,
                startDate: governanceStartDate,
                endDate: governanceEndDate,
                titleEn: 'Governance',
                detailEn: 'Governance event for ' + fundName,
                titleEs: 'Governance',
                detailEs: 'Evento Governance de ' + fundName,
                titlePt: 'Governance',
                detailPt: 'Evento Governance de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueVestingID,
                startDate: vestingStartDate,
                endDate: vestingEndDate,
                titleEn: 'Vesting',
                detailEn: 'Vesting event for ' + fundName,
                titleEs: 'Vesting',
                detailEs: 'Evento Vesting de ' + fundName,
                titlePt: 'Vesting',
                detailPt: 'Evento Vesting de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueExecutionID,
                startDate: datePlusROI,
                endDate: datePlusROI,
                titleEn: 'Execution',
                detailEn: 'Execution event for ' + fundName,
                titleEs: 'Execution',
                detailEs: 'Evento Execution de ' + fundName,
                titlePt: 'Execution',
                detailPt: 'Evento Execution de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueROIID,
                startDate: datePlusROI,
                endDate: datePlusROI,
                titleEn: 'ROI',
                detailEn: 'ROI event for ' + fundName,
                titleEs: 'ROI',
                detailEs: 'Evento ROI de ' + fundName,
                titlePt: 'ROI',
                detailPt: 'Evento ROI de ' + fundName,
                fundId: uniqueID,
            }
        })
        await createEvent({
            variables: {
                id: uniqueClosedID,
                startDate: datePlusROI,
                endDate: datePlusROI,
                titleEn: 'Closed',
                detailEn: 'Closed event for ' + fundName,
                titleEs: 'Closed',
                detailEs: 'Evento Closed de ' + fundName,
                titlePt: 'Closed',
                detailPt: 'Evento Closed de ' + fundName,
                fundId: uniqueID,
            }
        })
        await relateFundToken({
            variables: {
                tokenId: tokenIdToRelate,
                fundId: uniqueID
            }
        })
        setSendingMutation(false)
        await dispatch(push('/admin-fund-management'))
        await dispatch(showMessage({ message: 'Fund published!', variant: 'success' }))
    }

    const selectedLanguage = i18n.language

    const [collectingErrors, setCollectingErrors] = useState(false);
    const [governanceErrors, setGovernanceErrors] = useState(false);
    const [openErrors, setOpenErrors] = useState(false);
    const [vestingErrors, setVestingErrors] = useState(false);

    useEffect(() => {
        const errorConditionsCollecting = [
            collectingStartDate === undefined || collectingStartDate < todayDate,
            collectingEndDate === undefined || collectingEndDate < nextDayToCollectingStart
        ];
        setCollectingErrors(errorConditionsCollecting.some(condition => condition));

        const errorConditionsGovernance = [
            governanceStartDate === undefined || governanceStartDate < nextDayToOpenEnd,
            governanceEndDate === undefined || governanceEndDate < nextDayToGovernanceStart
        ];
        setGovernanceErrors(errorConditionsGovernance.some(condition => condition));

        const errorConditionsOpen = [
            openStartDate === undefined || openStartDate < nextDayToCollectingEnd,
            openEndDate === undefined || openEndDate < nextDayToOpenStart
        ];
        setOpenErrors(errorConditionsOpen.some(condition => condition));

        const errorConditionsVesting = [
            vestingStartDate === undefined || vestingStartDate < nextDayToGovernanceEnd,
            vestingEndDate === undefined || vestingEndDate < nextDayToVestingStart
        ];
        setVestingErrors(errorConditionsVesting.some(condition => condition));

    }, [todayDate,
        collectingStartDate,
        collectingEndDate,
        nextDayToCollectingStart,
        governanceStartDate,
        nextDayToOpenEnd,
        governanceEndDate,
        nextDayToGovernanceStart,
        openStartDate,
        nextDayToCollectingEnd,
        openEndDate,
        nextDayToOpenStart,
        vestingStartDate,
        nextDayToGovernanceEnd,
        vestingEndDate,
        nextDayToVestingStart
    ]);

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const [openNewToken, setOpenNewToken] = React.useState(false);
    const handleOpenNewToken = () => setOpenNewToken(true);
    const handleCloseNewToken = () => setOpenNewToken(false);

    const [createToken] = useMutation(CREATE_TOKEN)

    const [tokenImgURLState, setTokenImgURLState] = useState('')
    const uploadTokenImgHandler = async (file) => {
        const tokenImgURL = await imgUploadHandler(file)
        setTokenImgURLState(tokenImgURL)
    }

    const [symbol, setSymbol] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [policyId, setPolicyId] = useState('')
    const [encodedName, setEncodedName] = useState('')
    const [fingerprint, setFingerprint] = useState('')
    const [maxCap, setMaxCap] = useState('')

    const startDateCollecting = moment(collectingStartDate);
    const endDateCollecting = moment(collectingEndDate);
    const startDayCollecting = startDateCollecting.format('DD');
    const startMonthCollecting = startDateCollecting.format('MM');
    const endDayCollecting = endDateCollecting.format('DD');
    const endMonthCollecting = endDateCollecting.format('MM');

    const startDateOpen = moment(openStartDate);
    const endDateOpen = moment(openEndDate);
    const startDayOpen = startDateOpen.format('DD');
    const startMonthOpen = startDateOpen.format('MM');
    const endDayOpen = endDateOpen.format('DD');
    const endMonthOpen = endDateOpen.format('MM');

    const startDateGovernance = moment(governanceStartDate);
    const endDateGovernance = moment(governanceEndDate);
    const startDayGovernance = startDateGovernance.format('DD');
    const startMonthGovernance = startDateGovernance.format('MM');
    const endDayGovernance = endDateGovernance.format('DD');
    const endMonthGovernance = endDateGovernance.format('MM');

    const startDateVesting = moment(vestingStartDate);
    const endDateVesting = moment(vestingEndDate);
    const startDayVesting = startDateVesting.format('DD');
    const startMonthVesting = startDateVesting.format('MM');
    const endDayVesting = endDateVesting.format('DD');
    const endMonthVesting = endDateVesting.format('MM');

    const finalDate789 = moment(datePlusROI)
    const dayFinalDate = finalDate789.format('DD');
    const monthFinalDate = finalDate789.format('MM');
    const yearFinalDate = finalDate789.format('YYYY');

    const handleSave = async () => {
        await createToken({
            variables: {
                tokenData: {
                    id: uniqueID,
                    symbol: symbol,
                    logo: tokenImgURLState,
                    description: description,
                    marketprice: Number(price),
                    policyId: policyId,
                    encodedName: encodedName,
                    fingerprint: fingerprint,
                    maxCap: Number(maxCap)
                }
            }
        })
        await handleCloseNewToken()
        await dispatch(showMessage({ message: 'Token/NFT created!', variant: 'success' }))
    }


    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    if (isCoreTeam) {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        mb: 6
                    }}>
                    <Grid
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column'
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                m: 4,
                                alignItems: "center", justifyContent: "left"
                            }}>
                            <Box
                                onClick={handleOpenConfirmation}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    p: 0.5,
                                }}>
                                <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                                <Typography sx={{ ml: 1, color: '#9A1D4C' }}>
                                    {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 4 }}>
                                <Typography
                                    fontSize={20}
                                    fontWeight={'bold'}>
                                    {t('ADMIN.CREATE_FUND')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid >
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {/* COLUMNA IZQUIERZA */}
                        <Grid container xs={12} md={6} sx={{ pl: 8, pr: 8 }}>
                            {/* Name Field - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}>
                                        {t('ADMIN.FUND_NAME')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={fundName}
                                        onChange={(e) => setFundName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Name Field - End */}

                            {/* Amount to Reach - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.AMOUNT_GATHER')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <NumericFormat
                                        value={fundAmountToReach}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setFundAmountToReach(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(fundAmountToReach) && fundAmountToReach === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Amount to Reach - End */}

                            {/* Minimum Amount to Invest - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MIN_INVEST')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <NumericFormat
                                        value={minimumAmountToInvest}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMinimumAmountToInvest(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(minimumAmountToInvest) && minimumAmountToInvest === ''}
                                    />
                                    {errorMinMaxToInvest
                                        &&
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: '27%' }}>
                                            <Tooltip title={'MIN-MAX ERROR'}>
                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                            </Tooltip>
                                        </Box>

                                    }
                                </Grid>
                            </Grid>
                            {/* Minimum Amount to Invest - End */}

                            {/* MAXIMUM OPTIONAL INVESTMET - LEGAL INFO START */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_OPT_LEGAL')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <NumericFormat
                                        value={maxOptionalDeclaration}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMaxOptionalDeclaration(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(maxOptionalDeclaration) && maxOptionalDeclaration === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* MAXIMUM OPTIONAL INVESTMET - LEGAL INFO END */}

                            {/* Minimum Companies Asking - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MIN_REQ_COMPANIES')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <NumericFormat
                                        value={minimumCompanyAsking}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMinimumCompanyAsking(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(minimumCompanyAsking) && minimumCompanyAsking === ''}
                                    />
                                    {errorMinMaxCompanyAsking
                                        &&
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: '27%' }}>
                                            <Tooltip title={'MIN-MAX ERROR'}>
                                                <PriorityHighIcon sx={{ color: 'red' }} />
                                            </Tooltip>
                                        </Box>

                                    }
                                </Grid>
                            </Grid>
                            {/* Minimum Companies Asking - End */}

                            {/* Vesting - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        Vesting
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={vesting}
                                            onChange={(e) => setVesting(e.target.value)}
                                            displayEmpty
                                            fullWidth
                                        >
                                            <MenuItem value={'vesting 1'}>t Vesting Option 1</MenuItem>
                                            <MenuItem value={'vesting 2'}>t Vesting Option 2</MenuItem>
                                            <MenuItem value={'vesting 3'}>t Vesting Option 3</MenuItem>
                                            <MenuItem value={'vesting 4'}>t Vesting Option 4</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* Vesting - End */}



                            {/* Expected Return on Investment - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.ROI_TIME')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        fullWidth
                                        value={expectedTimeROI}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{t('ADMIN.YEARS')}</InputAdornment>,
                                        }}
                                        onChange={(e) => setExpectedTimeROI(e.target.value)}
                                        error={isNaN(expectedTimeROI) && expectedTimeROI !== ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Expected Return on Investment - End */}



                            {/* Collecting Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -1.8, fontWeight: 'bold' }}>1</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.COLLECTING_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                {collectingErrors
                                    &&
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -25 }}>
                                        <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                            <PriorityHighIcon sx={{ color: 'red' }} />
                                        </Tooltip>
                                    </Box>

                                }
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: todayDate
                                        }}
                                        fullWidth
                                        value={collectingStartDate}
                                        onChange={(e) => setCollectingStartDate(e.target.value)}
                                        error={collectingStartDate === undefined || collectingStartDate < todayDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingStart || todayDate
                                        }}
                                        fullWidth
                                        value={collectingEndDate}
                                        onChange={(e) => setCollectingEndDate(e.target.value)}
                                        error={collectingEndDate === undefined || collectingEndDate < nextDayToCollectingStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Collecting Period - End */}



                            {/* Open Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>3</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.OPEN_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                {openErrors
                                    &&
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -25 }}>
                                        <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                            <PriorityHighIcon sx={{ color: 'red' }} />
                                        </Tooltip>
                                    </Box>

                                }
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingEnd || todayDate
                                        }}
                                        fullWidth
                                        value={openStartDate}
                                        onChange={(e) => setOpenStartDate(e.target.value)}
                                        error={openStartDate === undefined || openStartDate < nextDayToCollectingEnd}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenStart || todayDate
                                        }}
                                        fullWidth
                                        value={openEndDate}
                                        onChange={(e) => setOpenEndDate(e.target.value)}
                                        error={openEndDate === undefined || openEndDate < nextDayToOpenStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Open Period - End */}


                            {/* Governance Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>5</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.GOVERNANCE_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                {governanceErrors
                                    &&
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -25 }}>
                                        <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                            <PriorityHighIcon sx={{ color: 'red' }} />
                                        </Tooltip>
                                    </Box>

                                }
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenEnd || todayDate
                                        }}
                                        fullWidth
                                        value={governanceStartDate}
                                        onChange={(e) => setGovernanceStartDate(e.target.value)}
                                        error={governanceStartDate === undefined || governanceStartDate < nextDayToOpenEnd}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToGovernanceStart || todayDate
                                        }}
                                        fullWidth
                                        value={governanceEndDate}
                                        onChange={(e) => setGovernanceEndDate(e.target.value)}
                                        error={governanceEndDate === undefined || governanceEndDate < nextDayToGovernanceStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Governance Period - End */}

                            {/* Execution Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>7</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.EXECUTION_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenEnd || todayDate
                                        }}
                                        fullWidth
                                        disabled
                                        value={datePlusROI}
                                    />
                                </Grid>

                            </Grid>
                            {/* Execution Period - End */}

                            {/* NDA UPLOADER - START */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('FUND.NDA')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            width: { xs: '100%', md: '100%' },
                                            alignItems: 'center',
                                            flexDirection: { xs: 'column', md: 'row' },
                                        }}
                                    >
                                        {ndaURLState ?
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                component="label"
                                                starticon={<UploadFileIcon />}
                                                sx={{
                                                    borderRadius: '5px',
                                                    border: '1px dashed #000000',
                                                    width: { xs: '100%', md: '100%' },
                                                    height: '50px',
                                                }}
                                            >
                                                ✅
                                            </Button>
                                            :
                                            (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    component="label"
                                                    starticon={<UploadFileIcon />}
                                                    sx={{
                                                        borderRadius: '5px',
                                                        border: '1px dashed #000000',
                                                        width: { xs: '100%', md: '100%' },
                                                        height: '50px',
                                                    }}
                                                >
                                                    <UploadFileIcon sx={{ mr: 2 }} />
                                                    <input
                                                        hidden
                                                        type="file"
                                                        accept="*/*"
                                                        onChange={(e) => uploadNdaHandler(e.target.files?.[0])}
                                                    />
                                                    {t('FUND.NDA')}
                                                </Button>
                                            )}
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* NDA UPLOADER - END */}

                            {/* TOKEN/NFT - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        Token/NFT
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    {dataTokens == undefined ?

                                        (
                                            <Autocomplete
                                                fullWidth
                                                limitTags={6}
                                                disabled
                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        {...params}
                                                        name="Token/NFT"
                                                        color="pink"
                                                    />
                                                )}
                                            />
                                        )
                                        :
                                        (
                                            <Autocomplete
                                                fullWidth
                                                limitTags={6}
                                                onChange={(event, newValue) => {
                                                    setTokenIdToRelate(newValue.id)
                                                }}
                                                options={filteredTokens}
                                                getOptionLabel={(p) => (p?.symbol)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        {...params}
                                                        name="Token/NFT"
                                                        color="pink"
                                                    />
                                                )}
                                            />
                                        )}

                                </Grid>
                            </Grid>
                            {/* TOKEN/NFT - End */}

                        </Grid>


                        {/* COLUMNA DERECHA */}
                        <Grid container xs={12} md={6} sx={{ pl: 8, pr: 8 }}>
                            {/* Image Uploader - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        Logo
                                    </InputLabel>

                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            width: { xs: '100%', md: '100%' },
                                            alignItems: 'center',
                                            flexDirection: { xs: 'column', md: 'row' },
                                        }}
                                    >
                                        {fundImgURLState ? (
                                            <img
                                                src={fundImgURLState}
                                                style={{
                                                    width: '150px',
                                                    height: '50px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                component="label"
                                                starticon={<UploadFileIcon />}
                                                sx={{
                                                    borderRadius: '5px',
                                                    border: '1px dashed #000000',
                                                    width: { xs: '100%', md: '100%' },
                                                    height: '50px',
                                                }}
                                            >
                                                <UploadFileIcon sx={{ mr: 2 }} />
                                                <input
                                                    hidden
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => uploadFundImgHandler(e.target.files?.[0])}
                                                />
                                                {t('ADMIN.FUND_IMG_UPLOAD')}
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* Minimum Success Thresshold - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.SUCC_THRESHOLD')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        fullWidth
                                        value={minimumSuccessThresshold}
                                        onChange={(e) => setMinimumSuccessThresshold(e.target.value)}
                                        error={isNaN(minimumSuccessThresshold) && minimumSuccessThresshold !== ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Minimum Success Thresshold - End */}
                            {/* Maximum Amount to Invest - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_INVEST')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <NumericFormat
                                        value={maximumAmountToInvest}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMaximumAmountToInvest(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(maximumAmountToInvest) && maximumAmountToInvest === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Maximum Amount to Invest - End */}
                            {/* PADDING - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 5,
                                    mb: 5
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}>

                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>

                                </Grid>
                            </Grid>
                            {/* PADDING - End */}
                            {/* Maximum Companies Asking - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_REQ_COMPANIES')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <NumericFormat
                                        value={maximumCompanyAsking}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMaximumCompanyAsking(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(maximumCompanyAsking) && maximumCompanyAsking === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Maximum Companies Asking - End */}

                            {/* Purpose - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.PURPOSE')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    {fundPurpose == undefined ?

                                        (
                                            <Autocomplete
                                                fullWidth
                                                limitTags={6}
                                                disabled
                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        {...params}
                                                        name="Country"
                                                        color="pink"
                                                    />
                                                )}
                                            />
                                        )
                                        :
                                        (
                                            <Autocomplete
                                                fullWidth
                                                limitTags={6}
                                                onChange={(event, newValue) => {
                                                    setPurposeId(newValue.id)
                                                    setPurposeText(newValue.titleEn)
                                                }}
                                                options={fundPurpose?.fundPurposes}
                                                getOptionLabel={(p) => (
                                                    selectedLanguage === 'en' ? p?.titleEn :
                                                        selectedLanguage === 'es' ? p?.titleEs :
                                                            selectedLanguage === 'pt' && p?.titlePt
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        {...params}
                                                        name="Purpose"
                                                        color="pink"
                                                    />
                                                )}
                                            />
                                        )}
                                </Grid>
                            </Grid>
                            {/* Purpose - End */}

                            {/* ROI MULTIPLIER - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}>
                                        {t('ADMIN.ROI_MULTIPLIER')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={roiMultiplier}
                                        onChange={(e) => setRoiMultiplier(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* ROI MULTIPLIER - End */}


                            {/* PUBLISHED Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>2</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.PUBLISHED_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingEnd || todayDate
                                        }}
                                        fullWidth
                                        value={collectingEndDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenStart || todayDate
                                        }}
                                        fullWidth
                                        value={openStartDate}
                                    />
                                </Grid>
                            </Grid>
                            {/* PUBLISHED Period - End */}


                            {/* ASSESSMENT Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>4</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.ASSESSMENT_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingEnd || todayDate
                                        }}
                                        fullWidth
                                        value={openEndDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenStart || todayDate
                                        }}
                                        fullWidth
                                        value={governanceStartDate}
                                    />
                                </Grid>
                            </Grid>
                            {/* ASSESSMENT Period - End */}

                            {/* Vesting Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>6</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.VESTING_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                {vestingErrors
                                    &&
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: -25 }}>
                                        <Tooltip title={t('ADMIN.DATES_ERROR')}>
                                            <PriorityHighIcon sx={{ color: 'red' }} />
                                        </Tooltip>
                                    </Box>

                                }
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToGovernanceEnd || todayDate
                                        }}
                                        fullWidth
                                        value={vestingStartDate}
                                        onChange={(e) => setVestingStartDate(e.target.value)}
                                        error={vestingStartDate === undefined || vestingStartDate < nextDayToGovernanceEnd}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToVestingStart || todayDate
                                        }}
                                        fullWidth
                                        value={vestingEndDate}
                                        onChange={(e) => setVestingEndDate(e.target.value)}
                                        error={vestingEndDate === undefined || vestingEndDate < nextDayToVestingStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Vesting Period - End */}

                            {/* ROI Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.ROI_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenEnd || todayDate
                                        }}
                                        fullWidth
                                        disabled
                                        value={datePlusROI}
                                    />
                                </Grid>

                            </Grid>
                            {/* ROI Period - End */}

                            {/* TYC UPLOADER - START */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('FUND.TERMS_&_CONDITIONS')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            width: { xs: '100%', md: '100%' },
                                            alignItems: 'center',
                                            flexDirection: { xs: 'column', md: 'row' },
                                        }}
                                    >
                                        {tycURLState ?
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                component="label"
                                                starticon={<UploadFileIcon />}
                                                sx={{
                                                    borderRadius: '5px',
                                                    border: '1px dashed #000000',
                                                    width: { xs: '100%', md: '100%' },
                                                    height: '50px',
                                                }}
                                            >
                                                ✅
                                            </Button>
                                            :
                                            (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    component="label"
                                                    starticon={<UploadFileIcon />}
                                                    sx={{
                                                        borderRadius: '5px',
                                                        border: '1px dashed #000000',
                                                        width: { xs: '100%', md: '100%' },
                                                        height: '50px',
                                                    }}
                                                >
                                                    <UploadFileIcon sx={{ mr: 2 }} />
                                                    <input
                                                        hidden
                                                        type="file"
                                                        accept="*/*"
                                                        onChange={(e) => uploadTycHandler(e.target.files?.[0])}
                                                    />
                                                    {t('FUND.TERMS_&_CONDITIONS')}
                                                </Button>
                                            )}
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* TYC UPLOADER - END */}

                            {/* SPACER - START */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Button variant='contained' onClick={handleOpenNewToken}> New Token/NFT </Button>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            width: { xs: '100%', md: '100%' },
                                            height: '60px',
                                            alignItems: 'center',
                                            flexDirection: { xs: 'column', md: 'row' },
                                        }}
                                    >
                                        {' '}
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* SPACER - END */}


                        </Grid>
                    </Box>

                    {/* Description ENGLISH - Start */}
                    {selectedDescriptionRadio === 'EN' &&
                        <Grid container xs={12} md={11}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center', mt: 2
                            }}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.DESCRIPTION')} - English
                                    </InputLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={selectedDescriptionRadio}
                                        onChange={handleChangeSelectedDescription}
                                        sx={{ ml: 6 }}
                                    >
                                        <FormControlLabel value="EN" control={<Radio />} label="EN" />
                                        <FormControlLabel value="ES" control={<Radio />} label="ES" />
                                        <FormControlLabel value="PT" control={<Radio />} label="PT" />
                                    </RadioGroup>
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={descriptionEn}
                                    onChange={(e) => setDescriptionEn(e.target.value)}
                                />
                            </Grid>
                        </Grid>}
                    {/* Description ENGLISH - End */}

                    {/* Description ESPAÑOL - Start */}
                    {selectedDescriptionRadio === 'ES' && <Grid container xs={12} md={11}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center', mt: 2
                        }}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.DESCRIPTION')} - Español
                                </InputLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedDescriptionRadio}
                                    onChange={handleChangeSelectedDescription}
                                    sx={{ ml: 6 }}
                                >
                                    <FormControlLabel value="EN" control={<Radio />} label="EN" />
                                    <FormControlLabel value="ES" control={<Radio />} label="ES" />
                                    <FormControlLabel value="PT" control={<Radio />} label="PT" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={2}
                                value={descriptionEs}
                                onChange={(e) => setDescriptionEs(e.target.value)}
                            />
                        </Grid>
                    </Grid>}
                    {/* Description ESPAÑOL - End */}

                    {/* Description PORTUGUÊS - Start */}
                    {selectedDescriptionRadio === 'PT' && <Grid container xs={12} md={11}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center', mt: 2
                        }}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.DESCRIPTION')} - Português
                                </InputLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedDescriptionRadio}
                                    onChange={handleChangeSelectedDescription}
                                    sx={{ ml: 6 }}
                                >
                                    <FormControlLabel value="EN" control={<Radio />} label="EN" />
                                    <FormControlLabel value="ES" control={<Radio />} label="ES" />
                                    <FormControlLabel value="PT" control={<Radio />} label="PT" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={2}
                                value={descriptionPt}
                                onChange={(e) => setDescriptionPt(e.target.value)}
                            />
                        </Grid>
                    </Grid>}
                    {/* Description PORTUGUÊS - End */}

                    {/* Preview Button - Start */}
                    <Grid container xs={12} md={8}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 2
                        }}>
                        {
                            fundName === '' ||
                                fundAmountToReach === '' ||
                                minimumAmountToInvest === '' ||
                                maximumAmountToInvest === '' ||
                                minimumCompanyAsking === '' ||
                                maximumCompanyAsking === '' ||
                                minimumSuccessThresshold === '' ||
                                expectedTimeROI === '' ||
                                vesting === '' ||
                                tokenIdToRelate === '' ||
                                purposeId === '' ||
                                descriptionEn === '' ||
                                ndaURLState === '' ||
                                tycURLState === '' ||
                                collectingStartDate === undefined ||
                                collectingEndDate === undefined ||
                                openStartDate === undefined ||
                                openEndDate === undefined ||
                                governanceStartDate === undefined ||
                                governanceEndDate === undefined ||
                                vestingStartDate === undefined ||
                                vestingEndDate === undefined ||
                                collectingErrors === true ||
                                governanceErrors === true ||
                                openErrors === true ||
                                vestingErrors === true
                                ?
                                <Button disabled variant='contained'>{t('ADMIN.PREVIEW')}</Button>
                                :
                                <Button onClick={handleOpen} variant='contained'>{t('ADMIN.PREVIEW')}</Button>}
                    </Grid>
                    {/* Preview Button - End */}


                </Box >
                <Modal
                    open={openNewToken}
                    onClose={handleCloseNewToken}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                mb: 6
                            }}>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        m: 4,
                                        alignItems: "center", justifyContent: "left"
                                    }}>
                                    <Box sx={{ ml: 4 }}>
                                        <Typography
                                            fontSize={20}
                                            fontWeight={'bold'}>
                                            {t('ADMIN.NEW_FUND_PURPOSE').split(' ')[0]} Token/NFT
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid >
                            <Grid container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }}>
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "100%"
                                    }}>
                                    <Grid item xs={12} md={7}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                width: { xs: '100%', md: '100%' },
                                                alignItems: 'center',
                                                flexDirection: { xs: 'column', md: 'row' },
                                            }}
                                        >
                                            {tokenImgURLState ? (
                                                <img
                                                    src={tokenImgURLState}
                                                    style={{
                                                        width: '150px',
                                                        height: '50px',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            ) : (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    component="label"
                                                    starticon={<UploadFileIcon />}
                                                    sx={{
                                                        borderRadius: '5px',
                                                        border: '1px dashed #000000',
                                                        width: { xs: '400px', md: '400px' },
                                                        height: '50px',
                                                    }}
                                                >
                                                    <UploadFileIcon sx={{ mr: 2 }} />
                                                    <input
                                                        hidden
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => uploadTokenImgHandler(e.target.files?.[0])}
                                                    />
                                                    Logo
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                {/* SYMBOL */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        {t('CREATE_OFFERING.NAME_TOKEN')}
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        value={symbol}
                                        onChange={(e) => setSymbol(e.target.value)}
                                    />
                                </Grid>

                                {/* DESCRIPTION */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        {t('CREATE_COMPANY.DESCRIPTION')}
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Grid>

                                {/* PRICE */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        {t('GENERAL.PRICE')}
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        type='number'
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </Grid>

                                {/* POLICY ID */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        Policy ID
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        value={policyId}
                                        onChange={(e) => setPolicyId(e.target.value)}
                                    />
                                </Grid>

                                {/* ENCODED NAME */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        {t('ADMIN.ENCODED_NAME')}
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        value={encodedName}
                                        onChange={(e) => setEncodedName(e.target.value)}
                                    />
                                </Grid>

                                {/* FINGERPRINT */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        Fingerprint
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        value={fingerprint}
                                        onChange={(e) => setFingerprint(e.target.value)}
                                    />
                                </Grid>

                                {/* MAXCAP */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Typography>
                                        MaxCap
                                    </Typography>
                                    <TextField sx={{ width: '400px' }} size='small'
                                        type='number'
                                        onWheel={(e) => e.target.blur()}
                                        value={maxCap}
                                        onChange={(e) => setMaxCap(e.target.value)}
                                    />
                                </Grid>


                                {/* FUND */}
                                <Grid item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: "50%",
                                        mt: 2
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, alignItems: 'center' }}>

                                        <Typography>
                                            {' '}
                                        </Typography>

                                    </Box>
                                </Grid>




                                {
                                    symbol === '' ||
                                        description === '' ||
                                        price === '' ||
                                        policyId === '' ||
                                        encodedName === '' ||
                                        fingerprint === '' ||
                                        maxCap === '' ||
                                        tokenImgURLState === ''
                                        ?

                                        <Button sx={{ mt: 4 }} disabled variant='contained'>{t('CO_ASSESSMENT.SAVE')}</Button>
                                        :

                                        <Button sx={{ mt: 4 }} onClick={handleSave} variant='contained'>
                                            {t('CO_ASSESSMENT.SAVE')}
                                        </Button>

                                }
                            </Grid>
                            <Modal
                                open={openConfirmation}
                                onClose={handleCloseConfirmation}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={styleConfirm}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                                    </Typography>
                                    <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                                        {t('MY_OFFERINGS.CHANGES_LOST')}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: '100%',
                                            justifyContent: 'space-evenly',
                                            mt: 2,
                                        }}
                                    >
                                        <Button variant="outlined" onClick={handleCloseConfirmation}>
                                            {t('CO_ASSESSMENT.CANCEL')}
                                        </Button>
                                        <Button variant="contained" onClick={() => dispatch(goBack())}>
                                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                        </Box >
                    </Box>
                </Modal>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box onClick={handleClose}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    pr: 4
                                }}>
                                <IconButton
                                    color="primary"
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            {fundImgURLState !== '' && <Grid container xs={12} md={12}>
                                <Grid item xs={12} md={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                    <img
                                        src={fundImgURLState}
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            objectFit: 'scale-down',
                                            borderRadius: '15px'
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                            <Grid container sx={{ mt: 1 }}>
                                <Grid item xs={12} md={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'start',
                                        ml: 6
                                    }}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                                        {fundName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1 }}>
                                <Grid item xs={12} md={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'center',
                                        ml: 6
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.DESCRIPTION')}:
                                    </InputLabel>
                                    <Typography>
                                        {descriptionEn}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.AMOUNT_GATHER')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        $ {fundAmountToReach}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.ROI_TIME')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        {expectedTimeROI}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MIN_INVEST')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        $ {minimumAmountToInvest}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_INVEST')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        $ {maximumAmountToInvest}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MIN_REQ_COMPANIES')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        $ {minimumCompanyAsking}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_REQ_COMPANIES')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        $ {maximumCompanyAsking}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.SUCC_THRESHOLD')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        {minimumSuccessThresshold}%
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        Vesting:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        {vesting}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.ROI_TIME')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        {expectedTimeROI} {t('ADMIN.YEARS')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.ROI_MULTIPLIER')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        {roiMultiplier}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.PURPOSE')}:
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        {purposeText}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_OPT_LEGAL')}
                                    </InputLabel>
                                    <Typography sx={{ ml: 1 }}>
                                        $ {maxOptionalDeclaration}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ ml: 6, mt: 1 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -1.8, fontWeight: 'bold' }}>1</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.COLLECTING_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${startMonthCollecting || ''}`).substring(0, 3)}. {startDayCollecting || ''} - {t(`MONTHS.${endMonthCollecting || ''}`).substring(0, 3)}. {endDayCollecting || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>2</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.PUBLISHED_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${endMonthCollecting || ''}`).substring(0, 3)}. {endDayCollecting || ''} - {t(`MONTHS.${startMonthOpen || ''}`).substring(0, 3)}. {startDayOpen || ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ ml: 6, mt: 1 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>3</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.OPEN_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${startMonthOpen || ''}`).substring(0, 3)}. {startDayOpen || ''} - {t(`MONTHS.${endMonthOpen || ''}`).substring(0, 3)}. {endDayOpen || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>4</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.ASSESSMENT_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${endMonthOpen || ''}`).substring(0, 3)}. {endDayOpen || ''} - {t(`MONTHS.${startMonthGovernance || ''}`).substring(0, 3)}. {startDayGovernance || ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>5</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.GOVERNANCE_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${startMonthGovernance || ''}`).substring(0, 3)}. {startDayGovernance || ''} - {t(`MONTHS.${endMonthGovernance || ''}`).substring(0, 3)}. {endDayGovernance || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>6</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.VESTING_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${startMonthVesting || ''}`).substring(0, 3)}. {startDayVesting || ''} - {t(`MONTHS.${endMonthVesting || ''}`).substring(0, 3)}. {endDayVesting || ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1, ml: 6 }}>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>7</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.EXECUTION_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${monthFinalDate || ''}`).substring(0, 3)}. {dayFinalDate || ''}, {yearFinalDate || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: actualThemeLight ? 'white' : 'black', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.ROI_PERIOD')}:
                                        </InputLabel>
                                    </Box>
                                    <Typography sx={{ ml: 1 }}>
                                        {t(`MONTHS.${monthFinalDate || ''}`).substring(0, 3)}. {dayFinalDate || ''}, {yearFinalDate || ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!sendingMutation && <Grid
                                container
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Button onClick={handleClose} sx={{ mr: 2 }} variant='outlined'>{t('CREATE_OFFERING.BUTTON_MODIFY')}</Button>
                                <Button onClick={handlePublishDraft} sx={{ ml: 2 }} variant='contained'>{t('CREATE_OFFERING.BUTTON_DRAFT')}</Button>
                                {todayDate === collectingStartDate ?
                                    <Button onClick={handlePublishCollecting} sx={{ ml: 2 }} variant='contained'>{t('ADMIN.PUBLISH_COLLECTING')}</Button>
                                    :
                                    <Button disabled sx={{ ml: 2 }} variant='contained'>{t('ADMIN.PUBLISH_COLLECTING')}</Button>
                                }
                            </Grid>}
                            {sendingMutation &&
                                <Box sx={{ width: '100%', mt: 2 }}>
                                    <LinearProgress />
                                </Box>

                            }
                        </Box>
                    </Modal>
                    <Modal
                        open={openConfirmation}
                        onClose={handleCloseConfirmation}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleConfirm}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t('MY_OFFERINGS.ARE_YOU_SURE')}
                            </Typography>
                            <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                                {t('MY_OFFERINGS.CHANGES_LOST')}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'space-evenly',
                                    mt: 2,
                                }}
                            >
                                <Button variant="outlined" onClick={handleCloseConfirmation}>
                                    {t('CO_ASSESSMENT.CANCEL')}
                                </Button>
                                <Button variant="contained" onClick={() => dispatch(goBack())}>
                                    {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </div>
            </>
        )
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default CreateNewFund