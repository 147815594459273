import { gql } from '@apollo/client'

export const GET_ALL_USERS = gql`
  query getAllUsers(
    $search: String!
    $order: [UserSort!]
    $filter: [String!]
    $activeFilter: Boolean
  ) {
    users(
      where: {
        OR: [{ userName_CONTAINS: $search }, { email_CONTAINS: $search }]
        AND: [{ has_SOME: { roleName_IN: $filter } }, { active: $activeFilter }]
      }
      options: { sort: $order }
    ) {
      id
      profilePicture
      sentMessages {
        id
        sentTo {
          id
        }
      }
      manages {
        id
        coName
        proposal {
          id
        }
      }
      memberSince
      has_url {
        link
        name
      }
      userName
      has {
        id
        roleName
      }
      email
      active
      prefers {
        id
        preferenceName
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation (
    $active: Boolean!
    $email: String!
    $id: ID!
    $userName: String!
    $memberSince: DateTime!
  ) {
    createUsers(
      input: {
        active: $active
        email: $email
        id: $id
        userName: $userName
        memberSince: $memberSince
        has: { connect: { where: { node: { id: "role-applicant" } } } }
      }
    ) {
      users {
        id
      }
    }
  }
`

export const SET_USER_AS_BLOCKED = gql`
  mutation ($userId: ID!) {
    updateUsers(where: { id: $userId }, update: { active: false }) {
      users {
        id
      }
    }
  }
`

export const SET_USER_AS_ACTIVE = gql`
  mutation ($userId: ID!) {
    updateUsers(where: { id: $userId }, update: { active: true }) {
      users {
        id
      }
    }
  }
`

export const SET_USER_AS_CORE_TEAM = gql`
  mutation ($userId: ID!) {
    updateUsers(
      where: { id: $userId }
      connect: { has: { where: { node: { id: "role-core-team" } } } }
    ) {
      users {
        id
      }
    }
  }
`

export const SET_USER_AS_NOT_CORE_TEAM = gql`
  mutation ($userId: ID!) {
    updateUsers(
      where: { id: $userId }
      disconnect: { has: { where: { node: { id: "role-core-team" } } } }
    ) {
      users {
        id
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation ($userId: ID!) {
    deleteUsers(where: { id: $userId }) {
      nodesDeleted
    }
  }
`

export const GET_ALL_PREFERENCES = gql`
  query getAllPreferences($order: [PreferencesSort!]) {
    preferences(options: { sort: $order }) {
      id
      preferenceName
      isActive
    }
  }
`

export const CREATE_PREFERENCE = gql`
  mutation ($data: [PreferencesCreateInput!]!) {
    createPreferences(input: $data) {
      preferences {
        id
      }
    }
  }
`

export const UPDATE_PREFERENCE = gql`
  mutation ($preferenceId: ID!, $preferenceName: String!) {
    updatePreferences(
      where: { id: $preferenceId }
      update: { preferenceName: $preferenceName }
    ) {
      preferences {
        id
      }
    }
  }
`

export const GET_ALL_VALIDATED_COMPANIES = gql`
  query getAllCompanies($order: [CompanySort!]) {
    companies(
      where: { AND: [{ isValidated: true }, { isDraft: false }] }
      options: { sort: $order }
    ) {
      id
      logo
      email
      coName
      isValidated
      isActive
      profileComplete
      users {
        id
        userName
      }
      proposal {
        reqInvestAmount
        fund {
          id
          name
        }
      }
      descriptionEn
      descriptionEs
      descriptionPt
      createdOn
      address
    }
  }
`

export const GET_ALL_NOT_VALIDATED_COMPANIES = gql`
  query getAllCompanies($order: [CompanySort!]) {
    companies(
      where: { AND: [{ isValidated: false }, { isDraft: false }] }
      options: { sort: $order }
    ) {
      id
      logo
      email
      coName
      isValidated
      isActive
      profileComplete
      users {
        id
        userName
      }
      proposal {
        reqInvestAmount
        fund {
          id
          name
        }
      }
      descriptionEn
      descriptionEs
      descriptionPt
      createdOn
      address
    }
  }
`

export const GET_ALL_FUNDS_ADMIN = gql`
  query ($order: [FundSort!]) {
    funds(options: { sort: $order }) {
      id
      name
      image
      status
      vestingPlan
      event {
        id
        titleEn
        startDate
        endDate
      }
      token {
        id
        symbol
      }
      status
      roiTime
      ndaFile
      tycFile
      vesting {
        id
        amount
      }
      purpose {
        titleEn
        titleEs
        titlePt
        targetEn
        targetEs
        targetPt
      }
      proposal {
        id
        reqInvestAmount
      }
      vestingPlan
      amountToReach
      minInvestment
      maxInvestment
      minCompanyAsk
      maxCompanyAsk
      roiMultiplier
      descriptionEn
      descriptionEs
      descriptionPt
      offerPurchase {
        id
        on
        txCbor
        lastTxId
        lastUpdate
        withdrawLink
        fund {
          id
          name
          image
          status
          offerPurchase {
            id
            amount
          }
          token {
            id
            logo
            symbol
            maxCap
            policyId
            description
            marketprice
            encodedName
            fingerprint
          }
          amountToReach
          successThreshold
          event {
            id
            titleEn
            detailEn
            startDate
            endDate
          }
        }
        users {
          id
          userName
        }
        transaction {
          id
          date
          type
          hash
          amount
          user {
            id
          }
        }
        amount
        price
        status
        investorAddress
        legalFullname
        legalId
        legalCountry
        legalFundsOrigin
        smartContract {
          status
          cborHex
          creator
          contractId
          id
          createdDate
          contractLink
        }
      }
      successThreshold
    }
  }
`

export const GET_SPECIFIC_FUND_ADMIN = gql`
  query ($fundId: ID!) {
    funds(where: { id: $fundId }) {
      id
      name
      image
      status
      vestingPlan
      event {
        id
        titleEn
        startDate
        endDate
      }
      token {
        id
        symbol
      }
      status
      roiTime
      ndaFile
      tycFile
      vesting {
        id
        amount
      }
      purpose {
        titleEn
        titleEs
        titlePt
        targetEn
        targetEs
        targetPt
      }
      proposal {
        id
        reqInvestAmount
      }
      vestingPlan
      amountToReach
      minInvestment
      maxInvestment
      minCompanyAsk
      maxCompanyAsk
      roiMultiplier
      descriptionEn
      descriptionEs
      descriptionPt
      offerPurchase {
        id
        on
        txCbor
        lastTxId
        lastUpdate
        withdrawLink
        fund {
          id
          name
          image
          status
          offerPurchase {
            id
            amount
          }
          token {
            id
            logo
            symbol
            maxCap
            policyId
            description
            marketprice
            encodedName
            fingerprint
          }
          amountToReach
          successThreshold
          event {
            id
            titleEn
            detailEn
            startDate
            endDate
          }
        }
        users {
          id
          userName
        }
        transaction {
          id
          date
          type
          hash
          amount
          user {
            id
          }
        }
        amount
        price
        status
        investorAddress
        coreteamAddress
        choicemakerAddress
        legalFullname
        legalId
        legalCountry
        legalFundsOrigin
        smartContract {
          status
          cborHex
          creator
          contractId
          id
          createdDate
          contractLink
        }
      }
      successThreshold
    }
  }
`

export const CREATE_NEWS_POST = gql`
  mutation (
    $data: [NewsCreateInput!]!
    $urlLink: String!
    $urlId: ID!
    $newsId: ID!
  ) {
    createNews(input: $data) {
      news {
        id
      }
    }
    createUrls(
      input: {
        id: $urlId
        link: $urlLink
        name: "NewsLink"
        news: { connect: { where: { node: { id: $newsId } } } }
      }
    ) {
      urls {
        id
      }
    }
  }
`

export const DISABLE_NEWS_POST = gql`
  mutation ($newsId: ID!) {
    updateNews(where: { id: $newsId }, update: { isActive: false }) {
      news {
        id
      }
    }
  }
`

export const ENABLE_NEWS_POST = gql`
  mutation ($newsId: ID!) {
    updateNews(where: { id: $newsId }, update: { isActive: true }) {
      news {
        id
      }
    }
  }
`

export const GET_NEWS_BY_ID = gql`
  query ($newsId: ID!) {
    news(where: { id: $newsId }) {
      id
      image
      titleEn
      titleEs
      titlePt
      contentEn
      contentEs
      contentPt
      url {
        id
        link
        name
      }
    }
  }
`

export const UPDATE_NEWS_POST = gql`
  mutation (
    $newsId: ID!
    $newsData: NewsUpdateInput!
    $urlId: ID!
    $urlLink: String!
  ) {
    updateNews(where: { id: $newsId }, update: $newsData) {
      news {
        id
      }
    }
    updateUrls(where: { id: $urlId }, update: { link: $urlLink }) {
      urls {
        id
      }
    }
  }
`

export const GET_ALL_NEWS = gql`
  query {
    news(options: { sort: { on: DESC } }) {
      id
      on
      titleEn
      titleEs
      titlePt
      contentEn
      contentEs
      contentPt
      isActive
      url {
        link
      }
      image
    }
  }
`

export const CREATE_EVENT = gql`
  mutation ($data: [EventCreateInput!]!) {
    createEvents(input: $data) {
      events {
        id
      }
    }
  }
`

export const DELETE_EVENT = gql`
  mutation ($eventId: ID!) {
    deleteEvents(where: { id: $eventId }) {
      nodesDeleted
    }
  }
`

export const UPDATE_EVENT_ADMIN = gql`
  mutation ($eventId: ID!, $eventData: EventUpdateInput!) {
    updateEvents(where: { id: $eventId }, update: $eventData) {
      events {
        id
      }
    }
  }
`

export const GET_ALL_EVENTS = gql`
  query {
    events(options: { sort: { startDate: DESC } }) {
      id
      titleEn
      titleEs
      titlePt
      detailEn
      detailEs
      detailPt
      startDate
      endDate
    }
  }
`

export const GET_EVENT_BY_ID = gql`
  query ($eventId: ID!) {
    events(where: { id: $eventId }) {
      id
      titleEn
      titleEs
      titlePt
      detailEn
      detailEs
      detailPt
      fund {
        id
        name
      }
      startDate
      endDate
    }
  }
`

export const CREATE_FUND = gql`
  mutation (
    $id: ID!
    $name: String!
    $amountToReach: Float!
    $minInvestment: Float!
    $maxInvestment: Float!
    $minCompanyAsk: Float!
    $maxCompanyAsk: Float!
    $maxOptionalLegalInfo: Float!
    $successThreshold: Float!
    $roiTime: String!
    $roiMultiplier: String!
    $vestingPlan: String!
    $descriptionEn: String!
    $descriptionEs: String!
    $descriptionPt: String!
    $status: String!
    $image: String!
    $ndaFile: String!
    $tycFile: String!
    $purposeId: ID!
  ) {
    createFunds(
      input: {
        id: $id
        name: $name
        amountToReach: $amountToReach
        minInvestment: $minInvestment
        maxInvestment: $maxInvestment
        minCompanyAsk: $minCompanyAsk
        maxCompanyAsk: $maxCompanyAsk
        maxOptionalLegalInfo: $maxOptionalLegalInfo
        successThreshold: $successThreshold
        roiTime: $roiTime
        roiMultiplier: $roiMultiplier
        vestingPlan: $vestingPlan
        descriptionEn: $descriptionEn
        descriptionEs: $descriptionEs
        descriptionPt: $descriptionPt
        status: $status
        image: $image
        ndaFile: $ndaFile
        tycFile: $tycFile
        purpose: { connect: { where: { node: { id: $purposeId } } } }
      }
    ) {
      funds {
        id
      }
    }
  }
`

export const GET_COMPANY_BY_ID = gql`
  query ($coId: ID!) {
    companies(where: { id: $coId }) {
      id
      logo
      proposal {
        fund {
          id
          name
          image
          status
          vestingPlan
          descriptionEn
          descriptionEs
          descriptionPt
        }
      }
      email
      phone
      employees
      fundationYear
      isIncorporated
      isIncJurisdiction
      annualBilling
      coName
      users {
        id
        email
        userName
        prefers {
          id
          preferenceName
        }
      }
      address
      has_url {
        id
        name
        link
      }
      country {
        name
      }
      createdOn
      descriptionEn
      descriptionEs
      descriptionPt
      isValidated
      profileComplete
      preferences {
        id
        preferenceName
      }
    }
  }
`

export const SET_COMPANY_PROFILE_AS_COMPLETE = gql`
  mutation ($companyId: ID!) {
    updateCompanies(
      where: { id: $companyId }
      update: { profileComplete: true }
    ) {
      companies {
        id
      }
    }
  }
`

export const SET_COMPANY_PROFILE_AS_INCOMPLETE = gql`
  mutation ($companyId: ID!) {
    updateCompanies(
      where: { id: $companyId }
      update: { profileComplete: false, isValidated: false }
    ) {
      companies {
        id
      }
    }
  }
`

export const SET_COMPANY_PROFILE_AS_VALIDATED = gql`
  mutation ($companyId: ID!) {
    updateCompanies(where: { id: $companyId }, update: { isValidated: true }) {
      companies {
        id
      }
    }
  }
`

export const SET_COMPANY_PROFILE_AS_NOT_VALIDATED = gql`
  mutation ($companyId: ID!) {
    updateCompanies(where: { id: $companyId }, update: { isValidated: false }) {
      companies {
        id
      }
    }
  }
`

export const GET_ALL_FUNDS = gql`
  query {
    funds {
      id
      name
      offerPurchase {
        id
      }
    }
  }
`

export const GET_ALL_FUNDS_NOT_CLOSED = gql`
  query {
    funds(where: { NOT: { status: "Closed" } }) {
      id
      status
      name
    }
  }
`

export const CREATE_EVENT_RELATE_FUND = gql`
  mutation (
    $id: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $titleEn: String!
    $detailEn: String!
    $titleEs: String!
    $detailEs: String!
    $titlePt: String!
    $detailPt: String!
    $fundId: ID!
  ) {
    createEvents(
      input: {
        id: $id
        startDate: $startDate
        endDate: $endDate
        titleEn: $titleEn
        titleEs: $titleEs
        titlePt: $titlePt
        detailEn: $detailEn
        detailEs: $detailEs
        detailPt: $detailPt
        fund: { connect: { where: { node: { id: $fundId } } } }
      }
    ) {
      events {
        id
      }
    }
  }
`

export const RELATE_FUND_TOKEN = gql`
  mutation ($tokenId: ID!, $fundId: ID!) {
    updateTokens(
      where: { id: $tokenId }
      connect: { fund: { where: { node: { id: $fundId } } } }
    ) {
      tokens {
        id
      }
    }
  }
`

export const GET_ALL_FUND_PURPOSE = gql`
  query {
    fundPurposes {
      id
      titleEn
      titleEs
      titlePt
      targetEn
      targetEs
      targetPt
      isActive
    }
  }
`

export const CREATE_FUND_PURPOSE = gql`
  mutation ($data: [FundPurposeCreateInput!]!) {
    createFundPurposes(input: $data) {
      fundPurposes {
        id
      }
    }
  }
`

export const GET_FUND_PURPOSE_BY_ID = gql`
  query ($purposeId: ID!) {
    fundPurposes(where: { id: $purposeId }) {
      id
      titleEn
      titleEs
      titlePt
      targetEn
      targetEs
      targetPt
    }
  }
`

export const EDIT_FUND_PURPOSE = gql`
  mutation ($purposeId: ID!, $updateData: FundPurposeUpdateInput!) {
    updateFundPurposes(where: { id: $purposeId }, update: $updateData) {
      fundPurposes {
        id
      }
    }
  }
`

export const PUBLISH_FUND_COLLECTING = gql`
  mutation ($fundId: ID!) {
    updateFunds(where: { id: $fundId }, update: { status: "Collecting" }) {
      funds {
        id
      }
    }
  }
`

export const UPDATE_FUND = gql`
  mutation ($fundId: ID!, $updateData: FundUpdateInput!) {
    updateFunds(where: { id: $fundId }, update: $updateData) {
      funds {
        id
      }
    }
  }
`

export const UPDATE_PURPOSE = gql`
  mutation ($oldPurposeId: ID!, $fundId: ID!, $newPurposeId: ID!) {
    updateFunds(
      where: { id: $fundId }
      disconnect: { purpose: { where: { node: { id: $oldPurposeId } } } }
      connect: { purpose: { where: { node: { id: $newPurposeId } } } }
    ) {
      funds {
        id
      }
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation ($eventId: ID!, $newData: EventUpdateInput!) {
    updateEvents(where: { id: $eventId }, update: $newData) {
      events {
        id
      }
    }
  }
`

export const GET_ALL_VESTING_EVENTS = gql`
  query ($search: String!) {
    vestingEvents(
      options: { sort: { vestingDate: DESC } }
      where: {
        OR: [
          { fund: { name_CONTAINS: $search } }
          { proposal: { titleEn_CONTAINS: $search } }
          { proposal: { titleEs_CONTAINS: $search } }
          { proposal: { titlePt_CONTAINS: $search } }
          { proposal: { company: { coName_CONTAINS: $search } } }
          {
            proposal: {
              company: { users_SOME: { userName_CONTAINS: $search } }
            }
          }
        ]
      }
    ) {
      id
      fund {
        id
        name
      }
      amount
      proposal {
        id
        titleEn
        titleEs
        titlePt
        company {
          logo
          coName
          users {
            userName
          }
        }
      }
      vestingDate
    }
  }
`

export const CREATE_VESTING_EVENT = gql`
  mutation (
    $vestingId: ID!
    $vestingAmount: Float!
    $vestingDate: DateTime!
    $fundId: ID!
    $proposalId: ID!
  ) {
    createVestingEvents(
      input: {
        id: $vestingId
        amount: $vestingAmount
        vestingDate: $vestingDate
        fund: { connect: { where: { node: { id: $fundId } } } }
        proposal: { connect: { where: { node: { id: $proposalId } } } }
      }
    ) {
      vestingEvents {
        id
      }
    }
  }
`

export const DELETE_VESTING_EVENT = gql`
  mutation ($vestingEventId: ID!) {
    deleteVestingEvents(where: { id: $vestingEventId }) {
      nodesDeleted
    }
  }
`

export const UPDATE_VESTING_EVENT = gql`
  mutation (
    $vestingEventId: ID!
    $vestingAmount: Float!
    $fundId: ID!
    $proposalId: ID!
  ) {
    updateVestingEvents(
      where: { id: $vestingEventId }
      update: { amount: $vestingAmount }
      disconnect: {
        fund: { where: { NOT: { node: { id: "PABLO123" } } } }
        proposal: { where: { NOT: { node: { id: "PABLO123" } } } }
      }
      connect: {
        fund: { where: { node: { id: $fundId } } }
        proposal: { where: { node: { id: $proposalId } } }
      }
    ) {
      vestingEvents {
        id
      }
    }
  }
`

export const GET_VESTING_EVENT_BY_ID = gql`
  query ($vestingEventId: ID!) {
    vestingEvents(where: { id: $vestingEventId }) {
      id
      amount
      fund {
        id
        name
      }
      proposal {
        id
        titleEn
        titleEs
        titlePt
        assessmentStatus
      }
    }
  }
`

export const GET_FUNDS_VESTING = gql`
  query {
    funds(where: { status: "Vesting" }) {
      id
      name
      proposal {
        id
        titleEn
        titleEs
        titlePt
        status
        assessmentStatus
        company {
          coName
          users {
            userName
          }
        }
      }
    }
  }
`

export const GET_ALL_TOKENS = gql`
  query {
    tokens(options: { sort: { symbol: ASC } }) {
      id
      logo
      fund {
        id
        name
      }
      symbol
      maxCap
      policyId
      description
      marketprice
      encodedName
      fingerprint
    }
  }
`

export const DELETE_TOKEN = gql`
  mutation ($tokenId: ID!) {
    deleteTokens(where: { id: $tokenId }) {
      nodesDeleted
    }
  }
`

export const CREATE_TOKEN = gql`
  mutation ($tokenData: [TokenCreateInput!]!) {
    createTokens(input: $tokenData) {
      tokens {
        id
      }
    }
  }
`

export const CREATE_TOKEN_FUND = gql`
  mutation (
    $id: ID!
    $symbol: String!
    $logo: String!
    $description: String!
    $marketprice: Float!
    $policyId: String!
    $encodedName: String!
    $fingerprint: String!
    $maxCap: Int!
    $fundId: ID!
  ) {
    createTokens(
      input: {
        id: $id
        symbol: $symbol
        logo: $logo
        description: $description
        marketprice: $marketprice
        policyId: $policyId
        encodedName: $encodedName
        fingerprint: $fingerprint
        maxCap: $maxCap
        fund: { connect: { where: { node: { id: $fundId } } } }
      }
    ) {
      tokens {
        id
      }
    }
  }
`

export const UPDATE_TOKEN = gql`
  mutation ($tokenData: TokenUpdateInput!, $tokenId: ID!) {
    updateTokens(where: { id: $tokenId }, update: $tokenData) {
      tokens {
        id
      }
    }
  }
`

export const REMOVE_FUND_TOKEN = gql`
  mutation ($tokenId: ID!, $fundId: ID!) {
    updateTokens(
      where: { id: $tokenId }
      disconnect: { fund: { where: { node: { id: $fundId } } } }
    ) {
      tokens {
        id
      }
    }
  }
`

export const ADD_FUND_TOKEN = gql`
  mutation ($tokenId: ID!, $fundId: ID!) {
    updateTokens(
      where: { id: $tokenId }
      connect: { fund: { where: { node: { id: $fundId } } } }
    ) {
      tokens {
        id
      }
    }
  }
`

export const GET_TOKEN_BY_ID = gql`
  query ($tokenId: ID!) {
    tokens(where: { id: $tokenId }) {
      id
      logo
      fund {
        id
        name
      }
      symbol
      maxCap
      policyId
      description
      marketprice
      encodedName
      fingerprint
    }
  }
`

export const DISABLE_PREFERENCE = gql`
  mutation ($preferenceId: ID!) {
    updatePreferences(
      where: { id: $preferenceId }
      update: { isActive: false }
    ) {
      preferences {
        id
      }
    }
  }
`

export const ENABLE_PREFERENCE = gql`
  mutation ($preferenceId: ID!) {
    updatePreferences(
      where: { id: $preferenceId }
      update: { isActive: true }
    ) {
      preferences {
        id
      }
    }
  }
`

export const DISABLE_COMPANY = gql`
  mutation ($coId: ID!) {
    updateCompanies(where: { id: $coId }, update: { isActive: false }) {
      companies {
        id
      }
    }
  }
`

export const ENABLE_COMPANY = gql`
  mutation ($coId: ID!) {
    updateCompanies(where: { id: $coId }, update: { isActive: true }) {
      companies {
        id
      }
    }
  }
`

export const DISABLE_FUND_PURPOSE = gql`
  mutation ($purposeId: ID!) {
    updateFundPurposes(where: { id: $purposeId }, update: { isActive: false }) {
      fundPurposes {
        id
      }
    }
  }
`

export const ENABLE_FUND_PURPOSE = gql`
  mutation ($purposeId: ID!) {
    updateFundPurposes(where: { id: $purposeId }, update: { isActive: true }) {
      fundPurposes {
        id
      }
    }
  }
`

export const ADMIN_GET_ALL_TRANSACTIONS = gql`
  query {
    transactions(options: { sort: { date: DESC } }) {
      id
      status
      type
      amount
      hash
      date
      offerPurchase {
        investorAddress
        smartContract {
          id
          contractId
          contractLink
        }
        fund {
          name
          token {
            symbol
          }
        }
      }
      user {
        id
        userName
        wallet {
          id
          address
        }
      }
    }
  }
`

export const ADMIN_GET_ALL_ERRORS = gql`
  query {
    errors(options: { sort: { date: DESC } }) {
      id
      date
      user {
        id
      }
      errorCode
      errorString
    }
  }
`
export const GET_ALL_SETTINGS = gql`
  query {
    settingProperties(
      where: { NOT: { settingName_CONTAINS: "FUND_ORIGIN_OPT" } }
    ) {
      id
      settingName
      settingValue
    }
  }
`

export const GET_ALL_FUND_OPTIONS = gql`
  query {
    settingProperties(where: { settingName_CONTAINS: "FUND_ORIGIN_OPT" }) {
      id
      settingName
      settingValue
    }
  }
`

export const UPDATE_SINGLE_SETTING = gql`
  mutation ($settingId: ID!, $newSettingValue: String!) {
    updateSettingProperties(
      where: { id: $settingId }
      update: { settingValue: $newSettingValue }
    ) {
      settingProperties {
        id
      }
    }
  }
`

export const GET_ALL_COUNTRIES = gql`
  query {
    countries(where: { NOT: { id: "1" } }) {
      id
      name
    }
  }
`
