import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GET_USER_INFO } from '../../../Queries/Users'
import { Box, CircularProgress, Grid, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GET_ALL_NEWS } from '../../../Queries/Admin';
import IndividualNew from './IndividualNew';






const DaoNews = () => {

    const [search, setSearch] = React.useState('');
    const { user } = useAuth0()
    const { t } = useTranslation()
    const dispatch = useDispatch()


    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };

    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    const [getAllNews, { data: newsData, loading: dataLoading }] = useLazyQuery(GET_ALL_NEWS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getUserData()
        getAllNews()
    }, [getUserData, getAllNews]);

    const [filteredNews, setFilteredNews] = useState([]);

    useEffect(() => {
        if (newsData?.news) {
            const filtered = newsData.news.filter(indNew =>
                indNew.contentEn.toLowerCase().includes(search.toLowerCase()) ||
                indNew.contentEs.toLowerCase().includes(search.toLowerCase()) ||
                indNew.contentPt.toLowerCase().includes(search.toLowerCase()) ||
                indNew.titleEn.toLowerCase().includes(search.toLowerCase()) ||
                indNew.titleEs.toLowerCase().includes(search.toLowerCase()) ||
                indNew.titlePt.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredNews(filtered);
        }
    }, [search, newsData]);

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }


    if (isCoreTeam) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
                <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                            {t('ADMIN.DAO_NEWS')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            m: 4,
                            alignItems: "center", justifyContent: "space-around"
                        }}>
                        <Box
                            onClick={() => {
                                dispatch(push('/admin-dao-news-create'))
                            }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                p: 0.5,
                            }}>
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',

                                }}

                            />
                            <Typography sx={{
                                ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                            }}>{t('ADMIN.NEW_PUBLICATION')}</Typography>
                        </Box>
                        <TextField
                            id="filled-search"
                            label={t('ADMIN.ONLY_SEARCH')}
                            type="search"
                            variant="outlined"
                            onChange={handleTextChange}
                            sx={{ width: '55%', ml: 4 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid >
                {dataLoading ?
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 8
                        }}>
                        <CircularProgress />
                    </Box>
                    :
                    <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                        {filteredNews?.map((news) => (
                            <IndividualNew key={news.id} data={news} />
                        ))}
                    </Grid>}
            </Box >
        )
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default DaoNews