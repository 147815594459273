import React, { useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ADMIN_GET_ALL_ERRORS } from '../../../Queries/Admin';
import { useLazyQuery } from '@apollo/client';
import IndividualErrorLog from './IndividualErrorLog';
import Preloader from '../../preloader/Preloader';
import { Box, Typography, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';


const TransactionLog = () => {

    const { t } = useTranslation()

    const [getAllErrors, { data: errorsData, loading: loadingErrorsData }] = useLazyQuery(ADMIN_GET_ALL_ERRORS,
        {
            pollInterval: 1500
        })

    useEffect(() => {
        getAllErrors()
    }, [getAllErrors])

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (loadingErrorsData) return <Preloader />

    return (
        <>
            <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', ml: 4 }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        {t('ADMIN.ERROR_LOG')}
                    </Typography>
                </Box>
                <TableContainer sx={{ width: '100%', height: '75vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center', mt: 2 }}>
                    <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", width: '10%' }} align='center'>{t('ADMIN.NEW_EVENT_DATE')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", width: '20%' }} align="center">{t('GENERAL.USER')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", width: '10%' }} align="center">{t('ADMIN.ERROR_CODE')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="left">{t('ADMIN.ERROR_TEXT')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errorsData?.errors?.map((row) => (
                                <IndividualErrorLog key={row?.id} data={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default TransactionLog