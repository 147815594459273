import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth0Provider } from '@auth0/auth0-react'
import './i18n'
import JWT from 'expo-jwt'


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL_DEV || '/graphql',
})
//console.log("Secre 2", process.env.REACT_APP_JWT_SECRET)
const ctime = new Date().toISOString()
const payload = "{isAuthenticated: true, time: " + ctime + "}"
//console.log("payload", payload)

const authLink = setContext((_, { headers }) => {
  const token = JWT.encode(payload,process.env.REACT_APP_JWT_SECRET)
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const Main = () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Auth0Provider>
)
// eslint-disable-next-line
ReactDOM.render(<Main />, document.getElementById('root'))
