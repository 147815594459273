import { gql } from '@apollo/client'

export const GET_DVC_POLICY = gql`
  query {
    tokens(where: { symbol: "DVC" }) {
      id
      symbol
      policyId
    }
  }
`

export const GET_ALL_TOKENS = gql`
  query {
    tokens {
      id
      symbol
      policyId
    }
  }
`
