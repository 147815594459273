import { gql } from '@apollo/client'

export const CREATE_ERROR_LOG = gql`
  mutation (
    $errorId: ID!
    $errorDate: DateTime!
    $errorCode: String!
    $errorText: String
    $userId: ID!
  ) {
    createErrors(
      input: {
        id: $errorId
        date: $errorDate
        errorCode: $errorCode
        errorString: $errorText
        user: { connect: { where: { node: { id: $userId } } } }
      }
    ) {
      errors {
        id
      }
    }
  }
`
