import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import NextWeekIcon from '@mui/icons-material/NextWeek'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { GET_USER_INFO } from '../../Queries/Users'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import HistoricMessages from './HistoricMessages'
import NewMessageModal from './NewMessageModal'
import { GET_ALL_MESSAGES } from '../../Queries/Messages'
import { ArrowBack } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router'
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATIONS, } from '../../Queries/Notifications'

const Messages = () => {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  const [getUserData, { data: userData }] = useLazyQuery(GET_USER_INFO, {
    variables: { email: user?.email || user?.sub },
  })
  useEffect(() => {
    getUserData()
  }, [user, getUserData])


  const { data: notificationData } = useQuery(GET_NOTIFICATIONS, {
    pollInterval: 2000,
    variables: {
      userID: user?.email || user?.sub,
    },
  })

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS)
  const [notificationsState, setNotificationsState] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)

  useEffect(() => {
    if (notificationData !== undefined) {
      setNotificationsState(notificationData?.userNotifications)
      const unreadExists = notificationData?.userNotifications.some(
        (notification) => notification.read == false
      )
      setUnreadNotifications(unreadExists)
    }
  }, [notificationData, unreadNotifications])

  const handleOpenNotifications = async () => {
    const notificationsIds = []
    notificationsState.forEach((notification) => {
      if (notification.read == false) {
        notificationsIds.push(notification.id)
      }
    })
    // eslint-disable-next-line
    const response = await updateNotifications({
      variables: {
        ids: notificationsIds,
      },
    })
    setUnreadNotifications(false)
  }

  useEffect(() => {
    handleOpenNotifications()
  }, [notificationData, unreadNotifications])


  const [searchField, setSearchField] = useState('')

  const [
    getAllMessages,
    {
      data: allMessages,
      refetch: refetchAllMessages,
      loading: loadingAllMessages
    },
  ] = useLazyQuery(GET_ALL_MESSAGES, {
    pollInterval: 2000,
  })

  function filterMessages(messages, userId) {
    return messages?.filter(message => {
      const sentBy = message.sentBy && message.sentBy.id;
      const sentTo = message.sentTo && message.sentTo.id;

      return sentBy && sentTo && (sentBy === userId || sentTo === userId);
    }) || [];
  }

  const [newMessages, setNewMessages] = useState([])

  useEffect(() => {
    if (allMessages) { setNewMessages(filterMessages(allMessages?.messages, userData?.users[0]?.id)) }
  }, [allMessages])

  function filterMessagesBySearchTerm(messages, searchField) {
    const searchTerm = searchField.toLowerCase();
    return messages?.filter(message => {
      const textMatch = message.text.toLowerCase().includes(searchTerm);
      const sentToMatch = message?.sentTo?.userName?.toLowerCase().includes(searchTerm);
      const sentByMatch = message?.sentBy?.userName?.toLowerCase().includes(searchTerm);
      return textMatch || sentToMatch || sentByMatch;
    });
  }

  const filteredMessages = filterMessagesBySearchTerm(newMessages, searchField);

  const [allMessage, setAllMessage] = useState([])

  useEffect(() => {
    if (filteredMessages !== undefined) {
      const messagesByUser = filteredMessages?.reduce((acc, curr) => {
        if (curr?.sentBy?.userName === userData?.users[0]?.userName) {
          if (!acc[curr?.sentTo?.userName]) {
            acc[curr?.sentTo?.userName] = []
          }
          acc[curr?.sentTo?.userName].push(curr)
        } else {
          if (!acc[curr?.sentBy?.userName]) {
            acc[curr?.sentBy?.userName] = []
          }
          acc[curr?.sentBy?.userName].push(curr)
        }
        return acc
      }, {})
      setAllMessage(messagesByUser)
    }
  }, [searchField, newMessages])

  const [modalSendMessage, setModalSendMessage] = useState(false)
  const handleClose = () => {
    setModalSendMessage(false)
  }

  const handleSearchChange = (event) => {
    setSearchField(event.target.value)
  }

  const handleOpenNewMessage = () => {
    setModalSendMessage(true)
  }

  useEffect(() => {
    getAllMessages()
  }, [user, getAllMessages, userData])

  const { userParam } = useParams()

  const [cameFromParam, setCameFromParam] = useState(false)


  const [selectedUser, setSelectedUser] = useState(userParam.trim() || undefined)

  useEffect(() => {
    setSelectedUser(userParam.trim())
    setCameFromParam(true)
  }, [userParam])

  const handleClickUser = (user) => {
    setSelectedUser(user)
    setCameFromParam(false)
    setIsGroupCollapsed(true)
    refetchAllMessages()
  }

  const [isOfferer, setIsOfferer] = useState(false)

  const [isGroupCollapsed, setIsGroupCollapsed] = useState(false)

  const userRoles = userData?.users[0]?.has

  useEffect(() => {
    if (userRoles) {
      userRoles.forEach((role) => {
        if (
          role.roleName === 'Investor & Offerer' ||
          role.roleName === 'Offerer'
        ) {
          setIsOfferer(true)
        }
      })
    }
  }, [userData])

  return (
    <Box
      sx={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid var(--borderGray)',
        borderRadius: '5px',
      }}
    >
      <Box
        className="msgGroupContainer"
        sx={{
          display: { xs: isGroupCollapsed ? 'none' : 'flex', md: 'flex' },
          width: { xs: '100%', md: '30%' },
          padding: '0.5rem',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid var(--borderGray)',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: '14px', md: '18px', lg: '20px' },
              fontFamily: 'Quicksand, sans-serif',
              lineHeight: '40px',
              fontWeight: 'bold',
              color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
            }}
          >
            {userData?.users[0]?.userName}
          </Typography>
          <Box
            sx={{
              mr: '5px',
              width: '20%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              cursor: 'pointer !important',
            }}
          >
            <Tooltip title={t(
              'MESSAGES.SEND_NEW'
            )}>
              <BorderColorOutlinedIcon
                onClick={handleOpenNewMessage}
                color="pink"
                sx={{
                  border: actualThemeLight ? '1px solid #9A1D4C' : '1px solid #e6a3bd',
                  color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                  width: '30px',
                  height: '30px',
                  borderRadius: '5px',
                  p: '5px',
                }}
              />
            </Tooltip>
            {isOfferer && (
              <Tooltip title={t(
                'MESSAGES.SEND_PROPOSAL_MESSAGE'
              )}>
                <NextWeekIcon
                  onClick={'handleOpenNewProposal'}
                  sx={{
                    border: '1px solid #9A1D4C',
                    width: '30px',
                    height: '30px',
                    borderRadius: '5px',
                    p: '5px',
                  }}
                  color="pink"
                />
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OutlinedInput
            sx={{
              width: '90%',
              height: '35px',
              mt: 1,
              mb: 1,
              border: actualThemeLight ? '1px solid #9A1D4C' : '1px solid #e6a3bd',
              borderRadius: '25px',
              pl: 1,
              decoration: 'none',
            }}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon sx={{ color: actualThemeLight ? '#9A1D4C' : '#e6a3bd' }} />
              </InputAdornment>
            }
            id="outlined-basic"
            onChange={handleSearchChange}
            placeholder={t('MESSAGES.SEARCH_MESSAGE')}
            variant="standard"
          />
        </Box>
        {loadingAllMessages ?
          <Box sx={{ width: '100%', height: '100%', ml: '50%', mt: 5 }}>
            <CircularProgress />
          </Box> :
          <Box
            sx={{
              width: '100%',
              cursor: 'pointer !important',
              overflowY: 'auto',
              height: '75vh',
            }}
          >
            {Object.keys(allMessage).map((u) => {
              const filtered = allMessage[u].filter(
                (msg) => msg?.sentTo?.id == user?.email
              )
              const flag = filtered.some((msg) => msg.read === false)
              return (
                <Box
                  key={u.id}
                  onClick={() => {
                    handleClickUser(u)
                  }}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    borderTop: '1px solid var(--borderGray)',
                    border: selectedUser === u ? '2px solid #9A1D4C' : '2px solid rgba(0, 0, 0, 0)',
                    p: 1,
                    backgroundColor:
                      allMessage[u][0]?.sentTo?.id === allMessage[u][0]?.sentBy?.id
                        ? 'transparent'
                        : flag
                          ? actualThemeLight ? 'var(--unreadMessage)' : '#b02555'
                          : 'transparent',
                    borderRadius: '5px',
                    flexDirection: 'row',
                    justifyContent: 'start',
                  }}
                >
                  {allMessage[u][0]?.sentTo?.id === allMessage[u][0]?.sentBy?.id ?
                    <Box sx={{ justifyContent: 'start' }}>
                      {allMessage[u][0].sentTo.id === user.email && (
                        <Avatar
                          src={allMessage[u][0]?.sentBy?.profilePicture}
                          alt={allMessage[u][0]?.sentBy?.userName}
                          sx={{ width: 50, height: 50 }}
                        />
                      )}
                    </Box>
                    :
                    <Box sx={{ justifyContent: 'start' }}>
                      {allMessage[u][0]?.sentTo?.id === user?.email && (
                        <Avatar
                          src={allMessage[u][0]?.sentBy?.profilePicture}
                          alt={allMessage[u][0]?.sentBy?.userName}
                          sx={{ width: 50, height: 50 }}
                        />
                      )}
                      {allMessage[u][0].sentBy.id === user.email && (
                        <Avatar
                          src={allMessage[u][0]?.sentTo?.profilePicture}
                          alt={allMessage[u][0]?.sentTo?.userName}
                          sx={{ width: 50, height: 50 }}
                        />
                      )}
                    </Box>
                  }
                  <Box
                    sx={{
                      justifyContent: 'start',
                      width: '60%',
                      ml: 2,
                      color: '#9A1D4C',
                    }}
                  >
                    <Typography sx={{ fontWeight: '800', overflow: 'hidden', color: actualThemeLight ? '#9A1D4C' : '#e6a3bd' }}>{u}</Typography>
                    {allMessage[u][0].text.split(':')[0] === 'REJ_NOTICE' ||
                      allMessage[u][0].text.split(':')[0] === 'COUNTER_OFFER' ||
                      allMessage[u][0].text === 'ACC_COUNTEROFFER' ||
                      allMessage[u][0].text === 'REJ_COUNTEROFFER' ||
                      allMessage[u][0].text.split('-')[0] === 'MESSAGE_STOP' ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          maxHeight: '18px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('MESSAGES.SPECIAL_MESSAGE')}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          maxHeight: '18px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          color: actualThemeLight ? '#9A1D4C' : 'white',
                        }}
                      >
                        {allMessage[u][0].text}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', color: '#9A1D4C', ml: '5%' }}>
                    <Typography sx={{ fontSize: '14px', color: actualThemeLight ? '#9A1D4C' : '#e6a3bd' }}>
                      {`${allMessage[u][0].on.split('T')[0].split('-')[2]}/${allMessage[u][0].on.split('T')[0].split('-')[1]}`}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Box>}
      </Box>
      <Box
        className="singleMsgContainer"
        sx={{
          width: { xs: '100%', md: '70%' },
          position: 'relative',
          display: { xs: isGroupCollapsed ? 'block' : 'none', md: 'block' },
          maxHeight: '80vh',
          borderLeft: '1px solid var(--borderGray)',
          transition: '150ms ease-in-out'
        }}
      >
        <IconButton
          onClick={() => setIsGroupCollapsed(false)}
          sx={{
            display: { xs: 'block', md: 'none' },
            position: 'absolute',
            top: '7px',
            zIndex: 1000
          }}><ArrowBack /></IconButton>
        <HistoricMessages selectedUser={selectedUser} param={cameFromParam} />
      </Box>
      {
        modalSendMessage && (
          <NewMessageModal open={modalSendMessage} handleClose={handleClose} />
        )
      }
    </Box >
  )
}

export default Messages
