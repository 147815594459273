import React, { Fragment, useEffect } from "react"
import {
    Box, Typography, Avatar, styled, Button, Link, Grid, CircularProgress, Tooltip,
    useTheme
} from "@mui/material"
import VerifiedIcon from '@mui/icons-material/Verified';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useParams } from "react-router"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GET_COMPANY_BY_ID } from "../../Queries/Companies"
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip';
import { useAuth0 } from "@auth0/auth0-react"
import BuildingIcon from '../../../src/assets/icons/building.svg'
import TelMailIcon from '../../../src/assets/icons/telmail.svg'
import i18n from "i18next";
import { push } from 'connected-react-router'

const CompanyView = () => {
    const selectedLanguage = i18n.language
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { companyId } = useParams()
    const { user } = useAuth0()
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    // Fetch company data by ID using useLazyQuery hook
    const [fetchCompany, { data: companyById, loading: companyLoading }] = useLazyQuery(
        GET_COMPANY_BY_ID,
        {
            variables: { CompanyId: companyId },
            pollInterval: 1500
        }
    )

    // Call fetchCompany function on mount and whenever it changes
    useEffect(() => {
        fetchCompany()
    }, [fetchCompany])

    // Define styled component for back button
    const BackButton = styled(Button)(({ theme }) => ({
        color: theme.palette.text.hint,
        fontFamily: 'Quicksand, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    }))

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }

    if (companyLoading) {
        return (
            <>
                <Grid sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 8
                }} xs={12} md={12}>
                    <CircularProgress />
                </Grid>
            </>
        )
    }

    return <>
        <Grid container sx={{ mb: 4 }} >
            {/* HEADER */}
            <Grid Grid item xs={12} md={12} className="right" >
                <Box sx={{ backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B" }}>
                    <Grid container>
                        {/* BACK BUTTON & AVATAR */}
                        <Grid item md={1} xs={12} sx={{ alignSelf: 'center', alignItems: 'center', justifyContent: { md: 'center', xs: 'center' }, mr: { md: 0, xs: '90%' } }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: { md: 'center', xs: 'left' }, alignItems: 'center' }}>
                                <ArrowBackIcon sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '16px' }} />
                                <BackButton
                                    onClick={() => dispatch(goBack())}
                                    sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', textDecoration: 'underline', fontSize: '14px' }}
                                >
                                    {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                </BackButton>
                            </Box>
                        </Grid>
                        <Grid item md={2} xs={4} sx={{
                            maxHeight: '16vh',
                            alignSelf: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: '100px',
                                        height: '100px',
                                    }}
                                    alt={companyById?.companies[0]?.coName}
                                    src={companyById?.companies[0]?.logo}
                                />
                            </Box>
                        </Grid>
                        {/* FUND INFO */}
                        <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                            {/* COLUMN 1 */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', color: "#4F4F4F", width: { md: '30%', xs: '90%' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                    {companyById?.companies[0].isValidated && <VerifiedIcon fontSize="medium" sx={{ mr: 1 }} />}
                                    <Typography sx={{ fontWeight: 'bold', mr: 1, color: actualThemeLight ? '#4F4F4F' : 'white' }}>
                                        {companyById?.companies[0]?.coName}
                                    </Typography>
                                    {companyById?.companies[0]?.isValidated === false
                                        &&
                                        companyById?.companies[0]?.users[0]?.id == user.email
                                        ?
                                        <Tooltip title={t('MY_COMPANIES.EDIT_COMPANY')}>
                                            <EditIcon
                                                sx={{ cursor: 'pointer', color: actualThemeLight ? null : '#e6a3bd' }}
                                                onClick={() => { dispatch(push(`/companies-edit/${companyById?.companies[0]?.id}`)) }}
                                                fontSize="small" />
                                        </Tooltip>
                                        : null
                                    }
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', ml: 2 }}>
                                    <PublicIcon sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', mr: 1 }} />
                                    <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white' }}>
                                        {companyById?.companies[0]?.address}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid >
            <Grid item md={1} xs={1} />
            <Grid item md={9} xs={9} >
                {/* FUND TAGS */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '20px' }}>
                        # Tags
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                        {companyById?.companies[0]?.preferences.map((preference, i) => (
                            <Chip label={preference.preferenceName} key={i} sx={{ backgroundColor: actualThemeLight ? '#ebecf2' : '#9a1d4c', border: '1px solid #DB417B', m: 1 }} />
                        ))}
                    </Box>
                </Box>
                {/* DESCRIPTION & CONTACT */}
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, justifyContent: { md: 'space-between', xs: 'center' } }}>
                    {/* DESCRIPTION */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: { md: '60%', xs: '100%' }, alignItems: { md: 'start', xs: 'center' } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: { xs: 2 }, alignItems: { md: 'start', xs: 'center' } }}>
                            {/* TITLE */}
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end', mb: 1 }}>
                                <img src={BuildingIcon} alt="icon" width='70px' />
                                <Typography sx={{ fontSize: '20px' }}>
                                    {t('INVERSOR_VIEW.OFFERING_CARD.DESCRIPTION')}
                                </Typography>
                            </Box>
                            {/* EMPLOYEES */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('COMPANY.EMPLOYEES')}: {companyById?.companies[0]?.employees}
                            </Typography>
                            {/* FUNDATION YEAR */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('CREATE_COMPANY.FOUNDATION_YEAR')}: {companyById?.companies[0]?.fundationYear.year}
                            </Typography>
                            {/* BILLING */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('CREATE_COMPANY.ANNUAL_BILLING')}: $ {companyById?.companies[0]?.annualBilling?.toLocaleString()}
                            </Typography>
                            {/* LINK TREE */}
                            <Link
                                variant="text"
                                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', color: 'black' }}
                                href={companyById?.companies[0]?.has_url[0]?.link}
                                target={{ _blank: true }}>
                                <LinkIcon sx={{ color: actualThemeLight ? null : '#e6a3bd' }} />
                                <Typography sx={{
                                    fontWeight: 'bold', textDecoration: 'underline', fontSize: '14px', color: actualThemeLight ? null : '#e6a3bd',
                                    textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                                }}>
                                    LinkTree
                                </Typography>
                            </Link>
                        </Box>
                        {/* DESCRIPTION TEXT */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 4, mb: 4, width: { md: '80%', xs: '100%' }, alignItems: { md: 'start', xs: 'center' } }}>
                            <Box sx={{ border: '4px solid #9A1D4C', height: '100%', minHeight: '4vh', mr: 3 }} />
                            <Typography sx={{ textAlign: { md: 'left', xs: 'center' } }} >
                                {selectedLanguage === 'en' ? companyById?.companies[0]?.descriptionEn :
                                    selectedLanguage === 'es' && companyById?.companies[0]?.descriptionEs !== '' ?
                                        companyById?.companies[0]?.descriptionEs :
                                        selectedLanguage === 'pt' && companyById?.companies[0]?.descriptionPt !== '' ?
                                            companyById?.companies[0]?.descriptionPt : companyById?.companies[0]?.descriptionEn}
                            </Typography>
                        </Box>
                    </Box>
                    {/* CONTACT */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: { md: '40%', xs: '100%' }, mt: { xs: 4 }, alignItems: { md: 'start', xs: 'center' } }}>
                        {/* TITLE */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end', mb: 1 }}>
                            <img src={TelMailIcon} alt="icon" width='70px' />
                            <Typography sx={{ fontSize: '20px' }}>
                                {t('COMPANY.COMPANY_DATA')}
                            </Typography>
                        </Box>
                        {/* ADDRESS */}
                        <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                            {t('MY_COMPANIES.ADDRESS')}: {companyById?.companies[0]?.address}
                        </Typography>
                        {/* PHONE */}
                        <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                            {t('CREATE_COMPANY.PHONE')}: {companyById?.companies[0]?.phone}
                        </Typography>
                        {/* MAIL */}
                        <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                            {t('CREATE_COMPANY.MAIL')}: {companyById?.companies[0]?.email}
                        </Typography>
                        {/* LEGAL */}
                        <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                            {t('CREATE_COMPANY.LEGAL_COMPANY')}: {companyById?.companies[0]?.isIncorporated ? t('COMPANY.YES') : t('COMPANY.NO')}
                        </Typography>
                        {/* JURISDICTION */}
                        <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                            {t('COMPANY.JURISDICTION')}: {companyById?.companies[0]?.isIncJurisdiction}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    </>
}


export default CompanyView