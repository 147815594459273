import { ConnectedRouter } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { history } from '../../store'
import LandingPage from '../../Views/LandingPage/LandingPage'
import PerfilConfig from '../../Views/PerfilConfig/PerfilConfig'
import FundSearchView from '../../Views/FundSearchView/FundView'
import MessagesView from '../../Views/MessagesView/MessagesView'
import BlockedView from '../../Views/BlockedView/BlockedView'
import Navbar from '../NavBar/Navbar'
import NavbarMobile from '../NavBar/NavbarMobile'
import UserProfile from '../../Views/UserProfile/UserProfile'
import WalletHistory from '../../Views/Wallet/WalletHistory'
import Wallet from '../../Views/Wallet/Wallet'
import FAQView from '../../Views/FAQ/FAQ.jsx'
import { Box, useTheme } from '@mui/material'
import BottomNavbar from '../NavBar/BottomNavbar'
import AdminViewUsers from '../../Views/AdminView/AdminViewUsers'
import AdminViewCompanies from '../../Views/AdminView/AdminViewCompanies'
import AdminViewInterests from '../../Views/AdminView/AdminViewInterests'
import AdminViewCalendar from '../../Views/AdminView/AdminViewCalendar'
import AdminViewDaoNews from '../../Views/AdminView/AdminViewDaoNews'
import AdminViewDaoNewsCreate from '../../Views/AdminView/AdminViewDaoNewsCreate'
import AdminViewDaoNewsEdit from '../../Views/AdminView/AdminViewDaoNewsEdit'
import { useAuth0 } from '@auth0/auth0-react'
import { IS_USER_BLOCKED } from '../../Queries/Users'
import { useLazyQuery } from '@apollo/client'
import AdminViewCalendarCreate from '../../Views/AdminView/AdminViewCalendarCreate'
import AdminViewCalendarEdit from '../../Views/AdminView/AdminViewCalendarEdit'
import AdminViewFundsCreate from '../../Views/AdminView/AdminViewFundsCreate'
import AdminViewFundsEdit from '../../Views/AdminView/AdminViewFundsEdit'
import newDashboardView from './../../Views/DashboardView/newDashboardView/newDashboardView.jsx'
import AdminViewCompaniesAssessment from '../../Views/AdminView/AdminViewCompaniesAssessment'
import CompanySearchView from '../../Views/CompanySearchView/CompanySearchView'
import CompanyView from '../../Views/CompanyView/CompanyView'
import CalendarComponent from '../CalendarComponent/CalendarComponent'
import NewsComponent from '../NewsComponent/NewsComponent'
import CreateCompany from '../../Views/CompanySearchView/CreateCompany/CreateCompany'
import EditCompany from '../../Views/CompanySearchView/EditCompany/EditCompany'
import CompanyPostulation from '../CompanyPostulation/CompanyPostulation'
import EditProposal from '../CompanyPostulation/EditProposal'
import AdminViewFundPurpose from '../../Views/AdminView/AdminViewFundPurpose'
import AdminViewFundPurposeCreate from '../../Views/AdminView/AdminViewFundPurposeCreate'
import AdminViewFundPurposeEdit from '../../Views/AdminView/AdminViewFundPurposeEdit'
import AdminViewSettings from '../../Views/AdminView/AdminViewSettings.jsx'
import AdminViewVestingEventAdministration from '../../Views/AdminView/AdminViewVestingEventAdministration'
import CreateFund from '../../Views/FundView/CreateFund'
import FundView2 from '../../Views/FundView/FundView2'
import AdminViewFundDetail from '../../Views/AdminView/AdminViewFundDetail'
import AdminViewApplications from '../../Views/AdminView/AdminViewApplications'
import ProposalView from '../../Views/ProposalView/ProposalView'
import AdminViewNewVestingEvent from '../../Views/AdminView/AdminViewNewVestingEvent'
import AdminViewEditVestingEvent from '../../Views/AdminView/AdminViewEditVestingEvent'
import AdminViewTokensAdministration from '../../Views/AdminView/AdminViewTokensAdministration'
import AdminViewTokensCreate from './../../Views/AdminView/AdminViewTokensCreate'
import AdminViewTokensEdit from './../../Views/AdminView/AdminViewTokensEdit'
import AdminViewFundManagement from '../../Views/AdminView/AdminViewFundManagement.jsx'
import AdminViewTransactionLog from '../../Views/AdminView/AdminViewTransactionLog.jsx'
import AdminViewErrorLog from '../../Views/AdminView/AdminViewErrorLog.jsx'
import AdminViewIndivFundsManagement from '../../Views/AdminView/AdminViewIndivFundsManagement.jsx'

const RoutingComponent = () => {
  const { user, isAuthenticated } = useAuth0()

  const [getUserData, { data: userData, refetch }] = useLazyQuery(
    IS_USER_BLOCKED,
    {
      variables: { userId: user?.email || user?.sub },
      pollInterval: 5000,
    }
  )

  useEffect(() => {
    if (user?.email !== undefined) {
      getUserData()
    }
  }, [getUserData, user])

  const [userActiveNew, setUserActiveNew] = useState(true)

  useEffect(() => {
    if (userData?.users[0]?.active === false) {
      setUserActiveNew(false)
    }
    if (userData?.users[0]?.active === true) {
      setUserActiveNew(true)
    }
  }, [userData])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  if (userActiveNew === false) {
    return (
      <ConnectedRouter>
        <Box sx={{ display: { xs: 'block', md: 'block' } }}>
          <BlockedView />
        </Box>
      </ConnectedRouter>
    )
  } else {
    return (
      <ConnectedRouter history={history}>
        <Box
          sx={{
            backgroundColor: actualThemeLight ? null : '#262626',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Navbar />
        </Box>
        <Box
          sx={{
            backgroundColor: actualThemeLight ? null : '#262626',
            display: { xs: 'block', md: 'none' },
          }}
        >
          <NavbarMobile />
          {isAuthenticated ? <BottomNavbar /> : null}
        </Box>
        <Box sx={{ backgroundColor: actualThemeLight ? null : '#262626' }}>
          {isAuthenticated ? (
            <Switch>
              <Route path="/profile-settings" component={PerfilConfig} />
              <Route path="/dashboard" component={newDashboardView} />
              <Route path="/funds" component={FundSearchView} />
              <Route path="/companies" component={CompanySearchView} />
              <Route path="/companies-create" component={CreateCompany} />
              <Route
                path="/companies-edit/:companyId"
                component={EditCompany}
              />
              <Route path="/dao/calendar" component={CalendarComponent} />
              <Route path="/dao/news" component={NewsComponent} />
              <Route path="/faq" component={FAQView} />
              <Route path="/fund-detail/:fundId" component={FundView2} />
              <Route
                path="/admin-fund-detail/:fundId"
                component={AdminViewFundDetail}
              />
              <Route
                path="/admin-application-detail/:applicationId"
                component={AdminViewApplications}
              />
              <Route path="/fund-create/:fundId" component={CreateFund} />
              <Route
                path="/application-detail/:applicationId"
                component={ProposalView}
              />
              <Route
                path="/company-detail/:companyId"
                component={CompanyView}
              />
              <Route path="/user-profile/:email" component={UserProfile} />
              <Route path="/messages/:userParam" component={MessagesView} />
              <Route path="/admin-users" component={AdminViewUsers} />
              <Route path="/admin-companies" component={AdminViewCompanies} />
              <Route path="/admin-interests" component={AdminViewInterests} />
              <Route
                path="/admin-fund-purpose"
                component={AdminViewFundPurpose}
              />
              <Route path="/admin-settings" component={AdminViewSettings} />
              <Route
                path="/admin-fund-purpose-create"
                component={AdminViewFundPurposeCreate}
              />
              <Route
                path="/admin-fund-purpose-edit/:id"
                component={AdminViewFundPurposeEdit}
              />
              <Route
                path="/admin-fund-management"
                component={AdminViewFundManagement}
              />
              <Route
                path="/admin-transactionlog"
                component={AdminViewTransactionLog}
              />
              <Route path="/admin-errorlog" component={AdminViewErrorLog} />
              <Route path="/admin-dao-news" component={AdminViewDaoNews} />
              <Route
                path="/admin-dao-news-create"
                component={AdminViewDaoNewsCreate}
              />
              <Route
                path="/admin-dao-news-edit/:id"
                component={AdminViewDaoNewsEdit}
              />
              <Route path="/admin-calendar" component={AdminViewCalendar} />
              <Route
                path="/admin-vesting-event"
                component={AdminViewVestingEventAdministration}
              />
              <Route
                path="/admin-vesting-create"
                component={AdminViewNewVestingEvent}
              />
              <Route
                path="/admin-vesting-edit/:id"
                component={AdminViewEditVestingEvent}
              />
              <Route
                path="/admin-calendar-create"
                component={AdminViewCalendarCreate}
              />
              <Route
                path="/admin-tokens"
                component={AdminViewTokensAdministration}
              />
              <Route
                path="/admin-tokens-create"
                component={AdminViewTokensCreate}
              />
              <Route
                path="/admin-tokens-edit/:id"
                component={AdminViewTokensEdit}
              />
              <Route
                path="/admin-calendar-edit/:id"
                component={AdminViewCalendarEdit}
              />
              <Route
                path="/admin-funds-create"
                component={AdminViewFundsCreate}
              />
              <Route
                path="/admin-funds-edit/:fundId"
                component={AdminViewFundsEdit}
              />
              <Route
                path="/admin-indiv-fund-management/:fundId"
                component={AdminViewIndivFundsManagement}
              />
              <Route
                path="/admin-company-assessment/:coId"
                component={AdminViewCompaniesAssessment}
              />
              <Route path="/wallet/history" component={WalletHistory} />
              <Route path="/wallet" component={Wallet} />
              <Route path="/postulate/:fundId" component={CompanyPostulation} />
              <Route
                path="/edit-proposal/:proposalId"
                component={EditProposal}
              />
              <Route path="/" component={LandingPage} />
            </Switch>
          ) : (
            <Switch>
              <Route path="/" component={LandingPage} />
            </Switch>
          )}
        </Box>
        <Box
          className="dummyContainer"
          sx={{
            width: '1px',
            height: { xs: '75px', md: '0px' },
            backgroundColor: actualThemeLight ? null : '#262626',
          }}
        ></Box>
      </ConnectedRouter>
    )
  }
}

export default RoutingComponent
