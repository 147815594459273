import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS = gql`
  query getnotifications($userID: ID!) {
    userNotifications(
      where: { hasnotification_SOME: { id: $userID } }
      options: { sort: { createdOn: DESC } }
    ) {
      id
      read
      message
      createdOn
      link
    }
  }
`

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $notificationID: ID!
    $msg: String!
    $date: DateTime!
    $link: String!
    $userID: ID!
  ) {
    createUserNotifications(
      input: {
        id: $notificationID
        read: false
        message: $msg
        createdOn: $date
        link: $link
        hasnotification: { connect: { where: { node: { id: $userID } } } }
      }
    ) {
      userNotifications {
        id
      }
    }
  }
`

export const UPDATE_NOTIFICATIONS = gql`
  mutation updateNotification($ids: [ID!]!) {
    updateUserNotifications(where: { id_IN: $ids }, update: { read: true }) {
      userNotifications {
        read
        id
      }
    }
  }
`

export const LINK_NOTIFICATION_TO_MANY_USER = gql`
  mutation addUserToNotif($notificationID: ID!, $otherUserId: [ID!]!) {
    updateUserNotifications(
      where: { id: $notificationID }
      connect: { hasnotification: { where: { node: { id_IN: $otherUserId } } } }
    ) {
      userNotifications {
        id
      }
    }
  }
`
