/* import { useState, useEffect } from 'react'

export function useUtcTime() {
  const [utcTime, setUtcTime] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      fetchTimeData()
    }, 2000)
    return () => clearInterval(interval)
  })

  const fetchTimeData = () => {
    const getTime = () => {
      const url = 'https://worldtimeapi.org/api/timezone/Etc/UTC'
      fetch(url)
        .then((r) => r.json())
        .then((r) => {
          setUtcTime(r.datetime)
        })
    }
    getTime()
  }

  return utcTime
}
 */

import axios from 'axios'

export const fetchUTCTime = async () => {
  const url = 'https://worldtimeapi.org/api/timezone/Etc/UTC'
  try {
    const res = await axios.get(url)

    if (res.status === 200) {
      const DATE = res.data.datetime
      return DATE
    } else {
      return { err: 'Error fetching date' }
    }
  } catch (error) {
    return error
  }
}
