import { Box } from '@mui/material'
import React from 'react'
import DaoNewsCreate from '../../components/AdminComponents/DaoNews/DaoNewsCreate'

const AdminViewDaoNewsCreate = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
                justifySelf: 'center',
                width: { xs: '100%', md: '90%' },
                pt: '2%',
                pl: { xs: '0', md: '10%' },
            }}
        >
            <DaoNewsCreate />
        </Box>
    )
}

export default AdminViewDaoNewsCreate