import React, { useCallback, useEffect } from 'react'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { GET_USER_INFO, UPDATE_PHOTO } from '../../Queries/Users'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import { v4 as uuidv4 } from 'uuid'
import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Divider, useTheme } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/icons/TokenAlliesIcon.svg'
import I18nMenu from '../../Layouts/DashboardLayout/I18nMenu'
import { login, logoutAction } from '../../store/actions/authActions'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { setViewPreference } from '../../store/actions/perfilActions'
import Style from './Navbar.module.css'
import { useTranslation } from 'react-i18next'
import DarkModeSwitch from './DarkModeSwitch'
import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from '../../Queries/Notifications'
import { useState } from 'react'
import { CREATE_WALLET, LINK_WALLET } from '../../Queries/Wallet'
import WalletGC from '../../utils/walletGC'

const NavBar = () => {
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0()
  const { t } = useTranslation()

  // GET URL PARAMS
  const addressFromURL = useSelector(
    (state) => state.router.location?.query?.address
  )
  const addressStatus = useSelector(
    (state) => state.router.location?.query?.action
  )

  const [
    createWallet,
    // eslint-disable-next-line
    { data: walletData, loading: walletLoading },
  ] = useMutation(CREATE_WALLET)
  const [
    linkWallet,
    // eslint-disable-next-line
    { data: linkWalletData, loading: linkWalletLoading },
  ] = useMutation(LINK_WALLET)

  const [getUserData, { data: userData, loading }] = useLazyQuery(
    GET_USER_INFO,
    {
      variables: { email: user?.email || user?.sub },
    }
  )

  // eslint-disable-next-line
  const [updatePhoto, { data: photoUpdateResponse }] = useMutation(UPDATE_PHOTO)

  useEffect(() => {
    if (
      !loading &&
      !isLoading &&
      user?.picture !== userData?.users[0]?.profilePicture
    ) {
      updatePhoto({
        variables: {
          id: user.email || user?.sub,
          profilePicture: user.picture,
        },
      })
    }
  }, [user, userData])

  const [
    updateNotifications,
    // eslint-disable-next-line
    { data: notificationsUpdateResponse },
  ] = useMutation(UPDATE_NOTIFICATIONS)
  //! CHANGE ID!
  // eslint-disable-next-line
  const { data: notificationData, loading: notifLoading } = useQuery(
    GET_NOTIFICATIONS,
    {
      pollInterval: 10000,
      variables: {
        userID: user?.email || user?.sub,
      },
    }
  )

  const showInvestorOrOffererView = localStorage.getItem(
    'showInvestorOrOffererView'
  )
  const [viewTypeToShow, setViewTypeToShow] = React.useState(
    showInvestorOrOffererView
  )

  const [notificationsState, setNotificationsState] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)

  useEffect(() => {
    if (notificationData !== undefined && !loading) {
      setNotificationsState(notificationData?.userNotifications)
      const unreadExists = notificationData?.userNotifications.some(
        (notification) => notification.read == false
      )
      setUnreadNotifications(unreadExists)
    }
  }, [notificationData, loading, unreadNotifications])

  useEffect(() => {
    setViewTypeToShow(showInvestorOrOffererView)
  }, [userData])

  // eslint-disable-next-line
  const [viewOptions, setViewOptions] = React.useState(null)
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  // eslint-disable-next-line
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const dispatch = useDispatch()

  const getAccessToken = useCallback(async () => {
    if (!isLoading && user !== undefined) {
      try {
        const token = await getAccessTokenSilently()
        dispatch(login(token, user))
        //dispatch(push('/profile-settings'))
      } catch (err) {
        dispatch(push('/'))
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect, dispatch, isLoading])

  useEffect(() => {
    if (!isLoading && user !== undefined) {
      getAccessToken()
      getUserData()
    }
  }, [getAccessToken, isLoading, user, loading, getUserData])

  useEffect(() => {
    if (showInvestorOrOffererView == null) {
      if (
        userData?.users[0]?.has[0]?.roleName !== undefined &&
        userData?.users[0]?.has[0]?.roleName === null
      ) {
        localStorage.setItem(
          'showInvestorOrOffererView',
          userData?.users[0]?.has[0]?.roleName
        )
        setViewTypeToShow(userData?.users[0]?.has[0]?.roleName)
      }
    }
    if (userData?.users[0]?.has[0]?.roleName === 'Investor & Offerer') {
      setViewOptions(['Investor', 'Offerer'])
    } else if (userData?.users[0]?.has[0]?.roleName === 'Investor') {
      setViewOptions(['Investor'])
    } else if (userData?.users[0]?.has[0]?.roleName === 'Offerer') {
      setViewOptions(['Offerer'])
    }
  }, [userData?.users[0]?.has[0]?.roleName])

  useEffect(() => {
    if (viewTypeToShow !== undefined && viewTypeToShow !== null) {
      localStorage.setItem('showInvestorOrOffererView', viewTypeToShow)
      dispatch(setViewPreference(viewTypeToShow))
    }
  }, [viewTypeToShow])

  // EFFECT FOR WALLET CONNECTION
  useEffect(() => {
    if (userData?.users[0]?.wallet != null) return

    if (
      addressFromURL !== '' &&
      addressFromURL != undefined &&
      addressStatus != 'reject'
    ) {
      // eslint-disable-next-line
      const stakeAddress = WalletGC.getAddressInfo(addressFromURL)
      const walletID = uuidv4()
      createWallet({
        variables: {
          walletID: walletID,
          address: addressFromURL,
          stakeAddress: addressFromURL,
        },
      })
        // eslint-disable-next-line
        .then((res) =>
          linkWallet({
            variables: {
              userID: user?.email || user?.sub,
              walletID: walletID,
            },
          })
        )
        // eslint-disable-next-line
        .catch((err) => {
          //Err
        })
    }
  }, [addressFromURL])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
    dispatch(logoutAction())
  }

  // eslint-disable-next-line
  const handleChangeViewType = (event) => {
    setViewTypeToShow(event.target.value)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // eslint-disable-next-line
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  // eslint-disable-next-line
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  //FUNCTIONS FOR NOTIFICATION HANDLING
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openNotificationsDesk, setOpenNotificationsDesk] = useState(false)
  // eslint-disable-next-line
  const handleOpenNotificationsDesk = async () => {
    const notificationsIds = []
    setOpenNotificationsDesk(openNotificationsDesk ? false : true)
    notificationsState.forEach((notification) => {
      if (notification.read == false) {
        notificationsIds.push(notification.id)
      }
    })
    // eslint-disable-next-line
    const response = await updateNotifications({
      variables: {
        ids: notificationsIds,
      },
    })
    setUnreadNotifications(false)
  }
  // eslint-disable-next-line
  const handleOpenNotifications = async () => {
    const notificationsIds = []
    setOpenNotifications(openNotifications ? false : true)
    notificationsState.forEach((notification) => {
      if (notification.read == false) {
        notificationsIds.push(notification.id)
      }
    })
    // eslint-disable-next-line
    const response = await updateNotifications({
      variables: {
        ids: notificationsIds,
      },
    })
    setUnreadNotifications(false)
  }

  //Function change color
  const [dashboard, setDashboard] = useState(false)
  const [explore, setExplore] = useState(false)
  const [offering, setOffering] = useState(false)
  const [wallet, setWallet] = useState(false)

  const handleRedir = (dir) => {
    handleCloseNavMenu()
    dispatch(push(dir))
  }

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: actualThemeLight ? '#EFEFEF' : '#1D1D1D',
        padding: '12px 0px',
      }}
      elevation={0}
    >
      <Container
        sx={{
          width: '90%',
          margin: 'auto',
          maxWidth: 'none !important',
          padding: '0px',
        }}
      >
        <Toolbar
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          disableGutters
        >
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: '#4F4F4F' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {isAuthenticated ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                  }}
                  onClick={() => handleRedir('/profile-settings')}
                >
                  <Avatar src={userData?.users[0]?.profilePicture}></Avatar>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                      fontSize: '15px',
                      fontFamily: 'Quicksand, sans-serif',
                      fontStyle: 'normal',
                      textAlign: 'start',
                      width: '100%',
                      ml: 1,
                    }}
                  >
                    {user?.name}
                  </Typography>
                </Box>
              ) : null}

              {isAuthenticated === false ? (
                <MenuItem onClick={() => loginWithRedirect()}>
                  <Box sx={{ flexGrow: 0 }}>
                    <Typography>Login</Typography>
                  </Box>
                </MenuItem>
              ) : null}
              <MenuItem>
                <Box sx={{ flexGrow: 0 }}>
                  <I18nMenu />
                </Box>
              </MenuItem>
              <Divider />
              {/* AUTH == true OPTIONS */}
              <Box sx={{ pl: 2, pr: 2, pb: 1 }}>
                <DarkModeSwitch />
              </Box>
              <Divider />
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/profile-settings')}>
                  <Typography>{t('NAVBAR.EDIT_PROFILE')}</Typography>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/dashboard')}>
                  <Typography>{t('NAVBAR.DASHBOARD')}</Typography>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/funds')}>
                  <Typography>{t('NAVBAR.FUNDS')}</Typography>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/companies')}>
                  <Typography>{t('NAVBAR.COMPANIES')}</Typography>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/wallet')}>
                  <Typography>{t('NAVBAR.MY_WALLET')}</Typography>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/dao/calendar')}>
                  <Typography>{t('NAVBAR.DAO')}</Typography>
                </MenuItem>
              ) : null}
              {isAuthenticated ? (
                <MenuItem onClick={() => handleRedir('/faq')}>
                  <Typography>FAQ</Typography>
                </MenuItem>
              ) : null}
              <Divider />
              {isAuthenticated ? (
                <MenuItem onClick={() => handleLogout()}>
                  <Typography>{t('NAVBAR.LOG_OUT')}</Typography>
                </MenuItem>
              ) : null}
            </Menu>
          </Box>
          {/*Mobile*/}
          <IconButton
            component="div"
            onClick={() => handleRedir('/')}
            sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}
          >
            <Logo />
          </IconButton>
          {/* LINKS CONTAINER */}
          {isAuthenticated == 28 ? (
            <Container sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              {/* DASHBOARD LINK */}
              <Box sx={{ mr: '37px' }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/dashboard'))
                    setDashboard(true)
                    setExplore(false)
                    setOffering(false)
                    setWallet(false)
                  }}
                  className={
                    !dashboard ? Style.titlesNavLight : Style.titlesNavBold
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: '#9A1D4C',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.DASHBOARD')}
                </Typography>
              </Box>
              {/* EXPLORE LINK */}
              {viewTypeToShow !== 'Offerer' && (
                <Box sx={{ mr: '37px' }}>
                  <Typography
                    onClick={() => {
                      dispatch(push('/funds'))
                      setDashboard(false)
                      setExplore(true)
                      setOffering(false)
                      setWallet(false)
                    }}
                    className={
                      !explore ? Style.titlesNavLight : Style.titlesNavBold
                    }
                    sx={{
                      fontSize: { sm: '12px', md: '16px', lg: '18px' },
                      fontFamily: 'Quicksand, sans-serif',
                      lineHeight: '20px',
                      color: '#9A1D4C',
                      cursor: 'pointer !important',
                    }}
                  >
                    {t('NAVBAR.EXPLORE')}
                  </Typography>
                </Box>
              )}
              {/* MY OFFERINGS LINK */}
              {viewTypeToShow !== 'Investor' && (
                <Box sx={{ mr: '37px' }}>
                  <Typography
                    onClick={() => {
                      dispatch(push('/my-offerings'))
                      setDashboard(false)
                      setExplore(false)
                      setOffering(true)
                      setWallet(false)
                    }}
                    className={
                      !offering ? Style.titlesNavLight : Style.titlesNavBold
                    }
                    sx={{
                      fontSize: { sm: '12px', md: '16px', lg: '18px' },
                      fontFamily: 'Quicksand, sans-serif',
                      lineHeight: '20px',
                      color: '#9A1D4C',
                      cursor: 'pointer !important',
                    }}
                  >
                    {t('NAVBAR.MY_OFFERINGS')}
                  </Typography>
                </Box>
              )}
              {/* WALLET LINK */}
              <Box sx={{ mr: '37px' }}>
                <Typography
                  onClick={() => {
                    dispatch(push('/wallet'))
                    setDashboard(false)
                    setExplore(false)
                    setOffering(false)
                    setWallet(true)
                  }}
                  className={
                    !wallet ? Style.titlesNavLight : Style.titlesNavBold
                  }
                  sx={{
                    fontSize: { sm: '12px', md: '16px', lg: '18px' },
                    fontFamily: 'Quicksand, sans-serif',
                    lineHeight: '20px',
                    color: '#9A1D4C',
                    cursor: 'pointer !important',
                  }}
                >
                  {t('NAVBAR.MY_WALLET')}
                </Typography>
              </Box>
              {/* INVERSOR LINK */}
              {viewTypeToShow === 'Offerer' && (
                <Box sx={{ mr: '37px' }}>
                  <Typography
                    onClick={() => {
                      dispatch(push('/funds'))
                      setDashboard(false)
                      setExplore(true)
                      setOffering(false)
                      setWallet(false)
                    }}
                    className={
                      !explore ? Style.titlesNavLight : Style.titlesNavBold
                    }
                    sx={{
                      fontSize: { sm: '12px', md: '16px', lg: '18px' },
                      fontFamily: 'Quicksand, sans-serif',
                      lineHeight: '20px',
                      color: '#9A1D4C',
                      cursor: 'pointer !important',
                    }}
                  >
                    {t('NAVBAR.EXPLORE')}
                  </Typography>
                </Box>
              )}
            </Container>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavBar
