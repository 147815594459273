import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        backgroundColor: '#262626',
        color: 'white',
        height: '100%',
        width: '100%',
        fontFamily: 'Quicksand',
      },
      a: {
        textDecoration: 'none',
        color: '#e6a3bd',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
)

const GlobalStylesDark = () => {
  useStyles()

  return null
}

export default GlobalStylesDark
