import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tabs,
  Tab,
  Grid,
  Box,
  useTheme,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import './CompanySearchView.css'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_INFO } from '../../Queries/Users'
import {
  GET_COMPANIES_SEARCH,
  GET_COMPANIES_SEARCH_NO_PREF,
  GET_PROPOSALS_BY_USER,
} from '../../Queries/Companies'
import { GET_ACTIVE_FUNDS } from '../../Queries/Funds'
import useDebounce from '../../hooks/useDebounce'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import ActiveCompanieCard from '../DashboardView/newDashboardView/ActiveCompanieCard/ActiveCompanieCard'
import ActiveProposalCard from '../../components/ActiveProposalCard/ActiveProposalCard'
import PropTypes from 'prop-types'
import { GET_USER_COMPANIES } from '../../Queries/Companies'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import i18n from 'i18next'

export default function CompanySearchView() {
  const { t } = useTranslation()
  const selectedLanguage = i18n.language
  const { user } = useAuth0()
  const dispatch = useDispatch()
  // Custom hook to debounce search term updates
  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 250)
  // States
  const [value, setValue] = useState(0)
  const [openFilter, setOpenFilter] = useState(false)
  const [sort, setSort] = useState({ coName: 'ASC' })
  const [postSort] = useState({ titleEn: 'ASC' })
  const [filter, setFilter] = useState(null)

  // Query to fetch user data
  const [getUserData, { data: userData }] = useLazyQuery(GET_USER_INFO, {
    variables: { email: user?.email || user?.sub },
  })

  const [getActiveFundsData, { data: activeFunds, loading: loadingFunds }] =
    useLazyQuery(GET_ACTIVE_FUNDS, {
      variables: { email: user?.email || user?.sub },
    })

  const [selectedFundId, setSelectedFundId] = useState('All')

  const prefersIds = userData?.users[0]?.prefers.map(
    (preference) => preference.id
  )

  // Query to fetch user companies
  const [
    fetchUserCompanies,
    { data: userCompaniesData, loading: userCompaniesDataLoading },
  ] = useLazyQuery(GET_USER_COMPANIES, {
    variables: { userId: user?.email || user?.sub },
    pollInterval: 2000,
  })

  // Query to fetch companies with same interests
  const [fetchCompanies, { data: companiesData }] = useLazyQuery(
    GET_COMPANIES_SEARCH,
    {
      pollInterval: 10000,
      variables: {
        sort: sort,
        filter: Boolean(filter),
        prefArray: { id_IN: prefersIds },
      },
    }
  )

  function filterCompanies(companies, searchTerm) {
    if (!searchTerm) {
      return companies
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase()

    const filteredCompanies = companies?.filter((company) => {
      return (
        (company.coName &&
          company.coName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (company.descriptionEn &&
          company.descriptionEn.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (company.descriptionEs &&
          company.descriptionEs.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (company.descriptionPt &&
          company.descriptionPt.toLowerCase().includes(lowerCaseSearchTerm))
      )
    })

    return filteredCompanies
  }

  const companiesDataAfterSearch = filterCompanies(
    companiesData?.companies,
    debouncedSearchTerm
  )

  function filterCompaniesBySelectedFund(companies, selectedFundId) {
    if (selectedFundId === 'All') {
      return companies
    }

    const filteredCompanies = companies?.filter((company) => {
      const matchingProposals = company?.proposal?.filter(
        (proposal) => proposal?.fund?.id === selectedFundId
      )
      return matchingProposals.length > 0
    })

    return filteredCompanies
  }

  // Query to fetch companies that DONT have the same interests and NO filter
  const [
    fetchCompaniesNoFilterNoInterest,
    { data: companiesDataNoFilterNoInterest },
  ] = useLazyQuery(GET_COMPANIES_SEARCH_NO_PREF, {
    pollInterval: 10000,
    variables: {
      sort: sort,
      userId: user?.email || user?.sub,
      prefArray: { id_IN: prefersIds },
    },
  })

  const filteredCompaniesDataNoFilterNoInterest = filterCompaniesBySelectedFund(
    companiesDataNoFilterNoInterest?.companies,
    selectedFundId
  )

  const filteredCompaniesDataNoFilterNoInterestAfterSearch = filterCompanies(
    filteredCompaniesDataNoFilterNoInterest,
    debouncedSearchTerm
  )

  const [
    fetchPostulations,
    { data: userPostulations, loading: userPostulationsLoading },
  ] = useLazyQuery(GET_PROPOSALS_BY_USER, {
    pollInterval: 10000,
    variables: {
      search: debouncedSearchTerm,
      sort: postSort,
      userId: user?.email || user?.sub,
    },
  })

  // Effect hook to fetch companies when the filter values change
  useEffect(() => {
    fetchCompanies()
    fetchPostulations()
    getUserData()
    getActiveFundsData()
    fetchUserCompanies()
    fetchCompaniesNoFilterNoInterest()
  }, [
    fetchCompanies,
    fetchPostulations,
    getUserData,
    getActiveFundsData,
    fetchUserCompanies,
  ])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: { md: 2, xs: 0 },
            }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }

  // Function to handle sorting changes
  const handleChangeSort = (event) => {
    setSort(event.target.value)
  }

  // Functions to open and close filter menu
  const handleOpenFilter = () => setOpenFilter(true)
  const handleCloseFilter = () => setOpenFilter(false)

  // Inline styles for filter menu
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 500, xs: '90%' },
    bgcolor: 'background.paper',
    p: 3,
  }

  const locStorageRole = localStorage.getItem('role')
  useEffect(() => {
    if (locStorageRole === 'role-investor') {
      setValue(2)
    }
  }, [locStorageRole])

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <Box sx={{ backgroundColor: actualThemeLight ? null : '#262626' }}>
      {locStorageRole !== 'role-investor' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'center',
            width: { md: '100%', xs: '99%' },
          }}
        >
          <Tabs
            allowScrollButtonsMobile
            md={6}
            xs={12}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
          >
            <Tab label={t('OFFERINGS_TABLE.MY_COMPANIES')} />
            <Tab label={t('OFFERINGS_TABLE.MY_POSTULATIONS')} />
            <Tab label={t('OFFERINGS_TABLE.FIND_COMPANIES')} />
          </Tabs>
        </Box>
      )}
      {value === 0 && (
        <>
          <Box>
            <Box
              sx={{
                pb: '70px',
                width: '100%',
              }}
            >
              <Box
                onClick={() => {
                  dispatch(push('/companies-create'))
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  ml: 6,
                  alignItems: 'center',
                  cursor: 'pointer',
                  mt: { md: 0, xs: 2 },
                }}
              >
                <AddCircleIcon
                  sx={{
                    width: '50px',
                    height: '50px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                  }}
                />
                <Typography
                  sx={{
                    ml: 1,
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                  }}
                >
                  {t('OFFERINGS_TABLE.CREATE_COMPANY')}
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  width: { xs: '100%', sm: '100%' },
                  pl: { md: 6, xs: 2 },
                  pr: { md: 6, xs: 2 },
                  mt: 3,
                }}
              >
                {userCompaniesDataLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      mt: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  userCompaniesData?.companies?.map((company) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        key={company.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          mb: 4,
                        }}
                      >
                        <Box sx={{ width: { md: '75%', xs: '100%' } }}>
                          <ActiveCompanieCard
                            id={company?.id}
                            name={company?.coName}
                            logo={company?.logo}
                            isValidated={company?.isValidated}
                            isDraft={company?.isDraft}
                            fundName={company?.proposal[0]?.fund?.name}
                            address={company?.address}
                            country={company?.country}
                            employees={company?.employees}
                            fundationYear={company?.fundationYear}
                            annualBilling={company?.annualBilling}
                            isIncJurisdiction={company?.isIncJurisdiction}
                            isIncorporated={company?.isIncorporated}
                            seeMore={true}
                            showMore={true}
                            desc={
                              selectedLanguage === 'en'
                                ? company?.descriptionEn
                                : selectedLanguage === 'es' &&
                                  company?.descriptionEs !== ''
                                ? company?.descriptionEs
                                : selectedLanguage === 'pt' &&
                                  company?.descriptionPt !== ''
                                ? company?.descriptionPt
                                : company?.descriptionEn
                            }
                            link={company?.has_url[0]?.link}
                          />
                        </Box>
                      </Grid>
                    )
                  })
                )}
              </Grid>
            </Box>
          </Box>
        </>
      )}
      <TabPanel value={value} index={1}>
        <>
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              mt: 4,
            }}
          >
            <Grid item xs={12} md={6} sx={{ pl: 6, pr: 6 }}>
              <FormControl fullWidth>
                <OutlinedInput
                  size="small"
                  fullWidth
                  autoFocus="autoFocus"
                  placeholder={t('COMPANY_VIEW.SEARCH')}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  startAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                pl: { md: 0, xs: 4 },
                pr: { md: 0, xs: 4 },
                mt: { md: 0, xs: 2 },
              }}
            >
              <ImportExportOutlinedIcon />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  mr: 1,
                }}
              >
                {t('ADMIN.SORT_BY')}
              </Typography>
              <FormControl sx={{ minWidth: '200px', maxWidth: '200px' }}>
                <Select
                  sx={{ maxHeight: '40px' }}
                  value={sort}
                  onChange={handleChangeSort}
                  renderValue={(selected) => {
                    if (selected.coName) {
                      return selected.coName === 'ASC'
                        ? t('FUND_SEARCH_VIEW.NAME_SORT_A')
                        : t('FUND_SEARCH_VIEW.NAME_SORT_Z')
                    }
                    return ''
                  }}
                >
                  <MenuItem value={{ coName: 'ASC' }}>
                    {t('COMPANY_VIEW.NAME_SORT_A')}
                  </MenuItem>
                  <MenuItem value={{ coName: 'DESC' }}>
                    {t('COMPANY_VIEW.NAME_SORT_Z')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                pl: { md: 0, xs: 8 },
                pr: { md: 0, xs: 8 },
                mt: { md: 0, xs: 2 },
              }}
            >
              <Box
                onClick={handleOpenFilter}
                sx={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <FilterListIcon />
                <Typography
                  sx={{
                    width: '150px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    ml: 1,
                  }}
                >
                  {t('OFFERINGS_TABLE.FILTER')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box
              sx={{
                pb: '70px',
                width: '100%',
              }}
            >
              <Grid
                container
                sx={{
                  width: { xs: '100%', sm: '100%' },
                  pl: { md: 4, xs: 2 },
                  pr: { md: 4, xs: 2 },
                  mt: 3,
                }}
              >
                {userPostulationsLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      mt: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  userPostulations?.proposals?.map((proposal) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        key={proposal.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          mb: 4,
                          pr: 1,
                          pl: 1,
                        }}
                      >
                        <Box sx={{ width: { md: '100%', xs: '100%' } }}>
                          <ActiveProposalCard
                            id={proposal?.id}
                            name={proposal?.company?.coName}
                            logo={proposal?.company?.logo}
                            state={proposal?.assessmentStatus}
                            fundName={proposal?.fund?.name}
                            address={proposal?.company?.address}
                            country={proposal?.company?.country}
                            creator={true}
                            showMore={true}
                            link={proposal?.url[0]?.link}
                            proposalTitle={
                              selectedLanguage === 'en'
                                ? proposal?.titleEn
                                : selectedLanguage === 'es'
                                ? proposal?.titleEs || proposal?.titleEn || ''
                                : selectedLanguage === 'pt'
                                ? proposal?.titlePt || proposal?.titleEn || ''
                                : null
                            }
                            proposalScore={proposal?.assessmentRating}
                            desc={
                              selectedLanguage === 'en'
                                ? proposal?.descriptionEn
                                : selectedLanguage === 'es'
                                ? proposal?.descriptionEs ||
                                  proposal?.descriptionEn ||
                                  ''
                                : selectedLanguage === 'pt'
                                ? proposal?.descriptionPt ||
                                  proposal?.descriptionEn ||
                                  ''
                                : null
                            }
                            ADArequest={proposal?.reqInvestAmount}
                            applicationId={proposal?.id}
                            fundStatus={proposal?.fund?.status}
                          />
                        </Box>
                      </Grid>
                    )
                  })
                )}
              </Grid>
            </Box>
          </Box>
          {/* FILTER MODAL */}
          <Modal
            open={openFilter}
            onClose={handleCloseFilter}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                onClick={handleOpenFilter}
                sx={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <FilterListIcon />
                <Typography
                  sx={{
                    width: '150px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    ml: 1,
                  }}
                >
                  {t('OFFERINGS_TABLE.FILTER')}
                </Typography>
              </Box>
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: -6,
                  mb: 2,
                }}
              >
                <IconButton>
                  <CloseIcon onClick={handleCloseFilter} />
                </IconButton>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    variant="outlined"
                    sx={{ mb: 1 }}
                    onClick={() => {
                      setFilter(['pending', 'accepted', 'rejected'])
                      handleCloseFilter()
                    }}
                  >
                    {t('FUND_SEARCH_VIEW.STATUS_ALL')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setFilter(['pending'])
                      handleCloseFilter()
                    }}
                  >
                    {t('COMPANY_VIEW.STATUS_PENDING')}
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    sx={{ mb: 1 }}
                    variant="outlined"
                    onClick={() => {
                      setFilter(['accepted'])
                      handleCloseFilter()
                    }}
                  >
                    {t('COMPANY_VIEW.STATUS_ACCEPTED')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setFilter(['rejected'])
                      handleCloseFilter()
                    }}
                  >
                    {t('COMPANY_VIEW.STATUS_REJECTED')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          {/* FILTER MODAL - END */}
        </>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <>
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              mt: 4,
            }}
          >
            {/* FILTER */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                pl: { md: 0, xs: 4 },
                pr: { md: 0, xs: 4 },
                mt: { md: 0, xs: 2 },
                mb: { md: 0, xs: 2 },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  mr: 1,
                  color: actualThemeLight ? 'black' : 'white',
                }}
              >
                {t('INVESTMENT_FILTER.ORDER_BY')} {t('NAVBAR.FUNDS')}
              </Typography>
              <FormControl sx={{ minWidth: '200px', maxWidth: '200px' }}>
                {!loadingFunds && (
                  <Select
                    defaultValue={'All'}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedFundId}
                    size="small"
                    fullWidth
                    onChange={(event) => {
                      const selectedIndex = event.target.value
                      setSelectedFundId(selectedIndex)
                    }}
                  >
                    <MenuItem value={'All'}>
                      {t('INVESTMENT_FILTER.ALL')}
                    </MenuItem>
                    {activeFunds?.funds?.map((fund) => {
                      const name = fund.name
                      const id = fund.id
                      return (
                        <MenuItem key={fund.id} value={id}>
                          {name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ pl: 6, pr: 6 }}>
              <FormControl
                fullWidth
                sx={{
                  margin: { xs: '0 auto', md: 0 },
                }}
              >
                <OutlinedInput
                  size="small"
                  autoFocus="autoFocus"
                  placeholder={t('COMPANY_VIEW.SEARCH')}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  startAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{ width: '100%', margin: '0 auto' }}
                />
              </FormControl>
            </Grid>

            {/* SORT */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                pl: { md: 0, xs: 4 },
                pr: { md: 0, xs: 4 },
                mt: { md: 0, xs: 2 },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  mr: 1,
                  color: actualThemeLight ? 'black' : 'white',
                }}
              >
                {t('ADMIN.SORT_BY')}
              </Typography>
              <FormControl sx={{ minWidth: '200px', maxWidth: '200px' }}>
                <Select
                  sx={{ maxHeight: '40px' }}
                  value={sort}
                  onChange={handleChangeSort}
                  renderValue={(selected) => {
                    if (selected.coName) {
                      return selected.coName === 'ASC'
                        ? t('FUND_SEARCH_VIEW.NAME_SORT_A')
                        : t('FUND_SEARCH_VIEW.NAME_SORT_Z')
                    }
                    return ''
                  }}
                >
                  <MenuItem value={{ coName: 'ASC' }}>
                    {t('COMPANY_VIEW.NAME_SORT_A')}
                  </MenuItem>
                  <MenuItem value={{ coName: 'DESC' }}>
                    {t('COMPANY_VIEW.NAME_SORT_Z')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            <Box
              sx={{
                pb: '70px',
                width: '100%',
              }}
            >
              <Grid
                container
                sx={{
                  width: { xs: '100%', sm: '100%' },
                  pl: { md: 6, xs: 2 },
                  pr: { md: 6, xs: 2 },
                  mt: 3,
                }}
              >
                {selectedFundId === 'All' &&
                search !== '' &&
                filteredCompaniesDataNoFilterNoInterestAfterSearch?.length ===
                  0 ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: '20px' }}>
                      {t('COMPANY_VIEW.NO_COMPANY_FOUND')}
                    </Typography>
                  </Box>
                ) : null}
                {filter === null &&
                  filteredCompaniesDataNoFilterNoInterestAfterSearch?.map(
                    (company) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          key={company.id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            mb: 4,
                          }}
                        >
                          <Box sx={{ width: { md: '80%', xs: '100%' } }}>
                            <ActiveCompanieCard
                              id={company?.id}
                              name={company?.coName}
                              logo={company?.logo}
                              isValidated={company?.isValidated}
                              fundName={company?.proposal[0]?.fund?.name}
                              address={company?.address}
                              country={company?.country}
                              employees={company?.employees}
                              fundationYear={company?.fundationYear}
                              annualBilling={company?.annualBilling}
                              isIncJurisdiction={company?.isIncJurisdiction}
                              isIncorporated={company?.isIncorporated}
                              desc={
                                selectedLanguage === 'en'
                                  ? company?.descriptionEn
                                  : selectedLanguage === 'es' &&
                                    company?.descriptionEs !== ''
                                  ? company?.descriptionEs
                                  : selectedLanguage === 'pt' &&
                                    company?.descriptionPt !== ''
                                  ? company?.descriptionPt
                                  : company?.descriptionEn
                              }
                              link={company?.has_url[0]?.link}
                            />
                          </Box>
                        </Grid>
                      )
                    }
                  )}
                {selectedFundId !== 'All' &&
                companiesDataAfterSearch?.length === 0 ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: '20px' }}>
                      {t('COMPANY_VIEW.NO_COMPANY_YET')}
                    </Typography>
                  </Box>
                ) : (
                  filter !== null &&
                  companiesDataAfterSearch?.map((company) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        key={company.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          mb: 4,
                        }}
                      >
                        <Box sx={{ width: { md: '80%', xs: '100%' } }}>
                          <ActiveCompanieCard
                            id={company?.id}
                            name={company?.coName}
                            logo={company?.logo}
                            isValidated={company?.isValidated}
                            fundName={company?.proposal[0]?.fund?.name}
                            address={company?.address}
                            country={company?.country}
                            employees={company?.employees}
                            fundationYear={company?.fundationYear}
                            annualBilling={company?.annualBilling}
                            isIncJurisdiction={company?.isIncJurisdiction}
                            isIncorporated={company?.isIncorporated}
                            desc={
                              selectedLanguage === 'en'
                                ? company?.descriptionEn
                                : selectedLanguage === 'es' &&
                                  company?.descriptionEs !== ''
                                ? company?.descriptionEs
                                : selectedLanguage === 'pt' &&
                                  company?.descriptionPt !== ''
                                ? company?.descriptionPt
                                : company?.descriptionEn
                            }
                            link={company?.has_url[0]?.link}
                          />
                        </Box>
                      </Grid>
                    )
                  })
                )}
              </Grid>
            </Box>
          </Box>
        </>
      </TabPanel>
    </Box>
  )
}
