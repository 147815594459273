import {
    Box, Button, Checkbox, CircularProgress, Link, TableCell, TableRow,
    Tooltip, LinearProgress, Typography, Modal, TextField, InputAdornment, IconButton,
} from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Fade from '@mui/material/Fade';
import { UPDATE_OFFER_PURCHASE } from '../../../Queries/SmartContracts';
import { useMutation } from '@apollo/client';
import { showMessage } from '../../../store/actions/snackBarActions'
import { useDispatch } from 'react-redux';
import { CREATE_SMART_CONTRACT, UPDATE_SMART_CONTRACT } from '../../../Queries/SmartContracts';
import { v4 as uuidv4 } from 'uuid'
import { CREATE_NOTIFICATION } from '../../../Queries/Notifications'
import { CREATE_TRANSACTION_USER, UPDATE_TRANSACTION_STATUS } from "../../../Queries/Transactions";
import BadgeIcon from '@mui/icons-material/Badge';
import CopyToClipboardButton from '../UsersAdministration/CopyToClipboardButton'
import { CREATE_ERROR_LOG } from '../../../Queries/Errors';
import moment from 'moment'
import WarningIcon from '@mui/icons-material/Warning';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import OutputIcon from '@mui/icons-material/Output';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useCallback } from 'react';
import DirectMessageModal from '../../UserCard/DirectMessageModal';
import MessageIcon from '@mui/icons-material/Message';

const SCRow = ({
    data, totalSelected,
    sendDataToParent, sendDataToParentRemove, sendDataToParentID,
    sendDataToParentRemoveID, sendDataToParentUSERS, sendDataToParentRemoveUSERS,
    isCoreTeamWallet, isValidatorWallet, sendAmountToParentAdd, sendAmountToParentRemove,
    allData, refreshAllSc, setIsContractStillActive, setAmountTokenInActive, setAmountALLIInActive, finalWalletAddress, API,
    sendDataToParentCreatingTxEmptyTx, dataReceivedFromChildCreatingEmptyTx
}) => {

    function hasPendingWord(inputString) {
        return inputString?.includes("Pending");
    }

    const [updatedData, setUpdatedData] = useState([])

    useEffect(() => {
        setUpdatedData(data)
    }, [data])

    const { user } = useAuth0()
    const [createTransactionUser] = useMutation(CREATE_TRANSACTION_USER)
    const [updateTransactionUser] = useMutation(UPDATE_TRANSACTION_STATUS)
    const newTransactionId = uuidv4()
    const [inputString, setInputString] = useState(undefined)

    useEffect(() => {
        if (updatedData?.status !== undefined) {
            setInputString(updatedData?.status)
        }
    }, [updatedData])

    const containsPending = hasPendingWord(inputString);
    const totalAmountToGather = updatedData?.fund?.amountToReach
    const marloweApiUrlContracts = process.env.REACT_APP_DEMETER_URL + '/contracts';
    const pureMarloweApiUrl = process.env.REACT_APP_DEMETER_URL;
    const marloweScanUrl = "https://preprod.marlowescan.com/contractView?tab=info&contractId="
    const marloweScanUrlTransactions = "https://preprod.marlowescan.com/contractView?tab=tx-list&contractId="
    const demeterKey = process.env.REACT_APP_DEMETER_KEY

    function replaceHash(inputString) {
        var resultString = encodeURIComponent(inputString);
        return resultString;
    }

    const [smartContractIdURL, setSmartContractIdURL] = useState(undefined)
    const [smartContractIdURI, setSmartContractIdURI] = useState(undefined)

    useEffect(() => {
        setSmartContractIdURL(updatedData?.smartContract?.contractId)
    }, [updatedData])

    useEffect(() => {
        const resultURI = replaceHash(smartContractIdURL)
        setSmartContractIdURI(resultURI)
    }, [smartContractIdURL])

    const ValidatorWalletList = process.env.REACT_APP_TOKEN_WALLET
    const [validatorWalletArray, setValidatorWalletArray] = useState(undefined)
    const [firstValidatorWallet, setfirstValidatorWallet] = useState(undefined)

    useEffect(() => {
        setValidatorWalletArray(ValidatorWalletList?.split(','))
    }, [ValidatorWalletList])

    useEffect(() => {
        if (validatorWalletArray !== undefined) {
            setfirstValidatorWallet(validatorWalletArray[0])
        }
    }, [validatorWalletArray])

    const { t } = useTranslation()
    const currentTimeMillis = Date.now();
    const twoDaysMillis = 2 * 24 * 60 * 60 * 1000;
    const twoDaysAndTwoHoursMillis = twoDaysMillis + (2 * 60 * 60 * 1000);

    let twoDaysAfterCurrent = currentTimeMillis + twoDaysMillis;
    let twoDaysAndTwoHoursAfterCurrent = currentTimeMillis + twoDaysAndTwoHoursMillis;

    const [updateOfferPurchase] = useMutation(UPDATE_OFFER_PURCHASE)
    const [createSmartContract] = useMutation(CREATE_SMART_CONTRACT)
    const [updateSmartContract] = useMutation(UPDATE_SMART_CONTRACT)
    const [createNotification] = useMutation(CREATE_NOTIFICATION)
    const [createErrorLog] = useMutation(CREATE_ERROR_LOG)

    const dispatch = useDispatch()

    function findEndDateByStatus(data) {
        const { fund } = data;
        const { event } = fund;
        const matchingEvent = event?.find((eventItem) => eventItem?.titleEn === 'Collecting');
        if (matchingEvent) {
            return matchingEvent.endDate;
        } else {
            return "Event not found";
        }
    }

    const endDate = findEndDateByStatus(data);
    const endDateInMillis = new Date(endDate).getTime();
    const fiveDaysInMillis = 5 * 24 * 60 * 60 * 1000;
    const endDatePlusFiveDaysInMillis = endDateInMillis + fiveDaysInMillis;
    const exchangeRate = 1

    const [TokenNameInvestor, setTokenNameInvestor] = useState(undefined)
    const [TokenNameCoreTeam, setTokenNameCoreTeam] = useState(undefined)
    const [TokenNameValidator, setTokenNameValidator] = useState(undefined)

    useEffect(() => {
        const fundName = data?.fund?.name || "";
        const cleanedFundName = fundName.replace(/\s+/g, '').substring(0, 9);
        setTokenNameInvestor(`INV_${cleanedFundName}_${data?.id?.substr(-3)}`);
        setTokenNameCoreTeam(`COR_${cleanedFundName}_${data?.id?.substr(-3)}`);
        setTokenNameValidator(`VAL_${cleanedFundName}_${data?.id?.substr(-3)}`);
    }, [data]);

    const [isEndDatePassed, setIsEndDatePassed] = useState(false);

    const checkIfEndDatePassed = () => {
        const currentTimeInMillis = Date.now();
        if (currentTimeInMillis > endDatePlusFiveDaysInMillis) {
            setIsEndDatePassed(true);
        }
    };

    useEffect(() => {
        checkIfEndDatePassed();
        const interval = setInterval(checkIfEndDatePassed, 1000);

        return () => clearInterval(interval);
    }, [endDatePlusFiveDaysInMillis]);

    const smartContractTemplate = {
        when: [
            {
                then: {
                    when: [
                        {
                            then: {
                                when: [
                                    {
                                        then: {
                                            then: {
                                                token: {
                                                    token_name: 'tstALLI',
                                                    currency_symbol:
                                                        '5413911679525158254c00a953ea98b871b763e697ffcd3cc9b1ede9',
                                                },
                                                to: {
                                                    party: {
                                                        role_token: TokenNameInvestor,
                                                    },
                                                },
                                                then: {
                                                    token: {
                                                        token_name: data?.fund?.token[0]?.symbol,
                                                        currency_symbol: data?.fund?.token[0]?.policyId,
                                                    },
                                                    to: {
                                                        party: {
                                                            role_token: TokenNameCoreTeam,
                                                        },
                                                    },
                                                    then: 'close',
                                                    pay: data?.amount,
                                                    from_account: {
                                                        role_token: TokenNameInvestor,
                                                    },
                                                },
                                                pay: data?.amount * exchangeRate,
                                                from_account: {
                                                    role_token: TokenNameCoreTeam,
                                                },
                                            },
                                            if: {
                                                value: {
                                                    value_of_choice: {
                                                        choice_owner: {
                                                            role_token: TokenNameValidator,
                                                        },
                                                        choice_name: 'PAY',
                                                    },
                                                },
                                                equal_to: 1,
                                            },
                                            else: 'close',
                                        },
                                        case: {
                                            for_choice: {
                                                choice_owner: {
                                                    role_token: TokenNameValidator,
                                                },
                                                choice_name: 'PAY',
                                            },
                                            choose_between: [
                                                {
                                                    to: 2,
                                                    from: 1,
                                                },
                                            ],
                                        },
                                    },
                                ],
                                timeout_continuation: 'close',
                                timeout: endDatePlusFiveDaysInMillis,
                            },
                            case: {
                                party: {
                                    role_token: TokenNameInvestor,
                                },
                                of_token: {
                                    token_name: data?.fund?.token[0]?.symbol,
                                    currency_symbol: data?.fund?.token[0]?.policyId,
                                },
                                into_account: {
                                    role_token: TokenNameInvestor,
                                },
                                deposits: data?.amount,
                            },
                        },
                    ],
                    timeout_continuation: 'close',
                    timeout: twoDaysAndTwoHoursAfterCurrent,
                },
                case: {
                    party: {
                        role_token: TokenNameCoreTeam,
                    },
                    of_token: {
                        token_name: 'tstALLI',
                        currency_symbol:
                            '5413911679525158254c00a953ea98b871b763e697ffcd3cc9b1ede9',
                    },
                    into_account: {
                        role_token: TokenNameCoreTeam,
                    },
                    deposits: data?.amount * exchangeRate,
                },
            },
        ],
        timeout_continuation: 'close',
        timeout: twoDaysAfterCurrent,
    }

    const [creatingContract, setCreatingContract] = useState(false)
    const delay = 5000
    const uniqueID = uuidv4()
    const todayDate = new Date().toISOString()

    async function createMarloweContract() {
        try {
            setCreatingContract(true);
            //! CREATING CONTRACT ON DEMETER - START
            const createSCaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vendor.iog.marlowe-runtime.contract-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "contract": smartContractTemplate,
                    "metadata": {},
                    "minUTxODeposit": 2000000,
                    "roles": {
                        [TokenNameCoreTeam]: finalWalletAddress,
                        [TokenNameValidator]: firstValidatorWallet,
                        [TokenNameInvestor]: data?.investorAddress,
                    },
                    "tags": {},
                    "version": "v1"
                })
            };
            const responseSCcreated = await fetch(marloweApiUrlContracts, createSCaction);
            const createdContractData = await responseSCcreated.json();
            //! CREATING CONTRACT ON DEMETER - END

            // //! SIGNING CONTRACT TX - START
            await API.signTx(createdContractData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'success' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdContractData?.links?.contract)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))

                //! CREATING CONTRACT ON TOKEN´S DATABASE - START
                await createSmartContract({
                    variables: {
                        id: uniqueID,
                        createdDate: todayDate,
                        status: "SC Created",
                        contractId: createdContractData?.resource?.contractId,
                        contractLink: createdContractData?.links?.contract,
                        smartId: uniqueID,
                        offerPurchaseId: data?.id
                    }
                })
                await updateOfferPurchase({
                    variables: {
                        offerId: data?.id,
                        coreteamaddress: finalWalletAddress,
                        choicemakerAddress: firstValidatorWallet
                    }
                })
                //! CREATING CONTRACT ON TOKEN´S DATABASE - END
                await dispatch(showMessage({ message: 'Smart Contract created!', variant: 'success' }));
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1001",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1001 - Something went wrong, please try again' }));
            })
            // //! SIGNING CONTRACT TX - END
            await setCreatingContract(false);
            return;
        }
        catch (error) {
            setCreatingContract(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1002",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1002 - Something went wrong, please try again' }));
        }
    }

    const [creatingTx, setCreatingTx] = useState(false)
    const marloweApiUrl = process.env.REACT_APP_DEMETER_URL + '/' + data?.smartContract?.contractLink + '/transactions'
    const [notificationSent, setNotificationSent] = useState(false)

    async function depositTstAlli() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.apply-inputs-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "inputs": [
                        {
                            "input_from_party": {
                                "role_token": TokenNameCoreTeam
                            },
                            "into_account": {
                                "role_token": TokenNameCoreTeam
                            },
                            "of_token": {
                                "currency_symbol": "5413911679525158254c00a953ea98b871b763e697ffcd3cc9b1ede9",
                                "token_name": "tstALLI"
                            },
                            "that_deposits": data?.amount
                        }
                    ],
                    "metadata": {},
                    "tags": {},
                    "version": 'v1'
                })
            };
            const responseTXcreated = await fetch(marloweApiUrl, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await API.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'success' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.transaction)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                await updateOfferPurchase({
                    variables: {
                        offerId: data?.id,
                        status: "SC Created - ALLI Deposited - Pending",
                        txId: createdTXData?.resource?.transactionId,
                        lastUpdate: todayDate
                    }
                })
                const notifID = await uuidv4()
                const dateTime = await moment.utc()
                if (notificationSent === false) {
                    setNotificationSent(true)
                    await createNotification({
                        variables: {
                            notificationID: notifID,
                            msg: `RDYDEPOSIT - Core Team (${data?.fund?.name})`,
                            date: dateTime,
                            link: '/wallet',
                            userID: data?.users?.id,
                        },
                    })
                }
                await createTransactionUser({
                    variables: {
                        id: newTransactionId,
                        date: todayDate,
                        type: "Deposit (Core Team) - tstAlli",
                        status: "Pending",
                        hash: createdTXData?.resource?.transactionId,
                        amount: data?.amount,
                        userId: user?.email || user?.sub,
                        transactionId: newTransactionId,
                        offPurId: data?.id
                    }
                })
                dispatch(showMessage({ message: 'Deposit Successful', variant: 'success' }));
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1003",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1003 - Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setCreatingTx(false);
            return;
        } catch (error) {
            setCreatingTx(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1004",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1004 - Something went wrong, please try again' }));
        }
    }

    const [countdownEmptyTx, setCountdownEmptyTx] = useState(30);
    const [creatingTxEmptyTx, setCreatingTxEmptyTx] = useState(false);

    useEffect(() => {
        if (creatingTxEmptyTx === true) {
            const timer = setInterval(() => {
                setCountdownEmptyTx((prevCount) => {
                    if (prevCount === 0) {
                        setCreatingTxEmptyTx(false);
                        setCountdownEmptyTx(30);
                        getContractById()
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCount - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [creatingTxEmptyTx]);

    const startCountdownEmptyTx = () => {
        setCreatingTxEmptyTx(true);
    };

    useEffect(() => {
        if (creatingTxEmptyTx === true) {
            sendDataToParentCreatingTxEmptyTx(creatingTxEmptyTx)
        }
    }, [creatingTxEmptyTx])

    async function updateContractAfterTimeout() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.apply-inputs-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "inputs": [],
                    "metadata": {},
                    "tags": {},
                    "version": 'v1'
                })
            };
            const responseTXcreated = await fetch(marloweApiUrl, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END
            // //! SIGNING TX - START
            await API.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'success' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.transaction)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                dispatch(showMessage({ message: 'Empty transaction sent successfully. Confirmation timeout set to 30 seconds.', variant: 'success' }));
                startCountdownEmptyTx()
            }).catch(function (error) {
                const errorMessage = error.toString();
                console.log(errorMessage)
                dispatch(showMessage({ message: 'Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setCreatingTx(false);
            return;
        } catch (error) {
            setCreatingTx(false);
            console.log(error)
        }
    }

    const [notificationSentPay, setNotificationSentPay] = useState(false)

    async function makeChoicePay() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.apply-inputs-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "inputs": [{
                        "for_choice_id": {
                            "choice_name": "PAY",
                            "choice_owner": {
                                "role_token": TokenNameValidator
                            }
                        },
                        "input_that_chooses_num": 1
                    },
                    ],
                    "metadata": {},
                    "tags": {},
                    "version": "v1"
                })
            };
            const responseTXcreated = await fetch(marloweApiUrlPay, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await API.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'success' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.transaction)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                await updateOfferPurchase({
                    variables: {
                        offerId: data?.id,
                        status: "Payout Executed - Pending",
                        txId: createdTXData?.resource?.transactionId,
                        lastUpdate: todayDate
                    }
                })
                const notifID = await uuidv4()
                const dateTime = await moment.utc()
                if (notificationSentPay === false) {
                    setNotificationSentPay(true)
                    await createNotification({
                        variables: {
                            notificationID: notifID,
                            msg: `PAYOUTEXE - Core Team (${data?.fund?.name})`,
                            date: dateTime,
                            link: '/wallet',
                            userID: data?.users?.id,
                        },
                    })
                }
                dispatch(showMessage({ message: 'Choice Executed', variant: 'success' }));
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1005",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1005 - Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setCreatingTx(false);
            return;
        } catch (error) {
            setCreatingTx(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1006",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1006 - Something went wrong, please try again' }));
        }
    }

    const [payoutToWithdraw, setPayoutToWithdraw] = useState(undefined)
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
        if (checked === true && payoutToWithdraw !== undefined) {
            sendDataToParent(payoutToWithdraw)
            sendDataToParentID(data?.id)
            sendAmountToParentAdd(data?.amount)
            sendDataToParentUSERS(data?.users?.id)
        } else if (checked === false && payoutToWithdraw === undefined) {
            sendDataToParentRemove(payoutToWithdraw)
            sendDataToParentRemoveID(data?.id)
            sendAmountToParentRemove(data?.amount)
            sendDataToParentRemoveUSERS(data?.users?.id)
        }
    }, [payoutToWithdraw, checked])

    const [timer, setTimer] = useState({ minutes: 3, seconds: 0 });
    const [isTimerFinished, setIsTimerFinished] = useState(false);
    const [isCountdownStarted, setIsCountdownStarted] = useState(false);

    useEffect(() => {
        let countdown;
        if (isCountdownStarted) {
            countdown = setInterval(() => {
                if (timer.minutes === 0 && timer.seconds === 0) {
                    clearInterval(countdown);
                    setIsTimerFinished(true);
                } else {
                    if (timer.seconds === 0) {
                        setTimer({ minutes: timer.minutes - 1, seconds: 59 });
                    } else {
                        setTimer({ ...timer, seconds: timer.seconds - 1 });
                    }
                }
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [timer, isCountdownStarted]);

    const startCountdown = () => {
        setIsCountdownStarted(true);
    };

    useEffect(() => {
        if (containsPending === true) {
            startCountdown()
        }
    }, [containsPending])

    const formatTime = (time) => (time < 10 ? `0${time}` : time);

    const Day = data?.on?.split('-')[2].split('T')[0]
    const Month = data?.on?.split('-')[1]

    const DayLastUpdate = data?.lastUpdate?.split('-')[2].split('T')[0]
    const MonthLastUpdate = data?.lastUpdate?.split('-')[1]

    const [dataPendingTx, setDataPendingTx] = useState(undefined)
    const [dataPendingTxOffId, setDataPendingTxOffId] = useState(undefined)
    const [dataPendingTxOffStatus, setDataPendingTxOffStatus] = useState(undefined)
    const [dataPendingTxDBID, setDataPendingTxDBID] = useState(undefined)
    const newStatusToUpdateDeposit = dataPendingTxOffStatus?.replace(' - Pending', '')

    useEffect(() => {
        if (containsPending === true) {
            setDataPendingTx(data?.lastTxId)
            setDataPendingTxOffId(data?.id)
            setDataPendingTxOffStatus(data?.status)
            setDataPendingTxDBID(data?.transaction?.id)
        }
    }, [containsPending])

    const [blockConfirm, setBlockConfirm] = useState('')
    const [currTime, setCurrTime] = useState('')
    const first10fromTime = Number(currTime?.server_time?.toString().substring(0, 10))
    const readyAfter3Blocks = blockConfirm?.block_time + 60 < first10fromTime
    const [blockFrostAPITest, setBlockFrostAPITest] = useState(undefined)

    useEffect(() => {
        if (dataPendingTx !== undefined) {
            if (dataPendingTx?.includes('withdrawals')) {
                const updatedTxUrl = dataPendingTx.replace('withdrawals', '');
                setBlockFrostAPITest(`https://cardano-preprod.blockfrost.io/api/v0/txs/${updatedTxUrl}`);
            } else {
                setBlockFrostAPITest(`https://cardano-preprod.blockfrost.io/api/v0/txs/${dataPendingTx}`);
            }
        }
    }, [dataPendingTx]);

    const projectAPIKey = process.env.REACT_APP_BLOCKFROST_API
    const axiosBFClient = axios.create({
        baseURL: blockFrostAPITest,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            project_id: projectAPIKey,
        },
    })
    function get() {
        const finalRes = axiosBFClient
            .get(blockFrostAPITest)
            .then((res) => res.data)
        return finalRes
    }

    const blockFrostTimeTest = `https://cardano-preprod.blockfrost.io/api/v0/health/clock`
    const axiosBFClientTime = axios.create({
        baseURL: blockFrostTimeTest,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            project_id: projectAPIKey,
        },
    })
    function getTime() {
        const finalTimeRes = axiosBFClientTime
            .get(blockFrostTimeTest)
            .then((resTime) => resTime.data)
        return finalTimeRes
    }

    useEffect(async () => {
        if (blockConfirm?.block_time && readyAfter3Blocks) {
            await updateSingleOfferPurchase({
                variables: {
                    offerId: dataPendingTxOffId,
                    status: newStatusToUpdateDeposit,
                    txId: null,
                }
            })
            await updateTransactionUser({
                variables: {
                    txId: dataPendingTxDBID,
                    status: "Confirmed"
                }
            })
            await setDataPendingTxOffId(undefined)
            await setDataPendingTxDBID(undefined)
        }
    }, [blockConfirm, readyAfter3Blocks, dataPendingTx])

    useEffect(() => {
        if (blockFrostAPITest !== undefined) {
            const fetchData = () => {
                get().then((res) => {
                    setBlockConfirm(res);
                });
                getTime().then((time) => {
                    setCurrTime(time);
                });
            };
            const intervalId = setInterval(fetchData, 10000);
            return () => clearInterval(intervalId);
        }
    }, [blockFrostAPITest]);

    function isWithin48Hours(dateString) {
        const inputDate = new Date(dateString);
        inputDate.setDate(inputDate.getDate() + 2);
        const currentDate = new Date();
        return currentDate <= inputDate;
    }

    const isWithin48Hrs = isWithin48Hours(data?.smartContract?.createdDate);

    function isWithin50Hours(dateString) {
        const inputDate = new Date(dateString);
        inputDate.setHours(inputDate.getHours() + 50);
        const currentDate = new Date();
        return currentDate <= inputDate;
    }

    const isWithin50Hrs = isWithin50Hours(data?.smartContract?.createdDate);

    const [cancelInvReason, setCancelInvReason] = useState('')
    const [openConfirmationCancelInvest, setOpenConfirmationCancelInvest] = useState(false);
    const handleOpenConfirmation = () => setOpenConfirmationCancelInvest(true);
    const handleCloseConfirmation = () => setOpenConfirmationCancelInvest(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    async function cancelInvestment() {
        try {
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "Investment Cancelled",
                    lastUpdate: todayDate
                }
            })
            const notifID = await uuidv4()
            const dateTime = await moment.utc()
            if (notificationSent === false) {
                setNotificationSent(true)
                await createNotification({
                    variables: {
                        notificationID: notifID,
                        msg: `INVCANCEL - Core Team (${data?.fund?.name}) * ${cancelInvReason}`,
                        date: dateTime,
                        link: '/wallet',
                        userID: data?.users?.id,
                    },
                })
            }
            handleCloseConfirmation()
            dispatch(showMessage({ message: 'Investment Cancelled', variant: 'success' }));
            return;
        } catch (error) {
            setCreatingTx(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1009",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1009 - Something went wrong, please try again' }));
        }
    }

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    const totalAmountInvestorDeposited = allData?.reduce((acc, transaction) => {
        if (!excludedStatuses?.includes(transaction?.status)) {
            acc += transaction?.amount;
        }
        return acc;
    }, 0);

    async function createNewMarloweContract() {
        try {
            setCreatingContract(true);
            //! CREATING CONTRACT ON DEMETER - START
            const createSCaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vendor.iog.marlowe-runtime.contract-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "contract": smartContractTemplate,
                    "metadata": {},
                    "minUTxODeposit": 2000000,
                    "roles": {
                        [TokenNameCoreTeam]: finalWalletAddress,
                        [TokenNameValidator]: firstValidatorWallet,
                        [TokenNameInvestor]: data?.investorAddress,
                    },
                    "tags": {},
                    "version": "v1"
                })
            };
            const responseSCcreated = await fetch(marloweApiUrlContracts, createSCaction);
            const createdContractData = await responseSCcreated.json();
            //! CREATING CONTRACT ON DEMETER - END

            // //! SIGNING CONTRACT TX - START
            await API.signTx(createdContractData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'success' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdContractData?.links?.contract)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))

                //! CREATING CONTRACT ON TOKEN´S DATABASE - START
                await updateSmartContract({
                    variables: {
                        contractData: {
                            createdDate: todayDate,
                            status: "SC Created",
                            contractId: createdContractData?.resource?.contractId,
                            contractLink: createdContractData?.links?.contract
                        },
                        smartId: data?.smartContract?.id,
                    }
                })
                //! CREATING CONTRACT ON TOKEN´S DATABASE - END
                await dispatch(showMessage({ message: 'New Smart Contract created!', variant: 'success' }));
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1010",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1010 - Something went wrong, please try again' }));
            })
            // //! SIGNING CONTRACT TX - END
            await setCreatingContract(false);
            return;
        } catch (error) {
            setCreatingContract(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1011",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1011 - Something went wrong, please try again' }));
        }
    }

    const [updateOfferingMaxAmountReachedNotification, setUpdateOfferingMaxAmountReachedNotification] = useState(false);

    const updateOfferingMaxAmountReached = useCallback(async () => {
        try {
            const notifID = await uuidv4();
            const dateTime = await moment.utc();
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "Investment Cancelled - Target Met",
                    lastUpdate: todayDate
                }
            });
            await createNotification({
                variables: {
                    notificationID: notifID,
                    msg: `TARGETMET - Core Team (${data?.fund?.name})`,
                    date: dateTime,
                    link: "/wallet",
                    userID: data?.users?.id
                }
            });
        } catch (error) {
            const uniqueErrorID = uuidv4();
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1012",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            });
        }
    }, [todayDate, data, updateOfferPurchase, setUpdateOfferingMaxAmountReachedNotification, createNotification, totalAmountInvestorDeposited, totalAmountToGather]);

    useEffect(() => {
        if (
            !updateOfferingMaxAmountReachedNotification &&
            totalAmountInvestorDeposited >= totalAmountToGather &&
            [
                "Investment Intention",
                "Investment Intention - Waiting List"
            ].includes(data?.status)
        ) {
            updateOfferingMaxAmountReached();
            setUpdateOfferingMaxAmountReachedNotification(true);
        }
    }, [updateOfferingMaxAmountReachedNotification, updateOfferingMaxAmountReached, totalAmountInvestorDeposited, totalAmountToGather]);

    const [updateOfferingEndDateReacedNotification, setUpdateOfferingEndDateReacedNotification] = useState(false);

    const updateOfferingEndDateReaced = useCallback(async () => {
        try {
            const notifID = await uuidv4();
            const dateTime = await moment.utc();
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "Investment Cancelled - Collecting Ended",
                    lastUpdate: todayDate
                }
            });
            await createNotification({
                variables: {
                    notificationID: notifID,
                    msg: `COLLOVER - Core Team (${data?.fund?.name})`,
                    date: dateTime,
                    link: "/wallet",
                    userID: data?.users?.id
                }
            });
        } catch (error) {
            const uniqueErrorID = uuidv4();
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1013",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            });
        }
    }, [todayDate, endDate, data, updateOfferingEndDateReacedNotification]);

    useEffect(() => {
        if (
            !updateOfferingEndDateReacedNotification &&
            todayDate > endDate &&
            [
                "Investment Intention",
                "Investment Intention - Waiting List",
                "SC Created",
                "SC Created - ALLI Deposited"
            ].includes(data?.status)
        ) {
            updateOfferingEndDateReaced();
            setUpdateOfferingEndDateReacedNotification(true);
        }
    }, [todayDate, endDate, data, updateOfferingEndDateReaced, updateOfferingEndDateReacedNotification]);

    const depositTransactions = data?.transaction?.id

    const updateOfferingInvertorDepositNotConfirmed = async () => {
        try {
            const notifID = await uuidv4();
            const dateTime = await moment.utc()
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "SC Created - ALLI Deposited",
                    lastUpdate: todayDate
                }
            })

            await createNotification({
                variables: {
                    notificationID: notifID,
                    msg: `DEPNOTCONFIRMED - Core Team (${data?.fund?.name})`,
                    date: dateTime,
                    link: '/wallet',
                    userID: data?.users?.id,
                },
            });
            await updateTransactionUser({
                variables: {
                    txId: depositTransactions,
                    status: "Not confirmed"
                }
            })
        } catch (error) {
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1014",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
        }
    };

    const updateOfferWithdrawInvNotSuccess = async () => {
        try {
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "Payout Executed",
                    lastUpdate: todayDate
                }
            })
        } catch (error) {
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1027",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
        }
    };

    const updateOfferWithdrawInvNotSuccessAfterCore = async () => {
        try {
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "Core Withdrawn",
                    lastUpdate: todayDate
                }
            })
        } catch (error) {
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1028",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
        }
    };

    const updateOfferingPayoutNotExecuted = async () => {
        try {
            await updateOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: "Investor Deposited",
                    lastUpdate: todayDate
                }
            })
        } catch (error) {
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1029",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
        }
    };

    const [hasLegalData, setHasLegalData] = useState(false)

    useEffect(() => {
        if (
            data?.legalFullname !== null && data?.legalFullname !== '' ||
            data?.legalId !== null && data?.legalId !== '' ||
            data?.legalCountry !== null && data?.legalCountry !== '' ||
            data?.legalFundsOrigin !== null && data?.legalFundsOrigin !== ''
        ) {
            setHasLegalData(true)
        }
    }, [data])

    const amountToPassThresshold = data?.fund?.amountToReach * data?.fund?.successThreshold / 100
    const payableSuccessful = totalAmountInvestorDeposited >= amountToPassThresshold
    const [selectedMakePaySCLink, setSelectedMakePaySCLink] = useState('')
    const marloweApiUrlPay = process.env.REACT_APP_DEMETER_URL + '/' + selectedMakePaySCLink + '/transactions'
    const [selectedOffPurchaseId, setSelectedOffPurchaseId] = useState(undefined)
    const [madeChoiceClose, setMadeChoiceClose] = useState(false)

    useEffect(() => {
        if (madeChoiceClose === true) {
            makeChoiceClose()
        }
        setMadeChoiceClose(false)
    }, [TokenNameValidator, madeChoiceClose])

    const [madeChoicePay, setMadeChoicePay] = useState(false)

    useEffect(() => {
        if (madeChoicePay === true) {
            makeChoicePay()
        }
        setMadeChoicePay(false)
    }, [TokenNameValidator, madeChoicePay])

    const [notificationSentClose, setNotificationSentClose] = useState(false)

    async function makeChoiceClose() {
        try {
            setCreatingTx(true);

            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.apply-inputs-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "inputs": [{
                        "for_choice_id": {
                            "choice_name": "PAY",
                            "choice_owner": {
                                "role_token": TokenNameValidator
                            }
                        },
                        "input_that_chooses_num": 2
                    },
                    ],
                    "metadata": {},
                    "tags": {},
                    "version": "v1"
                })
            };
            const responseTXcreated = await fetch(marloweApiUrlPay, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await API.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            const responseObject = JSON.parse(this.response);
                            const message = responseObject.message
                            dispatch(showMessage({ message: message, variant: 'success' }));
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.transaction)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                await updateOfferPurchase({
                    variables: {
                        offerId: selectedOffPurchaseId,
                        status: "Close Executed - Pending",
                        txId: createdTXData?.resource?.transactionId,
                        lastUpdate: todayDate
                    }
                })
                const notifID = await uuidv4()
                const dateTime = await moment.utc()
                if (notificationSentClose === false) {
                    setNotificationSentClose(true)
                    await createNotification({
                        variables: {
                            notificationID: notifID,
                            msg: `CLOSEEXE - Core Team (${data?.fund?.name})`,
                            date: dateTime,
                            link: '/wallet',
                            userID: data?.users?.id,
                        },
                    })
                }
                dispatch(showMessage({ message: 'Choice Executed', variant: 'success' }));
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1019",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1019 - Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setCreatingTx(false);
            return;
        } catch (error) {
            setCreatingTx(false);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1020",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1020 - Something went wrong, please try again' }));
        }
    }

    useEffect(() => {
        if (smartContractIdURI !== undefined)
            getContractById()
    }, [smartContractIdURI])

    const [demeterContractData, setDemeterContractData] = useState(undefined);

    const tokenData = demeterContractData?.resource?.assets?.tokens?.[data?.fund?.token[0]?.policyId];
    const amountOfTokensInAssets = tokenData ? Object.values(tokenData)[0] : undefined;

    const tokenALLIData = demeterContractData?.resource?.assets?.tokens?.["5413911679525158254c00a953ea98b871b763e697ffcd3cc9b1ede9"];
    const amountOfALLIInAssets = tokenALLIData ? Object.values(tokenALLIData)[0] : undefined;

    useEffect(() => {
        if (amountOfTokensInAssets !== undefined) {
            setAmountTokenInActive(amountOfTokensInAssets)
        }
        if (amountOfALLIInAssets !== undefined) {
            setAmountALLIInActive(amountOfALLIInAssets)
        }
    }, [amountOfTokensInAssets, amountOfALLIInAssets])

    const [loading, setLoading] = useState(false);
    const [errorContract, setErrorContract] = useState(null)

    const getContractById = () => {
        if (smartContractIdURI !== "undefined") {
            setLoading(true);
            const url = `${marloweApiUrlContracts}/${smartContractIdURI}`;
            axios.get(url)
                .then((response) => {
                    setDemeterContractData(response.data);
                    setErrorContract(false)
                })
                .catch((error) => {
                    console.log("error:", error)
                    setErrorContract(true)
                    setDemeterContractData(undefined);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (refreshAllSc === true && smartContractIdURI !== undefined) {
            getContractById()
        }
    }, [refreshAllSc, smartContractIdURI])

    const [unclaimedPayouts, setUnclaimedPayouts] = useState(undefined)
    const [isNextStepDeposit, setisNextStepDeposit] = useState(false)
    const [isNextStepDone, setisNextStepDone] = useState(false)
    const [isNextDepositCT, setisNextDepositCT] = useState(false)
    const [isNextDepositIN, setisNextDepositIN] = useState(false)
    const [isNextStepChoice, setisNextStepChoice] = useState(false)

    useEffect(() => {
        setUnclaimedPayouts(demeterContractData?.resource?.unclaimedPayouts?.length)
        if (demeterContractData?.resource?.currentContract === null) {
            setisNextStepDone(true)
        } else {
            if (
                (data?.status === "Investment Intention" || data?.status === "Investment Intention - Waiting List") &&
                data?.smartContract !== null &&
                demeterContractData?.resource?.status === "confirmed"
            ) {
                updateOfferPurchase({
                    variables: {
                        offerId: data?.id,
                        status: "SC Created",
                        lastUpdate: todayDate,
                    }
                })
            }
            if (demeterContractData?.resource?.currentContract?.when[0]?.case?.deposits !== undefined) {
                setisNextStepDeposit(true)
            }
            if (demeterContractData?.resource?.currentContract?.when[0]?.case?.choose_between !== undefined) {
                setisNextStepChoice(true)
            }
            if (demeterContractData?.resource?.currentContract?.when[0]?.case?.party?.role_token?.substring(0, 3) === 'COR') {
                setisNextDepositCT(true)
            } else {
                setisNextDepositCT(false)
            }
            if (
                demeterContractData?.resource?.currentContract?.when[0]?.case?.party?.role_token?.substring(0, 3) === 'INV' &&
                (data?.status === "SC Created - ALLI Deposited" || data?.status === "SC Created - ALLI Deposited - Pending")
            ) {
                setisNextDepositIN(true)
            } else {
                setisNextDepositIN(false)
            }
        }
    }, [demeterContractData])

    const [hasCoreTeamPayout, setHasCoreTeamPayout] = useState(false);
    const [completePayoutToWithdraw, setCompletePayoutToWithdraw] = useState(undefined)

    useEffect(() => {
        const unclaimedPayouts = demeterContractData?.resource?.unclaimedPayouts || [];
        let hasRole = false;

        unclaimedPayouts.forEach(payout => {
            if (payout.role.startsWith('C') || payout.role.startsWith('F')) {
                hasRole = true;
                setCompletePayoutToWithdraw(payout);
            }
        });

        setHasCoreTeamPayout(hasRole);
    }, [demeterContractData]);

    const handleChange = async () => {
        if (checked === false) {
            await setPayoutToWithdraw(completePayoutToWithdraw?.payoutId)
            await setChecked(!checked);
        } else if (checked === true) {
            await setPayoutToWithdraw(undefined)
            await setChecked(false);
        }
    };

    const marloweApiUrlWithdrawals = process.env.REACT_APP_DEMETER_URL + '/withdrawals'
    const [updateOfferingInvestmentTimedOut, setUpdateOfferingInvestmentTimedOut] = useState(false)

    async function handleWithdrawInvNotDeposited() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.withdraw-tx-json',
                    'X-Change-Address': finalWalletAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "contractId": data?.smartContract?.contractId,
                    "role": TokenNameCoreTeam,
                    "payouts": [completePayoutToWithdraw?.payoutId]
                })
            };
            const responseTXcreated = await fetch(marloweApiUrlWithdrawals, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await API.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            //"Transaction submission failed."
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.withdrawal)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))

                await updateOfferPurchase({
                    variables: {
                        offerId: data?.id,
                        status: "SC Timed Out - Core Withdrawn",
                        txId: createdTXData?.links?.withdrawal,
                        lastUpdate: todayDate
                    }
                })
                await createTransactionUser({
                    variables: {
                        transactionData: {
                            id: newTransactionId,
                            date: todayDate,
                            type: "Withdraw (Core Team) - tstAlli",
                            status: "Confirmed",
                            hash: createdTXData?.links?.withdrawal,
                            amount: data?.amount
                        },
                        userId: user?.email || user?.sub,
                        transactionId: newTransactionId,
                        offPurId: data?.id
                    }
                })
                const notifID = await uuidv4()
                const dateTime = await moment.utc()
                if (updateOfferingInvestmentTimedOut === false) {
                    await setUpdateOfferingInvestmentTimedOut(true)
                    await createNotification({
                        variables: {
                            notificationID: notifID,
                            msg: `INVTIMEOUT - Core Team (${data?.fund?.name})`,
                            date: dateTime,
                            link: '/wallet',
                            userID: data?.users?.id,
                        },
                    });
                }
            }).catch(async function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1025",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1025 - Something went wrong, please try again' }));
                setCompletePayoutToWithdraw(undefined)
            })
            // //! SIGNING TX - END
            await setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            await setCompletePayoutToWithdraw(undefined)
            return;
        } catch (error) {
            setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1026",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1026 - Something went wrong, please try again' }));
            setCompletePayoutToWithdraw(undefined)
        }
    }

    const maxAllowedMillis = endDateInMillis - (26 * 60 * 60 * 1000);

    if (twoDaysAfterCurrent > maxAllowedMillis) {
        twoDaysAfterCurrent = maxAllowedMillis;
    }

    if (twoDaysAndTwoHoursAfterCurrent > maxAllowedMillis) {
        twoDaysAndTwoHoursAfterCurrent = maxAllowedMillis;
    }

    const [dataSentActiveContracts, setDataSentActiveContract] = useState(false)

    useEffect(() => {
        if (
            (isNextDepositCT || isNextDepositIN || isNextStepChoice || isNextStepDeposit || (isNextStepDone && unclaimedPayouts > 0)) &&
            (dataSentActiveContracts === false)
        ) {
            setIsContractStillActive(true)
            setDataSentActiveContract(true)
        }
    }, [demeterContractData, dataSentActiveContracts, isNextDepositCT, isNextDepositIN, isNextStepChoice, isNextStepDeposit, isNextStepDone, unclaimedPayouts])

    const [updateSingleOfferPurchase] = useMutation(UPDATE_OFFER_PURCHASE)
    const [newStatusToUpdate, setNewStatusToUpdate] = useState(undefined)

    useEffect(() => {
        if (data?.status?.includes('Pending')) {
            setNewStatusToUpdate(data.status.replace(' - Pending', ''))
        }
    }, [data])

    async function forceStatusConfirm() {
        try {
            await updateSingleOfferPurchase({
                variables: {
                    offerId: data?.id,
                    status: newStatusToUpdate,
                    txId: null,
                }
            })
            await updateTransactionUser({
                variables: {
                    txId: data?.transaction?.id,
                    status: "Confirmed"
                }
            })
            dispatch(showMessage({ message: 'Forced confirm', variant: 'success' }));
            return;
        } catch (error) {
            console.log(error)
        }
    }

    const allAssetsInSC = demeterContractData?.resource?.assets

    function includesFakeUSD(obj) {
        return obj && obj.tokens && Object.values(obj.tokens).some(token => Object.keys(token).includes(data?.fund?.token[0]?.symbol));
    }

    function includesAlli(obj) {
        return obj && obj.tokens && Object.values(obj.tokens).some(token => Object.keys(token).includes('tstALLI'));
    }
    // eslint-disable-next-line
    const isTokenInAssets = includesFakeUSD(allAssetsInSC || {});
    // eslint-disable-next-line
    const isAlliInAssets = includesAlli(allAssetsInSC || {});

    useEffect(() => {
        if (dataReceivedFromChildCreatingEmptyTx === true) {
            startCountdownEmptyTx()
        }
    }, [dataReceivedFromChildCreatingEmptyTx])

    const [modalSendMessage, setModalSendMessage] = useState(false)
    const targetUser = data?.users?.id
    const targetUsername = data?.users?.userName

    const handleOpenNewMessage = () => {
        setModalSendMessage(true)
    }
    const handleClose = () => {
        setModalSendMessage(false)
    }

    return (
        <>
            <TableRow key={data?.data?.id} >
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '14px' }}>
                            {data?.users?.userName}
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                            ({data?.users?.id})
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {hasLegalData &&
                                <Box>
                                    <Tooltip
                                        title={
                                            <div>
                                                {t('INVEST_MODAL.OPT_LEGALNAME')}: {data?.legalFullname}
                                                <br />
                                                <br />
                                                {t('INVEST_MODAL.OPT_LEGALID')}: {data?.legalId}
                                                <br />
                                                <br />
                                                {t('INVEST_MODAL.OPT_LEGALCOUNTRY')}: {data?.legalCountry}
                                                <br />
                                                <br />
                                                {t('INVEST_MODAL.OPT_LEGALFUNDSORIGIN')}: {data?.legalFundsOrigin}
                                            </div>
                                        }>
                                        <BadgeIcon sx={{ color: '#9A1D4C', ml: 1 }} fontSize='small' />
                                    </Tooltip>
                                </Box>
                            }
                            <Box>
                                <Tooltip title={
                                    <div>
                                        {t('INVESTMENT_MODAL.MESSAGE.SEND_MESSAGE')}
                                    </div>
                                }>
                                    <IconButton onClick={handleOpenNewMessage} sx={{ ml: 1, cursor: 'pointer', color: '#9A1D4C' }} >
                                        <MessageIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box>
                        {t(`MONTHS.${Month}`).substring(0, 3)}. {Day}
                    </Box>
                    <Box>
                        {data?.on?.split('T')[1]?.split('.')[0]} UTC
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="column">
                    <Box>
                        {data?.amount}
                    </Box>
                    <Box>
                        {data?.fund?.token[0]?.symbol}
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {
                        (data?.status === "Investment Intention" || data?.status === "Investment Intention - Waiting List") &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#FFB0A0",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${Month}`).substring(0, 3)}. {Day} -  {data?.on?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "SC Created" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#A0FFB0",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "SC Created - ALLI Deposited" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#A0A0FF",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status} {isWithin48Hrs === true ? null :
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'red' }}>{t('INVERSOR_VIEW.OFFERING_CARD.TIMEOUT')}</Typography>
                                    }
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "SC Created - ALLI Deposited - Pending" &&
                        <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#A0A0FF",
                            pl: 0.5, pr: 0.5
                        }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                {data?.status}
                            </Typography>
                            <LinearProgress sx={{ ml: 3, mr: 3, mt: 1, mb: 1 }} size={14} />
                        </Box>
                    }
                    {
                        data?.status === "Investor Deposited" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#FFA0D4",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Investor Deposited - Pending" &&
                        <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#A0A0FF",
                            pl: 0.5, pr: 0.5
                        }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                {data?.status}
                            </Typography>
                            <LinearProgress sx={{ ml: 3, mr: 3, mt: 1, mb: 1 }} size={14} />
                        </Box>
                    }
                    {
                        data?.status === "Payout Executed" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#D4A0FF",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Close Executed" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#D4A0FF",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Payout Executed - Pending" &&
                        <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#A0A0FF",
                            pl: 0.5, pr: 0.5
                        }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                {data?.status}
                            </Typography>
                            <LinearProgress sx={{ ml: 3, mr: 3, mt: 1, mb: 1 }} size={14} />
                        </Box>
                    }
                    {
                        data?.status === "Close Executed - Pending" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#D4A0FF",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Core Withdrawn - Pending" &&
                        <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#A0A0FF",
                            pl: 0.5, pr: 0.5
                        }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                {data?.status}
                            </Typography>
                            <LinearProgress sx={{ ml: 3, mr: 3, mt: 1, mb: 1 }} size={14} />
                        </Box>
                    }
                    {
                        data?.status === "Core Withdrawn" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#A0C8FF",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Both Withdrawn" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#FFEDB0",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Both Withdrawn - Pending" &&
                        <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#FFEDB0",
                            pl: 0.5, pr: 0.5
                        }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                {data?.status}
                            </Typography>
                            <LinearProgress sx={{ ml: 3, mr: 3, mt: 1, mb: 1 }} size={14} />
                        </Box>
                    }
                    {
                        data?.status === "SC Timed Out - Core Withdrawn" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#A0C8FF",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Investment Cancelled - Target Met" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "gray",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'white' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Investment Cancelled - Collecting Ended" &&
                        <>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "gray",
                                pl: 0.5, pr: 0.5
                            }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'white' }}>
                                    {data?.status}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                    {
                        data?.status === "Investment Cancelled" &&
                        <>
                            {data?.smartContract === null ?
                                <Box sx={{
                                    border: '1px solid gray',
                                    borderRadius: '10px',
                                    backgroundColor: "gray",
                                    pl: 0.5, pr: 0.5
                                }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'white' }}>
                                        {t('TX_TICKER.INV_CANCELLED_SC_NOT_CREATED')}
                                    </Typography>
                                </Box>
                                :
                                <Box sx={{
                                    border: '1px solid gray',
                                    borderRadius: '10px',
                                    backgroundColor: "gray",
                                    pl: 0.5, pr: 0.5
                                }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'white' }}>
                                        {t('TX_TICKER.INV_CANCELLED')}
                                    </Typography>
                                </Box>
                            }
                            <Typography sx={{ fontSize: '10px', color: 'red', mt: 0.5 }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                        </>
                    }
                </TableCell>
                <TableCell sx={{ maxWidth: '50px' }}>
                    <CopyToClipboardButton
                        text={data?.id}
                        title={
                            <div>
                                <Typography sx={{ fontWeight: 'bold' }}>Copy Investment ID to clipboard</Typography>
                                <br />
                                <Typography sx={{ fontSize: '12px' }}> {data?.id}</Typography>
                            </div>
                        }
                    />
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box>
                        {
                            data?.smartContract !== null &&
                            <Link
                                href={marloweScanUrl + smartContractIdURI}
                                sx={{ cursor: 'pointer' }}
                                target={{ _blank: true }}
                            >
                                <LaunchIcon />
                            </Link>
                        }
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box>
                        {
                            data?.smartContract !== null &&
                            <Link
                                href={marloweScanUrlTransactions + smartContractIdURI}
                                sx={{ cursor: 'pointer' }}
                                target={{ _blank: true }}
                            >
                                <ReceiptLongIcon />
                            </Link>
                        }
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ pl: 5 }}>
                    {data?.smartContract !== null &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '150px', alignItems: 'center', justifyContent: 'space-around' }}>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        <Typography sx={{ fontWeight: 'bold' }}>{t('SC_DETAIL.SC_ASSETS')}:</Typography>
                                        <Typography>{JSON.stringify(demeterContractData?.resource?.assets, null, 2)}</Typography>
                                    </div>
                                }>
                                <AttachMoneyIcon sx={{ color: demeterContractData?.resource?.assets?.lovelace <= 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={unclaimedPayouts === 2 ?
                                    <>
                                        <Typography sx={{ mr: 1 }}>{t('SC_DETAIL.2UNCLAIMED')}</Typography>
                                        <Typography sx={{ fontSize: '10px', flexWrap: 'wrap' }}>
                                            {t('SC_DETAIL.ROLE')}: {JSON.stringify(demeterContractData?.resource?.unclaimedPayouts[0]?.role || "None", null, 2)}
                                        </Typography>
                                        <Typography sx={{ fontSize: '10px', flexWrap: 'wrap' }}>
                                            {t('SC_DETAIL.OF_TOKEN')}: {JSON.stringify(demeterContractData?.resource?.unclaimedPayouts[0]?.assets?.tokens || "None", null, 2)}
                                        </Typography>
                                        <Typography sx={{ fontSize: '10px', flexWrap: 'wrap' }}>
                                            {t('SC_DETAIL.ROLE')}: {JSON.stringify(demeterContractData?.resource?.unclaimedPayouts[1]?.role || "None", null, 2)}
                                        </Typography>
                                        <Typography sx={{ fontSize: '10px', flexWrap: 'wrap' }}>
                                            {t('SC_DETAIL.OF_TOKEN')}: {JSON.stringify(demeterContractData?.resource?.unclaimedPayouts[1]?.assets?.tokens || "None", null, 2)}
                                        </Typography>
                                    </>
                                    : unclaimedPayouts === 1 ?
                                        <>
                                            <Typography sx={{ mr: 1 }}>{t('SC_DETAIL.1UNCLAIMED')}</Typography>
                                            <Typography sx={{ fontSize: '10px', flexWrap: 'wrap' }}>
                                                {t('SC_DETAIL.ROLE')}: {JSON.stringify(demeterContractData?.resource?.unclaimedPayouts[0]?.role || "None", null, 2)}
                                            </Typography>
                                            <Typography sx={{ fontSize: '10px', flexWrap: 'wrap' }}>
                                                {t('SC_DETAIL.OF_TOKEN')}: {JSON.stringify(demeterContractData?.resource?.unclaimedPayouts[0]?.assets?.tokens || "None", null, 2)}
                                            </Typography>
                                        </>
                                        :
                                        <Typography sx={{ mr: 1 }}>{t('SC_DETAIL.0UNCLAIMED')}</Typography>
                                }>
                                <OutputIcon sx={{ color: unclaimedPayouts <= 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        {
                                            isNextStepDeposit && !isNextStepDone && !isNextStepChoice &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'start', p: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Typography sx={{ flexWrap: 'wrap', mr: 1, fontWeight: 'bold' }}>
                                                        {t('SC_DETAIL.SC_NEXT_STEP')}:
                                                    </Typography>
                                                    {isNextDepositCT && <Typography>{t('SC_DETAIL.CT_CEPOSIT')}</Typography>}
                                                    {isNextDepositIN && <Typography>{t('SC_DETAIL.IN_DEPOSIT')}</Typography>}
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                                    <Typography sx={{ fontSize: '14px', flexWrap: 'wrap' }}>
                                                        {t('SC_DETAIL.ROLE')}: {JSON.stringify(demeterContractData?.resource?.currentContract?.when[0]?.case?.party?.role_token, null, 2)}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '14px', flexWrap: 'wrap' }}>
                                                        {t('SC_DETAIL.DEPOSITS')}: {JSON.stringify(demeterContractData?.resource?.currentContract?.when[0]?.case?.deposits, null, 2)}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '14px', flexWrap: 'wrap' }}>
                                                        {t('SC_DETAIL.OF_TOKEN')}: {JSON.stringify(demeterContractData?.resource?.currentContract?.when[0]?.case?.of_token?.token_name, null, 2)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            isNextStepChoice && !isNextStepDone &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'start', p: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Typography sx={{ flexWrap: 'wrap', mr: 1, fontWeight: 'bold' }}>
                                                        {t('SC_DETAIL.SC_NEXT_STEP_CHOICE')}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
                                                    <Typography sx={{ fontSize: '14px', flexWrap: 'wrap' }}>
                                                        {t('SC_DETAIL.ROLE')}: {JSON.stringify(demeterContractData?.resource?.currentContract?.when[0]?.case?.for_choice?.choice_owner?.role_token, null, 2)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            isNextStepDone && unclaimedPayouts > 0 &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'start', p: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Typography sx={{ flexWrap: 'wrap', mr: 1, fontWeight: 'bold' }}>
                                                        {t('SC_DETAIL.SC_NEXT_STEP_WITH_SC_CLOSED')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            isNextStepDone && unclaimedPayouts === 0 &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'start', p: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Typography sx={{ flexWrap: 'wrap', mr: 1, fontWeight: 'bold' }}>
                                                        {t('SC_DETAIL.SC_CLOSED')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </div>
                                }>
                                <DoubleArrowIcon sx={{ color: isNextStepDone && unclaimedPayouts === 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'center', p: 1 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                <Typography sx={{ fontWeight: 'bold' }}>{t('SC_DETAIL.CT_WALLET')}: </Typography>
                                                <Typography sx={{ ml: 1 }}>...{data?.coreteamAddress?.slice(-10)}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                <Typography sx={{ fontWeight: 'bold' }}>{t('SC_DETAIL.CM_WALLET')}: </Typography>
                                                <Typography sx={{ ml: 1 }}>...{data?.choicemakerAddress?.slice(-10)}</Typography>
                                            </Box>
                                        </Box>
                                    </div>
                                }>
                                <EmojiPeopleIcon sx={{ color: isNextStepDone && unclaimedPayouts === 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        <Typography sx={{ fontWeight: 'bold' }}>Refresh</Typography>
                                    </div>
                                }>
                                <IconButton edge='end' size='small' onClick={getContractById}>
                                    {loading ?
                                        <CircularProgress size={20} /> :
                                        <RefreshIcon fontSize='20px' sx={{ color: '#0000ff' }} />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                </TableCell>
                <TableCell sx={{ minWidth: '150px' }}>
                    {
                        (data?.status === "Investment Intention" || data?.status === "Investment Intention - Waiting List") && data?.smartContract === null &&
                        <>
                            {creatingContract || creatingTx ?
                                <Box sx={{ width: '150px' }}>
                                    <CircularProgress />
                                </Box>
                                :
                                <>
                                    {totalAmountInvestorDeposited >= totalAmountToGather ?
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                                            {t('ADMIN.AMOUNT_GATHER')}: {t('INVERSOR_VIEW.OFFERING_CARD.AMOUNT_REACHED')}
                                        </Typography>
                                        :
                                        isCoreTeamWallet ?
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                <Button
                                                    disabled={finalWalletAddress === undefined}
                                                    size="small" sx={{ width: '150px' }} onClick={() => createMarloweContract()}
                                                    variant="contained">
                                                    {t('ADMIN.CREATE') + " SC"}
                                                </Button>
                                                <Button
                                                    disabled={finalWalletAddress === undefined}
                                                    size="small" sx={{ mt: 0.3, width: '150px' }} onClick={() => handleOpenConfirmation()}
                                                    variant="outlined">
                                                    {t('ADMIN.CANCEL_INV')}
                                                </Button>
                                            </Box>
                                            :
                                            <Box>
                                                <Typography>{t('SC_DETAIL.CT_WALLET_NEEDED')}</Typography>
                                            </Box>
                                    }
                                </>
                            }
                        </>
                    }
                    {
                        data?.smartContract !== null &&
                        errorContract === true &&
                        errorContract !== null &&
                        errorContract !== false &&
                        demeterContractData === undefined &&
                        loading === false &&
                        <>
                            {
                                isCoreTeamWallet ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <Button
                                            disabled={finalWalletAddress === undefined || errorContract === false}
                                            size="small" sx={{ width: '150px' }} onClick={() => createNewMarloweContract()}
                                            variant="contained">
                                            {t('NOTIFICATIONS.RETRY') + ' ' + t('ADMIN.CREATE') + " SC"}
                                        </Button>
                                    </Box> :
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography>{t('SC_DETAIL.CT_WALLET_NEEDED')}</Typography>
                                    </Box>
                            }
                        </>

                    }
                    {data?.smartContract !== null && (
                        <>
                            {
                                demeterContractData !== undefined &&
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        {
                                            data?.smartContract !== null && isNextDepositCT === true &&
                                            <>
                                                {data?.status === "SC Created" ?
                                                    <>
                                                        {creatingTx ?
                                                            <CircularProgress />
                                                            :
                                                            <>
                                                                {isCoreTeamWallet ? <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    <Button
                                                                        disabled={finalWalletAddress === undefined}
                                                                        size="small" sx={{ width: '150px' }} onClick={() => depositTstAlli()}
                                                                        variant="contained">
                                                                        {t('ADMIN.DEPOSIT_ALLI')}
                                                                    </Button>
                                                                </Box> : <Box>
                                                                    <Typography>{t('SC_DETAIL.CT_WALLET_NEEDED')}</Typography>
                                                                </Box>}
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    null
                                                }
                                            </>
                                        }
                                        {data?.status === "SC Created - ALLI Deposited - Pending" ?
                                            <>
                                                {isTimerFinished === false ?
                                                    <Typography>{timer?.minutes}:{formatTime(timer?.seconds)}</Typography>
                                                    :
                                                    <>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography fontSize={'12px'} color={'red'} textAlign={'center'}>{t('ADMIN.TIMEOUT_ERROR')}</Typography>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        IF DEPOSIT DIDN´T GO THROUGH
                                                                        <br />
                                                                        <br />
                                                                        YOU CAN CLICK THIS BUTTON
                                                                        <br />
                                                                        <br />
                                                                        TO RETRY THE CORE TEAM DEPOSIT
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        disabled={finalWalletAddress === undefined}
                                                                        size="small" onClick={() => depositTstAlli()} variant="outlined">
                                                                        Retry Deposit
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL CHANGE STATUS FROM PENDING TO CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        THE CORE TEAM DEPOSIT WILL BE CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE DEPOSIT HAS BEEN MADE
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        sx={{ mt: 0.5 }}
                                                                        size="small" onClick={() => forceStatusConfirm()} variant="contained">
                                                                        Force Confirm
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    </>
                                                }
                                            </>
                                            :
                                            null
                                        }
                                        {data?.status === "Investor Deposited - Pending" ?
                                            <>
                                                {isTimerFinished === false ?
                                                    <Typography>{timer?.minutes}:{formatTime(timer?.seconds)}</Typography>
                                                    :
                                                    <>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography fontSize={'12px'} color={'red'} textAlign={'center'}>{t('ADMIN.TIMEOUT_ERROR')}</Typography>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL RESET STATUS
                                                                        <br />
                                                                        <br />
                                                                        AND FORCE THE INVESTOR TO RETRY DEPOSIT
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE DEPOSIT HAS NOT BEEN MADE
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        size="small" onClick={() => updateOfferingInvertorDepositNotConfirmed()} variant="outlined">
                                                                        {t('ADMIN.INVESTOR_RETRY')}
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL CHANGE STATUS FROM PENDING TO CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        THE INVESTOR WILL SEE THE DEPOSIT CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE DEPOSIT HAS BEEN MADE
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        sx={{ mt: 0.5 }}
                                                                        size="small" onClick={() => forceStatusConfirm()} variant="contained">
                                                                        Force Confirm
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    </>
                                                }
                                            </>
                                            :
                                            null
                                        }
                                        {data?.status === "Payout Executed - Pending" ?
                                            <>
                                                {isTimerFinished === false ?
                                                    <Typography>{timer?.minutes}:{formatTime(timer?.seconds)}</Typography>
                                                    :
                                                    <>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography fontSize={'12px'} color={'red'} textAlign={'center'}>{t('ADMIN.TIMEOUT_ERROR')}</Typography>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL SET THE OFFER PURCHASE STATUS
                                                                        <br />
                                                                        <br />
                                                                        BACK TO - INVESTOR DEPOSITED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE CHOICE (PAYOUT) HAS NOT BEEN EXECUTED
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        size="small" onClick={() => updateOfferingPayoutNotExecuted()} variant="outlined">
                                                                        {t('ADMIN.GO_BACK_STATUS')}
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL CHANGE STATUS FROM PENDING TO CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE CHOICE HAS BEEN EXECUTED
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        sx={{ mt: 0.5 }}
                                                                        size="small" onClick={() => forceStatusConfirm()} variant="contained">
                                                                        Force Confirm
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    </>
                                                }
                                            </>
                                            :
                                            null
                                        }
                                        {data?.status === "Investor Withdrawn - Pending" ?
                                            <>
                                                {isTimerFinished === false ?
                                                    <Typography>{timer?.minutes}:{formatTime(timer?.seconds)}</Typography>
                                                    :
                                                    <>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography fontSize={'12px'} color={'red'} textAlign={'center'}>{t('ADMIN.TIMEOUT_ERROR')}</Typography>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL SET THE OFFER PURCHASE STATUS
                                                                        <br />
                                                                        <br />
                                                                        BACK TO - PAYOUT EXECUTED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE INVESTOR WITHDRAW DID NOT TAKE PLACE
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        size="small" onClick={() => updateOfferWithdrawInvNotSuccess()} variant="outlined">
                                                                        {t('ADMIN.GO_BACK_STATUS')}
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL CHANGE STATUS FROM PENDING TO CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE WITHDRAW WAS SUCCESSFUL
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        sx={{ mt: 0.5 }}
                                                                        size="small" onClick={() => forceStatusConfirm()} variant="contained">
                                                                        Force Confirm
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    </>
                                                }
                                            </>
                                            :
                                            null
                                        }
                                        {data?.status === "Both Withdrawn - Pending" ?
                                            <>
                                                {isTimerFinished === false ?
                                                    <Typography>{timer?.minutes}:{formatTime(timer?.seconds)}</Typography>
                                                    :
                                                    <>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography fontSize={'12px'} color={'red'} textAlign={'center'}>{t('ADMIN.TIMEOUT_ERROR')}</Typography>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL SET THE OFFER PURCHASE STATUS
                                                                        <br />
                                                                        <br />
                                                                        BACK TO - CORE WITHDRAWN
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE INVESTOR WITHDRAW DID NOT TAKE PLACE
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        size="small" onClick={() => updateOfferWithdrawInvNotSuccessAfterCore()} variant="outlined">
                                                                        {t('ADMIN.GO_BACK_STATUS')}
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                placement="left"
                                                                followCursor
                                                                title={
                                                                    <div>
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <br />
                                                                        <br />
                                                                        WARNING
                                                                        <br />
                                                                        <br />
                                                                        THIS WILL CHANGE STATUS FROM PENDING TO CONFIRMED
                                                                        <br />
                                                                        <br />
                                                                        MAKE SURE THE WITHDRAW WAS SUCCESSFUL
                                                                        <br />
                                                                        <br />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                                                        <WarningIcon style={{ color: 'yellow', marginLeft: '5px' }} />
                                                                    </div>
                                                                }>
                                                                <Box>
                                                                    <Button
                                                                        sx={{ mt: 0.5 }}
                                                                        size="small" onClick={() => forceStatusConfirm()} variant="contained">
                                                                        Force Confirm
                                                                    </Button>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    </>
                                                }
                                            </>
                                            :
                                            null
                                        }
                                        {isNextStepChoice && unclaimedPayouts < 1 && !isEndDatePassed ?
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                {creatingTx ?
                                                    <CircularProgress />
                                                    :
                                                    <>
                                                        {isValidatorWallet === false ?
                                                            <Typography>{t('SC_DETAIL.CM_WALLET_NEEDED')}</Typography>
                                                            :
                                                            <>
                                                                {
                                                                    payableSuccessful ?
                                                                        <>
                                                                            {data?.status === "Investor Deposited" &&
                                                                                <>
                                                                                    <Button
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                await setSelectedMakePaySCLink(data?.smartContract?.contractLink);
                                                                                                await setSelectedOffPurchaseId(data?.id);
                                                                                                await setMadeChoicePay(true);
                                                                                            } catch (error) {
                                                                                                dispatch(showMessage({ message: 'Something went wrong, please try again' }));
                                                                                            }
                                                                                        }}
                                                                                        size="small"
                                                                                        disabled={finalWalletAddress === undefined}
                                                                                        sx={{ width: '150px' }}
                                                                                        variant="contained">
                                                                                        {t('ADMIN.CHOOSE_PAY')}
                                                                                    </Button>
                                                                                    <Button
                                                                                        sx={{ width: '150px', mt: 0.3 }}
                                                                                        size="small"
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                await setSelectedMakePaySCLink(data?.smartContract?.contractLink);
                                                                                                await setSelectedOffPurchaseId(data?.id);
                                                                                                await setMadeChoiceClose(true);
                                                                                            } catch (error) {
                                                                                                dispatch(showMessage({ message: 'Something went wrong, please try again' }));
                                                                                            }
                                                                                        }}
                                                                                        disabled={finalWalletAddress === undefined}
                                                                                        variant="outlined">
                                                                                        {t('ADMIN.CHOOSE_DONTPAY')}
                                                                                    </Button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {data?.status === "Investor Deposited" &&
                                                                                <>
                                                                                    <Button
                                                                                        disabled
                                                                                        size="small"
                                                                                        sx={{ width: '150px' }}
                                                                                        variant="contained">
                                                                                        {t('ADMIN.CHOOSE_PAY')}
                                                                                    </Button>
                                                                                    <Button
                                                                                        size="small"
                                                                                        sx={{ width: '150px', mt: 0.3 }}
                                                                                        disabled
                                                                                        variant="outlined">
                                                                                        {t('ADMIN.CHOOSE_DONTPAY')}
                                                                                    </Button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                }

                                                            </>
                                                        }
                                                    </>
                                                }
                                            </Box>
                                            : null
                                        }
                                        {hasCoreTeamPayout === true && unclaimedPayouts === 2
                                            ?
                                            <>
                                                {creatingTx ?
                                                    <CircularProgress />
                                                    :
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        {isCoreTeamWallet === false ?
                                                            <Box>
                                                                <Typography>{t('SC_DETAIL.CT_WALLET_NEEDED')}</Typography>
                                                            </Box>
                                                            :
                                                            <>
                                                                <Typography>
                                                                    {t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}
                                                                </Typography>
                                                                {totalSelected <= 6 ?
                                                                    <Checkbox
                                                                        checked={checked}
                                                                        onChange={handleChange}
                                                                        disabled={finalWalletAddress === undefined}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                    :
                                                                    <Checkbox
                                                                        disabled
                                                                        checked={checked}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                    </Box>
                                                }
                                            </>
                                            : null
                                        }
                                        {hasCoreTeamPayout === true && unclaimedPayouts === 1 ?
                                            <>
                                                {creatingTx ?
                                                    <CircularProgress />
                                                    :
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        {isCoreTeamWallet === false ?
                                                            <Box>
                                                                <Typography>{t('SC_DETAIL.CT_WALLET_NEEDED')}</Typography>
                                                            </Box>
                                                            :
                                                            <>
                                                                <Button
                                                                    disabled={finalWalletAddress === undefined}
                                                                    size="small"
                                                                    onClick={handleWithdrawInvNotDeposited}
                                                                    variant='contained'>
                                                                    <Typography>
                                                                        {t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}
                                                                    </Typography>
                                                                </Button>
                                                            </>
                                                        }
                                                    </Box>
                                                }
                                            </>
                                            : null
                                        }
                                    </Box>
                                </>
                            }
                        </>
                    )}
                    {isEndDatePassed &&
                        isNextStepChoice &&
                        unclaimedPayouts < 1 &&
                        <Button
                            disabled={finalWalletAddress === undefined || creatingTxEmptyTx}
                            size='small'
                            onClick={() => updateContractAfterTimeout()}
                            variant='contained'
                        >
                            {t('ADMIN.CHOOSE_CLOSE')}
                            {creatingTxEmptyTx &&
                                <Typography sx={{ ml: 1, fontSize: '14px' }}>
                                    {countdownEmptyTx} sec.
                                </Typography>
                            }
                        </Button>
                    }
                    {isEndDatePassed &&
                        isNextStepDeposit &&
                        unclaimedPayouts < 1 &&
                        !isNextStepDone &&
                        <Button
                            disabled={finalWalletAddress === undefined || creatingTxEmptyTx}
                            size='small'
                            onClick={() => updateContractAfterTimeout()}
                            variant='contained'
                        >
                            {t('ADMIN.CHOOSE_CLOSE')}
                            {creatingTxEmptyTx &&
                                <Typography sx={{ ml: 1, fontSize: '14px' }}>
                                    {countdownEmptyTx} sec.
                                </Typography>
                            }
                        </Button>
                    }
                    {(data?.status === "SC Created - ALLI Deposited" &&
                        isWithin50Hrs === false) &&
                        <Button
                            disabled={finalWalletAddress === undefined || creatingTxEmptyTx}
                            size='small'
                            onClick={() => updateContractAfterTimeout()}
                            variant='contained'
                        >
                            {t('ADMIN.CHOOSE_CLOSE')}
                            {creatingTxEmptyTx &&
                                <Typography sx={{ ml: 1, fontSize: '14px' }}>
                                    {countdownEmptyTx} sec.
                                </Typography>
                            }
                        </Button>
                    }
                </TableCell>
                <Modal
                    open={openConfirmationCancelInvest}
                    onClose={handleCloseConfirmation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('MY_OFFERINGS.ARE_YOU_SURE')}
                        </Typography>
                        <TextField
                            variant="outlined"
                            size='small'
                            fullWidth
                            value={cancelInvReason}
                            InputProps={{
                                startAdornment: <InputAdornment sx={{ mr: 1 }} position="start">Reason:</InputAdornment>,
                            }}
                            onChange={(e) => setCancelInvReason(e.target.value)}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                            <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                            <Button variant='contained' onClick={() => cancelInvestment()}>{t('ADMIN.CANCEL_INV')}</Button>
                        </Box>
                    </Box>
                </Modal>
                {modalSendMessage && (
                    <DirectMessageModal
                        targetUser={targetUser}
                        targetUsername={targetUsername}
                        open={modalSendMessage}
                        handleClose={handleClose}
                    />
                )}
            </TableRow >
        </>
    )
}

export default SCRow