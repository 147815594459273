import { Box } from '@mui/material'
import React from 'react'
import CompaniesAssessment from '../../components/AdminComponents/CompaniesAdministration/CompaniesAssessment'

const AdminViewCompaniesAssessment = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
                justifySelf: 'center',
                width: { xs: '100%', md: '100%' },

            }}
        >
            <CompaniesAssessment />
        </Box>
    )
}

export default AdminViewCompaniesAssessment