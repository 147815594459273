import { useMutation } from '@apollo/client'
import { Box, Button, Fade, LinearProgress, Link, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SEND_COMMENT_TO_NEWS, SEND_REPLY_TO_COMMENT_TO_NEWS } from '../../Queries/News'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import i18n from "i18next";

const IndividualNewComponent = ({ data }) => {
    const [seeMore, setSeeMore] = useState(false)
    const [seeComments, setSeeComments] = useState(false)
    const selectedLanguage = i18n.language
    const { t } = useTranslation()
    const [commentToSend, setCommentToSend] = useState('')
    const myuuid = uuidv4()
    const dateTime = moment.utc()
    const { user } = useAuth0()
    const userId = user?.email
    const NewsId = data?.id
    const [toggleReply, setToggleReply] = useState(false)
    const [replyToPerson, setReplyToPerson] = useState('')
    const [replyToOriginalMsgId, setReplyToOriginalMsgId] = useState('')
    const [sendComment, { loading: loadingSendComment }] = useMutation(SEND_COMMENT_TO_NEWS)
    const [sendReplyToComment, { loading: loadingSendReply }] = useMutation(SEND_REPLY_TO_COMMENT_TO_NEWS)
    const handleSendComment = async (e) => {
        e.preventDefault()
        await sendComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: commentToSend,
                UserId: userId,
                NewsId: NewsId
            }
        })
        setCommentToSend("")
    }

    const handleSendReplyComment = async (e) => {
        e.preventDefault()
        await sendReplyToComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: commentToSend,
                UserId: userId,
                NewsId: NewsId,
                replyToId: replyToOriginalMsgId
            }
        })
        setCommentToSend("")
        setToggleReply(false)
    }

    const filteredData = data?.messages?.filter(item => {
        const isNullReply = item.replyTo === null
        return isNullReply === true;
    });
    const filteredReplies = data?.messages?.filter(item => {
        const isNullReply = item.replyTo === null
        return isNullReply !== true;
    });

    const Day = data?.on?.split('-')[2].split('T')[0]
    const Month = data?.on?.split('-')[1]
    const Year = data?.on?.split('-')[0]

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box
            sx={{
                display: 'flex',
                p: { md: 2, xs: 1 },
                pb: 4,
                backgroundColor: actualThemeLight ? 'white' : '#1d1d1d',
                mt: { md: 1, xs: 0 },
                mb: { md: 0, xs: 2 },
                flexDirection: { md: 'row', xs: 'column' },
                width: '100%',
                border: '2px solid #9A1D4C',
                borderRadius: '15px',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' }}>
                <img src={data.image} style={{
                    width: '150px',
                    height: '150px',
                    marginRight: 10,
                    marginLeft: 10,
                    objectFit: 'scale-down'
                }} />
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'right',
                        mb: -2,
                        mt: { md: 0, xs: -2 }
                    }}>
                    <Typography
                        sx={{ color: actualThemeLight ? 'black' : 'white' }}
                        fontSize={12}>
                        {t(`MONTHS.${Month}`).substring(0, 3)}. {Day} - {Year}
                    </Typography>
                    <Typography
                        fontSize={12}
                        sx={{ ml: 0.5, mr: 0.5, color: actualThemeLight ? 'black' : 'white' }}
                    >
                        -
                    </Typography>
                    <Typography
                        fontSize={12}
                        sx={{ color: actualThemeLight ? 'black' : 'white' }}
                    >
                        {data.on.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}
                    </Typography>
                </Box>
                {seeMore ?
                    <Fade in={seeMore}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            ml: 2,
                            mt: { xs: 2 },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                            <Typography
                                fontSize={18}
                                sx={{ color: actualThemeLight ? 'black' : 'white' }}
                                fontWeight={'bold'}>
                                {selectedLanguage === 'en' ? data?.titleEn :
                                    selectedLanguage === 'es' ? data?.titleEs :
                                        selectedLanguage === 'pt' && data?.titlePt}
                            </Typography>
                            <Typography
                                fontSize={14}
                                sx={{ color: actualThemeLight ? 'black' : 'white' }}
                            >
                                {selectedLanguage === 'en' ? data?.contentEn :
                                    selectedLanguage === 'es' ? data?.contentEs :
                                        selectedLanguage === 'pt' && data?.contentPt}
                            </Typography>
                        </Box>
                    </Fade>
                    :
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 2,
                        height: { md: '150px' },
                        maxHeight: { md: '150px', xs: '400px' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        <Typography
                            fontSize={18}
                            fontWeight={'bold'}
                            sx={{ mt: 2, color: actualThemeLight ? 'black' : 'white' }}
                        >
                            {selectedLanguage === 'en' ? data?.titleEn :
                                selectedLanguage === 'es' ? data?.titleEs :
                                    selectedLanguage === 'pt' && data?.titlePt}
                        </Typography>
                        <Link
                            href={data?.url?.link}
                            target={{ _blank: true }}
                            color='inherit'
                            variant='h6'
                            sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', color: actualThemeLight ? null : '#e6a3bd',
                                textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                            }}>
                            <Typography sx={{ fontSize: '12px' }}>{data?.url?.link}</Typography>

                        </Link>
                        <Typography
                            fontSize={14}
                            overflow={{ md: 'hidden', xs: 'auto' }}
                            sx={{ mt: { md: 0, xs: 2 }, color: actualThemeLight ? 'black' : 'white' }}
                        >
                            {selectedLanguage === 'en' ? data?.contentEn :
                                selectedLanguage === 'es' ? data?.contentEs :
                                    selectedLanguage === 'pt' && data?.contentPt}
                        </Typography>
                    </Box>
                }
                {seeComments &&
                    <>
                        <Fade in={seeComments}>
                            <Box sx={{ pt: 2, pl: 2 }}>
                                <Typography
                                    sx={{
                                        borderBottom: actualThemeLight ? '1px solid black' : '1px solid white',
                                        fontWeight: 'bold',
                                        color: actualThemeLight ? 'black' : 'white'
                                    }}>
                                    {t('NEWS_SECTION.COMMENTS')}
                                </Typography>
                                <Box sx={{
                                    width: '100%',
                                    height: '40vh',
                                    borderBottom: actualThemeLight ? '1px solid black' : '1px solid white',
                                    overflowY: 'scroll'
                                }}>
                                    {filteredData?.length === 0 &&
                                        <>
                                            <Typography
                                                sx={{ color: actualThemeLight ? 'black' : 'white' }}
                                            >{t('NEWS_SECTION.NO_COMMENTS')}</Typography>
                                        </>
                                    }
                                    {filteredData.map((message) => {
                                        return (
                                            <Box
                                                key={message.id}
                                                sx={{
                                                    mt: 1, mb: 1, ml: 1, mr: 2,
                                                    border: '1px solid pink', borderRadius: '10px'
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row', alignItems: 'center',
                                                        ml: 1
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                                                            fontSize: '16px'
                                                        }}>
                                                        {message.sentBy.userName}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                                                        {message.on.split('T')[0]}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ ml: 1, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                                                        {message.on.split('T')[1].split('.')[0]}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', ml: 1, mt: -1, color: actualThemeLight ? 'black' : 'white' }}>
                                                    <Typography>{message.text}</Typography>
                                                    <Box
                                                        onClick={() => {
                                                            setToggleReply(!toggleReply)
                                                            setReplyToPerson(message.sentBy.userName)
                                                            setReplyToOriginalMsgId(message.id)
                                                        }}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center', ml: 1,
                                                            cursor: 'pointer',
                                                            p: 0.2
                                                        }}>
                                                        <ReplyIcon sx={{ color: actualThemeLight ? null : '#e6a3bd' }} fontSize='small' />
                                                        <Typography sx={{ fontSize: '10px' }}>{t('ADMIN.REPLY')}</Typography>
                                                    </Box>
                                                </Box>
                                                {filteredReplies.reverse().map((reply) => {
                                                    return (
                                                        <Box key={reply.id}>
                                                            {reply.replyTo == message.id ?
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row', alignItems: 'center',
                                                                            ml: 3
                                                                        }}>
                                                                        <SubdirectoryArrowRightIcon sx={{ color: actualThemeLight ? null : '#e6a3bd' }} />
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 'bold',
                                                                                color: actualThemeLight ? '#DB417B' : '#e6a3bd',
                                                                                fontSize: '14px'
                                                                            }}>
                                                                            {reply.sentBy.userName}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ ml: 1, mt: 0.4, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                                                                            {reply.on.split('T')[0]}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ ml: 1, mt: 0.4, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                                                                            {reply.on.split('T')[1].split('.')[0]}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row', alignItems: 'center',
                                                                            ml: 6
                                                                        }}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '12px',
                                                                                mt: -0.8,
                                                                                color: actualThemeLight ? 'black' : 'white'
                                                                            }}>
                                                                            {reply.text}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : null
                                                            }
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        )

                                    })}
                                </Box>

                            </Box>
                        </Fade>
                        {/* LEAVING A COMMENT - NOT REPLY */}
                        {toggleReply === false &&
                            <> {loadingSendComment ?
                                <Box sx={{ width: '100%', pl: 2, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                    <LinearProgress />
                                </Box>
                                :
                                <Box sx={{ pl: 2, mt: 1 }}>
                                    <Typography sx={{ fontSize: 12, color: actualThemeLight ? 'black' : 'white' }}>
                                        {t('FUND_VIEW.LEAVE_A_COMMENT')}
                                    </Typography>

                                    <form onSubmit={handleSendComment}>
                                        <TextField
                                            size='small'
                                            value={commentToSend}
                                            onChange={(event) => { setCommentToSend(event.target.value) }}
                                            sx={{ width: { md: '90%', xs: '98%' } }}
                                            variant="outlined"
                                        />
                                        <Button
                                            type='submit'
                                            sx={{ ml: { md: 2, xs: '35%' }, mt: { md: 0, xs: 1 } }}
                                            variant='contained'>
                                            {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                        </Button>
                                    </form>
                                </Box>
                            }
                            </>
                        }

                        {/* LEAVING A  REPLY */}
                        {toggleReply === true &&
                            <> {loadingSendReply ?
                                <Box sx={{ width: '100%', pl: 2, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                    <LinearProgress />
                                </Box>
                                :
                                <Box sx={{ pl: 2, mt: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white' }}>
                                            {t('ADMIN.REPLY_TO')} {replyToPerson}
                                        </Typography>
                                        <CloseIcon onClick={() => { setToggleReply(false) }} sx={{ ml: 1, cursor: 'pointer', color: actualThemeLight ? null : '#e6a3bd' }} fontSize='small' />
                                    </Box>
                                    <form onSubmit={handleSendReplyComment}>
                                        <TextField
                                            size='small'
                                            value={commentToSend}
                                            onChange={(event) => { setCommentToSend(event.target.value) }}
                                            sx={{ width: '90%' }}
                                            variant="outlined"
                                        />
                                        <Button
                                            type='submit'
                                            sx={{ ml: 2 }}
                                            variant='contained'>
                                            {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                        </Button>
                                    </form>
                                </Box>
                            }
                            </>
                        }
                    </>
                }

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                    justifyContent: 'right',
                    width: '100%',

                    mt: 2
                }}>
                    <Box
                        onClick={() => {
                            setSeeMore(!seeMore)
                            setSeeComments(!seeComments)
                        }}
                        sx={{
                            cursor: 'pointer',
                            mr: 1
                        }}>
                        {
                            seeMore ?
                                <Typography
                                    color={actualThemeLight ? '#9A1D4C' : '#e6a3bd'}
                                    fontSize={12}
                                    fontWeight={'bold'}
                                >
                                    {t("ADMIN.SEE_LESS")}
                                </Typography>
                                :
                                <Typography
                                    color={actualThemeLight ? '#9A1D4C' : '#e6a3bd'}
                                    fontSize={12}
                                    fontWeight={'bold'}
                                >
                                    {t("ADMIN.SEE_MORE")}
                                </Typography>
                        }
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default IndividualNewComponent