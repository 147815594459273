import { AUTH_LOGIN, LOGOUT } from '../actions/authActions'

const initialState = {
  token: null,
  userInfo: [],
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN.success: {
      return {
        token: action.payload,
      }
    }
    case AUTH_LOGIN.failure: {
      return initialState
    }
    case LOGOUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default auth
