import React, { useEffect, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, IconButton, Modal, TextField, Typography, LinearProgress, useTheme } from '@mui/material';
import { CREATE_USER } from '../../../Queries/Admin';
import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../../store/actions/snackBarActions';


const UserAdministrationAddUserModal = ({ data }) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const handleChangeUsername = (event) => {
        setUsername(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const style = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: actualThemeLight ? 'rgba(229, 229, 229, 1)' : '#1d1d1d',
        borderRadius: '10px',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
        width: '450px',
        maxHeight: '100%',
    };

    const [emailRegistered, setEmailRegistered] = useState(false)
    const todayDate = new Date().toISOString().split('T')[0]
    const dispatch = useDispatch()

    useEffect(() => {
        const userWithEmail = data?.users?.find(user => user.email === email);
        if (userWithEmail) {
            setEmailRegistered(true)
        } else {
            setEmailRegistered(false)
        }
    }, [email, data?.users]);

    const [addUser] = useMutation(CREATE_USER, {
        variables: {
            active: true,
            email: email,
            id: email,
            userName: username,
            memberSince: todayDate,
        },
    });

    const handleCreateUser = async () => {
        try {
            await setCreating(true)
            await addUser();
            await dispatch(showMessage({ message: 'User created!', variant: 'success' }))
            await handleClose();
            await setCreating(false)
        } catch {
            dispatch(showMessage({ message: 'Something went wrong - User already exists' }));
            await setCreating(false)
        }
    };

    const [creating, setCreating] = useState(false)


    return (
        <>
            <AddCircleIcon
                sx={{
                    cursor: 'pointer',
                    width: '50px',
                    height: '50px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                }}
                onClick={handleOpen}
            />
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'right' }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('ADMIN.USERNAME')}
                    </Typography>
                    <TextField placeholder={t('ADMIN.USERNAME')} required onChange={handleChangeUsername} sx={{ m: 2, width: '90%' }}></TextField>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Email
                    </Typography>
                    <TextField placeholder="Email" required onChange={handleChangeEmail} sx={{ m: 2, width: '90%' }}></TextField>
                    {emailRegistered ?
                        <Typography sx={{ color: 'red' }}>
                            {t('ADMIN.EMAIL_REGISTERED')}
                        </Typography>
                        : <Button onClick={handleCreateUser} sx={{ width: '150px', mt: 2 }} variant="contained">
                            {t('ADMIN.CREATE')}
                        </Button>}
                    {creating && <Box sx={{ width: '100%', mt: 2 }}>
                        <LinearProgress />
                    </Box>}
                </Box>
            </Modal>
        </>
    )
}

export default UserAdministrationAddUserModal