import { gql } from '@apollo/client'

export const GET_EVENTS = gql`
  query {
    events(options: { sort: { startDate: DESC } }) {
      id
      titleEn
      titleEs
      titlePt
      detailEn
      detailEs
      detailPt
      fund {
        id
        name
      }
      startDate
      endDate
    }
  }
`
