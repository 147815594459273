import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react'
import { GET_NEWS } from '../../Queries/News';
import { useEffect } from 'react';
import { Box, CircularProgress, Grid, InputAdornment, TextField, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import IndividualNewComponent from './IndividualNewComponent';
import { useDispatch } from "react-redux"
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'

const NewsComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [search, setSearch] = React.useState('');

    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };

    const [getAllNews, { data: newsData, loading: dataLoading }] = useLazyQuery(GET_NEWS, {
        variables: { search: search },
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllNews()
    }, [getAllNews]);

    const [value, setValue] = useState(1); // el valor inicial de la pestaña seleccionada es 0

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNewsClick = () => {
        dispatch(push('/dao/news'))
    }

    const handleCalendarClick = () => {
        dispatch(push('/dao/calendar'))
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }


    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="pestañas">
                    <Tab label={t('ADMIN.CALENDAR')} onClick={handleCalendarClick} />
                    <Tab label={t('ADMIN.DAO_NEWS')} onClick={handleNewsClick} />
                </Tabs>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                pl: 6, pr: 6,
                pb: 4
            }}>
                <Grid sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row'
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: "center",
                            justifyContent: "left",
                            width: '100%',
                            mt: 2,
                            mb: 2,
                        }}>
                        <TextField
                            id="filled-search"
                            size='small'
                            label={t('ADMIN.ONLY_SEARCH')}
                            type="search"
                            variant="outlined"
                            onChange={handleTextChange}
                            sx={{ width: { md: '45%', xs: '100%' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid >
                {dataLoading ?
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 8
                        }}>
                        <CircularProgress />
                    </Box>
                    :
                    <Grid sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        ml: 6,
                        mr: 6
                    }}>
                        {newsData?.news?.map((news) => (
                            <IndividualNewComponent key={news.id} data={news} />
                        ))}
                    </Grid>}
            </Box >
        </>
    )
}

export default NewsComponent