import { green, red } from '@mui/material/colors/'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { AlertCircle, AlertTriangle, CheckCircle, Info, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
  Link,
  Box
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { hideMessage } from '../../store/actions/snackBarActions'
import {
  selectSnackbarOptions,
  selectSnackbarState,
} from '../../store/selectors/snackBarSelectors'

const useStyles = makeStyles((theme) => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  warning: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  message: {
    marginLeft: theme.spacing(),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const success = {
  backgroundColor: green[600],
  color: '#FFFFFF',
}

const fail = {
  backgroundColor: red[600],
  color: '#FFFFFF',
}

const variantIcon = {
  success: <CheckCircle />,
  warning: <AlertTriangle />,
  error: <AlertCircle />,
  info: <Info />,
}

function SnackbarMessage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const state = useSelector(selectSnackbarState)
  const options = useSelector(selectSnackbarOptions)


  const classes = useStyles()
  const handleOnClose = useCallback(() => {
    dispatch(hideMessage())
  }, [dispatch])

  const messageComponent = useMemo(() => {
    return (
      <div className={classes.container}>
        {variantIcon[options.variant]}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes?.message}>
            {t(`ERRORS.${options?.message}`, {
              defaultValue: options?.message,
            })}
          </Typography>
          {options?.variant === 'success' ? null :
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ml: 1 }}>
              <Typography>If problem persists please contact us in our </Typography>
              <Link
                href='https://t.co/hdEX1oCQRb'
                target={{ _blank: true }}
                color='inherit'
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 0.5 }}>
                <Typography> Discord</Typography>
              </Link>
            </Box>
          }
        </Box>
      </div>
    )
  }, [classes, options])

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={handleOnClose}
      classes={{
        root: classes.root,
      }}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div',
        },
      }}
    >
      <SnackbarContent
        sx={options?.variant === 'success' ? success : fail}
        message={messageComponent}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleOnClose}
          >
            <X />
          </IconButton>,
        ]}
      >

      </SnackbarContent>
    </Snackbar>
  )
}

export default React.memo(SnackbarMessage)
