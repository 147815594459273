import {
    Autocomplete, Box, Button, CircularProgress, FormControlLabel,
    Grid, InputAdornment, InputLabel, LinearProgress, Radio, RadioGroup, TextField,
    Tooltip, Typography
} from '@mui/material';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useParams } from "react-router"
import { useLazyQuery, useMutation } from '@apollo/client';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { GET_FUNDS_BY_ID } from "../../../Queries/Funds"
import { useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GET_FUND_PURPOSE } from '../../../Queries/Funds';
import { UPDATE_PURPOSE, UPDATE_EVENT } from '../../../Queries/Admin'
import { imgUploadHandler } from '../../../utils/fileUpload'
import i18n from "i18next";
import { showMessage } from '../../../store/actions/snackBarActions'
import { UPDATE_FUND } from '../../../Queries/Admin';
import { NumericFormat } from 'react-number-format';

const FundEdit = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { fundId } = useParams()
    const selectedLanguage = i18n.language
    const [updateFund] = useMutation(UPDATE_FUND)
    const [updatePurpose] = useMutation(UPDATE_PURPOSE)
    const [updateEvent] = useMutation(UPDATE_EVENT)
    const [selectedDescriptionRadio, setSelectedDescriptionRadio] = useState('EN');

    const handleChangeSelectedDescription = (event) => {
        setSelectedDescriptionRadio(event.target.value);
    };

    const vestingOptions = [
        { id: '1', value: 'vesting 1' },
        { id: '2', value: 'vesting 2' },
        { id: '3', value: 'vesting 3' },
        { id: '4', value: 'vesting 4' },
    ]

    const [fetchFunds, { data: fundsById, loading: fundLoading, refetch }] = useLazyQuery(
        GET_FUNDS_BY_ID,
        {
            variables: { FundId: fundId },
            pollInterval: 1500
        }
    )

    const thisFundId = fundsById?.funds[0]?.id

    const [getFundPurpose, { data: fundPurpose }] = useLazyQuery(GET_FUND_PURPOSE)
    useEffect(() => {

        getFundPurpose()
    }, [getFundPurpose]);

    useEffect(() => {
        fetchFunds()
    }, [fetchFunds])

    function convertToNumberWithCommas(numWithCommas) {
        if (typeof numWithCommas === 'string') {
            return parseFloat(numWithCommas.replace(/,/g, ''));
        } else {
            return NaN;
        }
    }

    const [fundName, setFundName] = useState('')
    const [fundAmountToReach, setFundAmountToReach] = useState(undefined)
    const pureFundAmountToReach = convertToNumberWithCommas(fundAmountToReach)

    const [minimumSuccessThresshold, setMinimumSuccessThresshold] = useState('')
    const [minimumAmountToInvest, setMinimumAmountToInvest] = useState(undefined)
    const pureMinimumAmountToInvest = convertToNumberWithCommas(minimumAmountToInvest)

    const [maximumAmountToInvest, setMaximumAmountToInvest] = useState(undefined)
    const pureMaximumAmountToInvest = convertToNumberWithCommas(maximumAmountToInvest)

    const [minimumCompanyAsking, setMinimumCompanyAsking] = useState(undefined)
    const pureMinimumCompanyAsking = convertToNumberWithCommas(minimumCompanyAsking)

    const [maximumCompanyAsking, setMaximumCompanyAsking] = useState(undefined)
    const pureMaximumCompanyAsking = convertToNumberWithCommas(maximumCompanyAsking)

    const [minAmountKYC, setMinAmountKYC] = useState(undefined)
    const pureMinAmountKYC = convertToNumberWithCommas(minAmountKYC)

    const [errorMinMaxToInvest, setErrorMinMaxToInvest] = useState(false)
    const [errorMinMaxCompanyAsking, setErrorMinMaxCompanyAsking] = useState(false)
    const [vesting, setVesting] = useState('')
    const currentPurpose = fundsById?.funds[0]?.purpose?.id
    const [purposeId, setPurposeId] = useState('')
    const [purposeName, setPurposeName] = useState('')
    const [expectedTimeROI, setExpectedTimeROI] = useState('')
    const [roiMultiplier, setRoiMultiplier] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [descriptionEs, setDescriptionEs] = useState('')
    const [descriptionPt, setDescriptionPt] = useState('')
    const [fundImgURLState, setFundImgURLState] = useState('')
    const [fundStatus, setFundStatus] = useState('')
    const uploadFundImgHandler = async (file) => {
        const fundImgURL = await imgUploadHandler(file)
        setFundImgURLState(fundImgURL)
    }

    useEffect(() => {
        setFundName(fundsById?.funds[0]?.name)
        setFundAmountToReach(String(fundsById?.funds[0]?.amountToReach))
        setMinimumAmountToInvest(String(fundsById?.funds[0]?.minInvestment))
        setMinimumCompanyAsking(String(fundsById?.funds[0]?.minCompanyAsk))
        setVesting(fundsById?.funds[0]?.vestingPlan)
        setPurposeId(fundsById?.funds[0]?.purpose?.id)
        setPurposeName(fundsById?.funds[0]?.purpose?.titleEn)
        setFundImgURLState(fundsById?.funds[0]?.image)
        setMinimumSuccessThresshold(fundsById?.funds[0]?.successThreshold)
        setMaximumAmountToInvest(String(fundsById?.funds[0]?.maxInvestment))
        setFundStatus(fundsById?.funds[0]?.status)
        setMaximumCompanyAsking(String(fundsById?.funds[0]?.maxCompanyAsk))
        setExpectedTimeROI(fundsById?.funds[0]?.roiTime.split(' ')[0])
        setRoiMultiplier(fundsById?.funds[0]?.roiMultiplier)
        setDescriptionEn(fundsById?.funds[0]?.descriptionEn)
        setDescriptionEs(fundsById?.funds[0]?.descriptionEs)
        setDescriptionPt(fundsById?.funds[0]?.descriptionPt)
        setMinAmountKYC(fundsById?.funds[0]?.maxOptionalLegalInfo)
    }, [fundsById])

    useEffect(() => {
        if (Number(fundAmountToReach) < 0) {
            setFundAmountToReach(0)
        }
        if (Number(minimumAmountToInvest) < 0) {
            setMinimumAmountToInvest(0)
        }
        if (Number(maximumAmountToInvest) < 0) {
            setMaximumAmountToInvest(0)
        }
        if (Number(minimumCompanyAsking) < 0) {
            setMinimumCompanyAsking(0)
        }
        if (Number(maximumCompanyAsking) < 0) {
            setMaximumCompanyAsking(0)
        }
        if (Number(minimumSuccessThresshold) < 0) {
            setMinimumSuccessThresshold(0)
        }
        if (Number(minimumSuccessThresshold) > 100) {
            setMinimumSuccessThresshold(100)
        }
        if (Number(expectedTimeROI) <= 0) {
            setExpectedTimeROI(1)
        }
        if (Number(pureMinimumAmountToInvest) >= Number(pureMaximumAmountToInvest)) {
            setErrorMinMaxToInvest(true)
        }
        if (Number(pureMinimumAmountToInvest) < Number(pureMaximumAmountToInvest)) {
            setErrorMinMaxToInvest(false)
        }
        if (Number(pureMaximumAmountToInvest) <= Number(pureMinimumAmountToInvest)) {
            setErrorMinMaxToInvest(true)
        }
        if (Number(pureMaximumAmountToInvest) > Number(pureMinimumAmountToInvest)) {
            setErrorMinMaxToInvest(false)
        }
        if (Number(pureMinimumCompanyAsking) >= Number(pureMaximumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(true)
        }
        if (Number(pureMinimumCompanyAsking) < Number(pureMaximumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(false)
        }
        if (Number(pureMaximumCompanyAsking) <= Number(pureMinimumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(true)
        }
        if (Number(pureMaximumCompanyAsking) > Number(pureMinimumCompanyAsking)) {
            setErrorMinMaxCompanyAsking(false)
        }
    }, [
        fundAmountToReach,
        minimumAmountToInvest,
        maximumAmountToInvest,
        minimumCompanyAsking,
        maximumCompanyAsking,
        minimumSuccessThresshold,
        expectedTimeROI,
        errorMinMaxToInvest,
        errorMinMaxCompanyAsking
    ])

    function addDayToDate(date) {
        if (date) {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        }
    }

    const todayDate = new Date().toISOString().split('T')[0]

    const [currentCollectingID, setCurrentCollectingId] = useState(undefined)
    const [collectingStartDate, setCollectingStartDate] = useState(undefined)
    const [collectingEndDate, setCollectingEndDate] = useState(undefined)
    const nextDayToCollectingStart = addDayToDate(collectingStartDate)?.toISOString('en-EN').split('T')[0]
    const nextDayToCollectingEnd = addDayToDate(collectingEndDate)?.toISOString('en-EN').split('T')[0]

    const [currentPublishedID, setCurrentPublishedId] = useState(undefined)

    const [currentOpenID, setCurrentOpenId] = useState(undefined)
    const [openStartDate, setOpenStartDate] = useState(undefined)
    const [openEndDate, setOpenEndDate] = useState(undefined)
    const nextDayToOpenStart = addDayToDate(openStartDate)?.toISOString('en-EN').split('T')[0]
    const nextDayToOpenEnd = addDayToDate(openEndDate)?.toISOString('en-EN').split('T')[0]

    const [currentAssessmentID, setCurrentAssessmentId] = useState(undefined)

    const [currentGovernanceID, setCurrentGovernanceId] = useState(undefined)
    const [governanceStartDate, setGovernanceStartDate] = useState(undefined)
    const [governanceEndDate, setGovernanceEndDate] = useState(undefined)
    const nextDayToGovernanceStart = addDayToDate(governanceStartDate)?.toISOString('en-EN').split('T')[0]
    const nextDayToGovernanceEnd = addDayToDate(governanceEndDate)?.toISOString('en-EN').split('T')[0]

    const [currentVestingID, setCurrentVestingId] = useState(undefined)
    const [vestingStartDate, setVestingStartDate] = useState(undefined)
    const [vestingEndDate, setVestingEndDate] = useState(undefined)
    const nextDayToVestingStart = addDayToDate(vestingStartDate)?.toISOString('en-EN').split('T')[0]

    const [currentExecutionID, setCurrentExecutionId] = useState(undefined)
    const [currentROIID, setCurrentROIId] = useState(undefined)

    useEffect(() => {
        fundsById?.funds[0]?.event?.map((e) => {
            if (e?.titleEn === 'Collecting') {
                setCurrentCollectingId(e?.id)
                setCollectingStartDate(e?.startDate?.split('T')[0])
                setCollectingEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Published') {
                setCurrentPublishedId(e?.id)
            }
            if (e?.titleEn === 'Open') {
                setCurrentOpenId(e?.id)
                setOpenStartDate(e?.startDate?.split('T')[0])
                setOpenEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Assessment') {
                setCurrentAssessmentId(e?.id)
            }
            if (e?.titleEn === 'Governance') {
                setCurrentGovernanceId(e?.id)
                setGovernanceStartDate(e?.startDate?.split('T')[0])
                setGovernanceEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Vesting') {
                setCurrentVestingId(e?.id)
                setVestingStartDate(e?.startDate?.split('T')[0])
                setVestingEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Execution') {
                setCurrentExecutionId(e?.id)
            }
            if (e?.titleEn === 'ROI') {
                setCurrentROIId(e?.id)
            }
        })
    }, [fundsById])

    const [sendingMutation, setSendingMutation] = useState(false)
    const handleSave = async () => {
        setSendingMutation(true)
        try {
            await updateFund({
                variables: {
                    fundId: thisFundId,
                    updateData: {
                        name: fundName,
                        amountToReach: Number(pureFundAmountToReach),
                        minInvestment: Number(pureMinimumAmountToInvest),
                        maxInvestment: Number(pureMaximumAmountToInvest),
                        minCompanyAsk: Number(pureMinimumCompanyAsking),
                        maxCompanyAsk: Number(pureMaximumCompanyAsking),
                        maxOptionalLegalInfo: Number(pureMinAmountKYC),
                        successThreshold: Number(minimumSuccessThresshold),
                        roiTime: expectedTimeROI + ' years',
                        roiMultiplier: roiMultiplier,
                        vestingPlan: vesting,
                        descriptionEn: descriptionEn,
                        descriptionEs: descriptionEs,
                        descriptionPt: descriptionPt,
                        status: fundStatus,
                        image: fundImgURLState
                    }
                }
            })
            await updatePurpose({
                variables: {
                    oldPurposeId: currentPurpose,
                    fundId: thisFundId,
                    newPurposeId: purposeId
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentCollectingID,
                    newData: {
                        startDate: collectingStartDate,
                        endDate: collectingEndDate,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentPublishedID,
                    newData: {
                        startDate: collectingEndDate,
                        endDate: openStartDate,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentOpenID,
                    newData: {
                        startDate: openStartDate,
                        endDate: openEndDate,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentAssessmentID,
                    newData: {
                        startDate: openEndDate,
                        endDate: governanceStartDate,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentGovernanceID,
                    newData: {
                        startDate: governanceStartDate,
                        endDate: governanceEndDate,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentVestingID,
                    newData: {
                        startDate: vestingStartDate,
                        endDate: vestingEndDate,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentExecutionID,
                    newData: {
                        startDate: datePlusROI,
                        endDate: datePlusROI,
                    }
                }
            })
            await updateEvent({
                variables: {
                    eventId: currentROIID,
                    newData: {
                        startDate: datePlusROI,
                        endDate: datePlusROI,
                    }
                }
            })
            await dispatch(showMessage({ message: 'Saved', variant: 'success' }))
            setSendingMutation(false)
            await dispatch(push('/admin-fund-management'))
        } catch (error) {
            await dispatch(showMessage({ message: 'Changes Saved, some errors', variant: 'error' }))
        }
        await refetch()
    }

    const [datePlusROI, setDatePlusROI] = useState(null);

    const calculateNewDate = () => {
        if (collectingStartDate && expectedTimeROI) {
            const startDate = new Date(collectingStartDate);
            const yearsToAdd = parseInt(expectedTimeROI);

            const newDate = new Date(startDate);
            newDate.setFullYear(startDate.getFullYear() + yearsToAdd);

            setDatePlusROI(newDate.toISOString().split('T')[0]);
        }
    };

    useEffect(() => {
        calculateNewDate();
    }, [collectingStartDate, expectedTimeROI]);

    if (fundLoading) {
        return (
            <>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '30vh'
                }}>
                    <CircularProgress />
                </Box>
            </>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    mb: 6
                }}>
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            m: 4,
                            alignItems: "center", justifyContent: "left"
                        }}>
                        <Box
                            onClick={() => {
                                dispatch(push('/admin-fund-management'))
                            }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                p: 0.5,
                            }}>
                            <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                            <Typography sx={{ ml: 1, color: '#9A1D4C' }}>
                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 4 }}>
                            <Typography
                                fontSize={20}
                                fontWeight={'bold'}>
                                {t('MY_OFFERINGS.MODIFY')} {t('ADMIN.CREATE_FUND').split(' ')[1]}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {/* COLUMNA IZQUIERZA */}
                        <Grid container xs={12} md={6} sx={{ pl: 8, pr: 8 }}>
                            {/* Name Field - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}>
                                        {t('ADMIN.FUND_NAME')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={fundName}
                                        onChange={(e) => setFundName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Name Field - End */}
                            {/* Amount to Reach - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.AMOUNT_GATHER')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <NumericFormat
                                        value={fundAmountToReach}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setFundAmountToReach(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(fundAmountToReach) && fundAmountToReach === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Amount to Reach - End */}
                            {/* Minimum Amount to Invest - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MIN_INVEST')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <NumericFormat
                                        value={minimumAmountToInvest}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMinimumAmountToInvest(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(minimumAmountToInvest) && minimumAmountToInvest === ''}
                                    />
                                    {errorMinMaxToInvest
                                        &&
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: '27%' }}>
                                            <Tooltip title={'MIN-MAX ERROR'}>
                                                <WarningIcon fontSize='large' sx={{ color: 'red' }} />
                                            </Tooltip>
                                        </Box>

                                    }
                                </Grid>

                            </Grid>
                            {/* Minimum Amount to Invest - End */}
                            {/* MAXIMUM OPTIONAL INVESTMET - LEGAL INFO START */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_OPT_LEGAL')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <NumericFormat
                                        value={minAmountKYC}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMinAmountKYC(e.target.value)}
                                        customInput={TextField}
                                    />
                                </Grid>
                            </Grid>
                            {/* MAXIMUM OPTIONAL INVESTMET - LEGAL INFO END */}
                            {/* Minimum Companies Asking - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MIN_REQ_COMPANIES')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <NumericFormat
                                        value={minimumCompanyAsking}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMinimumCompanyAsking(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(minimumCompanyAsking) && minimumCompanyAsking === ''}
                                    />
                                    {errorMinMaxCompanyAsking
                                        &&
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', ml: '27%' }}>
                                            <Tooltip title={'MIN-MAX ERROR'}>
                                                <WarningIcon fontSize='large' sx={{ color: 'red' }} />
                                            </Tooltip>
                                        </Box>

                                    }
                                </Grid>

                            </Grid>
                            {/* Minimum Companies Asking - End */}
                            {/* Vesting - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        Vesting
                                    </InputLabel>
                                </Grid>
                                {vesting !== '' &&
                                    <Grid item xs={12} md={7}>
                                        <Autocomplete
                                            fullWidth
                                            limitTags={6}
                                            onChange={(e, option) =>
                                                setVesting(option.value || '')
                                            }
                                            id="tags-outlined"
                                            options={vestingOptions}
                                            getOptionLabel={(e) => e.value}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    id="employees-input"
                                                    sx={{ width: '100%' }}
                                                    label={vesting}
                                                    {...params}
                                                    name={t('CREATE_COMPANY.EMPLOYEES_AMOUNT')}
                                                    variant="outlined"
                                                    aria-describedby="employees-input-text"
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {/* Vesting - End */}
                            {/* Expected Return on Investment - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.ROI_TIME')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        fullWidth
                                        value={expectedTimeROI}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{t('ADMIN.YEARS')}</InputAdornment>,
                                        }}
                                        onChange={(e) => setExpectedTimeROI(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Expected Return on Investment - End */}
                            {/* Collecting Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -1.8, fontWeight: 'bold' }}>1</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.COLLECTING_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: todayDate
                                        }}
                                        fullWidth
                                        value={collectingStartDate}
                                        onChange={(e) => setCollectingStartDate(e.target.value)}
                                        error={collectingStartDate === undefined}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingStart || todayDate
                                        }}
                                        fullWidth
                                        value={collectingEndDate}
                                        onChange={(e) => setCollectingEndDate(e.target.value)}
                                        error={collectingEndDate === undefined || collectingEndDate < collectingStartDate}
                                    />
                                </Grid>
                            </Grid>
                            {/* Collecting Period - End */}
                            {/* Open Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>3</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.OPEN_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingEnd || todayDate
                                        }}
                                        fullWidth
                                        value={openStartDate}
                                        onChange={(e) => setOpenStartDate(e.target.value)}
                                        error={openStartDate === undefined || openStartDate < nextDayToCollectingEnd}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenStart || todayDate
                                        }}
                                        fullWidth
                                        value={openEndDate}
                                        onChange={(e) => setOpenEndDate(e.target.value)}
                                        error={openEndDate === undefined || openEndDate < nextDayToOpenStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Open Period - End */}
                            {/* Governance Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>5</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.GOVERNANCE_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenEnd || todayDate
                                        }}
                                        fullWidth
                                        value={governanceStartDate}
                                        onChange={(e) => setGovernanceStartDate(e.target.value)}
                                        error={governanceStartDate === undefined || governanceStartDate < nextDayToOpenEnd}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToGovernanceStart || todayDate
                                        }}
                                        fullWidth
                                        value={governanceEndDate}
                                        onChange={(e) => setGovernanceEndDate(e.target.value)}
                                        error={governanceEndDate === undefined || governanceEndDate < nextDayToGovernanceStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Governance Period - End */}
                            {/* Execution Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>7</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.EXECUTION_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenEnd || todayDate
                                        }}
                                        fullWidth
                                        disabled
                                        value={datePlusROI}
                                    />
                                </Grid>

                            </Grid>
                            {/* Execution Period - End */}
                        </Grid>
                        {/* COLUMNA DERECHA */}
                        <Grid container xs={12} md={6} sx={{ pl: 8, pr: 8 }}>
                            {/* Image Uploader - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        Logo
                                    </InputLabel>

                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            width: { xs: '100%', md: '100%' },
                                            alignItems: 'center',
                                            flexDirection: { xs: 'column', md: 'row' },
                                        }}
                                    >
                                        <>
                                            <img
                                                src={fundImgURLState}
                                                style={{
                                                    width: '150px',
                                                    height: '50px',
                                                    objectFit: 'contain',
                                                }}
                                            />

                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                component="label"
                                                starticon={<UploadFileIcon />}
                                                sx={{
                                                    borderRadius: '5px',
                                                    border: '1px dashed #000000',
                                                    width: { xs: '100%', md: '100%' },
                                                    height: '50px',
                                                    fontSize: { xs: '10px', lg: '14px' }
                                                }}
                                            >
                                                <UploadFileIcon sx={{ mr: 2 }} />
                                                <input
                                                    hidden
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => uploadFundImgHandler(e.target.files?.[0])}
                                                />
                                                {t('ADMIN.FUND_IMG_UPLOAD')}
                                            </Button>
                                        </>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* Minimum Success Thresshold - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.SUCC_THRESHOLD')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        fullWidth
                                        value={minimumSuccessThresshold}
                                        onChange={(e) => setMinimumSuccessThresshold(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Minimum Success Thresshold - End */}
                            {/* Maximum Amount to Invest - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_INVEST')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <NumericFormat
                                        value={maximumAmountToInvest}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMaximumAmountToInvest(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(maximumAmountToInvest) && maximumAmountToInvest === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Maximum Amount to Invest - End */}
                            {/* PADDING - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 5,
                                    mb: 5
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}>

                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>

                                </Grid>
                            </Grid>
                            {/* PADDING - End */}
                            {/* Maximum Companies Asking - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.MAX_REQ_COMPANIES')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <NumericFormat
                                        value={maximumCompanyAsking}
                                        thousandSeparator
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        }}
                                        onChange={(e) => setMaximumCompanyAsking(e.target.value)}
                                        customInput={TextField}
                                        error={isNaN(maximumCompanyAsking) && maximumCompanyAsking === ''}
                                    />
                                </Grid>
                            </Grid>
                            {/* Maximum Companies Asking - End */}
                            {/* Purpose - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.PURPOSE')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    {fundPurpose == undefined ?

                                        (
                                            <Autocomplete
                                                fullWidth
                                                limitTags={6}
                                                disabled
                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        {...params}
                                                        name="Country"
                                                        color="pink"
                                                    />
                                                )}
                                            />
                                        )
                                        :
                                        (
                                            <>
                                                <Autocomplete
                                                    fullWidth
                                                    limitTags={6}
                                                    value={purposeId}
                                                    onChange={(event, newValue) => {
                                                        setPurposeId(newValue.id)
                                                        setPurposeName(newValue.titleEn)
                                                    }}
                                                    options={fundPurpose?.fundPurposes}
                                                    getOptionLabel={(p) => (
                                                        selectedLanguage === 'en' ? p?.titleEn :
                                                            selectedLanguage === 'es' ? p?.titleEs :
                                                                selectedLanguage === 'pt' && p?.titlePt
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{ width: '100%' }}
                                                            {...params}
                                                            label={purposeName}
                                                            name="Purpose"
                                                            color="pink"
                                                        />
                                                    )}
                                                />
                                            </>
                                        )}
                                </Grid>
                            </Grid>
                            {/* Purpose - End */}
                            {/* ROI MULTIPLIER - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <InputLabel
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}>
                                        {t('ADMIN.ROI_MULTIPLIER')}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={roiMultiplier}
                                        onChange={(e) => setRoiMultiplier(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* ROI MULTIPLIER - End */}
                            {/* PUBLISHED Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>2</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.PUBLISHED_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingEnd || todayDate
                                        }}
                                        fullWidth
                                        value={collectingEndDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenStart || todayDate
                                        }}
                                        fullWidth
                                        value={openStartDate}
                                    />
                                </Grid>
                            </Grid>
                            {/* PUBLISHED Period - End */}
                            {/* ASSESSMENT Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>4</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.ASSESSMENT_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToCollectingEnd || todayDate
                                        }}
                                        fullWidth
                                        value={openEndDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenStart || todayDate
                                        }}
                                        fullWidth
                                        value={governanceStartDate}
                                    />
                                </Grid>
                            </Grid>
                            {/* ASSESSMENT Period - End */}
                            {/* Vesting Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>6</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.VESTING_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3.5} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToGovernanceEnd || todayDate
                                        }}
                                        fullWidth
                                        value={vestingStartDate}
                                        onChange={(e) => setVestingStartDate(e.target.value)}
                                        error={vestingStartDate === undefined || vestingStartDate < governanceEndDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToVestingStart || todayDate
                                        }}
                                        fullWidth
                                        value={vestingEndDate}
                                        onChange={(e) => setVestingEndDate(e.target.value)}
                                        error={vestingEndDate === undefined || vestingEndDate < nextDayToVestingStart}
                                    />
                                </Grid>
                            </Grid>
                            {/* Vesting Period - End */}
                            {/* ROI Period - Start */}
                            <Grid container xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2
                                }}>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px' }}>
                                        <FiberManualRecordIcon />
                                        <Typography sx={{ color: 'white', ml: -2, fontWeight: 'bold' }}>8</Typography>
                                        <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                            {t('ADMIN.ROI_PERIOD')}
                                        </InputLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={7} sx={{ pr: 0.5 }}>
                                    <TextField
                                        variant="outlined"
                                        type="date"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        inputProps={{
                                            min: nextDayToOpenEnd || todayDate
                                        }}
                                        fullWidth
                                        disabled
                                        value={datePlusROI}
                                    />
                                </Grid>

                            </Grid>
                            {/* ROI Period - End */}
                        </Grid>
                    </Box>
                    {/* Description ENGLISH - Start */}
                    {selectedDescriptionRadio === 'EN' &&
                        <Grid container xs={12} md={11}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center', mt: 2, ml: 8
                            }}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {t('ADMIN.DESCRIPTION')} - English
                                    </InputLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={selectedDescriptionRadio}
                                        onChange={handleChangeSelectedDescription}
                                        sx={{ ml: 6 }}
                                    >
                                        <FormControlLabel value="EN" control={<Radio />} label="EN" />
                                        <FormControlLabel value="ES" control={<Radio />} label="ES" />
                                        <FormControlLabel value="PT" control={<Radio />} label="PT" />
                                    </RadioGroup>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={descriptionEn}
                                    onChange={(e) => setDescriptionEn(e.target.value)}
                                />
                            </Grid>
                        </Grid>}
                    {/* Description ENGLISH - End */}
                    {/* Description ESPAÑOL - Start */}
                    {selectedDescriptionRadio === 'ES' && <Grid container xs={12} md={11}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center', mt: 2, ml: 8
                        }}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.DESCRIPTION')} - Español
                                </InputLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedDescriptionRadio}
                                    onChange={handleChangeSelectedDescription}
                                    sx={{ ml: 6 }}
                                >
                                    <FormControlLabel value="EN" control={<Radio />} label="EN" />
                                    <FormControlLabel value="ES" control={<Radio />} label="ES" />
                                    <FormControlLabel value="PT" control={<Radio />} label="PT" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={2}
                                value={descriptionEs}
                                onChange={(e) => setDescriptionEs(e.target.value)}
                            />
                        </Grid>
                    </Grid>}
                    {/* Description ESPAÑOL - End */}
                    {/* Description PORTUGUÊS - Start */}
                    {selectedDescriptionRadio === 'PT' && <Grid container xs={12} md={11}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center', mt: 2, ml: 8
                        }}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <InputLabel sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {t('ADMIN.DESCRIPTION')} - Português
                                </InputLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedDescriptionRadio}
                                    onChange={handleChangeSelectedDescription}
                                    sx={{ ml: 6 }}
                                >
                                    <FormControlLabel value="EN" control={<Radio />} label="EN" />
                                    <FormControlLabel value="ES" control={<Radio />} label="ES" />
                                    <FormControlLabel value="PT" control={<Radio />} label="PT" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={2}
                                value={descriptionPt}
                                onChange={(e) => setDescriptionPt(e.target.value)}
                            />
                        </Grid>
                    </Grid>}
                    {/* Description PORTUGUÊS - End */}
                    {sendingMutation ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ width: '50%', mt: 2 }}>
                                <LinearProgress />
                            </Box>
                        </Box>
                        :
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                            <Button onClick={handleSave} variant='contained'>Save</Button>
                        </Box>
                    }
                </Grid >
            </Box>
        </>
    )
}


export default FundEdit