import { gql } from '@apollo/client'
import { client } from '../..'
import axios from 'axios'
import moment from 'moment'

const REGISTER_USER = gql`
  mutation CreateUser(
    $userName: String!
    $email: String!
    $id: ID!
    $active: Boolean!
    $pictureUrl: String!
    $memberSince: DateTime!
  ) {
    createUsers(
      input: {
        active: $active
        email: $email
        id: $id
        userName: $userName
        profilePicture: $pictureUrl
        memberSince: $memberSince
        has: { connect: { where: { node: { id: "role-applicant" } } } }
      }
    ) {
      users {
        userName
      }
    }
  }
`
const GET_USER = gql`
  query User($email: String) {
    users(where: { email: $email }) {
      userName
      active
      prefers {
        preferenceName
      }
    }
  }
`

export const passwordChange = (email) => {
  var options = {
    method: 'POST',
    url: 'https://dev--b7mq3b3.us.auth0.com/dbconnections/change_password',
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: email,
      connection: 'Username-Password-Authentication',
    },
  }
  axios
    .request(options)
    .then(function (response) {
      console.log(response.data)
    })
    .catch(function (error) {
      return error
    })
}

export const loginWithCredentials = async (user) => {
  const { data } = await client.query({
    query: GET_USER,
    variables: {
      email: user.email || user?.sub,
    },
  })

  const todayDate = moment.utc()

  if (
    data?.users?.length === 0 &&
    (user?.email != undefined || user?.sub != undefined)
  ) {
    const { data: newUser } = await client.mutate({
      mutation: REGISTER_USER,
      variables: {
        userName: user.nickname,
        email: user.email || user?.sub,
        id: user.email || user?.sub,
        active: true,
        urlId: user.picture,
        pictureUrl: user.picture,
        memberSince: todayDate,
      },
    })
    localStorage.setItem('role', 'role-applicant')
    return newUser
  }
}

export default loginWithCredentials
