import React, { useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, IconButton, Modal, TextField, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CREATE_PREFERENCE } from '../../../Queries/Admin';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const InterestsAdministrationAddModal = () => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [interest, setInterest] = useState('')
    const handleChangeInterest = (event) => { setInterest(event.target.value) }
    const newId = uuidv4()
    const [addPreference] = useMutation(CREATE_PREFERENCE, {
        variables: {
            data: {
                id: newId,
                preferenceName: interest,
                isActive: true
            },
        },
    });

    const style = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: actualThemeLight ? 'rgba(229, 229, 229, 1)' : '#1d1d1d',
        borderRadius: '10px',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
        width: '450px',
        maxHeight: '100%',
    };

    const handleCreatePreference = async () => {
        await addPreference()
        await handleClose()
    }

    return (
        <>
            <AddCircleIcon
                sx={{
                    cursor: 'pointer',
                    width: '50px',
                    height: '50px',
                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',

                }}
                onClick={handleOpen}
            />
            <Typography onClick={handleOpen} sx={{
                ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd', cursor: 'pointer'
            }}>{t('ADMIN.CREATE_PREFERENCE')}</Typography >
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'right' }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('ADMIN.PREFERENCE')}
                    </Typography>
                    <TextField placeholder={t('ADMIN.PREFERENCE')} required onChange={handleChangeInterest} sx={{ m: 2, width: '90%' }}></TextField>
                    <Button onClick={handleCreatePreference} sx={{ width: '150px', mt: 2 }} variant="contained">
                        {t('ADMIN.CREATE')}
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default InterestsAdministrationAddModal