import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const CustomTooltipCollectingThresshold = ({ active, payload, thresshold, raised, amountToReach }) => {
    const { t } = useTranslation()
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '2px solid #db417b' }}>
                <p>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FiberManualRecordIcon fontSize="medium" sx={{ color: "green" }} />
                        <Typography sx={{ fontSize: '12px', color: 'black' }}>
                            {`${t('FUND.MIN_THRESHOLD')}: ${thresshold}% ($${amountToReach * thresshold / 100})`}
                        </Typography>
                    </Box>
                </p>
                <p>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FiberManualRecordIcon fontSize="medium" sx={{ color: "#db417b" }} />
                        <Typography sx={{ fontSize: '12px', color: 'black' }}>
                            {`${t('FUND.COLLECTED')}: $ ${raised} / $ ${amountToReach}`}
                        </Typography>
                    </Box>
                </p>
            </div>
        );
    }
    return null;
};

export default CustomTooltipCollectingThresshold;
