import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Link } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function createData(date, transactionType, orderNumber, symbol, amount, fund, scId) {
  return {
    date,
    transactionType,
    orderNumber,
    symbol,
    amount,
    fund,
    scId
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  const { t } = useTranslation()
  const headCells = [
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: t('WALLET_VIEW.DATE'),
    },
    {
      id: 'transaction',
      numeric: true,
      disablePadding: false,
      label: t('WALLET_VIEW.TRANSACTION'),
    },
    {
      id: 'orderNumber',
      numeric: true,
      disablePadding: false,
      label: t('WALLET_VIEW.ORDER_NUMBER'),
    },
    {
      id: 'symbol',
      numeric: true,
      disablePadding: false,
      label: t('WALLET_VIEW.SYMBOL'),
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: t('WALLET_VIEW.AMOUNT'),
    },
  ]

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'normal' : 'none'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ ml: 2 }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props

  return (
    <Toolbar
      sx={{
        ...(numSelected > 0 && {
          color: 'black',
        }),
      }}
    >
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

export default function EnhancedTable({ transactions, isLoading }) {
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('date')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const { t } = useTranslation()


  const rows = transactions?.map((t) => {
    const fDate = t.date.split('T')[0]
    return createData(fDate, t?.type, t?.hash, t?.offerPurchase?.fund?.token[0]?.symbol, t?.amount, t?.offerPurchase?.fund?.name, t?.offerPurchase?.smartContract?.contractId)
  })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  function replaceHash(inputString) {
    var resultString = encodeURIComponent(inputString);
    return resultString;
  }

  function extractAfterWithdrawals(inputString) {
    const keyword = 'withdrawals/';

    if (inputString.includes(keyword)) {
      const index = inputString.indexOf(keyword);
      return inputString.substring(index + keyword.length);
    }
    return inputString;
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  if (isLoading) {
    return <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 8
      }}>
      <CircularProgress />
    </Box>
  }

  return (
    <Box sx={{ width: '100%' }}>
      {rows?.length > 0 ? (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    const isItemSelected = isSelected(row.name)
                    const labelId = `enhanced-table-checkbox-${index}`
                    let transaction = row?.transactionType
                    const orderNumber = extractAfterWithdrawals(row.orderNumber)
                    const isDeposit = transaction?.includes('Deposit')
                    const isWithdraw = transaction.includes('Withdraw')

                    if (transaction !== '') {

                      var smartContractIdURI = replaceHash(row?.scId);
                      const depositTransactionUrl = `https://preprod.marlowescan.com/contractView?tab=tx&contractId=${smartContractIdURI}&transactionId=${orderNumber}`
                      const withdrawTransactionUrl = `https://preprod.cardanoscan.io/transaction/${orderNumber}`

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {row.date}
                          </TableCell>
                          <TableCell align="center">{transaction}</TableCell>
                          {
                            isDeposit && <TableCell align="center" sx={{ justifyContent: 'center' }}>
                              <Link
                                href={depositTransactionUrl}
                                sx={{ cursor: 'pointer' }}
                                target={{ _blank: true }}
                              >
                                {orderNumber}
                              </Link>
                              <OpenInNewIcon fontSize="tiny" sx={{ ml: 0.5, color: '#9A1D4C' }} />
                            </TableCell>
                          }
                          {
                            isWithdraw && <TableCell align="left" sx={{ justifyContent: 'center' }}>
                              <Link
                                href={withdrawTransactionUrl}
                                sx={{ cursor: 'pointer' }}
                                target={{ _blank: true }}
                              >
                                {orderNumber}
                              </Link>
                              <OpenInNewIcon fontSize="tiny" sx={{ ml: 0.5, color: '#9A1D4C' }} />
                            </TableCell>
                          }
                          <TableCell align="center">{row.fund}</TableCell>
                          {transaction === 'Deposit' ?
                            <TableCell align="center">{row.amount} {row.symbol}</TableCell>
                            :
                            <TableCell align="center">{row.amount} Alli</TableCell>
                          }
                        </TableRow>
                      )
                    } else {
                      null
                    }
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Typography variant="h5" textAlign="center">
          {t('WALLET_VIEW.NO_TRANSACTIONS')}
        </Typography>
      )}
    </Box>
  )
}
