import { gql } from '@apollo/client'

export const GET_USER_INFO = gql`
  query ($email: String!) {
    users(where: { email: $email }) {
      id
      userName
      bio
      profilePicture
      prefers {
        id
        preferenceName
      }
      has {
        id
        roleName
      }
      speaks {
        id
        description
      }
      has_url {
        link
        name
        id
      }
      email
      active
      wallet {
        address
      }
    }
  }
`

export const GET_USERS_BY_EMAIL = gql`
  query ($email: String!) {
    users(where: { email: $email }) {
      id
      has_url(where: { NOT: { id: null } }) {
        id
        name
        link
      }
      userName
      manages {
        id
        address
        coName
        descriptionEn
        descriptionEs
        descriptionPt
        logo
        has_url {
          _id
          name
        }
      }
      profilePicture
      prefers {
        id
        preferenceName
      }
      has {
        id
        roleName
      }
      email
      active
      bio
    }
  }
`

export const UPDATE_PHOTO = gql`
  mutation updateProfilePic($id: ID!, $profilePicture: String!) {
    updateUsers(
      where: { id: $id }
      update: { profilePicture: $profilePicture }
    ) {
      users {
        id
        profilePicture
        userName
      }
    }
  }
`

export const IS_USER_BLOCKED = gql`
  query ($userId: ID!) {
    users(where: { id: $userId }) {
      active
    }
  }
`

export const WELCOME_MODAL_SHOW = gql`
  query ($userId: ID!) {
    users(where: { id: $userId }) {
      welcomeModal
    }
  }
`

export const DISABLE_MODAL_SHOW = gql`
  mutation ($userId: ID!) {
    updateUsers(where: { id: $userId }, update: { welcomeModal: false }) {
      users {
        id
      }
    }
  }
`

export const GET_CORE_TEAM_USERS = gql`
  query {
    users(where: { has_SOME: { id: "role-core-team" } }) {
      id
      userName
      email
    }
  }
`

export const UPDATE_USER_LEGAL_INFO = gql`
  mutation (
    $userId: ID!
    $legalFullname: String
    $legalId: String
    $legalCountry: String
    $legalFundsOrigin: String
  ) {
    updateUsers(
      where: { id: $userId }
      update: {
        legalFullname: $legalFullname
        legalId: $legalId
        legalCountry: $legalCountry
        legalFundsOrigin: $legalFundsOrigin
      }
    ) {
      users {
        id
      }
    }
  }
`

export const GET_USER_LEGAL_INFO = gql`
  query ($userId: ID!) {
    users(where: { id: $userId }) {
      id
      legalId
      legalCountry
      legalFullname
      legalFundsOrigin
    }
  }
`
