import { Avatar, FormControl, InputLabel, Select, List, ListItem, ListItemButton, ListItemText, TableCell, TableRow, Typography, Chip, Box, CircularProgress, Modal, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SET_USER_AS_BLOCKED, SET_USER_AS_ACTIVE, DELETE_USER, SET_USER_AS_CORE_TEAM, SET_USER_AS_NOT_CORE_TEAM } from '../../../Queries/Admin';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import UserDetailAdmin from './UserDetailAdmin';

const UserAdministrationRow = (data) => {
    // Get translations
    const { t } = useTranslation()
    // Define and initialize show and showDisabled states as false using useState hook
    // eslint-disable-next-line
    const [show, setShow] = useState(false);
    // eslint-disable-next-line
    const [showDisabled, setShowDisabled] = useState(false);

    // Define mutations to block, activate, set as core team member or set as not core team member using useMutation hook
    const [setUserAsBlocked] = useMutation(SET_USER_AS_BLOCKED, {
        variables: {
            userId: data?.data?.id,
        },
    });


    const [setUserAsActive] = useMutation(SET_USER_AS_ACTIVE, {
        variables: {
            userId: data?.data?.id,
        },
    });

    const [setUserAsCoreTeam, { loading: setAsCoreLoading }] = useMutation(SET_USER_AS_CORE_TEAM, {
        variables: {
            userId: data?.data?.id,
        },
    });

    const [setUserAsNOTCoreTeam, { loading: setAsNotCoreLoading }] = useMutation(SET_USER_AS_NOT_CORE_TEAM, {
        variables: {
            userId: data?.data?.id,
        },
    });

    // Define mutation to delete user using useMutation hook
    const [deleteUser] = useMutation(DELETE_USER, {
        variables: {
            userId: data?.data?.id,
        },
    });

    // Define and initialize isCoreTeam state as false using useState hook
    const [isCoreTeam, setIsCoreTeam] = useState(false)

    // Use useEffect hook to check if the user is a core team member or not
    useEffect(() => {
        const intervalId = setInterval(() => {
            let isCore = false;
            for (const role of data?.data?.has) {
                if (role.id === 'role-core-team') {
                    isCore = true;
                    break;
                }
            }

            setIsCoreTeam(isCore);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [data?.data?.has, setIsCoreTeam]);

    // Define and initialize localLoading state as false using useState hook
    const [localLoading, setLocalLoading] = useState(false)

    // Define and initialize roleOnDB state as empty string using useState hook
    const [roleOnDB, setRoleOnDB] = useState('')

    // Use useEffect hook to set the user's role on the database to role-applicant or role-investor
    useEffect(() => {
        data?.data?.has?.map((role) => {
            if (role.id === "role-applicant") setRoleOnDB("role-applicant")
            if (role.id === "role-investor") setRoleOnDB("role-investor")
        })
    }, [data])

    const [openConfirmationCoreTeam, setOpenConfirmationCoreTeam] = React.useState(false);
    const handleOpenConfirmationCoreTeam = () => setOpenConfirmationCoreTeam(true);
    const handleCloseConfirmationCoreTeam = () => setOpenConfirmationCoreTeam(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const [openConfirmationBlock, setOpenConfirmationBlock] = React.useState(false);
    const handleOpenConfirmationBlock = () => setOpenConfirmationBlock(true);
    const handleCloseConfirmationBlock = () => setOpenConfirmationBlock(false);

    const [openConfirmationDelete, setOpenConfirmationDelete] = React.useState(false);
    const handleOpenConfirmationDelete = () => setOpenConfirmationDelete(true);
    const handleCloseConfirmationDelete = () => setOpenConfirmationDelete(false);

    const startDay = data?.data?.memberSince?.split('-')[2].split('T')[0]
    const startMonth = data?.data?.memberSince?.split('-')[1]
    const startYear = data?.data?.memberSince?.split('-')[0]

    // Check if any of the following variables are true
    if (setAsCoreLoading || setAsNotCoreLoading || localLoading) {
        // If so, return a TableRow component with a key based on data.id
        // and styling for the last child of the table
        return (
            <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center" component="th" scope="row" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100px' }}>
                    <Avatar src={data?.data?.profilePicture} sx={{ mr: 2 }}></Avatar>
                    {data?.data?.userName}
                </TableCell>
                <TableCell align="center">{data?.data?.email}</TableCell>
                <TableCell align="center">
                    <CircularProgress />
                </TableCell>
                {/* If the user is active, render the following components */}
                {data?.data?.active === true ? (
                    <>
                        {/* Render a cell with green text indicating that the user is active */}
                        <TableCell align="center">
                            <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                {t('ADMIN.STATUS_ACT')}
                            </Typography>
                        </TableCell>
                        {/* Render a Select component with options to block or set/unset as core team */}
                        <TableCell align="center">
                            <FormControl fullWidth>
                                <InputLabel>{t('ADMIN.OPTIONS')}</InputLabel>
                                <Select
                                    label='<MoreVertOutlinedIcon/>'
                                    onClick={() => setShow((prev) => !prev)}
                                    sx={{ width: '114px', minWidth: '114px' }}
                                >
                                    <List>
                                        {/* Render a ListItemButton component that sets the user as blocked */}
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleOpenConfirmationBlock}>
                                                <ListItemText primary={t('ADMIN.ACT_BLOCK')} />
                                            </ListItemButton>
                                        </ListItem>
                                        {/* Render a ListItemButton component that sets the user as not core team */}
                                        {isCoreTeam !== false ? (
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={handleOpenConfirmationCoreTeam}>
                                                    <ListItemText primary={t('ADMIN.ACT_SET_AS_NOT_CORE_TEAM')} />
                                                </ListItemButton>
                                            </ListItem>
                                        ) : (
                                            // Render a ListItemButton component that sets the user as core team
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={handleOpenConfirmationCoreTeam}>
                                                    <ListItemText primary={t('ADMIN.ACT_SET_AS_CORE_TEAM')} />
                                                </ListItemButton>
                                            </ListItem>
                                        )}

                                    </List>
                                </Select>
                            </FormControl>
                        </TableCell>
                    </>
                ) : (
                    // If the user is not active, render the following components
                    <>
                        {/* Render a cell with red text indicating that the user is blocked */}
                        <TableCell align="center">
                            <Typography sx={{
                                color: 'red', fontWeight: 'bold'
                            }}>
                                {t('ADMIN.STATUS_BLO')}
                            </Typography>
                        </TableCell>
                        {/* Render a select dropdown for admin options */}
                        <TableCell align="center">
                            <FormControl fullWidth>
                                <InputLabel>{t('ADMIN.OPTIONS')}</InputLabel>
                                <Select
                                    label='<MoreVertOutlinedIcon/>'
                                    // Toggle the dropdown when clicked
                                    onClick={() => setShow((prev) => !prev)}
                                    sx={{ width: '114px' }}



                                >
                                    <List>
                                        {/* Add button to enable the user */}
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleOpenConfirmationBlock}>
                                                <ListItemText primary={t('ADMIN.ACT_ENABLE')} />
                                            </ListItemButton>
                                        </ListItem>
                                        {/* Add button to delete the user */}
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => {
                                                deleteUser()
                                                setShowDisabled(false)
                                                // Set loading to true before calling the API
                                                setLocalLoading(true)
                                                // Disable loading after 4 seconds
                                                setTimeout(function disableSet2secLoading() {
                                                    setLocalLoading(false)
                                                }, 4000);
                                            }}>
                                                <ListItemText primary={t('ADMIN.ACT_DELETE')} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Select>
                            </FormControl>
                        </TableCell>
                    </>
                )}
            </TableRow>
        )
    }


    return (
        <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" scope="row"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '100px',
                    overflow: 'hidden',
                }}>
                <Avatar src={data?.data?.profilePicture} sx={{ mr: 2 }}></Avatar>
                {data?.data?.userName}
            </TableCell>
            <TableCell align="center">{data?.data?.email}</TableCell>
            <TableCell align="center">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {roleOnDB === 'role-applicant' ? t('PERFIL_CONFIG_VIEW.ROLE_OFFERER') : t('PERFIL_CONFIG_VIEW.ROLE_INVESTOR')}
                    {isCoreTeam && <Chip sx={{ mt: 1 }} size="small" color="pink" label="Core Team" />}
                </Box>
            </TableCell>
            <TableCell align="center">{t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}, {startYear}</TableCell>
            {data?.data?.active === true ? ( // Check if data is active
                <>
                    {/* Display table cells */}
                    <TableCell align="center">
                        <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                            {t('ADMIN.STATUS_ACT')}
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        {/* Display form control */}
                        <FormControl fullWidth>
                            <InputLabel sx={{ pr: 1 }}>{t('ADMIN.OPTIONS')}</InputLabel>
                            {/* Display select input */}
                            <Select
                                label='<MoreVertOutlinedIcon/>'
                                onClick={() => setShow((prev) => !prev)} // Show or hide the modal
                                sx={{ width: '114px', minWidth: '114px' }}
                            >
                                {/* Display list */}
                                <List>
                                    {/* Display list items */}
                                    <ListItem disablePadding>
                                        {/* Add event listener to the button */}
                                        <ListItemButton onClick={handleOpenConfirmationBlock}>
                                            <ListItemText primary={t('ADMIN.ACT_BLOCK')} />
                                        </ListItemButton>
                                    </ListItem>
                                    {/* Check if user is already a core team member */}
                                    {isCoreTeam !== false ? (
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleOpenConfirmationCoreTeam}>
                                                <ListItemText primary={t('ADMIN.ACT_SET_AS_NOT_CORE_TEAM')} />
                                            </ListItemButton>
                                        </ListItem>
                                    ) : (
                                        // Render a ListItemButton component that sets the user as core team
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleOpenConfirmationCoreTeam}>
                                                <ListItemText primary={t('ADMIN.ACT_SET_AS_CORE_TEAM')} />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </List>
                            </Select>
                        </FormControl>
                    </TableCell>
                </>
            ) : // This code generates a table cell with status "Blocked" and a dropdown button that displays a list of options.
                // When the button is clicked, it triggers different functions to change the user's status or delete the user.

                (
                    <>
                        {/* Table cell with status "Blocked" */}
                        <TableCell align="center">
                            <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                                {t('ADMIN.STATUS_BLO')}
                            </Typography>
                        </TableCell>
                        {/* Dropdown button with options */}
                        <TableCell align="center">
                            <FormControl fullWidth>
                                <InputLabel>{t('ADMIN.OPTIONS')}</InputLabel>
                                <Select
                                    label='<MoreVertOutlinedIcon/>'
                                    onClick={() => setShow((prev) => !prev)}
                                    sx={{ width: '112px', minWidth: '112px' }}
                                >
                                    <List>
                                        {/* Enable user button */}
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleOpenConfirmationBlock}>
                                                <ListItemText primary={t('ADMIN.ACT_ENABLE')} />
                                            </ListItemButton>
                                        </ListItem>
                                        {/* Delete user button */}
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleOpenConfirmationDelete}>
                                                <ListItemText primary={t('ADMIN.ACT_DELETE')} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Select>
                            </FormControl>
                        </TableCell>
                    </>
                )
            }
            <Modal
                open={openConfirmationCoreTeam}
                onClose={handleCloseConfirmationCoreTeam}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        {isCoreTeam !== false
                            ?
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmationCoreTeam}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={() => {
                                    setIsCoreTeam(false)
                                    setShow(false)
                                    setUserAsNOTCoreTeam()
                                    setLocalLoading(true)
                                    handleCloseConfirmationCoreTeam()
                                    setTimeout(function disableSet2secLoading() {
                                        setLocalLoading(false)
                                    }, 4000);
                                }}>{t('ADMIN.ACT_SET_AS_NOT_CORE_TEAM')}</Button>
                            </>
                            :
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmationCoreTeam}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={() => {
                                    setUserAsCoreTeam()
                                    setShow(false)
                                    setLocalLoading(true)
                                    handleCloseConfirmationCoreTeam()
                                    setTimeout(function disableSet2secLoading() {
                                        setLocalLoading(false)
                                    }, 4000);
                                }}>{t('ADMIN.ACT_SET_AS_CORE_TEAM')}</Button>
                            </>
                        }
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openConfirmationBlock}
                onClose={handleCloseConfirmationBlock}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        {data?.data?.active === true
                            ?
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmationBlock}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={() => {
                                    setUserAsBlocked()
                                    setShow(false)
                                    setLocalLoading(true)
                                    handleCloseConfirmationBlock()
                                    setTimeout(function disableSet2secLoading() {
                                        setLocalLoading(false)
                                    }, 4000);
                                }}>{t('ADMIN.ACT_BLOCK')}</Button>
                            </>
                            :
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmationBlock}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={() => {
                                    setUserAsActive()
                                    setShowDisabled(false)
                                    setLocalLoading(true)
                                    handleCloseConfirmationBlock()
                                    setTimeout(function disableSet2secLoading() {
                                        setLocalLoading(false)
                                    }, 4000);
                                }}>{t('ADMIN.ACT_ENABLE')}</Button>
                            </>
                        }
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openConfirmationDelete}
                onClose={handleCloseConfirmationDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        <Button variant='outlined' onClick={handleCloseConfirmationDelete}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                        <Button variant='contained' onClick={() => {
                            deleteUser()
                            setShowDisabled(false)
                            setLocalLoading(true)
                            handleCloseConfirmationDelete()
                            setTimeout(function disableSet2secLoading() {
                                setLocalLoading(false)
                            }, 4000);
                        }}>{t('MY_OFFERINGS.DELETE')}</Button>
                    </Box>
                </Box>
            </Modal>
            <TableCell align="center"><UserDetailAdmin data={data?.data} /></TableCell>
        </TableRow >
    );
};

export default UserAdministrationRow;
