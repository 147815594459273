import { Avatar, Box, Button, Link, Modal, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { showMessage } from '../../../store/actions/snackBarActions'
import { useMutation } from '@apollo/client';
import { DELETE_TOKEN } from '../../../Queries/Admin';

const IndividualToken = ({ data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleOpenEdit = () => {
        dispatch(push(`/admin-tokens-edit/${data.id}`))
    };

    const [deleteToken] = useMutation(DELETE_TOKEN)

    const handleDelete = async () => {
        await deleteToken({
            variables: {
                tokenId: data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Deleted!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    return (
        <TableRow key={data?.data?.id} >
            <TableCell align="center" component="th" scope="row">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    maxWidth: '250px',
                    overflow: 'hidden',
                }} >
                    <Avatar src={data?.logo} sx={{ mr: 2 }}></Avatar>
                    {data?.symbol}
                </Box>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Box sx={{ wordBreak: 'break-word', width: '100px' }}>
                    {data?.maxCap.toLocaleString()}
                </Box>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Box sx={{ wordBreak: 'break-word' }}>
                    {data?.policyId}
                </Box>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Box sx={{ wordBreak: 'break-word' }}>
                    {data?.description}
                </Box>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Box sx={{ wordBreak: 'break-word' }}>
                    {data?.marketprice}
                </Box>
            </TableCell>

            <TableCell align="center" component="th" scope="row">
                <Box sx={{ overflow: 'hidden', wordBreak: 'break-word', maxHeight: '20px' }}>
                    {data?.fingerprint}
                </Box>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Box sx={{ overflow: 'hidden', maxHeight: '20px' }}>
                    {data?.fund?.length === 0 ? '-' : data?.fund?.length === 1 ? data?.fund[0]?.name : data?.fund?.length + ' ' + t('WALLET_VIEW.SYMBOL') + 's'}
                </Box>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenEdit} >
                    {t('MY_OFFERINGS.MODIFY')}
                </Link>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                    {t('MY_OFFERINGS.DELETE')}
                </Link>
            </TableCell>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                        <Button variant='contained' onClick={handleDelete}>{t('MY_OFFERINGS.DELETE')}</Button>
                    </Box>
                </Box>
            </Modal>
        </TableRow>
    )
}

export default IndividualToken