import { gql } from '@apollo/client'

export const SEND_MESSAGE = gql`
  mutation (
    $id: ID!
    $on: DateTime!
    $onUTC: DateTime!
    $text: String!
    $read: Boolean!
    $deleted: Boolean!
    $userId: ID!
    $otherUserId: ID!
  ) {
    createMessages(
      input: {
        id: $id
        on: $on
        onUTC: $onUTC
        text: $text
        read: $read
        deleted: $deleted
        sentBy: { connect: { where: { node: { id: $userId } } } }
        sentTo: { connect: { where: { node: { id: $otherUserId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`

export const GET_MESSAGES_FROM_USERS = gql`
  query ($userId: ID!, $otherUser: String) {
    messages(
      options: { sort: { onUTC: ASC } }
      where: {
        OR: [
          { sentBy: { id: $userId }, AND: { sentTo: { userName: $otherUser } } }
          { sentTo: { id: $userId }, AND: { sentBy: { userName: $otherUser } } }
        ]
      }
    ) {
      read
      text
      id
      isOfferPurchase
      on
      onUTC
      sentTo {
        userName
        id
      }
      sentBy {
        userName
        id
      }
    }
  }
`

export const GET_USER_ID = gql`
  query ($username: String) {
    users(where: { userName: $username }) {
      userName
      id
    }
  }
`

export const GET_ALL_USERS = gql`
  query {
    users {
      userName
      id
    }
  }
`

export const GET_ALL_MESSAGES = gql`
  query {
    messages(options: { sort: { on: DESC } }) {
      id
      on
      text
      read
      sentTo {
        id
        userName
        profilePicture
      }
      sentBy {
        id
        userName
        profilePicture
      }
      deleted
    }
  }
`

export const SET_MESSAGES_AS_READ = gql`
  mutation setMessagesAsRead($ids: [ID!]!) {
    updateMessages(where: { id_IN: $ids }, update: { read: true }) {
      messages {
        id
      }
    }
  }
`
