import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, Chip, Tooltip, Avatar, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CopyToClipboardButton from './CopyToClipboardButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const UserDetailAdmin = ({ data }) => {
    const idIncludesEmail = data?.id.includes('@')
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
    const startDay = data?.memberSince?.split('-')[2].split('T')[0]
    const startMonth = data?.memberSince?.split('-')[1]
    const startYear = data?.memberSince?.split('-')[0]
    const [roleOnDB, setRoleOnDB] = useState('')
    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        data?.has?.map((role) => {
            if (role.id === "role-applicant") setRoleOnDB("role-applicant")
            if (role.id === "role-investor") setRoleOnDB("role-investor")
        })
        const intervalId = setInterval(() => {
            let isCore = false;
            for (const role of data?.has) {
                if (role.id === 'role-core-team') {
                    isCore = true;
                    break;
                }
            }

            setIsCoreTeam(isCore);
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [data])

    function getTotalProposals(userData) {
        let totalProposals = 0;
        if (userData && userData.manages && userData.manages.length > 0) {
            userData.manages.forEach(company => {
                if (company.proposal && company.proposal.length > 0) {
                    totalProposals += company.proposal.length;
                }
            });
        }
        return totalProposals;
    }

    const totalProposals = getTotalProposals(data);

    function countUniqueUsers(userData) {
        let uniqueUsers = new Set()
        if (userData && userData.sentMessages && userData.sentMessages.length > 0) {
            userData.sentMessages.forEach(message => {
                if (message.sentTo && message.sentTo.id) {
                    uniqueUsers.add(message.sentTo.id);
                }
            });
        }
        return uniqueUsers.size;
    }

    const uniqueUserCount = countUniqueUsers(data);

    function countNullSentTo(userData) {
        let nullCount = 0;
        if (userData && userData.sentMessages && userData.sentMessages.length > 0) {
            userData.sentMessages.forEach(message => {
                if (!message.sentTo || !message.sentTo.id) {
                    nullCount++;
                }
            });
        }
        return nullCount;
    }

    const nullCount = countNullSentTo(data);

    function countNonNullSentTo(userData) {
        let nonNullCount = 0;
        if (userData && userData.sentMessages && userData.sentMessages.length > 0) {
            userData.sentMessages.forEach(message => {
                if (message.sentTo && message.sentTo.id) {
                    nonNullCount++;
                }
            });
        }
        return nonNullCount;
    }

    const nonNullCount = countNonNullSentTo(data);

    return (
        <>
            <Link sx={{ cursor: 'pointer' }} onClick={handleOpen}>
                {t('OFFERINGS_TABLE.ACT_SEE_MORE')}
            </Link>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* USERNAME AND ID BLOCK - START */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'right' }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.USERNAME')}:
                            </Typography>
                            <Avatar src={data?.profilePicture} sx={{ ml: 1, width: 30, height: 30 }}></Avatar>
                            <Typography sx={{ ml: 1 }}>
                                {data?.userName}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Email / ID:
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {data?.id}
                            </Typography>
                            {idIncludesEmail &&
                                <>
                                    <Tooltip title="Send Email">
                                        <Link
                                            href={'mailto:' + data?.id}
                                            sx={{ cursor: 'pointer', fontSize: '13px' }}
                                            target={{ _blank: true }}
                                        >
                                            <MailOutlineIcon fontSize='small' sx={{ ml: 1 }} />
                                        </Link>
                                    </Tooltip>

                                    <CopyToClipboardButton text={data?.id} title={'Copy Email'} />

                                </>
                            }
                        </Box>
                    </Box>
                    {/* USERNAME AND ID BLOCK - END */}
                    {/* MEMBER SINCE AND ROLE - START */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 4 }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.MEMBER_SINCE')}:
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}, {startYear}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.PROFILE')}:
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {roleOnDB === 'role-applicant' ? t('PERFIL_CONFIG_VIEW.ROLE_OFFERER') : t('PERFIL_CONFIG_VIEW.ROLE_INVESTOR')}
                                {isCoreTeam && <Chip sx={{ ml: 1 }} size="small" color="pink" label="Core Team" />}
                            </Typography>
                        </Box>
                    </Box>
                    {/* MEMBER SINCE AND ROLE - START */}
                    {/* COMPANIES CREATED AND PROPOSALS - START */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.COMPANIES_CREATED')}
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {data?.manages?.length}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.PROPOSALS_CREATED')}
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {totalProposals}
                            </Typography>
                        </Box>
                    </Box>
                    {/* COMPANIES CREATED AND PROPOSALS - END */}
                    {/* MESSAGES TO UNIQUE USERS - START */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.TOTAL_MESSAGES_SENT')}
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {nonNullCount}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.USERS_CONTACTED')}
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {uniqueUserCount}
                            </Typography>
                        </Box>
                    </Box>
                    {/* MESSAGES TO UNIQUE USERS - END */}
                    {/* COMMENTS COUNTER - START */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {t('ADMIN.COMMENTS')}
                            </Typography>
                            <Typography sx={{ ml: 1 }}>
                                {nullCount}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                        </Box>
                    </Box>
                    {/* COMMENTS COUNTER - END */}
                </Box>
            </Modal>
        </>
    );
}

export default UserDetailAdmin