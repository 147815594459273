import { Box, Button, Link, Modal, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from "i18next";
import { useMutation } from '@apollo/client';
import { ENABLE_NEWS_POST, DISABLE_NEWS_POST } from '../../../Queries/Admin';
import { useDispatch } from 'react-redux'
import { showMessage } from '../../../store/actions/snackBarActions'
import { push } from 'connected-react-router'


const IndividualNew = ({ data }) => {
    const [seeMore, setSeeMore] = useState(false)
    const { t } = useTranslation()
    const selectedLanguage = i18n.language
    const [disableNews] = useMutation(DISABLE_NEWS_POST)
    const [enableNews] = useMutation(ENABLE_NEWS_POST)
    const dispatch = useDispatch()

    const handleDisable = async () => {
        await disableNews({
            variables: {
                newsId: data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'News post disabled!', variant: 'success' }))
    }

    const handleEnable = async () => {
        await enableNews({
            variables: {
                newsId: data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'News post enabled!', variant: 'success' }))
    }

    const handleOpenEdit = () => {
        dispatch(push(`/admin-dao-news-edit/${data.id}`))
    };

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const Day = data?.on?.split('-')[2].split('T')[0]
    const Month = data?.on?.split('-')[1]
    const Year = data?.on?.split('-')[0]

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box
            sx={{
                display: 'flex',
                p: 2,
                pb: 4,
                backgroundColor: data?.isActive ? actualThemeLight ? 'white' : '#1d1d1d' : '#C0C0C0',
                mt: 1,
                flexDirection: 'row',
                width: '100%',
                border: '2px solid #9A1D4C',
                borderRadius: '15px',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <img src={data?.image} style={{
                    width: '170px',
                    height: '170px',
                    marginRight: 10,
                    marginLeft: 10,
                    objectFit: 'scale-down'
                }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', mt: 1 }}>
                    <Button onClick={handleOpenEdit} variant='contained'>{t('MY_OFFERINGS.MODIFY')}</Button>
                    {data.isActive === true ?
                        <Button sx={{ ml: 0.5 }} onClick={handleOpenConfirmation} variant='contained'>{t('ADMIN.ACT_DISABLE')}</Button>
                        :
                        <Button sx={{ ml: 0.5 }} onClick={handleOpenConfirmation} variant='contained'>{t('ADMIN.ACT_ENABLE')}</Button>
                    }
                </Box>
                <Modal
                    open={openConfirmation}
                    onClose={handleCloseConfirmation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('MY_OFFERINGS.ARE_YOU_SURE')}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                            {data?.isActive
                                ?
                                <>
                                    <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                    <Button variant='contained' onClick={handleDisable}>{t('ADMIN.ACT_DISABLE')}</Button>
                                </>
                                :
                                <>
                                    <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                    <Button variant='contained' onClick={handleEnable}>{t('ADMIN.ACT_ENABLE')}</Button>
                                </>
                            }
                        </Box>
                    </Box>
                </Modal>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', mb: -1 }}>
                    <Typography fontSize={12}>{t(`MONTHS.${Month}`).substring(0, 3)}. {Day} - {Year}</Typography>
                    <Typography fontSize={12} sx={{ ml: 0.5, mr: 0.5 }}>-</Typography>
                    <Typography fontSize={12}>{data.on.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}</Typography>
                </Box>
                {seeMore ?
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        <Typography fontSize={18} fontWeight={'bold'}>
                            {selectedLanguage === 'en' ? data?.titleEn :
                                selectedLanguage === 'es' ? data?.titleEs :
                                    selectedLanguage === 'pt' && data?.titlePt}
                        </Typography>
                        <Typography fontSize={14}>
                            {selectedLanguage === 'en' ? data?.contentEn :
                                selectedLanguage === 'es' ? data?.contentEs :
                                    selectedLanguage === 'pt' && data?.contentPt}
                        </Typography>
                    </Box>
                    :
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 2,
                        height: '175px',
                        maxHeight: '175px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mt: 1
                    }}>
                        <Typography fontSize={18} fontWeight={'bold'}>
                            {selectedLanguage === 'en' ? data?.titleEn :
                                selectedLanguage === 'es' ? data?.titleEs :
                                    selectedLanguage === 'pt' && data?.titlePt}
                        </Typography>
                        <Link
                            href={data?.url?.link}
                            target={{ _blank: true }}
                            color='inherit'
                            variant='h6'
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px' }}>{data?.url?.link}</Typography>
                        </Link>
                        <Typography fontSize={14}>
                            {selectedLanguage === 'en' ? data?.contentEn :
                                selectedLanguage === 'es' ? data?.contentEs :
                                    selectedLanguage === 'pt' && data?.contentPt}
                        </Typography>
                    </Box>
                }
                <Box
                    onClick={() => { setSeeMore(!seeMore) }}
                    sx={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'right',
                        mt: 1,
                        mb: -3
                    }}>
                    {data.contentEn.length > 800 ?
                        seeMore ?
                            <Typography color={'#9A1D4C'} fontSize={12} fontWeight={'bold'}>{t("ADMIN.SEE_LESS")}</Typography>
                            :
                            <Typography color={'#9A1D4C'} fontSize={12} fontWeight={'bold'}>{t("ADMIN.SEE_MORE")}</Typography> : null}


                </Box>
            </Box>
        </Box>
    )
}

export default IndividualNew