import {
    Avatar, Box, CircularProgress,
    LinearProgress, Link, TableCell, TableRow,
    Typography, Button,
    Tooltip
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useEffect } from 'react'
import { useState } from 'react'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const FundAdministrationRow = ({ data, sendData }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleOpen = () => {
        dispatch(push(`/admin-fund-detail/${data.id}`))
    };
    const handleOpenEdit = () => {
        dispatch(push(`/admin-funds-edit/${data.id}`))
    };

    const [headerStartDateToShow, setHeaderStartDateToShow] = useState(undefined)
    const [headerEndDateToShow, setHeaderEndDateToShow] = useState(undefined)

    useEffect(() => {
        data?.event?.map((e) => {
            if (e?.titleEn === data?.status) {
                setHeaderStartDateToShow(e?.startDate?.split('T')[0])
                setHeaderEndDateToShow(e?.endDate?.split('T')[0])
            }
        })
    }, [data])

    const startDay = headerStartDateToShow?.split('-')[2].split('T')[0]
    const startMonth = headerStartDateToShow?.split('-')[1]
    const startYear = headerStartDateToShow?.split('-')[0]
    const endDay = headerEndDateToShow?.split('-')[2].split('T')[0]
    const endMonth = headerEndDateToShow?.split('-')[1]
    const endYear = headerEndDateToShow?.split('-')[0]

    const [manageFund, setManageFund] = useState(null);

    useEffect(() => {
        if (manageFund !== null) {
            sendData(manageFund?.id);
        }
        setManageFund(null)
    }, [manageFund]);

    const handleClickPush = () => {
        dispatch(push(`/admin-indiv-fund-management/${data.id}`))
    };

    const [hasInvestmentIntention, setHasInvestmentIntention] = useState(false)
    const [hasPendingStatus, setHasPendingStatus] = useState(false)

    useEffect(() => {
        if (data && Array.isArray(data.offerPurchase)) {
            setHasInvestmentIntention(data.offerPurchase.some(offer => offer.status === 'Investment Intention'));
            setHasInvestmentIntention(data.offerPurchase.some(offer => offer.status === 'Investment Intention - Waiting List'));
            setHasPendingStatus(data.offerPurchase.some(offer => offer.status.toLowerCase().includes('pending')));
        }
    }, [data]);

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    const validOfferPurchases = data?.offerPurchase?.filter(
        offer => !excludedStatuses?.includes(offer?.status)
    );

    function calculateTotalInvestment(validOfferPurchases) {
        if (validOfferPurchases.length === 0) {
            return 0;
        }

        const totalAmount = validOfferPurchases.reduce(
            (sum, offer) => sum + offer.amount,
            0
        );
        return totalAmount;
    }

    const totalInvestment = calculateTotalInvestment(validOfferPurchases);

    return (
        <>
            <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {data.image !== '' ?
                            <Avatar src={data.image} alt={'LOGO'} sx={{ mr: 1 }} />
                            : <Box sx={{ ml: 6 }}> </Box>
                        }
                        {data.name}
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {data.status}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {headerStartDateToShow !== undefined &&
                            <Typography sx={{ fontSize: '14px' }}>
                                {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}, {startYear} /
                            </Typography>
                        }
                        {headerEndDateToShow !== undefined &&
                            <Typography sx={{ fontSize: '14px', ml: 0.5 }}>
                                {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}, {endYear}
                            </Typography>
                        }
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <LinearProgress thickness={8} variant="determinate" value={(totalInvestment * 100) / data.amountToReach} />
                        <Typography>
                            $ {data.amountToReach.toLocaleString()}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ mt: -2 }}>
                        <CircularProgress
                            thickness={8}
                            sx={{ color: 'gray', zIndex: -1, mt: 1 }}
                            variant="determinate"
                            value={100} />
                        <CircularProgress
                            thickness={8}
                            sx={{ zIndex: 1, ml: -5 }}
                            variant="determinate"
                            value={data.successThreshold} />
                        <Typography
                            sx={{
                                mt: -4.1,
                                fontSize: '10px',
                                zIndex: 2
                            }}>
                            {data.successThreshold}%
                        </Typography>
                    </Box>
                    <Typography sx={{ fontSize: '10px', mt: 2 }}>
                        $ {(data.amountToReach * data.successThreshold / 100).toLocaleString()}
                    </Typography>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {data.status !== 'Draft' ? null :
                        <Link onClick={handleOpenEdit} >
                            {t('MY_OFFERINGS.MODIFY')}
                        </Link>
                    }
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Link onClick={handleOpen}  >
                        {t('OFFERINGS_TABLE.ACT_SEE_MORE')}
                    </Link>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <Button variant='contained' onClick={handleClickPush}>{t('ADMIN.MANAGE')}
                            {hasPendingStatus || hasInvestmentIntention ?
                                <Tooltip title={
                                    <div>
                                        {hasPendingStatus && <Typography>Has Pending Transaction/s</Typography>}
                                        {hasPendingStatus && hasInvestmentIntention && <br />}
                                        {hasInvestmentIntention && <Typography>Has Pending Investment Intention</Typography>}
                                    </div>
                                }>
                                    <PriorityHighIcon fontSize='small' sx={{ color: 'orange', ml: 0.2 }} />
                                </Tooltip> :
                                null
                            }
                        </Button>

                    </Box>
                </TableCell>
            </TableRow >

        </>
    )
}

export default FundAdministrationRow