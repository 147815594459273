import { Button, TableCell, TableRow, TextField, Modal, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { UPDATE_SINGLE_SETTING } from '../../../Queries/Admin'
import { useMutation } from '@apollo/client'
import { showMessage } from '../../../store/actions/snackBarActions'
import { useDispatch } from 'react-redux'

const IndividualSetting = ({ data }) => {
    const { t } = useTranslation()
    const [updateSingleSetting] = useMutation(UPDATE_SINGLE_SETTING)
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);
    const dispatch = useDispatch()
    const [newValue, setNewValue] = useState(undefined);

    const handleUpdateSingleSetting = async () => {
        await updateSingleSetting({
            variables: {
                settingId: data?.id,
                newSettingValue: newValue
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
        await setNewValue(undefined)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    return (
        <>
            <TableRow key={data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center" component="th" scope="row">
                    {data?.settingName}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {data?.settingValue}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <TextField
                        id="outlined-basic"
                        size='small'
                        variant="outlined"
                        value={newValue}
                        onChange={(event) => {
                            setNewValue(event.target.value);
                        }} />
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {newValue === undefined ?
                        <Button variant='contained' disabled>
                            {t('CO_ASSESSMENT.SAVE')}
                        </Button>
                        :
                        <Button variant='contained' onClick={handleOpenConfirmation}>
                            {t('CO_ASSESSMENT.SAVE')}
                        </Button>
                    }
                </TableCell>
            </TableRow>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        <>
                            <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                            <Button variant='contained' onClick={handleUpdateSingleSetting}>{t('CO_ASSESSMENT.SAVE')}</Button>
                        </>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default IndividualSetting