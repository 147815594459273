import { Box, Button, Link, Modal, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react'
import InterestAdminEditModal from './InterestAdminEditModal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'
import { showMessage } from '../../../store/actions/snackBarActions'
import { useMutation } from '@apollo/client';
import { ENABLE_PREFERENCE, DISABLE_PREFERENCE } from '../../../Queries/Admin';



const InterestsAdministrationRow = (data) => {
    const { t } = useTranslation()
    const [disablePreference] = useMutation(DISABLE_PREFERENCE)
    const [enablePreference] = useMutation(ENABLE_PREFERENCE)
    const dispatch = useDispatch()


    const handleDisable = async () => {
        await disablePreference({
            variables: {
                preferenceId: data?.data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Preference disabled!', variant: 'success' }))
    }

    const handleEnable = async () => {
        await enablePreference({
            variables: {
                preferenceId: data?.data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Preference enabled!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    return (
        <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" component="th" scope="row">
                {data?.data?.preferenceName}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <InterestAdminEditModal data={data} />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.data?.isActive
                    ?
                    <Typography sx={{ color: 'green' }}>
                        {t('ADMIN.ENABLED')}
                    </Typography>
                    :
                    <Typography sx={{ color: 'red' }}>
                        {t('ADMIN.DISABLED')}
                    </Typography>
                }
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.data?.isActive
                    ?
                    <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                        {t('ADMIN.ACT_DISABLE')}
                    </Link>
                    :
                    <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                        {t('ADMIN.ACT_ENABLE')}
                    </Link>
                }
            </TableCell>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        {data?.data?.isActive
                            ?
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={handleDisable}>{t('ADMIN.ACT_DISABLE')}</Button>
                            </>
                            :
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={handleEnable}>{t('ADMIN.ACT_ENABLE')}</Button>
                            </>
                        }
                    </Box>
                </Box>
            </Modal>
        </TableRow>
    );
}

export default InterestsAdministrationRow