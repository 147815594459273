// Import necessary dependencies
import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, Typography, InputAdornment, CircularProgress, Modal,
    IconButton, Button, Tooltip,
    useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GET_USER_INFO } from '../../../Queries/Users';
import { GET_ALL_USERS } from '../../../Queries/Admin';
import UserAdministrationRow from './UserAdministrationRow';
import UserAdministrationAddUserModal from './UserAdministrationAddUserModal';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

// Create the component
const UsersAdministration = () => {
    const { t } = useTranslation();
    const { user } = useAuth0();
    // Set initial state for search query
    const [search, setSearch] = React.useState('');
    // Handle change of search query
    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };
    // Set initial state for ordering of results
    const [ordering, setOrdering] = useState({ "memberSince": "DESC" });

    // Use useLazyQuery to get data for logged in user
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } }
    );
    // Use useLazyQuery to get data for all users, based on search query and ordering
    const [filter, setFilter] = useState(['Investor', 'Applicant', 'Core Team'])
    const [activeFilter, setActiveFilter] = useState(undefined)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    const [getAllUsers, { data: allUsersData, loading: allUserDataLoading }] = useLazyQuery(GET_ALL_USERS, {
        variables: {
            search: search,
            order: ordering,
            filter: filter,
            activeFilter: activeFilter
        },
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    });

    // Call use effect hooks to get data for logged in user and all users
    useEffect(() => {
        getUserData();
        getAllUsers();
    }, [getUserData, getAllUsers]);


    const filteredData = allUsersData?.users?.filter(item => {
        const memberSince = item.memberSince.split('T')[0];
        return memberSince >= fromDate && memberSince <= toDate;
    });

    const filteredDataFrom = allUsersData?.users?.filter(item => {
        const memberSince = item.memberSince.split('T')[0];
        return memberSince >= fromDate;
    });


    // Set state for whether logged in user is part of core team
    const [isCoreTeam, setIsCoreTeam] = useState(false);
    // Use use effect hook to check if logged in user is part of core team
    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true);
            }
        });
    });

    const [openFilter, setOpenFilter] = useState(false)
    const handleOpenFilter = () => setOpenFilter(true)
    const handleCloseFilter = () => setOpenFilter(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        p: 3,
    }

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    // If user is part of core team, display the user administration page
    if (isCoreTeam) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        {t('ADMIN.USERS')}
                    </Typography>
                </Box>
                <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '17%' }}>
                            <UserAdministrationAddUserModal data={allUsersData} />
                            <Typography sx={{
                                ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                            }}>
                                {t('ADMIN.ADD_USER')}
                            </Typography>
                        </Box>
                        <TextField
                            id="filled-search"
                            label={t('ADMIN.SEARCH')}
                            size='small'
                            type="search"
                            variant="outlined"
                            onChange={handleTextChange}
                            sx={{ width: '45%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box
                            onClick={handleOpenFilter}
                            sx={{
                                width: '9%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'right',
                                cursor: 'pointer',
                            }}
                        >
                            <FilterListIcon />
                            <Typography
                                sx={{
                                    width: '150px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    ml: 1,
                                }}
                            >
                                {t('OFFERINGS_TABLE.FILTER')}
                                {filter?.length !== 3 ||
                                    fromDate !== '' ||
                                    toDate !== '' ||
                                    activeFilter !== undefined
                                    ?
                                    <Tooltip title={t('FUND_SEARCH_VIEW.FILTER_APPLIED')}>
                                        <InfoIcon fontSize="medium"
                                            sx={{
                                                position: 'absolute',
                                                mt: -0.1,
                                                ml: 1
                                            }} color="primary" />
                                    </Tooltip>
                                    : null}
                            </Typography>
                        </Box>
                    </Box>
                    {allUserDataLoading ? // Check if all user data is still loading
                        // If it's still loading, display a loading spinner
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 8,
                            }}>
                            <CircularProgress />
                        </Box>
                        :
                        // If loading is complete, display a table with user data
                        <TableContainer sx={{ width: '100%', height: '60vh', border: '1px solid gray', borderRadius: '5px' }}>
                            <Table stickyHeader={true} sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            onClick={() => {
                                                if (ordering.userName != 'ASC') {
                                                    setOrdering({ userName: 'ASC' })
                                                }
                                                if (ordering.userName != 'DESC') {
                                                    setOrdering({ userName: 'DESC' })
                                                }
                                            }}
                                            sx={{
                                                backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                cursor: 'pointer',
                                            }} align="center">
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                {t('ADMIN.USERNAME')}
                                                {ordering.userName !== "ASC" && ordering.userName !== "DESC" &&
                                                    <SwapVertIcon />
                                                }
                                                {ordering.userName === "DESC" &&
                                                    <ArrowUpwardIcon />
                                                }
                                                {ordering.userName === "ASC" &&
                                                    <ArrowDownwardIcon />
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => {
                                                if (ordering.email != 'ASC') {
                                                    setOrdering({ email: 'ASC' })
                                                }
                                                if (ordering.email != 'DESC') {
                                                    setOrdering({ email: 'DESC' })
                                                }
                                            }}
                                            sx={{
                                                backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                cursor: 'pointer',
                                            }} align="center">


                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                Email
                                                {ordering.email !== "ASC" && ordering.email !== "DESC" &&
                                                    <SwapVertIcon />
                                                }
                                                {ordering.email === "DESC" &&
                                                    <ArrowUpwardIcon />
                                                }
                                                {ordering.email === "ASC" &&
                                                    <ArrowDownwardIcon />
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.PROFILE')}</TableCell>
                                        <TableCell
                                            onClick={() => {
                                                if (ordering.memberSince != 'ASC') {
                                                    setOrdering({ memberSince: 'ASC' })
                                                }
                                                if (ordering.memberSince != 'DESC') {
                                                    setOrdering({ memberSince: 'DESC' })
                                                }
                                            }}
                                            sx={{
                                                backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                cursor: 'pointer',
                                            }} align="center">
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                {t('ADMIN.MEMBER_SINCE')}
                                                {ordering.memberSince !== "ASC" && ordering.memberSince !== "DESC" &&
                                                    <SwapVertIcon />
                                                }
                                                {ordering.memberSince === "DESC" &&
                                                    <ArrowUpwardIcon />
                                                }
                                                {ordering.memberSince === "ASC" &&
                                                    <ArrowDownwardIcon />
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => {
                                                if (ordering.active != 'ASC') {
                                                    setOrdering({ active: 'ASC' })
                                                }
                                                if (ordering.active != 'DESC') {
                                                    setOrdering({ active: 'DESC' })
                                                }
                                            }}
                                            sx={{
                                                backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d",
                                                cursor: 'pointer',
                                            }} align="center">


                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                {t('ADMIN.STATUS')}
                                                {ordering.active !== "ASC" && ordering.active !== "DESC" &&
                                                    <SwapVertIcon />
                                                }
                                                {ordering.active === "DESC" &&
                                                    <ArrowUpwardIcon />
                                                }
                                                {ordering.active === "ASC" &&
                                                    <ArrowDownwardIcon />
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData?.length < 1
                                        ?
                                        filteredDataFrom?.map((row) => (
                                            <UserAdministrationRow key={row.id} data={row} />
                                        ))
                                        :
                                        filteredData?.map((row) => (
                                            <UserAdministrationRow key={row.id} data={row} />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                            {/* FILTER MODAL */}
                            {/* FILTER MODAL - END */}
                        </TableContainer>
                    }
                </Grid>
                <Modal
                    open={openFilter}
                    onClose={handleCloseFilter}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box
                            onClick={handleOpenFilter}
                            sx={{
                                width: '20%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mb: 2,
                            }}
                        >
                            <FilterListIcon />
                            <Typography
                                sx={{
                                    width: '150px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    ml: 1,
                                }}
                            >
                                {t('OFFERINGS_TABLE.FILTER')}
                            </Typography>
                        </Box>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                mt: -6,
                                mb: 2,
                            }}
                        >
                            <IconButton>
                                <CloseIcon onClick={handleCloseFilter} />
                            </IconButton>
                        </Grid>

                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', mb: 4 }}>
                            <Typography sx={{ mr: 2 }}>{t('PERFIL_CONFIG_VIEW.PERFIL')}</Typography>
                            <Button
                                sx={{ mr: 1 }}
                                variant={filter?.length > 2 ? 'contained' : "outlined"}
                                onClick={() => {
                                    setFilter(['Investor', 'Applicant', 'Core Team'])
                                }}
                            >
                                {t('FUND_SEARCH_VIEW.STATUS_ALL')}
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                variant={filter?.length == 1 && filter[0] === 'Investor' ? 'contained' : "outlined"}
                                onClick={() => {
                                    setFilter(['Investor'])
                                }}
                            >
                                {t('PERFIL_CONFIG_VIEW.ROLE_INVESTOR')}
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                variant={filter[0] === 'Applicant' ? 'contained' : "outlined"}
                                onClick={() => {
                                    setFilter(['Applicant'])
                                }}
                            >
                                {t('PERFIL_CONFIG_VIEW.ROLE_OFFERER')}
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                variant={filter[0] === 'Core Team' ? 'contained' : "outlined"}
                                onClick={() => {
                                    setFilter(['Core Team'])
                                }}
                            >
                                Core Team
                            </Button>
                        </Box>


                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', mb: 4 }}>
                            <Typography sx={{ mr: 2 }}>{t('ADMIN.STATUS')}</Typography>
                            <Button
                                sx={{ mr: 1 }}
                                variant={activeFilter === undefined ? 'contained' : "outlined"}
                                onClick={() => {
                                    setActiveFilter(undefined)
                                }}
                            >
                                {t('FUND_SEARCH_VIEW.STATUS_ALL')}
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                variant={activeFilter === true ? 'contained' : "outlined"}
                                onClick={() => {
                                    setActiveFilter(true)
                                }}
                            >
                                {t('ADMIN.STATUS_ACT')}
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                variant={activeFilter === false ? 'contained' : "outlined"}
                                onClick={() => {
                                    setActiveFilter(false)
                                }}
                            >
                                {t('ADMIN.STATUS_BLO')}
                            </Button>
                        </Box>


                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                            <Typography sx={{ mr: 2 }}>{t('ADMIN.MEMBER_SINCE')}</Typography>
                            <TextField
                                type="date"
                                variant="outlined"
                                value={fromDate}
                                onChange={(e) => {
                                    setFromDate(e.target.value)
                                }}
                            />
                            <IconButton onClick={() => { setFromDate('') }} size='small' sx={{ mr: 1 }}>X</IconButton>
                            <TextField
                                type="date"
                                variant="outlined"
                                value={toDate}
                                onChange={(e) => {
                                    setToDate(e.target.value)
                                }}
                            />
                            <IconButton onClick={() => { setToDate('') }} size='small' sx={{ mr: 1 }}>X</IconButton>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        );
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default UsersAdministration