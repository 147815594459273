import React from "react"
import { Fragment, useEffect, useState } from "react"
import Card from "../../../../components/Card/Card"
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, CircularProgress, useTheme } from "@mui/material"
import { Typography, Grid } from "@mui/material"
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { PieChart, Pie, CartesianGrid, XAxis, YAxis, Bar, Legend, ResponsiveContainer, Tooltip, BarChart } from 'recharts';
import { useAuth0 } from "@auth0/auth0-react"
import { GET_ALL_MY_TXS } from './../../../../Queries/Transactions';
import { useLazyQuery } from "@apollo/client"
import { GET_TOKENS } from '../../../../Queries/Wallet'
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from 'swiper'
const axios = require('axios')

const InvestorMetricsDashboard = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { user } = useAuth0()

    const [getAllTx, { data: myTx, loading: dataloading }] = useLazyQuery(GET_ALL_MY_TXS, {
        variables: { userId: user?.email || user?.sub },
        pollInterval: 10000
    })

    useEffect(() => {
        getAllTx()
    }, [getAllTx])

    const [oneConfirmed, setOneConfirmed] = useState(false);

    useEffect(() => {
        myTx?.offerPurchases?.map((tx) => {
            if (tx.status !== 'Investment Intention') {
                setOneConfirmed(true)
            }
        })
    }, [myTx])

    function calculateTotal(offerPurchases) {
        let total = 0;

        for (let i = 0; i < offerPurchases?.length; i++) {
            total += offerPurchases[i].amount;
        }
        return total;
    }

    const totalAmount = calculateTotal(myTx?.offerPurchases);

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    function calculateTotalPerFund(offerPurchases) {
        const totals = {};

        for (let i = 0; i < offerPurchases?.length; i++) {
            const fundName = offerPurchases[i].fund.name;
            const amount = offerPurchases[i].amount;

            if (totals[fundName]) {
                totals[fundName] += amount;
            } else {
                totals[fundName] = amount;
            }
        }

        const possibleColors = actualThemeLight ? ["#444054", "#ff0164", "#66ff66", "#14cfff", "#f4c211"] : ["#373347", "#cc0150", "#52cc52", "#109fcc", "#c49b0e"]
        const result = [];

        for (let i = 0; i < possibleColors.length; i++) {
            const fundName = Object.keys(totals)[i];
            if (!fundName) break;

            const color = possibleColors[i];
            result.push({ name: fundName, total: totals[fundName], fill: color });
        }
        return result;
    }

    const [totalPerFund, setTotalPerFund] = useState(undefined)

    useEffect(() => {
        setTotalPerFund(calculateTotalPerFund(myTx?.offerPurchases))
    }, [actualThemeLight, myTx])

    function reorganizeData(inputData) {
        const reorganizedData = [];

        if (inputData) {
            const uniqueFunds = new Set();
            const possibleColors = actualThemeLight ? ["#babbff", "#f4c211", "#7779ff", "#6b48ff"] : ["#9696cc", "#c49b0e", "#6061cc", "#5538cc"];

            for (let i = 0; i < inputData.length; i++) {
                const item = inputData[i];
                const fundName = item?.fund?.name;

                if (!uniqueFunds.has(fundName)) {
                    const color = possibleColors[i % possibleColors.length];

                    const newItem = {
                        name: fundName,
                        Multiplier: item?.fund?.roiMultiplier?.split(' ')[0].split('x')[0],
                        MultiText: item?.fund?.roiMultiplier?.split(' ')[0].split('x')[0] + 'X',
                        ROI: item?.fund?.roiTime?.split(' ')[0] * 12,
                        ROIText: item?.fund?.roiTime?.split(' ')[0] + ' months',
                        fill: color
                    };

                    reorganizedData.push(newItem);
                    uniqueFunds.add(fundName);
                }
            }
        }

        return reorganizedData;
    }

    const [data, setData] = useState(undefined)

    useEffect(() => {
        setData(reorganizeData(myTx?.offerPurchases))
    }, [actualThemeLight, myTx])


    const [getAllTokens, { data: tokensData, loading: tokensLoading }] = useLazyQuery(GET_TOKENS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllTokens()
    }, [getAllTokens])

    const blockFrostAPITest = `https://cardano-preprod.blockfrost.io/api/v0/assets/`
    const testProjectID = 'preprod0YDs6YpK8cM5KFJA7bTZbOAmWVW0D94s'
    const axiosBFClient = axios.create({
        baseURL: blockFrostAPITest,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            project_id: testProjectID,
        },
    })

    const dvcObject = tokensData?.tokens?.find(item => item.symbol === "DVC");
    const [mintedDVC, setMintedDVC] = useState(undefined)
    const policyIdToQueryDVC = dvcObject?.policyId + dvcObject?.encodedName

    function getMintedDVC() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQueryDVC}/history`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (policyIdToQueryDVC.length > 7) {
            getMintedDVC().then((res) => {
                setMintedDVC(res)
            })
        }
    }, [policyIdToQueryDVC])

    function sumMintedAmountsDVC(data) {
        if (!data || !Array.isArray(data)) {
            return 0;
        }

        let totalAmount = 0;
        for (const item of data) {
            if (item && item.action === "minted") {
                totalAmount += parseInt(item.amount);
            }
        }
        return totalAmount;
    }

    const totalMintedAmountDVC = sumMintedAmountsDVC(mintedDVC);
    const tstALLIObject = tokensData?.tokens?.find(item => item.symbol === "tstALLI");
    const policyIdToQuerytstAlli = tstALLIObject?.policyId + tstALLIObject?.encodedName
    const [mintedtstALLI, setMintedtestALLI] = useState(undefined)


    function getMintedtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/history`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (policyIdToQuerytstAlli.length > 7) {
            getMintedtstAlli().then((res) => {
                setMintedtestALLI(res)
            })
        }
    }, [policyIdToQuerytstAlli])

    function sumMintedAmountstAlli(data) {
        if (!data || !Array.isArray(data)) {
            return 0;
        }

        let totalAmount = 0;
        for (const item of data) {
            if (item && item.action === "minted") {
                totalAmount += parseInt(item.amount);
            }
        }
        return totalAmount;
    }

    const totalMintedAmountTstAlli = sumMintedAmountstAlli(mintedtstALLI);


    const [percentHoldingAlli, setPercentHoldingAlli] = useState(undefined)

    const excludedAddress = "addr_test1qq4hg23ralkz9wzv9tn9kd2c2rxrzxckz8fecrdmrc8qltcd7r8mfgmnp3eaaasrds0fd42feweqvwxk8qqk2sy60wus7aaykm";

    const [totalQuantityHoldersTstAllies, setTotalQuantityHoldersTstAllies] = useState(0);

    const [addressesHoldingtstAlli, setAddressesHoldingtstAlli] = useState(undefined)

    function getMCtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/addresses`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (policyIdToQuerytstAlli.length > 7) {
            getMCtstAlli().then((res) => {
                setAddressesHoldingtstAlli(res)
            })
        }
    }, [policyIdToQuerytstAlli])

    useEffect(() => {
        const filteredArray = addressesHoldingtstAlli?.filter(item => item.address !== excludedAddress);
        const sum = filteredArray?.reduce((acc, curr) => acc + parseInt(curr.quantity, 10), 0);
        setTotalQuantityHoldersTstAllies(sum);
    }, [addressesHoldingtstAlli, excludedAddress]);


    useEffect(() => {
        setPercentHoldingAlli((totalQuantityHoldersTstAllies / totalMintedAmountTstAlli) * 100)
    }, [totalQuantityHoldersTstAllies, totalMintedAmountTstAlli])

    const tooltipFormatter = (value) => `$${value.toLocaleString()}`;

    return <Fragment>
        <Card color='#FFF' sx={{ backgroundColor: '#fff' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* METRICS */}
                {dataloading && <Grid container spacing={1}>
                    <Box sx={{ width: '100%', height: '31vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                </Grid>}
                {!dataloading &&
                    <Grid container spacing={1}>
                        {myTx?.offerPurchases?.length === 0 ?
                            <Box sx={{ width: '100%', height: '31vh', display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <Box sx={{ display: 'flex', width: { md: '30%', xs: '90%' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(154, 29, 76, 0.3)', p: { md: 1, xs: 0.2 }, borderRadius: '20px' }} >
                                    <Avatar sx={{ width: { md: 50, xs: 25 }, height: { md: 50, xs: 25 } }} src={dvcObject?.logo}></Avatar>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{dvcObject?.symbol}</Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('FUND.MINTED_TOKENS')}:</Typography>
                                            <Typography sx={{ fontSize: '14px', ml: 1 }} >{dvcObject?.symbol === "DVC" ? totalMintedAmountDVC.toLocaleString() : 0}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                            <Typography sx={{ fontSize: '14px', ml: 1 }} >$ {dvcObject?.marketprice}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: { md: '30%', xs: '90%' }, backgroundColor: 'rgba(154, 29, 76, 0.3)', p: { md: 1, xs: 0.2 }, borderRadius: '20px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '60%' }} >
                                        <Avatar sx={{ width: { md: 50, xs: 25 }, height: { md: 50, xs: 25 } }} src={tstALLIObject?.logo}></Avatar>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{tstALLIObject?.symbol}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('FUND.MINTED_TOKENS')}:</Typography>
                                                <Typography sx={{ fontSize: '14px', ml: 1 }} >{tstALLIObject?.symbol === "tstALLI" ? totalQuantityHoldersTstAllies?.toLocaleString() : 0}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                                <Typography sx={{ fontSize: '14px', ml: 1 }} >$ {tstALLIObject?.marketprice}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {percentHoldingAlli !== undefined && !isNaN(percentHoldingAlli) &&
                                        <Box sx={{ width: '80px', height: '80px' }}>
                                            <CircularProgress
                                                sx={{
                                                    position: 'absolute',
                                                    zIndex: 10,
                                                    color: '#808080',
                                                }}
                                                size={80}
                                                thickness={22}
                                                variant="determinate"
                                                value={100}
                                            />
                                            <CircularProgress
                                                sx={{
                                                    position: 'absolute',
                                                    zIndex: 30,
                                                    color: '#9A1D4C',
                                                }}
                                                size={80}
                                                thickness={22}
                                                variant="determinate"
                                                value={percentHoldingAlli < 1 ? percentHoldingAlli * 10 : percentHoldingAlli}
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 50, position: 'absolute', mt: '24px', ml: '14px', color: 'white' }}>
                                                <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                                                    {percentHoldingAlli.toFixed(2)}%
                                                </Typography>
                                                <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                                                    Marketcap
                                                </Typography>
                                            </Box>
                                        </Box>}
                                </Box>
                            </Box>
                            :
                            !oneConfirmed ?
                                <Grid item xs={12} md={8} sx={{ height: '31vh' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        {/* NO POSTULATIONS */}
                                        <Typography sx={{ fontSize: '16px', color: '#9A1D4C', pr: '4px', fontWeight: 'bold' }}>
                                            {t('DASHBOARD_VIEW.NOT_CONFIRMED_YET')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item xs={12} md={8} >
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        {/* TOTAL RECEIVED */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px', color: '#828282', pr: '4px'
                                                }}
                                            >
                                                {t('DASHBOARD_VIEW.TOTAL_INVESTED')}: ${totalAmount.toLocaleString()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* PIE CHART */}

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '200px', width: '100%' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart width={200} height={200}>
                                                <Pie
                                                    dataKey="total"
                                                    isAnimationActive={true}
                                                    nameKey="name"
                                                    data={totalPerFund}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={68}
                                                    fill="#14cfff"
                                                    label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
                                                        const RADIAN = Math.PI / 180;
                                                        const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                                        return (
                                                            <text
                                                                x={x}
                                                                y={y}
                                                                fill="#8884d8"
                                                                textAnchor={x > cx ? 'start' : 'end'}
                                                                dominantBaseline="central"
                                                            >
                                                                ${value.toLocaleString()}
                                                            </text>
                                                        );
                                                    }}
                                                />
                                                <Tooltip formatter={tooltipFormatter} />
                                            </PieChart>
                                        </ResponsiveContainer>
                                        <BarChart width={200} height={200} data={data}>
                                            <CartesianGrid strokeDasharray="1 1" />
                                            <XAxis dataKey="name" fontSize={8} />
                                            <YAxis fontSize={8} />
                                            <Tooltip />
                                            <Legend align="right" iconSize={8} height={12} />
                                            <Bar name={t('ADMIN.DURATION_MONTHS')} maxBarSize={15} dataKey="ROI" fill="#f4c211" />
                                        </BarChart>
                                        <BarChart width={200} height={200} data={data}>
                                            <CartesianGrid strokeDasharray="1 1" />
                                            <XAxis dataKey="name" fontSize={8} />
                                            <YAxis fontSize={8} />
                                            <Tooltip />
                                            <Legend align="right" iconSize={8} height={12} />
                                            <Bar name={t('ADMIN.EXPECTED_ROI_MULTI')} maxBarSize={15} dataKey="Multiplier" fill="#7779ff" />
                                        </BarChart>
                                    </Box>
                                </Grid>
                        }
                        {myTx?.offerPurchases?.length > 0 ?
                            <>
                                <Grid item md={1} />
                                <Grid item xs={12} md={3}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: 'rgba(154, 29, 76, 0.3)',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '20px',
                                            justifyContent: 'center'
                                        }}>
                                        {tokensLoading ?
                                            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                                                <CircularProgress />
                                            </Box>
                                            :
                                            <Swiper
                                                loop={true}
                                                spaceBetween={0}
                                                slidesPerView={1}
                                                modules={[Navigation, Pagination]}
                                                navigation={true}
                                                grabCursor={true}
                                                className="recommended tokens slider"
                                            >
                                                <SwiperSlide>
                                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: { md: 0, xs: 1 }, mb: { md: 0, xs: 1 } }} >
                                                        <Avatar sx={{ width: 42, height: 42 }} src={dvcObject?.logo}></Avatar>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{dvcObject?.symbol}</Typography>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Typography>{t('FUND.MINTED_TOKENS')}:</Typography>
                                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>{dvcObject?.symbol === "DVC" ? totalMintedAmountDVC?.toLocaleString() : 0}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Typography>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>$ {dvcObject?.marketprice}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: { md: 0, xs: 1 }, mb: { md: 0, xs: 1 } }} >
                                                        <Avatar sx={{ width: 42, height: 42 }} src={tstALLIObject?.logo}></Avatar>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{tstALLIObject?.symbol}</Typography>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Typography>{t('FUND.MINTED_TOKENS')}:</Typography>
                                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>{tstALLIObject?.symbol === "tstALLI" ? totalQuantityHoldersTstAllies?.toLocaleString() : 0}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Typography>{t('OFFERINGS_TABLE.TOKEN_PRICE')}:</Typography>
                                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>$ {tstALLIObject?.marketprice}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </SwiperSlide>

                                            </Swiper>
                                        }
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                {myTx?.offerPurchases?.length > 0 && <>
                                    <Grid item md={1} />
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}>
                                        <Box sx={{
                                            backgroundColor: 'rgba(154, 29, 76, 0.3)',
                                            borderRadius: '10px',
                                            width: '100%',
                                            height: '60%',
                                            mt: '15%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            p: 2,
                                            textAlign: 'center'
                                        }}>
                                            <Typography>{t('REGISTER_VIEW.CLICK_HERE_CONNECT_WALLET')}</Typography>
                                            <Button onClick={() => {
                                                dispatch(push('/profile-settings'))
                                            }} sx={{ mt: 1 }} variant="contained">{t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT').split(' ')[0]}</Button>
                                        </Box>
                                    </Grid>
                                </>}
                            </>
                        }
                    </Grid>}
            </Box>
        </Card >
    </Fragment >
}

export default InvestorMetricsDashboard