import { Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { GET_ALL_SETTINGS, GET_ALL_COUNTRIES, GET_ALL_FUND_OPTIONS } from '../../../Queries/Admin';
import { useLazyQuery } from '@apollo/client';
import IndividualSetting from './IndividualSetting';
import IndividualCountry from './IndividualCountry';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import IndividualWalletInfo from './IndividualWalletInfo';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminSettings = () => {

    const [getAllPurposes, { data: settingsData, loading: dataLoadingSettings }] = useLazyQuery(GET_ALL_SETTINGS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    const [getAllFundOptions, { data: fundOptionsData, loading: dataLoadingFundOptions }] = useLazyQuery(GET_ALL_FUND_OPTIONS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    const [getAllCountries, { data: countriesData, loading: dataLoadingCountries }] = useLazyQuery(GET_ALL_COUNTRIES, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllPurposes()
        getAllCountries()
        getAllFundOptions()
    }, [getAllPurposes, getAllCountries, getAllFundOptions]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const ValidatorWalletList = process.env.REACT_APP_TOKEN_WALLET
    const CoreTeamWalletList = process.env.REACT_APP_CORE_TEAM_WALLET

    const [validatorWalletArray, setValidatorWalletArray] = useState(undefined)
    const [CoreTeamWalletArray, setCoreTeamWalletArray] = useState(undefined)

    useEffect(() => {
        setValidatorWalletArray(ValidatorWalletList?.split(','))
        setCoreTeamWalletArray(CoreTeamWalletList?.split(','))
    }, [ValidatorWalletList])

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
            <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        {t('ADMIN.SETTINGS_ADMINISTRATION')}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Parameters" {...a11yProps(0)} />
                            <Tab label="Funds Origin" {...a11yProps(1)} />
                            <Tab label="Countries" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        {dataLoadingSettings ?
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 2
                                }}>
                                <CircularProgress />
                            </Box>
                            :
                            <TableContainer sx={{ width: '100%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                                <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.NAME')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.CURRENT_VALUE')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.NEW_VALUE')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {settingsData?.settingProperties?.map((setting) => (
                                            <IndividualSetting key={setting.id} data={setting} />
                                        ))}
                                        {CoreTeamWalletArray?.map((walletAddrress) => (
                                            <IndividualWalletInfo key={walletAddrress} text={"Core Team Wallet"} data={walletAddrress} />
                                        ))}
                                        {validatorWalletArray?.map((walletAddrress) => (
                                            <IndividualWalletInfo key={walletAddrress} text={"Validator Wallet"} data={walletAddrress} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {dataLoadingFundOptions ?
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 2
                                }}>
                                <CircularProgress />
                            </Box>
                            :
                            <TableContainer sx={{ width: '100%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                                <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.NAME')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.CURRENT_VALUE')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.NEW_VALUE')}</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fundOptionsData?.settingProperties?.map((setting) => (
                                            <IndividualSetting key={setting.id} data={setting} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        {dataLoadingCountries ?
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 2
                                }}>
                                <CircularProgress />
                            </Box>
                            :
                            <TableContainer sx={{ width: '100%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                                <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Country Code</TableCell>
                                            <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.NAME')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {countriesData?.countries?.map((country) => (
                                            <IndividualCountry key={country.id} data={country} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </CustomTabPanel>
                </Box>
            </Grid >

        </Box >
    )
}

export default AdminSettings