import { CircularProgress, Grid, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ADACard({ name, symbol, amount, img }) {
    const { t } = useTranslation()

    const dataContainer = {
        display: 'flex',
        flexDirection: { md: 'column', xs: 'row' },
        alignItems: 'center',
        justifyContent: { xs: 'center' }
    }

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Grid
            container
            component={Paper}
            sx={{
                p: 1,
                border: '2px solid var(--borderGray)',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: { xs: '100%', md: '100%' },
                maxWidth: { xs: 'none', md: '450px' },
                height: { xs: 'none', md: '80px' },
                borderRadius: '15px',
                backgroundColor: actualThemeLight ? 'white' : '#1d1d1d'
            }}
        >
            <Grid item xs={12} md={4} sx={dataContainer}>
                <img
                    src={img}
                    alt={name}
                    style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'scale-down',
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4} sx={dataContainer}>
                <Typography fontWeight="bold">{name}</Typography>
                <Typography sx={{ ml: { xs: 1 } }}>{symbol}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={dataContainer}>
                {amount === null
                    ?
                    <CircularProgress size={24} />
                    :
                    <>
                        <Typography fontWeight="bold">{t('WALLET_VIEW.AMOUNT')}</Typography>
                        <Typography sx={{ ml: { xs: 1 } }}>{amount}</Typography>
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default ADACard