import React, { useState } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { showMessage } from '../../../store/actions/snackBarActions'
import { GET_FUND_PURPOSE_BY_ID, EDIT_FUND_PURPOSE } from '../../../Queries/Admin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from "react-router"
import { useEffect } from 'react';
import Preloader from '../../preloader/Preloader';

const FundPurposeEdit = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id } = useParams()

    const [titleEn, setTitleEn] = useState('')
    const [titleEs, setTitleEs] = useState('')
    const [titlePt, setTitlePt] = useState('')
    const [targetEn, setTargetEn] = useState('')
    const [targetEs, setTargetEs] = useState('')
    const [targetPt, setTargetPt] = useState('')

    const [fetchPurpose, { data: purposeById, loading }] = useLazyQuery(
        GET_FUND_PURPOSE_BY_ID,
        {
            variables: { purposeId: id },
            pollInterval: 1500
        }
    )

    useEffect(() => {
        fetchPurpose()
    }, [fetchPurpose])

    useEffect(() => {
        if (purposeById !== undefined) {
            setTitleEn(purposeById?.fundPurposes[0]?.titleEn)
            setTitleEs(purposeById?.fundPurposes[0]?.titleEs)
            setTitlePt(purposeById?.fundPurposes[0]?.titlePt)
            setTargetEn(purposeById?.fundPurposes[0]?.targetEn)
            setTargetEs(purposeById?.fundPurposes[0]?.targetEs)
            setTargetPt(purposeById?.fundPurposes[0]?.targetPt)
        }
    }, [purposeById])

    const [updatePurpose] = useMutation(EDIT_FUND_PURPOSE)

    const handleSave = async () => {
        await updatePurpose({
            variables: {
                purposeId: id,
                updateData: {
                    titleEn: titleEn,
                    titleEs: titleEs,
                    titlePt: titlePt,
                    targetEn: targetEn,
                    targetEs: targetEs,
                    targetPt: targetPt
                }
            }
        })
        await dispatch(push('/admin-fund-purpose'))
        await dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
    }

    if (loading) return <Preloader />

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 6
            }}>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "left"
                    }}>
                    <Box
                        onClick={() => {
                            dispatch(push('/admin-fund-purpose'))
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                        <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                    </Box>
                    <Box sx={{ ml: 4 }}>
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}>
                            {t('ADMIN.EDIT_FUND_PURPOSE')}
                        </Typography>
                    </Box>
                </Box>
            </Grid >
            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        English Title
                    </Typography>
                    <TextField size='small'
                        value={titleEn}
                        onChange={(e) => setTitleEn(e.target.value)}
                    />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Titulo en Español
                    </Typography>
                    <TextField size='small'
                        value={titleEs}
                        onChange={(e) => setTitleEs(e.target.value)} />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Titulo em portugues
                    </Typography>
                    <TextField size='small'
                        value={titlePt}
                        onChange={(e) => setTitlePt(e.target.value)} />
                </Grid>
            </Grid>

            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    mt: 3
                }}>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        English Target
                    </Typography>
                    <TextField size='small'
                        multiline
                        rows={4}
                        value={targetEn}
                        onChange={(e) => setTargetEn(e.target.value)}
                    />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Target en Español
                    </Typography>
                    <TextField size='small'
                        multiline
                        rows={4}
                        value={targetEs}
                        onChange={(e) => setTargetEs(e.target.value)} />
                </Grid>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography sx={{ mr: 2 }}>
                        Target em portugues
                    </Typography>
                    <TextField size='small'
                        multiline
                        rows={4}
                        value={targetPt}
                        onChange={(e) => setTargetPt(e.target.value)} />
                </Grid>
            </Grid>

            {titleEn === '' ||
                titleEs === '' ||
                titlePt === '' ||
                targetEn === '' ||
                targetEs === '' ||
                targetPt === ''
                ?
                <Button
                    sx={{ m: 3 }}
                    disabled variant='contained'>
                    {t('CO_ASSESSMENT.SAVE')}
                </Button> :
                <Button
                    sx={{ m: 3 }}
                    onClick={handleSave}
                    variant='contained'>
                    {t('CO_ASSESSMENT.SAVE')}
                </Button>}
        </Box >
    )
}

export default FundPurposeEdit