import {
    Box, Button, CircularProgress, Dialog, DialogContent, IconButton,
    InputAdornment, Switch, ListItemText, MenuItem, Select, Autocomplete,
    FormControlLabel, Checkbox, Modal, LinearProgress, Slide, TextField,
    Typography,
    useTheme
} from '@mui/material'
import React from 'react'
import { push } from 'connected-react-router'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Address, TransactionUnspentOutput } from '@emurgo/cardano-serialization-lib-asmjs'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_DVC_POLICY } from '../../Queries/Tokens'
import { CREATE_OFFER_PURCHASE } from '../../Queries/OfferPurchases'
import { UPDATE_USER_LEGAL_INFO, GET_USER_LEGAL_INFO } from '../../Queries/Users'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../store/actions/snackBarActions'
import { GET_COUNTRIES } from '../../Queries/Countries'
import { GET_ALL_FUND_OPTIONS } from '../../Queries/Funds'
import i18n from "i18next";
import { GET_CORE_TEAM_USERS } from '../../Queries/Users'
import { makeStyles } from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles({
    placeholder: {
        color: 'black'
    },
    input: {
        color: 'black',
    },
});

const InvestModal = ({ open, handleClose, minInvest, maxInvest, txFundId, raised, target, token, data }) => {
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const { t } = useTranslation()
    const [amountToInvest, setAmountToInvest] = useState('')
    const dispatch = useDispatch()
    const { user } = useAuth0()
    const classes = useStyles();
    const closeModal = () => {
        handleClose()
    }

    const [walletEnabled, setWalletEnabled] = useState(false)
    const [wallets, setWallets] = useState([])
    const [noWallet, setNoWallet] = useState(false)
    const [openDetail, setOpenDetail] = useState(false);
    const handleOpenDetail = () => setOpenDetail(true);
    const handleCloseDetail = () => setOpenDetail(false);

    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])


    function noEnabledWallet() {
        setTimeout(function () {
            setNoWallet(true);
        }, 5000);
    }

    noEnabledWallet()

    const [createOfferPurchase] = useMutation(CREATE_OFFER_PURCHASE)
    const [updateUserLegalInfo] = useMutation(UPDATE_USER_LEGAL_INFO)
    const [getUserLegalInfo, { data: userLegalInfo }] = useLazyQuery(GET_USER_LEGAL_INFO, { variables: { userId: user?.email || user?.sub } })

    useEffect(() => {
        getUserLegalInfo()
    }, [getUserLegalInfo])

    const [XChangeAddress, setXChangeAddress] = useState(undefined)
    const [API, setAPI] = useState(null)
    const [Utxos, setUtxos] = useState(undefined)
    const [hasInvestorToken, setHasInvestorToken] = useState(undefined)
    const [getDVCData, { data: DVCdata }] = useLazyQuery(GET_DVC_POLICY)

    useEffect(() => {
        getDVCData()
    }, [])

    const [selectedWallet, setSelectedWallet] = useState(undefined)

    async function checkEnabledStatus(walletNames) {
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}:`, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );

        setWalletEnabled(anyEnabled);
    }

    function getLocalStorageField(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    const [localStorageWallet, setLocalStorageWallet] = useState(getLocalStorageField('wallet'));

    useEffect(() => {
        const interval = setInterval(() => {
            setLocalStorageWallet(getLocalStorageField('wallet'));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (localStorageWallet !== null) {
            checkEnabledStatus(wallets)
            const intervalId = setInterval(() => {
                checkEnabledStatus(wallets)
            }, 5000)

            return () => clearInterval(intervalId)
        }
    }, [wallets, localStorageWallet])

    const enableWallet = async () => {
        const walletKey = selectedWallet
        try {
            setAPI(await window.cardano[walletKey].enable())
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return checkIfWalletEnabled()
    }

    useEffect(() => {
        if (selectedWallet !== undefined) {
            enableWallet()
        }
    }, [selectedWallet, localStorageWallet])

    const checkIfWalletEnabled = async () => {
        let walletIsEnabled = false
        try {
            const walletName = localStorageWallet
            walletIsEnabled = await window.cardano[walletName].isEnabled()
            setWalletEnabled(walletIsEnabled)
        } catch (err) {
            //Err
            setSelectedWallet(undefined)
        }
        return walletIsEnabled
    }

    useEffect(() => {
        if (localStorageWallet !== null) {
            checkEnabledStatus(wallets)
            const intervalId = setInterval(() => {
                checkEnabledStatus(wallets)
            }, 5000)

            return () => clearInterval(intervalId)
        }
    }, [wallets, localStorageWallet])

    const investorTokenPolicy = DVCdata?.tokens[0]?.policyId

    function findAmountByInvestorTokenPolicy(data, investorTokenPolicy) {
        if (
            !Array.isArray(data) ||
            typeof investorTokenPolicy !== 'string' ||
            !investorTokenPolicy.trim()
        ) {
            return null;
        }

        let totalAmount = 0;

        for (const item of data) {
            if (
                item.multiAssetStr &&
                item.multiAssetStr.includes(investorTokenPolicy)
            ) {
                const amountMatch = item.multiAssetStr.match(/\+ (\d+) \+.*\s\((.*?)\)/);
                if (amountMatch) {
                    const amount = parseInt(amountMatch[1]);
                    totalAmount += amount;
                }
            }
        }

        return totalAmount !== 0 ? totalAmount : null;
    }

    const DVCamount = findAmountByInvestorTokenPolicy(Utxos, investorTokenPolicy)

    useEffect(() => {
        if (DVCamount >= 1) {
            setHasInvestorToken(true)
        } else {
            setHasInvestorToken(false)
        }
    }, [DVCamount])

    const currFundTokenPolicy = token?.policyId
    const currFundTokenAmount = findAmountByInvestorTokenPolicy(Utxos, currFundTokenPolicy)

    const handleBuyClick = () => {
        const url = 'https://tokenallies.com/#DVC'
        window.open(url, '_blank')
    }

    const getUtxos = async () => {
        let utxos = []
        if (API !== null) {
            try {
                const rawUtxos = await API?.getUtxos()

                for (const rawUtxo of rawUtxos) {
                    const utxo = TransactionUnspentOutput.from_bytes(
                        Buffer.from(rawUtxo, 'hex')
                    )
                    const input = utxo.input()
                    const txid = Buffer.from(
                        input.transaction_id().to_bytes(),
                        'utf8'
                    ).toString('hex')
                    const txindx = input.index()
                    const output = utxo.output()
                    const amount = output.amount().coin().to_str()
                    const multiasset = output.amount().multiasset()
                    let multiAssetStr = ''

                    if (multiasset) {
                        const keys = multiasset.keys()
                        const N = keys.len()

                        for (let i = 0; i < N; i++) {
                            const policyId = keys.get(i)
                            const policyIdHex = Buffer.from(
                                policyId.to_bytes(),
                                'utf8'
                            ).toString('hex')

                            const assets = multiasset.get(policyId)
                            const assetNames = assets.keys()
                            const K = assetNames.len()

                            for (let j = 0; j < K; j++) {
                                const assetName = assetNames.get(j)
                                const assetNameString = Buffer.from(
                                    assetName.name(),
                                    'utf8'
                                ).toString()
                                const assetNameHex = Buffer.from(
                                    assetName.name(),
                                    'utf8'
                                ).toString('hex')
                                const multiassetAmt = multiasset.get_asset(policyId, assetName)
                                multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                            }
                        }
                    }

                    const obj = {
                        txid: txid,
                        txindx: txindx,
                        amount: amount,
                        str: `${txid} #${txindx} = ${amount}`,
                        multiAssetStr: multiAssetStr,
                        TransactionUnspentOutput: utxo,
                    }
                    utxos.push(obj)
                }
                setUtxos(utxos)
            } catch (err) {
                setSelectedWallet(undefined)
                //Err
            }
        }
    }

    const getChangeAddress = async () => {
        if (API !== null) {
            try {
                const raw = await API.getChangeAddress()
                const changeAddressObj = Address.from_bytes(
                    Buffer.from(raw, 'hex')
                ).to_bech32()
                setXChangeAddress(changeAddressObj)
            } catch (err) {
                setSelectedWallet(null)
            }
        }
    }

    const [netId, setNetId] = useState(undefined)
    const appropiateNetworkIdIdentified = 0
    const [isCorrectNetworkSelected, setIsCorrectNetworkSelected] = useState(null)

    useEffect(() => {
        if (netId !== undefined) {
            if (appropiateNetworkIdIdentified === netId) {
                setIsCorrectNetworkSelected(true)
            } else {
                setIsCorrectNetworkSelected(false)
            }
        }
    }, [appropiateNetworkIdIdentified, netId])


    const getNetId = async () => {
        if (API !== null) {
            try {
                const id = await API.getNetworkId()
                setNetId(id)
            } catch (err) {
                setNetId(undefined)
            }
        }
    }

    function deleteLocalStorageField() {
        localStorage.removeItem('wallet')
    }

    const refreshData = async () => {
        try {
            if (walletEnabled && API !== null) {
                await getNetId()
                await getChangeAddress()
                await getUtxos()
            }
        } catch (err) {
            setSelectedWallet(null)
            deleteLocalStorageField()
            //Err
        }
    }

    useEffect(() => {
        refreshData()
        const intervalId = setInterval(() => {
            if ((XChangeAddress !== undefined || API !== null) && walletEnabled) {
                refreshData()
            }
        }, 5000)

        return () => clearInterval(intervalId)
    }, [XChangeAddress, API, walletEnabled, localStorageWallet])


    const [creatingTx, setCreatingTx] = useState(false)

    const [declarationFullLegalName, setDeclarationFullLegalName] = useState('')
    const [declarationID, setDeclarationID] = useState('')
    const [declarationCountry, setDeclarationCountry] = useState('')
    const [declarationFundsOrigin, setDeclarationFundsOrigin] = useState([])
    const [concatenatedFundsOrigin, setConcatenatedFundsOrigin] = useState('')

    useEffect(() => {
        setConcatenatedFundsOrigin(declarationFundsOrigin?.join(', '))
    }, [declarationFundsOrigin])

    useEffect(() => {
        if (userLegalInfo?.users[0]?.legalId !== null && declarationID === '') {
            setDeclarationID(userLegalInfo?.users[0]?.legalId)
        }
        if (userLegalInfo?.users[0]?.legalCountry !== null && declarationCountry === '') {
            setDeclarationCountry(userLegalInfo?.users[0]?.legalCountry)
        }
        if (userLegalInfo?.users[0]?.legalFullname !== null && declarationFullLegalName === '') {
            setDeclarationFullLegalName(userLegalInfo?.users[0]?.legalFullname)
        }
        if (userLegalInfo?.users[0]?.legalFundsOrigin !== null) {
            const splitAppart = userLegalInfo?.users[0]?.legalFundsOrigin?.split(', ')
            setDeclarationFundsOrigin(splitAppart)
        }
    }, [userLegalInfo])


    const [getCoreTeam, { data: coreTeamData }] =
        useLazyQuery(GET_CORE_TEAM_USERS)

    useEffect(() => {
        getCoreTeam()
    }, [getCoreTeam])

    function extractIds(usersArray) {
        return usersArray?.map((user) => user?.id)
    }

    // eslint-disable-next-line
    const pureCoreTeamIds = extractIds(coreTeamData?.users)

    async function createTransaction() {
        try {
            setCreatingTx(true);
            const newPurchaseID = uuidv4()
            const dateFormatted = new Date()

            await createOfferPurchase({
                variables: {
                    userID: user?.email || user?.sub,
                    fundID: txFundId,
                    purchaseID: newPurchaseID,
                    amount: parseFloat(amountToInvest),
                    date: dateFormatted,
                    price: parseFloat(amountToInvest),
                    status: "Investment Intention",
                    investorAddress: XChangeAddress,
                    legalFullname: declarationFullLegalName,
                    legalId: declarationID,
                    legalCountry: declarationCountry,
                    legalFundsOrigin: concatenatedFundsOrigin
                },
            })
            await updateUserLegalInfo({
                variables: {
                    userId: user?.email || user?.sub,
                    legalFullname: declarationFullLegalName,
                    legalId: declarationID,
                    legalCountry: declarationCountry,
                    legalFundsOrigin: concatenatedFundsOrigin
                },
            })
            dispatch(showMessage({ message: 'Investment Intention Sent, you can check the status in My Assets Tab', variant: 'success' }));
            await setCreatingTx(false);
            await handleCloseDetail()
            await handleClose()
            return;
        } catch (error) {
            setCreatingTx(false);
            dispatch(showMessage({ message: 'Something went wrong, please try again' }));
            if (error.response) {
                console.error('Server Error:', error.response.status, error.response.data);
            } else if (error.request) {
                console.error('No response received from server');
            } else {
                console.error('Error:', error.message);
            }
            throw error;
        }
    }

    const totalAmountForUser = data?.offerPurchase
        .filter(offer => offer?.users?.id === user?.email || offer?.users?.id === user?.sub)
        .reduce((total, offer) => total + offer?.amount, 0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (amountToInvest === '') {
                setAmountToInvest(minInvest)
            }
            if (amountToInvest < minInvest && currFundTokenAmount > minInvest) {
                setAmountToInvest(minInvest)
            }
            if (amountToInvest > maxInvest) {
                setAmountToInvest(maxInvest)
            }
            if (amountToInvest > currFundTokenAmount) {
                setAmountToInvest(currFundTokenAmount)
            }
            if (amountToInvest > (maxInvest - totalAmountForUser)) {
                setAmountToInvest(maxInvest - totalAmountForUser)
            }
        }, 600);
        return () => clearTimeout(timer);
    }, [amountToInvest, minInvest, currFundTokenAmount, totalAmountForUser])

    const minInvestHigherThanLeftInvest = maxInvest - totalAmountForUser < minInvest

    const investedNotDeposited = data?.offerPurchase?.reduce((accumulator, purchase) => {
        if (
            (purchase.users.id === user?.email || purchase.users.id === user?.sub) &&
            (
                purchase.status === "Investment Intention" ||
                purchase.status === "SC Created" ||
                purchase.status === "SC Created - ALLI Deposited" ||
                purchase.status === "SC Created - ALLI Deposited - Pending" ||
                purchase.status === "Investor Deposited - Pending"
            )
        ) {
            return accumulator + 1;
        } else {
            return accumulator;
        }
    }, 0);
    const [checkedPersonalInfo, setCheckedPersonalInfo] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { md: 800, xs: '95%' },
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 4,
        p: { md: 2, xs: 1 },
    };

    if (checkedPersonalInfo) {
        style.top = '42%';
    } else {
        style.top = '50%';
    }

    const [checkedDeclaration, setCheckedDeclaration] = useState(false);

    const handleChangeDeclaration = (event) => {
        setCheckedDeclaration(event.target.checked);
    };


    const handleChangePersonalInfo = (event) => {
        setCheckedPersonalInfo(event.target.checked);
    };

    const [getCountries, { data: countriesList }] = useLazyQuery(GET_COUNTRIES, {
        pollInterval: 10000,
    })

    const [getFundsOptions, { data: fundOptions }] = useLazyQuery(GET_ALL_FUND_OPTIONS, {
        pollInterval: 10000,
    })

    useEffect(() => {
        getCountries()
        getFundsOptions()
    }, [getCountries, getFundsOptions])

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const language = i18n.language.toUpperCase();
    const filteredArray = fundOptions?.settingProperties?.filter(setting => setting.settingName.endsWith(`_${language}`));
    const optionOrigin = filteredArray?.map(setting => setting.settingValue);

    const handleChangeOrigin = (event) => {
        const {
            target: { value },
        } = event;
        setDeclarationFundsOrigin(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [forceFullDeclaration, setFullForceDeclaration] = useState(false)
    const [amountToForceDeclaration, setAmountToForceDeclaration] = useState(undefined)

    useEffect(() => {
        if (data?.maxOptionalLegalInfo !== undefined) {
            setAmountToForceDeclaration(data?.maxOptionalLegalInfo);
        }
    }, [data])


    useEffect(() => {
        if (amountToInvest > amountToForceDeclaration) {
            setFullForceDeclaration(true)
            setCheckedPersonalInfo(true)
        } else if (amountToInvest <= amountToForceDeclaration) {
            setFullForceDeclaration(false)
            setCheckedPersonalInfo(false)
        }
    }, [amountToInvest, amountToForceDeclaration])

    useEffect(() => {
        if (checkedPersonalInfo === false) {
            setDeclarationFullLegalName('')
            setDeclarationID('')
            setDeclarationCountry('')
            setDeclarationFundsOrigin([])
        }
    }, [checkedPersonalInfo])


    useEffect(() => {
        if (localStorageWallet !== null) {
            setSelectedWallet(localStorageWallet)
        } else if (localStorageWallet === null) {
            setSelectedWallet(null)
        }
    }, [localStorageWallet])

    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogContent style={{ pb: '16px', backgroundColor: actualThemeLight ? null : '#1d1d1d' }}>
                <>
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'right',
                            position: 'absolute',
                            mt: -1
                        }}>
                        <IconButton>
                            <CloseIcon onClick={closeModal} />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { sm: '14px', md: '18px', lg: '20px' },
                                fontFamily: 'Quicksand, sans-serif',
                                lineHeight: '20px',
                                fontWeight: 'bold',
                                color: '#9A1D4C',
                            }}
                        >
                            {t('INVEST_MODAL.INVEST')}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: { md: 3, xs: 1 }, p: { md: 1, xs: 0 },
                        border: '2px solid #e3e1e3',
                        borderRadius: 1
                    }}>
                        <Typography sx={{ mr: 1, fontWeight: 'bold' }}>{t('ADMIN.MIN_INVEST')}</Typography>
                        <Typography sx={{ mr: 1 }}>{minInvest.toLocaleString()} {token?.symbol}</Typography>
                        <Typography>-</Typography>
                        <Typography sx={{ ml: 1, mr: 1, fontWeight: 'bold' }}>{t('ADMIN.MAX_INVEST')}</Typography>
                        <Typography>{maxInvest.toLocaleString()} {token?.symbol}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: { md: 1, xs: 0.5 }, p: { md: 1, xs: 0.5 },
                    }}>
                        {(minInvestHigherThanLeftInvest === false && localStorageWallet !== null) &&
                            <Typography>
                                {t('INVEST_MODAL.YOUR_INVESTMENT')}
                                {(raised === 0 ? 100 : ((isNaN(amountToInvest) ? 0 : amountToInvest) * 100 / raised)).toFixed(2)}%
                                {t('INVEST_MODAL.OF_RAISED')}
                            </Typography>}
                        {(minInvestHigherThanLeftInvest === false && localStorageWallet !== null) &&
                            <Typography>
                                {t('INVEST_MODAL.YOUR_INVESTMENT')}{((isNaN(amountToInvest) ? 0 : amountToInvest) * 100 / target).toFixed(2)}
                                %{t('INVEST_MODAL.OF_TARGET')}
                            </Typography>}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {(minInvestHigherThanLeftInvest === false && localStorageWallet !== null) &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                <Typography
                                    sx={{ mr: { md: 2, xs: 0 }, fontWeight: 'bold' }}>
                                    {t('INVEST_MODAL.INVEST_AMOUNT')}
                                </Typography>
                                <TextField
                                    type='number'
                                    value={amountToInvest}
                                    sx={{ m: 1, width: '25ch' }}
                                    onChange={(e) => {
                                        const intNumber = parseInt(e.target.value)
                                        setAmountToInvest(intNumber)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{token?.symbol}</InputAdornment>,
                                    }}
                                />
                            </Box>}
                        {(minInvestHigherThanLeftInvest === false &&
                            walletEnabled &&
                            localStorageWallet !== null) &&
                            <Typography sx={{ mt: 1, fontStyle: 'italic', color: '#9A1D4C' }}>
                                {t('WALLET.CONNECTED_AVALIABLE')} {currFundTokenAmount === null ? 0 : currFundTokenAmount} {token?.symbol} ({selectedWallet})
                            </Typography>
                        }
                        {(minInvestHigherThanLeftInvest === false && localStorageWallet !== null) &&
                            <Typography sx={{
                                fontSize: '10px',
                                color: '#9A1D4C',
                                overflow: { md: 'visible', xs: 'hidden' },
                                wordBreak: { xs: 'break-all', md: 'normal' },
                                whiteSpace: { xs: 'normal', md: 'nowrap' },
                            }}>
                                {XChangeAddress}
                            </Typography>
                        }
                        {isCorrectNetworkSelected === false &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ color: '#f7712e' }}>{t('WALLET_VIEW.WRONG_NET')}</Typography>
                            </Box>
                        }
                        {totalAmountForUser > 0 &&
                            <Typography sx={{ mt: 1, fontStyle: 'italic', color: '#9A1D4C' }}>
                                {t('INVERSOR_VIEW.ALREADY_INVESTED')} {totalAmountForUser} {token?.symbol}
                            </Typography>
                        }
                        {currFundTokenAmount >= minInvest ?
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 1, p: 1,
                            }}>
                                {investedNotDeposited < 2 ?
                                    <>
                                        {(minInvestHigherThanLeftInvest === false && localStorageWallet !== null) &&
                                            <Typography>
                                                {t('INVEST_MODAL.HELPER_1')}
                                            </Typography>}
                                        {(minInvestHigherThanLeftInvest === false && localStorageWallet !== null) &&
                                            <Typography>
                                                {t('INVEST_MODAL.HELPER_2')}
                                            </Typography>}
                                    </>
                                    :
                                    <>
                                        <Typography sx={{ color: 'red', textAlign: 'center' }}>
                                            {t('INVEST_MODAL.HELPER_6')}
                                        </Typography>
                                        <Typography sx={{ color: 'red', textAlign: 'center' }}>
                                            {t('INVEST_MODAL.HELPER_7')}
                                        </Typography>
                                    </>
                                }
                            </Box> :
                            walletEnabled && <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 1, p: 1,
                            }}>
                                <Typography>{t('INVEST_MODAL.HELPER_5')}</Typography>
                            </Box>
                        }
                        {(walletEnabled && localStorageWallet !== null) ?
                            <>
                                <Box>
                                    {hasInvestorToken ?
                                        <>
                                            {currFundTokenAmount > minInvest ?
                                                <>
                                                    {amountToInvest === '' ?
                                                        <>
                                                            <Button
                                                                sx={{ width: '200px', mt: 2 }}
                                                                variant='contained'
                                                                disabled
                                                            >
                                                                {t('INVEST_MODAL.INVEST')}
                                                            </Button>
                                                        </>

                                                        :
                                                        (maxInvest - totalAmountForUser > 0) && (investedNotDeposited < 2) ?
                                                            <>
                                                                {minInvestHigherThanLeftInvest === false && <Button
                                                                    sx={{ width: '200px', mt: 2 }}
                                                                    variant='contained'
                                                                    onClick={handleOpenDetail}
                                                                >
                                                                    {t('INVEST_MODAL.INVEST')}
                                                                </Button>}
                                                            </>
                                                            : null
                                                    }
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            alignItems: 'center', mt: 1
                                        }}>
                                            <Typography
                                                id="modal-modal-description"
                                            >
                                                {t('PERFIL_CONFIG_VIEW.REQ_TOKEN1')}
                                            </Typography>
                                            <Button
                                                onClick={handleBuyClick}
                                                sx={{ mt: 1 }}
                                                variant="contained"
                                            >
                                                {t('PERFIL_CONFIG_VIEW.BUY_HERE')}
                                            </Button>
                                            {timer <= 3 ? <CircularProgress size={20} sx={{ mt: 2 }} />
                                                :
                                                <Typography
                                                    id="modal-modal-description"
                                                    sx={{ mt: 1, fontStyle: 'italic', color: '#9A1D4C' }}
                                                >
                                                    {t('PERFIL_CONFIG_VIEW.REQ_TOKEN_NOT')}
                                                </Typography>
                                            }
                                        </Box>
                                    }
                                </Box>
                            </>
                            : noWallet ?
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    alignItems: 'center', mt: 2
                                }}>
                                    <Typography>
                                        {t('INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET')}
                                    </Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            pt: 0.4,
                                            pb: 0.4,
                                            pl: 1,
                                            pr: 1,
                                        }}
                                        onClick={() => { dispatch(push('/wallet')) }}
                                    >
                                        {t(
                                            'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                                        )}
                                    </Button>
                                </Box>
                                :
                                <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                                    <LinearProgress />
                                </Box>
                        }
                    </Box>
                    <Modal
                        open={openDetail}
                        onClose={handleCloseDetail}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box>
                                <Box
                                    sx={{
                                        width: '96%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'right',
                                        position: 'absolute',
                                        mt: -1
                                    }}>
                                    <IconButton>
                                        <CloseIcon onClick={handleCloseDetail} />
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: { md: '100%', xs: '90%' }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: { sm: '14px', md: '18px', lg: '20px' },
                                            fontFamily: 'Quicksand, sans-serif',
                                            lineHeight: '20px',
                                            fontWeight: 'bold',
                                            color: '#9A1D4C',
                                        }}
                                    >
                                        {t('INVEST_MODAL.TITLE_DECLARATION')}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mt: 4
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: { sm: '18px', md: '18px', lg: '18px' },
                                            fontFamily: 'Quicksand, sans-serif',
                                            lineHeight: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {t('INVEST_MODAL.YOUR_INVESTMENT_IS')} {amountToInvest} {token?.symbol}
                                    </Typography>
                                </Box>
                                <FormControlLabel sx={{ mt: 2, mb: 2 }}
                                    control={
                                        <Checkbox checked={checkedDeclaration}
                                            onChange={handleChangeDeclaration} />
                                    }
                                    label={t('INVEST_MODAL.I_DECLARE')}
                                />
                                {forceFullDeclaration === true ? null :
                                    <Box
                                        sx={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center', width: '100%', justifyContent: 'center',
                                            mt: 1, mb: 1
                                        }}>
                                        <Switch
                                            checked={checkedPersonalInfo}
                                            onChange={handleChangePersonalInfo}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <Typography>
                                            {t('INVEST_MODAL.OPTIONAL_DETAILS')}
                                        </Typography>
                                    </Box>}
                                {
                                    checkedPersonalInfo &&
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center',
                                        width: '100%', justifyContent: 'center'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                width: '100%', mt: 1, alignItems: 'center'
                                            }}>
                                                <Box sx={{ width: '30%' }}>
                                                    {t('INVEST_MODAL.OPT_LEGALNAME')}
                                                </Box>
                                                <Box sx={{ width: '70%' }}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        value={declarationFullLegalName}
                                                        onChange={(e) => setDeclarationFullLegalName(e.target.value)}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                width: '100%', mt: 2, alignItems: 'center'
                                            }}>
                                                <Box sx={{ width: '30%' }}>
                                                    {t('INVEST_MODAL.OPT_LEGALID')}
                                                </Box>
                                                <Box sx={{ width: '70%' }}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        value={declarationID}
                                                        onChange={(e) => setDeclarationID(e.target.value)}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                width: '100%', mt: 2, alignItems: 'center'
                                            }}>
                                                <Box sx={{ width: '30%' }}>
                                                    {t('INVEST_MODAL.OPT_LEGALCOUNTRY')}
                                                </Box>
                                                <Box sx={{ width: '70%' }}>
                                                    {countriesList == undefined ? null : (
                                                        <Autocomplete
                                                            fullWidth
                                                            size='small'
                                                            limitTags={6}
                                                            onChange={(e, value) => {
                                                                setDeclarationCountry(value?.name)
                                                            }}
                                                            id="countries-outlined"
                                                            options={countriesList?.countries}
                                                            getOptionLabel={(p) => p?.name}
                                                            filterSelectedOptions
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    sx={{ width: '100%' }}
                                                                    {...params}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        className: classes.input,
                                                                    }}
                                                                    placeholder={declarationCountry}
                                                                    name="country"
                                                                    color="pink"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                width: '100%', mt: 2, alignItems: 'center'
                                            }}>
                                                <Box sx={{ width: '30%' }}>
                                                    {t('INVEST_MODAL.OPT_LEGALFUNDSORIGIN')}
                                                </Box>
                                                <Box sx={{ width: '70%' }}>
                                                    <Select
                                                        id="declarationcheckbox"
                                                        multiple
                                                        fullWidth
                                                        size='small'
                                                        value={declarationFundsOrigin}
                                                        onChange={handleChangeOrigin}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {optionOrigin.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                {forceFullDeclaration === false &&
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row',
                                        alignItems: 'center', width: '100%', justifyContent: 'center', mt: 1
                                    }}>
                                        {checkedDeclaration === true ? <Button
                                            sx={{ width: '200px', mt: 2, ml: 2 }}
                                            variant='contained'
                                            onClick={() => { createTransaction() }}
                                        >
                                            {t('INVEST_MODAL.INVEST')}
                                        </Button> :
                                            <Button
                                                sx={{ width: '200px', mt: 2, ml: 2 }}
                                                variant='contained'
                                                disabled
                                            >
                                                {t('INVEST_MODAL.INVEST')}
                                            </Button>
                                        }
                                    </Box>
                                }
                                {creatingTx &&
                                    <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
                                        <LinearProgress />
                                    </Box>
                                }
                                {
                                    forceFullDeclaration === true &&
                                    <Box
                                        sx={{
                                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                                            width: '100%', justifyContent: 'center', mt: 1
                                        }}>
                                        {
                                            checkedDeclaration === true &&
                                                declarationFullLegalName !== '' &&
                                                declarationID !== '' &&
                                                declarationCountry !== '' &&
                                                declarationCountry !== undefined &&
                                                concatenatedFundsOrigin !== '' ?
                                                <Button
                                                    sx={{ width: '200px', mt: 2, ml: 2 }}
                                                    variant='contained'
                                                    onClick={() => { createTransaction() }}
                                                >
                                                    {t('INVEST_MODAL.INVEST')}
                                                </Button> :
                                                <Button
                                                    sx={{ width: '200px', mt: 2, ml: 2 }}
                                                    variant='contained'
                                                    disabled
                                                >
                                                    {t('INVEST_MODAL.INVEST')}
                                                </Button>
                                        }
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Modal>
                </>
            </DialogContent>
        </Dialog>
    )
}

export default InvestModal