import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { GET_COMPANY_BY_ID } from '../../../Queries/Admin'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    styled,
    Link,
    useTheme
} from '@mui/material'
import { useDispatch } from 'react-redux'
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import DownloadIcon from '@mui/icons-material/Download';
import { useAuth0 } from '@auth0/auth0-react'
import { GET_USER_INFO } from '../../../Queries/Users'
import DirectMessageModal from '../../UserCard/DirectMessageModal'
import { fetchUTCTime } from '../../../utils/utcTime'
import { SEND_MESSAGE, GET_MESSAGES_FROM_USERS } from '../../../Queries/Messages'
import BuildingIcon from '../../../../src/assets/icons/building.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import {
    CREATE_NOTIFICATION,
} from '../../../Queries/Notifications'
import {
    SET_COMPANY_PROFILE_AS_COMPLETE,
    SET_COMPANY_PROFILE_AS_INCOMPLETE,
    SET_COMPANY_PROFILE_AS_VALIDATED,
    SET_COMPANY_PROFILE_AS_NOT_VALIDATED
} from '../../../Queries/Admin'
import { showMessage } from '../../../store/actions/snackBarActions'
import i18n from "i18next";


const CompaniesAssessment = () => {
    // Obtiene el parámetro coId de la URL
    const { coId } = useParams()
    const selectedLanguage = i18n.language
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    // Obtiene el objeto dispatch de Redux
    const dispatch = useDispatch()

    // Obtiene la función t de la biblioteca i18n para traducción
    const { t } = useTranslation()

    // Obtiene el objeto user del componente Auth0
    const { user } = useAuth0()

    const hitos = [
        { id: '1', title: 'Hito 1', description: 'Describir objetivos, entregables y criterio de éxito.', detail: 'Tokio, la ajetreada capital de Japón, mezcla lo ultramoderno y lo tradicional, desde los rascacielos iluminados con neones hasta los templos históricos. El opulento santuario Shinto Meiji es conocido por su puerta altísima y los bosques circundantes. El Palacio Imperial se ubica en medio de grandes jardines públicos. Los distintos museos de la ciudad ofrecen exhibiciones que van desde el arte clásico (en el Museo Nacional de Tokio) hasta un teatro kabuki reconstruido (en el Museo Edo-Tokyo' },
        { id: '2', title: 'Hito 2', description: 'Describir objetivos, entregables y criterio de éxito.', detail: 'Tokio, la ajetreada capital de Japón, mezcla lo ultramoderno y lo tradicional, desde los rascacielos iluminados con neones hasta los templos históricos. El opulento santuario Shinto Meiji es conocido por su puerta altísima y los bosques circundantes. El Palacio Imperial se ubica en medio de grandes jardines públicos. Los distintos museos de la ciudad ofrecen exhibiciones que van desde el arte clásico (en el Museo Nacional de Tokio) hasta un teatro kabuki reconstruido (en el Museo Edo-Tokyo' },
        { id: '3', title: 'Hito 3', description: 'Describir objetivos, entregables y criterio de éxito.', detail: 'Tokio, la ajetreada capital de Japón, mezcla lo ultramoderno y lo tradicional, desde los rascacielos iluminados con neones hasta los templos históricos. El opulento santuario Shinto Meiji es conocido por su puerta altísima y los bosques circundantes. El Palacio Imperial se ubica en medio de grandes jardines públicos. Los distintos museos de la ciudad ofrecen exhibiciones que van desde el arte clásico (en el Museo Nacional de Tokio) hasta un teatro kabuki reconstruido (en el Museo Edo-Tokyo' },
        { id: '4', title: 'Hito 4', description: 'Describir objetivos, entregables y criterio de éxito.', detail: 'Tokio, la ajetreada capital de Japón, mezcla lo ultramoderno y lo tradicional, desde los rascacielos iluminados con neones hasta los templos históricos. El opulento santuario Shinto Meiji es conocido por su puerta altísima y los bosques circundantes. El Palacio Imperial se ubica en medio de grandes jardines públicos. Los distintos museos de la ciudad ofrecen exhibiciones que van desde el arte clásico (en el Museo Nacional de Tokio) hasta un teatro kabuki reconstruido (en el Museo Edo-Tokyo' }
    ]

    // Obtiene los datos del usuario a través de la consulta GET_USER_INFO
    // usando la función useLazyQuery de Apollo Client
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    // Define el estado isCoreTeam y lo inicializa como falso
    const [isCoreTeam, setIsCoreTeam] = useState(false)

    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    // Se ejecuta cuando userData cambia
    // Busca el rol 'role-core-team' en userData
    // Si lo encuentra, actualiza el estado isCoreTeam a verdadero
    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    // Se ejecuta cuando el componente monta o cuando getUserData cambia
    // Ejecuta la función getUserData para obtener los datos del usuario
    useEffect(() => {
        getUserData()
    }, [getUserData])

    const [getCompanyData, { data: companyData, loading: dataLoading, refetch }] = useLazyQuery(
        GET_COMPANY_BY_ID, { variables: { coId: coId } })

    const data = companyData?.companies[0]
    const [getMessages, { data: messagesData }] = useLazyQuery(
        GET_MESSAGES_FROM_USERS, {
        variables: {
            userId: user?.email || user?.sub, otherUser: data?.users[0]?.userName
        },
        pollInterval: 2000
    })

    useEffect(() => {
        getMessages()
    }, [getMessages, messagesData])

    function filterMessagesBySearchTerm(messages, searchField) {
        const searchTerm = searchField?.toLowerCase();
        return messages?.filter(message => {
            const textMatch = message.text.toLowerCase().includes(searchTerm);
            return textMatch;
        });
    }

    const filteredMessages = filterMessagesBySearchTerm(messagesData?.messages, data?.coName);

    useEffect(() => {
        scrollToBottom()
    })

    useEffect(() => {
        getCompanyData()
    }, [getCompanyData, coId])
    const BackButton = styled(Button)(({ theme }) => ({
        color: theme.palette.text.hint,
        fontFamily: 'Quicksand, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    }))
    const handleClick = () => {
        dispatch(push(`/user-profile/${data?.users[0]?.email}`))
    }
    const [value, setValue] = React.useState(null || data?.profileComplete);


    useEffect(() => {
        if (data?.profileComplete !== null) { setValue(data?.profileComplete) } else { setValue(null) }
    }, [data?.profileComplete])

    const handleChangeRadio = (event) => {
        setValue(event.target.value);
    };

    const [validate, setValidate] = useState(false)
    const [modalSendMessage, setModalSendMessage] = useState(false)
    const targetUser = data?.users[0]?.id
    const targetUsername = data?.users[0]?.userName
    const handleOpenNewMessage = () => {
        setModalSendMessage(true)
    }
    const handleCloseNewMessage = () => {
        setModalSendMessage(false)
    }
    const [milestones, setMilestones] = useState(false)
    const handleCloseMilestones = () => {
        setMilestones(false)
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px',
        width: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const [textToSend, setTextToSend] = useState('')
    const addedInfoToText = "(Core Team) - Incomplete information for " + data?.coName + ' - '
    const [finalMessage, setFinalMessage] = useState('')

    useEffect(() => {
        setFinalMessage(addedInfoToText + textToSend)
    }, [textToSend, data])


    const [messageSending, setMessageSending] = useState(false)
    const [sendMessageMutation] = useMutation(SEND_MESSAGE)
    const [createNotification] = useMutation(CREATE_NOTIFICATION)
    const myuuid = uuidv4()
    const dateTime = moment.utc()
    const [messageSent, setMessageSent] = useState(false)

    const sendMessage = async () => {
        try {
            if (textToSend !== '' && messageSent === false) {
                const utcTime = await fetchUTCTime()
                setMessageSent(true)
                setMessageSending(true)
                await sendMessageMutation({
                    variables: {
                        id: myuuid,
                        on: dateTime,
                        onUTC: utcTime,
                        text: finalMessage,
                        read: false,
                        deleted: false,
                        userId: userData?.users[0]?.id,
                        messageId: myuuid,
                        otherUserId: data?.users[0]?.id,
                    },
                })
                const notifID = uuidv4()
                await createNotification({
                    variables: {
                        notificationID: notifID,
                        msg: `INFOMISSING - Core Team (${userData?.users[0]?.userName})`,
                        date: dateTime,
                        link: '/messages',
                        userID: data?.users[0]?.id,
                    },
                })
                await (setTextToSend(''))
                await setMessageSending(false)
                await setValidate(false)
                await dispatch(showMessage({ message: 'Message Sent!', variant: 'success' }))
                await setMessageSent(false)
            }
        } catch (err) {
            //Err
        }
    }

    const [setProfileComplete] = useMutation(SET_COMPANY_PROFILE_AS_COMPLETE)
    const [setProfileIncomplete] = useMutation(SET_COMPANY_PROFILE_AS_INCOMPLETE)
    const [setCompanyAsValidated] = useMutation(SET_COMPANY_PROFILE_AS_VALIDATED)
    const [setCompanyAsNotValidated] = useMutation(SET_COMPANY_PROFILE_AS_NOT_VALIDATED)

    const setCompleteProfile = async () => {
        try {
            await setProfileComplete({
                variables: {
                    companyId: data?.id,
                }
            })
            await refetch()
            setValidate(!validate)
            dispatch(showMessage({ message: 'Profile Completed!', variant: 'success' }))
        } catch (error) {
            //Err
        }
    }

    const setIncompleteProfile = async () => {
        try {
            await setProfileIncomplete({
                variables: {
                    companyId: data?.id,
                }
            })
            await refetch()
            setValidate(!validate)
            dispatch(showMessage({ message: 'Profile Incomplete', variant: 'success' }))
        } catch (error) {
            //Err
        }
    }

    const setValidatedCompany = async () => {
        try {
            await setCompanyAsValidated({
                variables: {
                    companyId: data?.id,
                }
            })
            await refetch()
            setValidate(!validate)
            dispatch(showMessage({ message: 'Validated', variant: 'success' }))
        } catch (error) {
            //Err
        }
    }

    const setNotValidatedCompany = async () => {
        try {
            await setCompanyAsNotValidated({
                variables: {
                    companyId: data?.id,
                }
            })
            await refetch()
            setValidate(!validate)
            dispatch(showMessage({ message: 'Not Validated', variant: 'success' }))
        } catch (error) {
            //Err
        }
    }



    if (dataLoading) {
        return (
            <Box sx={{
                display: 'flex',
                width: '100%',
                height: '80vh',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress />
            </Box>
        )
    }

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    if (isCoreTeam) {
        return <>
            <Grid container>
                {/* HEADER */}
                <Grid item xs={12} md={12} className="right">
                    <Box sx={{ backgroundColor: '#9A1D4C' }}>
                        <Grid container>
                            {/* BACK BUTTON & AVATAR */}
                            <Grid item md={1} xs={1} sx={{ alignSelf: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                    <BackButton
                                        onClick={() => dispatch(goBack())}
                                        sx={{ marginBottom: '10px', color: 'white', textDecoration: 'underline', fontSize: '14px' }}
                                    >
                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                    </BackButton>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={4} sx={{
                                maxHeight: '16vh',
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                        alt={data?.coName}
                                        src={data?.logo}
                                    />
                                </Box>
                            </Grid>
                            {/* COMPANY INFO */}
                            <Grid item md={9} xs={9} sx={{ alignSelf: 'center' }}>
                                <Grid container sx={{ color: '#FFF' }}>
                                    {/* COLUMN 1 */}
                                    <Grid item md={4} xs={4}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                {data?.coName}
                                            </Typography>
                                            {data?.assessmentStatus === "pending" &&
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'row',
                                                        justifyContent: 'flex-start', alignItems: 'center'
                                                    }}>
                                                    <Typography sx={{ fontSize: '18px', mr: 1 }}>
                                                        {t('CO_ASSESSMENT.APPLIED')}
                                                    </Typography>
                                                    <Typography>
                                                        • {data?.proposal?.fund?.name}
                                                    </Typography>
                                                </Box>
                                            }
                                            {data?.assessmentStatus === "accepted" &&
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'row',
                                                        justifyContent: 'flex-start', alignItems: 'center'
                                                    }}>
                                                    <Typography sx={{ fontSize: '18px', mr: 1 }}>
                                                        {t('CO_ASSESSMENT.ACCEPTED')}
                                                    </Typography>
                                                    <Typography>
                                                        • {data?.proposal[0]?.fund?.name}
                                                    </Typography>
                                                </Box>
                                            }
                                            {data?.assessmentStatus === "rejected" &&
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'row',
                                                        justifyContent: 'flex-start', alignItems: 'center'
                                                    }}>
                                                    <Typography sx={{ fontSize: '18px', mr: 1 }}>
                                                        {t('CO_ASSESSMENT.REJECTED')}
                                                    </Typography>
                                                    <Typography>
                                                        • {data?.proposal[0]?.fund?.name}
                                                    </Typography>
                                                </Box>
                                            }
                                            {data?.assessmentStatus === "incomplete" &&
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'row',
                                                        justifyContent: 'flex-start', alignItems: 'center'
                                                    }}>
                                                    <Typography sx={{ fontSize: '18px', mr: 1 }}>
                                                        {t('CO_ASSESSMENT.INCOMPLETE')}
                                                    </Typography>
                                                    <Typography>
                                                        • {data?.proposal[0]?.fund?.name}
                                                    </Typography>
                                                </Box>
                                            }
                                            <Typography
                                                onClick={handleClick}
                                                sx={{
                                                    fontSize: '18px',
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline'
                                                }}>
                                                {data?.users[0]?.userName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {/* COLUMN 2 */}
                                    <Grid item md={4} xs={4}
                                        sx={{
                                            alignSelf: 'center',
                                            height: '100%'
                                        }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            fontSize: '16px'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <Typography sx={{ pr: '4px' }} >
                                                    {t('CO_ASSESSMENT.ADDRESS')}
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    {data?.address}
                                                </Typography>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <Typography sx={{ pr: '4px' }} >
                                                    {t('CO_ASSESSMENT.PHONE')}
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    {data?.phone}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* COLUMN 3 */}
                                    <Grid item md={4} xs={4} sx={{ alignSelf: 'center', height: '100%' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                    Email:
                                                </Typography>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                    {data?.email}
                                                </Typography>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                mt: 2,
                                                cursor: 'pointer'
                                            }}>
                                                <DownloadIcon />
                                                <Typography sx={{ fontSize: '16px', ml: 1 }}>
                                                    {t('CO_ASSESSMENT.DOWNLOAD_ACT_PLAN')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {/* COMPANY DESCRIPTION - PREFERENCES AND ACTIONS */}
                <Grid item md={12} xs={12} sx={{ mt: 2, ml: '25%' }}>
                    {data?.preferences?.map((preference) => {
                        return <Chip
                            key={preference?.id}
                            label={preference?.preferenceName}
                            variant="outlined"
                            sx={{ ml: 1 }}
                        />
                    })}
                </Grid>
                <Grid item md={12} xs={12} sx={{ mt: 2, ml: '25%' }}>
                    <Typography>
                        {data?.description}
                    </Typography>
                </Grid>
                <Box
                    sx={{
                        display: 'flex', flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: { md: 'space-between', xs: 'center' },
                        ml: 8, mt: 2
                    }}>
                    {/* DESCRIPTION */}
                    <Box
                        sx={{
                            display: 'flex', flexDirection: 'column',
                            width: { md: '40%', xs: '100%' }, alignItems: { md: 'center', xs: 'center' }, justifyContent: 'center', mt: 6
                        }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { md: 'start', xs: 'center' }, justifyContent: 'center' }}>
                            {/* EMPLOYEES */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('COMPANY.EMPLOYEES')}: {data?.employees || '-'}
                            </Typography>
                            {/* FUNDATION YEAR */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('CREATE_COMPANY.FOUNDATION_YEAR')}: {data?.fundationYear.year || '-'}
                            </Typography>
                            {/* BILLING */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('CREATE_COMPANY.ANNUAL_BILLING')}: $ {data?.annualBilling?.toLocaleString() || '-'}
                            </Typography>


                            {/* LEGAL */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('CREATE_COMPANY.LEGAL_COMPANY')}: {data?.isIncorporated ? t('COMPANY.YES') : t('COMPANY.NO')}
                            </Typography>
                            {/* JURISDICTION */}
                            <Typography sx={{ color: actualThemeLight ? '#4F4F4F' : 'white', fontSize: '14px' }}>
                                {t('COMPANY.JURISDICTION')}: {data?.isIncJurisdiction || '-'}
                            </Typography>

                            {/* LINK TREE */}
                            <Link
                                variant="text"
                                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', color: 'black' }}
                                href={data?.has_url[0]?.link}
                                target={{ _blank: true }}>
                                <LinkIcon sx={{ color: actualThemeLight ? null : 'white' }} />
                                <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline', textDecorationColor: actualThemeLight ? null : 'white', fontSize: '14px', color: actualThemeLight ? null : 'white' }}>
                                    LinkTree
                                </Typography>
                            </Link>
                        </Box>

                    </Box>
                    {/* CONTACT */}
                    <Box
                        sx={{
                            display: 'flex', flexDirection: 'column',
                            width: { md: '60%', xs: '100%' }, alignItems: { md: 'start', xs: 'center' }
                        }}>
                        {/* DESCRIPTION TEXT */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                            <img src={BuildingIcon} alt="icon" width='50px' />
                            <Typography sx={{ fontSize: '20px' }}>
                                {t('INVERSOR_VIEW.OFFERING_CARD.DESCRIPTION')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex', flexDirection: 'row', mt: 2,
                                mb: 4, width: { md: '80%', xs: '100%' }, alignItems: { md: 'start', xs: 'center' }
                            }}>
                            {/* TITLE */}
                            <Box sx={{ border: '4px solid #9A1D4C', height: '100%', minHeight: '4vh', mr: 3 }} />
                            <Typography sx={{ textAlign: { md: 'left', xs: 'center' } }} >
                                {selectedLanguage === 'en' ? data?.descriptionEn :
                                    selectedLanguage === 'es' && data?.descriptionEs !== '' ?
                                        data?.descriptionEs :
                                        selectedLanguage === 'pt' && data?.descriptionPt !== '' ?
                                            data?.descriptionPt : data?.descriptionEn}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Grid
                    item md={12} xs={12}
                    sx={{
                        display: 'flex', flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'center'
                    }}>
                    <Button
                        onClick={() => { setMilestones(!milestones) }}
                        variant='outlined'>
                        {t('CO_ASSESSMENT.VIEW_MILESTONES')}
                    </Button>
                </Grid>

                {value !== undefined &&
                    <Grid item md={12} xs={12} sx={{ mt: 6, ml: '25%' }}>
                        <FormControl >
                            <RadioGroup
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChangeRadio}
                            >
                                <FormControlLabel
                                    value={true}
                                    onClick={setCompleteProfile}
                                    control={<Radio />}
                                    label={t('CO_ASSESSMENT.MARK_COMPLETE')}
                                />
                                <FormControlLabel
                                    sx={{ ml: 4 }}
                                    value={false}
                                    onClick={setIncompleteProfile}
                                    control={<Radio />}
                                    label={t('CO_ASSESSMENT.MARK_INCOMPLETE')}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>}
                <Grid item md={12} xs={12} sx={{ mt: 6, ml: '25%' }}>
                    {value !== false &&
                        targetUser !== user?.email ?
                        <Button
                            onClick={handleOpenNewMessage}
                            variant='contained'>
                            {t('CO_ASSESSMENT.CONTACT')}
                        </Button>
                        : null
                    }
                    {value === true &&
                        <>
                            {data?.isValidated === true &&
                                <>
                                    <Button
                                        variant='contained'
                                        sx={{ ml: 4 }}
                                        onClick={setNotValidatedCompany}
                                    >
                                        {t('ADMIN.ACT_UNVERIFY')}
                                    </Button>
                                    <Typography
                                        sx={{ mt: 2, fontWeight: 'bold', color: 'green' }}
                                    >
                                        {t('CO_ASSESSMENT.STATUS_ACCEPTED_VIEW')}
                                    </Typography>
                                </>
                            }
                            {data?.isValidated === false &&
                                <>
                                    <Button
                                        variant='contained'
                                        sx={{ ml: 4 }}
                                        onClick={setValidatedCompany}
                                    >
                                        {t('ADMIN.ACT_VERIFY')}
                                    </Button>
                                    <Typography
                                        sx={{ mt: 2, fontWeight: 'bold', color: 'red' }}
                                    >
                                        {t('CO_ASSESSMENT.STATUS_REJECTED_VIEW')}
                                    </Typography>
                                </>
                            }
                        </>
                    }
                </Grid>
                {
                    <>
                        {validate === true && value === 'complete' ? null
                            : value === false
                                ?
                                <Box sx={{
                                    mt: 0,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'

                                }}>
                                    {filteredMessages?.length < 1
                                        ?
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {t('CO_ASSESSMENT.MESSAGE_REQ_MISSING_INFO')}
                                        </Typography>
                                        :
                                        <Box
                                            sx={{
                                                border: '1px solid gray',
                                                borderRadius: '5px',
                                                width: '50%',
                                                mt: 2,
                                                height: '100%',
                                                overflow: 'auto',
                                                p: 1
                                            }}>
                                            {filteredMessages?.map((message) => {
                                                return (<>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        border: '1px solid pink',
                                                        borderRadius: '5px',
                                                        mt: 1, p: 1
                                                    }}>
                                                        <Box sx={{ width: '23%' }}>
                                                            <Typography>{message.sentBy.userName}</Typography>
                                                        </Box>
                                                        <Box sx={{ width: '65%' }}>
                                                            <Typography>{message.text}</Typography>
                                                        </Box>
                                                        <Box sx={{ width: '12%' }}>
                                                            <Typography
                                                                fontSize={12}>
                                                                {message.onUTC.split('T')[0]}
                                                            </Typography>
                                                            <Typography
                                                                fontSize={12}>
                                                                {message.onUTC.split('T')[1].split('.')[0]}
                                                            </Typography>
                                                        </Box>
                                                    </Box>

                                                    < div ref={messagesEndRef} />
                                                </>)
                                            })}
                                        </Box>
                                    }
                                    {value === false ?
                                        < TextField
                                            onChange={(e) => setTextToSend(e.target.value)}
                                            value={textToSend}
                                            sx={{ width: '50%', mt: 2 }}
                                            id="outlined-basic"
                                            variant="outlined"
                                        />
                                        :
                                        < TextField
                                            multiline
                                            onChange={(e) => setTextToSend(e.target.value)}
                                            minRows={4}
                                            sx={{ width: '50%', mt: 2 }}
                                            id="outlined-basic"
                                            variant="outlined"
                                        />
                                    }

                                    {messageSending && <Box sx={{ width: '50%', mt: 2 }}>
                                        <LinearProgress />
                                    </Box>}
                                    <Grid item
                                        md={12}
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            mt: 2,
                                            mb: 4,
                                            maxHeight: '4vh'
                                        }}>
                                        <Button
                                            sx={{ ml: 4 }}
                                            variant='contained'
                                            onClick={
                                                sendMessage
                                            }
                                        >
                                            {t('CO_ASSESSMENT.SEND_MESSAGE')}
                                        </Button>
                                    </Grid>
                                </Box> : null
                        }

                    </>
                }
            </Grid >
            {modalSendMessage && (
                <DirectMessageModal
                    targetUser={targetUser}
                    targetUsername={targetUsername}
                    open={modalSendMessage}
                    handleClose={handleCloseNewMessage}
                />
            )
            }
            <Modal
                open={milestones}
                onClose={handleCloseMilestones}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box onClick={handleCloseMilestones}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                            cursor: 'pointer',
                            position: 'absolute',
                            pr: 4
                        }}>
                        <IconButton
                            color="primary"
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {hitos.map((hito) => {
                            return (
                                <Box sx={{ mt: 4, mb: 4 }} key={hito.id}>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        {hito.title}: {hito.description}
                                    </Typography>
                                    <Typography>
                                        {hito.detail}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Modal>
        </>
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default CompaniesAssessment