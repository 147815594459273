import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import authReducer from './authReducer'
import offeringReducer from './offeringReducer'
import PerfilReducer from './perfilReducer'
import preferenceReducer from './preferenceReducer'
import snackBarReducer from './snackBarReducer'
import statusReducer from './statusReducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    status: statusReducer,
    snackbar: snackBarReducer,
    preferences: preferenceReducer,
    auth: authReducer,
    offerings: offeringReducer,
    perfilInfo: PerfilReducer,
  })
