import React from 'react'
import InterestsAdministration from '../../../src/components/AdminComponents/InterestsAdministration/InterestsAdministration'
import { Box } from '@mui/material'

const AdminViewInterests = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
                justifySelf: 'center',
                width: { xs: '100%', md: '90%' },
                pt: '2%',
                pl: { xs: '0', md: '10%' },
            }}
        >
            <InterestsAdministration />
        </Box>
    )
}

export default AdminViewInterests