import { gql } from '@apollo/client'

export const GET_NEWS = gql`
  query ($search: String!) {
    news(
      options: { sort: { on: DESC } }
      where: {
        OR: [
          { titleEn_CONTAINS: $search }
          { contentEn_CONTAINS: $search }
          { titleEs_CONTAINS: $search }
          { contentEs_CONTAINS: $search }
          { titlePt_CONTAINS: $search }
          { contentPt_CONTAINS: $search }
        ]
        AND: { isActive: true }
      }
    ) {
      id
      image
      on
      messages {
        id
        text
        replyTo
        on
        sentBy {
          id
          userName
        }
      }
      titleEn
      titleEs
      titlePt
      contentEn
      contentEs
      contentPt
      isActive
      url {
        link
      }
      fund {
        id
        name
      }
    }
  }
`

export const GET_ALL_NEWS = gql`
  query {
    news(options: { sort: { on: DESC } }, where: { isActive: true }) {
      id
      image
      on
      titleEn
      titleEs
      titlePt
      contentEn
      contentEs
      contentPt
      url {
        link
      }
      fund {
        id
        name
      }
    }
  }
`

export const SEND_COMMENT_TO_NEWS = gql`
  mutation createMessage(
    $MessageId: ID!
    $MessageDate: DateTime!
    $MessageText: String!
    $UserId: ID!
    $NewsId: ID!
  ) {
    createMessages(
      input: {
        id: $MessageId
        on: $MessageDate
        text: $MessageText
        read: false
        sentBy: { connect: { where: { node: { id: $UserId } } } }
        news: { connect: { where: { node: { id: $NewsId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`

export const SEND_REPLY_TO_COMMENT_TO_NEWS = gql`
  mutation createMessage(
    $MessageId: ID!
    $MessageDate: DateTime!
    $MessageText: String!
    $UserId: ID!
    $NewsId: ID!
    $replyToId: String
  ) {
    createMessages(
      input: {
        id: $MessageId
        on: $MessageDate
        text: $MessageText
        read: false
        replyTo: $replyToId
        sentBy: { connect: { where: { node: { id: $UserId } } } }
        news: { connect: { where: { node: { id: $NewsId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`
