import { Box, Grid, Link, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { GET_EVENTS } from '../../../Queries/Event'
import Card from '../../../components/Card/Card'
import InvestorMetricsDashboard from './metricsDashboard/InvestorMetricsDashboard'
import AlliesNewsDashboard from './AlliesNewsDashboard/AlliesNewsDashboard'
import ActiveProposalCard from '../../../components/ActiveProposalCard/ActiveProposalCard'
import Events from './Events/Events'
import ActiveFundsDashboard from './ActiveFundsDashboard/ActiveFundsDashboard'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/free-mode/free-mode.min.css'
import { useLazyQuery } from '@apollo/client'
import { GET_ACTIVE_FUNDS, GET_ALL_ACTIVE_FUNDS } from '../../../Queries/Funds'
import ApplicantMetricsDashboard from './metricsDashboard/ApplicantMetricsDashboard'
import i18n from "i18next";
import { push } from 'connected-react-router'

const newDashboardView = () => {
    // Get authenticated user from Auth0
    // eslint-disable-next-line
    const { user } = useAuth0();

    // Define dispatch function for Redux actions
    // eslint-disable-next-line
    const dispatch = useDispatch();

    // Define translation function
    const { t } = useTranslation();

    const locStorageRole = localStorage.getItem('role')

    // Import active funds using GraphQL query
    const [getFunds, { data }] = useLazyQuery(GET_ACTIVE_FUNDS, {
        pollInterval: 10000,
    });

    const [getAllFunds, { data: dataAllFunds }] = useLazyQuery(GET_ALL_ACTIVE_FUNDS, {
        pollInterval: 10000,
    });

    const [funds, setFunds] = useState([]);

    const sortedStatuses = [
        'Collecting',
        'Published',
        'Open',
        'Assessment',
        'Governance',
        'Vesting',
        'Execution',
        'ROI',
        'Closed',
        'Closed - Not Fully Funded',
    ]

    useEffect(() => {
        if (dataAllFunds && dataAllFunds.funds) {
            const sortedFunds = [...dataAllFunds.funds].sort((a, b) => {
                const statusA = sortedStatuses.indexOf(a.status);
                const statusB = sortedStatuses.indexOf(b.status);
                return statusA - statusB;
            });
            setFunds(sortedFunds);
        }
    }, [dataAllFunds]);

    // Call getFunds when component mounts
    useEffect(() => {
        getFunds();
        getAllFunds()
    }, [getFunds, getAllFunds]);

    // Import events using GraphQL query
    const [fetchEvents, { data: eventsData }] = useLazyQuery(GET_EVENTS, {
        fetchPolicy: 'network-only',
    });

    // Call fetchEvents when component mounts
    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    // Define test data
    const treasury = '999.999';

    // Define state variable and function for updating slide index
    const [indexFund, setIndexFund] = useState(0);
    function handleSwiperSlideChange(swiper) {
        const newIndex = swiper.activeIndex;
        setIndexFund(newIndex);
    }
    const selectedLanguage = i18n.language

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return <Grid container spacing={3}
        sx={{
            width: '95%',
            margin: '0 auto',
            backgroundColor: actualThemeLight ? null : '#262626'
        }}>
        <Grid item xs={0} md={1} />
        {/* INVESTOR METRICS */}
        <Grid item xs={12} md={10} sx={{ mt: { md: '16px', xs: 0 }, pr: { md: 0, xs: 3 } }}>
            <Box sx={{ position: 'absolute', mt: -2, width: '77%', display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                <Typography sx={{ fontSize: '10px', color: actualThemeLight ? 'black' : 'white' }}>{t('DASHBOARD.AMOUNTS')}</Typography>
            </Box>
            {locStorageRole === 'role-applicant' ? <ApplicantMetricsDashboard /> : <InvestorMetricsDashboard />}
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={0} md={1} />
            {/* ACTIVE FUNDS */}
            <Grid item xs={12} md={7} sx={{ mt: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ ml: '16px', fontSize: '20px', color: actualThemeLight ? 'black' : 'white' }}>
                        {t('DASHBOARD_VIEW.ACTIVE_FUNDS')}
                    </Typography>
                    <Link sx={{
                        cursor: 'pointer', mr: 4,
                        color: actualThemeLight ? null : '#e6a3bd',
                        textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                    }} onClick={() => {
                        dispatch(push('/funds'))
                    }}>
                        {t('DASHBOARD_VIEW.SEE_ALL_FUND')}
                    </Link>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Swiper
                        loop={false}
                        spaceBetween={10}
                        slidesPerView={1.1}
                        modules={[Navigation, Pagination]}
                        navigation={true}
                        grabCursor={true}
                        className="recommended tokens slider"
                        onSlideChange={handleSwiperSlideChange}
                    >
                        {funds?.map((fund, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <Box sx={{ mr: '24px', ml: { md: '24px', xs: '18px' }, width: { md: '100%', xs: '90%' } }}>
                                        <ActiveFundsDashboard
                                            data={fund}
                                            dataAll={funds[i]}
                                        />
                                    </Box>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Box>
            </Grid>
            {/* EVENTS */}
            <Grid item xs={11} md={3} sx={{ mt: '16px', ml: { md: 0, xs: 2 } }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ ml: '16px', fontSize: '20px', color: actualThemeLight ? 'black' : 'white' }}>
                        {t('DASHBOARD_VIEW.EVENTS')}
                    </Typography>
                    <Link sx={{
                        cursor: 'pointer', mr: 4, color: actualThemeLight ? null : '#e6a3bd',
                        textDecorationColor: actualThemeLight ? null : '#e6a3bd'
                    }} onClick={() => {
                        dispatch(push('/dao/calendar'))
                    }}>
                        {t('DASHBOARD_VIEW.SEE_ALL_EVENTS')}
                    </Link>
                </Box>
                <Card color='#FFF' mt={1}>
                    {eventsData?.events?.map((event, i) => {
                        if (i < 4) {
                            return (
                                <Events key={i}
                                    data={event}
                                />
                            )
                        }
                    })}
                </ Card>
            </Grid>
            <Grid item xs={0} md={1} />
        </Grid>
        <Grid container spacing={3} sx={{ mt: -3, mb: 3 }}>
            <Grid item md={1} />
            {/* ACTIVE PROPOSALS */}
            <Grid item xs={12} md={7} sx={{ mt: { md: '16px', xs: 0 } }}>
                {data?.funds[indexFund]?.proposal.length === 0 ?
                    <Typography sx={{ ml: '16px', fontSize: '20px', color: actualThemeLight ? 'black' : 'white' }}>
                        {t('FUND_SEARCH_VIEW.NO_COMPANIES_YET')}
                    </Typography>
                    :
                    <Typography sx={{ ml: '16px', fontSize: '20px' }}>
                        {t('DASHBOARD_VIEW.ACTIVE_PROPOSALS')}
                    </Typography>
                }
                <Box sx={{ mt: 1, mb: 4 }}>
                    <Swiper
                        loop={false}
                        spaceBetween={2}
                        slidesPerView={2}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            1024: {
                                slidesPerView: 2,
                            }
                        }}
                        modules={[Navigation, Pagination]}
                        navigation={true}
                        grabCursor={true}
                        className="recommended tokens slider"
                    >
                        {data?.funds[indexFund]?.proposal.map((proposal, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <Box sx={{ mr: 4, ml: 4, width: '100%' }}>
                                        <ActiveProposalCard
                                            id={proposal?.id}
                                            name={proposal?.company?.coName}
                                            logo={proposal?.company?.logo}
                                            state={proposal?.assessmentStatus}
                                            fundName={proposal?.fund?.name}
                                            address={proposal?.company?.address}
                                            country={proposal?.company?.country}
                                            link={proposal?.url[0]?.link}
                                            proposalTitle={
                                                selectedLanguage === 'en'
                                                    ? proposal?.titleEn
                                                    : selectedLanguage === 'es'
                                                        ? proposal?.titleEs || proposal?.titleEn || ''
                                                        : selectedLanguage === 'pt'
                                                            ? proposal?.titlePt || proposal?.titleEn || ''
                                                            : null
                                            }
                                            proposalScore={proposal?.assessmentRating}
                                            desc={
                                                selectedLanguage === 'en'
                                                    ? proposal?.descriptionEn
                                                    : selectedLanguage === 'es'
                                                        ? proposal?.descriptionEs ||
                                                        proposal?.descriptionEn ||
                                                        ''
                                                        : selectedLanguage === 'pt'
                                                            ? proposal?.descriptionPt ||
                                                            proposal?.descriptionEn ||
                                                            ''
                                                            : null
                                            }
                                            ADArequest={proposal?.reqInvestAmount}
                                            applicationId={proposal?.id}
                                            fundStatus={proposal?.fund?.status}
                                        />
                                    </Box>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Box>
            </Grid>
            {/* TOKEN ALLIES DAO */}
            <Grid item xs={11} md={3} sx={{ mb: 3, ml: { md: 0, xs: 2 } }} >
                <Box sx={{ mt: { md: '10px', xs: 0 } }}>
                    <AlliesNewsDashboard treasuryAmount={treasury} />
                </Box>
            </Grid>
            <Grid item md={1} />
        </Grid>
    </Grid>
}

export default newDashboardView