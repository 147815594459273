import React, { useState } from 'react'
import { Box, Button, IconButton, Link, Modal, TextField, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UPDATE_PREFERENCE } from '../../../Queries/Admin';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { showMessage } from '../../../store/actions/snackBarActions'

const InterestAdminEditModal = ({ data }) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const dispatch = useDispatch()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [interest, setInterest] = useState('')
    const handleChangeInterest = (event) => { setInterest(event.target.value) }
    const [updatePreference] = useMutation(UPDATE_PREFERENCE, {
        variables: {
            preferenceId: data?.data.id,
            preferenceName: interest
        },
    });
    const [loaded, setLoaded] = useState(false)

    const style = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: actualThemeLight ? 'rgba(229, 229, 229, 1)' : '#1d1d1d',
        borderRadius: '10px',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
        width: '450px',
        maxHeight: '100%',
    };

    useEffect(() => {
        if (data !== undefined && loaded === false) {
            setInterest(data?.data.preferenceName)
            setLoaded(true)
        }
    }, [data])


    const handleUpdatePreference = async () => {
        await updatePreference()
        await dispatch(showMessage({ message: 'Saved!', variant: 'success' }))
        await handleClose()
    }

    return (
        <>
            <Link sx={{ cursor: 'pointer' }} onClick={handleOpen} >
                {t('MY_OFFERINGS.MODIFY')}
            </Link>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'right' }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('ADMIN.PREFERENCE')}
                    </Typography>
                    <TextField
                        placeholder={interest}
                        value={interest}
                        required
                        onChange={handleChangeInterest}
                        sx={{ m: 2, width: '90%' }}>
                    </TextField>
                    <Button onClick={handleUpdatePreference} sx={{ width: '150px', mt: 2 }} variant="contained">
                        {t('CO_ASSESSMENT.SAVE')}
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default InterestAdminEditModal