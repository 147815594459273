import React, { Fragment, useEffect, useState } from "react"
import {
    Box, Typography, Avatar, styled, Button, Tabs, Tab, Grid, TextField, Tooltip, CircularProgress,
    MenuItem, Link, FormControl, OutlinedInput, InputAdornment, IconButton, Select, LinearProgress, Zoom,
    Tooltip as Tool,
    useTheme
} from "@mui/material"
import LaunchIcon from '@mui/icons-material/Launch';
import SearchIcon from '@mui/icons-material/Search'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import ReplyIcon from '@mui/icons-material/Reply';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_FUNDS_BY_ID } from "../../Queries/Funds"
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from "react-router"
import { CREATE_MESSAGE_TO_FUND, SEND_REPLY_TO_COMMENT_TO_FUND } from "../../Queries/Funds"
import { useAuth0 } from '@auth0/auth0-react'
const axios = require('axios')
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import Crecimiento from '../../../src/assets/icons/crecimiento.svg'
import CrecimientoWhite from '../../../src/assets/icons/crecimientoWhite.svg'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { GET_TOKENS } from '../../Queries/Wallet'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import i18n from "i18next";
import Arrows from '../../../src/assets/icons/Arrows.svg'
import Rocket from '../../../src/assets/icons/rocket.svg'
import RocketWhite from '../../../src/assets/icons/rocketWhite.svg'
import TokenAllies from '../../../src/assets/icons/TokenAlliesIcon.svg'
import InvestModal from "../../components/InvestModal/InvestModal";
import WaitingListModal from "../../components/InvestModal/WaitingListModal";
import { linearProgressClasses } from '@mui/material/LinearProgress';
import ActiveProposalCard from './../../components/ActiveProposalCard/ActiveProposalCard';

const FundView2 = () => {
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'
    const selectedLanguage = i18n.language;
    const locStorageRole = localStorage.getItem('role')
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { fundId } = useParams();
    const { user } = useAuth0();
    const myuuid = uuidv4();
    const dateTime = moment.utc()
    const userId = user?.email;
    const [textToSend, setTextToSend] = useState("");
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("awarded");
    const [toggleReply, setToggleReply] = useState(false);
    const [replyToPerson, setReplyToPerson] = useState("");
    const [replyToOriginalMsgId, setReplyToOriginalMsgId] = useState("");
    const [value, setValue] = useState(1);
    const [modalInvest, setModalInvest] = useState(false)
    const VotingLink = 'https://th.bing.com/th/id/OIP.asT4cZHIRx5Xu9-QHdLW0AHaEI?pid=ImgDet&rs=1'
    const handleVoteButtonClick = () => {
        window.open(VotingLink, '_blank');
    };

    const handleCloseInvest = () => {
        setModalInvest(false)
    }
    const [fetchFunds, { data: fundsById, loading: fundLoading }] = useLazyQuery(GET_FUNDS_BY_ID, {
        variables: { FundId: fundId },
        pollInterval: 1500,
    });

    function filterAndSortProposals(proposals, search) {
        const filteredProposals = proposals?.filter((proposal) => {
            const {
                titleEn,
                titleEs,
                titlePt,
                descriptionEn,
                descriptionEs,
                descriptionPt,
                company: { coName },
            } = proposal;
            const lowercaseSearch = search.toLowerCase();

            const matchFields = [
                titleEn,
                titleEs,
                titlePt,
                descriptionEn,
                descriptionEs,
                descriptionPt,
                coName,
            ];

            return matchFields.some(
                (field) => field && field.toLowerCase().includes(lowercaseSearch)
            );
        });

        const sortedProposals = filteredProposals.sort((a, b) => {
            const statusOrder = {
                assessed: 0,
                proposed: 1,
                rejected: 2,
            };

            const statusA = a.assessmentStatus.toLowerCase();
            const statusB = b.assessmentStatus.toLowerCase();

            if (statusOrder[statusA] < statusOrder[statusB]) {
                return -1;
            } else if (statusOrder[statusA] > statusOrder[statusB]) {
                return 1;
            } else {
                const proposalStatusA = a.status.toLowerCase();
                const proposalStatusB = b.status.toLowerCase();

                if (statusOrder[proposalStatusA] < statusOrder[proposalStatusB]) {
                    return -1;
                } else if (statusOrder[proposalStatusA] > statusOrder[proposalStatusB]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });

        return sortedProposals;
    }

    function calculateTotalAmount(data) {
        if (data && data.offerPurchase && Array.isArray(data.offerPurchase)) {
            const totalAmount = data.offerPurchase.reduce((total, offer) => {
                const amount = parseFloat(offer.amount);
                return isNaN(amount) ? total : total + amount;
            }, 0);

            return totalAmount;
        } else {
            return 0;
        }
    }

    const amountToReach = fundsById?.funds[0]?.amountToReach
    const totalAmountGathered = calculateTotalAmount(fundsById?.funds[0]);

    const [modalWaitingList, setModalWaitingList] = useState(false)
    const handleCloseWaitingList = () => {
        setModalWaitingList(false)
    }

    const proposals = fundsById?.funds[0]?.proposal || [];
    const sortedProposalsByAwarded = filterAndSortProposals([...proposals], search);


    function filterAndSortProposalsByRating(proposals, search) {
        const filteredProposals = proposals.filter((proposal) => {
            const {
                titleEn,
                titleEs,
                titlePt,
                descriptionEn,
                descriptionEs,
                descriptionPt,
                company: { coName },
            } = proposal;
            const lowercaseSearch = search.toLowerCase();

            const matchFields = [
                titleEn,
                titleEs,
                titlePt,
                descriptionEn,
                descriptionEs,
                descriptionPt,
                coName,
            ];

            return matchFields.some(
                (field) => field && field.toLowerCase().includes(lowercaseSearch)
            );
        });

        const sortedProposals = filteredProposals.sort((a, b) => {
            const assessmentRatingA = a.assessmentRating;
            const assessmentRatingB = b.assessmentRating;

            if (assessmentRatingA > assessmentRatingB) {
                return -1;
            } else if (assessmentRatingA < assessmentRatingB) {
                return 1;
            } else {
                return 0;
            }
        });

        return sortedProposals;
    }


    const sortedProposalsByRating = filterAndSortProposalsByRating([...proposals], search);

    function filterAndSortProposalsByDate(proposals, search) {
        const filteredProposals = proposals.filter((proposal) => {
            const {
                titleEn,
                titleEs,
                titlePt,
                descriptionEn,
                descriptionEs,
                descriptionPt,
                company: { coName },
            } = proposal;
            const lowercaseSearch = search.toLowerCase();

            const matchFields = [
                titleEn,
                titleEs,
                titlePt,
                descriptionEn,
                descriptionEs,
                descriptionPt,
                coName,
            ];

            return matchFields.some(
                (field) => field && field.toLowerCase().includes(lowercaseSearch)
            );
        });

        const sortedProposals = filteredProposals.sort((a, b) => {
            const dateA = new Date(a.on);
            const dateB = new Date(b.on);

            return dateB - dateA;
        });

        return sortedProposals;
    }

    const sortedProposalsByDate = filterAndSortProposalsByDate([...proposals], search);

    const [createMessage, { loading: loadingSendComment }] = useMutation(CREATE_MESSAGE_TO_FUND);
    const [sendReplyToComment, { loading: loadingSendReply }] = useMutation(SEND_REPLY_TO_COMMENT_TO_FUND);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await createMessage({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: textToSend,
                UserId: userId,
                FundId: fundId,
            },
        });
        setTextToSend("");
    };

    const handleSendReplyComment = async (e) => {
        e.preventDefault();
        await sendReplyToComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: textToSend,
                UserId: userId,
                FundId: fundId,
                replyToId: replyToOriginalMsgId,
            },
        });
        setTextToSend("");
        setToggleReply(false);
    };

    // Fetch funds by ID
    useEffect(() => {
        fetchFunds()
    }, [fetchFunds])


    const approvedApplicationsCount = fundsById?.funds[0]?.proposal.filter(
        (proposal) => proposal.assessmentStatus === 'assessed'
    ).length;

    const uniqueCompanies = new Set();
    fundsById?.funds[0]?.proposal.forEach((proposal) => {
        if (proposal.company && Array.isArray(proposal.company)) {
            proposal.company.forEach((company) => {
                uniqueCompanies.add(company);
            });
        }
    });

    const companiesAmount = uniqueCompanies.size;

    // Button styling for back button
    const BackButton = styled(Button)(({ theme }) => ({
        color: theme.palette.text.hint,
        fontFamily: 'Quicksand, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    }))

    // Filter messages with null reply
    const filteredData = fundsById?.funds[0]?.messages?.filter((item) => item.replyTo === null);

    const filteredReplies = fundsById?.funds[0]?.messages?.filter((item) => item.replyTo !== null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeSort = (event) => {
        setSort(event.target.value);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    useEffect(() => {
        {
            fundsById?.funds[0]?.status === "Assessment"
                || fundsById?.funds[0]?.status === "Governance"
                || fundsById?.funds[0]?.status === "ROI"
                || fundsById?.funds[0]?.status === "Vesting"
                || fundsById?.funds[0]?.status === "Open"
                || fundsById?.funds[0]?.status === "Execution"
                &&
                setValue(1)
        }
    }, [fundsById])

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const dummyData = [{ deliveredFunds: 0 }]
    const finalReport = "https://docs.google.com/document/d/1YVbG4veYCEjTjYimwC_XvDjkiwa3agIb9T34cprBefI/edit?usp=sharing"


    function countEmployeesOccurrences(proposals) {
        const employeeCountMap = new Map();
        proposals?.forEach(proposal => {
            const employeesString = proposal.company.employees;
            if (employeesString) {
                if (employeeCountMap.has(employeesString)) {
                    employeeCountMap.set(employeesString, employeeCountMap.get(employeesString) + 1);
                } else {
                    employeeCountMap.set(employeesString, 1);
                }
            }
        });
        const dataEx = Array.from(employeeCountMap).map(([name, qty]) => ({
            name,
            qty,
        }));

        return dataEx;
    }

    const employeesCount = countEmployeesOccurrences(fundsById?.funds[0]?.proposal);

    const [totalProposalCount, setTotalProposalCount] = useState(0)

    useEffect(() => {
        function countProposalStatuses(proposals) {
            let totalCount = 0;

            for (const proposal of proposals) {
                const status = proposal.status;

                if (status !== "draft") {
                    totalCount++;
                }
            }

            return totalCount;
        }
        if (fundsById?.funds[0]?.proposal.length > 0) {
            const total = countProposalStatuses(fundsById?.funds[0]?.proposal);
            setTotalProposalCount(total)
        }
    }, [fundsById])

    const [currEvent, setCurrEvent] = useState(null)
    const [fundStartingDate, setFundStartingDate] = useState(null)


    useEffect(() => {
        fundsById?.funds[0]?.event?.map((e) => {
            if (e?.titleEn === fundsById?.funds[0]?.status) {
                setCurrEvent(e)
            }
            if (e?.titleEn === "Collecting") {
                setFundStartingDate(e?.startDate?.split('T')[0])
            }
        })
    }, [fundsById])

    function calculateYearsSince(date) {
        const currentDate = new Date();
        const pastDate = new Date(date);

        var yearsDiff = currentDate.getFullYear() - pastDate.getFullYear();
        if (currentDate.getMonth() < pastDate.getMonth() ||
            (currentDate.getMonth() === pastDate.getMonth() && currentDate.getDate() < pastDate.getDate())) {
            yearsDiff--;
        }

        return yearsDiff;
    }


    const yearsSince = calculateYearsSince(fundStartingDate);

    function getDaysBetweenDates(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000;
        const start = new Date(startDate);
        const end = new Date(endDate);
        const timeDiff = end.getTime() - start.getTime();
        const daysDiff = Math.ceil(timeDiff / oneDay);
        return daysDiff;
    }

    const today = new Date()
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };

    const todayFormattedDate = today.toLocaleDateString("en-US", options);
    const eventDate = new Date(currEvent?.endDate?.split('T')[0] || fundsById?.funds[0]?.event[0]?.endDate?.split('T')[0])
    const eventFormattedDate = eventDate.toLocaleDateString("en-US", options);
    const daysBetween = getDaysBetweenDates(todayFormattedDate, eventFormattedDate);

    function calculateDaysLeft(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

        // Convert the date strings to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calculate the difference in days
        const timeDiff = end.getTime() - start.getTime();
        const daysDiff = Math.ceil(timeDiff / oneDay);

        // Return the number of days left
        return daysDiff;
    }

    const daysLeft = calculateDaysLeft(todayFormattedDate, eventFormattedDate);

    const excludedStatuses = [
        'Investment Intention',
        'Investment Cancelled',
        'SC Created',
        'SC Created - ALLI Deposited',
        'SC Created - ALLI Deposited - Pending',
        'SC Timed Out - Core Withdrawn',
        'Investment Cancelled - Target Met',
        'Investment Cancelled - Collecting Ended',
        'Investment Rejected - SC Not Created',
        'Investor Deposited - Pending'
    ];

    const validOfferPurchases = fundsById?.funds[0]?.offerPurchase?.filter(
        offer => !excludedStatuses?.includes(offer?.status)
    );

    function countValidOfferPurchases(offerPurchases) {

        const validOfferPurchases = offerPurchases?.filter(
            offer => !excludedStatuses?.includes(offer?.status)
        );

        return validOfferPurchases?.length;
    }
    const countOfValidOffPur = countValidOfferPurchases(fundsById?.funds[0]?.offerPurchase);

    function calculateAverageAmount(validOfferPurchases) {
        if (validOfferPurchases?.length === 0) {
            return 0;
        }

        const totalAmount = validOfferPurchases?.reduce(
            (sum, offer) => sum + offer?.amount,
            0
        );

        const averageAmount = totalAmount / validOfferPurchases?.length;
        return averageAmount;
    }

    const averageAmount = calculateAverageAmount(validOfferPurchases);

    function calculateTotalInvestment(validOfferPurchases) {
        if (validOfferPurchases?.length === 0) {
            return 0;
        }

        const totalAmount = validOfferPurchases?.reduce(
            (sum, offer) => sum + offer.amount,
            0
        );

        return totalAmount;
    }

    const totalInvestment = calculateTotalInvestment(validOfferPurchases);
    const [statusValue, setStatusValue] = useState(0)

    useEffect(() => {
        if (fundsById?.funds[0]?.status === "Collecting") {
            setStatusValue(1)
        }
        if (fundsById?.funds[0]?.status === "Published") {
            setStatusValue(2)
        }
        if (fundsById?.funds[0]?.status === "Open") {
            setStatusValue(3)
        }
        if (fundsById?.funds[0]?.status === "Assessment") {
            setStatusValue(4)
        }
        if (fundsById?.funds[0]?.status === "Governance") {
            setStatusValue(5)
        }
        if (fundsById?.funds[0]?.status === "Vesting") {
            setStatusValue(6)
        }
        if (fundsById?.funds[0]?.status === "Execution") {
            setStatusValue(7)
        }
        if (fundsById?.funds[0]?.status === "ROI") {
            setStatusValue(8)
        }
    }, [fundsById?.funds[0]?.status])

    const amountToReachThreshold = fundsById?.funds[0]?.amountToReach * (fundsById?.funds[0]?.successThreshold / 100)
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
        },
    }));

    function countAssessedProposals(obj) {
        let count = 0;

        if (obj?.proposal && Array.isArray(obj?.proposal)) {
            for (const proposal of obj?.proposal) {
                if (proposal.status === "assessed") {
                    count++;
                }
            }
        }

        return count;
    }

    const assessedProposalsCount = countAssessedProposals(fundsById?.funds[0]);
    const startDay = currEvent?.startDate?.split('-')[2].split('T')[0] || fundsById?.funds[0]?.event[0]?.startDate?.split('-')[2].split('T')[0]
    const startMonth = currEvent?.startDate?.split('-')[1] || fundsById?.funds[0]?.event[0]?.startDate?.split('-')[1]
    const endDay = currEvent?.endDate?.split('-')[2].split('T')[0] || fundsById?.funds[0]?.event[0]?.endDate?.split('-')[2].split('T')[0]
    const endMonth = currEvent?.endDate?.split('-')[1] || fundsById?.funds[0]?.event[0]?.endDate?.split('-')[1]

    const [getAllTokens, { data: tokensData }] = useLazyQuery(GET_TOKENS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        if (fundsById?.funds[0]?.status === 'Published') {
            getAllTokens()
        }
    }, [getAllTokens, fundsById])

    const blockFrostAPITest = `https://cardano-preprod.blockfrost.io/api/v0/assets/`
    const testProjectID = 'preprod0YDs6YpK8cM5KFJA7bTZbOAmWVW0D94s'
    const axiosBFClient = axios.create({
        baseURL: blockFrostAPITest,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            project_id: testProjectID,
        },
    })

    const tstALLIObject = tokensData?.tokens?.find(item => item.symbol === "tstALLI");
    const policyIdToQuerytstAlli = tstALLIObject?.policyId + tstALLIObject?.encodedName
    const [mintedtstALLI, setMintedtestALLI] = useState(undefined)

    function getMintedtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/history`)
            .then((res) => res.data)
        return finalRes
    }

    useEffect(() => {
        if (fundsById?.funds[0]?.status === 'Published') {

            getMintedtstAlli().then((res) => {
                setMintedtestALLI(res)
            })
        }
    }, [policyIdToQuerytstAlli, fundsById])

    function getMCtstAlli() {
        const finalRes = axiosBFClient
            .get(`${blockFrostAPITest}${policyIdToQuerytstAlli}/addresses`)
            .then((res) => res.data)
        return finalRes
    }

    const [addressesHoldingtstAlli, setAddressesHoldingtstAlli] = useState(undefined)

    useEffect(() => {
        if (fundsById?.funds[0]?.status === 'Published') {

            getMCtstAlli().then((res) => {
                setAddressesHoldingtstAlli(res)
            })
        }
    }, [policyIdToQuerytstAlli, fundsById])

    const excludedAddress = "addr_test1qq4hg23ralkz9wzv9tn9kd2c2rxrzxckz8fecrdmrc8qltcd7r8mfgmnp3eaaasrds0fd42feweqvwxk8qqk2sy60wus7aaykm";

    const [totalQuantityHoldersTstAllies, setTotalQuantityHoldersTstAllies] = useState(0);

    useEffect(() => {
        const filteredArray = addressesHoldingtstAlli?.filter(item => item.address !== excludedAddress);
        const sum = filteredArray?.reduce((acc, curr) => acc + parseInt(curr.quantity, 10), 0);
        setTotalQuantityHoldersTstAllies(sum);
    }, [addressesHoldingtstAlli, excludedAddress]);


    function sumMintedAmountstAlli(data) {
        if (!data || !Array.isArray(data)) {
            return 0;
        }

        let totalAmount = 0;
        for (const item of data) {
            if (item && item.action === "minted") {
                totalAmount += parseInt(item.amount);
            }
        }
        return totalAmount;
    }

    const totalMintedAmountTstAlli = sumMintedAmountstAlli(mintedtstALLI);
    // eslint-disable-next-line
    const [percentHoldingAlli, setPercentHoldingAlli] = useState(undefined)

    useEffect(() => {
        if (fundsById?.funds[0]?.status === 'Published') {
            setPercentHoldingAlli((totalQuantityHoldersTstAllies / totalMintedAmountTstAlli) * 100)
        }
    }, [totalQuantityHoldersTstAllies, totalMintedAmountTstAlli, fundsById])

    const [thresholdMet, setThresholdMet] = useState(false)

    useEffect(() => {
        if (totalInvestment >= (fundsById?.funds[0]?.amountToReach * fundsById?.funds[0]?.successThreshold / 100)) {
            setThresholdMet(true)
        } else {
            setThresholdMet(false)
        }
    }, [totalInvestment, fundsById])


    if (fundLoading) {
        return (
            <>
                <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: 8 }} xs={12} lg={12}>
                    <CircularProgress />
                </Grid>
            </>
        );
    }

    return <Fragment>
        <Grid container>
            {/* HEADER */}
            {fundsById?.funds[0]?.status === 'Open' ?
                <Grid item xs={12} lg={12} className="right">
                    <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                        <Grid container>
                            {/* BACK BUTTON & AVATAR */}
                            <Grid item md={1} xs={12}
                                sx={{
                                    alignSelf: 'center', alignItems: 'center',
                                    justifyContent: { md: 'center', xs: 'center' },
                                    mr: { md: 0, xs: '90%' }
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex', flexDirection: 'row',
                                        justifyContent: { md: 'center', xs: 'left' }, alignItems: 'center'
                                    }}>
                                    <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                    <BackButton
                                        onClick={() => {
                                            dispatch(goBack())
                                        }}
                                        sx={{
                                            marginBottom: '10px', color: 'white',
                                            textDecoration: 'underline', fontSize: '14px'
                                        }}
                                    >
                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                    </BackButton>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={4} sx={{
                                maxHeight: '16vh',
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} >
                                <Box
                                    sx={{
                                        width: '100px',
                                        height: '100px',
                                    }}
                                >
                                    {fundsById?.funds[0]?.image !== '' ?
                                        <Avatar sx={{
                                            width: '100%',
                                            height: '100%'
                                        }} src={fundsById?.funds[0]?.image}></Avatar>
                                        :
                                        <Avatar sx={{
                                            width: '100%',
                                            height: '100%'
                                        }} src={TokenAllies}></Avatar>
                                    }
                                </Box>
                            </Grid>
                            {/* FUND INFO */}
                            <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                <Grid container sx={{ color: '#FFF' }}>
                                    {/* COLUMN 1 */}
                                    <Grid item md={6} xs={12}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                {fundsById?.funds[0]?.name}
                                            </Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <FiberManualRecordIcon fontSize="large" />
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {fundsById?.funds[0]?.status}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
                                                <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                    {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                </Typography>
                                                {daysBetween > 0 &&
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        <HourglassBottomIcon />
                                                        <Typography sx={{ fontSize: '14px' }}>
                                                            {t('FUND_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* COLUMN 2 */}
                                    <Grid item md={6} xs={12} sx={{ alignSelf: 'center', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {/* PURPOSE */}
                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                {t('FUND_VIEW.PURPOSE')}:
                                            </Typography>
                                            <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                    {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                        selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                            selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {/* AMOUNT COLLECTED */}
                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                {t('FUND.DELIVERED_FUNDS')}:
                                            </Typography>
                                            <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                    {totalInvestment}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                : fundsById?.funds[0]?.status === 'Execution' ?
                    <Grid item xs={12} lg={12} className="right">
                        <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                            <Grid container>
                                {/* BACK BUTTON & AVATAR */}
                                <Grid item md={1} xs={12}
                                    sx={{
                                        alignSelf: 'center', alignItems: 'center',
                                        justifyContent: { md: 'center', xs: 'center' }, mr: { md: 0, xs: '90%' }
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex', flexDirection: 'row',
                                            justifyContent: { md: 'center', xs: 'left' },
                                            alignItems: 'center'
                                        }}>
                                        <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                        <BackButton
                                            onClick={() => {
                                                dispatch(goBack())
                                            }}
                                            sx={{
                                                marginBottom: '10px', color: 'white',
                                                textDecoration: 'underline', fontSize: '14px'
                                            }}
                                        >
                                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                        </BackButton>
                                    </Box>
                                </Grid>
                                <Grid item md={2} xs={4} sx={{
                                    maxHeight: '16vh',
                                    alignSelf: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} >
                                    <Box
                                        sx={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                    >
                                        {fundsById?.funds[0]?.image !== '' ?
                                            <Avatar sx={{
                                                width: '100%',
                                                height: '100%'
                                            }} src={fundsById?.funds[0]?.image}></Avatar>
                                            :
                                            <Avatar sx={{
                                                width: '100%',
                                                height: '100%'
                                            }} src={TokenAllies}></Avatar>
                                        }
                                    </Box>
                                </Grid>
                                {/* FUND INFO */}
                                <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                    <Grid container sx={{ color: '#FFF' }}>
                                        {/* COLUMN 1 */}
                                        <Grid item md={6} xs={12}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                    {fundsById?.funds[0]?.name}
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <FiberManualRecordIcon fontSize="large" />
                                                    <Typography sx={{ fontSize: '18px' }}>
                                                        {fundsById?.funds[0]?.status}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {/* COLUMN 2 */}
                                        <Grid item md={6} xs={12}
                                            sx={{
                                                alignSelf: 'center', height: '100%',
                                                display: 'flex', flexDirection: 'column'
                                            }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                {/* PURPOSE */}
                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                    {t('FUND_VIEW.PURPOSE')}:
                                                </Typography>
                                                <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                {/* AMOUNT COLLECTED */}
                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                    {t('FUND.DELIVERED_FUNDS')}:
                                                </Typography>
                                                <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                        {totalInvestment}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    : fundsById?.funds[0]?.status === 'Governance' ?
                        <Grid item xs={12} lg={12} className="right">
                            <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                <Grid container>
                                    {/* BACK BUTTON & AVATAR */}
                                    <Grid item md={1} xs={12}
                                        sx={{
                                            alignSelf: 'center', alignItems: 'center',
                                            justifyContent: { md: 'center', xs: 'center' },
                                            mr: { md: 0, xs: '90%' }
                                        }}>
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'row',
                                                justifyContent: { md: 'center', xs: 'left' },
                                                alignItems: 'center'
                                            }}>
                                            <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                            <BackButton
                                                onClick={() => {
                                                    dispatch(goBack())
                                                }}
                                                sx={{
                                                    marginBottom: '10px', color: 'white',
                                                    textDecoration: 'underline', fontSize: '14px'
                                                }}
                                            >
                                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                            </BackButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} sx={{
                                        maxHeight: '16vh',
                                        alignSelf: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} >
                                        <Box
                                            sx={{
                                                width: '100px',
                                                height: '100px',
                                            }}
                                        >
                                            {fundsById?.funds[0]?.image !== '' ?
                                                <Avatar sx={{
                                                    width: '100%',
                                                    height: '100%'
                                                }} src={fundsById?.funds[0]?.image}></Avatar>
                                                :
                                                <Avatar sx={{
                                                    width: '100%',
                                                    height: '100%'
                                                }} src={TokenAllies}></Avatar>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* FUND INFO */}
                                    <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                        <Grid container sx={{ color: '#FFF' }}>
                                            {/* COLUMN 1 */}
                                            <Grid item md={6} xs={12}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                        {fundsById?.funds[0]?.name}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <FiberManualRecordIcon fontSize="large" />
                                                        <Typography sx={{ fontSize: '18px' }}>
                                                            {fundsById?.funds[0]?.status}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
                                                        <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                        </Typography>
                                                        {daysBetween > 0 &&
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                <HourglassBottomIcon />
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {t('FUND_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* COLUMN 2 */}
                                            <Grid item md={6} xs={12}
                                                sx={{
                                                    alignSelf: 'center', height: '100%',
                                                    display: 'flex', flexDirection: 'column'
                                                }}>
                                                {/* VOTING */}
                                                {locStorageRole === 'role-investor' ? <Button
                                                    onClick={handleVoteButtonClick}
                                                    sx={{
                                                        display: 'flex', flexDirection: 'row',
                                                        justifyContent: 'flex-start'
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '16px', fontWeight: 'bold',
                                                            textDecoration: 'underline', color: '#FFF'
                                                        }}>
                                                        {t('FUND.VOTING')}
                                                    </Typography>
                                                </Button> : null}
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    {/* PURPOSE */}
                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                        {t('FUND_VIEW.PURPOSE')}:
                                                    </Typography>
                                                    <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                            {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                    selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    {/* AMOUNT COLLECTED */}
                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                        {t('FUND.DELIVERED_FUNDS')}:
                                                    </Typography>
                                                    <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                            {totalInvestment}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        : fundsById?.funds[0]?.status === 'Assessment' ?
                            <Grid item xs={12} lg={12} className="right">
                                <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                    <Grid container>
                                        {/* BACK BUTTON & AVATAR */}
                                        <Grid item md={1} xs={12}
                                            sx={{
                                                alignSelf: 'center', alignItems: 'center',
                                                justifyContent: { md: 'center', xs: 'center' },
                                                mr: { md: 0, xs: '90%' }
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'row',
                                                    justifyContent: { md: 'center', xs: 'left' },
                                                    alignItems: 'center'
                                                }}>
                                                <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                <BackButton
                                                    onClick={() => {
                                                        dispatch(goBack())
                                                    }}
                                                    sx={{
                                                        marginBottom: '10px', color: 'white',
                                                        textDecoration: 'underline', fontSize: '14px'
                                                    }}
                                                >
                                                    {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                </BackButton>
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} xs={4} sx={{
                                            maxHeight: '16vh',
                                            alignSelf: 'center',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} >
                                            <Box
                                                sx={{
                                                    width: '100px',
                                                    height: '100px',
                                                }}
                                            >
                                                {fundsById?.funds[0]?.image !== '' ?
                                                    <Avatar sx={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }} src={fundsById?.funds[0]?.image}></Avatar>
                                                    :
                                                    <Avatar sx={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }} src={TokenAllies}></Avatar>
                                                }
                                            </Box>
                                        </Grid>
                                        {/* FUND INFO */}
                                        <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                            <Grid container sx={{ color: '#FFF' }}>
                                                {/* COLUMN 1 */}
                                                <Grid item md={6} xs={12}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                            {fundsById?.funds[0]?.name}
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <FiberManualRecordIcon fontSize="large" />
                                                            <Typography sx={{ fontSize: '18px' }}>
                                                                {fundsById?.funds[0]?.status}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
                                                            <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                                {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                            </Typography>
                                                            {daysBetween > 0 &&
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <HourglassBottomIcon />
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        {t('FUND_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                {/* COLUMN 2 */}
                                                <Grid item md={6} xs={12}
                                                    sx={{
                                                        alignSelf: 'center', height: '100%',
                                                        display: 'flex', flexDirection: 'column'
                                                    }}>
                                                    {/* VOTING */}
                                                    {locStorageRole === 'role-investor' ? <Button sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '16px', fontWeight: 'bold',
                                                                textDecoration: 'underline', color: '#FFF'
                                                            }}>
                                                            {t('FUND.VOTING')}
                                                        </Typography>
                                                    </Button> : null}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        {/* PURPOSE */}
                                                        <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                            {t('FUND_VIEW.PURPOSE')}:
                                                        </Typography>
                                                        <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        {/* AMOUNT COLLECTED */}
                                                        <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                            {t('FUND.AVAILABLE_FUNDS')}:
                                                        </Typography>
                                                        <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                {totalInvestment}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            : fundsById?.funds[0]?.status === 'Published' ?
                                <Grid item xs={12} lg={12} className="right">
                                    <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                        <Grid container>
                                            {/* BACK BUTTON & AVATAR */}
                                            <Grid item md={1} xs={12}
                                                sx={{
                                                    alignSelf: 'center', alignItems: 'center',
                                                    justifyContent: { md: 'center', xs: 'center' },
                                                    mr: { md: 0, xs: '90%' }
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'row',
                                                        justifyContent: { md: 'center', xs: 'left' },
                                                        alignItems: 'center'
                                                    }}>
                                                    <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                    <BackButton
                                                        onClick={() => {
                                                            dispatch(goBack())
                                                        }}
                                                        sx={{
                                                            marginBottom: '10px', color: 'white',
                                                            textDecoration: 'underline', fontSize: '14px'
                                                        }}
                                                    >
                                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                    </BackButton>
                                                </Box>
                                            </Grid>
                                            <Grid item md={2} xs={4} sx={{
                                                maxHeight: '16vh',
                                                alignSelf: 'center',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }} >
                                                <Box
                                                    sx={{
                                                        width: '100px',
                                                        height: '100px',
                                                    }}
                                                >
                                                    {fundsById?.funds[0]?.image !== '' ?
                                                        <Avatar sx={{
                                                            width: '100%',
                                                            height: '100%'
                                                        }} src={fundsById?.funds[0]?.image}></Avatar>
                                                        :
                                                        <Avatar sx={{
                                                            width: '100%',
                                                            height: '100%'
                                                        }} src={TokenAllies}></Avatar>
                                                    }
                                                </Box>
                                            </Grid>
                                            {/* FUND INFO */}
                                            <Grid item md={8} xs={8}
                                                sx={{
                                                    alignSelf: 'center',
                                                    mb: { md: 0, xs: 2 }
                                                }}>
                                                <Grid container sx={{ color: '#FFF' }}>
                                                    {/* COLUMN 1 */}
                                                    <Grid item md={6} xs={12}>
                                                        <Box
                                                            sx={{
                                                                alignSelf: 'flex-end', justifyContent: 'flex-end',
                                                                height: '100%', display: 'flex', flexDirection: 'column'
                                                            }}>
                                                            {/* FUND NAME */}
                                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                {fundsById?.funds[0]?.name}
                                                            </Typography>
                                                            {/* STATUS */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <FiberManualRecordIcon fontSize="large" />
                                                                <Typography sx={{ fontSize: '18px' }}>
                                                                    {fundsById?.funds[0]?.status}
                                                                </Typography>
                                                                <Box sx={{ position: 'absolute', ml: 1.6 }}>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* DATES */}
                                                            {daysBetween > 0 &&
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <HourglassBottomIcon />
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        {t('FUND_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                                    </Typography>
                                                                </Box>}
                                                        </Box>
                                                    </Grid>
                                                    {/* COLUMN 2 */}
                                                    <Grid item md={6} xs={12}
                                                        sx={{
                                                            alignSelf: 'center', height: '100%',
                                                            display: 'flex', flexDirection: 'column'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            {/* PURPOSE */}
                                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                {t('FUND_VIEW.PURPOSE')}:
                                                            </Typography>
                                                            <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                    {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                        selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                            selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            {/* AMOUNT COLLECTED */}
                                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                {t('FUND.AVAILABLE_FUNDS')}:
                                                            </Typography>
                                                            <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                    {totalInvestment}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                : fundsById?.funds[0]?.status === 'ROI' ?
                                    <Grid item xs={12} lg={12} className="right">
                                        <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                            <Grid container>
                                                {/* BACK BUTTON & AVATAR */}
                                                <Grid item md={1} xs={12}
                                                    sx={{
                                                        alignSelf: 'center', alignItems: 'center',
                                                        justifyContent: { md: 'center', xs: 'center' },
                                                        mr: { md: 0, xs: '90%' }
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'row',
                                                            justifyContent: { md: 'center', xs: 'left' },
                                                            alignItems: 'center'
                                                        }}>
                                                        <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                        <BackButton
                                                            onClick={() => {
                                                                dispatch(goBack())
                                                            }}
                                                            sx={{
                                                                marginBottom: '10px', color: 'white',
                                                                textDecoration: 'underline', fontSize: '14px'
                                                            }}
                                                        >
                                                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                        </BackButton>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={2} xs={4} sx={{
                                                    maxHeight: '16vh',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }} >
                                                    <Box
                                                        sx={{
                                                            width: '100px',
                                                            height: '100px',
                                                        }}
                                                    >
                                                        {fundsById?.funds[0]?.image !== '' ?
                                                            <Avatar sx={{
                                                                width: '100%',
                                                                height: '100%'
                                                            }} src={fundsById?.funds[0]?.image}></Avatar>
                                                            :
                                                            <Avatar sx={{
                                                                width: '100%',
                                                                height: '100%'
                                                            }} src={TokenAllies}></Avatar>
                                                        }
                                                    </Box>
                                                </Grid>
                                                {/* FUND INFO */}
                                                <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                                    <Grid container sx={{ color: '#FFF' }}>

                                                        <Grid item md={6} xs={6}
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column',
                                                                alignSelf: 'flex-start', justifyContent: 'flex-start'
                                                            }}>
                                                            {/* FUND NAME */}
                                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                {fundsById?.funds[0]?.name}
                                                            </Typography>
                                                            {/* STATUS */}
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <FiberManualRecordIcon fontSize="large" />
                                                                <Typography sx={{ fontSize: '18px' }}>
                                                                    {fundsById?.funds[0]?.status}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        {/* COLUMN 2 */}
                                                        <Grid item md={6} xs={12}
                                                            sx={{
                                                                alignSelf: 'center', height: '100%',
                                                                display: 'flex', flexDirection: 'column'
                                                            }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                {/* PURPOSE */}
                                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                    {t('FUND_VIEW.PURPOSE')}:
                                                                </Typography>
                                                                <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                {/* AMOUNT COLLECTED */}
                                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                    {t('FUND.AVAILABLE_FUNDS')}:
                                                                </Typography>
                                                                <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                        {totalInvestment}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    : fundsById?.funds[0]?.status === 'Vesting' ?
                                        <Grid item xs={12} lg={12} className="right">
                                            <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                                <Grid container>
                                                    {/* BACK BUTTON & AVATAR */}
                                                    <Grid item md={1} xs={12}
                                                        sx={{
                                                            alignSelf: 'center', alignItems: 'center',
                                                            justifyContent: { md: 'center', xs: 'center' },
                                                            mr: { md: 0, xs: '90%' }
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'row',
                                                                justifyContent: { md: 'center', xs: 'left' },
                                                                alignItems: 'center'
                                                            }}>
                                                            <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                            <BackButton
                                                                onClick={() => {
                                                                    dispatch(goBack())
                                                                }}
                                                                sx={{
                                                                    marginBottom: '10px', color: 'white',
                                                                    textDecoration: 'underline', fontSize: '14px'
                                                                }}
                                                            >
                                                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                            </BackButton>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={2} xs={4} sx={{
                                                        maxHeight: '16vh',
                                                        alignSelf: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }} >
                                                        <Box
                                                            sx={{
                                                                width: '100px',
                                                                height: '100px',
                                                            }}
                                                        >
                                                            {fundsById?.funds[0]?.image !== '' ?
                                                                <Avatar sx={{
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }} src={fundsById?.funds[0]?.image}></Avatar>
                                                                :
                                                                <Avatar sx={{
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }} src={TokenAllies}></Avatar>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    {/* FUND INFO */}
                                                    <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                                        <Grid container sx={{ color: '#FFF' }}>
                                                            {/* COLUMN 1 */}
                                                            <Grid item md={6} xs={12}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                        {fundsById?.funds[0]?.name}
                                                                    </Typography>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <FiberManualRecordIcon fontSize="large" />
                                                                        <Typography sx={{ fontSize: '18px' }}>
                                                                            {fundsById?.funds[0]?.status}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                                        </Typography>
                                                                        {daysBetween > 0 &&
                                                                            <>
                                                                                <HourglassBottomIcon />
                                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                                    {t('FUND_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                                                </Typography>
                                                                            </>
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            {/* COLUMN 2 */}
                                                            <Grid item md={6} xs={12}
                                                                sx={{
                                                                    alignSelf: 'center', height: '100%',
                                                                    display: 'flex', flexDirection: 'column'
                                                                }}>
                                                                {/* VOTING */}
                                                                {locStorageRole === 'role-investor' ? <Link href={VotingLink}
                                                                    target={{ _blank: false }}
                                                                    variant='text'
                                                                    sx={{
                                                                        fontSize: '16px', pr: '4px',
                                                                        textDecoration: 'underline', color: 'white'
                                                                    }}>
                                                                    {t('FUND.VOTING')}
                                                                </Link> : null}
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    {/* PURPOSE */}
                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                        {t('FUND_VIEW.PURPOSE')}:
                                                                    </Typography>
                                                                    <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                            {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                                selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                                    selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                    {/* AMOUNT COLLECTED */}
                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                        {t('FUND.AVAILABLE_FUNDS')}:
                                                                    </Typography>
                                                                    <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                            {totalInvestment}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        : fundsById?.funds[0]?.status === 'Collecting' ?
                                            (
                                                <Grid item xs={12} lg={12} className="right">
                                                    <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                                        <Grid container>
                                                            {/* BACK BUTTON & AVATAR */}
                                                            <Grid item md={1} xs={12}
                                                                sx={{
                                                                    alignSelf: 'center', alignItems: 'center',
                                                                    justifyContent: { md: 'center', xs: 'center' },
                                                                    mr: { md: 0, xs: '90%' }
                                                                }}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: { md: 'center', xs: 'left' }, alignItems: 'center' }}>
                                                                    <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                                    <BackButton
                                                                        onClick={() => {
                                                                            dispatch(goBack())
                                                                        }}
                                                                        sx={{
                                                                            marginBottom: '10px', color: 'white',
                                                                            textDecoration: 'underline', fontSize: '14px'
                                                                        }}
                                                                    >
                                                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                                    </BackButton>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={2} xs={4} sx={{
                                                                maxHeight: '16vh',
                                                                alignSelf: 'center',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }} >
                                                                <Box
                                                                    sx={{
                                                                        width: '100px',
                                                                        height: '100px',
                                                                    }}
                                                                >
                                                                    {fundsById?.funds[0]?.image !== '' ?
                                                                        <Avatar sx={{
                                                                            width: '100%',
                                                                            height: '100%'
                                                                        }} src={fundsById?.funds[0]?.image}></Avatar>
                                                                        :
                                                                        <Avatar sx={{
                                                                            width: '100%',
                                                                            height: '100%'
                                                                        }} src={TokenAllies}></Avatar>
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                            {/* FUND INFO */}
                                                            <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                                                <Grid container sx={{ color: '#FFF' }}>
                                                                    {/* COLUMN 1 */}
                                                                    <Grid item md={6} xs={12}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                                {fundsById?.funds[0]?.name}
                                                                            </Typography>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                <FiberManualRecordIcon fontSize="large" />
                                                                                <Typography sx={{ fontSize: '18px' }}>
                                                                                    {fundsById?.funds[0]?.status}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                                            </Box>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                                                    {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                                                </Typography>
                                                                                {daysBetween > 0 &&
                                                                                    <>
                                                                                        <HourglassBottomIcon />
                                                                                        <Typography sx={{ fontSize: '14px' }}>
                                                                                            {t('FUND_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    {/* COLUMN 2 */}
                                                                    <Grid item md={6} xs={12}
                                                                        sx={{
                                                                            alignSelf: 'center', height: '100%', display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                            {/* PURPOSE */}
                                                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                {t('FUND_VIEW.PURPOSE')}:
                                                                            </Typography>
                                                                            <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                    {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                                        selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                                            selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                            {/* AMOUNT COLLECTED */}
                                                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                {t('FUND.AVAILABLE_FUNDS')}:
                                                                            </Typography>
                                                                            <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                    $ {totalInvestment}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            )
                                            : fundsById?.funds[0]?.status === 'Closed' || fundsById?.funds[0]?.status === 'Closed - Not Fully Funded' && (totalInvestment) < amountToReachThreshold ?
                                                (
                                                    <Grid item xs={12} lg={12} className="right">
                                                        <Box sx={{ backgroundColor: '#4F4F4F' }}>
                                                            <Grid container>
                                                                {/* BACK BUTTON & AVATAR */}
                                                                <Grid item md={1} xs={12}
                                                                    sx={{
                                                                        alignSelf: 'center', alignItems: 'center',
                                                                        justifyContent: { md: 'center', xs: 'center' },
                                                                        mr: { md: 0, xs: '90%' }
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex', flexDirection: 'row',
                                                                            justifyContent: { md: 'center', xs: 'left' },
                                                                            alignItems: 'center'
                                                                        }}>
                                                                        <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                                        <BackButton
                                                                            onClick={() => {
                                                                                dispatch(goBack())
                                                                            }}
                                                                            sx={{
                                                                                marginBottom: '10px', color: 'white',
                                                                                textDecoration: 'underline', fontSize: '14px'
                                                                            }}
                                                                        >
                                                                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                                        </BackButton>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={4} sx={{
                                                                    maxHeight: '16vh',
                                                                    alignSelf: 'center',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }} >
                                                                    <Box
                                                                        sx={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                        }}
                                                                    >
                                                                        {fundsById?.funds[0]?.image !== '' ?
                                                                            <Avatar sx={{
                                                                                width: '100%',
                                                                                height: '100%'
                                                                            }} src={fundsById?.funds[0]?.image}></Avatar>
                                                                            :
                                                                            <Avatar sx={{
                                                                                width: '100%',
                                                                                height: '100%'
                                                                            }} src={TokenAllies}></Avatar>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                {/* FUND INFO */}
                                                                <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                                                    <Grid container sx={{ color: '#FFF' }}>
                                                                        {/* COLUMN 1 */}
                                                                        <Grid item md={6} xs={12}>
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex', alignSelf: 'flex-end',
                                                                                    flexDirection: 'column', justifyContent: 'flex-end'
                                                                                }}>
                                                                                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                                    {fundsById?.funds[0]?.name}
                                                                                </Typography>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                    <Typography sx={{ fontSize: '18px' }}>
                                                                                        {fundsById?.funds[0]?.status}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                                                        {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                                                    </Typography>
                                                                                    <HourglassBottomIcon />
                                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                                        {t('FUND_CARD.FINALIZED')}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                        {/* COLUMN 2 */}
                                                                        <Grid item md={6} xs={12}
                                                                            sx={{
                                                                                alignSelf: 'center', height: '100%', display: 'flex',
                                                                                flexDirection: 'column'
                                                                            }}>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                {/* PURPOSE */}
                                                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                    {t('FUND_VIEW.PURPOSE')}:
                                                                                </Typography>
                                                                                <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                {/* AMOUNT COLLECTED */}
                                                                                <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                    {t('FUND.AVAILABLE_FUNDS')}:
                                                                                </Typography>
                                                                                <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                        $ {totalInvestment}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                )
                                                : fundsById?.funds[0]?.status === 'Closed' || fundsById?.funds[0]?.status === 'Closed - Not Fully Funded' && (totalInvestment) > amountToReachThreshold ?
                                                    (
                                                        <Grid item xs={12} lg={12} className="right">
                                                            <Box sx={{ backgroundColor: '#4F4F4F' }}>
                                                                <Grid container>
                                                                    {/* BACK BUTTON & AVATAR */}
                                                                    <Grid item md={1} xs={12}
                                                                        sx={{
                                                                            alignSelf: 'center', alignItems: 'center',
                                                                            justifyContent: { md: 'center', xs: 'center' },
                                                                            mr: { md: 0, xs: '90%' }
                                                                        }}>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex', flexDirection: 'row',
                                                                                justifyContent: { md: 'center', xs: 'left' },
                                                                                alignItems: 'center'
                                                                            }}>
                                                                            <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                                            <BackButton
                                                                                onClick={() => {
                                                                                    dispatch(goBack())
                                                                                }}
                                                                                sx={{
                                                                                    marginBottom: '10px', color: 'white',
                                                                                    textDecoration: 'underline', fontSize: '14px'
                                                                                }}
                                                                            >
                                                                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                                            </BackButton>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={2} xs={4} sx={{
                                                                        maxHeight: '16vh',
                                                                        alignSelf: 'center',
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }} >
                                                                        <Box
                                                                            sx={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                            }}
                                                                        >
                                                                            {fundsById?.funds[0]?.image !== '' ?
                                                                                <Avatar sx={{
                                                                                    width: '100%',
                                                                                    height: '100%'
                                                                                }} src={fundsById?.funds[0]?.image}></Avatar>
                                                                                :
                                                                                <Avatar sx={{
                                                                                    width: '100%',
                                                                                    height: '100%'
                                                                                }} src={TokenAllies}></Avatar>
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                    {/* FUND INFO */}
                                                                    <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                                                        <Grid container sx={{ color: '#FFF' }}>
                                                                            {/* COLUMN 1 */}
                                                                            <Grid item md={6} xs={12}>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex', alignSelf: 'flex-end',
                                                                                        flexDirection: 'column', justifyContent: 'flex-end'
                                                                                    }}>
                                                                                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                                        {fundsById?.funds[0]?.name}
                                                                                    </Typography>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                        <FiberManualRecordIcon fontSize="large" />
                                                                                        <Typography sx={{ fontSize: '18px' }}>
                                                                                            {fundsById?.funds[0]?.status}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                                                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                            {/* COLUMN 2 */}
                                                                            <Grid item md={6} xs={12}
                                                                                sx={{
                                                                                    alignSelf: 'center', height: '100%',
                                                                                    display: 'flex', flexDirection: 'column'
                                                                                }}>
                                                                                {/* VOTING */}
                                                                                <Link href={finalReport}
                                                                                    target={{ _blank: false }}
                                                                                    variant='text'
                                                                                    sx={{
                                                                                        fontSize: '16px', pr: '4px',
                                                                                        textDecoration: 'underline', color: 'white',
                                                                                        fontWeight: 'bold'
                                                                                    }}>
                                                                                    {t('FUND.DOWNLOAD_FINAL_REPORT')}
                                                                                </Link>
                                                                                {/* PURPOSE */}
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                        {t('FUND_VIEW.PURPOSE')}:
                                                                                    </Typography>
                                                                                    <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                            {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                                                selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                                                    selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                {/* AMOUNT COLLECTED */}
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                        {t('FUND.AVAILABLE_FUNDS')}:
                                                                                    </Typography>
                                                                                    <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                            $ {totalInvestment}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                    :
                                                    (
                                                        <Grid item xs={12} lg={12} className="right">
                                                            <Box sx={{ backgroundColor: actualThemeLight ? '#9A1D4C' : "#57313f" }}>
                                                                <Grid container>
                                                                    {/* BACK BUTTON & AVATAR */}
                                                                    <Grid item md={1} xs={12}
                                                                        sx={{
                                                                            alignSelf: 'center', alignItems: 'center',
                                                                            justifyContent: { md: 'center', xs: 'center' },
                                                                            mr: { md: 0, xs: '90%' }
                                                                        }}>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex', flexDirection: 'row',
                                                                                justifyContent: { md: 'center', xs: 'left' },
                                                                                alignItems: 'center'
                                                                            }}>
                                                                            <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                                                            <BackButton
                                                                                onClick={() => {
                                                                                    dispatch(goBack())
                                                                                }}
                                                                                sx={{
                                                                                    marginBottom: '10px', color: 'white',
                                                                                    textDecoration: 'underline', fontSize: '14px'
                                                                                }}
                                                                            >
                                                                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                                                            </BackButton>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={2} xs={4} sx={{
                                                                        maxHeight: '16vh',
                                                                        alignSelf: 'center',
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }} >
                                                                        <Box
                                                                            sx={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                            }}
                                                                        >
                                                                            {fundsById?.funds[0]?.image !== '' ?
                                                                                <Avatar sx={{
                                                                                    width: '100%',
                                                                                    height: '100%'
                                                                                }} src={fundsById?.funds[0]?.image}></Avatar>
                                                                                :
                                                                                <Avatar sx={{
                                                                                    width: '100%',
                                                                                    height: '100%'
                                                                                }} src={TokenAllies}></Avatar>
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                    {/* FUND INFO */}
                                                                    <Grid item md={8} xs={8} sx={{ alignSelf: 'center', mb: { md: 0, xs: 2 } }}>
                                                                        <Grid container sx={{ color: '#FFF' }}>
                                                                            <Grid item md={0.5} xs={0.5} />
                                                                            {/* COLUMN 1 */}
                                                                            <Grid item md={6} xs={12}>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                                        {fundsById?.funds[0]?.name}
                                                                                    </Typography>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                        <FiberManualRecordIcon fontSize="large" />
                                                                                        <Typography sx={{ fontSize: '18px' }}>
                                                                                            {fundsById?.funds[0]?.status}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ position: 'absolute', ml: 1.6, mt: 4.4 }}>
                                                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{statusValue}</Typography>
                                                                                    </Box>
                                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                                        {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            {/* COLUMN 2 */}
                                                                            <Grid item md={2.5} xs={12} sx={{ alignSelf: 'center', height: '100%' }}>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                                                                                    <Typography sx={{ pr: '4px' }} >
                                                                                        {t('FUND.INVESTMENT_TOKEN')}:
                                                                                    </Typography>
                                                                                    <Typography sx={{ pr: '4px' }} >
                                                                                        {t('FUND_VIEW.ADA_VALUE')}:
                                                                                    </Typography>
                                                                                    <Typography sx={{ pr: '4px' }} >
                                                                                        {t('FUND_VIEW.ADA_COLLECTED')}:
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={2} xs={2} sx={{ alignSelf: 'center', height: '100%' }}>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                                                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                                                        {fundsById?.funds[0]?.minInvestment?.toLocaleString()}
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                                                        {fundsById?.funds[0]?.amountToReach?.toLocaleString()}
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                                                        {totalInvestment?.toLocaleString()}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            {/* COLUMN 3 */}
                                                                            <Grid item md={2} xs={2} sx={{ alignSelf: 'center', height: '100%' }}>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                        {t('FUND.VESTING.VESTING')}:
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                        {t('FUND_VIEW.PURPOSE')}:
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                                                        {t('FUND_VIEW.ADA_COLLECTED')}:
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={2} xs={2} sx={{ alignSelf: 'center', height: '100%' }}>
                                                                                <Tooltip sx={{ mr: -1 }} title={t('CREATE_OFFERING.VESTING_TOOLTIP')}>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                                                {fundsById?.funds[0]?.vestingPlan}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                                {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box sx={{ height: '25px', overflow: 'hidden' }}>
                                                                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                                                {totalInvestment?.toLocaleString()}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item md={0.5} xs={0.5} />
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    )
            }

            {/* FUND DESCRIPTION & CTA */}
            <Grid item lg={12} xs={12} sx={{ mt: 2 }}>
                <Grid container lg={12} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                    {/* FUND DESCRIPTION */}
                    <Tabs sx={{ ml: { md: 8, xs: 2 } }} value={value} onChange={handleChange} aria-label="pestañas">
                        {(fundsById?.funds[0]?.status === "Assessment" ||
                            fundsById?.funds[0]?.status === "Governance" ||
                            fundsById?.funds[0]?.status === "Vesting" ||
                            fundsById?.funds[0]?.status === "Execution" ||
                            fundsById?.funds[0]?.status === "Open" ||
                            (fundsById?.funds[0]?.status === 'Closed' || fundsById?.funds[0]?.status === 'Closed - Not Fully Funded' && totalInvestment > amountToReachThreshold) ||
                            fundsById?.funds[0]?.status === "ROI") && (
                                <Tab label={t('FUND.APPLICATIONS')} />
                            )}
                        <Tab label={t('FUND.INFORMATION')} value={1} />
                        <Tab label="Q&A" value={2} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {/* COMPANIES */}
                        {fundsById?.funds[0]?.proposal.length > 0 ?
                            <Grid container lg={12} xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid item md={8} xs={12} sx={{
                                    pl: { md: 8, xs: 2 },
                                    pt: 1
                                }}>
                                    <FormControl
                                        sx={{ width: { md: '50%', xs: '100%' } }}
                                        autoFocus="autoFocus"
                                    >
                                        <OutlinedInput
                                            size="small"
                                            placeholder={t('MESSAGES.SEARCH_MESSAGE')}
                                            value={search}
                                            autoFocus="autoFocus"
                                            onChange={(e) => {
                                                setSearch(e.target.value)
                                            }}
                                            startAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{ width: '100%', margin: '0 auto' }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}
                                    sx={{
                                        display: 'flex', flexDirection: 'row',
                                        pt: 1, alignItems: 'center', justifyContent: { md: 'right', xs: 'center' },
                                        pr: { md: 8, xs: 0 }
                                    }} >
                                    <Typography sx={{ pr: 2 }}>{t('ADMIN.SORT_BY')}</Typography>
                                    <FormControl sx={{ minWidth: '200px', maxWidth: '200px' }}>
                                        <Select
                                            sx={{ maxHeight: '40px' }}
                                            value={sort}
                                            onChange={handleChangeSort}
                                        >
                                            <MenuItem value={'awarded'}>
                                                {t('FUND_SEARCH_VIEW.AWARDED_FIRST')}
                                            </MenuItem>
                                            <MenuItem value={'ranking'}>
                                                {t('FUND_SEARCH_VIEW.RANKING')}
                                            </MenuItem>
                                            <MenuItem value={'inscription'}>
                                                {t('FUND_SEARCH_VIEW.REGISTRATION_DATE')}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            :
                            <Box sx={{ ml: 8, mt: 8 }}>
                                <Typography>
                                    {t('FUND_SEARCH_VIEW.NO_COMPANIES_YET')}
                                </Typography>
                            </Box>
                        }
                        {fundsById?.funds[0]?.proposal.length > 0 ? <Box
                            sx={{
                                pb: '70px',
                                width: '100%',
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    width: { xs: '100%', sm: '100%' },
                                    mt: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mb: 4,
                                }}
                            >
                                {sort === 'awarded' && sortedProposalsByAwarded?.map((proposal) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            key={proposal.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                mb: 4,
                                            }}
                                        >
                                            <Box sx={{ width: { md: '75%', xs: '95%' }, height: locStorageRole === "role-investor" ? '42vh' : '14vh' }}>
                                                <ActiveProposalCard
                                                    id={proposal?.id}
                                                    name={proposal?.company?.coName}
                                                    logo={proposal?.company?.logo}
                                                    state={proposal?.assessmentStatus}
                                                    fundName={proposal?.fund?.name}
                                                    link={proposal?.url[0]?.link}
                                                    address={proposal?.company?.address}
                                                    proposalTitle={
                                                        selectedLanguage === 'en'
                                                            ? proposal?.titleEn
                                                            : selectedLanguage === 'es'
                                                                ? proposal?.titleEs || proposal?.titleEn || ''
                                                                : selectedLanguage === 'pt'
                                                                    ? proposal?.titlePt || proposal?.titleEn || ''
                                                                    : null
                                                    }
                                                    proposalScore={proposal?.assessmentRating}
                                                    desc={
                                                        selectedLanguage === 'en'
                                                            ? proposal?.descriptionEn
                                                            : selectedLanguage === 'es'
                                                                ? proposal?.descriptionEs ||
                                                                proposal?.descriptionEn ||
                                                                ''
                                                                : selectedLanguage === 'pt'
                                                                    ? proposal?.descriptionPt ||
                                                                    proposal?.descriptionEn ||
                                                                    ''
                                                                    : null
                                                    }
                                                    ADArequest={proposal?.reqInvestAmount}
                                                    applicationId={proposal?.id}
                                                    fundStatus={proposal?.fund?.status}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                })
                                }
                                {sort === 'ranking' && sortedProposalsByRating?.map((proposal) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            key={proposal.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                mb: 4,
                                            }}
                                        >
                                            <Box sx={{ width: { md: '75%', xs: '95%' }, height: locStorageRole === "role-investor" ? '42vh' : '14vh' }}>
                                                <ActiveProposalCard
                                                    id={proposal?.id}
                                                    link={proposal?.url[0]?.link}
                                                    name={proposal?.company?.coName}
                                                    logo={proposal?.company?.logo}
                                                    state={proposal?.assessmentStatus}
                                                    fundName={proposal?.fund?.name}
                                                    address={proposal?.company?.address}
                                                    proposalTitle={
                                                        selectedLanguage === 'en'
                                                            ? proposal?.titleEn
                                                            : selectedLanguage === 'es'
                                                                ? proposal?.titleEs || proposal?.titleEn || ''
                                                                : selectedLanguage === 'pt'
                                                                    ? proposal?.titlePt || proposal?.titleEn || ''
                                                                    : null
                                                    }
                                                    proposalScore={proposal?.assessmentRating}
                                                    desc={
                                                        selectedLanguage === 'en'
                                                            ? proposal?.descriptionEn
                                                            : selectedLanguage === 'es'
                                                                ? proposal?.descriptionEs ||
                                                                proposal?.descriptionEn ||
                                                                ''
                                                                : selectedLanguage === 'pt'
                                                                    ? proposal?.descriptionPt ||
                                                                    proposal?.descriptionEn ||
                                                                    ''
                                                                    : null
                                                    }
                                                    ADArequest={proposal?.reqInvestAmount}
                                                    applicationId={proposal?.id}
                                                    fundStatus={proposal?.fund?.status}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                }
                                )}
                                {sort === 'inscription' && sortedProposalsByDate?.map((proposal) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            key={proposal.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                mb: 4,
                                            }}
                                        >
                                            <Box sx={{ width: { md: '75%', xs: '95%' }, height: locStorageRole === "role-investor" ? '42vh' : '14vh' }}>
                                                <ActiveProposalCard
                                                    id={proposal?.id}
                                                    name={proposal?.company?.coName}
                                                    link={proposal?.url[0]?.link}
                                                    logo={proposal?.company?.logo}
                                                    state={proposal?.assessmentStatus}
                                                    fundName={proposal?.fund?.name}
                                                    address={proposal?.company?.address}
                                                    proposalTitle={
                                                        selectedLanguage === 'en'
                                                            ? proposal?.titleEn
                                                            : selectedLanguage === 'es'
                                                                ? proposal?.titleEs || proposal?.titleEn || ''
                                                                : selectedLanguage === 'pt'
                                                                    ? proposal?.titlePt || proposal?.titleEn || ''
                                                                    : null
                                                    }
                                                    proposalScore={proposal?.assessmentRating}
                                                    desc={
                                                        selectedLanguage === 'en'
                                                            ? proposal?.descriptionEn
                                                            : selectedLanguage === 'es'
                                                                ? proposal?.descriptionEs ||
                                                                proposal?.descriptionEn ||
                                                                ''
                                                                : selectedLanguage === 'pt'
                                                                    ? proposal?.descriptionPt ||
                                                                    proposal?.descriptionEn ||
                                                                    ''
                                                                    : null
                                                    }
                                                    ADArequest={proposal?.reqInvestAmount}
                                                    applicationId={proposal?.id}
                                                    fundStatus={proposal?.fund?.status}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>
                        </Box> : null
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {fundsById?.funds[0]?.status === "Open" &&
                            <Grid container mt={2} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto', color: 'white' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', ml: 3 }}>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                            {/* MIN/MAX INVESTMENT */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.MIN/MAX_INVESTMENT')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', ml: 1 }}>
                                                    $ {fundsById?.funds[0]?.minInvestment.toLocaleString()} - $ {
                                                        fundsById?.funds[0]?.maxInvestment.toLocaleString()
                                                    }
                                                </Typography>
                                            </Box>
                                            {/* LINKS */}
                                            <Box>
                                                {/* TERMS & CONDITIONS */}
                                                <Link
                                                    href={fundsById?.funds[0]?.tycFile}
                                                    target={{ _blank: true }}
                                                    color='#9A1D4C'
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <LaunchIcon />
                                                    <Typography sx={{ textDecoration: 'underline', fontSize: '14px', ml: 1 }}>
                                                        {t('FUND.TERMS_&_CONDITIONS')}
                                                    </Typography>
                                                </Link>
                                                {/* NDA */}
                                                <Link href={fundsById?.funds[0]?.ndaFile}
                                                    target={{ _blank: true }}
                                                    color='#9A1D4C'
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <LaunchIcon />
                                                    <Typography sx={{ textDecoration: 'underline', fontSize: '14px', ml: 1 }}>
                                                        {t('FUND.NDA')}
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={2.4} xs={12} sx={{
                                                mt: { md: 0, xs: 0 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* VESTING */}
                                            <Grid item md={2.4} xs={12}
                                                sx={{
                                                    mt: { md: 0, xs: 4 },
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'start',
                                                    justifyContent: 'center'
                                                }} >
                                                <Tooltip sx={{ mr: -1 }} title={t('CREATE_OFFERING.VESTING_TOOLTIP')}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        width: '100%',
                                                    }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                            {t('FUND.VESTING.VESTING_TYPE')}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column',
                                                                justifyContent: 'center', alignItems: 'center'
                                                            }}>
                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                {fundsById?.funds[0]?.vestingPlan}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={2.4} xs={12}
                                                sx={{
                                                    mt: { md: 0, xs: 4 },
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'start',
                                                    justifyContent: 'center'
                                                }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* RECEIVED APLICATIONS */}
                                            <Grid item md={2.4} xs={12} sx={{ mt: { md: 0, xs: 4 } }}>
                                                <Box sx={{
                                                    backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                                    borderRadius: '15px',
                                                    pl: 2,
                                                    pr: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.RECEIVED_APPLICATIONS')}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <PersonIcon />
                                                        {totalProposalCount}
                                                    </Box>
                                                    <Button sx={{ color: '#' }} variant="text" onClick={() => { setValue(0) }}>
                                                        <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                            {t('FUND.VIEW_APPLICATIONS')}
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            {/* END PERIOD */}
                                            <Grid item md={2.4} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                {daysBetween < 0 ?
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND_CARD.FINALIZED')}
                                                    </Typography>
                                                    :
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('INVERSOR_VIEW.OFFERING_CARD.ENDS_IN')} {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                    </Typography>
                                                }
                                                {daysBetween >= 0 &&
                                                    <Typography sx={{ fontSize: '12px' }}>
                                                        {daysBetween} {t('INVERSOR_VIEW.OFFERING_CARD.DAYS')}
                                                    </Typography>}
                                                <Box
                                                    sx={{
                                                        width: '100%', display: 'flex', flexDirection: 'row',
                                                        alignItems: 'center', justifyContent: 'center'
                                                    }}>
                                                    <Typography sx={{ fontSize: '12px' }}>
                                                        {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}
                                                    </Typography>
                                                    <Box sx={{ width: '50%', ml: 1, mr: 1 }}>
                                                        <BorderLinearProgress variant="determinate" value={statusValue * 100 / 8} />
                                                    </Box>
                                                    <Typography sx={{ fontSize: '12px' }}>
                                                        {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '100%', display: 'flex', flexDirection: 'row',
                                                        alignItems: 'center', justifyContent: 'center', mt: 1, cursor: 'pointer'
                                                    }}>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Collecting">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column',
                                                                alignItems: 'end', justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 1 ? '#B2E0DF' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 1 ? "gray" : statusValue === 1 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 0 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>1</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Published">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 2 ? '#B2E0DF' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 2 ? "gray" : statusValue === 2 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 1 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>2</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Open">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 3 ? '#B2E0DF' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 3 ? "gray" : statusValue === 3 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 2 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>3</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Assessment">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 4 ? '#9A1D4C' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 4 ? "gray" : statusValue === 4 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 3 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>4</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Governance">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 5 ? '#9A1D4C' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 5 ? "gray" : statusValue === 5 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 4 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>5</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Vesting">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 6 ? '#9A1D4C' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 6 ? "gray" : statusValue === 6 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 5 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>6</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="Execution">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 7 ? '#9A1D4C' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 7 ? "gray" : statusValue === 7 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 6 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>7</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title="ROI">
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', alignItems: 'end',
                                                                justifyContent: 'end', ml: -0.3, mr: -0.3
                                                            }}>
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    color: statusValue === 8 ? '#9A1D4C' : actualThemeLight ? "#EBECF2" : "#262626", mb: -0.8, pl: 0.4
                                                                }} />
                                                            <FiberManualRecordIcon fontSize="small"
                                                                sx={{
                                                                    color: statusValue < 8 ? "gray" : statusValue === 8 ? '#B2E0DF' : "#E1BBC9"
                                                                }} />
                                                        </Box>
                                                        <Box sx={{ position: 'absolute', ml: 1, mt: -2.3 }}>
                                                            <Typography sx={{ color: statusValue > 7 ? 'black' : 'white', fontSize: '12px', fontWeight: 'bold' }}>8</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                                {/* APPLY BUTTON */}
                                {locStorageRole === 'role-applicant' && daysLeft >= 1 ?
                                    <Grid item lg={12} xs={12}
                                        sx={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: 4, mt: { md: 0, xs: 4 }
                                        }}>
                                        <Button
                                            onClick={() => {
                                                dispatch(push(`/postulate/${fundId}`))
                                            }}
                                            variant="contained"
                                        >
                                            <Typography sx={{ color: 'white' }}>
                                                {t('CREATE_COMPANY.APPLY')}
                                            </Typography>
                                        </Button>
                                    </Grid> : null}
                                {locStorageRole === 'role-investor' && daysLeft >= 1 ?
                                    <Grid item lg={12} xs={12}
                                        sx={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: 4, mt: { md: 0, xs: 4 }
                                        }}>
                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.APPLY_BUTTON_HELPER')}>
                                            <Box>
                                                <Button
                                                    disabled
                                                    variant="contained"
                                                >
                                                    <Typography sx={{ color: 'white' }}>
                                                        {t('CREATE_COMPANY.APPLY')}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Tool>
                                    </Grid> : null}
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "Governance" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', ml: 3 }}>
                                            {/* PARTICIPATING COMPANIES */}
                                            <Button
                                                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
                                                onClick={() => { setValue(0) }}>
                                                <LaunchIcon />
                                                <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                    {t('FUND_SEARCH_VIEW.PARTICIPATING_COMPANIES')}
                                                </Typography>
                                            </Button>
                                            {/* ASSESSED COMPANIES */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                    {t('FUND.ASSESSED_COMPANIES')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {assessedProposalsCount}
                                                </Typography>
                                            </Box>
                                            {/* TOTAL REQUESTED */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                    {t('FUND.TOTAL_REQUESTED')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    $ {fundsById?.funds[0]?.amountToReach.toLocaleString()}
                                                </Typography>
                                            </Box>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 0 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* EMPLOYEES AMOUNT */}
                                            <Grid item md={3} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.COMPANY_TYPE')}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'column',
                                                        alignItems: 'center', justifyContent: 'center',
                                                        width: '80%', height: '80%'
                                                    }}>
                                                    <BarChart width={200} height={120} data={employeesCount}>
                                                        <CartesianGrid strokeDasharray="2 0" />
                                                        <XAxis dataKey="name" fontSize={8} />
                                                        <YAxis fontSize={8} />
                                                        <Tooltip />
                                                        <Bar dataKey="qty" fill="#8884d8" />
                                                    </BarChart>
                                                    <Typography sx={{ fontSize: '12px', position: 'absolute', mt: 14, ml: 6 }}>
                                                        {t('CREATE_COMPANY.EMPLOYEES_AMOUNT')}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ fontSize: '12px', position: 'absolute', mt: -4, ml: -18, rotate: '-90deg' }}>
                                                        N° {t('ADMIN.COMPANY_ADMINISTRATION')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* RECEIVED APLICATIONS */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                                    borderRadius: '15px',
                                                    pl: 2,
                                                    pr: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.APROVED_APLICATIONS')}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <PersonIcon />
                                                        {approvedApplicationsCount} / {fundsById?.funds[0]?.proposal.length}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                                {/* VOTE BUTTON */}
                                {locStorageRole === 'role-investor' ? <Grid item lg={12} xs={12}
                                    sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                        mb: 4, mt: { md: 0, xs: 4 }
                                    }}>
                                    <Button
                                        onClick={handleVoteButtonClick}
                                        sx={{ backgroundColor: '#9A1D4C' }}
                                    >
                                        <Typography sx={{ color: 'white' }}>
                                            {t('FUND.VOTE')}
                                        </Typography>
                                    </Button>
                                </Grid> : null}
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "Assessment" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly', ml: 3
                                            }}>
                                            {/* PARTICIPATING COMPANIES */}
                                            <Button
                                                sx={{
                                                    display: 'flex', flexDirection: 'row',
                                                    justifyContent: 'flex-start'
                                                }}
                                                onClick={() => { setValue(0) }}>
                                                <LaunchIcon />
                                                <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                    {t('FUND_SEARCH_VIEW.PARTICIPATING_COMPANIES')}
                                                </Typography>
                                            </Button>
                                            {/* TOTAL REQUESTED */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px', mr: 1 }}>
                                                    {t('FUND.TOTAL_REQUESTED')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    $ {fundsById?.funds[0]?.amountToReach.toLocaleString()}
                                                </Typography>
                                            </Box>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={3} xs={12} sx={{ mt: { md: 0, xs: 0 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* EMPLOYEES AMOUNT */}
                                            <Grid item md={3} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.COMPANY_TYPE')}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex', flexDirection: 'column', alignItems: 'center',
                                                        justifyContent: 'center', width: '80%', height: '80%'
                                                    }}>
                                                    <BarChart width={200} height={120} data={employeesCount}>
                                                        <CartesianGrid strokeDasharray="2 0" />
                                                        <XAxis dataKey="name" fontSize={8} />
                                                        <YAxis fontSize={8} />
                                                        <Tooltip />
                                                        <Bar dataKey="qty" fill="#8884d8" />
                                                    </BarChart>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px', position: 'absolute',
                                                            mt: 14, ml: 6
                                                        }}>
                                                        {t('CREATE_COMPANY.EMPLOYEES_AMOUNT')}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px', position: 'absolute',
                                                            mt: -4, ml: -18, rotate: '-90deg'
                                                        }}>
                                                        N° {t('ADMIN.COMPANY_ADMINISTRATION')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* RECEIVED APLICATIONS */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                                    borderRadius: '15px',
                                                    pl: 2,
                                                    pr: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.RECEIVED_APPLICATIONS')}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <PersonIcon />
                                                        {totalProposalCount}
                                                    </Box>
                                                    <Button sx={{ color: '#' }} variant="text" onClick={() => { setValue(0) }}>
                                                        <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                            {t('FUND.VIEW_APPLICATIONS')}
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                                {/* VOTE BUTTON */}
                                {locStorageRole === 'role-investor' ? <Grid item lg={12} xs={12}
                                    sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                        mb: 4, mt: { md: 0, xs: 4 }
                                    }}>
                                    <Button
                                        onClick={handleVoteButtonClick}
                                        sx={{ backgroundColor: '#9A1D4C' }}
                                    >
                                        <Typography sx={{ color: 'white' }}>
                                            {t('FUND.VOTE')}
                                        </Typography>
                                    </Button>
                                </Grid> : null}
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "Vesting" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly', alignItems: 'flex-start', ml: 3
                                            }}>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                            {/* VOTATION */}
                                            <Link href={VotingLink}
                                                target={{ _blank: false }}
                                                variant='text'
                                                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
                                            >
                                                <LaunchIcon />
                                                <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                    {t('FUND.VOTING_RESULTS')}
                                                </Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={2.4} xs={12} sx={{ mt: { md: 0, xs: 0 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* AVAILABLE FUNDS */}
                                            <Grid item md={2.4} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.AVAILABLE_FUNDS')}
                                                </Typography>
                                                <Typography sx={{ fontSize: '12px' }}>
                                                    {totalInvestment / fundsById?.funds[0]?.amountToReach}%
                                                </Typography>
                                                <Box sx={{ width: '80%' }}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={totalInvestment / fundsById?.funds[0]?.amountToReach}
                                                    />
                                                </Box>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                    {t('FUND.DELIVERED')} $ 0
                                                </Typography>
                                            </Grid>
                                            {/* WINNING COMPANIES */}
                                            <Grid item md={2.4} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                                    borderRadius: '15px',
                                                    p: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.WINNING_COMPANIES')}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <PersonIcon />
                                                        {approvedApplicationsCount}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* VESTING */}
                                            <Grid item md={2.4} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Tooltip sx={{ mr: -1 }} title={t('CREATE_OFFERING.VESTING_TOOLTIP')}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        width: '100%',
                                                    }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                            {t('FUND.VESTING.DELIVER')}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column',
                                                                justifyContent: 'space-evenly', alignItems: 'center'
                                                            }}>
                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                {fundsById?.funds[0]?.vestingPlan}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={2.4} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                                {/* VOTE BUTTON */}
                                {locStorageRole === 'role-investor' ? <Grid item lg={12} xs={12}
                                    sx={{
                                        display: 'flex', flexDirection: 'row',
                                        justifyContent: 'center', mb: 4, mt: { md: 0, xs: 4 }
                                    }}>
                                    <Button
                                        onClick={handleVoteButtonClick}
                                        sx={{ backgroundColor: '#9A1D4C' }}
                                    >
                                        <Typography sx={{ color: 'white' }}>
                                            {t('FUND.VOTE')}
                                        </Typography>
                                    </Button>
                                </Grid> : null}
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "Execution" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-arround', alignItems: 'flex-start',
                                                ml: 3
                                            }}>
                                            {/* PARTICIPATING COMPANIES */}
                                            {locStorageRole === 'role-investor' ? <Button
                                                sx={{
                                                    display: 'flex', flexDirection: 'row',
                                                    justifyContent: 'flex-start'
                                                }}
                                                onClick={() => { setValue(0) }}>
                                                <LaunchIcon />
                                                <Link
                                                    href={''}
                                                    target={{ _blank: true }}
                                                    color="inherit"
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {t('COMPANY_VIEW.REPORTS')}
                                                </Link>
                                            </Button> : null}
                                            <Button
                                                sx={{
                                                    display: 'flex', flexDirection: 'row',
                                                    justifyContent: 'flex-start'
                                                }}
                                                onClick={() => { setValue(0) }}>
                                                <LaunchIcon />
                                                <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                    {t('FUND_SEARCH_VIEW.PARTICIPATING_COMPANIES')}
                                                </Typography>
                                            </Button>
                                            {/* INVESTED COMPANIES */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND_SEARCH_VIEW.INVESTED_COMPANIES')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {approvedApplicationsCount}
                                                </Typography>
                                            </Box>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={3} xs={12} sx={{ mt: { md: 0, xs: 0 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* AVAILABLE FUNDS */}
                                            <Grid item md={3} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.DELIVERED_FUNDS')}
                                                </Typography>
                                                <Typography sx={{ fontSize: '12px' }}>
                                                    {totalInvestment / fundsById?.funds[0]?.amountToReach}%
                                                </Typography>
                                                <Box sx={{ width: '80%' }}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={totalInvestment / fundsById?.funds[0]?.amountToReach}
                                                    />
                                                </Box>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                    {t('FUND.DELIVERED')} $ 0
                                                </Typography>
                                            </Grid>
                                            {/* TIME SPENT */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.TIME_SPENT')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            <HourglassBottomIcon />
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                8 {t('ADMIN.MONTHS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                            {t('FUND.RECEIVED_INVESTMENTS')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "ROI" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly', alignItems: 'flex-start', ml: 3
                                            }}>
                                            {/* EXIT COMPANIES */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.EXIT_COMPANIES')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', ml: 1 }}>
                                                    {assessedProposalsCount}
                                                </Typography>
                                            </Box>
                                            {/* ROI */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    ROI:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', ml: 1 }}>
                                                    $ 0
                                                </Typography>
                                            </Box>
                                            {/* PARTICIPATING COMPANIES */}
                                            <Button
                                                sx={{
                                                    display: 'flex', flexDirection: 'row',
                                                    justifyContent: 'flex-start'
                                                }}
                                                onClick={() => { setValue(0) }}>
                                                <LaunchIcon />
                                                <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                    {t('FUND_SEARCH_VIEW.PARTICIPATING_COMPANIES')}
                                                </Typography>
                                            </Button>
                                            {/* PARTICIPATING COMPANIES */}
                                            {locStorageRole === 'role-investor' ? <Button
                                                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
                                                onClick={() => { }}>
                                                <LaunchIcon />
                                                <Typography sx={{ textDecoration: 'underline', fontSize: '14px' }}>
                                                    {t('FUND_SEARCH_VIEW.REPORTS')}
                                                </Typography>
                                            </Button> : null}
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={3} xs={12} sx={{ mt: { md: 0, xs: 0 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* AVAILABLE FUNDS */}
                                            <Grid item md={3} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.AVAILABLE_FUNDS')}
                                                </Typography>
                                                <Typography sx={{ fontSize: '12px' }}>
                                                    $ {totalInvestment / fundsById?.funds[0]?.amountToReach}%
                                                </Typography>
                                                <Box sx={{ width: '80%' }}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={totalInvestment / fundsById?.funds[0]?.amountToReach}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ fontSize: '16px', mr: 1 }}>
                                                        {t('FUND.DELIVERED')}:
                                                    </Typography>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        $ 0
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* TIME SPENT */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.TIME_SPENT')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            <HourglassBottomIcon />
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                1 {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>
                                                            {t('FUND.RECEIVED_INVESTMENTS')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={3} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "Collecting" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly', alignItems: 'flex-start',
                                                ml: 3
                                            }}>
                                            {/* MIN/MAX INVESTMENT */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.MIN/MAX_INVESTMENT')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', ml: 1 }}>
                                                    $ {fundsById?.funds[0]?.minInvestment.toLocaleString()} - $ {fundsById?.funds[0]?.maxInvestment.toLocaleString()}
                                                </Typography>
                                            </Box>
                                            {/* INVESTORS */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <PersonIcon />
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {countOfValidOffPur} {t('FUND.INVESTORS')} | {t('FUND.AVERAGE_INVESTMENT')} $ {averageAmount.toFixed(2)}
                                                </Typography>
                                            </Box>
                                            {/* ROI */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ mr: 1 }}>
                                                    {t('FUND.EXPECTED_ROI')}:
                                                </Typography>
                                                <AccessTimeIcon />
                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>
                                                    {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                </Typography>
                                            </Box>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={2} p={1}>
                                    <Grid item lg={12} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={4} xs={12} sx={{
                                                mt: { md: 0, xs: 0 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* MIN THRESHOLD */}
                                            <Grid item md={4} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{t('FUND.COLLECTED')}</Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>$ {totalInvestment.toLocaleString()}</Typography>
                                                        <Typography sx={{ fontWeight: 'bold', ml: 1, mr: 1, fontSize: '14px' }}>{t('FUND.OF')}</Typography>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}> $ {(fundsById?.funds[0]?.amountToReach).toLocaleString()}  </Typography>
                                                    </Box>
                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                                        <Typography sx={{ fontSize: '12px' }}>{t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}</Typography>
                                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow placement="top-start" title={`${t('FUND.COLLECTED')}: $ ${totalInvestment.toLocaleString()} / ${t('FUND.TARGET')}: $ ${fundsById?.funds[0]?.amountToReach?.toLocaleString()}`}>
                                                            <Box sx={{ width: '70%', ml: 1, mr: 1, position: 'relative' }}>
                                                                <BorderLinearProgress variant="determinate" value={(totalInvestment / fundsById?.funds[0]?.amountToReach) * 100} />
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: `${fundsById?.funds[0]?.successThreshold}%`,
                                                                        transform: 'translate(-50%, -50%)',
                                                                        borderLeft: '2px solid #9A1D4C',
                                                                        height: '100%',
                                                                        zIndex: 1
                                                                    }}
                                                                />
                                                                <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow title={`${t('FUND.MIN_THRESHOLD')}: $ ${(fundsById?.funds[0]?.amountToReach * fundsById?.funds[0]?.successThreshold / 100).toLocaleString()} (${fundsById?.funds[0]?.successThreshold}%)`}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: thresholdMet ? 'green' : '#9A1D4C',
                                                                            fontSize: '20px',
                                                                            fontWeight: 'bold',
                                                                            position: 'absolute',
                                                                            left: `${fundsById?.funds[0]?.successThreshold}%`,
                                                                            top: '50%',
                                                                            transform: 'translate(-50%, -50%)'
                                                                        }}>
                                                                        |
                                                                    </Typography>
                                                                </Tool>
                                                            </Box>
                                                        </Tool>
                                                        <Typography sx={{ fontSize: '12px' }}>{t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}</Typography>
                                                    </Box>
                                                    <Typography sx={{ fontSize: '14px' }}>{((totalInvestment / fundsById?.funds[0]?.amountToReach) * 100).toFixed(2)}%</Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>{t('DASHBOARD_VIEW.TO_REACH_THRESHOLD')}</Typography>
                                                        {thresholdMet === false ?
                                                            <Typography sx={{ fontSize: '14px', ml: 1 }}>
                                                                ${(isNaN(fundsById?.funds[0]?.amountToReach) || isNaN(fundsById?.funds[0]?.successThreshold) || isNaN(totalInvestment)) ?
                                                                    '0' :
                                                                    ((fundsById?.funds[0]?.amountToReach * fundsById?.funds[0]?.successThreshold / 100 - totalInvestment).toLocaleString())}
                                                            </Typography>
                                                            :
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <Typography sx={{ fontSize: '14px', ml: 1 }}>
                                                                    {t('DASHBOARD_VIEW.THRESHOLD_REACHED')}
                                                                </Typography>
                                                                <CheckIcon sx={{ color: 'green', ml: 1 }} />
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* VESTING */}
                                            <Grid item md={4} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Tooltip sx={{ mr: -1 }} title={t('CREATE_OFFERING.VESTING_TOOLTIP')}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        width: '100%',
                                                    }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                            {t('FUND.VESTING.VESTING_TYPE')}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}>
                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                {fundsById?.funds[0]?.vestingPlan}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        {locStorageRole === 'role-investor' &&
                                            <>
                                                {
                                                    amountToReach > totalAmountGathered && daysLeft >= 1
                                                        ?
                                                        <Box onClick={() => {
                                                            setModalInvest(true)
                                                        }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                                            <Button variant="contained" >
                                                                <Typography>
                                                                    {t('FUND.INVEST')}
                                                                </Typography>
                                                            </Button>
                                                        </Box>
                                                        :
                                                        daysLeft <= 0 ? null :
                                                            <Box onClick={() => {
                                                                setModalWaitingList(true)
                                                            }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                                                <Button variant="contained" >
                                                                    <Typography>
                                                                        {t('DASHBOARD.WAITING_LIST')}
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                }
                                            </>
                                        }
                                        {locStorageRole === 'role-applicant' &&
                                            <>
                                                {
                                                    amountToReach > totalAmountGathered && daysLeft >= 1
                                                        ?
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                                            <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.INVEST_BUTTON_HELPER')}>
                                                                <Box>
                                                                    <Button disabled variant="contained" >
                                                                        <Typography>
                                                                            {t('FUND.INVEST')}
                                                                        </Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Tool>
                                                        </Box>
                                                        :
                                                        daysLeft <= 0 ? null :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                                                <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow title={t('FUND.INVEST_BUTTON_HELPER')}>
                                                                    <Box>
                                                                        <Button disabled variant="contained" >
                                                                            <Typography>
                                                                                {t('DASHBOARD.WAITING_LIST')}
                                                                            </Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Tool>
                                                            </Box>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    modalInvest && (
                                        <InvestModal
                                            data={fundsById?.funds[0]}
                                            open={modalInvest}
                                            handleClose={handleCloseInvest}
                                            raised={totalInvestment}
                                            target={fundsById?.funds[0]?.amountToReach}
                                            minInvest={fundsById?.funds[0]?.minInvestment}
                                            maxInvest={fundsById?.funds[0]?.maxInvestment}
                                            txFundId={fundId}
                                            scData={fundsById?.funds[0]?.smartContract}
                                            token={fundsById?.funds[0]?.token[0]}
                                        />
                                    )
                                }
                                {
                                    modalWaitingList && (
                                        <WaitingListModal
                                            data={fundsById?.funds[0]}
                                            open={modalWaitingList}
                                            handleClose={handleCloseWaitingList}
                                            raised={totalInvestment}
                                            target={fundsById?.funds[0]?.amountToReach}
                                            minInvest={fundsById?.funds[0]?.minInvestment}
                                            maxInvest={fundsById?.funds[0]?.maxInvestment}
                                            txFundId={fundsById?.funds[0]?.id}
                                            scData={fundsById?.funds[0]?.smartContract}
                                            token={fundsById?.funds[0]?.token[0]}
                                        />
                                    )
                                }
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === "Published" &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'flex-start', ml: 3
                                            }}>
                                            {/* TARGET */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.TARGET')}: {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.targetPt}
                                            </Typography>
                                            {/* MIN/MAX INVESTMENT */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.MIN/MAX_EXPECTED_INVESTMENT')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', ml: 1 }}>
                                                    $ {fundsById?.funds[0]?.minCompanyAsk?.toLocaleString()} - $ {fundsById?.funds[0]?.maxCompanyAsk?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                            {/* LINKS */}
                                            <Box>
                                                {/* TERMS & CONDITIONS */}
                                                <Link
                                                    href={fundsById?.funds[0]?.tycFile}
                                                    target={{ _blank: true }}
                                                    color='#9A1D4C'
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <LaunchIcon />
                                                    <Typography sx={{ textDecoration: 'underline', fontSize: '14px', ml: 1 }}>
                                                        {t('FUND.TERMS_&_CONDITIONS')}
                                                    </Typography>
                                                </Link>
                                                {/* NDA */}
                                                <Link href={fundsById?.funds[0]?.ndaFile}
                                                    target={{ _blank: true }}
                                                    color='#9A1D4C'
                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <LaunchIcon />
                                                    <Typography sx={{ textDecoration: 'underline', fontSize: '14px', ml: 1 }}>
                                                        {t('FUND.NDA')}
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%', flexDirection: 'column',
                                                    justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item lg={12} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={2} xs={12} sx={{
                                                mt: { md: 0, xs: 0 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* VESTING */}
                                            <Grid item md={2} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Tooltip sx={{ mr: -1 }} title={t('CREATE_OFFERING.VESTING_TOOLTIP')}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        width: '100%',
                                                    }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                            {t('FUND.VESTING.VESTING_TYPE')}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex', flexDirection: 'column',
                                                                justifyContent: 'center', alignItems: 'center'
                                                            }}>
                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                {fundsById?.funds[0]?.vestingPlan}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={2} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.EXPECTED_ROI')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon />
                                                            <Typography sx={{ fontWeight: 'bold', ml: 1 }}>
                                                                {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '12px', mt: 1 }}>
                                                            {t('FUND.MULTIPLIED')}: {fundsById?.funds[0]?.roiMultiplier}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* INVESTORS */}
                                            <Grid item md={2} xs={12} sx={{
                                                mt: { md: 0, xs: 4 },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'start',
                                                justifyContent: 'center'
                                            }}>
                                                <Box sx={{
                                                    backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                                    borderRadius: '15px',
                                                    pl: 2,
                                                    pr: 2,
                                                    pb: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.INVESTORS')}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <PersonIcon />
                                                        {totalProposalCount}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ALLIs */}
                                            <Grid item md={4} xs={12} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'start',
                                                width: '100%',

                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                {/* LOGO */}
                                                <Box>
                                                    <img src={TokenAllies} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'start',
                                                    width: '60%',
                                                    pl: 1
                                                }}>
                                                    {/* TITLE */}
                                                    <Typography sx={{ fontSize: '20px' }}>
                                                        ALLIs
                                                    </Typography>
                                                    {/* MINTED TOKENS */}
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}>
                                                        <Typography sx={{ fontSize: '14px', pr: 1 }}>
                                                            {t('FUND.MINTED_TOKENS')}:
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {tstALLIObject?.symbol === "tstALLI" ? totalQuantityHoldersTstAllies?.toLocaleString() : 0}
                                                        </Typography>
                                                    </Box>
                                                    {/* ALLIS VALUE */}
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}>
                                                        <Typography sx={{ fontSize: '14px', pr: 1 }}>
                                                            {t('FUND.ALLI_VALUE')}:
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            $ 1
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid >
                        }
                        {(fundsById?.funds[0]?.status === 'Closed' || fundsById?.funds[0]?.status === 'Closed - Not Fully Funded') && totalInvestment < amountToReachThreshold &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly', alignItems: 'flex-start', ml: 3
                                            }}>
                                            {/* MIN/MAX INVESTMENT */}
                                            <Typography sx={{ fontSize: '14px', mb: 2 }}>
                                                {t('FUND.MIN/MAX_INVESTMENT')}: $ {fundsById?.funds[0]?.minInvestment?.toLocaleString()} - $ {fundsById?.funds[0]?.maxInvestment?.toLocaleString()}
                                            </Typography>
                                            {/* INVESTORS */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <PersonIcon />
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {totalProposalCount} {t('FUND.INVESTORS')} | {t('FUND.AVERAGE_INVESTMENT')} $ {averageAmount}
                                                </Typography>
                                            </Box>
                                            {/* ROI */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ mr: 1 }}>
                                                    {t('FUND.EXPECTED_ROI')}:
                                                </Typography>
                                                <AccessTimeIcon />
                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>
                                                    {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%',
                                                    flexDirection: 'column', justifyContent: 'flex-end', ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={4} xs={12} sx={{ mt: { md: 0, xs: 0 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* MIN THRESHOLD */}
                                            <Grid item md={4} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{t('FUND.COLLECTED')}</Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>$ {totalInvestment.toLocaleString()}</Typography>
                                                        <Typography sx={{ fontWeight: 'bold', ml: 1, mr: 1, fontSize: '14px' }}>{t('FUND.OF')}</Typography>
                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}> $ {(fundsById?.funds[0]?.amountToReach).toLocaleString()}  </Typography>
                                                    </Box>
                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography sx={{ fontSize: '12px' }}>{t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}</Typography>
                                                        <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow placement="top-start" title={`${t('FUND.COLLECTED')}: $ ${totalInvestment.toLocaleString()} / ${t('FUND.TARGET')}: $ ${fundsById?.funds[0]?.amountToReach?.toLocaleString()}`}>
                                                            <Box sx={{ width: '80%', ml: 1, mr: 1 }}>
                                                                <BorderLinearProgress variant="determinate" value={(totalInvestment / fundsById?.funds[0]?.amountToReach) * 100} />
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        mt: -2.55,
                                                                        width: { md: '25%', xs: '43%' },
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'start',
                                                                        cursor: 'pointer'
                                                                    }}>
                                                                    <Tool TransitionComponent={Zoom} TransitionProps={{ timeout: 50 }} arrow title={`${t('FUND.MIN_THRESHOLD')}: $ ${(fundsById?.funds[0]?.amountToReach * fundsById?.funds[0]?.successThreshold / 100).toLocaleString()} (${fundsById?.funds[0]?.successThreshold}%)`}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: thresholdMet ? 'green' : '#9A1D4C',
                                                                                fontSize: '20px',
                                                                                fontWeight: 'bold',
                                                                                ml: `${fundsById?.funds[0]?.successThreshold}%`
                                                                            }}>
                                                                            |
                                                                        </Typography>
                                                                    </Tool>
                                                                </Box>
                                                            </Box>
                                                        </Tool>
                                                        <Typography sx={{ fontSize: '12px' }}>{t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}</Typography>
                                                    </Box>
                                                    <Typography sx={{ fontSize: '14px' }}>{((totalInvestment / fundsById?.funds[0]?.amountToReach) * 100).toFixed(2)}%</Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography sx={{ fontSize: '14px' }}>{t('DASHBOARD_VIEW.TO_REACH_THRESHOLD')}</Typography>
                                                        {thresholdMet === false ?
                                                            <Typography sx={{ fontSize: '14px', ml: 1 }}>
                                                                $ {(fundsById?.funds[0]?.amountToReach * fundsById?.funds[0]?.successThreshold / 100).toLocaleString() - totalInvestment.toLocaleString()}
                                                            </Typography>
                                                            :
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <Typography sx={{ fontSize: '14px', ml: 1 }}>
                                                                    {t('DASHBOARD_VIEW.THRESHOLD_REACHED')}
                                                                </Typography>
                                                                <CheckIcon sx={{ color: 'green', ml: 1 }} />
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* INVESTOR ROI */}
                                            <Grid item md={4} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.ROI')}
                                                </Typography>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography sx={{ fontSize: '24px', fontWeight: 'bold', pr: 1 }}>
                                                        $ 0
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                            </Grid >
                        }
                        {fundsById?.funds[0]?.status === 'Closed' || fundsById?.funds[0]?.status === 'Closed - Not Fully Funded' && totalInvestment > amountToReachThreshold &&
                            <Grid container mt={4} p={{ md: 2, xs: 0 }}>
                                <Grid item md={1} />
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                </Box>
                                {/* COLOR CARD */}
                                <Grid item md={5} xs={12}>
                                    <Box sx={{
                                        height: '30vh',
                                        backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                        borderRadius: '15px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <Box sx={{
                                            width: '15%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img src={actualThemeLight ? Crecimiento : CrecimientoWhite} alt="icon" width="70px" style={{ margin: 'auto' }} />
                                        </Box>
                                        {/* INFORMATION */}
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'space-evenly', alignItems: 'flex-start',
                                                ml: 3
                                            }}>
                                            {/* EXIT COMPANIES */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {t('FUND.EXIT_COMPANIES')}:
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px', ml: 1 }}>
                                                    {assessedProposalsCount}/{companiesAmount}
                                                </Typography>
                                            </Box>
                                            {/* ROI */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ mr: 1 }}>
                                                    {t('FUND.EXPECTED_ROI')}:
                                                </Typography>
                                                <AccessTimeIcon />
                                                <Typography sx={{ fontWeight: 'bold', ml: 1 }}>
                                                    {fundsById?.funds[0]?.roiTime.split(' ')[0]} {t('ADMIN.YEARS')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* DESCRIPTION */}
                                <Grid item md={5} xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                                    <Box sx={{
                                        borderRadius: '15px',
                                        ml: 4,
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <img src={Arrows} alt="icon" width="30px" />
                                            <Box
                                                sx={{
                                                    display: 'flex', height: '100%',
                                                    flexDirection: 'column', justifyContent: 'flex-end',
                                                    ml: 2
                                                }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {t('CREATE_COMPANY.DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ border: '4px solid #9A1D4C', height: '12vh', mt: 2, borderRadius: '5px' }} />
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                    selectedLanguage === 'es' && fundsById?.funds[0].descriptionEs !== '' ?
                                                        fundsById?.funds[0].descriptionEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt !== '' ?
                                                            fundsById?.funds[0].descriptionPt : fundsById?.funds[0].descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={1} />
                                {/* UNDER COLUMNS */}
                                <Grid container mt={4} p={2}>
                                    <Grid item md={1} />
                                    <Grid item md={10} xs={12}>
                                        <Grid container>
                                            {/* SHIP LOGO */}
                                            <Grid item md={3} xs={12} sx={{ mt: { md: 0, xs: 0 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <img src={actualThemeLight ? Rocket : RocketWhite} alt="icon" width="50px" style={{ margin: 'auto' }} />
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        {selectedLanguage === 'en' ? fundsById?.funds[0]?.purpose.titleEn :
                                                            selectedLanguage === 'es' ? fundsById?.funds[0]?.purpose.titleEs :
                                                                selectedLanguage === 'pt' && fundsById?.funds[0]?.purpose.titlePt}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* INVESTED FUNDS */}
                                            <Grid item md={3} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 2 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    {t('FUND.INVESTED_FUNDS')}
                                                </Typography>
                                                <Typography sx={{ fontSize: '12px' }}>
                                                    {dummyData[0].deliveredFunds / totalInvestment}%
                                                </Typography>
                                                <Box sx={{ width: '80%' }}>
                                                    <LinearProgress variant="determinate" value={dummyData[0].deliveredFunds / totalInvestment} />
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ fontSize: '16px', mr: 1 }}>
                                                        {t('FUND.DELIVERED')}:
                                                    </Typography>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        $ {dummyData[0].deliveredFunds}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* TIME SPENT */}
                                            <Grid item md={3} xs={12} sx={{ mt: { md: 0, xs: 4 } }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                        {t('FUND.TIME_SPENT')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex', flexDirection: 'column',
                                                            justifyContent: 'center', alignItems: 'center'
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            <HourglassBottomIcon />
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {yearsSince} {t('ADMIN.YEARS')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>
                                                            {t('FUND.SINCE_FUND_OPENED')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ROI */}
                                            <Grid item md={3} xs={12} sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mt: { md: 0, xs: 4 }
                                            }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                                                    ROI
                                                </Typography>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography sx={{ fontSize: '24px', fontWeight: 'bold', pr: 1 }}>
                                                        {fundsById?.funds[0]?.roiMultiplier}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1} />
                                </Grid>
                            </Grid >
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <>
                            {/* Q&A */}
                            <Box
                                sx={{
                                    pl: 2, display: 'flex', flexDirection: 'column',
                                    width: '100%', alignItems: 'center'
                                }}>
                                <Box sx={{
                                    p: 1,
                                    border: '1px solid black',
                                    width: '98%',
                                    height: '40vh',
                                    overflowY: 'scroll',
                                    borderRadius: '5px'
                                }}>
                                    {filteredData?.map((message) => {
                                        return (
                                            <Box
                                                key={message.id}
                                                sx={{
                                                    mt: 1, mb: 1, ml: 1, mr: 2,
                                                    border: '1px solid pink', borderRadius: '10px',
                                                    backgroundColor: actualThemeLight ? 'white' : '#1D1D1D',
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row', alignItems: 'center',
                                                        ml: 1
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: '#9A1D4C',
                                                            fontSize: '16px'
                                                        }}>
                                                        {message.sentBy.userName}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ ml: 1, fontSize: '12px' }}>
                                                        {message.on.split('T')[0]}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ ml: 1, fontSize: '12px' }}>
                                                        {message.on.split('T')[1].split('.')[0]}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', ml: 1, mt: -1 }}>
                                                    <Typography>{message.text}</Typography>
                                                    <Box
                                                        onClick={() => {
                                                            setToggleReply(!toggleReply)
                                                            setReplyToPerson(message.sentBy.userName)
                                                            setReplyToOriginalMsgId(message.id)
                                                        }}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center', ml: 1,
                                                            cursor: 'pointer',
                                                            p: 0.2
                                                        }}>
                                                        <ReplyIcon fontSize='small' />
                                                        <Typography sx={{ fontSize: '10px' }}>{t('ADMIN.REPLY')}</Typography>
                                                    </Box>
                                                </Box>
                                                {filteredReplies.reverse().map((reply) => {
                                                    return (
                                                        <Box key={reply.id}>
                                                            {reply.replyTo == message.id ?
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row', alignItems: 'center',
                                                                            ml: 3
                                                                        }}>
                                                                        <SubdirectoryArrowRightIcon />
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 'bold',
                                                                                color: '#DB417B',
                                                                                fontSize: '14px'
                                                                            }}>
                                                                            {reply.sentBy.userName}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ ml: 1, mt: 0.4, fontSize: '12px' }}>
                                                                            {reply.on.split('T')[0]}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ ml: 1, mt: 0.4, fontSize: '12px' }}>
                                                                            {reply.on.split('T')[1].split('.')[0]}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row', alignItems: 'center',
                                                                            ml: 6
                                                                        }}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '12px',
                                                                                mt: -0.8
                                                                            }}>
                                                                            {reply.text}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : null
                                                            }
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                            {/* LEAVING A COMMENT - NOT REPLY */}
                            {toggleReply === false &&
                                <> {loadingSendComment ?
                                    <Box
                                        sx={{
                                            width: '98%', pl: { md: 4, xs: 3 },
                                            mt: 1, height: '30px', pt: 3, pb: 3
                                        }}>
                                        <LinearProgress />
                                    </Box>
                                    :
                                    <Box key={'text'} sx={{ width: '100%', pl: { md: 4, xs: 3 }, mt: 1, mb: 4 }}>
                                        <Typography sx={{ fontSize: 12 }}>
                                            {t('FUND_VIEW.LEAVE_A_COMMENT')}
                                        </Typography>
                                        <form style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mb: 1
                                        }} onSubmit={handleSubmit}>
                                            <TextField
                                                key={'texto'}
                                                size='small'
                                                autoFocus="autoFocus"
                                                value={textToSend}
                                                onChange={(event) => { setTextToSend(event.target.value) }}
                                                sx={{ width: '92%' }}
                                                variant="outlined"
                                            />
                                            <Button
                                                type='submit'
                                                sx={{ ml: 2 }}
                                                variant='contained'>
                                                {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                            </Button>
                                        </form>
                                    </Box>
                                }
                                </>
                            }
                            {/* LEAVING A  REPLY */}
                            {toggleReply === true &&
                                <> {loadingSendReply ?
                                    <Box sx={{ width: '98%', pl: { md: 4, xs: 3 }, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                        <LinearProgress />
                                    </Box>
                                    :
                                    <Box sx={{ width: '100%', pl: { md: 4, xs: 3 }, mt: 1, mb: 4 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                {t('ADMIN.REPLY_TO')}{replyToPerson}
                                            </Typography>
                                            <CloseIcon
                                                onClick={() => { setToggleReply(false) }}
                                                sx={{ ml: 1, cursor: 'pointer' }}
                                                fontSize='small'
                                            />
                                        </Box>
                                        <form style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mb: 1
                                        }} onSubmit={handleSendReplyComment}>
                                            <TextField
                                                size='small'
                                                autoFocus="autoFocus"
                                                value={textToSend}
                                                onChange={(event) => { setTextToSend(event.target.value) }}
                                                sx={{ width: '92%' }}
                                                variant="outlined"
                                            />
                                            <Button
                                                type='submit'
                                                sx={{ ml: 2 }}
                                                variant='contained'>
                                                {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                            </Button>
                                        </form>
                                    </Box>
                                }
                                </>
                            }
                        </>
                    </TabPanel>
                </Grid>
            </Grid >
        </Grid >
    </Fragment >
}

export default FundView2