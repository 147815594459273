import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router"
import {
    GET_PROPOSAL_BY_ID,
    MARK_PROPOSAL_COMPLETE,
    MARK_PROPOSAL_INCOMPLETE,
    UPDATE_PROPOSAL_ASSESSMENT,
    UPDATE_PROPOSAL_VOTE_COUNT,
    SEND_COMMENT_TO_PROPOSAL,
    SEND_REPLY_TO_COMMENT_TO_PROPOSAL,
    CREATE_REPORT_PROPOSAL
} from '../../../Queries/Proposals'
import {
    Box, Typography, Avatar, styled, Button, Grid,
    CircularProgress, InputAdornment,
    Link, Tabs, Tab, LinearProgress, Chip, RadioGroup,
    FormControlLabel, Radio, TextField, Select, MenuItem, Modal, IconButton, InputLabel,
    useTheme
} from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PropTypes from 'prop-types';
import Building from '../../../../src/assets/icons/building.svg'
import Target from '../../../../src/assets/icons/target.svg'
import Files from '../../../../src/assets/icons/files.svg'
import LinkIcon from '@mui/icons-material/Link';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import i18n from "i18next";
import { useAuth0 } from '@auth0/auth0-react'
import { SEND_MESSAGE, GET_MESSAGES_FROM_USERS } from '../../../Queries/Messages'
import { fetchUTCTime } from '../../../utils/utcTime'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import {
    CREATE_NOTIFICATION,
} from '../../../Queries/Notifications'
import { showMessage } from '../../../store/actions/snackBarActions'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { imgUploadHandler } from '../../../utils/fileUpload'
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AplicationAdministration = () => {
    const { applicationId } = useParams()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const selectedLanguage = i18n.language
    const { user } = useAuth0()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [radioValue, setRadioValue] = React.useState('');

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    const [fetchProposal,
        { data: proposalByID, loading: proposalByIDLoading }] = useLazyQuery(
            GET_PROPOSAL_BY_ID,
            {
                variables: { proposalId: applicationId },
                pollInterval: 1500
            }
        )

    const [voteCount, setVoteCount] = useState(null)

    useEffect(() => {
        setVoteCount(proposalByID?.proposals[0]?.voteCount)
    }, [proposalByID])


    const [updateVoteCount] = useMutation(UPDATE_PROPOSAL_VOTE_COUNT)

    const handleUpdateVoteCount = async () => {
        try {
            await updateVoteCount({
                variables: {
                    proposalId: applicationId,
                    voteCount: Number(voteCount)
                }
            })
            await dispatch(showMessage({ message: 'Saved', variant: 'success' }))
        }
        catch (error) {
            //Err
        }
    }


    const [LinkTreeLink, setLinkTreeLink] = useState(undefined)

    useEffect(() => {
        if (proposalByID?.proposals[0]?.company?.has_url.length > 0) {
            proposalByID?.proposals[0]?.company?.has_url.map((link) => {
                if (link.name === 'LinkTree') {
                    setLinkTreeLink(link.link)
                }
            })
        }
    }, [proposalByID])

    const [markProposalComplete] = useMutation(MARK_PROPOSAL_COMPLETE,
        {
            variables: {
                proposalId: applicationId
            }
        }
    )

    const [markProposalIncomplete] = useMutation(MARK_PROPOSAL_INCOMPLETE,
        {
            variables: {
                proposalId: applicationId
            }
        }
    )


    const [textToSend, setTextToSend] = useState('')
    const [messageSending, setMessageSending] = useState(false)


    const dataOtherUser = proposalByID?.proposals[0]?.company

    const [getMessages, { data: messagesData }] = useLazyQuery(GET_MESSAGES_FROM_USERS, {
        variables: {
            userId: user?.email || user?.sub, otherUser: dataOtherUser?.users[0]?.userName
        },
        pollInterval: 2000
    })

    useEffect(() => {
        getMessages()
    }, [getMessages, proposalByID])


    const [sendMessageMutation] = useMutation(SEND_MESSAGE)
    const [createNotification] = useMutation(CREATE_NOTIFICATION)
    const myuuid = uuidv4()
    const dateTime = moment.utc()


    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(async () => {
        await fetchProposal()
        await setRadioValue(proposalByID?.proposals[0]?.assessmentStatus)
    }, [fetchProposal, setRadioValue, proposalByID])

    const [assessmentCompleteValue, setAssessmentCompleteValue] = useState(undefined)

    const handleAssessmentCompleteValueChange = (event) => {
        setAssessmentCompleteValue(event.target.value);
    };

    const [score, setScore] = useState(0)

    useEffect(() => {
        if (score > 10) {
            setScore(10)
        }
        if (score < 0) {
            setScore(0)
        }
    }, [score])

    useEffect(() => {
        scrollToBottom()
    })

    const [modalReport, setModalReport] = useState(false)
    const handleOpenReport = () => setModalReport(true);
    const handleCloseReport = () => setModalReport(false);

    const reportStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: '15px',
        boxShadow: 24,
        p: 2,
    };

    const sendMessage = async () => {
        try {
            const utcTime = await fetchUTCTime()
            if (textToSend !== '') {
                setMessageSending(true)
                await sendMessageMutation({
                    variables: {
                        id: myuuid,
                        on: dateTime,
                        onUTC: utcTime,
                        text: textToSend,
                        read: false,
                        deleted: false,
                        userId: user?.email || user?.sub,
                        messageId: myuuid,
                        otherUserId: proposalByID?.proposals[0]?.company.users[0].id,
                    },
                })
                const notifID = uuidv4()
                await createNotification({
                    variables: {
                        notificationID: notifID,
                        msg: `MSG - ${user.email}`,
                        date: dateTime,
                        link: '/messages',
                        userID: proposalByID?.proposals[0]?.company.users[0].id,
                    },
                })
                setMessageSending(false)
                dispatch(showMessage({ message: 'Message Sent!', variant: 'success' }))
            }
            setTextToSend('')
        } catch (err) {
            //Err
        }
    }


    const [assessmentFile, setAssessmentFile] = React.useState('')
    const uploadAssessmentFileHandler = async (file) => {
        const fileURL = await imgUploadHandler(file)
        setAssessmentFile(fileURL)
    }

    const [updateAssessment] = useMutation(UPDATE_PROPOSAL_ASSESSMENT)

    const submitUpdateAssessed = async () => {
        try {
            updateAssessment({
                variables: {
                    proposalId: applicationId,
                    newStatus: 'assessed',
                    assessmentStatus: 'assessed',
                    assessmentDetail: assessmentFile,
                    assessmentRating: Number(score)
                }
            })
        }
        catch (error) {
            //Err
        }
    }

    const [rejectReason, setRejectReason] = useState('')

    const submitUpdateRejected = async () => {
        try {
            updateAssessment({
                variables: {
                    proposalId: applicationId,
                    newStatus: 'rejected',
                    assessmentStatus: 'rejected',
                    assessmentDetail: rejectReason,
                    assessmentRating: 0
                }
            })
        }
        catch (error) {
            //Err
        }
    }

    const [commentToSend, setCommentToSend] = useState('')
    const [toggleReply, setToggleReply] = useState(false)
    const [replyToPerson, setReplyToPerson] = useState('')
    const [replyToOriginalMsgId, setReplyToOriginalMsgId] = useState('')
    const [sendComment, { loading: loadingSendComment }] = useMutation(SEND_COMMENT_TO_PROPOSAL)
    const [sendReplyToComment, { loading: loadingSendReply }] = useMutation(SEND_REPLY_TO_COMMENT_TO_PROPOSAL)

    const handleSendComment = async (e) => {
        e.preventDefault()
        await sendComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: commentToSend,
                UserId: user.email || user?.sub,
                ProposalId: applicationId
            }
        })
        setCommentToSend("")
    }

    const handleSendReplyComment = async (e) => {
        e.preventDefault()
        await sendReplyToComment({
            variables: {
                MessageId: myuuid,
                MessageDate: dateTime,
                MessageText: commentToSend,
                UserId: user.email || user?.sub,
                ProposalId: applicationId,
                replyToId: replyToOriginalMsgId
            }
        })
        setCommentToSend("")
        setToggleReply(false)
    }

    const filteredData = proposalByID?.proposals[0]?.messages?.filter(item => {
        const isNullReply = item.replyTo === null
        return isNullReply === true;
    });
    const filteredReplies = proposalByID?.proposals[0]?.messages?.filter(item => {
        const isNullReply = item.replyTo === null
        return isNullReply !== true;
    });

    const [sendReport] = useMutation(CREATE_REPORT_PROPOSAL)

    const newRepID = uuidv4()
    const newUrlID = uuidv4()

    const [reportTitleEn, setReportTitleEn] = useState('')
    const [reportTitleEs, setReportTitleEs] = useState('')
    const [reportTitlePt, setReportTitlePt] = useState('')
    const [achievedObjective, setAchievedObjective] = useState(undefined)
    const [amountReceived, setAmountReceived] = useState(undefined)
    const [amountRequested, setAmountRequested] = useState(undefined)
    const [votePercent, setVotePercent] = useState(undefined)
    const [reportLinkUrl, setReportLinkUrl] = useState(undefined)

    useEffect(() => {
        if (votePercent > 100) {
            setVotePercent(100)
        }
        if (votePercent < 0) {
            setVotePercent(0)
        }
    }, [votePercent])

    const submitReport = async () => {
        try {
            sendReport({
                variables: {
                    reportData: {
                        id: newRepID,
                        titleEn: reportTitleEn,
                        titleEs: reportTitleEs,
                        titlePt: reportTitlePt,
                        achievedObjective: achievedObjective,
                        amountReceived: Number(amountReceived),
                        amountRequested: Number(amountRequested),
                        votePercent: Number(votePercent)
                    },
                    urlData: {
                        id: newUrlID,
                        link: reportLinkUrl,
                        name: "ReportLink"
                    },
                    reportId: newRepID,
                    urlId: newUrlID,
                    proposalId: applicationId
                }
            })
            handleCloseReport()
            setReportTitleEn('')
            setReportTitleEs('')
            setReportTitlePt('')
            setAchievedObjective(undefined)
            setAmountReceived(undefined)
            setAmountRequested(undefined)
            setVotePercent(undefined)
            setReportLinkUrl(undefined)
        }
        catch (error) {
            //Err
        }
    }

    if (proposalByIDLoading) {
        return (
            <>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '30vh'
                }}>
                    <CircularProgress />
                </Box>
            </>
        )
    }

    // Button styling for back button
    const BackButton = styled(Button)(({ theme }) => ({
        color: theme.palette.text.hint,
        fontFamily: 'Quicksand, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    }))

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} className="right">
                    <Box sx={{ backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B", }}>

                        <Grid container>
                            {/* BACK BUTTON & AVATAR */}
                            <Grid item md={1} xs={1} sx={{ alignSelf: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <ArrowBackIcon sx={{ fontSize: '16px' }} />
                                    <BackButton
                                        onClick={() => dispatch(goBack())}
                                        sx={{ marginBottom: '10px', textDecoration: 'underline', fontSize: '14px' }}
                                    >
                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                    </BackButton>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={2} sx={{ maxHeight: '16vh', alignSelf: 'center' }} >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: '110px',
                                            height: '110px',
                                        }}
                                        alt={proposalByID?.proposals[0]?.company.coName}
                                        src={proposalByID?.proposals[0]?.company.logo}
                                    />
                                </Box>
                            </Grid>
                            {/* FUND INFO */}
                            <Grid item md={8} xs={8} sx={{ alignSelf: 'center' }}>
                                <Grid container>
                                    <Grid item md={0.5} xs={0.5} />
                                    {/* COLUMN 1 */}
                                    <Grid item md={4.5} xs={4.5}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                {proposalByID?.proposals[0]?.company.coName}
                                            </Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {proposalByID?.proposals[0]?.status === 'proposed' &&
                                                        <Typography>
                                                            {t('CO_ASSESSMENT.APPLIED')}
                                                        </Typography>}
                                                </Typography>
                                                <FiberManualRecordIcon fontSize='small' sx={{ ml: 1, mr: 1 }} />
                                                <Typography sx={{ fontSize: '18px' }}>
                                                    {proposalByID?.proposals[0]?.fund.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* COLUMN 2 */}
                                    <Grid item md={6} xs={6} sx={{ alignSelf: 'center', height: '100%', ml: 3 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                {proposalByID?.proposals[0]?.company.users[0].userName}
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                {proposalByID?.proposals[0]?.company.address} - {proposalByID?.proposals[0]?.company.country.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', pl: 20, pr: 8 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={t('FUND.INFORMATION')} {...a11yProps(0)} />
                                    <Tab label={'Q&A'} {...a11yProps(1)} />
                                    <Tab label={t('COMPANY_VIEW.REPORTS')} {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography>#Tags</Typography>
                                    {proposalByID?.proposals[0]?.company?.preferences?.map((pref) => {

                                        return (< Chip variant="outlined" key={pref.id} label={pref.preferenceName} sx={{ ml: 1, mr: 1 }} />)
                                    }
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', mt: 2 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                            <img src={Building} alt="icon" width="55px" />
                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', ml: 2 }}>{t('INVERSOR_VIEW.OFFERING_CARD.DESCRIPTION')}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('COMPANY.EMPLOYEES')}: {proposalByID?.proposals[0]?.company?.employees}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('CREATE_COMPANY.FOUNDATION_YEAR')}: {proposalByID?.proposals[0]?.company?.fundationYear?.split('-')[0]}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('CREATE_COMPANY.ANNUAL_BILLING')}: {proposalByID?.proposals[0]?.company?.annualBilling}</Typography>
                                        </Box>
                                        {LinkTreeLink === undefined ? null :
                                            <Link
                                                href={proposalByID?.proposals[0]?.company?.has_url[0]?.link}
                                                target={{ _blank: true }}
                                                color='inherit'
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <LinkIcon />
                                                <Typography>LinkTree</Typography>
                                            </Link>
                                        }
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Box sx={{ minWidth: '10px', height: '8vh', backgroundColor: '#9A1D4C', mt: 2 }}>
                                                <Typography sx={{ color: '#9A1D4C' }}>a</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', ml: 2 }}>
                                                {selectedLanguage === 'en' ? proposalByID?.proposals[0]?.company.descriptionEn :
                                                    selectedLanguage === 'es' && proposalByID?.proposals[0]?.company.descriptionEs !== null ?
                                                        proposalByID?.proposals[0]?.company.descriptionEs :
                                                        selectedLanguage === 'pt' && proposalByID?.proposals[0]?.company.descriptionPt !== null ?
                                                            proposalByID?.proposals[0]?.company.descriptionPt : proposalByID?.proposals[0]?.company.descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', mt: 2 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <DoubleArrowIcon fontSize="large" />
                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', ml: 2 }}>
                                                {selectedLanguage === 'en' ? proposalByID?.proposals[0]?.titleEn :
                                                    selectedLanguage === 'es' && proposalByID?.proposals[0]?.titleEs !== null ?
                                                        proposalByID?.proposals[0]?.titleEs :
                                                        selectedLanguage === 'pt' && proposalByID?.proposals[0]?.titlePt !== null ?
                                                            proposalByID?.proposals[0]?.titlePt : proposalByID?.proposals[0]?.titleEn}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>
                                                {selectedLanguage === 'en' ? proposalByID?.proposals[0]?.descriptionEn :
                                                    selectedLanguage === 'es' && proposalByID?.proposals[0]?.descriptionEs !== '' ?
                                                        proposalByID?.proposals[0]?.descriptionEs :
                                                        selectedLanguage === 'pt' && proposalByID?.proposals[0]?.descriptionPt !== '' ?
                                                            proposalByID?.proposals[0]?.descriptionPt : proposalByID?.proposals[0]?.descriptionEn}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', mt: 2 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                            <img src={Target} alt="icon" width="55px" />
                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', ml: 2 }}>{t('FUND.OBJECTIVES')}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                            <Typography>{t('MY_OFFERINGS.REQUESTED')}{proposalByID?.proposals[0]?.reqInvestAmount}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', mt: 2 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                            <img src={Files} alt="icon" width="55px" />
                                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', ml: 2 }}>{t('CREATE_COMPANY.PROJECT_FILES')}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                            <Link
                                                href={proposalByID?.proposals[0]?.milestonesFile}
                                                target={{ _blank: true }}
                                                color='inherit'
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <LinkIcon />
                                                <Typography>{t('CO_ASSESSMENT.VIEW_MILESTONES')}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                                            <Link
                                                href={proposalByID?.proposals[0].actionPlanFile}
                                                target={{ _blank: true }}
                                                color='inherit'
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <LinkIcon />
                                                <Typography>{t('CO_ASSESSMENT.DOWNLOAD_ACT_PLAN')}</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                                {proposalByID?.proposals[0].status === 'proposed' &&
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
                                        <Box sx={{
                                            width: '70%',
                                            border: '1px solid black',
                                            borderRadius: '15px', p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <RadioGroup row
                                                value={radioValue}
                                                onChange={handleRadioChange}
                                            >
                                                <FormControlLabel value="complete" control={<Radio />} label={t('CO_ASSESSMENT.MARK_COMPLETE')} />
                                                <FormControlLabel value="incomplete" control={<Radio />} label={t('CO_ASSESSMENT.MARK_INCOMPLETE')} />
                                            </RadioGroup>
                                            {radioValue === 'complete' && proposalByID?.proposals[0]?.assessmentStatus !== 'complete' ?
                                                <Button
                                                    onClick={() => { markProposalComplete() }}
                                                    sx={{ mt: 2 }} variant='contained'>
                                                    {t('PERFIL_CONFIG_VIEW.CONTINUE')}
                                                </Button> : null
                                            }
                                            {radioValue === 'incomplete' && proposalByID?.proposals[0]?.assessmentStatus !== 'incomplete' ?
                                                <Button
                                                    onClick={() => { markProposalIncomplete() }}
                                                    sx={{ mt: 2 }} variant='contained'>
                                                    {t('PERFIL_CONFIG_VIEW.CONTINUE')}
                                                </Button> : null
                                            }
                                            {radioValue === 'pending' &&
                                                <Button disabled sx={{ mt: 2 }} variant='contained'>
                                                    {t('PERFIL_CONFIG_VIEW.CONTINUE')}
                                                </Button>
                                            }
                                            {
                                                proposalByID?.proposals[0]?.assessmentStatus === 'incomplete' && radioValue === 'incomplete' ?
                                                    <Box sx={{
                                                        mt: 2,
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        {messagesData?.messages.length < 1
                                                            ?
                                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                                {t('CO_ASSESSMENT.MESSAGE_REQ_MISSING_INFO')}
                                                            </Typography>
                                                            :
                                                            <Box
                                                                sx={{
                                                                    border: '1px solid gray',
                                                                    borderRadius: '5px',
                                                                    width: '90%',
                                                                    mt: 2,
                                                                    height: '30vh',
                                                                    overflow: 'auto',
                                                                    p: 1
                                                                }}>
                                                                {messagesData?.messages?.map((message) => {
                                                                    return (<>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            border: '1px solid pink',
                                                                            borderRadius: '5px',
                                                                            mt: 1, p: 1
                                                                        }}>
                                                                            <Box sx={{ width: '23%' }}>
                                                                                <Typography>{message.sentBy.userName}</Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: '65%' }}>
                                                                                <Typography>{message.text}</Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: '12%' }}>
                                                                                <Typography fontSize={12}>{message.onUTC.split('T')[0]}</Typography>
                                                                                <Typography fontSize={12}>{message.onUTC.split('T')[1].split('.')[0]}</Typography>
                                                                            </Box>
                                                                        </Box>

                                                                        < div ref={messagesEndRef} />
                                                                    </>)
                                                                })}
                                                            </Box>
                                                        }
                                                        {value === false ?
                                                            < TextField
                                                                onChange={(e) => setTextToSend(e.target.value)}
                                                                value={textToSend}
                                                                sx={{ width: '90%', mt: 2 }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                            />
                                                            :
                                                            < TextField
                                                                onChange={(e) => setTextToSend(e.target.value)}
                                                                value={textToSend}
                                                                sx={{ width: '90%', mt: 2 }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                            />
                                                        }

                                                        {messageSending && <Box sx={{ width: '50%', mt: 2 }}>
                                                            <LinearProgress />
                                                        </Box>}
                                                        <Grid item
                                                            md={12}
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignContent: 'center',
                                                                justifyContent: 'center',
                                                                mt: 2,
                                                                mb: 4,
                                                                maxHeight: '4vh'
                                                            }}>
                                                            <Button
                                                                sx={{ ml: 4 }}
                                                                variant='contained'
                                                                onClick={() => {
                                                                    sendMessage()
                                                                    setTextToSend('')
                                                                }}
                                                            >
                                                                {t('CO_ASSESSMENT.SEND_MESSAGE')}
                                                            </Button>
                                                        </Grid>
                                                    </Box>
                                                    : null
                                            }
                                            {
                                                proposalByID?.proposals[0]?.assessmentStatus === 'complete' && radioValue === 'complete' ?
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mr: 10 }}>
                                                                {t('CO_ASSESSMENT.STATUS')}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size='small'
                                                                    sx={{ ml: 1, minWidth: '150px' }}
                                                                    value={assessmentCompleteValue}
                                                                    onChange={handleAssessmentCompleteValueChange}
                                                                >
                                                                    <MenuItem value={'assessed'}>{t('CO_ASSESSMENT.STATUS_ASSESSED')}</MenuItem>
                                                                    <MenuItem value={'rejected'}>{t('CO_ASSESSMENT.STATUS_REJECTED')}</MenuItem>
                                                                </Select>
                                                            </Box>
                                                            {assessmentCompleteValue === 'assessed' &&
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 10 }}>
                                                                    {t('CO_ASSESSMENT.SCORE')}
                                                                    <TextField
                                                                        variant="outlined"
                                                                        type="number"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                                        value={score}
                                                                        sx={{ ml: 1, width: '150px' }}
                                                                        size='small'
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">/10</InputAdornment>,
                                                                        }}
                                                                        onChange={(e) => setScore(e.target.value)}
                                                                        error={isNaN(score) && score !== ''}
                                                                    />
                                                                </Box>}
                                                        </Box>
                                                    </Box>
                                                    : null
                                            }
                                            {assessmentCompleteValue === 'assessed' && radioValue === 'complete' &&
                                                proposalByID?.proposals[0]?.assessmentStatus === 'complete'
                                                ?
                                                <Box sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    {assessmentFile ?
                                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                component="label"
                                                                starticon={<UploadFileIcon />}
                                                                sx={{
                                                                    borderRadius: '5px',
                                                                    border: '1px dashed #000000',
                                                                    width: { xs: '100%', md: '80%' },
                                                                    height: '100px',
                                                                    marginRight: { md: '5px' }
                                                                }}
                                                            >
                                                                ✅
                                                            </Button>
                                                            {assessmentFile !== '' && score !== 0 ?
                                                                <Box>
                                                                    <Button onClick={() => { setAssessmentCompleteValue('') }} variant='contained' sx={{ mt: 1, mr: 1 }}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                                                    <Button onClick={submitUpdateAssessed} variant='contained' sx={{ mt: 1 }}>{t('CO_ASSESSMENT.SAVE')}</Button>
                                                                </Box>
                                                                :
                                                                <Box>
                                                                    <Button onClick={() => { setAssessmentCompleteValue('') }} variant='contained' sx={{ mt: 1, mr: 1 }}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                                                    <Button disabled variant='contained' sx={{ mt: 1 }}>{t('CO_ASSESSMENT.SAVE')}</Button>
                                                                </Box>
                                                            }
                                                        </Box>
                                                        : <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                component="label"
                                                                starticon={<UploadFileIcon />}
                                                                sx={{
                                                                    borderRadius: '5px',
                                                                    border: '1px dashed #000000',
                                                                    width: { xs: '100%', md: '80%' },
                                                                    height: '100px',
                                                                    marginRight: { md: '5px' }
                                                                }}
                                                            >
                                                                <UploadFileIcon sx={{ mr: 2 }} />
                                                                <input
                                                                    hidden
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={(e) => uploadAssessmentFileHandler(e.target.files?.[0])}
                                                                />
                                                                {t('CO_ASSESSMENT.UPLOAD_ASSESSMENT')}
                                                            </Button>
                                                            <Box>
                                                                <Button onClick={() => { setAssessmentCompleteValue('') }} variant='contained' sx={{ mt: 1, mr: 1 }}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                                                <Button disabled variant='contained' sx={{ mt: 1 }}>{t('CO_ASSESSMENT.SAVE')}</Button>
                                                            </Box>
                                                        </Box>
                                                    }
                                                </Box> : null
                                            }
                                            {assessmentCompleteValue === 'rejected' && radioValue === 'complete' ?
                                                <Box sx={{ width: '90%', mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    {t('CO_ASSESSMENT.REJ_MESSAGE')}
                                                    <TextField
                                                        size='small'
                                                        multiline
                                                        minRows={3}
                                                        fullWidth
                                                        onChange={(e) => setRejectReason(e.target.value)}
                                                        value={rejectReason}
                                                    />
                                                    <Box>
                                                        <Button onClick={() => { setAssessmentCompleteValue('') }} variant='contained' sx={{ mt: 1, mr: 1 }}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                                        {rejectReason === '' ?
                                                            <Button disabled variant='contained' sx={{ mt: 1 }}>{t('CO_ASSESSMENT.SAVE')}</Button>
                                                            :
                                                            <Button onClick={submitUpdateRejected} variant='contained' sx={{ mt: 1 }}>{t('CO_ASSESSMENT.SAVE')}</Button>
                                                        }
                                                    </Box>
                                                </Box>
                                                : null
                                            }
                                        </Box>
                                        <Box sx={{ height: '10vh' }}>

                                        </Box>
                                    </Box>}
                            </TabPanel>
                            <TabPanel value={value} index={1}>

                                <>
                                    <Box>
                                        <Box sx={{
                                            width: '100%',
                                            height: '48vh',
                                            borderBottom: '1px solid black',
                                            overflowY: 'scroll'
                                        }}>
                                            {filteredData?.length === 0 &&
                                                <>
                                                    <Typography>{t('NEWS_SECTION.NO_COMMENTS')}</Typography>
                                                </>
                                            }
                                            {filteredData?.map((message) => {
                                                return (
                                                    <Box
                                                        key={message.id}
                                                        sx={{
                                                            mt: 1, mb: 1, ml: 1, mr: 2,
                                                            border: '1px solid pink', borderRadius: '10px'
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row', alignItems: 'center',
                                                                ml: 1
                                                            }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    color: '#9A1D4C',
                                                                    fontSize: '16px'
                                                                }}>
                                                                {message.sentBy.userName}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ ml: 1, fontSize: '12px' }}>
                                                                {message.on.split('T')[0]}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ ml: 1, fontSize: '12px' }}>
                                                                {message.on.split('T')[1].split('.')[0]}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', ml: 1, mt: -1 }}>
                                                            <Typography>{message.text}</Typography>
                                                            <Box
                                                                onClick={() => {
                                                                    setToggleReply(!toggleReply)
                                                                    setReplyToPerson(message.sentBy.userName)
                                                                    setReplyToOriginalMsgId(message.id)
                                                                }}
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center', ml: 1,
                                                                    cursor: 'pointer',
                                                                    p: 0.2
                                                                }}>
                                                                <ReplyIcon fontSize='small' />
                                                                <Typography sx={{ fontSize: '10px' }}>{t('ADMIN.REPLY')}</Typography>
                                                            </Box>
                                                        </Box>
                                                        {filteredReplies.reverse().map((reply) => {
                                                            return (
                                                                <Box key={reply.id}>
                                                                    {reply.replyTo == message.id ?
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row', alignItems: 'center',
                                                                                    ml: 3
                                                                                }}>
                                                                                <SubdirectoryArrowRightIcon />
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontWeight: 'bold',
                                                                                        color: '#DB417B',
                                                                                        fontSize: '14px'
                                                                                    }}>
                                                                                    {reply.sentBy.userName}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{ ml: 1, mt: 0.4, fontSize: '12px' }}>
                                                                                    {reply.on.split('T')[0]}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{ ml: 1, mt: 0.4, fontSize: '12px' }}>
                                                                                    {reply.on.split('T')[1].split('.')[0]}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row', alignItems: 'center',
                                                                                    ml: 6
                                                                                }}>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '12px',
                                                                                        mt: -0.8
                                                                                    }}>
                                                                                    {reply.text}
                                                                                </Typography>
                                                                            </Box>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                )

                                            })}
                                        </Box>

                                    </Box>
                                    {/* LEAVING A COMMENT - NOT REPLY */}
                                    {toggleReply === false &&
                                        <> {loadingSendComment ?
                                            <Box sx={{ width: '100%', pl: 2, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                                <LinearProgress />
                                            </Box>
                                            :
                                            <Box sx={{ pl: 2, mt: 1 }}>
                                                <Typography sx={{ fontSize: 12 }}>
                                                    {t('FUND_VIEW.LEAVE_A_COMMENT')}
                                                </Typography>

                                                <form style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mb: 1
                                                }} onSubmit={handleSendComment}>
                                                    <TextField
                                                        size='small'
                                                        value={commentToSend}
                                                        onChange={(event) => { setCommentToSend(event.target.value) }}
                                                        sx={{ width: '90%' }}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        type='submit'
                                                        sx={{ ml: 2 }}
                                                        variant='contained'>
                                                        {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                                    </Button>
                                                </form>
                                            </Box>
                                        }
                                        </>
                                    }

                                    {/* LEAVING A  REPLY */}
                                    {toggleReply === true &&
                                        <> {loadingSendReply ?
                                            <Box sx={{ width: '100%', pl: 2, mt: 1, height: '30px', pt: 3, pb: 3 }}>
                                                <LinearProgress />
                                            </Box>
                                            :
                                            <Box sx={{ pl: 2, mt: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                        {t('ADMIN.REPLY_TO')} {replyToPerson}
                                                    </Typography>
                                                    <CloseIcon onClick={() => { setToggleReply(false) }} sx={{ ml: 1, cursor: 'pointer' }} fontSize='small' />
                                                </Box>
                                                <form style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mb: 1
                                                }} onSubmit={handleSendReplyComment}>
                                                    <TextField
                                                        size='small'
                                                        value={commentToSend}
                                                        onChange={(event) => { setCommentToSend(event.target.value) }}
                                                        sx={{ width: '90%' }}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        type='submit'
                                                        sx={{ ml: 2 }}
                                                        variant='contained'>
                                                        {t('INVESTMENT_MODAL.MESSAGE.SEND')}
                                                    </Button>
                                                </form>
                                            </Box>
                                        }
                                        </>
                                    }
                                </>

                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', mt: 1 }}>
                                    <Button onClick={handleOpenReport} variant='outlined'>
                                        {t('ADMIN.UPLOAD_REPORT')}
                                    </Button>
                                    {
                                        proposalByID?.proposals[0].status === 'assessed' &&
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography>{t('ADMIN.RECEIVED_VOTES')}:</Typography>
                                            <TextField
                                                type='number'
                                                sx={{ ml: 1, mr: 1 }}
                                                size='small'
                                                value={voteCount}
                                                onChange={(e) => setVoteCount(e.target.value)}
                                                onWheel={(e) => e.target.blur()}
                                            ></TextField>
                                            <Button onClick={handleUpdateVoteCount} variant='contained'>
                                                {t('CO_ASSESSMENT.SAVE')}
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center', mt: 2 }}>
                                    {proposalByID?.proposals[0]?.report?.map((rep) => {
                                        return (
                                            <Box
                                                key={rep.id}
                                                sx={{
                                                    width: '100% ',
                                                    border: '1px solid #DB417B',
                                                    borderRadius: '15px',
                                                    p: 2,
                                                    mt: 2
                                                }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <Box>
                                                        <Typography>
                                                            {selectedLanguage === 'en' ? rep.titleEn :
                                                                selectedLanguage === 'es' ? rep.titleEs :
                                                                    rep.titlePt
                                                            }</Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.REPORT')}
                                                        </Typography>
                                                        <Link
                                                            href={rep.url.link}
                                                            target={{ _blank: true }}
                                                            color='inherit'>
                                                            {rep.url.link}
                                                        </Link>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.GOAL_ACHIEVED')}
                                                        </Typography>
                                                        <Typography>
                                                            {rep.achievedObjective}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.RECEIVED')}
                                                        </Typography>
                                                        <Typography>
                                                            {rep.amountReceived}/{rep.amountRequested}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography>
                                                            {t('ADMIN.VOTES')}
                                                        </Typography>
                                                        <Typography>
                                                            {rep.votePercent} %
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Modal
                                    open={modalReport}
                                    onClose={handleCloseReport}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={reportStyle}>
                                        <Box sx={{
                                            position: 'relative',
                                            width: '100%', height: '50px'
                                        }}>
                                            <IconButton onClick={handleCloseReport} sx={{ position: 'absolute', right: '10px' }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                            <Grid item
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                <Typography sx={{ mr: 2 }}>
                                                    English Title
                                                </Typography>
                                                <TextField size='small'
                                                    value={reportTitleEn}
                                                    onChange={(e) => setReportTitleEn(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    ml: 1,
                                                    mr: 1
                                                }}>
                                                <Typography sx={{ mr: 2 }}>
                                                    Titulo en Español
                                                </Typography>
                                                <TextField size='small'
                                                    value={reportTitleEs}
                                                    onChange={(e) => setReportTitleEs(e.target.value)} />
                                            </Grid>
                                            <Grid item
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                <Typography sx={{ mr: 2 }}>
                                                    Titulo em portugues
                                                </Typography>
                                                <TextField size='small'
                                                    value={reportTitlePt}
                                                    onChange={(e) => setReportTitlePt(e.target.value)} />
                                            </Grid>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    width: '25%'
                                                }}>
                                                {t('ADMIN.GOAL_ACHIEVED')}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                value={achievedObjective}
                                                onChange={(e) => setAchievedObjective(e.target.value)}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '49%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        width: '50%'
                                                    }}>
                                                    {t('ADMIN.RECEIVED')}
                                                </InputLabel>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                    width={'50%'}
                                                    sx={{ ml: -7 }}
                                                    value={amountReceived}
                                                    onChange={(e) => setAmountReceived(e.target.value)}
                                                    error={isNaN(amountReceived) && amountReceived !== ''}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '49%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        width: '50%'
                                                    }}>
                                                    /{t('ADMIN.AMOUNT_GATHER')}
                                                </InputLabel>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                    width={'50%'}
                                                    value={amountRequested}
                                                    onChange={(e) => setAmountRequested(e.target.value)}
                                                    error={isNaN(amountReceived) && amountReceived !== ''}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    width: '32%'
                                                }}>
                                                % {t('ADMIN.VOTES')}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                width={'100px'}
                                                type="number"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                                                value={votePercent}
                                                onChange={(e) => setVotePercent(e.target.value)}
                                                error={isNaN(votePercent) && votePercent !== ''}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    width: '25%'
                                                }}>
                                                {t('CREATE_OFFERING.USEFUL_LINK')}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                value={reportLinkUrl}
                                                onChange={(e) => setReportLinkUrl(e.target.value)}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <Button onClick={handleCloseReport} sx={{ mr: 2 }} variant='contained'>Cancel</Button>
                                            {reportTitleEn === '' ||
                                                reportTitleEs === '' ||
                                                reportTitlePt === '' ||
                                                achievedObjective === undefined ||
                                                amountReceived === undefined ||
                                                amountRequested === undefined ||
                                                votePercent === undefined ||
                                                reportLinkUrl === undefined
                                                ?
                                                <Button disabled variant='contained'>Save</Button>
                                                :
                                                <Button onClick={submitReport} variant='contained'>Save</Button>
                                            }
                                        </Box>
                                    </Box>
                                </Modal>
                            </TabPanel>
                        </Box>
                    </Grid >
                </Grid >
            </Grid >
        </>
    )
}

export default AplicationAdministration