import { Box } from '@mui/material'
import React from 'react'
import TransactionsCard from './TransactionsCard'

function TransactionsRenderer({ transactions }) {
    return (
        <Box
            sx={{
                rowGap: '5px',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            {transactions?.map((transaction, i) => (
                <TransactionsCard key={i} transaction={transaction} />
            ))}
        </Box>

    )
}

export default TransactionsRenderer