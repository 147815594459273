import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tooltip,
  useTheme,
} from '@mui/material'
import './FundView.css'
import { useLazyQuery } from '@apollo/client'
import FundCard from '../../components/FundCard/FundCard'
import { GET_FUNDS_SEARCH } from '../../Queries/Funds'
import useDebounce from '../../hooks/useDebounce'
import { useTranslation } from 'react-i18next'
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

export default function BasicTabs() {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 250)
  const [sort, setSort] = useState('default')
  const handleChangeSort = (event) => {
    setSort(event.target.value)
  }

  useEffect(async () => {
    if (debouncedSearchTerm) {
      await fetchFunds()
    }
  }, [debouncedSearchTerm])

  const [openFilter, setOpenFilter] = useState(false)
  const handleOpenFilter = () => setOpenFilter(true)
  const handleCloseFilter = () => setOpenFilter(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 700, xs: '80%' },
    bgcolor: 'background.paper',
    p: 3,
  }

  const [filter, setFilter] = useState([
    'Collecting',
    'Published',
    'Assessment',
    'Open',
    'ROI',
    'Closed',
    'Closed - Not Fully Funded',
    'Governance',
    'Execution',
    'Finished',
    'Vesting',
  ])

  const [fetchFunds, { data: fundsData, loading: fundsDataLoading }] =
    useLazyQuery(GET_FUNDS_SEARCH, {
      pollInterval: 10000,
      variables: {
        search: debouncedSearchTerm,
        sort: sort === 'default' ? { name: 'ASC' } : sort,
        statusFilter: filter,
      },
    })

  useEffect(() => {
    fetchFunds()
  }, [fetchFunds, filter])

  const [funds, setFunds] = useState([])

  const sortedStatuses = [
    'Collecting',
    'Published',
    'Open',
    'Assessment',
    'Governance',
    'Vesting',
    'Execution',
    'ROI',
    'Closed',
    'Closed - Not Fully Funded',
  ]

  useEffect(() => {
    if (fundsData && fundsData?.funds) {
      const sortedFunds = [...fundsData?.funds].sort((a, b) => {
        const statusA = sortedStatuses.indexOf(a.status)
        const statusB = sortedStatuses.indexOf(b.status)
        return statusA - statusB
      })
      setFunds(sortedFunds)
    }
  }, [fundsData])

  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'

  return (
    <Box>
      <Grid
        container
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          pt: 4,
        }}
      >
        <Grid item xs={12} md={6} sx={{ pl: 8, pr: 8 }}>
          <FormControl fullWidth>
            <OutlinedInput
              size="small"
              fullWidth
              placeholder={t('INVERSOR_VIEW.SEARCH')}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            pl: { md: 0, xs: 8 },
            pr: { md: 0, xs: 8 },
            mt: { md: 0, xs: 2 },
          }}
        >
          <ImportExportOutlinedIcon
            sx={{ color: actualThemeLight ? null : 'white' }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              width: '40%',
              color: actualThemeLight ? 'black' : 'white',
            }}
          >
            {t('ADMIN.SORT_BY')}
          </Typography>
          <FormControl fullWidth>
            <Select
              fullWidth
              sx={{ maxHeight: '40px' }}
              value={sort}
              renderValue={(selected) => {
                if (selected === 'default') {
                  return t('ADMIN.STATUS')
                }
                if (selected.name) {
                  return selected.name === 'ASC'
                    ? t('FUND_SEARCH_VIEW.NAME_SORT_A')
                    : t('FUND_SEARCH_VIEW.NAME_SORT_Z')
                }
                if (selected.minInvestment) {
                  return selected.minInvestment === 'ASC'
                    ? t('FUND_SEARCH_VIEW.MIN_INVESTMENT_LOW')
                    : t('FUND_SEARCH_VIEW.MIN_INVESTMENT_HIGH')
                }
                if (selected.maxInvestment) {
                  return selected.maxInvestment === 'ASC'
                    ? t('FUND_SEARCH_VIEW.MAX_INVESTMENT_LOW')
                    : t('FUND_SEARCH_VIEW.MAX_INVESTMENT_HIGH')
                }
                if (selected.minCompanyAsk) {
                  return selected.minCompanyAsk === 'ASC'
                    ? t('FUND_SEARCH_VIEW.MIN_CO_ASK_LOW')
                    : t('FUND_SEARCH_VIEW.MIN_CO_ASK_HIGH')
                }
                if (selected.maxCompanyAsk) {
                  return selected.maxCompanyAsk === 'ASC'
                    ? t('FUND_SEARCH_VIEW.MAX_CO_ASK_LOW')
                    : t('FUND_SEARCH_VIEW.MAX_CO_ASK_HIGH')
                }
                if (selected.successThreshold) {
                  return selected.successThreshold === 'ASC'
                    ? t('FUND_SEARCH_VIEW.SUCC_THRESHOLD_LOW')
                    : t('FUND_SEARCH_VIEW.SUCC_THRESHOLD_HIGH')
                }
                return ''
              }}
              onChange={handleChangeSort}
            >
              <MenuItem value={'default'}>{t('ADMIN.STATUS')}</MenuItem>
              <MenuItem value={{ name: 'ASC' }}>
                {t('FUND_SEARCH_VIEW.NAME_SORT_A')}
              </MenuItem>
              <MenuItem value={{ name: 'DESC' }}>
                {t('FUND_SEARCH_VIEW.NAME_SORT_Z')}
              </MenuItem>
              <MenuItem value={{ minInvestment: 'ASC' }}>
                {t('FUND_SEARCH_VIEW.MIN_INVESTMENT_LOW')}
              </MenuItem>
              <MenuItem value={{ minInvestment: 'DESC' }}>
                {t('FUND_SEARCH_VIEW.MIN_INVESTMENT_HIGH')}
              </MenuItem>
              <MenuItem value={{ maxInvestment: 'ASC' }}>
                {t('FUND_SEARCH_VIEW.MAX_INVESTMENT_LOW')}
              </MenuItem>
              <MenuItem value={{ maxInvestment: 'DESC' }}>
                {t('FUND_SEARCH_VIEW.MAX_INVESTMENT_HIGH')}
              </MenuItem>
              <MenuItem value={{ minCompanyAsk: 'ASC' }}>
                {t('FUND_SEARCH_VIEW.MIN_CO_ASK_LOW')}
              </MenuItem>
              <MenuItem value={{ minCompanyAsk: 'DESC' }}>
                {t('FUND_SEARCH_VIEW.MIN_CO_ASK_HIGH')}
              </MenuItem>
              <MenuItem value={{ maxCompanyAsk: 'ASC' }}>
                {t('FUND_SEARCH_VIEW.MAX_CO_ASK_LOW')}
              </MenuItem>
              <MenuItem value={{ maxCompanyAsk: 'DESC' }}>
                {t('FUND_SEARCH_VIEW.MAX_CO_ASK_HIGH')}
              </MenuItem>
              <MenuItem value={{ successThreshold: 'ASC' }}>
                {t('FUND_SEARCH_VIEW.SUCC_THRESHOLD_LOW')}
              </MenuItem>
              <MenuItem value={{ successThreshold: 'DESC' }}>
                {t('FUND_SEARCH_VIEW.SUCC_THRESHOLD_HIGH')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            pl: { md: 0, xs: 8 },
            pr: { md: 0, xs: 8 },
            mt: { md: 0, xs: 2 },
          }}
        >
          <Box
            onClick={handleOpenFilter}
            sx={{
              width: '20%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <FilterListIcon sx={{ color: actualThemeLight ? null : 'white' }} />
            <Typography
              sx={{
                width: '150px',
                fontSize: '14px',
                fontWeight: 'bold',
                ml: 1,
                color: actualThemeLight ? 'black' : 'white',
              }}
            >
              {t('OFFERINGS_TABLE.FILTER')}
              {filter.length !== 11 ? (
                <Tooltip title={t('FUND_SEARCH_VIEW.FILTER_APPLIED')}>
                  <InfoIcon
                    fontSize="medium"
                    sx={{
                      position: 'absolute',
                      mt: -0.1,
                      ml: 1,
                    }}
                    color="primary"
                  />
                </Tooltip>
              ) : null}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box
          sx={{
            pb: '70px',
            width: '100%',
          }}
        >
          <Grid
            container
            sx={{
              width: { xs: '100%', sm: '100%' },
              pl: { md: 6, xs: 3 },
              pr: { md: 6, xs: 3 },
              mt: 3,
            }}
          >
            {fundsDataLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  mt: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : sort !== 'default' ? (
              fundsData?.funds?.map((offer) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={offer.id}
                    sx={{
                      display: 'flex',
                      mb: 2,
                      justifyContent: { xs: 'center', md: 'center' },
                    }}
                  >
                    <FundCard data={offer} />
                  </Grid>
                )
              })
            ) : (
              funds?.map((offer) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={offer.id}
                    sx={{
                      display: 'flex',
                      mb: 2,
                      justifyContent: { xs: 'center', md: 'center' },
                    }}
                  >
                    <FundCard data={offer} />
                  </Grid>
                )
              })
            )}
          </Grid>
        </Box>
      </Box>
      {/* FILTER MODAL */}
      <Modal
        open={openFilter}
        onClose={handleCloseFilter}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            onClick={handleOpenFilter}
            sx={{
              width: '20%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <FilterListIcon sx={{ color: actualThemeLight ? null : 'white' }} />
            <Typography
              sx={{
                width: '150px',
                fontSize: '14px',
                fontWeight: 'bold',
                ml: 1,
                color: actualThemeLight ? 'black' : 'white',
              }}
            >
              {t('OFFERINGS_TABLE.FILTER')}
            </Typography>
          </Box>
          <Grid
            item
            md={12}
            xs={8}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              mt: -6,
              mb: 2,
            }}
          >
            <IconButton>
              <CloseIcon onClick={handleCloseFilter} />
            </IconButton>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { md: 'row', xs: 'column' },
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              container
              md={2.3}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                variant={filter.length > 4 ? 'contained' : 'outlined'}
                sx={{ mb: 1 }}
                onClick={() => {
                  setFilter([
                    'Collecting',
                    'Published',
                    'Open',
                    'ROI',
                    'Closed',
                    'Closed - Not Fully Funded',
                    'Assessment',
                    'Vesting',
                    'Governance',
                    'Execution',
                    'Finished',
                  ])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_ALL')}
              </Button>
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={
                  filter.length < 2 && filter[0] === 'Collecting'
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setFilter(['Collecting'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_COLLECTING')}
              </Button>
            </Grid>
            <Grid
              container
              md={2.3}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Published' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Published'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_PUBLISHED')}
              </Button>
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Open' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Open'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_OPEN')}
              </Button>
            </Grid>
            <Grid
              container
              md={2.3}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Closed' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Closed', 'Closed - Not Fully Funded'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_CLOSED')}
              </Button>
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Governance' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Governance'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_GOVERNANCE')}
              </Button>
            </Grid>
            <Grid
              container
              md={2.3}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Execution' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Execution'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_EXECUTION')}
              </Button>
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Finished' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Finished'])
                }}
              >
                {t('FUND_SEARCH_VIEW.STATUS_FINISHED')}
              </Button>
            </Grid>
            <Grid
              container
              md={2.3}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'ROI' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['ROI'])
                }}
              >
                ROI
              </Button>
              <Button
                sx={{
                  mb: 1,
                  color: actualThemeLight ? null : '#e6a3bd',
                  borderColor: actualThemeLight ? null : '#e6a3bd',
                }}
                variant={filter[0] === 'Vesting' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter(['Vesting'])
                }}
              >
                Vesting
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>
      {/* FILTER MODAL - END */}
    </Box>
  )
}
