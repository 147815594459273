import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { goBack } from 'connected-react-router'
import { GET_USER_INFO } from '../../../Queries/Users'
import {
    Box, Button, Checkbox, CircularProgress, FormControl,
    FormControlLabel, Grid, LinearProgress, MenuItem,
    Modal,
    Radio, RadioGroup, Select, TextField, Typography
} from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    CREATE_EVENT,
    GET_ALL_FUNDS,
    CREATE_EVENT_RELATE_FUND
} from '../../../Queries/Admin';
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../../store/actions/snackBarActions'

const CalendarCreateEvent = () => {

    const { user } = useAuth0()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Dates Declarations
    const [startDate, setStartDate] = React.useState('')
    const [endDate, setEndDate] = React.useState('')
    const [singleDay, setSingleDay] = React.useState(false)
    const todayDate = new Date().toISOString().split('T')[0]

    // Information to use in mutation
    const [titleEn, setTitleEn] = React.useState('')
    const [detailEn, setDetailEn] = React.useState('')

    const [titleEs, setTitleEs] = React.useState('')
    const [detailEs, setDetailEs] = React.useState('')

    const [titlePt, setTitlePt] = React.useState('')
    const [detailPt, setDetailPt] = React.useState('')

    // Mutations to be used
    const [createEvent, { loading: daoLoading }] = useMutation(CREATE_EVENT)
    const [createFundEvent, { loading: fundLoading }] = useMutation(CREATE_EVENT_RELATE_FUND)

    // Getting user info to see if it isCoreTeam
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    // Getting all Funds info to display options if Event is Fund related
    const [getAllFunds, { data: fundsData, loading: loadingFundsData }] = useLazyQuery(GET_ALL_FUNDS)


    // Executing Queries
    useEffect(() => {
        getUserData()
        getAllFunds()
    }, [getUserData, getAllFunds]);


    // Evaluating if User isCoreTeam to allow access
    const [isCoreTeam, setIsCoreTeam] = useState(false)
    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    // Random unique ID to use is mutations
    const uniqueID = uuidv4()

    // Defining Event type. Default DAO
    const [eventType, setEventType] = useState('dao');

    // Function to change Event Type According to Radio Buttons
    const handleChangeEventType = (event) => {
        setEventType(event.target.value);
    };

    // Selected Fund ID from selector if Event type is fund
    const [selectedFund, setSelectedFund] = useState('');

    // Fundtion to change selectedFund ID from Select component
    const handleChangeSelect = (event) => {
        setSelectedFund(event.target.value);
    };

    // Function to Publish Dao Event
    const handlePublishDaoEvent = async () => {
        await createEvent({
            variables: {
                data: {
                    id: uniqueID,
                    startDate: startDate,
                    endDate: endDate,
                    titleEn: titleEn,
                    detailEn: detailEn,
                    titleEs: titleEs,
                    detailEs: detailEs,
                    titlePt: titlePt,
                    detailPt: detailPt
                }
            }
        })
        await dispatch(push('/admin-calendar'))
        await dispatch(showMessage({ message: 'DAO Event created!', variant: 'success' }))
    }

    // Function to Publish Dao Event - Single Day Event
    const handlePublishDaoEventSingleDay = async () => {
        await createEvent({
            variables: {
                data: {
                    id: uniqueID,
                    startDate: startDate,
                    titleEn: titleEn,
                    detailEn: detailEn,
                    titleEs: titleEs,
                    detailEs: detailEs,
                    titlePt: titlePt,
                    detailPt: detailPt
                }
            }
        })
        await dispatch(push('/admin-calendar'))
        await dispatch(showMessage({ message: 'DAO Event created!', variant: 'success' }))
    }

    // Function to Publish Fund Event
    const handlePublishFundEvent = async () => {
        await createFundEvent({
            variables: {
                id: uniqueID,
                startDate: startDate,
                endDate: endDate,
                titleEn: titleEn,
                detailEn: detailEn,
                titleEs: titleEs,
                detailEs: detailEs,
                titlePt: titlePt,
                detailPt: detailPt,
                fundId: selectedFund,
            }
        })
        await dispatch(push('/admin-calendar'))
        await dispatch(showMessage({ message: 'Fund Event created!', variant: 'success' }))
    }

    // Function to Publish Fund Event - Single Day Event
    const handlePublishFundEventSingleDay = async () => {
        await createFundEvent({
            variables: {
                id: uniqueID,
                startDate: startDate,
                titleEn: titleEn,
                detailEn: detailEn,
                titleEs: titleEs,
                detailEs: detailEs,
                titlePt: titlePt,
                detailPt: detailPt,
                fundId: selectedFund,
            }
        })
        await dispatch(push('/admin-calendar'))
        await dispatch(showMessage({ message: 'Fund Event created!', variant: 'success' }))
    }


    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    if (isCoreTeam) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    mb: 4
                }}>
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            ml: 4,
                            mt: 2,
                            mb: 2,
                            alignItems: "center",
                            justifyContent: "left"
                        }}>
                        <Box
                            onClick={handleOpenConfirmation}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                p: 0.5,
                            }}>
                            <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                            <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                        </Box>
                        <Box sx={{ ml: 4 }}>
                            <Typography
                                fontSize={20}
                                fontWeight={'bold'}>
                                {t('ADMIN.NEW_EVENT')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    mt: 2,
                    mb: 4,
                    ml: 16
                }}>
                    <FormControl >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={eventType}
                            name="radio-buttons-group"
                            sx={{ display: 'flex', flexDirection: 'row' }}
                            onChange={handleChangeEventType}
                        >
                            <FormControlLabel value="dao" control={<Radio />} label={t('ADMIN.DAO_EVENT')} />
                            {!loadingFundsData && <FormControlLabel sx={{ ml: 4 }} value="fund" control={<Radio />} label={t('ADMIN.FUND_EVENT')} />}
                        </RadioGroup>
                    </FormControl>
                    {eventType === 'fund' &&
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedFund}
                            size='small'
                            sx={{ width: '300px' }}
                            onChange={handleChangeSelect}
                        >
                            {fundsData.funds.map((fund) => {
                                const name = fund.name
                                const id = fund.id
                                return (<MenuItem key={fund.id} value={id}>{name}</MenuItem>)
                            })}

                        </Select>
                    }
                </Box>


                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around'
                    }}>
                    <Box
                        width={'60%'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        {!singleDay ?
                            <Typography
                                fontSize={14}>
                                {t('ADMIN.NEW_EVENT_START_DATE')}
                            </Typography>
                            :
                            <Typography
                                fontSize={14}>
                                {t('ADMIN.NEW_EVENT_DATE')}
                            </Typography>
                        }
                        <TextField
                            sx={{ ml: 2, mr: 8 }}
                            type="date"
                            width='100%'
                            onKeyDown={(e) => {
                                e.preventDefault()
                            }}
                            variant="outlined"
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(e.target.value)
                            }}
                            inputProps={{
                                min: todayDate
                            }}
                        />
                        {!singleDay &&
                            <>
                                <Typography fontSize={14}>{t('ADMIN.NEW_EVENT_END_DATE')}</Typography>
                                <TextField
                                    sx={{ ml: 2 }}
                                    type="date"
                                    width='100%'
                                    onKeyDown={(e) => {
                                        e.preventDefault()
                                    }}
                                    variant="outlined"
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value)
                                    }}
                                    inputProps={{
                                        min: startDate
                                    }}
                                />
                            </>
                        }
                    </Box>
                </Grid>
                {eventType !== 'fund' && <Grid sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    mb: 2
                }}>
                    <FormControlLabel
                        onChange={() => { setSingleDay(!singleDay) }}
                        value="end"
                        control={<Checkbox />}
                        label={t('ADMIN.NEW_EVENT_ONE_DAY')}
                        labelPlacement="end"
                    />
                </Grid>}

                {/* ENGLISH INFO */}
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        mt: 2,
                        mb: 1
                    }}>
                    <Box width={'45%'}>
                        <Typography
                            fontSize={14}>
                            {t('ADMIN.NEW_PUBLICATION_TITLE')} - English
                        </Typography>
                        <TextField
                            value={titleEn}
                            onChange={(e) => setTitleEn(e.target.value)}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                    <Box width={'45%'}>
                        <Typography
                            fontSize={14}>
                            {t('ADMIN.NEW_EVENT_DETAIL')} - English
                        </Typography>
                        <TextField
                            value={detailEn}
                            onChange={(e) => setDetailEn(e.target.value)}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                {/* ENGLISH INFO - END */}

                {/* SPANISH INFO */}
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        mb: 1
                    }}>
                    <Box width={'45%'}>
                        <Typography
                            fontSize={14}>
                            {t('ADMIN.NEW_PUBLICATION_TITLE')} - Español
                        </Typography>
                        <TextField
                            value={titleEs}
                            onChange={(e) => setTitleEs(e.target.value)}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                    <Box width={'45%'}>
                        <Typography
                            fontSize={14}>
                            {t('ADMIN.NEW_EVENT_DETAIL')} - Español
                        </Typography>
                        <TextField
                            value={detailEs}
                            onChange={(e) => setDetailEs(e.target.value)}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                {/* SPANISH INFO - END */}

                {/* PORTUGUESE INFO */}
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}>
                    <Box width={'45%'}>
                        <Typography
                            fontSize={14}>
                            {t('ADMIN.NEW_PUBLICATION_TITLE')} - Portugues
                        </Typography>
                        <TextField
                            value={titlePt}
                            onChange={(e) => setTitlePt(e.target.value)}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                    <Box width={'45%'}>
                        <Typography
                            fontSize={14}>
                            {t('ADMIN.NEW_EVENT_DETAIL')} - Portugues
                        </Typography>
                        <TextField
                            value={detailPt}
                            onChange={(e) => setDetailPt(e.target.value)}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                {/* PORTUGUESE INFO - END */}


                {eventType === 'dao' ?
                    <Grid sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        mt: 4
                    }}>
                        <Button sx={{ mr: 2 }}
                            onClick={() => { dispatch(push('/admin-calendar')) }}
                            variant='outlined'>
                            {t('MESSAGES.CANCEL')}
                        </Button>
                        {
                            titleEn === '' ||
                                detailEn === '' ||
                                titleEs === '' ||
                                detailEs === '' ||
                                titlePt === '' ||
                                detailPt === '' ||
                                startDate === ''
                                ?
                                <Button
                                    sx={{ ml: 2 }}
                                    disabled
                                    variant='contained'>
                                    {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                                </Button>
                                : !singleDay ?
                                    <Button
                                        sx={{ ml: 2 }}
                                        onClick={handlePublishDaoEvent}
                                        variant='contained'>
                                        {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                                    </Button>
                                    :
                                    <Button
                                        sx={{ ml: 2 }}
                                        onClick={handlePublishDaoEventSingleDay}
                                        variant='contained'>
                                        {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                                    </Button>
                        }
                    </Grid >
                    :
                    <Grid sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        mt: 4
                    }}>
                        <Button
                            sx={{ mr: 2 }}
                            onClick={() => {
                                dispatch(push('/admin-calendar'))
                            }}
                            variant='outlined'>
                            {t('MESSAGES.CANCEL')}
                        </Button>
                        {
                            titleEn === '' ||
                                detailEn === '' ||
                                titleEs === '' ||
                                detailEs === '' ||
                                titlePt === '' ||
                                detailPt === '' ||
                                startDate === '' ||
                                selectedFund === ''
                                ?
                                <Button
                                    sx={{ ml: 2 }}
                                    disabled
                                    variant='contained'>
                                    {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                                </Button>
                                :
                                !singleDay ?
                                    <Button
                                        sx={{ ml: 2 }}
                                        onClick={handlePublishFundEvent}
                                        variant='contained'>
                                        {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                                    </Button>
                                    :
                                    <Button
                                        sx={{ ml: 2 }}
                                        onClick={handlePublishFundEventSingleDay}
                                        variant='contained'>
                                        {t('ADMIN.NEW_PUBLICATION_PUBLISH')}
                                    </Button>
                        }
                    </Grid >
                }
                {
                    daoLoading || fundLoading &&
                    <Box sx={{ width: '100%', mt: 4 }}>
                        <LinearProgress />
                    </Box>
                }
                <Modal
                    open={openConfirmation}
                    onClose={handleCloseConfirmation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styleConfirm}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('MY_OFFERINGS.ARE_YOU_SURE')}
                        </Typography>
                        <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                            {t('MY_OFFERINGS.CHANGES_LOST')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-evenly',
                                mt: 2,
                            }}
                        >
                            <Button variant="outlined" onClick={handleCloseConfirmation}>
                                {t('CO_ASSESSMENT.CANCEL')}
                            </Button>
                            <Button variant="contained" onClick={() => dispatch(goBack())}>
                                {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box >
        )
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default CalendarCreateEvent