import { Avatar, Box, Button, Link, Modal, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { t } from 'i18next'
import i18n from "i18next";
import { showMessage } from '../../../store/actions/snackBarActions'
import { useMutation } from '@apollo/client';
import { ENABLE_COMPANY, DISABLE_COMPANY } from '../../../Queries/Admin';



const CompaniesAdministrationRow = (data) => {
    const dispatch = useDispatch()
    const selectedLanguage = i18n.language

    const handleClick = () => {
        dispatch(push(`/admin-company-assessment/${data?.data?.id}`))
    }

    const [disableCompany] = useMutation(DISABLE_COMPANY)
    const [enableCompany] = useMutation(ENABLE_COMPANY)
    const handleDisable = async () => {
        await disableCompany({
            variables: {
                coId: data?.data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Company disabled!', variant: 'success' }))
    }

    const handleEnable = async () => {
        await enableCompany({
            variables: {
                coId: data?.data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Company enabled!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '15px',
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <>
            <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                <TableCell align="left">
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'
                    }} >
                        <Avatar src={data?.data?.logo} sx={{ mr: 2 }}></Avatar>
                        {data?.data?.coName}
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <Typography sx={{ maxHeight: '100px', overflow: 'hidden' }}>
                        {selectedLanguage === 'en' ? data?.data?.descriptionEn :
                            selectedLanguage === 'es' && data?.data?.descriptionEs !== '' ?
                                data?.data?.descriptionEs :
                                selectedLanguage === 'pt' && data?.data?.descriptionPt !== '' ?
                                    data?.data?.descriptionPt : data?.data?.descriptionEn}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    {
                        data?.data?.isValidated === false ?
                            <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
                                {t('ADMIN.NOT_VERIFIED')}
                            </Typography>
                            :
                            data?.data?.isValidated === true &&
                            <Typography sx={{ color: 'green', fontWeight: 'bold', fontSize: '14px' }}>
                                {t('ADMIN.VERIFIED')}
                            </Typography>
                    }
                </TableCell>
                <TableCell align="center">
                    <Link onClick={handleClick}>
                        {t('OFFERINGS_TABLE.ACT_SEE_MORE')}
                    </Link>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {data?.data?.isActive
                        ?
                        <Typography sx={{ color: 'green' }}>
                            {t('ADMIN.ENABLED')}
                        </Typography>
                        :
                        <Typography sx={{ color: 'red' }}>
                            {t('ADMIN.DISABLED')}
                        </Typography>
                    }
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {data?.data?.isActive
                        ?
                        <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                            {t('ADMIN.ACT_DISABLE')}
                        </Link>
                        :
                        <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                            {t('ADMIN.ACT_ENABLE')}
                        </Link>
                    }
                </TableCell>
            </TableRow >
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ borderRadius: '15px' }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        {data?.data?.isActive
                            ?
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={handleDisable}>{t('ADMIN.ACT_DISABLE')}</Button>
                            </>
                            :
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={handleEnable}>{t('ADMIN.ACT_ENABLE')}</Button>
                            </>
                        }
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CompaniesAdministrationRow