import { Typography } from '@mui/material';
import React from 'react';

const CustomTooltipMonths = ({ active, payload }) => {

    if (active && payload && payload.length) {
        const { date } = payload[0].payload;
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '2px solid #db417b' }}>
                <p>
                    <Typography sx={{ fontSize: '12px', color: 'black' }}>
                        {`Month ${date}`}
                    </Typography>
                </p>
                <p>
                    <Typography sx={{ fontSize: '12px', color: 'black' }}>
                        {`${payload[0].name}: ${payload[0].value}`}
                    </Typography>
                </p>
            </div>
        );
    }
    return null;
};

export default CustomTooltipMonths;
