//import { sliderClasses } from '@mui/material'
import axios from 'axios'
import JsonUrl from 'json-url'
//import { useDispatch } from 'react-redux'
//import { getWalletInfo } from '../store/actions/perfilActions'

/* TOKEN SEND EXAMPLE JSON
{
    "type": "tx",
    "title": "ADA and Token Sending Demo",
    "description": "A GameChanger Script Demo. https://gamechanger.finance/ . The output key allows you to define multi-asset transaction outputs. You can any number of different assets to an output, ADA and tokens. Replace policy ID for an existing one in your wallet. Replace addresses to avoid donating us, but if you can, donate so we can keep building for you",
    "outputs": {
        "addr1q8nekq6ktvhc9mrflhwq7ws5k24704ptwvkj7m74zl6ft2ntav86sqsfxyanfcq62yarewag2jtj6uewltfnqmpnxcjsht6dt7": [
            {
                "quantity": "2000000",
                "policyId": "ada",
                "assetName": "ada"
            },
            {
                "quantity": "5",
                "policyId": "fa8d2aa84d63e221d61b02458bb5a0d00ade302117970ac78dd230fd",
                "assetName": "GameChangerNFT"
            }
        ]
    }
}
*/
const lib = JsonUrl('lzw')
// eslint-disable-next-line
const codec = require('json-url')('lzma')

//const dappURL = `https://wallet.gamechanger.finance/api/1/tx/`
// UNCOMMENT WHEN APP IS FULLY IN PROD
//const blockFrostAPIMain = 'https://cardano-mainnet.blockfrost.io/api/v0/'

//Testnet was retired on Jan 2023
//const blockFrostAPITest = 'https://cardano-testnet.blockfrost.io/api/v0/'
//const blockFrostAPITest = 'https://cardano-preview.blockfrost.io/api/v0/'
const blockFrostAPITest = 'https://cardano-preprod.blockfrost.io/api/v0/'

// CHANGE BASED ON GC NET (MAIN NET ==> mainProjectID)
//const mainProjectID = 'mainnetxGwdM99cVqWieJ1rjXtwySCN89jtju7k'

// Testnet was retired on Jan 2023
//For Preview and Preprod dif Keys
//const testProjectID = 'previewGVqTO91Njn17n77gZ7CKl04ieWZFpzs1'
const testProjectID = 'preprod0YDs6YpK8cM5KFJA7bTZbOAmWVW0D94s'
//const testProjectID = 'testnetrzKfmGLaAlChuS7PVKeFH6P7cDmqlKJq'

const axiosBFClient = axios.create({
  baseURL: blockFrostAPITest,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    project_id: testProjectID,
  },
})

// Add id for transaction and  create the redirection links
// Create Transaction with pending status => using the id in the redirect link change, transaction status based on redirect result
/**
  Helper Method to generate a transaction object for the DApp.
  @param {string} transactionID - Transaction ID for status update.
  @param {string} currency - The currency to send.
  @param {string} output - an array of objects with the following format:
  {
    "quantity": "2000000",
    "policyId": "ada",
    "assetName": "ada"
  }
  @param {string} walletAddress - The address of the wallet to send the asset to.
  @returns Returns an object for the JSON compressor function
*/
function createTransactionJSON({
  transactionID,
  output,
  currency,
  walletAddress,
}) {
  return {
    onSuccessURL: `/txs/${transactionID}`,
    onPendingURL: `/txs/${transactionID}`,
    onRejectURL: `/txs/${transactionID}`,
    type: 'tx',
    title: `${currency} Sending`,
    description: `Sending ${currency} from TokenAllies`,
    outputs: {
      [walletAddress]: output,
    },
  }
}

function hexToString(hex) {
  var hex1 = hex.toString()
  var str = ''
  for (var i = 0; i < hex1.length; i += 2)
    str += String.fromCharCode(parseInt(hex1.substr(i, 2), 16))
  return str
}

const WalletGC = {
  /**
    Gamechangers address getter
    @returns Return a string with the Gamechangers address in the url as ?address=
  */
  getAssetDetails: async (asset) => {
    try {
      const finalRes = await axiosBFClient
        .get(`${blockFrostAPITest}assets/${asset}`)
        .then((res) => res.data)
      return finalRes
    } catch (err) {
      //Err
      return err
    }
  },
  getAddress: () => {
    window.location.href =
      'https://testnet-wallet.gamechanger.finance/api/1/address'
  },
  // TODO! Modify Logic in all the code that uses this function
  // FIRST: Create the transaction then use the sendADA method to update the transaction status
  /**
    Gamechangers transaction: ADA sender
    - Create a transaction JSON with the output and the currency
    @param {string} address - The wallet address to send the ADA
    @param {number} amount - The amount of ADA to send
    @param {string} transactionID - Transaction ID for status update.
  */
  sendADA: (address, amount, transactionID) => {
    const convertedAmount = amount * 1000000
    const codeObj = createTransactionJSON({
      output: [
        { quantity: convertedAmount, policyId: 'ada', assetName: 'ada' },
      ],
      currency: 'ADA',
      walletAddress: address,
      transactionID: transactionID,
    })

    console.log(codeObj, 'data for GC')

    lib
      .compress(codeObj)
      .then(async (gcscript) => {
        console.log(gcscript, 'sc')

        window.open(
          'https://testnet-wallet.gamechanger.finance/api/1/tx/' + gcscript,
          '_blank'
        )
      })
      .catch((err) => {
        console.log(`Error: ${err?.message}`)
      })
  },
  getAddressInfo: (address) => {
    let res = axiosBFClient
      .get(`${blockFrostAPITest}addresses/${address}/extended`)
      .then(async (res) => {
        let assets = res.data.amount
        assets.forEach(async (asset, index) => {
          //console.log(assetDetails, 'asset')
          if (asset.unit === 'lovelace') {
            asset.unit = 'ADA'
            asset.img =
              'https://cdn4.iconfinder.com/data/icons/crypto-currency-and-coin-2/256/cardano_ada-512.png'
            asset.quantity /= 1000000
          } else {
            // We slice at 56 because the first 56 characters compose the policyId
            // Change to asset_name
            const proccesedAsset = asset.unit.slice(56)

            //const assetDetails = WalletGC.getAssetDetails(asset.unit).then(
            WalletGC.getAssetDetails(asset.unit).then((assetDetail) => {
              let x = Object.keys(assetDetail.onchain_metadata)[0]
              //console.log(x, 'metadata name?')
              if (x != 'name') {
                let assetsArray = assetDetail.onchain_metadata?.[x]
                //console.log(assetsArray, 'assets arr?')
                let p = Object.keys(assetsArray)
                let unproccessedImgURL =
                  assetsArray[p[index]]?.files[0]?.src?.slice(7)
                //let unproccessedImgURL = assetsArray[p[index]].image.slice(7)
                let finalImgURL = `https://ipfs.io/ipfs/${unproccessedImgURL}`
                //console.log(finalImgURL, 'final img')
                asset.img = finalImgURL
                //console.log(asset, 'info from BF')

                return asset
              } else {
                console.log(assetDetail.onchain_metadata?.[x], 'X?')
              }
            })
            asset.unit = hexToString(proccesedAsset)

            //console.log(asset, 'ASSET PROC')
            //let finalImgURL = `https://ipfs.io/ipfs/${unproccessedImgURL}`
            //console.log(unproccessedImgURL, 'finalImgURL')
            //asset.img = finalImgURL
          }
        })
        //console.log(assets, 'assets')
        return assets
      })
      .catch((err) => {
        //Err
        return err
      })
    return res
  },
  getAddressTotalInfo: (address) => {
    return axiosBFClient
      .get(`${blockFrostAPITest}addresses/${address}/total`)
      .then((res) => {
        res.data.forEach((asset) => {
          if (asset.unit === 'lovelace') {
            asset.unit = 'ADA'
            asset.quantity /= 1000000
          } else {
            const proccesedAsset = asset.unit.slice(56)
            asset.unit = hexToString(proccesedAsset)
          }
        })
        return res.data
      })
      .catch((err) => {
        //Err
        return err
      })
  },
  getAddressTransactions: (address) => {
    return axiosBFClient
      .get(`${blockFrostAPITest}addresses/${address}/total`)
      .then((res) => {
        res.data.forEach((asset) => {
          if (asset.unit === 'lovelace') {
            asset.unit = 'ADA'
            asset.quantity /= 1000000
          } else {
            const proccesedAsset = asset.unit.slice(56)
            asset.unit = hexToString(proccesedAsset)
          }
        })
        return res.data
      })
      .catch((err) => {
        //Err
        return err
      })
  },
}

export default WalletGC
