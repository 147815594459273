import React from 'react'
import UserProfileView from '../../components/UserCard/UserProfileView'
const UserProfile = () => {
  return (
    <>
      <UserProfileView />
    </>
  )
}

export default UserProfile
