import { asyncActionCreator } from '../../utils/loadingUtils'

export const GET_PERFIL_INFO = asyncActionCreator('GET_PERFIL_INFO')
export const GET_WALLET_INFO = asyncActionCreator('GET_WALLET_INFO')
export const CLEAR_PERFIL_INFO = 'CLEAR_PERFIL_INFO'
export const SHOW_INVESTOR_OR_OFFERER_VIEWS = asyncActionCreator(
  'SHOW_INVESTOR_OR_OFFERER_VIEWS'
)

export const getPerfilInfo = (data) => async (dispatch) => {
  try {
    await dispatch({ type: GET_PERFIL_INFO.start })
    return dispatch({
      type: GET_PERFIL_INFO.success,
      payload: data?.users[0],
    })
  } catch (error) {
    //Err
  }
}

export const setViewPreference = (data) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_INVESTOR_OR_OFFERER_VIEWS.start })
    return dispatch({
      type: SHOW_INVESTOR_OR_OFFERER_VIEWS.success,
      payload: data,
    })
  } catch (error) {
    //Err
  }
}
