import React from 'react'
import { Box, Button, Checkbox, Grid, InputAdornment, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { CREATE_TOKEN, GET_ALL_FUNDS_NOT_CLOSED, CREATE_TOKEN_FUND } from '../../../Queries/Admin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../../store/actions/snackBarActions'
import { imgUploadHandler } from '../../../utils/fileUpload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useEffect } from 'react';
import { goBack } from 'connected-react-router'


const TokenCreate = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [createToken] = useMutation(CREATE_TOKEN)
    const [createTokenFund] = useMutation(CREATE_TOKEN_FUND)
    const [getFunds, { data: allFunds, loading: loadingFunds }] = useLazyQuery(GET_ALL_FUNDS_NOT_CLOSED)

    useEffect(() => {
        getFunds()
    }, [getFunds])

    const [tokenImgURLState, setTokenImgURLState] = useState('')
    const uploadTokenImgHandler = async (file) => {
        const tokenImgURL = await imgUploadHandler(file)
        setTokenImgURLState(tokenImgURL)
    }

    const [symbol, setSymbol] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [policyId, setPolicyId] = useState('')
    const [encodedName, setEncodedName] = useState('')
    const [fingerprint, setFingerprint] = useState('')
    const [maxCap, setMaxCap] = useState('')
    const [inputError, setInputError] = useState(false)
    const [checked, setChecked] = useState(false);
    const [selectedFund, setSelectedFund] = useState(null)

    const handleMaxCapChange = (e) => {
        const inputValue = parseInt(e.target.value, 10);
        if (!isNaN(inputValue) && inputValue <= Number.MAX_SAFE_INTEGER) {
            setMaxCap(inputValue)
            setInputError(false)
        } else {
            setInputError(true)
            dispatch(showMessage({ message: 'MaxCap Error!' }))
        }
    };

    const uniqueID = uuidv4()
    const handleSave = async () => {
        await createToken({
            variables: {
                tokenData: {
                    id: uniqueID,
                    symbol: symbol,
                    logo: tokenImgURLState,
                    description: description,
                    marketprice: Number(price),
                    policyId: policyId,
                    encodedName: encodedName,
                    fingerprint: fingerprint,
                    maxCap: Number(maxCap)
                }
            }
        })
        await dispatch(push('/admin-tokens'))
        await dispatch(showMessage({ message: 'Token/NFT created!', variant: 'success' }))
    }

    const handleSaveFund = async () => {
        await createTokenFund({
            variables: {
                id: uniqueID,
                symbol: symbol,
                logo: tokenImgURLState,
                description: description,
                marketprice: Number(price),
                policyId: policyId,
                encodedName: encodedName,
                fingerprint: fingerprint,
                maxCap: Number(maxCap),
                fundId: selectedFund
            }
        })
        await dispatch(push('/admin-tokens'))
        await dispatch(showMessage({ message: 'Token/NFT created!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const handleOpenConfirmation = () => setOpenConfirmation(true)
    const handleCloseConfirmation = () => setOpenConfirmation(false)

    const styleConfirm = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 6
            }}>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "left"
                    }}>
                    <Box
                        onClick={handleOpenConfirmation}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <ArrowBackIcon sx={{ color: '#9A1D4C' }} />
                        <Typography sx={{ ml: 1, color: '#9A1D4C' }}>{t('INVERSOR_VIEW.USER_CARD.RETURN')}</Typography>
                    </Box>
                    <Box sx={{ ml: 4 }}>
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}>
                            {t('ADMIN.NEW_FUND_PURPOSE').split(' ')[0]} Token/NFT
                        </Typography>
                    </Box>
                </Box>
            </Grid >
            <Grid container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "100%"
                    }}>
                    <Grid item xs={12} md={7}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: { xs: '100%', md: '100%' },
                                alignItems: 'center',
                                flexDirection: { xs: 'column', md: 'row' },
                            }}
                        >
                            {tokenImgURLState ? (
                                <img
                                    src={tokenImgURLState}
                                    style={{
                                        width: '150px',
                                        height: '50px',
                                        objectFit: 'contain',
                                    }}
                                />
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component="label"
                                    starticon={<UploadFileIcon />}
                                    sx={{
                                        borderRadius: '5px',
                                        border: '1px dashed #000000',
                                        width: { xs: '400px', md: '400px' },
                                        height: '50px',
                                    }}
                                >
                                    <UploadFileIcon sx={{ mr: 2 }} />
                                    <input
                                        hidden
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => uploadTokenImgHandler(e.target.files?.[0])}
                                    />
                                    Logo
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                {/* SYMBOL */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('CREATE_OFFERING.NAME_TOKEN')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={symbol}
                        onChange={(e) => setSymbol(e.target.value)}
                    />
                </Grid>

                {/* DESCRIPTION */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('CREATE_COMPANY.DESCRIPTION')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>

                {/* PRICE */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('GENERAL.PRICE')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                        }}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </Grid>

                {/* POLICY ID */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        Policy ID
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={policyId}
                        onChange={(e) => setPolicyId(e.target.value)}
                    />
                </Grid>

                {/* ENCODED NAME */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        {t('ADMIN.ENCODED_NAME')}
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={encodedName}
                        onChange={(e) => setEncodedName(e.target.value)}
                    />
                </Grid>

                {/* FINGERPRINT */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        Fingerprint
                    </Typography>
                    <TextField sx={{ width: '400px' }} size='small'
                        value={fingerprint}
                        onChange={(e) => setFingerprint(e.target.value)}
                    />
                </Grid>

                {/* MAXCAP */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Typography>
                        MaxCap
                    </Typography>
                    <TextField
                        sx={{ width: '400px' }}
                        size='small'
                        type='number'
                        error={inputError}
                        onWheel={(e) => e.target.blur()}
                        value={maxCap}
                        onChange={handleMaxCapChange}
                    />
                </Grid>


                {/* FUND */}
                <Grid item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "50%",
                        mt: 2
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, alignItems: 'center' }}>
                        <Checkbox
                            checked={checked}
                            onChange={() => { setChecked(!checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography>
                            {t('WALLET_VIEW.SYMBOL')}
                        </Typography>
                        {
                            !loadingFunds && checked ?
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedFund}
                                    size='small'
                                    sx={{ width: '300px', ml: 2 }}
                                    onChange={(event) => {
                                        const selectedIndex = event.target.value;
                                        setSelectedFund(selectedIndex);
                                    }}
                                >
                                    {allFunds?.funds?.map((fund) => {
                                        const name = fund.name;
                                        const id = fund.id;
                                        return (<MenuItem key={fund.id} value={id}>{name}</MenuItem>);
                                    })
                                    }
                                </Select>
                                :
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size='small'
                                    disabled
                                    sx={{ width: '300px', ml: 2 }}

                                >
                                </Select>
                        }
                    </Box>
                </Grid>




                {
                    symbol === '' ||
                        description === '' ||
                        price === '' ||
                        policyId === '' ||
                        encodedName === '' ||
                        fingerprint === '' ||
                        maxCap === '' ||
                        tokenImgURLState === ''
                        ?
                        <>
                            <Button sx={{ mt: 4 }} disabled variant='contained'>{t('CO_ASSESSMENT.SAVE')}</Button>
                        </>
                        :
                        <>
                            {checked === true ?
                                <Button sx={{ mt: 4 }} onClick={handleSaveFund} variant='contained'>
                                    {t('CO_ASSESSMENT.SAVE')}
                                </Button>
                                :
                                <Button sx={{ mt: 4 }} onClick={handleSave} variant='contained'>
                                    {t('CO_ASSESSMENT.SAVE')}
                                </Button>}
                        </>
                }
            </Grid>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleConfirm}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Typography id="modal-modal-title" sx={{ fontSize: '14px' }}>
                        {t('MY_OFFERINGS.CHANGES_LOST')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            mt: 2,
                        }}
                    >
                        <Button variant="outlined" onClick={handleCloseConfirmation}>
                            {t('CO_ASSESSMENT.CANCEL')}
                        </Button>
                        <Button variant="contained" onClick={() => dispatch(goBack())}>
                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}

export default TokenCreate