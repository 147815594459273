import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_INFO } from '../../Queries/Users'
import TokenEdit from '../../components/AdminComponents/TokensAdministration/TokenEdit'



const AdminViewTokensEdit = () => {

    const { user } = useAuth0()
    const { t } = useTranslation()
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    useEffect(() => {
        getUserData()
    }, [getUserData]);

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    if (isCoreTeam) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifySelf: 'center',
                    width: { xs: '100%', md: '90%' },
                    pt: '2%',
                    pl: { xs: '0', md: '10%' },
                }}
            >
                <TokenEdit />
            </Box>
        )
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }

}

export default AdminViewTokensEdit