import { Link, TableCell, TableRow } from '@mui/material'
import React from 'react'
import i18n from "i18next";
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client';
import { DELETE_VESTING_EVENT } from '../../../Queries/Admin';
import { useDispatch } from 'react-redux'
import { showMessage } from '../../../store/actions/snackBarActions'
import { push } from 'connected-react-router'

const IndividualVestingEvent = ({ data }) => {
    const selectedLanguage = i18n.language
    const { t } = useTranslation()

    const [deleteVestingEvent] = useMutation(DELETE_VESTING_EVENT)
    const dispatch = useDispatch()

    const handleDelete = async () => {
        await deleteVestingEvent({
            variables: {
                vestingEventId: data?.id
            }
        })
        await dispatch(showMessage({ message: 'Vesting Event deleted!', variant: 'success' }))
    }

    const handleOpenEdit = () => {
        dispatch(push(`/admin-vesting-edit/${data.id}`))
    };



    return (
        <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" component="th" scope="row">
                {data?.vestingDate.split('T')[0]}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.fund.name}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.amount} $
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {selectedLanguage === 'en' ? data?.proposal.titleEn :
                    selectedLanguage === 'es' && data?.proposal.titleEs !== null ?
                        data?.proposal.titleEs :
                        selectedLanguage === 'pt' && data?.proposal.titlePt !== null ?
                            data?.proposal.titlePt : data?.proposal.titleEn}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.proposal.company.coName}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.proposal.company.users[0].userName}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenEdit} >
                    {t('MY_OFFERINGS.MODIFY')}
                </Link>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleDelete} >
                    {t('MY_OFFERINGS.DELETE')}
                </Link>
            </TableCell>

        </TableRow>
    )
}

export default IndividualVestingEvent