import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_PREFERENCES } from '../../../Queries/Admin'
import { GET_USER_INFO } from '../../../Queries/Users'
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, InputAdornment, CircularProgress, useTheme } from '@mui/material';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import InterestsAdministrationRow from './InterestsAdministrationRow';
import InterestsAdministrationAddModal from './InterestsAdministrationAddModal';
import SearchIcon from '@mui/icons-material/Search';



const InterestsAdministration = () => {
    const [search, setSearch] = React.useState('');
    const { user } = useAuth0()
    const { t } = useTranslation()

    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };
    const [ordering, setOrdering] = useState({ preferenceName: "ASC" });
    const handleChangeOrdering = (event) => {
        setOrdering(event.target.value);
    };
    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })
    const [fetchInterests, { data: dataInterests, loading: dataLoading }] = useLazyQuery(GET_ALL_PREFERENCES, {
        variables: { order: ordering },
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    });

    const [filteredPreferences, setFilteredPreferences] = useState([]);

    useEffect(() => {
        if (dataInterests?.preferences) {
            const filtered = dataInterests.preferences.filter(preference =>
                preference.preferenceName.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredPreferences(filtered);
        }
    }, [search, dataInterests]);

    useEffect(() => {
        fetchInterests();
        getUserData()
    }, [fetchInterests, getUserData]);

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }

    if (isCoreTeam) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
                <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                            {t('ADMIN.INTERESTS_ADMINISTRATION')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 4 }}>
                        <InterestsAdministrationAddModal />
                        <TextField
                            id="filled-search"
                            label={t('ADMIN.ONLY_SEARCH')}
                            type="search"
                            variant="outlined"
                            onChange={handleTextChange}
                            sx={{ width: '55%', ml: 4 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box sx={{ width: '35%', display: 'flex', flexDirection: 'row', alignItems: 'center', ml: '5%', mr: '5%' }}>
                            <ImportExportOutlinedIcon />
                            <Typography sx={{ width: '150px', fontSize: '14px', fontWeight: 'bold', mr: 2 }}>{t('ADMIN.SORT_BY')}</Typography>
                            <FormControl fullWidth>
                                <Select renderValue={(selected) => {
                                    if (selected.preferenceName) {
                                        return selected.preferenceName === 'ASC'
                                            ? t('FUND_SEARCH_VIEW.NAME_SORT_A')
                                            : t('FUND_SEARCH_VIEW.NAME_SORT_Z')
                                    }
                                    return ''
                                }} value={ordering} onChange={handleChangeOrdering}>
                                    <MenuItem value={{ preferenceName: "ASC" }}>{t('ADMIN.NAME')} - A-Z</MenuItem>
                                    <MenuItem value={{ preferenceName: "DESC" }}>{t('ADMIN.NAME')} - Z-A</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    {dataLoading ?
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 8
                            }}>
                            <CircularProgress />
                        </Box>
                        :
                        <TableContainer sx={{ width: '70%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                            <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">{t('ADMIN.PREFERENCE')}</TableCell>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="right"></TableCell>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="right"></TableCell>
                                        <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPreferences?.map((row) => (
                                        <InterestsAdministrationRow key={row.id} data={row} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </Grid>
            </Box>)
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default InterestsAdministration