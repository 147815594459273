import { Box, Button, Link, Modal, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import i18n from "i18next";
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useMutation } from '@apollo/client';
import { DISABLE_FUND_PURPOSE, ENABLE_FUND_PURPOSE } from '../../../Queries/Admin';
import { showMessage } from '../../../store/actions/snackBarActions'

const IndividualPurpose = ({ data }) => {
    const selectedLanguage = i18n.language
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleOpenEdit = () => {
        dispatch(push(`/admin-fund-purpose-edit/${data.id}`))
    };

    const [disableFundPurpose] = useMutation(DISABLE_FUND_PURPOSE)
    const [enableFundPurpose] = useMutation(ENABLE_FUND_PURPOSE)


    const handleDisable = async () => {
        await disableFundPurpose({
            variables: {
                purposeId: data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Fund Purpose disabled!', variant: 'success' }))
    }

    const handleEnable = async () => {
        await enableFundPurpose({
            variables: {
                purposeId: data?.id
            }
        })
        await handleCloseConfirmation()
        await dispatch(showMessage({ message: 'Fund Purpose enabled!', variant: 'success' }))
    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    return (
        <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" component="th" scope="row">
                {selectedLanguage === 'en' ? data?.titleEn :
                    selectedLanguage === 'es' ? data?.titleEs :
                        selectedLanguage === 'pt' && data?.titlePt}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {selectedLanguage === 'en' ? data?.targetEn :
                    selectedLanguage === 'es' ? data?.targetEs :
                        selectedLanguage === 'pt' && data?.targetPt}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenEdit}  >
                    {t('MY_OFFERINGS.MODIFY')}
                </Link>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.isActive
                    ?
                    <Typography sx={{ color: 'green' }}>
                        {t('ADMIN.ENABLED')}
                    </Typography>
                    :
                    <Typography sx={{ color: 'red' }}>
                        {t('ADMIN.DISABLED')}
                    </Typography>
                }
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {data?.isActive
                    ?
                    <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                        {t('ADMIN.ACT_DISABLE')}
                    </Link>
                    :
                    <Link sx={{ cursor: 'pointer' }} onClick={handleOpenConfirmation} >
                        {t('ADMIN.ACT_ENABLE')}
                    </Link>
                }
            </TableCell>
            <Modal
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('MY_OFFERINGS.ARE_YOU_SURE')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', mt: 2 }}>
                        {data?.isActive
                            ?
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={handleDisable}>{t('ADMIN.ACT_DISABLE')}</Button>
                            </>
                            :
                            <>
                                <Button variant='outlined' onClick={handleCloseConfirmation}>{t('CO_ASSESSMENT.CANCEL')}</Button>
                                <Button variant='contained' onClick={handleEnable}>{t('ADMIN.ACT_ENABLE')}</Button>
                            </>
                        }
                    </Box>
                </Box>
            </Modal>
        </TableRow>
    )
}

export default IndividualPurpose