import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GET_USER_INFO } from '../../../Queries/Users'
import { Box, CircularProgress, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GET_ALL_EVENTS } from '../../../Queries/Admin';
import IndividualEvent from './IndividualEvent';

const Calendar = () => {

    const [search, setSearch] = React.useState('');
    const { user } = useAuth0()
    const { t } = useTranslation()
    const dispatch = useDispatch()


    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };

    const [getUserData, { data: userData, loading: userDataLoading }] = useLazyQuery(
        GET_USER_INFO, { variables: { email: user?.email || user?.sub } })

    const [getAllEvents, { data: eventsData, loading: dataLoading }] = useLazyQuery(GET_ALL_EVENTS, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getUserData()
        getAllEvents()
    }, [getUserData, getAllEvents]);

    const [filteredEvents, setFilteredEvents] = useState([]);

    useEffect(() => {
        if (eventsData?.events) {
            const filtered = eventsData.events.filter(event =>
                event.detailEn.toLowerCase().includes(search.toLowerCase()) ||
                event.detailEs.toLowerCase().includes(search.toLowerCase()) ||
                event.detailPt.toLowerCase().includes(search.toLowerCase()) ||
                event.titleEn.toLowerCase().includes(search.toLowerCase()) ||
                event.titleEs.toLowerCase().includes(search.toLowerCase()) ||
                event.titlePt.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredEvents(filtered);
        }
    }, [search, eventsData]);

    const [isCoreTeam, setIsCoreTeam] = useState(false)

    useEffect(() => {
        userData?.users[0]?.has.map((role) => {
            if (role.id === 'role-core-team') {
                setIsCoreTeam(true)
            }
        })
    })

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (userDataLoading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}>
                <CircularProgress />
            </Box>
        )
    }


    if (isCoreTeam) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
                <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                            {t('ADMIN.CALENDAR')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            m: 4,
                            alignItems: "center", justifyContent: "space-around"
                        }}>
                        <Box
                            onClick={() => {
                                dispatch(push('/admin-calendar-create'))
                            }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                p: 0.5,
                            }}>
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',

                                }}

                            />
                            <Typography sx={{
                                ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                            }}>{t('ADMIN.NEW_EVENT')}</Typography>
                        </Box>
                        <TextField
                            id="filled-search"
                            label={t('ADMIN.ONLY_SEARCH')}
                            type="search"
                            variant="outlined"
                            onChange={handleTextChange}
                            sx={{ width: '55%', ml: 4 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid >
                {dataLoading ?
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 8
                        }}>
                        <CircularProgress />
                    </Box>
                    :
                    <TableContainer sx={{ width: '90%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                        <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Title</TableCell>
                                    <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center">Detail</TableCell>
                                    <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", width: '25%' }} align="center">Date</TableCell>
                                    <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                    <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d" }} align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredEvents?.map((event) => (
                                    <IndividualEvent key={event.id} data={event} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
            </Box >
        )
    } else {
        return <div>{t('ADMIN.DENIED')}</div>;
    }
}

export default Calendar