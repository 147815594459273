import React from "react"
import { Fragment } from "react"
import { Box, Button, Typography, useTheme } from "@mui/material"
import { useTranslation } from 'react-i18next'
import i18n from "i18next";
import { useState } from "react"
import EventModal from "../../../../components/EventModal/EventModal"

const Events = ({ data }) => {
    const { t } = useTranslation()
    const selectedLanguage = i18n.language

    const [modalEvent, setModalEvent] = useState(false)
    const handleCloseEvent = () => {
        setModalEvent(false)
    }

    const startDay = data?.startDate?.split('-')[2].split('T')[0]
    const startMonth = data?.startDate?.split('-')[1]
    const endDay = data?.endDate?.split('-')[2].split('T')[0]
    const endMonth = data?.endDate?.split('-')[1]

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return <Fragment>
        <Box key={data?.id} sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* EVENT */}
            <Box
                key={data?.id}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    p: '8px',
                    mb: 1,
                    backgroundColor: actualThemeLight ? 'white' : '#1D1D1D',
                    border: '1px solid #828282',
                    borderRadius: '5px',
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', p: '2px', flexGrow: 1, overflow: 'hidden' }}>
                    {data.endDate === null ?
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay}
                        </Typography>
                        :
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: actualThemeLight ? 'black' : 'white' }}>
                            {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} - {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                        </Typography>
                    }
                    {data?.fund?.name !== undefined
                        ?
                        <Box sx={{
                            backgroundColor: actualThemeLight ? '#E1BBC9' : "#57313f",
                            p: 0.5,
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            overflow: 'hidden',
                            maxHeight: '30px'
                        }}>
                            <Typography sx={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: actualThemeLight ? 'black' : 'white',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                {selectedLanguage === 'en' ? data?.titleEn :
                                    selectedLanguage === 'es' ? data?.titleEs :
                                        selectedLanguage === 'pt' && data?.titlePt} - {data?.fund?.name}
                            </Typography>
                        </Box>

                        :
                        <Box sx={{
                            backgroundColor: actualThemeLight ? '#E1BBC9' : "#57313f",
                            p: 0.5,
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: actualThemeLight ? 'black' : 'white', }}>
                                {selectedLanguage === 'en' ? data?.titleEn :
                                    selectedLanguage === 'es' ? data?.titleEs :
                                        selectedLanguage === 'pt' && data?.titlePt}
                            </Typography>
                        </Box>
                    }
                    <Typography sx={{ fontSize: '10px', color: actualThemeLight ? 'black' : 'white', }}>
                        {data?.detail}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    sx={{ height: '45px', minWidth: '55px', maxWidth: '55px', width: '55px' }}
                    onClick={() => setModalEvent(true)}
                >
                    {t('INVERSOR_VIEW.VIEW')}+
                </Button>
            </Box>
        </Box>
        {
            modalEvent && (
                <EventModal
                    open={modalEvent}
                    handleClose={handleCloseEvent}
                    data={data}
                />
            )
        }
    </Fragment>
}

export default Events