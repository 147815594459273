import { Box, CircularProgress, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import { GET_ALL_FUND_PURPOSE } from '../../../Queries/Admin';
import { useLazyQuery } from '@apollo/client';
import IndividualPurpose from './IndividualPurpose';

const FundPurpose = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [search, setSearch] = React.useState('');

    const handleTextChange = (event) => {
        setSearch(event.target.value);
    };

    const [getAllPurposes, { data: purposesData, loading: dataLoading }] = useLazyQuery(GET_ALL_FUND_PURPOSE, {
        pollInterval: 2000,
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        getAllPurposes()
    }, [getAllPurposes]);

    const [filteredPurposes, setFilteredPurposes] = useState([]);

    useEffect(() => {
        if (purposesData?.fundPurposes) {
            const filtered = purposesData.fundPurposes.filter(purpose =>
                purpose.targetEn.toLowerCase().includes(search.toLowerCase()) ||
                purpose.targetEs.toLowerCase().includes(search.toLowerCase()) ||
                purpose.targetPt.toLowerCase().includes(search.toLowerCase()) ||
                purpose.titleEn.toLowerCase().includes(search.toLowerCase()) ||
                purpose.titleEs.toLowerCase().includes(search.toLowerCase()) ||
                purpose.titlePt.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredPurposes(filtered);
        }
    }, [search, purposesData]);

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mb: 6 }}>
            <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: 'text.secondary' }}>
                        {t('ADMIN.FUND_PURPOSE')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: 4,
                        alignItems: "center", justifyContent: "space-around"
                    }}>
                    <Box
                        onClick={() => {
                            dispatch(push('/admin-fund-purpose-create'))
                        }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            p: 0.5,
                        }}>
                        <AddCircleIcon
                            sx={{
                                width: '50px',
                                height: '50px',
                                color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',

                            }}

                        />
                        <Typography sx={{
                            ml: 1, color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}>{t('ADMIN.NEW_FUND_PURPOSE')}</Typography>
                    </Box>
                    <TextField
                        id="filled-search"
                        label={t('ADMIN.ONLY_SEARCH')}
                        type="search"
                        variant="outlined"
                        onChange={handleTextChange}
                        sx={{ width: '55%', ml: 4 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Grid >
            {dataLoading ?
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 8
                    }}>
                    <CircularProgress />
                </Box>
                :
                <TableContainer sx={{ width: '90%', height: '55vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center' }}>
                    <Table stickyHeader={true} sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">{t('ADMIN.NEW_PUBLICATION_TITLE')}</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center">Target</TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                                <TableCell sx={{ backgroundColor: actualThemeLight ? '#EBECF2' : "#1d1d1d", }} align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredPurposes?.map((purpose) => (
                                <IndividualPurpose key={purpose.id} data={purpose} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </Box >
    )
}

export default FundPurpose