import React from 'react'
import { TableCell, TableRow, Box, Modal, Typography, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoupeIcon from '@mui/icons-material/Loupe';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close'


const IndividualErrorLog = ({ data }) => {
    const { t } = useTranslation()
    const Day = data?.date?.split('-')[2].split('T')[0]
    const Month = data?.date?.split('-')[1]
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
    };

    function getMessageForErrorCode(errorCode) {
        switch (errorCode) {
            case "1001":
                return "(Core Team) - Function signTx in createMarloweContract - SCRow.jsx";
            case "1002":
                return "(Core Team) - Function createMarloweContract - SCRow.jsx";
            case "1003":
                return "(Core Team) - Function signTx in depositTstAlli - SCRow.jsx";
            case "1004":
                return "(Core Team) - Function depositTstAlli - SCRow.jsx";
            case "1005":
                return "(Validator) - Function signTx in makeChoicePay - SCRow.jsx";
            case "1006":
                return "(Validator) - Function makeChoicePay - SCRow.jsx";
            case "1007":
                return "(Core Team) - Function signTx in retryWithdraw - SCRow.jsx";
            case "1008":
                return "(Core Team) - Function retryWithdraw - SCRow.jsx";
            case "1009":
                return "(Core Team) - Function cancelInvestment - SCRow.jsx";
            case "1010":
                return "(Core Team) - Function signTx de createNewMarloweContract - SCRow.jsx";
            case "1011":
                return "(Core Team) - Function createNewMarloweContract - SCRow.jsx";
            case "1012":
                return "(Core Team) - Function updateOfferingMaxAmountReached - SCRow.jsx";
            case "1013":
                return "(Core Team) - Function updateOfferingEndDateReaced - SCRow.jsx";
            case "1014":
                return "(Core Team) - Function updateOfferingInvertorDepositNotConfirmed - SCRow.jsx";
            case "1015":
                return "(Core Team) - Function signTx in handleWithdraw - SCAdministration.jsx";
            case "1016":
                return "(Core Team) - Function handleWithdraw - SCAdministration.jsx";
            case "1017":
                return "(Validator) - Function signTx in makeChoicePay - FundManagement.jsx";
            case "1018":
                return "(Validator) - Function makeChoicePay - FundManagement.jsx";
            case "1019":
                return "(Validator) - Function signTx in makeChoiceClose - FundManagement.jsx";
            case "1020":
                return "(Validator) - Function makeChoiceClose - FundManagement.jsx";
            case "1021":
                return "(User) - Function signTx in handleDeposit - InvestmentsCard.jsx";
            case "1022":
                return "(User) - Function handleDeposit - InvestmentsCard.jsx";
            case "1023":
                return "(User) - Function signTx in handleWithdraw - InvestmentsCard.jsx";
            case "1024":
                return "(User) - Function handleWithdraw - InvestmentsCard.jsx";
            case "1025":
                return "(Core Team) - Function handleWithdrawInvNotDeposited - SCRow.jsx";
            case "1026":
                return "(Core Team) - Function handleWithdrawInvNotDeposited - SCRow.jsx";
            case "1027":
                return "(Core Team) - Function updateOfferWithdrawInvNotSuccess - SCRow.jsx";
            case "1028":
                return "(Core Team) - Function updateOfferWithdrawInvNotSuccessAfterCore - SCRow.jsx";
            case "1029":
                return "(Core Team) - Function updateOfferingPayoutNotExecuted - SCRow.jsx";
            default:
                return "Default error message";
        }
    }


    return (
        <>
            <TableRow
                key={data.id}
                sx={{ border: 0, padding: 0 }}
            >
                <TableCell sx={{ fontSize: '12px' }} align="center">
                    {`${t(`MONTHS.${Month}`).substring(0, 3)}. ${Day} - ${data?.date?.split('T')[1]?.split('.')[0]}`}
                </TableCell>
                <TableCell sx={{ fontSize: '12px' }} align="center">{data?.user?.id}</TableCell>
                <TableCell sx={{ fontSize: '12px' }} align="center">{data?.errorCode}</TableCell>
                <TableCell align="left" sx={{ fontSize: '12px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <LoupeIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={handleOpen} /> - {data?.errorString}
                    </Box>
                </TableCell>
            </TableRow>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '96%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'right',
                            position: 'absolute',
                            mt: -2
                        }}>
                        <IconButton>
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 3 }}>
                        Error: {data?.errorCode} - {getMessageForErrorCode(data?.errorCode)}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        {`${t(`MONTHS.${Month}`).substring(0, 3)}. ${Day} - ${data?.date?.split('T')[1]?.split('.')[0]} - (${data?.user?.id})`}
                    </Typography>
                    <Box sx={{ border: '1px solid gray', borderRadius: '10px', p: 1, mt: 1, width: '100%' }}>
                        <Typography id="modal-modal-description">
                            {data?.errorString}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default IndividualErrorLog