import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import FadeIn from 'react-fade-in'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles, useTheme } from '@mui/styles'
import { useAuth0 } from '@auth0/auth0-react'
import Preloader from '../../components/preloader/Preloader'
import { GET_USER_INFO } from '../../Queries/Users'
import { GET_DVC_POLICY } from '../../Queries/Tokens'
import { v4 as uuidv4 } from 'uuid'
import TelegramIcon from '@mui/icons-material/Telegram'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import DiscordIcon from './../../assets/icons/icons8-discord.svg'
import DiscordIconWhite from './../../assets/icons/icons8-discordWhite.svg'
import {
  GET_WALLET,
  CREATE_WALLET,
  LINK_WALLET,
  UPDATE_WALLET,
} from '../../Queries/Wallet'
import { showMessage } from '../../store/actions/snackBarActions'
import { Address } from '@emurgo/cardano-serialization-lib-asmjs'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  GET_ALL_PREFERENCES,
  ADD_ROLE_USER,
  REMOVE_ROLES_USER,
  ADD_PREFERENCES_USER,
  ADD_LANGUAGES_USER,
  REMOVE_USER_PREFERENCE,
  REMOVE_USER_LANGUAGE,
  ADD_SOCIAL_NETWORK_USER,
  UPDATE_SOCIAL_NETWORK_USER,
  ADD_USER_BIO,
  GET_ALL_LANGUAGES,
} from '../../Queries/Preferences'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { TransactionUnspentOutput } from '@emurgo/cardano-serialization-lib-asmjs'
import { styled } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    letterSpacing: 1.25,
    fontSize: theme.spacing(2),
    width: '341px',
  },

  logo: {
    margin: `${theme.spacing(10)}px 0`,
    zIndex: 1,
    width: '100%',
  },
  links: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'Quicksand',
  },
}))

const PerfilConfig = () => {
  const theme = useTheme()
  const actualThemeLight = theme.palette.mode === 'light'
  const MyChip = styled(Chip)({
    '& .MuiChip-deleteIcon': {
      color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
    },
  })
  const { t } = useTranslation()
  const { user, isLoading } = useAuth0()
  const [changeAddress, setChangeAddress] = useState(undefined)
  const [walletEnabled, setWalletEnabled] = useState(false)
  const [hasInvestorToken, setHasInvestorToken] = useState(false)
  const [API, setAPI] = useState(null)
  const [getWallet, { data: walletFromDb }] = useLazyQuery(GET_WALLET)

  const [walletSaved, setWalletSaved] = useState(false)
  const [createWallet] = useMutation(CREATE_WALLET)
  const [updateWallet] = useMutation(UPDATE_WALLET)
  const [linkWallet] = useMutation(LINK_WALLET)
  const [showWallets, setShowWallets] = useState(false)
  const [wallets, setWallets] = useState([])
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [getUserData, { data: userData, loading: userLoading }] = useLazyQuery(
    GET_USER_INFO,
    { pollInterval: 1000, variables: { email: user?.email || user?.sub } }
  )
  const [preferenceToDelete, setPreferenceToDelete] = useState('')
  const [langToDelete, setLangToDelete] = useState('')
  const [getDVCData, { data: DVCdata }] = useLazyQuery(GET_DVC_POLICY)
  const [changedBio, setChangedBio] = useState(false)

  useEffect(() => {
    if (userData?.users[0]?.bio !== undefined && changedBio === false) {
      setBioField(userData?.users[0]?.bio)
    }
  }, [userData])

  useEffect(() => {
    getDVCData()
  }, [])

  const investorTokenPolicy = DVCdata?.tokens[0]?.policyId

  const handleBuyClick = () => {
    const url = 'https://tokenallies.com/#DVC'
    window.open(url, '_blank')
  }
  const [removePreference] = useMutation(REMOVE_USER_PREFERENCE, {
    variables: {
      email: userData?.users[0]?.email,
      preferenceId: preferenceToDelete,
    },
  })

  const [removeLang] = useMutation(REMOVE_USER_LANGUAGE, {
    variables: {
      userId: userData?.users[0]?.id,
      languageId: langToDelete,
    },
  })

  const { data: preferenceList, loading: preferenceLoading } = useQuery(
    GET_ALL_PREFERENCES,
    { pollInterval: 5000 }
  )
  const { data: languageList, loading: languageLoading } =
    useQuery(GET_ALL_LANGUAGES)
  const dispatch = useDispatch()
  const [values, setValues] = useState([])
  const [languageValues, setLanguageValues] = useState([])
  const idArray = values.map((v) => v.id)
  const langIdArray = languageValues.map((v) => v.id)
  const classes = useStyles()
  const [profileRole, setProfileRole] = useState(null)
  const [investorModalOpen, setInvestorModalOpen] = useState(null)
  const [updateUserRole] = useMutation(ADD_ROLE_USER)
  const [removeRolesUser] = useMutation(REMOVE_ROLES_USER)
  const [addSocialNetwork] = useMutation(ADD_SOCIAL_NETWORK_USER)
  const [updateSocialNetwork] = useMutation(UPDATE_SOCIAL_NETWORK_USER)
  const [addUserBio] = useMutation(ADD_USER_BIO)
  const [updateUserPreferences] = useMutation(ADD_PREFERENCES_USER)
  const [updateUserLanguages] = useMutation(ADD_LANGUAGES_USER)
  const [page, setPage] = useState(1)
  const handleCloseInvestorModal = () => setProfileRole('role-applicant')
  const [Utxos, setUtxos] = useState(undefined)
  const [localStorageWallet, setLocalStorageWallet] = useState(
    getLocalStorageField('wallet')
  )

  const getUtxos = async () => {
    let utxos = []
    if (API !== null) {
      try {
        const rawUtxos = await API?.getUtxos()

        for (const rawUtxo of rawUtxos) {
          const utxo = TransactionUnspentOutput.from_bytes(
            Buffer.from(rawUtxo, 'hex')
          )
          const input = utxo.input()
          const txid = Buffer.from(
            input.transaction_id().to_bytes(),
            'utf8'
          ).toString('hex')
          const txindx = input.index()
          const output = utxo.output()
          const amount = output.amount().coin().to_str()
          const multiasset = output.amount().multiasset()
          let multiAssetStr = ''

          if (multiasset) {
            const keys = multiasset.keys()
            const N = keys.len()

            for (let i = 0; i < N; i++) {
              const policyId = keys.get(i)
              const policyIdHex = Buffer.from(
                policyId.to_bytes(),
                'utf8'
              ).toString('hex')

              const assets = multiasset.get(policyId)
              const assetNames = assets.keys()
              const K = assetNames.len()

              for (let j = 0; j < K; j++) {
                const assetName = assetNames.get(j)
                const assetNameString = Buffer.from(
                  assetName.name(),
                  'utf8'
                ).toString()
                const assetNameHex = Buffer.from(
                  assetName.name(),
                  'utf8'
                ).toString('hex')
                const multiassetAmt = multiasset.get_asset(policyId, assetName)
                multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
              }
            }
          }

          const obj = {
            txid: txid,
            txindx: txindx,
            amount: amount,
            str: `${txid} #${txindx} = ${amount}`,
            multiAssetStr: multiAssetStr,
            TransactionUnspentOutput: utxo,
          }
          utxos.push(obj)
        }
        setUtxos(utxos)
      } catch (err) {
        setSelectedWallet(undefined)
        //Err
      }
    }
  }

  function findAmountByInvestorTokenPolicy(data, investorTokenPolicy) {
    if (
      !Array.isArray(data) ||
      typeof investorTokenPolicy !== 'string' ||
      !investorTokenPolicy.trim()
    ) {
      return null
    }

    for (const item of data) {
      if (
        item.multiAssetStr &&
        item.multiAssetStr.includes(investorTokenPolicy)
      ) {
        const amountMatch = item.multiAssetStr.match(/\+ (\d+) \+.*\s\((.*?)\)/)
        if (amountMatch) {
          const amount = parseInt(amountMatch[1])
          return amount
        }
      }
    }
    return null
  }

  const [DVCamount, setDVCamount] = useState(undefined)

  useEffect(() => {
    const result = findAmountByInvestorTokenPolicy(Utxos, investorTokenPolicy)
    setDVCamount(result)
  }, [
    DVCamount,
    Utxos,
    localStorageWallet,
    changeAddress,
    Utxos,
    investorTokenPolicy,
  ])

  useEffect(() => {
    if (DVCamount >= 1) {
      setHasInvestorToken(true)
    } else if (DVCamount < 1 || DVCamount === null) {
      setHasInvestorToken(false)
    }
  }, [DVCamount, Utxos, localStorageWallet, changeAddress, Utxos])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
  }

  const handleRoleChange = (event) => {
    setProfileRole(event.target.value)
    if (event.target.value === 'role-investor') {
      setInvestorModalOpen(true)
      refreshData()
      enableWallet()
    }
  }

  const handleDelete = (pref) => async () => {
    await setPreferenceToDelete(pref.id)
    await removePreference()
  }

  const handleDeleteLang = (lang) => async () => {
    await setLangToDelete(lang.id)
    await removeLang()
  }

  const dbTelegram = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Telegram' && acc.push(cur.link), acc),
    []
  )
  const dbTelegramId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Telegram' && acc.push(cur.id), acc),
    []
  )
  const dbTwitter = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Twitter' && acc.push(cur.link), acc),
    []
  )
  const dbTwitterId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Twitter' && acc.push(cur.id), acc),
    []
  )
  const dbLinkedin = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Linkedin' && acc.push(cur.link), acc),
    []
  )
  const dbLinkedinId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Linkedin' && acc.push(cur.id), acc),
    []
  )
  const dbYoutube = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Youtube' && acc.push(cur.link), acc),
    []
  )
  const dbYoutubeId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Youtube' && acc.push(cur.id), acc),
    []
  )
  const dbDiscord = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Discord' && acc.push(cur.link), acc),
    []
  )
  const dbDiscordId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Discord' && acc.push(cur.id), acc),
    []
  )
  const dbInstagram = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Instagram' && acc.push(cur.link), acc),
    []
  )
  const dbInstagramId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Instagram' && acc.push(cur.id), acc),
    []
  )
  const dbCustom = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Custom' && acc.push(cur.link), acc),
    []
  )
  const dbCustomId = userData?.users[0]?.has_url?.reduce(
    (acc, cur) => (cur.name === 'Custom' && acc.push(cur.id), acc),
    []
  )

  // DEFINE FIRST PART OF LINKS FOR RE-DIRECTION
  const prevUrlTelegram = 'https://t.me/'
  const prevUrlTwitter = 'https://twitter.com/'
  const prevUrlLinkedin = 'https://linkedin.com/'
  const prevUrlYoutube = 'https://youtube.com/'
  const prevUrlDiscord = 'https://discord.com/'
  const prevUrlInstagram = 'https://instagram.com/'
  const prevUrlCustom = 'https://'

  // DEFINE STATES
  const [telegramField, setTelegramField] = useState(undefined)
  const telegramId = uuidv4()
  const [twitterField, setTwitterField] = useState(undefined)
  const twitterId = uuidv4()
  const [linkedinField, setLinkedinField] = useState(undefined)
  const linkedinId = uuidv4()
  const [youtubeField, setYoutubeField] = useState(undefined)
  const youtubeId = uuidv4()
  const [discordField, setDiscordField] = useState(undefined)
  const discordId = uuidv4()
  const [instagramField, setInstagramField] = useState(undefined)
  const instagramId = uuidv4()
  const [customField, setCustomField] = useState(undefined)
  const customId = uuidv4()
  const [bioField, setBioField] = useState(undefined)

  // CONCAT LINKS
  const handleTelegramChange = (event) => {
    const newTelegramUrl = prevUrlTelegram + event.target.value
    setTelegramField(newTelegramUrl)
  }
  const handleTwitterChange = (event) => {
    const newTwitterUrl = prevUrlTwitter + event.target.value
    setTwitterField(newTwitterUrl)
  }
  const handleLinkedinChange = (event) => {
    const newLinkedinUrl = prevUrlLinkedin + event.target.value
    setLinkedinField(newLinkedinUrl)
  }
  const handleYoutubeChange = (event) => {
    const newYoutubeUrl = prevUrlYoutube + event.target.value
    setYoutubeField(newYoutubeUrl)
  }
  const handleDiscordChange = (event) => {
    const newDiscordUrl = prevUrlDiscord + event.target.value
    setDiscordField(newDiscordUrl)
  }
  const handleInstagramChange = (event) => {
    const newInstagramUrl = prevUrlInstagram + event.target.value
    setInstagramField(newInstagramUrl)
  }
  const handleCustomChange = (event) => {
    const newCustomUrl = prevUrlCustom + event.target.value
    setCustomField(newCustomUrl)
  }
  const handleBioChange = (event) => {
    setChangedBio(true)
    setBioField(event.target.value)
  }

  const [roleOnDB, setRoleOnDB] = useState(undefined)

  useEffect(() => {
    userData?.users[0]?.has?.map((role) => {
      if (role.id === 'role-applicant') setRoleOnDB('role-applicant')
      if (role.id === 'role-investor') setRoleOnDB('role-investor')
    })
  }, [userData])

  const [savingProfile, setSavingProfile] = useState(false)

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    setSavingProfile(true)
    try {
      if (profileRole) {
        await removeRolesUser({
          variables: {
            userId: userData?.users[0]?.id,
          },
        })
        await updateUserRole({
          variables: {
            userId: userData?.users[0]?.id,
            roleId: profileRole,
          },
        })
        localStorage.setItem('role', profileRole)
      }
      if (idArray?.length > 0) {
        await updateUserPreferences({
          variables: {
            userId: userData?.users[0]?.id,
            preferenceId: idArray,
          },
        })
      }
      if (langIdArray?.length > 0) {
        await updateUserLanguages({
          variables: {
            userId: userData?.users[0]?.id,
            languageId: langIdArray,
          },
        })
      }
      if (dbTelegram[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbTelegramId[0],
            newLink: telegramField || dbTelegram[0],
          },
        })
      } else if (telegramField != undefined) {
        await addSocialNetwork({
          variables: {
            id: telegramId,
            link: telegramField,
            name: 'Telegram',
            userId: userData?.users[0]?.id,
            urlId: telegramId,
          },
        })
      }
      if (dbTwitter[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbTwitterId[0],
            newLink: twitterField || dbTwitter[0],
          },
        })
      } else if (twitterField != undefined) {
        await addSocialNetwork({
          variables: {
            id: twitterId,
            link: twitterField,
            name: 'Twitter',
            userId: userData?.users[0]?.id,
            urlId: twitterId,
          },
        })
      }
      if (dbLinkedin[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbLinkedinId[0],
            newLink: linkedinField || dbLinkedin[0],
          },
        })
      } else if (linkedinField != undefined) {
        await addSocialNetwork({
          variables: {
            id: linkedinId,
            link: linkedinField,
            name: 'Linkedin',
            userId: userData?.users[0]?.id,
            urlId: linkedinId,
          },
        })
      }
      if (dbYoutube[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbYoutubeId[0],
            newLink: youtubeField || dbYoutube[0],
          },
        })
      } else if (youtubeField != undefined) {
        await addSocialNetwork({
          variables: {
            id: youtubeId,
            link: youtubeField,
            name: 'Youtube',
            userId: userData?.users[0]?.id,
            urlId: youtubeId,
          },
        })
      }
      if (dbDiscord[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbDiscordId[0],
            newLink: discordField || dbDiscord[0],
          },
        })
      } else if (discordField != undefined) {
        await addSocialNetwork({
          variables: {
            id: discordId,
            link: discordField,
            name: 'Discord',
            userId: userData?.users[0]?.id,
            urlId: discordId,
          },
        })
      }
      if (dbInstagram[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbInstagramId[0],
            newLink: instagramField || dbInstagram[0],
          },
        })
      } else if (instagramField != undefined) {
        await addSocialNetwork({
          variables: {
            id: instagramId,
            link: instagramField,
            name: 'Instagram',
            userId: userData?.users[0]?.id,
            urlId: instagramId,
          },
        })
      }
      if (dbCustom[0] != undefined) {
        await updateSocialNetwork({
          variables: {
            urlID: dbCustomId[0],
            newLink: customField || dbCustom[0],
          },
        })
      } else if (customField != undefined) {
        await addSocialNetwork({
          variables: {
            id: customId,
            link: customField,
            name: 'Custom',
            userId: userData?.users[0]?.id,
            urlId: customId,
          },
        })
      }
      if (bioField != undefined) {
        await addUserBio({
          variables: {
            userId: userData?.users[0]?.id,
            bio: bioField,
          },
        })
      }
      if (profileRole === 'role-investor') {
        localStorage.setItem('showInvestorOrOffererView', 'Investor')
      } else {
        localStorage.setItem('showInvestorOrOffererView', 'Offerer')
      }
      await setSavingProfile(false)
      await getUserData()
      window.location.href = window.location.href + '?saved'
      setSavedShown(false)
    } catch (err) {
      //Err
    }
  })

  const urlParams = new URLSearchParams(window.location.search)
  const [savedShown, setSavedShown] = useState(false)
  if (urlParams.has('saved') && savedShown === false) {
    setSavedShown(true)
    dispatch(
      showMessage({ message: t('NAVBAR.PROFILE_SAVED'), variant: 'success' })
    )
  }

  useEffect(() => {
    const nonCoreTeamRole = userData?.users[0]?.has.find(
      (role) => role.roleName !== 'Core Team'
    )
    if (nonCoreTeamRole) {
      setProfileRole(nonCoreTeamRole.id)
    }
  }, [userData])

  useEffect(() => {
    getUserData()
  }, [user, preferenceToDelete, getUserData])

  const [countRetry, setCountRetry] = useState(0)

  const incrementRetry = () => {
    setCountRetry(countRetry + 1)
  }

  function createLocalStorageField(value) {
    localStorage.setItem('wallet', JSON.stringify(value))
  }

  const handleWalletSelect = async (obj) => {
    await createLocalStorageField(obj)
    await refreshData()
    await setShowWallets(!showWallets)
    await incrementRetry()
  }

  const handleUpdateWallet = async () => {
    await updateWallet({
      variables: {
        walletId: walletFromDb?.users[0]?.wallet?.id,
        newAddress: changeAddress,
      },
    })
    await dispatch(showMessage({ message: 'Updated!', variant: 'success' }))
  }

  const handleConnectButtonClick = (selectedWallet) => {
    handleWalletSelect(selectedWallet)
  }

  const pollWallets = (count = 0) => {
    const IntWallets = []
    for (const key in window.cardano) {
      if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
        IntWallets.push(key)
      }
    }
    if (IntWallets.length === 0 && count < 3) {
      setTimeout(() => {
        pollWallets(count + 1)
      }, 1000)
      return
    }
    setWallets(IntWallets)
  }

  useEffect(() => {
    pollWallets()
  }, [])

  const enableWallet = async () => {
    const walletKey = selectedWallet
    try {
      setAPI(await window.cardano[walletKey].enable())
    } catch (err) {
      setSelectedWallet(undefined)
      //Err
    }
    return checkIfWalletEnabled()
  }

  const checkIfWalletEnabled = async () => {
    let walletIsEnabled = false
    try {
      const walletName = localStorageWallet
      walletIsEnabled = await window.cardano[walletName].isEnabled()
      setWalletEnabled(walletIsEnabled)
    } catch (err) {
      //Err
      setSelectedWallet(undefined)
    }
    return walletIsEnabled
  }

  async function checkEnabledStatus(walletNames) {
    let anyEnabled = false
    // eslint-disable-next-line
    const enabledStatuses = await Promise.all(
      walletNames
        .filter((walletName) => walletName && typeof walletName === 'string')
        .map(async (walletName) => {
          try {
            const isEnabled = await window.cardano?.[walletName]?.isEnabled()
            if (isEnabled) {
              anyEnabled = true
            }
          } catch (error) {
            console.error(`Error checking status for ${walletName}:`, error)
            return { walletName, isEnabled: false }
          }
        })
    )
    setWalletEnabled(anyEnabled)
  }

  useEffect(() => {
    if (localStorageWallet !== null) {
      checkEnabledStatus(wallets)
      const intervalId = setInterval(() => {
        checkEnabledStatus(wallets)
      }, 5000)

      return () => clearInterval(intervalId)
    }
  }, [wallets, localStorageWallet])

  useEffect(() => {
    getWallet({
      variables: {
        userID: user?.email || user?.sub,
      },
      pollInterval: 2000,
    })
  }, [user?.email])

  // EFFECT FOR WALLET CONNECTION
  useEffect(() => {
    if (walletFromDb?.users[0]?.wallet != null) return

    if (
      changeAddress !== '' &&
      changeAddress !== undefined &&
      walletSaved === false
    ) {
      const walletID = uuidv4()
      setWalletSaved(true)
      createWallet({
        variables: {
          walletID: walletID,
          address: changeAddress,
          stakeAddress: changeAddress,
        },
      })
        // eslint-disable-next-line
        .then((res) =>
          linkWallet({
            variables: {
              userID: user?.email || user?.sub,
              walletID: walletID,
            },
          })
        )
        // eslint-disable-next-line
        .catch((err) => {
          //Err
        })
    }
  }, [changeAddress])

  const getChangeAddress = async () => {
    if (API !== null) {
      try {
        const raw = await API.getChangeAddress()
        const changeAddressObj = Address.from_bytes(
          Buffer.from(raw, 'hex')
        ).to_bech32()
        setChangeAddress(changeAddressObj)
      } catch (err) {
        setSelectedWallet(undefined)
        //Err
      }
    }
  }

  const [netId, setNetId] = useState(undefined)
  const appropiateNetworkIdIdentified = 0
  const [isCorrectNetworkSelected, setIsCorrectNetworkSelected] = useState(null)

  useEffect(() => {
    if (netId !== undefined) {
      if (appropiateNetworkIdIdentified === netId) {
        setIsCorrectNetworkSelected(true)
      } else {
        setIsCorrectNetworkSelected(false)
      }
    }
  }, [appropiateNetworkIdIdentified, netId])

  const getNetId = async () => {
    if (API !== null) {
      try {
        const id = await API.getNetworkId()
        setNetId(id)
      } catch (err) {
        setNetId(undefined)
      }
    }
  }

  function deleteLocalStorageField() {
    localStorage.removeItem('wallet')
  }

  function getLocalStorageField(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  useEffect(() => {
    if (selectedWallet !== undefined) {
      enableWallet()
    }
  }, [selectedWallet, localStorageWallet])

  useEffect(() => {
    if (changeAddress !== undefined) {
      setCountRetry(0)
    }
  }, [changeAddress])

  const refreshData = async () => {
    try {
      if (walletEnabled && API !== null) {
        await getNetId()
        await getChangeAddress()
        await getUtxos()
      }
    } catch (err) {
      setSelectedWallet(undefined)
      deleteLocalStorageField()
      //Err
    }
  }

  useEffect(() => {
    if (localStorageWallet !== null) {
      setSelectedWallet(localStorageWallet)
      refreshData()
    } else if (localStorageWallet === null) {
      setSelectedWallet(null)
    }
  }, [localStorageWallet, walletEnabled])

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalStorageWallet(getLocalStorageField('wallet'))
      refreshData()
    }, 1000)

    return () => clearInterval(interval)
  }, [localStorageWallet, walletEnabled, API])

  if (isLoading) return <Preloader />

  return (
    <Grid
      container
      sx={{
        width: { xs: '90%', md: '90%' },
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: { xs: 'row', md: 'row' },
        justifyContent: 'space-around',
        height: { xs: '120%', md: '85%' },
        margin: 'auto',
        marginTop: '10px',
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 2,
            alignItems: 'center',
            padding: '0px 16px',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
          }}
        >
          <Typography
            sx={{
              color: actualThemeLight ? '#4F4F4F' : 'white',
              fontSize: '25px',
              fontWeight: 'bold',
              textAlign: 'left',
              //marginBottom: '5%',
            }}
            variant="h5"
          >
            {t('PERFIL_CONFIG_VIEW.ABOUT_ME')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 3,
            alignItems: 'center',
            padding: '0px 16px',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
          }}
        >
          <Avatar
            sx={{ ml: 2, width: '50px', height: '50px' }}
            alt={userData?.users[0]?.userName}
            src={userData?.users[0]?.profilePicture}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Container className={classes.login}>
          <FadeIn transitionDuration={200}>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <FormControl
                  fullWidth
                  sx={{ position: 'relative', marginBottom: 1 }}
                >
                  <InputLabel
                    shrink
                    fullWidth
                    htmlFor="userName-input"
                    sx={{
                      color: actualThemeLight ? '#4F4F4F' : 'white',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      fontFamily: 'Quicksand',
                      letterSpacing: 0.15,
                      textAlign: 'left',
                    }}
                  >
                    {t('PERFIL_CONFIG_VIEW.USERNAME')}
                  </InputLabel>
                  <TextField
                    disabled
                    size="small"
                    id="userName-input"
                    margin="normal"
                    name="username"
                    type="text"
                    value={userData?.users[0]?.userName}
                    variant="outlined"
                    aria-describedby="userName-input-text"
                    sx={{ width: { xs: '100%', md: '95%' } }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ position: 'relative' }}>
                  <InputLabel
                    shrink
                    htmlFor="userName-input"
                    sx={{
                      color: actualThemeLight ? '#4F4F4F' : 'white',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      fontFamily: 'Quicksand',
                      letterSpacing: 0.15,
                      textAlign: 'left',
                    }}
                  >
                    {t('PERFIL_CONFIG_VIEW.EMAIL')}
                  </InputLabel>
                  <TextField
                    disabled
                    size="small"
                    id="userName-input"
                    sx={{ width: '100%' }}
                    margin="normal"
                    name="email"
                    type="text"
                    value={userData?.users[0]?.email}
                    variant="outlined"
                    aria-describedby="email-input-text"
                  />
                </FormControl>
              </Box>
              <FormControl
                fullWidth
                sx={{ position: 'relative', mb: 2, mt: 2 }}
              >
                <InputLabel
                  shrink
                  htmlFor="bio-input"
                  sx={{
                    color: actualThemeLight ? '#4F4F4F' : 'white',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'Quicksand',
                    letterSpacing: 0.15,
                    textAlign: 'left',
                  }}
                >
                  {t('PERFIL_CONFIG_VIEW.SOCIAL_NETWORKS')}
                </InputLabel>
                <Box
                  className="socialContainer"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    mt: '16px',
                  }}
                >
                  <Tooltip
                    title={dbTelegram?.length > 0 ? dbTelegram[0] : 'Telegram'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '320px',
                      }}
                    >
                      <TelegramIcon
                        sx={{
                          width: '50px',
                          alignSelf: 'center',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                      <TextField
                        id="telegram-input"
                        size="small"
                        sx={{ width: { xs: '100%' } }}
                        margin="normal"
                        name="telegram"
                        onChange={handleTelegramChange}
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{
                                fontSize: '16px',
                                marginRight: '0px',
                              }}
                              position="start"
                            >
                              {prevUrlTelegram.split('//')[1]}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbTelegram?.length > 0
                            ? dbTelegram[0].split('/')[3]
                            : 'Telegram'
                        }
                        variant="outlined"
                        aria-describedby="telegram-input-text"
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={dbTwitter?.length > 0 ? dbTwitter[0] : 'Twitter'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '320px',
                      }}
                    >
                      <TwitterIcon
                        sx={{
                          width: '50px',
                          alignSelf: 'center',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                      <TextField
                        id="twitter-input"
                        size="small"
                        //sx={{ width: '50%', height: '20px' }}
                        sx={{ width: { xs: '100%' } }}
                        margin="normal"
                        name="twitter"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{ fontSize: '16px', marginRight: '0px' }}
                              position="start"
                            >
                              {prevUrlTwitter.split('//')[1]}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbTwitter?.length > 0
                            ? dbTwitter[0].split('/')[3]
                            : 'Twitter'
                        }
                        onChange={handleTwitterChange}
                        type="text"
                        variant="outlined"
                        aria-describedby="twitter-input-text"
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  className="socialContainer"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                >
                  <Tooltip
                    title={dbLinkedin?.length > 0 ? dbLinkedin[0] : 'LinkedIn'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '320px',
                      }}
                    >
                      <LinkedInIcon
                        sx={{
                          width: '50px',
                          alignSelf: 'center',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                      <TextField
                        id="linkedin-input"
                        size="small"
                        sx={{ width: { xs: '100%' } }}
                        margin="normal"
                        name="linkedin"
                        onChange={handleLinkedinChange}
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{ fontSize: '16px', marginRight: '0px' }}
                              position="start"
                            >
                              {prevUrlLinkedin.split('//')[1]}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbLinkedin?.length > 0
                            ? dbLinkedin[0].split('/')[3]
                            : 'LinkedIn'
                        }
                        variant="outlined"
                        aria-describedby="linkedin-input-text"
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={dbYoutube?.length > 0 ? dbYoutube[0] : 'Youtube'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '320px',
                      }}
                    >
                      <YouTubeIcon
                        sx={{
                          width: '50px',
                          alignSelf: 'center',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                      <TextField
                        id="youtube-input"
                        size="small"
                        sx={{ width: { xs: '100%' } }}
                        margin="normal"
                        name="youtube"
                        onChange={handleYoutubeChange}
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{ fontSize: '16px', marginRight: '0px' }}
                              position="start"
                            >
                              {prevUrlYoutube.split('//')[1]}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbYoutube?.length > 0
                            ? dbYoutube[0].split('/')[3]
                            : 'Youtube'
                        }
                        variant="outlined"
                        aria-describedby="youtube-input-text"
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  className="socialContainer"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                >
                  <Tooltip
                    title={dbDiscord?.length > 0 ? dbDiscord[0] : 'Discord'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '320px',
                      }}
                    >
                      <Box
                        sx={{
                          width: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={
                            actualThemeLight ? DiscordIcon : DiscordIconWhite
                          }
                          alt="icon"
                          width="25px"
                        />
                      </Box>
                      <TextField
                        size="small"
                        id="discord-input"
                        sx={{ width: { xs: '100%' }, ml: 1 }}
                        margin="normal"
                        name="discord"
                        onChange={handleDiscordChange}
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{ fontSize: '16px', marginRight: '0px' }}
                              position="start"
                            >
                              {prevUrlDiscord.split('//')[1]}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbDiscord?.length > 0
                            ? dbDiscord[0].split('/')[3]
                            : 'Discord'
                        }
                        variant="outlined"
                        aria-describedby="discord-input-text"
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      dbInstagram?.length > 0 ? dbInstagram[0] : 'Instagram'
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '320px',
                      }}
                    >
                      <InstagramIcon
                        sx={{
                          width: '50px',
                          alignSelf: 'center',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                      <TextField
                        id="instgram-input"
                        size="small"
                        sx={{ width: { xs: '100%' } }}
                        margin="normal"
                        name="instgram"
                        onChange={handleInstagramChange}
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{ fontSize: '16px', marginRight: '0px' }}
                              position="start"
                            >
                              {prevUrlInstagram.split('//')[1]}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbInstagram?.length > 0
                            ? dbInstagram[0].split('/')[3]
                            : 'Instagram'
                        }
                        variant="outlined"
                        aria-describedby="instgram-input-text"
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  className="socialContainer"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row', width: '100%' },
                    alignItems: 'start',
                  }}
                >
                  <Tooltip
                    title={dbCustom?.length > 0 ? dbCustom[0] : 'Custom'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '255px',
                      }}
                    >
                      <InsertLinkIcon
                        sx={{
                          width: '50px',
                          alignSelf: 'center',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                      <TextField
                        id="custom-input"
                        size="small"
                        sx={{ width: { xs: '100%' } }}
                        margin="normal"
                        name="custom"
                        onChange={handleCustomChange}
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              sx={{ fontSize: '16px', marginRight: '0px' }}
                              position="start"
                            >
                              {prevUrlCustom}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={
                          dbCustom?.length > 0
                            ? dbCustom[0].split('//')[1]
                            : 'Custom'
                        }
                        variant="outlined"
                        aria-describedby="custom-input-text"
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </FormControl>
              <FormControl fullWidth sx={{ position: 'relative' }}>
                <InputLabel
                  shrink
                  htmlFor="bio-input"
                  sx={{
                    color: actualThemeLight ? '#4F4F4F' : 'white',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'Quicksand',
                    letterSpacing: 0.15,
                    textAlign: 'left',
                  }}
                >
                  {'Bio'}
                </InputLabel>
                <TextField
                  id="bio-input"
                  fullWidth
                  multiline
                  rows={2}
                  margin="normal"
                  onChange={handleBioChange}
                  name="username"
                  type="text"
                  value={bioField || t('PERFIL_CONFIG_VIEW.BIO_DESCRIPTION')}
                  variant="outlined"
                  aria-describedby="userName-input-text"
                />
                <FormHelperText
                  id="userName-input-text"
                  sx={{
                    position: 'absolute',
                    bottom: '-15px',
                    color: 'red',
                  }}
                ></FormHelperText>
              </FormControl>
            </form>
          </FadeIn>
        </Container>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          height: '70%',
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ height: '100%', border: '1px solid #450D22 %500' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Container>
          <FadeIn transitionDuration={200}>
            <form onSubmit={handleSubmit}>
              <Box>
                <Box
                  sx={{
                    width: '100%',
                    mt: { xs: 10, md: 2 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}
                >
                  <FormControl fullWidth>
                    <Typography
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                        mt: -8,
                      }}
                    >
                      {t('PERFIL_CONFIG_VIEW.PERFIL')}
                    </Typography>
                    {roleOnDB !== undefined && (
                      <RadioGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: { xs: '100%' },
                          justifyContent: 'left',
                        }}
                        defaultValue={roleOnDB}
                        value={profileRole}
                        name={'perfil'}
                        id="perfile-select"
                        onChange={handleRoleChange}
                      >
                        <FormControlLabel
                          sx={{ ml: '-1px' }}
                          value="role-applicant"
                          id="role-applicant"
                          control={<Radio />}
                          label={t('PERFIL_CONFIG_VIEW.ROLE_OFFERER')}
                        />
                        <FormControlLabel
                          sx={{ ml: 5 }}
                          value="role-investor"
                          id="role-investor"
                          control={<Radio />}
                          label={t('PERFIL_CONFIG_VIEW.ROLE_INVESTOR')}
                        />
                      </RadioGroup>
                    )}
                    {roleOnDB === undefined && (
                      <RadioGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: { xs: '100%' },
                          marginTop: '15px',
                          justifyContent: 'left',
                        }}
                        name={'perfil'}
                        id="perfile-select"
                        onChange={handleRoleChange}
                      >
                        <FormControlLabel
                          sx={{ ml: 5 }}
                          value="role-applicant"
                          id="role-applicant"
                          control={<Radio />}
                          label={t('PERFIL_CONFIG_VIEW.ROLE_OFFERER')}
                        />
                        <FormControlLabel
                          sx={{ ml: 5 }}
                          value="role-investor"
                          id="role-investor"
                          control={<Radio />}
                          label={t('PERFIL_CONFIG_VIEW.ROLE_INVESTOR')}
                        />
                      </RadioGroup>
                    )}
                  </FormControl>
                </Box>
                <Modal
                  open={
                    profileRole === 'role-investor' &&
                    investorModalOpen === true &&
                    (walletEnabled === false ||
                      hasInvestorToken === false ||
                      localStorageWallet === null)
                  }
                  onClose={handleCloseInvestorModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Grid
                      container
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        height: '25px',
                        marginTop: '-23px',
                      }}
                    >
                      <IconButton onClick={handleCloseInvestorModal}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        sx={{ mt: 2, fontSize: '16px', fontWeight: 'bold' }}
                      >
                        {t('PERFIL_CONFIG_VIEW.REQUIREMENTS')}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
                      {page === 1 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            m: 0.5,
                            p: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              borderRadius: '50%',
                            }}
                          >
                            <Typography
                              sx={{ mr: 2 }}
                              id="modal-modal-description"
                            >
                              {t('PERFIL_CONFIG_VIEW.REQ_WALLET')}
                            </Typography>
                          </Box>
                          {walletEnabled && localStorageWallet !== null ? (
                            <CheckCircleIcon
                              fontSize="large"
                              sx={{ mt: 1 }}
                              color="success"
                            />
                          ) : (
                            <HighlightOffIcon
                              fontSize="large"
                              sx={{ mt: 1 }}
                              color="error"
                            />
                          )}
                          {localStorageWallet === null && (
                            <>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  mt: 1,
                                }}
                              >
                                <Tooltip title={t('WALLET.SUPPORTED_WALLET')}>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      setShowWallets(!showWallets)
                                    }}
                                  >
                                    {t(
                                      'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                                    )}
                                  </Button>
                                </Tooltip>
                                {countRetry >= 2 && (
                                  <Box>
                                    <Tooltip
                                      title={t(
                                        'INVERSOR_VIEW.OFFERING_CARD.WALLET_ERROR_DAPP'
                                      )}
                                    >
                                      <IconButton>
                                        <PriorityHighIcon
                                          sx={{ color: 'orange' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>

                              {showWallets && (
                                <>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    {wallets?.map((key) => (
                                      <Box
                                        key={key}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          m: 2,
                                        }}
                                      >
                                        <Button
                                          sx={{ mt: 1 }}
                                          variant="outlined"
                                          size="small"
                                          onClick={() =>
                                            handleConnectButtonClick(key)
                                          }
                                        >
                                          <Typography sx={{ ml: 1 }}>
                                            {
                                              t(
                                                'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                                              ).split(' ')[0]
                                            }
                                          </Typography>
                                          <Avatar
                                            sx={{ ml: 1 }}
                                            src={window.cardano[key].icon}
                                            width={24}
                                            height={24}
                                            alt={key}
                                          />
                                          <Typography sx={{ ml: 1 }}>
                                            {key}
                                          </Typography>
                                        </Button>
                                      </Box>
                                    ))}
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                      {page === 2 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            m: 0.5,
                            p: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              borderRadius: '50%',
                            }}
                          >
                            <Typography
                              id="modal-modal-description"
                              sx={{ mr: 2 }}
                            >
                              {t('PERFIL_CONFIG_VIEW.REQ_TOKEN')}
                            </Typography>
                          </Box>
                          {hasInvestorToken && localStorageWallet !== null && (
                            <CheckCircleIcon
                              fontSize="large"
                              sx={{ mt: 1 }}
                              color="success"
                            />
                          )}
                          {!hasInvestorToken || localStorageWallet === null ? (
                            <Button
                              onClick={handleBuyClick}
                              sx={{ mt: 1 }}
                              variant="contained"
                            >
                              {t('PERFIL_CONFIG_VIEW.BUY_HERE')}
                            </Button>
                          ) : null}
                        </Box>
                      )}
                    </Box>
                    {page === 1 && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: 3,
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                            color: 'white',
                            width: 24,
                            height: 24,
                            mr: 1,
                            ml: 1,
                            fontSize: '14px',
                          }}
                        >
                          1
                        </Avatar>
                        <Avatar
                          sx={{
                            bgcolor: 'gray',
                            color: 'white',
                            width: 24,
                            height: 24,
                            mr: 1,
                            fontSize: '14px',
                          }}
                        >
                          2
                        </Avatar>
                        <NavigateNextIcon
                          fontSize="large"
                          onClick={() => {
                            setPage(2)
                          }}
                          sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            mr: -30,
                            mt: 0.2,
                          }}
                        />
                      </Box>
                    )}
                    {page === 2 && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: 3,
                        }}
                      >
                        <NavigateBeforeIcon
                          fontSize="large"
                          onClick={() => {
                            setPage(1)
                          }}
                          sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            mr: 30,
                            mt: 0.2,
                          }}
                        />
                        <Avatar
                          sx={{
                            bgcolor: 'gray',
                            color: 'white',
                            width: 24,
                            height: 24,
                            mr: 1,
                            ml: 1,
                            fontSize: '14px',
                          }}
                        >
                          1
                        </Avatar>
                        <Avatar
                          sx={{
                            bgcolor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                            color: 'white',
                            width: 24,
                            height: 24,
                            mr: 1,
                            fontSize: '14px',
                          }}
                        >
                          2
                        </Avatar>
                      </Box>
                    )}
                  </Box>
                </Modal>
                {localStorageWallet === null && (
                  <Box
                    sx={{
                      width: '100%',
                      mt: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                    }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="perfile-select"
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('DASHBOARD_VIEW.MY_WALLET')}
                    </InputLabel>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          mt: 1,
                        }}
                      >
                        <Tooltip title={t('WALLET.SUPPORTED_WALLET')}>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              setShowWallets(!showWallets)
                            }}
                          >
                            {t(
                              'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                            )}
                          </Button>
                        </Tooltip>
                        {countRetry >= 2 && (
                          <Box>
                            <Tooltip
                              title={t(
                                'INVERSOR_VIEW.OFFERING_CARD.WALLET_ERROR_DAPP'
                              )}
                            >
                              <IconButton>
                                <PriorityHighIcon sx={{ color: 'orange' }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                      {showWallets && (
                        <>
                          {wallets.length === 0 ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'left',
                                  justifyContent: 'center',
                                  mt: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    pl: 1,
                                    pr: 1,
                                    pt: 1,
                                    color: actualThemeLight
                                      ? '#9A1D4C'
                                      : '#e6a3bd',
                                  }}
                                >
                                  {t('WALLET_VIEW.NO_WALLET_HELPER1')}
                                </Typography>
                                <Typography
                                  sx={{
                                    pl: 1,
                                    pr: 1,
                                    pb: 1,
                                    color: actualThemeLight
                                      ? '#9A1D4C'
                                      : '#e6a3bd',
                                  }}
                                >
                                  {t('WALLET_VIEW.NO_WALLET_HELPER2')}
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              {wallets?.map((key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mr: 2,
                                    mt: 1,
                                  }}
                                >
                                  <Button
                                    sx={{
                                      mt: 1,
                                      color: actualThemeLight
                                        ? null
                                        : '#e6a3bd',
                                      borderColor: actualThemeLight
                                        ? null
                                        : '#e6a3bd',
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      handleConnectButtonClick(key)
                                    }
                                  >
                                    <Typography sx={{ ml: 1 }}>
                                      {
                                        t(
                                          'INVERSOR_VIEW.OFFERING_CARD.ERROR_NO_WALLET_ACT'
                                        ).split(' ')[0]
                                      }
                                    </Typography>
                                    <Avatar
                                      sx={{ ml: 1 }}
                                      src={window.cardano[key].icon}
                                      width={24}
                                      height={24}
                                      alt={key}
                                    />
                                    <Typography sx={{ ml: 1 }}>
                                      {key}
                                    </Typography>
                                  </Button>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                )}
                {walletEnabled && localStorageWallet !== null && (
                  <Box
                    sx={{
                      width: '100%',
                      mt: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        color: actualThemeLight ? '#4F4F4F' : 'white',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        fontFamily: 'Quicksand',
                        letterSpacing: 0.15,
                        textAlign: 'left',
                      }}
                    >
                      {t('DASHBOARD_VIEW.MY_WALLET')}
                    </Typography>
                    {isCorrectNetworkSelected === false && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ color: '#f7712e' }}>
                          {t('WALLET_VIEW.WRONG_NET')}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        mt: 1,
                      }}
                    >
                      <Typography>
                        {t('WALLET.CONNECTED')} {localStorageWallet}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ fontSize: '10.5px', overflow: 'visible' }}
                    >
                      {changeAddress}
                    </Typography>
                    {walletFromDb?.users[0]?.wallet?.address ===
                      changeAddress && (
                      <Box sx={{ mt: 1 }}>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            whiteSpace: 'nowrap',
                            pt: 0.4,
                            pb: 0.4,
                            pl: 1,
                            pr: 1,
                          }}
                          onClick={() => {
                            deleteLocalStorageField()
                          }}
                        >
                          {t(
                            'INVERSOR_VIEW.OFFERING_CARD.ERROR_DISC_WALLET_ACT'
                          )}
                        </Button>
                      </Box>
                    )}
                    {walletFromDb?.users[0]?.wallet?.address ===
                    changeAddress ? null : (
                      <>
                        {walletFromDb?.users[0]?.wallet !== undefined && (
                          <>
                            <Typography sx={{ mt: 1 }}>
                              {t('WALLET.SAVED')}
                            </Typography>
                            <Typography
                              sx={{ fontSize: '10.5px', overflow: 'visible' }}
                            >
                              {walletFromDb?.users[0]?.wallet === null
                                ? 'NA'
                                : walletFromDb?.users[0]?.wallet?.address}
                            </Typography>
                            <Box sx={{ mt: 1 }}>
                              <Button
                                size="small"
                                sx={{
                                  maxWidth: '150px',
                                  whiteSpace: 'nowrap',
                                  pt: 0.4,
                                  pb: 0.4,
                                  pl: 1,
                                  pr: 1,
                                }}
                                variant="contained"
                                onClick={handleUpdateWallet}
                              >
                                {t('WALLET.ACT_SAVE')}
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  pt: 0.4,
                                  pb: 0.4,
                                  pl: 1,
                                  pr: 1,
                                  ml: 2,
                                }}
                                onClick={() => {
                                  deleteLocalStorageField()
                                }}
                              >
                                {t(
                                  'INVERSOR_VIEW.OFFERING_CARD.ERROR_DISC_WALLET_ACT'
                                )}
                              </Button>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                )}
                <Box>
                  <Typography
                    sx={{
                      color: actualThemeLight ? '#4F4F4F' : 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      fontFamily: 'Quicksand',
                      letterSpacing: 0.15,
                      textAlign: 'left',
                      mt: 4,
                    }}
                  >
                    {t('PERFIL_CONFIG_VIEW.TITLE_PREFERENCES')}
                  </Typography>
                  <InputLabel
                    htmlFor="perfile-select"
                    sx={{
                      color: actualThemeLight ? '#808080' : 'white',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      fontFamily: 'Quicksand',
                      letterSpacing: 0.15,
                      textAlign: 'left',
                    }}
                  >
                    {t('PERFIL_CONFIG_VIEW.PREFERENCES_SUBTITLE')}
                  </InputLabel>
                </Box>
                {preferenceLoading ? (
                  <CircularProgress
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                ) : (
                  <Autocomplete
                    fullWidth
                    multiple
                    size="small"
                    limitTags={6}
                    value={values}
                    onChange={(event, newValue) => setValues(newValue)}
                    id="tags-outlined"
                    options={preferenceList?.preferences}
                    getOptionLabel={(p) => p?.preferenceName}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: { xs: '100%' } }}
                        {...params}
                        name="preferences"
                        placeholder={t('PERFIL_CONFIG_VIEW.PREFECENCE_TITLE')}
                        color="pink"
                      />
                    )}
                  />
                )}
                <Box
                  sx={{
                    minwidth: '100%',
                    minheight: '50%',
                    paddingTop: '1%',
                  }}
                >
                  {userData?.users[0]?.prefers.map((pref) => (
                    <MyChip
                      variant="outlined"
                      value={pref.id}
                      label={pref.preferenceName}
                      onDelete={handleDelete(pref)}
                      key={pref.id}
                      sx={{
                        m: '1%',
                        color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        borderColor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        caretColor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                      }}
                    />
                  ))}
                </Box>

                <FormControl fullWidth>
                  <Typography
                    sx={{
                      color: actualThemeLight ? '#4F4F4F' : 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      fontFamily: 'Quicksand',
                      letterSpacing: 0.15,
                      textAlign: 'left',
                      mt: 4,
                    }}
                  >
                    {t('PERFIL_CONFIG_VIEW.LENGUAGE')}
                  </Typography>
                  {languageLoading ? (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  ) : (
                    <Autocomplete
                      fullWidth
                      multiple
                      sx={{ mt: 1 }}
                      size="small"
                      limitTags={6}
                      value={languageValues}
                      onChange={(event, newValue) =>
                        setLanguageValues(newValue)
                      }
                      id="tags-outlined"
                      options={languageList?.languages}
                      getOptionLabel={(p) => p?.description}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: { xs: '100%' } }}
                          {...params}
                          name="languages"
                          placeholder={t('PERFIL_CONFIG_VIEW.LANGUAGE_TITLE')}
                          color="pink"
                        />
                      )}
                    />
                  )}
                  <Box
                    sx={{
                      minwidth: '100%',
                      minheight: '50%',
                      paddingTop: '1%',
                    }}
                  >
                    {userData?.users[0]?.speaks.map((lang) => (
                      <MyChip
                        variant="outlined"
                        value={lang.id}
                        label={lang.description}
                        onDelete={handleDeleteLang(lang)}
                        key={lang.id}
                        sx={{
                          m: '1%',
                          color: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                          borderColor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                          caretColor: actualThemeLight ? '#9A1D4C' : '#e6a3bd',
                        }}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  marginTop: '5%',
                }}
              ></Box>
            </form>
          </FadeIn>
        </Container>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {savingProfile && (
          <Box sx={{ width: '30%' }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
      <Button
        className={classes.button}
        disabled={userLoading}
        type="submit"
        size="small"
        onClick={handleSubmit}
        variant="contained"
        sx={{ width: '275px', mb: 4, mt: 2 }}
      >
        {t('PERFIL_CONFIG_VIEW.SAVE')}
      </Button>
    </Grid>
  )
}

export default PerfilConfig
