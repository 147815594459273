import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const apiKey = process.env.REACT_APP_FIREBASE_APIKEY
const authDomain = process.env.REACT_APP_FIREBASE_AUTHDOMAIN
const projectId = process.env.REACT_APP_FIREBASE_PROJECTID
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGEBUCKET
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID
const appId = process.env.REACT_APP_FIREBASE_APPID
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENTID

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
}

const app = initializeApp(firebaseConfig)
export const fbStorage = getStorage(app)
