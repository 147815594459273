import { Box, Button, Grid, RadioGroup, Radio, FormControlLabel, InputAdornment, Link, TextField, Tooltip, Typography, Modal, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { showMessage } from '../../store/actions/snackBarActions'
import { EDIT_FUND_PROPOSAL } from '../../Queries/Funds'
import { GET_PROPOSAL_BY_ID } from '../../Queries/Proposals'
import { useLazyQuery, useMutation } from '@apollo/client'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { imgUploadHandler } from '../../utils/fileUpload'
import InfoIcon from '@mui/icons-material/Info'
import { useParams } from "react-router"
import { push } from 'connected-react-router'

const EditProposal = () => {
    const { t } = useTranslation()
    const { proposalId } = useParams()
    const dispatch = useDispatch()
    const dateTime = new Date()

    const [editProposal] = useMutation(EDIT_FUND_PROPOSAL)

    const [fetchProposal,
        { data: proposalByID, refetch }] = useLazyQuery(
            GET_PROPOSAL_BY_ID,
            {
                variables: { proposalId: proposalId },
                pollInterval: 1500
            }
        )

    const [budget, setBudget] = useState(0)
    const [radioSelected, setRadioSelected] = useState('en')
    const [values, setValues] = useState({
        company: '',
        titleEn: '',
        titleEs: '',
        titlePt: '',
        descriptionEN: '',
        descriptionES: '',
        descriptionPT: '',
        actionPlan: '',
        milestone: '',
        objectives: '',
        // Agrega más campos según tus necesidades
    })

    useEffect(async () => {
        await fetchProposal()
        values.company = proposalByID?.proposals[0]?.company?.coName
        values.titleEn = proposalByID?.proposals[0]?.titleEn
        values.titleEs = proposalByID?.proposals[0]?.titleEs
        values.titlePt = proposalByID?.proposals[0]?.titlePt
        values.descriptionEN = proposalByID?.proposals[0]?.descriptionEn
        values.descriptionES = proposalByID?.proposals[0]?.descriptionEs
        values.descriptionPT = proposalByID?.proposals[0]?.descriptionPt
        values.actionPlan = proposalByID?.proposals[0]?.actionPlanFile
        values.milestone = proposalByID?.proposals[0]?.milestonesFile
        values.objectives = proposalByID?.proposals[0]?.objectives
        setBudget(proposalByID?.proposals[0]?.reqInvestAmount)
    }, [fetchProposal, proposalByID])

    const handleInputChange = async (fieldName, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }))
    }

    const handleRadioChange = (event) => {
        setRadioSelected(event.target.value)
    }

    const milestoneHandler = async (file) => {
        const milestoneFileUrl = await imgUploadHandler(file)
        handleInputChange('milestone', milestoneFileUrl)
    }


    const actionPlanHandler = async (file) => {
        const actionPlanFileUrl = await imgUploadHandler(file)
        handleInputChange('actionPlan', actionPlanFileUrl)
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            if (budget < proposalByID?.proposals[0]?.fund?.minCompanyAsk) {
                setBudget(proposalByID?.proposals[0]?.fund?.minCompanyAsk)
            }
            if (budget > proposalByID?.proposals[0]?.fund?.maxCompanyAsk) {
                setBudget(proposalByID?.proposals[0]?.fund?.maxCompanyAsk)
            }
            if (budget === 0) {
                setBudget(proposalByID?.proposals[0]?.fund?.minCompanyAsk)
            }
        }, 400);
        return () => clearTimeout(timer);
    }, [budget, proposalByID])

    const [openSuccessPostulation, setOpenSuccessPostulation] = useState(false);
    const handleOpenSuccessPostulation = () => setOpenSuccessPostulation(true);
    const handleCloseSuccessPostulation = () => setOpenSuccessPostulation(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '5px',
        p: 2,
    };

    const handleEditProposal = async () => {
        try {
            await editProposal({
                variables: {
                    proposalId: proposalByID?.proposals[0]?.id,
                    updateData: {
                        id: proposalByID?.proposals[0]?.id,
                        status: proposalByID?.proposals[0]?.status,
                        titleEn: values.titleEn,
                        titleEs: values.titleEs,
                        titlePt: values.titlePt,
                        descriptionEn: values.descriptionEN,
                        descriptionEs: values.descriptionES,
                        descriptionPt: values.descriptionPT,
                        reqInvestAmount: Number(budget),
                        on: dateTime,
                        actionPlanFile: values.actionPlan,
                        milestonesFile: values.milestone,
                        objectives: values.objectives,
                        assessmentStatus: proposalByID?.proposals[0]?.assessmentStatus
                    }
                }
            })
        }
        catch (error) {
            await dispatch(showMessage({ message: 'Changes Saved, some errors', variant: 'error' }))
        }
        await refetch()
        await dispatch(push('/funds'))
        handleOpenSuccessPostulation()
    }

    return (
        <>
            {/* HEADER */}
            <Grid container xs={12} md={11}>
                <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #4F4F4F', width: '100%', ml: 6, mb: 2, mt: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}
                    >
                        <ArrowBackIcon sx={{
                            fontSize: '16px',
                            cursor: 'pointer'
                        }} />
                        <Typography
                            onClick={() => dispatch(goBack())}
                            sx={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontSize: '14px',
                            }}
                        >
                            {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                        </Typography>
                        <Typography sx={{ fontSize: '20px', ml: 4 }}>
                            {t('ADMIN.POSTULATION')}
                        </Typography>
                    </Box>
                    <Box>
                        <RadioGroup
                            sx={{ mt: 4, mb: 2 }}
                            onChange={handleRadioChange}
                            defaultValue={radioSelected}
                            row
                        >
                            <FormControlLabel
                                value="en"
                                control={<Radio />}
                                label="English*"
                            />
                            <FormControlLabel
                                value="es"
                                control={<Radio />}
                                label="Español"
                            />
                            <FormControlLabel
                                value="pt"
                                control={<Radio />}
                                label="Português"
                            />
                        </RadioGroup>
                    </Box>
                </Grid>
            </Grid>
            {/* LEFT COLUMN */}
            <Grid container xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item xs={12} md={6} sx={{ pl: 16, pr: 8 }}>
                    <Box sx={{ pl: 2, pr: 2 }}>
                        <Typography>
                            {t('INVESTMENT_TABLE.COMPANY')}
                        </Typography>
                        <TextField
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={proposalByID?.proposals[0]?.company?.coName}
                            disabled
                        />
                    </Box>

                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        {radioSelected === 'en' ? (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography>
                                        {t('ADMIN.NEW_PUBLICATION_TITLE_FULL')}*
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={values.titleEn}
                                        onChange={(e) =>
                                            handleInputChange('titleEn', e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        ) : radioSelected === 'es' ? (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography>
                                        {t('ADMIN.NEW_PUBLICATION_TITLE_FULL')}
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={values.titleEs}
                                        onChange={(e) =>
                                            handleInputChange('titleEs', e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography>
                                        {t('ADMIN.NEW_PUBLICATION_TITLE_FULL')}
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={values.titlePt}
                                        onChange={(e) =>
                                            handleInputChange('titlePt', e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        )}
                    </Box>

                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography>
                                {t('CO_ASSESSMENT.MILESTONES')}
                            </Typography>
                            <Tooltip title={'Definir informativo, Aca al lado va el boton para descargar template que cambia segun el vestingType de este fondo, se lee de la BD'}>
                                <InfoIcon
                                    fontSize="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                            {proposalByID?.proposals[0]?.fund?.vestingPlan === "vesting 1" &&
                                <Link
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting1.pdf?alt=media&token=05c4ff7e-70b8-4219-83cc-066f408f2f12'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 1
                                </Link>
                            }
                            {proposalByID?.proposals[0]?.fund?.vestingPlan === "vesting 2" &&
                                <Link
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting2.pdf?alt=media&token=30774161-8223-4bf4-9c88-80524ca05e19'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 2
                                </Link>
                            }
                            {proposalByID?.proposals[0]?.fund?.vestingPlan === "vesting 3" &&
                                <Link
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting3.pdf?alt=media&token=7c9a8eac-b743-4383-bfd8-50c162f1b1f2'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 3
                                </Link>
                            }
                            {proposalByID?.proposals[0]?.fund?.vestingPlan === "vesting 4" &&
                                <Link
                                    href='https://firebasestorage.googleapis.com/v0/b/tokenallies-test.appspot.com/o/images%2FVesting4.pdf?alt=media&token=d6eaf554-2ae5-4696-81b1-241a3dd26f0c'
                                    target='_blank'
                                    fontSize={14} sx={{ ml: 1 }}>
                                    {t('GENERAL.DOWNLOAD')} Template Vesting 4
                                </Link>
                            }
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px solid #000000',
                                    width: { xs: '15%', md: '15%' },
                                    height: '40px',
                                }}
                            >
                                ✅
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '80%', md: '80%' },
                                    height: '40px',
                                }}
                            >
                                <UploadFileIcon sx={{ mr: 2 }} />
                                <input
                                    hidden
                                    type="file"
                                    accept="file/*"
                                    onChange={(e) => milestoneHandler(e.target.files?.[0])}
                                />
                                {t('ADMIN.UPLOAD_MILESTONES')}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {/* LEFT COLUMN- END */}
                {/* RIGHT COLUMN */}
                <Grid item xs={12} md={6} sx={{ pl: 8, pr: 16 }}>


                    <Box sx={{ pl: 2, pr: 2 }}>
                        <Typography>
                            {t('WALLET_VIEW.SYMBOL')}
                        </Typography>
                        <TextField
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={proposalByID?.proposals[0]?.fund?.name}
                            disabled
                        />
                    </Box>

                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography>
                                {t('FUND.REQUESTED_INVESTMENT')}
                            </Typography>
                            <Tooltip title={`Min: ${proposalByID?.proposals[0]?.fund?.minCompanyAsk} - Max: ${proposalByID?.proposals[0]?.fund?.maxCompanyAsk}`}>
                                <InfoIcon
                                    fontSize="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                        </Box>



                        <TextField
                            variant="outlined"
                            type="number"
                            size='small'
                            onWheel={(e) => e.target.blur()}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-8*' }}
                            fullWidth
                            value={budget}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                            }}
                            onChange={(e) => {
                                setBudget(e.target.value)
                                if (budget === 0) {
                                    setBudget(proposalByID?.proposals[0]?.fund?.minCompanyAsk)
                                }
                            }}
                            error={isNaN(budget) && budget !== ''}
                        />
                    </Box>



                    <Box sx={{ pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography>
                                {t('CO_ASSESSMENT.ACT_PLAN')}
                            </Typography>
                            <Tooltip title={t('CREATE_COMPANY.UPLOAD_PDF_FILE')}>
                                <InfoIcon
                                    fontSize="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px solid #000000',
                                    width: { xs: '15%', md: '15%' },
                                    height: '40px',
                                }}
                            >
                                ✅
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                                starticon={<UploadFileIcon />}
                                sx={{
                                    borderRadius: '5px',
                                    border: '1px dashed #000000',
                                    width: { xs: '80%', md: '80%' },
                                    height: '40px',
                                }}
                            >
                                <UploadFileIcon sx={{ mr: 2 }} />
                                <input
                                    hidden
                                    type="file"
                                    accept="file/*"
                                    onChange={(e) => actionPlanHandler(e.target.files?.[0])}
                                />
                                {t('ADMIN.UPLOAD_ACTION_PLAN')}
                            </Button>
                        </Box>
                    </Box>


                </Grid>
                {/* RIGHT COLUMN - END */}
            </Grid >


            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 16, mr: 16, mt: 1 }}>
                <Box sx={{ pl: 2, pr: 2, width: '100%' }}>
                    <Typography>
                        {t('FUND.OBJECTIVES')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={2}
                        value={values.objectives}
                        onChange={(e) => handleInputChange('objectives', e.target.value)} />
                </Box>
            </Box >
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 16, mr: 16, mt: 1 }}>
                <Box sx={{ pl: 2, pr: 2, width: '100%' }}>
                    {radioSelected === 'en' ? (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {t('ADMIN.DESCRIPTION')}*
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={values.descriptionEN}
                                    onChange={(e) =>
                                        handleInputChange('descriptionEN', e.target.value)
                                    }
                                    type="text"
                                    aria-describedby="status-input-text"
                                />
                            </Box>
                        </>
                    ) : radioSelected === 'es' ? (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {t('ADMIN.DESCRIPTION')}
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={values.descriptionES}
                                    onChange={(e) =>
                                        handleInputChange('descriptionES', e.target.value)
                                    }
                                    type="text"
                                    aria-describedby="status-input-text"
                                />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {t('ADMIN.DESCRIPTION')}
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    value={values.descriptionPT}
                                    onChange={(e) =>
                                        handleInputChange('descriptionPT', e.target.value)
                                    }
                                    type="text"
                                    aria-describedby="status-input-text"
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Box >
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 16, mr: 16 }}>
                <Box sx={{ pl: 2, pr: 2, mt: 2, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        values.company === '' ||
                            values.titleEn === '' ||
                            values.descriptionEN === '' ||
                            budget === 0 ||
                            values.actionPlan === '' ||
                            values.milestone === '' ||
                            values.objectives === '' ?
                            < Button
                                variant="contained"
                                color="primary"
                                component="label"
                                disabled
                                sx={{
                                    borderRadius: '5px',
                                    backgroundColor: '#9A1D4C',
                                    ml: 8
                                }}
                            >
                                {t('CREATE_COMPANY.UPDATE')}
                            </Button> :
                            < Button
                                variant="contained"
                                color="primary"
                                component="label"
                                onClick={handleEditProposal}
                                sx={{
                                    borderRadius: '5px',
                                    backgroundColor: '#9A1D4C',
                                    ml: 8
                                }}
                            >
                                {t('CREATE_COMPANY.UPDATE')}
                            </Button>
                    }
                </Box>
            </Box >
            <Modal
                open={openSuccessPostulation}
                onClose={handleCloseSuccessPostulation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ fontSize: '18px', color: '#9A1D4C', fontWeight: 'bold', mb: 2 }}>
                        {t('FUND.SUCCESS_POSTULATION')}
                    </Typography>

                    <Typography sx={{ pb: 1, mb: 1, borderBottom: '1px solid gray' }}>
                        {proposalByID?.proposals[0]?.fund?.name}
                    </Typography>
                    <Typography>
                        {t('FUND.SUCCESS_POSTULATION_OK')}
                    </Typography>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                        <Button variant='contained' onClick={() => dispatch(goBack())}>
                            {t('FUND.BACK_FUND')}
                        </Button>
                    </Box>
                </Box>
            </Modal >
        </>
    )
}

export default EditProposal