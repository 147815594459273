import { gql } from '@apollo/client'

export const GET_PROPOSAL_BY_ID = gql`
  query ($proposalId: ID!) {
    proposals(where: { id: $proposalId }) {
      id
      url {
        id
        link
      }
      on
      fund {
        id
        name
        minCompanyAsk
        maxCompanyAsk
        vestingPlan
        status
      }
      status
      titleEn
      titleEs
      titlePt
      report {
        id
        url(where: { name: "ReportLink" }) {
          id
          link
        }
        titleEn
        titleEs
        titlePt
        votePercent
        amountReceived
        amountRequested
        achievedObjective
      }
      company {
        id
        logo
        coName
        address
        employees
        descriptionEn
        descriptionEs
        descriptionPt
        has_url {
          id
          link
          name
        }
        fundationYear
        annualBilling
        preferences {
          id
          preferenceName
        }
        country {
          id
          name
        }
        users {
          id
          userName
          email
        }
      }
      messages {
        id
        on
        text
        onUTC
        sentBy {
          id
          userName
        }
        deleted
        replyTo
        isOfferPurchase
      }
      voteCount
      objectives
      descriptionEn
      descriptionEs
      descriptionPt
      actionPlanFile
      milestonesFile
      reqInvestAmount
      assessmentStatus
      assessmentDetail
      assessmentRating
    }
  }
`

export const MARK_PROPOSAL_COMPLETE = gql`
  mutation ($proposalId: ID!) {
    updateProposals(
      where: { id: $proposalId }
      update: { assessmentStatus: "complete" }
    ) {
      proposals {
        id
      }
    }
  }
`

export const MARK_PROPOSAL_INCOMPLETE = gql`
  mutation ($proposalId: ID!) {
    updateProposals(
      where: { id: $proposalId }
      update: { assessmentStatus: "incomplete" }
    ) {
      proposals {
        id
      }
    }
  }
`

export const UPDATE_PROPOSAL_ASSESSMENT = gql`
  mutation (
    $proposalId: ID!
    $newStatus: String
    $assessmentStatus: String
    $assessmentDetail: String
    $assessmentRating: Float
  ) {
    updateProposals(
      where: { id: $proposalId }
      update: {
        status: $newStatus
        assessmentStatus: $assessmentStatus
        assessmentDetail: $assessmentDetail
        assessmentRating: $assessmentRating
      }
    ) {
      proposals {
        id
      }
    }
  }
`

export const SEND_COMMENT_TO_PROPOSAL = gql`
  mutation createMessage(
    $MessageId: ID!
    $MessageDate: DateTime!
    $MessageText: String!
    $UserId: ID!
    $ProposalId: ID!
  ) {
    createMessages(
      input: {
        id: $MessageId
        on: $MessageDate
        text: $MessageText
        read: false
        sentBy: { connect: { where: { node: { id: $UserId } } } }
        proposal: { connect: { where: { node: { id: $ProposalId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`

export const SEND_REPLY_TO_COMMENT_TO_PROPOSAL = gql`
  mutation createMessage(
    $MessageId: ID!
    $MessageDate: DateTime!
    $MessageText: String!
    $UserId: ID!
    $ProposalId: ID!
    $replyToId: String
  ) {
    createMessages(
      input: {
        id: $MessageId
        on: $MessageDate
        text: $MessageText
        read: false
        replyTo: $replyToId
        sentBy: { connect: { where: { node: { id: $UserId } } } }
        proposal: { connect: { where: { node: { id: $ProposalId } } } }
      }
    ) {
      messages {
        id
      }
    }
  }
`

export const CREATE_REPORT_PROPOSAL = gql`
  mutation (
    $reportData: [ReportCreateInput!]!
    $urlData: [UrlCreateInput!]!
    $reportId: ID!
    $urlId: ID!
    $proposalId: ID!
  ) {
    createReports(input: $reportData) {
      reports {
        id
      }
    }
    createUrls(input: $urlData) {
      urls {
        id
      }
    }
    updateReports(
      where: { id: $reportId }
      connect: {
        url: { where: { node: { id: $urlId } } }
        proposal: { where: { node: { id: $proposalId } } }
      }
    ) {
      reports {
        id
      }
    }
  }
`

export const UPDATE_PROPOSAL_VOTE_COUNT = gql`
  mutation ($proposalId: ID!, $voteCount: Int!) {
    updateProposals(
      where: { id: $proposalId }
      update: { voteCount: $voteCount }
    ) {
      proposals {
        id
      }
    }
  }
`
