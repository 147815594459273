import { GET_ALL_PREFERENCES } from '../actions/userConfigActions'

const initialState = {
  preferences: [],
}

const preferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PREFERENCES.success: {
      return {
        ...state,
        preferences: action.preferences,
      }
    }
    default: {
      return state
    }
  }
}

export default preferenceReducer
