import React, { Fragment, useEffect, useState } from "react"
import {
    Box, Typography, Avatar, styled, Button, Grid, CircularProgress,
    FormControl, OutlinedInput, InputAdornment, IconButton,
    Link, Tabs, Tab, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Dialog, Slide, DialogContent,
    useTheme
} from "@mui/material"
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_FUNDS_BY_ID } from "../../../Queries/Funds"
import { useParams } from "react-router"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search'
import moment from 'moment';
import { push } from 'connected-react-router'
import i18n from "i18next";
import PropTypes from 'prop-types';
import Crecimiento from '../../../../src/assets/icons/crecimiento.svg'
import Rocket from '../../../../src/assets/icons/rocket.svg'
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { PUBLISH_FUND_COLLECTING } from '../../../Queries/Admin'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const FundDetailAdmin = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { fundId } = useParams()
    const selectedLanguage = i18n.language

    const [value, setValue] = React.useState(0);
    const [selectedInvestor, setSelectedInvestor] = useState('')
    const [investmentModal, setInvestmentModal] = useState(false)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [search, setSearch] = useState('')

    const [publishFundToCollecting] = useMutation(PUBLISH_FUND_COLLECTING)

    // Query for funds by ID and set poll interval
    const [fetchFunds, { data: fundsById, loading: fundLoading }] = useLazyQuery(
        GET_FUNDS_BY_ID,
        {
            variables: { FundId: fundId },
            pollInterval: 1500
        }
    )


    const todayDate = new Date().toISOString().split('T')[0]
    const [collectingStartDate, setCollectingStartDate] = useState(undefined)
    const [collectingEndDate, setCollectingEndDate] = useState(undefined)
    const [openStartDate, setOpenStartDate] = useState(undefined)
    const [openEndDate, setOpenEndDate] = useState(undefined)
    const [governanceStartDate, setGovernanceStartDate] = useState(undefined)
    const [governanceEndDate, setGovernanceEndDate] = useState(undefined)
    const [vestingStartDate, setVestingStartDate] = useState(undefined)
    const [vestingEndDate, setVestingEndDate] = useState(undefined)

    useEffect(() => {
        fundsById?.funds[0]?.event?.map((e) => {
            if (e?.titleEn === 'Collecting') {
                setCollectingStartDate(e?.startDate?.split('T')[0])
                setCollectingEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Open') {
                setOpenStartDate(e?.startDate?.split('T')[0])
                setOpenEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Governance') {
                setGovernanceStartDate(e?.startDate?.split('T')[0])
                setGovernanceEndDate(e?.endDate?.split('T')[0])
            }
            if (e?.titleEn === 'Vesting') {
                setVestingStartDate(e?.startDate?.split('T')[0])
                setVestingEndDate(e?.endDate?.split('T')[0])
            }
        })
    }, [fundsById])

    const canBePublished = collectingStartDate <= todayDate

    const [headerStartDateToShow, setHeaderStartDateToShow] = useState(undefined)
    const [headerEndDateToShow, setHeaderEndDateToShow] = useState(undefined)

    useEffect(() => {
        fundsById?.funds[0]?.event?.map((e) => {
            if (e?.titleEn === fundsById?.funds[0]?.status) {
                setHeaderStartDateToShow(e?.startDate?.split('T')[0])
                setHeaderEndDateToShow(e?.endDate?.split('T')[0])
            }
        })
    }, [fundsById])

    const handlePublishFund = async () => {
        await publishFundToCollecting({
            variables: {
                fundId: fundId
            }
        })
        await dispatch(push('/admin-fund-management'))
    }

    const handleProposalClick = (applicationId) => {
        dispatch(push(`/admin-application-detail/${applicationId}`))
    }


    // Fetch funds by ID
    useEffect(() => {
        fetchFunds()
    }, [fetchFunds])

    // Button styling for back button
    const BackButton = styled(Button)(({ theme }) => ({
        color: theme.palette.text.hint,
        fontFamily: 'Quicksand, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    }))

    const startDate = moment(headerStartDateToShow);
    const endDate = moment(headerEndDateToShow);
    const startDay = startDate.format('DD');
    const startMonth = startDate.format('MM');
    const endDay = endDate.format('DD');
    const endMonth = endDate.format('MM');

    const startDateCollecting = moment(collectingStartDate);
    const endDateCollecting = moment(collectingEndDate);
    const startDayCollecting = startDateCollecting.format('DD');
    const startMonthCollecting = startDateCollecting.format('MM');
    const endDayCollecting = endDateCollecting.format('DD');
    const endMonthCollecting = endDateCollecting.format('MM');

    const startDateOpen = moment(openStartDate);
    const endDateOpen = moment(openEndDate);
    const startDayOpen = startDateOpen.format('DD');
    const startMonthOpen = startDateOpen.format('MM');
    const endDayOpen = endDateOpen.format('DD');
    const endMonthOpen = endDateOpen.format('MM');

    const startDateGovernance = moment(governanceStartDate);
    const endDateGovernance = moment(governanceEndDate);
    const startDayGovernance = startDateGovernance.format('DD');
    const startMonthGovernance = startDateGovernance.format('MM');
    const endDayGovernance = endDateGovernance.format('DD');
    const endMonthGovernance = endDateGovernance.format('MM');

    const startDateVesting = moment(vestingStartDate);
    const endDateVesting = moment(vestingEndDate);
    const startDayVesting = startDateVesting.format('DD');
    const startMonthVesting = startDateVesting.format('MM');
    const endDayVesting = endDateVesting.format('DD');
    const endMonthVesting = endDateVesting.format('MM');
    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    if (fundLoading) {
        return (
            <>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '30vh'
                }}>
                    <CircularProgress />
                </Box>
            </>
        )
    }

    return <Fragment>
        <Grid container>
            {/* HEADER */}
            <Grid item xs={12} md={12} className="right">
                <Box sx={{ backgroundColor: '#9A1D4C' }}>
                    {!fundLoading
                        ?
                        <Grid container>
                            {/* BACK BUTTON & AVATAR */}
                            <Grid item md={1} xs={1} sx={{ alignSelf: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <ArrowBackIcon sx={{ color: '#FFF', fontSize: '16px' }} />
                                    <BackButton
                                        onClick={() => dispatch(goBack())}
                                        sx={{ marginBottom: '10px', color: 'white', textDecoration: 'underline', fontSize: '14px' }}
                                    >
                                        {t('INVERSOR_VIEW.USER_CARD.RETURN')}
                                    </BackButton>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={2} sx={{ maxHeight: '16vh', alignSelf: 'center' }} >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: '90px',
                                            height: '90px',
                                        }}
                                        alt={fundsById?.funds[0]?.name}
                                        src={fundsById?.funds[0]?.image}
                                    />
                                </Box>
                            </Grid>
                            {/* FUND INFO */}
                            <Grid item md={8} xs={8} sx={{ alignSelf: 'center' }}>
                                <Grid container sx={{ color: '#FFF' }}>
                                    <Grid item md={0.5} xs={0.5} />
                                    {/* COLUMN 1 */}
                                    <Grid item md={3} xs={3}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                {fundsById?.funds[0]?.name}
                                            </Typography>
                                            <Typography sx={{ fontSize: '18px' }}>
                                                {fundsById?.funds[0]?.status}
                                            </Typography>
                                            <Typography sx={{ pr: '4px' }} >
                                                {t('ADMIN.AMOUNT_GATHER')}: $ {
                                                    fundsById?.funds[0].amountToReach.toLocaleString()
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {/* COLUMN 2 */}
                                    <Grid item md={8} xs={8} sx={{ alignSelf: 'center', height: '100%', ml: 3 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {headerStartDateToShow !== undefined &&
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                    {t(`MONTHS.${startMonth}`).substring(0, 3)}. {startDay} /
                                                </Typography>
                                            }
                                            {headerEndDateToShow !== undefined &&
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', ml: 1 }}>
                                                    {t(`MONTHS.${endMonth}`).substring(0, 3)}. {endDay}
                                                </Typography>
                                            }

                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography sx={{ fontSize: '16px', pr: '4px' }}>
                                                {t('ADMIN.PURPOSE')}:
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px', pr: '4px', ml: 1, fontWeight: 'bold' }}>
                                                {selectedLanguage === 'en' ? fundsById?.funds[0].purpose.titleEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0].purpose.titleEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].purpose.titlePt}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={0.5} xs={0.5} />
                        </Grid>
                        :
                        null}
                </Box>
            </Grid>
            {/* FUND DESCRIPTION & CTA */}
            <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                <Box sx={{ width: '100%', pl: 20, pr: 8 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={t('FUND.INFORMATION')} {...a11yProps(0)} />
                            {fundsById?.funds[0].status !== "Draft" &&
                                <Tab label={t('NAVBAR.COMPANIES')} {...a11yProps(1)} />
                            }
                            {fundsById?.funds[0].status !== "Draft" &&
                                <Tab label={t('OFFERER_DASHBOARD.INVESTOR')} {...a11yProps(2)} />
                            }
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{
                                width: '45%',
                                height: '20vh',
                                backgroundColor: actualThemeLight ? '#B2E0DF' : "#2A4C4B",
                                borderRadius: '15px',
                                p: 1,
                                pl: 2,
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <Box sx={{
                                    width: '15%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <img src={Crecimiento} alt="icon" width="70px" />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    alignItems: 'left',
                                    justifyContent: 'center',
                                    ml: 2
                                }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ fontSize: '14px' }}>{t('FUND.MIN/MAX_INVESTMENT')}: </Typography>
                                        <Typography sx={{ fontWeight: 'bold', ml: 1, fontSize: '14px' }}> ${fundsById?.funds[0].minInvestment.toLocaleString()} / ${fundsById?.funds[0].maxInvestment.toLocaleString()}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <AccessTimeIcon />
                                        <Typography sx={{ ml: 1, fontSize: '14px' }}>{t('FUND.EXPECTED_ROI')}: </Typography>
                                        <Typography sx={{ fontWeight: 'bold', ml: 1, fontSize: '14px' }}>{fundsById?.funds[0].roiTime}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ fontSize: '14px' }}>Min/Max {t('FUND.REQUESTED_INVESTMENT')}: </Typography>
                                        <Typography sx={{ fontWeight: 'bold', ml: 1, fontSize: '14px' }}>${fundsById?.funds[0].minCompanyAsk.toLocaleString()} / ${fundsById?.funds[0].maxCompanyAsk.toLocaleString()}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ fontSize: '14px' }}>Target: </Typography>
                                        <Typography sx={{ fontWeight: 'bold', ml: 1, fontSize: '14px' }}>
                                            {
                                                selectedLanguage === 'en' ? fundsById?.funds[0].purpose.targetEn :
                                                    selectedLanguage === 'es' ? fundsById?.funds[0].purpose.targetEs :
                                                        selectedLanguage === 'pt' && fundsById?.funds[0].purpose.targetPt
                                            }
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                            <Box sx={{
                                width: '45%',
                                height: '20vh',
                                borderRadius: '15px',
                                ml: 4,
                                p: 1,
                                mt: 2,
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <DoubleArrowIcon fontSize="large" />
                                    <Typography sx={{ fontSize: '18px' }}>
                                        {t('CREATE_COMPANY.DESCRIPTION')}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Box sx={{ minWidth: '10px', height: '10vh', backgroundColor: '#9A1D4C', mt: 2 }}>
                                        <Typography sx={{ color: '#9A1D4C' }}>a</Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: '13px', ml: 2 }}>
                                        {
                                            selectedLanguage === 'en' ? fundsById?.funds[0].descriptionEn :
                                                selectedLanguage === 'es' ? fundsById?.funds[0].descriptionEs :
                                                    selectedLanguage === 'pt' && fundsById?.funds[0].descriptionPt
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            width: '100%',
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <Box sx={{
                                width: '20%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <img src={Rocket} alt="icon" width="70px" />
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {selectedLanguage === 'en' ? fundsById?.funds[0].purpose.titleEn :
                                        selectedLanguage === 'es' ? fundsById?.funds[0].purpose.titleEs :
                                            selectedLanguage === 'pt' && fundsById?.funds[0].purpose.titlePt}
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '25%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{ fontWeight: 'bold', mt: 1, fontSize: '14px' }}>
                                    {t('ADMIN.AMOUNT_GATHER')}
                                </Typography>
                                <Typography sx={{ fontSize: '14px' }}>0%</Typography>
                                <Box sx={{ width: '70%' }}>
                                    <LinearProgress variant="determinate" value={0} />
                                </Box>
                                <Typography sx={{ fontSize: '14px' }}>$0/${fundsById?.funds[0].amountToReach.toLocaleString()}</Typography>

                            </Box>
                            <Box sx={{
                                width: '25%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2
                            }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t("ADMIN.SUCC_THRESHOLD")}
                                </Typography>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {fundsById?.funds[0].successThreshold}%
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '25%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2
                            }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t("FUND.VESTING.VESTING_TYPE")}
                                </Typography>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {fundsById?.funds[0].vestingPlan}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            width: '100%',
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '2px solid #9A1D4C',
                            borderRadius: '15px'
                        }}>
                            <Box sx={{
                                width: '20%',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                                alignItems: 'left',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t('ADMIN.COLLECTING_PERIOD')}
                                </Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t('ADMIN.OPEN_PERIOD')}
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '20%',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                                alignItems: 'left',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {t(`MONTHS.${startMonthCollecting || ''}`).substring(0, 3)}. {startDayCollecting || ''} - {t(`MONTHS.${endMonthCollecting || ''}`).substring(0, 3)}. {endDayCollecting || ''}
                                </Typography>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {t(`MONTHS.${startMonthOpen || ''}`).substring(0, 3)}. {startDayOpen || ''} - {t(`MONTHS.${endMonthOpen || ''}`).substring(0, 3)}. {endDayOpen || ''}
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '20%',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                                alignItems: 'left',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t('ADMIN.GOVERNANCE_PERIOD')}
                                </Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t('ADMIN.VESTING_PERIOD')}
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '20%',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                                alignItems: 'left',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {t(`MONTHS.${startMonthGovernance || ''}`).substring(0, 3)}. {startDayGovernance || ''} - {t(`MONTHS.${endMonthGovernance || ''}`).substring(0, 3)}. {endDayGovernance || ''}
                                </Typography>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {t(`MONTHS.${startMonthVesting || ''}`).substring(0, 3)}. {startDayVesting || ''} - {t(`MONTHS.${endMonthVesting || ''}`).substring(0, 3)}. {endDayVesting || ''}
                                </Typography>
                            </Box>
                        </Box>
                        {fundsById?.funds[0]?.status === 'Draft' &&
                            canBePublished
                            ?
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}>
                                <Button onClick={handlePublishFund} variant="contained" sx={{ mt: 2 }}>Publish</Button>
                            </Box> : null}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <>
                            {
                                fundsById?.funds[0]?.proposal.length > 0
                                    ?
                                    <>
                                        <Grid container md={12} xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item md={4} xs={4} sx={{ mt: 1 }}>
                                                <Typography>{t('FUND_SEARCH_VIEW.PARTICIPATING_COMPANIES')}</Typography>
                                                <FormControl fullWidth            >
                                                    <OutlinedInput
                                                        size="small"
                                                        placeholder={t('MESSAGES.SEARCH_MESSAGE')}
                                                        value={search}
                                                        onChange={(e) => {
                                                            setSearch(e.target.value)
                                                        }}
                                                        startAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        sx={{ width: '100%', margin: '0 auto' }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                        <TableContainer sx={{ width: '100%', height: '60vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center', mt: 2 }}>
                                            <Table stickyHeader={true} sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                            {t('ADMIN.COMPANY_NAME')}
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                            {t('ADMIN.STATUS')}
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                            {t('ADMIN.REQ_DJED')}
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {fundsById?.funds[0]?.proposal.map((prop) => {
                                                        return (
                                                            <>
                                                                <TableRow key={prop?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                                                    <TableCell align="center" component="th" scope="row" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <Avatar src={prop?.company.logo} sx={{ mr: 2 }}></Avatar>
                                                                        {prop?.company.coName}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {prop.status === 'proposed' &&
                                                                            <Typography>
                                                                                {t('CO_ASSESSMENT.APPLIED')}
                                                                            </Typography>}
                                                                        {prop.status === 'assessed' &&
                                                                            <Typography>
                                                                                {t('CO_ASSESSMENT.ACCEPTED')}
                                                                            </Typography>}
                                                                        {prop.status === 'rejected' &&
                                                                            <Typography>
                                                                                {t('CO_ASSESSMENT.REJECTED')}
                                                                            </Typography>}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {prop.reqInvestAmount}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Link onClick={() => {
                                                                            handleProposalClick(prop.id)
                                                                        }}>
                                                                            {t('OFFERINGS_TABLE.ACT_SEE_MORE')}
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow >
                                                            </>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                    :
                                    <Box sx={{ ml: 8, mt: 8 }}>
                                        <Typography>
                                            {t('FUND_SEARCH_VIEW.NO_COMPANIES_YET')}
                                        </Typography>
                                    </Box>
                            }
                        </>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <>
                            {
                                fundsById?.funds[0]?.offerPurchase.length > 0
                                    ?
                                    <>
                                        <Grid container md={12} xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item md={4} xs={4} sx={{ mt: 1 }}>
                                                <Typography>{t('ADMIN.USERS')}</Typography>
                                                <FormControl fullWidth            >
                                                    <OutlinedInput
                                                        size="small"
                                                        placeholder={t('MESSAGES.SEARCH_MESSAGE')}
                                                        value={search}
                                                        onChange={(e) => {
                                                            setSearch(e.target.value)
                                                        }}
                                                        startAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        sx={{ width: '100%', margin: '0 auto' }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                        <TableContainer sx={{ width: '100%', height: '60vh', border: '1px solid gray', borderRadius: '5px', alignSelf: 'center', mt: 2 }}>
                                            <Table stickyHeader={true} sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                            {t('ADMIN.USERNAME')}
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                            {t('WALLET_VIEW.INVESTMENT_CARD.INVESTED')}
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                            Smart Contract
                                                        </TableCell>
                                                        <TableCell sx={{ backgroundColor: '#EBECF2' }} align="center">
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {fundsById?.funds[0]?.offerPurchase.map((invest) => {
                                                        return (
                                                            <>
                                                                <TableRow key={invest?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                                                    <TableCell align="center" component="th" scope="row" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <Avatar src={invest?.users?.profilePicture} sx={{ mr: 2 }}></Avatar>
                                                                        {invest?.users?.userName}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {invest?.amount / 1000000}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <ContactPageIcon />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Link onClick={() => {
                                                                            setSelectedInvestor(invest)
                                                                            setInvestmentModal(!investmentModal)
                                                                        }}>
                                                                            {t('OFFERINGS_TABLE.ACT_SEE_MORE')}
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow >
                                                            </>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {<Dialog
                                            open={investmentModal}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={() => { setInvestmentModal(false) }}
                                        >
                                            <DialogContent style={{ width: '650px' }}>
                                                <>
                                                    <Box
                                                        sx={{
                                                            width: '95%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            position: 'absolute',
                                                            pr: 2
                                                        }}>
                                                        <CloseIcon
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setInvestmentModal(false)
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: { sm: '14px', md: '18px', lg: '20px' },
                                                                fontFamily: 'Quicksand, sans-serif',
                                                                lineHeight: '20px',
                                                                fontWeight: 'bold',
                                                                color: '#9A1D4C',
                                                            }}
                                                        >
                                                            {t('ADMIN.INVESTMENT_DETAIL')}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                mt: 2
                                                            }}
                                                        >
                                                            <Typography>
                                                                {t('ADMIN.INVESTMENT_DETAIL_INVESTOR')}:
                                                            </Typography>
                                                            <Avatar src={selectedInvestor?.users?.profilePicture} sx={{ mr: 2, ml: 2 }}></Avatar>
                                                            {selectedInvestor?.users?.userName}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                mt: 2
                                                            }}
                                                        >
                                                            <Typography sx={{ mr: 2 }}>
                                                                {t('ADMIN.INVESTMENT_DETAIL_DATE')}:
                                                            </Typography>
                                                            <Typography>
                                                                {selectedInvestor?.on?.split('T')[0]}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                mt: 2
                                                            }}
                                                        >
                                                            <Typography sx={{ mr: 2 }}>
                                                                {t('ADMIN.INVESTMENT_DETAIL_AMOUNT')}:
                                                            </Typography>
                                                            <Typography>
                                                                {selectedInvestor?.amount / 1000000}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                mt: 2
                                                            }}
                                                        >
                                                            <Typography sx={{ mr: 1 }}>
                                                                Hash:
                                                            </Typography>
                                                            <Typography>
                                                                {selectedInvestor?.transaction?.hash}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                mt: 2
                                                            }}
                                                        >
                                                            <Typography sx={{ mr: 1 }}>
                                                                Status:
                                                            </Typography>
                                                            <Typography>
                                                                {selectedInvestor?.status}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </>
                                            </ DialogContent>
                                        </Dialog >}
                                    </>
                                    :
                                    <Box sx={{ ml: 8, mt: 8 }}>
                                        <Typography>
                                            {t('FUND_SEARCH_VIEW.NO_INVESTMENTS_YET')}
                                        </Typography>
                                    </Box>
                            }
                        </>
                    </TabPanel>
                </Box>
            </Grid>
        </Grid>
    </Fragment >
}

export default FundDetailAdmin