import { Box, LinearProgress, TableCell, TableRow, Typography, CircularProgress, Button, Tooltip, IconButton, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid'
import { Address } from '@emurgo/cardano-serialization-lib-asmjs'
import { UPDATE_OFFER_PURCHASE } from '../../Queries/SmartContracts';
import { CREATE_TRANSACTION_USER } from "../../Queries/Transactions";
import { CREATE_ERROR_LOG } from '../../Queries/Errors';
import { useDispatch } from 'react-redux'
import { showMessage } from '../../store/actions/snackBarActions'
import Fade from '@mui/material/Fade';
import axios from 'axios';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import OutputIcon from '@mui/icons-material/Output';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RefreshIcon from '@mui/icons-material/Refresh';

const SelectedFundRowInvestment = ({ data, connectedWallet }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [createErrorLog] = useMutation(CREATE_ERROR_LOG)
    const marloweScanUrl = "https://preprod.marlowescan.com/contractView?tab=info&contractId="
    const [contractLink, setContractLink] = useState(undefined)
    const [smartContractIdURL, setSmartContractIdURL] = useState(undefined)
    const [smartContractIdURI, setSmartContractIdURI] = useState(undefined)
    // eslint-disable-next-line
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [wallets, setWallets] = useState([]);
    const [walletEnabled, setWalletEnabled] = useState(false);
    const [api, setAPI] = useState(null);
    const todayDate = new Date().toISOString()

    const [isVisible, setIsVisible] = useState(
        document.visibilityState === 'visible'
    )

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsVisible(document.visibilityState === 'visible')
        }

        window.addEventListener('visibilitychange', handleVisibilityChange)

        return () =>
            window.removeEventListener('visibilitychange', handleVisibilityChange)
    }, [])

    useEffect(() => {
        setSmartContractIdURL(data?.smartContract?.contractId)
    }, [data])

    useEffect(() => {
        setContractLink(data?.smartContract?.contractLink)
    }, [data])

    function replaceHash(inputString) {
        var resultString = encodeURIComponent(inputString);
        return resultString;
    }

    useEffect(() => {
        const resultURI = replaceHash(smartContractIdURL)
        setSmartContractIdURI(resultURI)
    }, [smartContractIdURL])

    const DayLastUpdate = data?.lastUpdate?.split('-')[2].split('T')[0]
    const MonthLastUpdate = data?.lastUpdate?.split('-')[1]
    const onDay = data?.on?.split('-')[2].split('T')[0]
    const onMonth = data?.on?.split('-')[1]
    const onYear = data?.on?.split('-')[0]
    const [creatingTx, setCreatingTx] = useState(false)

    function isWithin48Hours(dateString) {
        const inputDate = new Date(dateString);
        inputDate.setDate(inputDate.getDate() + 2);
        return new Date() <= inputDate;
    }

    const [isWithin48Hrs, setIsWithin48Hrs] = useState(() => isWithin48Hours(data?.smartContract?.createdDate));

    useEffect(() => {
        const updateIsWithin48Hrs = () => setIsWithin48Hrs(isWithin48Hours(data?.smartContract?.createdDate));
        const intervalId = setInterval(updateIsWithin48Hrs, 10000);
        return () => clearInterval(intervalId);
    }, [data?.smartContract?.createdDate]);

    function calculateTimeLeft(dateString) {
        const inputDate = new Date(dateString);
        inputDate.setDate(inputDate.getDate() + 2);
        const timeDifference = inputDate - new Date();
        return {
            hoursLeft: Math.floor(timeDifference / (1000 * 60 * 60)),
            minutesLeft: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
        };
    }

    const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(data?.smartContract?.createdDate));

    useEffect(() => {
        const updateTimeLeft = () => setTimeLeft(calculateTimeLeft(data?.smartContract?.createdDate));
        const intervalId = setInterval(updateTimeLeft, 30000);
        return () => clearInterval(intervalId);
    }, [data?.smartContract?.createdDate]);

    function getLocalStorageField(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    const [localStorageWallet, setLocalStorageWallet] = useState(getLocalStorageField('wallet'));

    useEffect(() => {
        if (localStorageWallet !== null) {
            setSelectedWallet(localStorageWallet)
        } else if (localStorageWallet === null) {
            setSelectedWallet(null)
        }
    }, [localStorageWallet])

    useEffect(() => {
        const interval = setInterval(() => {
            setLocalStorageWallet(getLocalStorageField('wallet'));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const isConnectedWalletTheSame = data?.investorAddress === connectedWallet
    const [xChangeAddress, setXChangeAddress] = useState(undefined);
    const [TokenNameInvestor, setTokenNameInvestor] = useState(undefined)

    useEffect(() => {
        const fundName = data?.fund?.name || "";
        const cleanedFundName = fundName.replace(/\s+/g, '').substring(0, 9);
        setTokenNameInvestor(`INV_${cleanedFundName}_${data?.id?.substr(-3)}`);
    }, [data]);

    const checkIfWalletEnabled = async () => {
        let walletIsEnabled = false
        try {
            const walletName = localStorageWallet
            walletIsEnabled = await window.cardano[walletName].isEnabled()
            setWalletEnabled(walletIsEnabled)
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return walletIsEnabled
    }

    const enableWallet = async () => {
        const walletKey = localStorageWallet
        try {
            setAPI(await window.cardano[walletKey].enable())
            setWalletEnabled(true)
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
        return checkIfWalletEnabled()
    }

    const pollWallets = (count = 0) => {
        const IntWallets = []
        for (const key in window.cardano) {
            if (window.cardano[key].enable && IntWallets.indexOf(key) === -1) {
                IntWallets.push(key)
            }
        }
        if (IntWallets.length === 0 && count < 3) {
            setTimeout(() => {
                pollWallets(count + 1)
            }, 1000)
            return
        }
        setWallets(IntWallets)
    }

    useEffect(() => {
        pollWallets()
    }, [])

    const getChangeAddress = async () => {
        if (api !== undefined) {
            try {
                const raw = await api.getChangeAddress()
                const changeAddressObj = Address.from_bytes(
                    Buffer.from(raw, 'hex')
                ).to_bech32()
                setXChangeAddress(changeAddressObj)
            } catch (err) {
                setSelectedWallet(null)
                //Err
            }
        }
    }

    const refreshData = async () => {
        try {
            if (walletEnabled && api !== null) {
                await getChangeAddress()
            }
        } catch (err) {
            setSelectedWallet(null)
            //Err
        }
    }

    async function checkEnabledStatus(walletNames) {
        // eslint-disable-next-line
        let anyEnabled = false
        // eslint-disable-next-line
        const enabledStatuses = await Promise.all(
            walletNames
                .filter(walletName => walletName && typeof walletName === 'string')
                .map(async walletName => {
                    try {
                        const isEnabled = await window.cardano?.[walletName]?.isEnabled();
                        if (isEnabled) {
                            anyEnabled = true;
                            setSelectedWallet(walletName)
                        }
                    } catch (error) {
                        console.error(`Error checking status for ${walletName}:`, error);
                        return { walletName, isEnabled: false };
                    }
                })
        );
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (localStorageWallet !== null) {
                enableWallet()
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [localStorageWallet]);

    useEffect(() => {
        if (isVisible === true) {
            checkEnabledStatus(wallets)
            const intervalId = setInterval(() => {
                checkEnabledStatus(wallets)
            }, 5000)

            return () => clearInterval(intervalId)
        }
    }, [wallets, isVisible])

    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshData();
        }, 2000);

        return () => clearInterval(intervalId);
    }, [api, localStorageWallet]);

    const marloweApiUrl = process.env.REACT_APP_DEMETER_URL + '/' + data?.smartContract?.contractLink + '/transactions'
    const demeterKey = process.env.REACT_APP_DEMETER_KEY
    const delay = 5000
    const pureMarloweApiUrl = process.env.REACT_APP_DEMETER_URL;
    const [updateOfferPurchase] = useMutation(UPDATE_OFFER_PURCHASE)
    const [createTransactionUser] = useMutation(CREATE_TRANSACTION_USER)
    const { user } = useAuth0()
    const newTransactionId = uuidv4()

    async function handleDeposit() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.apply-inputs-tx-json',
                    'X-Change-Address': xChangeAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "inputs": [
                        {
                            "input_from_party": {
                                "role_token": TokenNameInvestor
                            },
                            "into_account": {
                                "role_token": TokenNameInvestor
                            },
                            "of_token": {
                                "currency_symbol": data?.fund?.token[0]?.policyId,
                                "token_name": data?.fund?.token[0]?.symbol
                            },
                            "that_deposits": data?.amount
                        }
                    ],
                    "metadata": {},
                    "tags": {},
                    "version": 'v1'
                })
            };
            const responseTXcreated = await fetch(marloweApiUrl, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await api.signTx(createdTXData?.resource?.tx?.cborHex, true).then(function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            //"Transaction submission failed."
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.transaction)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                updateOfferPurchase({
                    variables: {
                        offerId: data?.id,
                        status: "Investor Deposited - Pending",
                        txId: createdTXData?.resource?.transactionId,
                        lastUpdate: todayDate
                    }
                })
                createTransactionUser({
                    variables: {
                        id: newTransactionId,
                        date: todayDate,
                        type: `Deposit - ${data?.fund?.token[0]?.symbol}`,
                        status: "Pending",
                        hash: createdTXData?.resource?.transactionId,
                        amount: data?.amount,
                        userId: user?.email || user?.sub,
                        transactionId: newTransactionId,
                        offPurId: data?.id
                    }
                })
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1021",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1021 - Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            return;
        } catch (error) {
            setTimeout(() => {
                setCreatingTx(false);
            }, 5000)
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1022",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1022 - Something went wrong, please try again' }));
        }
    }

    const marloweApiUrlWithdrawals = process.env.REACT_APP_DEMETER_URL + '/withdrawals'
    const marloweApiUrlContracts = process.env.REACT_APP_DEMETER_URL + '/contracts';
    const [demeterContractData, setDemeterContractData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const getContractById = () => {
        if (smartContractIdURI) {
            setLoading(true);
            const url = `${marloweApiUrlContracts}/${smartContractIdURI}`;
            axios.get(url)
                .then((response) => {
                    setDemeterContractData(response.data);
                })
                .catch((error) => {
                    console.log("error:", error)
                    setDemeterContractData(undefined);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (smartContractIdURI && demeterContractData === undefined) {
            getContractById()
        }
    }, [smartContractIdURI, demeterContractData])

    const [hasInvestorPayout, setHasInvestorPayout] = useState(false);
    const [completePayoutToWithdraw, setCompletePayoutToWithdraw] = useState(undefined)

    useEffect(() => {
        const unclaimedPayouts = demeterContractData?.resource?.unclaimedPayouts || [];
        let hasRole = false;
        unclaimedPayouts.forEach(payout => {
            if (payout.role.startsWith('I')) {
                hasRole = true;
                setCompletePayoutToWithdraw(payout)
            }
        });
        setHasInvestorPayout(hasRole);
    }, [demeterContractData]);

    async function handleWithdraw() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.withdraw-tx-json',
                    'X-Change-Address': xChangeAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "contractId": data?.smartContract?.contractId,
                    "role": completePayoutToWithdraw?.role,
                    "payouts": [completePayoutToWithdraw?.payoutId]
                })
            };
            const responseTXcreated = await fetch(marloweApiUrlWithdrawals, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await api.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {

                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            //"Transaction submission failed."
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.withdrawal)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                if (data?.status === "Payout Executed") {
                    await updateOfferPurchase({
                        variables: {
                            offerId: data?.id,
                            status: "Investor Withdrawn - Pending",
                            txId: createdTXData?.links?.withdrawal,
                            lastUpdate: todayDate
                        }
                    })
                } else if (data?.status === "Core Withdrawn") {
                    await updateOfferPurchase({
                        variables: {
                            offerId: data?.id,
                            status: "Both Withdrawn - Pending",
                            txId: createdTXData?.links?.withdrawal,
                            lastUpdate: todayDate
                        }
                    })
                }
                createTransactionUser({
                    variables: {
                        transactionData: {
                            id: newTransactionId,
                            date: todayDate,
                            type: "Withdraw - tstAlli",
                            status: "Pending",
                            hash: createdTXData?.links?.withdrawal,
                            amount: data?.amount
                        },
                        userId: user?.email || user?.sub,
                        transactionId: newTransactionId,
                        offPurId: data?.id
                    }
                })
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1023",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1023 - Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            return;
        } catch (error) {
            setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1024",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1024 - Something went wrong, please try again' }));
        }
    }

    async function handleWithdrawInvestment() {
        try {
            setCreatingTx(true);
            //! CREATING TX ON DEMETER - START
            const createTXaction = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/vendor.iog.marlowe-runtime.withdraw-tx-json',
                    'X-Change-Address': xChangeAddress,
                    'dmtr-api-key': demeterKey,
                },
                body: JSON.stringify({
                    "contractId": data?.smartContract?.contractId,
                    "role": completePayoutToWithdraw?.role,
                    "payouts": [completePayoutToWithdraw?.payoutId]
                })
            };
            const responseTXcreated = await fetch(marloweApiUrlWithdrawals, createTXaction);
            const createdTXData = await responseTXcreated.json();
            //! CREATING TX ON DEMETER - END

            // //! SIGNING TX - START
            await api.signTx(createdTXData?.resource?.tx?.cborHex, true).then(async function (witness) {
                const xhttp = new XMLHttpRequest()
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {

                        if (this.status == 202) {
                            setTimeout(delay)
                        } else {
                            //"Transaction submission failed."
                        }
                    }
                }
                xhttp.open("PUT", pureMarloweApiUrl + "/" + createdTXData?.links?.withdrawal)
                xhttp.setRequestHeader("Content-Type", "application/json")
                const req = {
                    type: "ShelleyTxWitness BabbageEra"
                    , description: ""
                    , cborHex: witness
                }
                xhttp.send(JSON.stringify(req))
                if (data?.status === "Payout Executed") {
                    await updateOfferPurchase({
                        variables: {
                            offerId: data?.id,
                            status: "Investor Withdrawn - Pending",
                            txId: createdTXData?.links?.withdrawal,
                            lastUpdate: todayDate
                        }
                    })
                } else if (data?.status === "Core Withdrawn") {
                    await updateOfferPurchase({
                        variables: {
                            offerId: data?.id,
                            status: "Both Withdrawn - Pending",
                            txId: createdTXData?.links?.withdrawal,
                            lastUpdate: todayDate
                        }
                    })
                }
                createTransactionUser({
                    variables: {
                        transactionData: {
                            id: newTransactionId,
                            date: todayDate,
                            type: `Withdraw - ${data?.fund?.token[0]?.symbol}`,
                            status: "Pending",
                            hash: createdTXData?.links?.withdrawal,
                            amount: data?.amount
                        },
                        userId: user?.email || user?.sub,
                        transactionId: newTransactionId,
                        offPurId: data?.id
                    }
                })
            }).catch(function (error) {
                const uniqueErrorID = uuidv4()
                const errorMessage = error.toString();
                createErrorLog({
                    variables: {
                        errorId: uniqueErrorID,
                        errorDate: todayDate,
                        errorCode: "1023",
                        errorText: errorMessage,
                        userId: user?.email || user?.sub
                    }
                })
                dispatch(showMessage({ message: 'Error 1023 - Something went wrong, please try again' }));
            })
            // //! SIGNING TX - END
            await setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            return;
        } catch (error) {
            setTimeout(() => {
                setCreatingTx(false);
            }, 5000);
            const uniqueErrorID = uuidv4()
            const errorMessage = error.toString();
            createErrorLog({
                variables: {
                    errorId: uniqueErrorID,
                    errorDate: todayDate,
                    errorCode: "1024",
                    errorText: errorMessage,
                    userId: user?.email || user?.sub
                }
            })
            dispatch(showMessage({ message: 'Error 1024 - Something went wrong, please try again' }));
        }
    }

    const [unclaimedPayouts, setUnclaimedPayouts] = useState(undefined)
    const [isNextStepDone, setisNextStepDone] = useState(false)

    useEffect(() => {
        setUnclaimedPayouts(demeterContractData?.resource?.unclaimedPayouts?.length)
        if (demeterContractData?.resource?.currentContract === null) {
            setisNextStepDone(true)
        } else {
            setisNextStepDone(false)
        }
    }, [demeterContractData])

    const theme = useTheme()
    const actualThemeLight = theme.palette.mode === 'light'

    return (
        <>
            <TableRow key={data?.data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: actualThemeLight ? 'white' : '#262626', cursor: 'pointer' }}>
                <TableCell sx={{ p: 1, width: '25%' }} align="center" component="th" scope="row">
                    <Typography sx={{ fontSize: '12px' }}>
                        {t(`MONTHS.${onMonth}`).substring(0, 3)}. {onDay}, {onYear}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                        {data?.on?.split('T')[1]?.split('.')[0]} UTC
                    </Typography>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    <Typography sx={{ fontSize: '12px' }}>
                        {data?.amount} {data?.fund?.token[0]?.symbol}
                    </Typography>
                </TableCell>
                <TableCell sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '10vh' }} align="center" component="th" scope="row">
                    {data?.status === "SC Created - ALLI Deposited" ? (
                        <Box sx={{
                            border: '1px solid gray',
                            borderRadius: '10px',
                            backgroundColor: "#EEF18B",
                            pl: 0.5, pr: 0.5,
                            width: '80%'
                        }}>
                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('INVESTMENT_STATUS.APPROVED')}</Typography>
                        </Box>)
                        : data?.status === "Investor Deposited" ? (
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '10px',
                                backgroundColor: "#6BF27D",
                                pl: 0.5, pr: 0.5,
                                width: '80%'
                            }}>
                                <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('TX_TICKER.TX_CONFIRMED')}</Typography>
                            </Box>)
                            : (data?.status === "Close Executed" || data?.status === "Close Executed - Pending") ? (
                                <Box sx={{
                                    border: '1px solid gray',
                                    borderRadius: '10px',
                                    backgroundColor: "#EEF18B",
                                    pl: 0.5, pr: 0.5,
                                    width: '80%'
                                }}>
                                    <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.FUND_CLOSED_WAITING')}</Typography>
                                </Box>)
                                : (data?.status === "Core Withdrawn" || data?.status === "Core Withdrawn - Pending") ? (
                                    <>
                                        <Box sx={{
                                            border: '1px solid gray',
                                            borderRadius: '10px',
                                            backgroundColor: "#EEF18B",
                                            pl: 0.5, pr: 0.5,
                                            width: '80%'
                                        }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW_READY')}</Typography>
                                        </Box>
                                    </>)
                                    : (data?.status === "Payout Executed" || data?.status === "Payout Executed - Pending") ? (
                                        <Box sx={{
                                            border: '1px solid gray',
                                            borderRadius: '10px',
                                            backgroundColor: "#EEF18B",
                                            pl: 0.5, pr: 0.5,
                                            width: '80%'
                                        }}>
                                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.PAYOUT_WAITING')}</Typography>
                                        </Box>)
                                        : data?.status === "SC Timed Out - Core Withdrawn" ? (
                                            <Box sx={{
                                                border: '1px solid gray',
                                                borderRadius: '10px',
                                                backgroundColor: "gray",
                                                pl: 0.5, pr: 0.5,
                                                width: '80%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.CONTRACT_CLOSED')}</Typography>
                                                <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('INVERSOR_VIEW.OFFERING_CARD.TIMEOUT')}</Typography>
                                            </Box>)
                                            : (data?.status === "Investor Withdrawn" || data?.status === "Both Withdrawn") ? (
                                                <Box sx={{
                                                    border: '1px solid gray',
                                                    borderRadius: '10px',
                                                    backgroundColor: "gray",
                                                    pl: 0.5, pr: 0.5,
                                                    width: '80%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.CONTRACT_CLOSED')}</Typography>
                                                    <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.NFA')}</Typography>
                                                </Box>)
                                                : data?.status === "Investor Deposited - Pending" ? (
                                                    <Box sx={{
                                                        border: '1px solid gray',
                                                        borderRadius: '10px',
                                                        backgroundColor: "#F4BC44",
                                                        pl: 0.5, pr: 0.5,
                                                        width: '80%'
                                                    }}>
                                                        <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('TX_TICKER.TX_CONFIRMED').split(' ')[0] + ' ' + t('TX_TICKER.TX_PENDING')}</Typography>
                                                    </Box>)
                                                    : data?.status === "Both Withdrawn - Pending" ? (
                                                        <Box sx={{
                                                            border: '1px solid gray',
                                                            borderRadius: '10px',
                                                            backgroundColor: "#F4BC44",
                                                            pl: 0.5, pr: 0.5,
                                                            width: '80%'
                                                        }}>
                                                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAWN') + ' ' + t('TX_TICKER.TX_PENDING')}</Typography>
                                                        </Box>)
                                                        : data?.status === "Investment Cancelled" ? (
                                                            <Box sx={{
                                                                border: '1px solid gray',
                                                                borderRadius: '10px',
                                                                backgroundColor: "gray",
                                                                pl: 0.5, pr: 0.5,
                                                                width: '80%'
                                                            }}>
                                                                <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('TX_TICKER.INV_CANCELLED')}</Typography>
                                                            </Box>)
                                                            : data?.status === "Investment Cancelled - Target Met" ? (
                                                                <Box sx={{
                                                                    border: '1px solid gray',
                                                                    borderRadius: '10px',
                                                                    backgroundColor: "gray",
                                                                    pl: 0.5, pr: 0.5,
                                                                    width: '80%'
                                                                }}>
                                                                    <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('TX_TICKER.INV_CANCELLED')}</Typography>
                                                                    <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('NOTIFICATIONS.TARGETMET')?.split(',')[0]}</Typography>
                                                                </Box>)
                                                                : data?.status === "Investment Cancelled - Collecting Ended" ? (
                                                                    <Box sx={{
                                                                        border: '1px solid gray',
                                                                        borderRadius: '10px',
                                                                        backgroundColor: "gray",
                                                                        pl: 0.5, pr: 0.5,
                                                                        width: '80%'
                                                                    }}>
                                                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('TX_TICKER.INV_CANCELLED')}</Typography>
                                                                        <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{t('NOTIFICATIONS.COLLOVER')?.split(',')[0]}</Typography>
                                                                    </Box>)
                                                                    : data?.status === "SC Created - ALLI Deposited - Pending" ? (
                                                                        <Box sx={{
                                                                            border: '1px solid gray',
                                                                            borderRadius: '10px',
                                                                            backgroundColor: "#EEF18B",
                                                                            pl: 0.5, pr: 0.5,
                                                                            width: '80%'
                                                                        }}>
                                                                            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('INVESTMENT_STATUS.APPROVED')}</Typography>
                                                                            <LinearProgress />
                                                                        </Box>)
                                                                        : data?.status === "Investment Intention - Waiting List" ? (
                                                                            <Box sx={{
                                                                                border: '1px solid gray',
                                                                                borderRadius: '10px',
                                                                                backgroundColor: "#EEF18B",
                                                                                pl: 0.5, pr: 0.5,
                                                                                width: '80%'
                                                                            }}>
                                                                                <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('INVESTMENT_STATUS.WAITING_APPROVAL')}</Typography>
                                                                                <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('DASHBOARD.WAITING_LIST')}</Typography>
                                                                            </Box>)

                                                                            : <Box sx={{
                                                                                border: '1px solid gray',
                                                                                borderRadius: '10px',
                                                                                backgroundColor: "#EEF18B",
                                                                                pl: 0.5, pr: 0.5,
                                                                                width: '80%'
                                                                            }}>
                                                                                <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>{t('INVESTMENT_STATUS.WAITING_APPROVAL')}</Typography>
                                                                            </Box>
                    }
                    <Typography sx={{ fontSize: '10px' }}>{t('ADMIN.LAST_UPDATE')} {t(`MONTHS.${MonthLastUpdate}`).substring(0, 3)}. {DayLastUpdate} -  {data?.lastUpdate?.split('T')[1]?.split('.')[0]} UTC</Typography>
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    {
                        contractLink !== undefined &&
                        <IconButton
                            href={marloweScanUrl + smartContractIdURI}
                            sx={{ cursor: 'pointer', color: actualThemeLight ? '#9A1D4C' : '#e6a3bd' }}
                            target={{ _blank: true }}
                        >
                            <LaunchIcon />
                        </IconButton>
                    }
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    {data?.smartContract !== null &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100px', alignItems: 'center', justifyContent: 'space-around' }}>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        <Typography sx={{ fontWeight: 'bold' }}>{t('SC_DETAIL.SC_ASSETS')}:</Typography>
                                        <Typography>{JSON.stringify(demeterContractData?.resource?.assets, null, 2)}</Typography>
                                    </div>
                                }>
                                <AttachMoneyIcon sx={{ color: demeterContractData?.resource?.assets?.lovelace <= 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={hasInvestorPayout ?
                                    <Typography sx={{ mr: 1 }}>{t('SC_DETAIL.1UNCLAIMED')}</Typography>
                                    :
                                    <Typography sx={{ mr: 1 }}>{t('SC_DETAIL.0UNCLAIMED')}</Typography>
                                }>
                                <OutputIcon sx={{ color: unclaimedPayouts <= 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        {
                                            (!isNextStepDone || unclaimedPayouts > 0) &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'start', p: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Typography sx={{ flexWrap: 'wrap', mr: 1, fontWeight: 'bold' }}>
                                                        {t('SC_DETAIL.SC_ACTIVE')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            (isNextStepDone && unclaimedPayouts === 0) &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '700px', justifyContent: 'start', p: 1 }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Typography sx={{ flexWrap: 'wrap', mr: 1, fontWeight: 'bold' }}>
                                                        {t('SC_DETAIL.SC_CLOSED')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </div>
                                }>
                                <DoubleArrowIcon sx={{ color: isNextStepDone && unclaimedPayouts === 0 ? '#e6e6e6' : '#00e600' }} />
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                followCursor
                                TransitionComponent={Fade}
                                title={
                                    <div>
                                        <Typography sx={{ fontWeight: 'bold' }}>Refresh</Typography>
                                    </div>
                                }>
                                <IconButton edge='end' size='small' onClick={getContractById}>
                                    {loading ?
                                        <CircularProgress size={20} /> :
                                        <RefreshIcon fontSize='20px' sx={{ color: '#0000ff' }} />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                </TableCell>
                <TableCell sx={{ p: 1 }} align="center" component="th" scope="row">
                    {data?.status === "SC Created - ALLI Deposited" ? (
                        creatingTx ?
                            <CircularProgress size={20} />
                            :
                            <>
                                {isWithin48Hrs === true ?
                                    <>
                                        {
                                            isConnectedWalletTheSame === true && xChangeAddress !== undefined
                                                ?
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                                    <Button size='small' onClick={handleDeposit} variant="contained">{t('INVESTMENT_STATUS.MAKE_DEPOSIT')}</Button>
                                                    <Typography fontSize={'12px'}>{timeLeft?.hoursLeft} {t('INVERSOR_VIEW.OFFERING_CARD.HOURS')} {timeLeft?.minutesLeft} {t('INVERSOR_VIEW.OFFERING_CARD.MINUTES')}</Typography>
                                                </Box>
                                                :
                                                <Tooltip title={t('WALLET_VIEW.WALLET_DOESNT_MATCH')}>
                                                    <Box>
                                                        <Button size='small' disabled variant="contained">{t('INVESTMENT_STATUS.MAKE_DEPOSIT')}</Button>
                                                    </Box>
                                                </Tooltip>
                                        }
                                    </>
                                    :
                                    <Typography sx={{ textAlign: 'center' }}>{t('INVERSOR_VIEW.OFFERING_CARD.TIMEOUT')}</Typography>
                                }
                            </>)
                        : (data?.status === "Core Withdrawn" || data?.status === "Core Withdrawn - Pending") ? (
                            creatingTx ?
                                <CircularProgress size={20} />
                                :
                                <>
                                    {
                                        isConnectedWalletTheSame === true
                                            ?
                                            <Button onClick={handleWithdraw} variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                            :
                                            <Tooltip title={t('WALLET_VIEW.WALLET_DOESNT_MATCH')}>
                                                <Box>
                                                    <Button disabled variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                </Box>
                                            </Tooltip>

                                    }
                                </>)
                            : (data?.status === "SC Timed Out - Core Withdrawn" && hasInvestorPayout) ? (
                                creatingTx ?
                                    <CircularProgress size={20} />
                                    :
                                    <>
                                        {
                                            isConnectedWalletTheSame === true
                                                ?
                                                <Button onClick={handleWithdrawInvestment} variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                :
                                                <Tooltip title={t('WALLET_VIEW.WALLET_DOESNT_MATCH')}>
                                                    <Box>
                                                        <Button disabled variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                    </Box>
                                                </Tooltip>

                                        }
                                    </>)
                                : (data?.status === "Investment Cancelled" && hasInvestorPayout) ? (
                                    creatingTx ?
                                        <CircularProgress size={20} />
                                        :
                                        <>
                                            {
                                                isConnectedWalletTheSame === true
                                                    ?
                                                    <Button onClick={handleWithdrawInvestment} variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                    :
                                                    <Tooltip title={t('WALLET_VIEW.WALLET_DOESNT_MATCH')}>
                                                        <Box>
                                                            <Button disabled variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                        </Box>
                                                    </Tooltip>

                                            }
                                        </>)
                                    : (data?.status === "Investment Cancelled - Target Met" && hasInvestorPayout) ? (
                                        creatingTx ?
                                            <CircularProgress size={20} />
                                            :
                                            <>
                                                {
                                                    isConnectedWalletTheSame === true
                                                        ?
                                                        <Button onClick={handleWithdrawInvestment} variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                        :
                                                        <Tooltip title={t('WALLET_VIEW.WALLET_DOESNT_MATCH')}>
                                                            <Box>
                                                                <Button disabled variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                            </Box>
                                                        </Tooltip>

                                                }
                                            </>)
                                        : (data?.status === "Investment Cancelled - Collecting Ended" && hasInvestorPayout) ? (
                                            creatingTx ?
                                                <CircularProgress size={20} />
                                                :
                                                <>
                                                    {
                                                        isConnectedWalletTheSame === true
                                                            ?
                                                            <Button onClick={handleWithdrawInvestment} variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                            :
                                                            <Tooltip title={t('WALLET_VIEW.WALLET_DOESNT_MATCH')}>
                                                                <Box>
                                                                    <Button disabled variant="contained">{t('WALLET_VIEW.INVESTMENT_CARD.WITHDRAW')}</Button>
                                                                </Box>
                                                            </Tooltip>

                                                    }
                                                </>)
                                            : data?.status === "SC Created - ALLI Deposited - Pending" ? (
                                                creatingTx ?
                                                    <CircularProgress size={20} />
                                                    :
                                                    <>
                                                        <Tooltip title={t('WALLET_VIEW.WAITING_CONFIRMATION')}>
                                                            <Box>
                                                                <Button disabled variant="contained">{t('INVESTMENT_STATUS.MAKE_DEPOSIT')}</Button>
                                                            </Box>
                                                        </Tooltip>
                                                    </>)
                                                : null}
                </TableCell>
            </TableRow >
        </>
    )
}

export default SelectedFundRowInvestment