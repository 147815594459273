import {
  CLEAR_OFFERING_TOKEN,
  CLEAR_REC_OFFERING,
  GET_OFFERING_REC,
  GET_OFFERING_TOKEN,
} from '../actions/offeringActions'

const initialState = {
  tokenOffering: [],
  recommendedOfferings: [],
}

const offeringReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERING_TOKEN.success: {
      return {
        ...state,
        tokenOffering: action.Offering,
      }
    }
    case GET_OFFERING_REC.success: {
      return {
        ...state,
        recommendedOfferings: action.users[0].offeringsWithSimilarPreferences,
      }
    }
    case CLEAR_OFFERING_TOKEN: {
      return initialState
    }
    case CLEAR_REC_OFFERING: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default offeringReducer
